/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { combineApplicantName, renderDateFromSource } from '../formatUtil';
import { Avatar, Box, Button, Card, Center, Group, Text, Title, Tooltip } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';

export const EmailSummaryCard = memo(function EmailSummaryCard ({ email, isAdmin = false }) {
  const accountName = combineApplicantName(email.account)

  const onDetailsClick = () => {
    console.warn('On email summary card details click - must implement show or navigate to details', email) // TODO [cycle pass full features] remove disabled prop
  }

  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg='green' py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>Email</Title>
          </Box>
        </Card.Section>
        <Card.Section mt='xxs' mb='1rem'>
          <Center>
            <Avatar
              color='green'
              alt={`Email address: ${email.id}`}
              size='xl'
            >
              <IconMail size='3rem' />
            </Avatar>
          </Center>
        </Card.Section>

        <Text ta='center' c='teal' size='md'>
          {email.id}
        </Text>

        {!!isAdmin && (
          <Text c={email.account ? 'teal' : 'dimmed'} ta='center' size={email.account ? 'md' : 'xxs'}>
            Account: {accountName || email.account?.email || 'None Assigned'}{email.account?.id ? ` #${email.account.id}` : ''}
          </Text>
        )}

        <Group justify="space-between" mt="auto" mb="xs">
          {!!email.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(email.created_at)}</Text>}
          {!!email.updated_at && <Text size="sm" c="dimmed">Updated: {renderDateFromSource(email.updated_at)}</Text>}
        </Group>

        <Tooltip label='Feature Coming Soon'>
          <Button color="blue" fullWidth mt={0} radius="md" onClick={onDetailsClick} disabled={true}>
            View Details
          </Button>
        </Tooltip>
      </Card>
    </>
  )
})
