import React, { useCallback, useState } from 'react'
import { useAssessment } from './AssessmentHooks'
import { exportAssessment } from '../../../js/api/assessment_repository'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { AssessmentPDFExport } from './AssessmentPDFExport';

const configToAssessmentParamsMap = {
  order_by_competency: 'orderByCompetency',
  randomization_seed: 'randomSeed',
  organization_name: 'organization_name',
  job_title: 'job_title'
}

const configToExportParamsMap = {
  order_by_competency: 'orderByCompetency',
  show_correct_answer: 'displayCorrectAnswers',
  show_competencies: 'displayCompetencies',
  organization_name: 'organization_name',
  job_title: 'job_title'
}

const missingConfigReplacements = {
  organization_name: '',
  job_title: ''
}

const additionalTextFields = {
  organization_name: {
    fieldProps: {
      label: 'Organization name',
      placeholder: 'Organization name for wildcards'
    }
  },
  job_title: {
    fieldProps: {
      label: 'Job title',
      placeholder: 'Job title for wildcards'
    }
  }
}

export default function Export () {
  const assessmentId = document.getElementById('export-assessment-container').getAttribute('data-assessment-id')
  const [assessmentParams, setAssessmentParams] = useState({
    order_by_competency: false,
    randomization_seed: 0,
    organization_name: '',
    job_title: ''
  })

  const [assessment, querying] = useAssessment(assessmentId, assessmentParams)

  const updateAssessmentParams = useCallback((newConfig) => {
    let anyChanged = false
    const newParams = { ...assessmentParams }
    for (const [paramKey, configKey] of Object.entries(configToAssessmentParamsMap)) {
      const newConfigValue = newConfig[configKey] ?? missingConfigReplacements[configKey] ?? null
      if (newParams[paramKey] !== newConfigValue) {
        newParams[paramKey] = newConfigValue
        anyChanged = true
        if (newConfigValue === null) {
          console.error('Unable to find expected config key when updating assessment params.', configKey, newConfig)
        }
      }
    }
    if (anyChanged) {
      console.debug('Updating assessment params. old|new|pdfConfig', assessmentParams, newParams, newConfig)
      setAssessmentParams(newParams)
    }
  }, [assessmentParams])

  const assessmentName = assessment?.name ?? 'Assessment'

  const exportLegacyAssessment = useCallback((pdfParams) => {
    const exportParams = Object.fromEntries(
      Object.entries(configToExportParamsMap)
        .map(([exportKey, pdfKey]) => [exportKey, pdfParams[pdfKey] ?? null])
    )
    console.info('Exporting to legacy pdf.', { exportParams, pdfParams })

    return exportAssessment(assessmentId, exportParams)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${assessmentName} Export.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch(err => {
        const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

        if (err.response.status === 400 && data?.type === 'validation_error') {
          _.forEach(data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }, [assessmentId, assessmentName])

  return (
    <AssessmentPDFExport
      assessment={assessment}
      querying={!!querying}
      updateAssessmentParams={updateAssessmentParams}
      exportLegacyAssessment={exportLegacyAssessment}
      additionalTextFields={additionalTextFields}
    />
  )
}
