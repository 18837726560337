/* eslint react/prop-types: 0 */
import React, { useMemo } from 'react';
import { Header, NumberCell, RezviewCell } from '../core/ReactTable/ReactTable';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData, selectParamsLoaded } from '../core/ReactTable/paramsSlice';
import { useSearchApplicantsQuery } from './SemanticApi';
import { Button, Text } from '@mantine/core'
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// import { IconMail } from '@tabler/icons-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetExpertiseCategoriesQuery } from '../../redux/query/hire/expertiseCategoryApi.slice';
import { isGranted } from '../../util/account/account.util';

export function useSemanticQuery (namespace) {
  const paramsLoaded = useSelector(state => selectParamsLoaded(state, namespace))
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))

  const { data: collection, isFetching: querying } = useSearchApplicantsQuery(paramsLoaded ? queryParams : skipToken)
  return [collection ?? null, querying]
}

export function useExpertiseCategoryQuery (query) {
  const { data: collection, isLoading: querying } = useGetExpertiseCategoriesQuery(query ?? skipToken)
  return [collection ?? [], querying]
}

export function useSemanticTable (account) {
  const isAdmin = account ? isGranted(account, 'ROLE_ADMIN') : false
  const columns = useSemanticColumns(isAdmin)
  //  const actions = useSemanticActions()

  return {
    columns
    //    actions
  }
}

export function useSemanticColumns (isAdmin) {
  return useMemo(
    () => [
      {
        Header: <Header>Name</Header>,
        accessor: (row) => row,
        Cell: ({ cell: { value } }) => <RezviewCell name={value.name} address={value.address} applicantId={value.id} isAdmin={isAdmin} />,
        id: 'name'
      },
      {
        Header: <Header>Created At</Header>,
        Cell: ({ cell: { value } }) => <Text>{dayjs(value).format('MM-DD-YYYY')}</Text>,
        id: 'created_at',
        accessor: 'created_at',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>Organization</Header>,
        Cell: ({ cell: { value } }) => <Text>{value}</Text>,
        id: 'organization',
        accessor: 'organization_name',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>Specific Job</Header>,
        Cell: ({ cell: { value } }) => <Text>{value}</Text>,
        id: 'specific_job',
        accessor: 'specific_job',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>Cycle</Header>,
        accessor: (row) => row,
        /**
         * @param {{cycle_id: number}} value
         * @param {{cycle_name: string}} value
         */
        Cell: ({ cell: { value } }) => <Button component={Link} to={`/cycles/${value.cycle_id}/beta-detail`} reloadDocument variant='transparent'>{value.cycle_name}</Button>,
        id: 'cycle',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>Baseline</Header>,
        Cell: ({ cell: { value } }) => value === undefined
          ? <NumberCell centered><Text>-</Text></NumberCell>
          : <NumberCell centered><Text>{Math.floor(value)}</Text></NumberCell>,
        id: 'baseline',
        accessor: 'baseline'
      }
    ].filter(item => item.metadata?.isGranted !== false), [isAdmin])
}

/* export function useSemanticActions () {
  return useMemo(
    () => [
      {
        label: 'Invite',
        leftSection: <IconMail/>,
        href: (id) => `/applicant/${id}/semantic/invite`
      }
    ], []
  )
} */
