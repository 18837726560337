/* eslint react/prop-types: 0 */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useReducer,
  useContext,
  memo
} from 'react';
import { useDebouncedValue, useEventListener } from '@mantine/hooks';
import { PinInput, Input, Group, Text, Space, Box, Popover, Button, ActionIcon, Tooltip } from '@mantine/core';
import { IconPhoneOff } from '@tabler/icons-react';
import { FilterReducerContext } from './FilterContexts';
import {
  createInitialFilterPhoneNumberState,
  filterPhoneNumberReducer,
  FilterPhoneNumberUpdate,
  wildcardCharacter,
  allCharactersSame
} from './FilterPhoneNumberState';

/**
 * @param {InputFilter} filter
 * @param {string} selected
 * @param {function} updateFilter
 */
export function FilterPhoneNumberInput ({ filter, selected, updateFilter }) {
  const [opened, setOpened] = useState(false)
  const [state, dispatch] = useReducer(
    filterPhoneNumberReducer,
    selected,
    createInitialFilterPhoneNumberState
  )

  const [debounced] = useDebouncedValue(state.value, 1000)
  const lastDebouncedRef = useRef(debounced)
  const updateFilterRef = useRef(updateFilter)
  const filterId = filter.id

  useEffect(() => {
    updateFilterRef.current = updateFilter
  }, [updateFilter])

  useEffect(() => {
    const selectedValue = (selected || null)
    const lastValue = lastDebouncedRef.current
    const filteredDebounced = allCharactersSame(debounced, wildcardCharacter) ? null : debounced
    console.debug('Phone number filter value or parent value changed.', { filteredDebounced, lastValue, selectedValue, debounced })
    if (filteredDebounced !== selectedValue) {
      if (lastValue === selectedValue) {
        lastDebouncedRef.current = filteredDebounced
        updateFilterRef.current(filterId, filteredDebounced)
      } else {
        lastDebouncedRef.current = selectedValue
        dispatch({ type: FilterPhoneNumberUpdate.ReplaceSelectedValue, value: selectedValue })
      }
    }
  }, [debounced, selected, filterId, dispatch])

  const disabled = filter?.disabled ?? false
  const fallbackText = 'Enter Number'
  const buttonVariant = selected ? 'subtle' : 'outline'
  const numberText = renderSelectedNumber(selected)

  const onClearClick = useCallback(() => {
    console.debug('Dispatching clear phone number filter', { filterId })
    dispatch({ type: FilterPhoneNumberUpdate.ClearSelectedValue })
    updateFilterRef.current(filterId, null)
    setOpened(false)
  }, [filterId, dispatch])

  const numberSegments = state.numberSegments
  const numberSegmentIndexes = useMemo(() => {
    return [...numberSegments.keys()]
  }, [numberSegments])

  console.debug('FilterPhoneNumberInput updating.', { state, selected, opened })

  return (
    <Input.Wrapper label={filter.label} description={filter.description}>
      <Group justify='center'>
        <Popover opened={opened} onChange={setOpened} position='top-start' offset={10} withArrow withinPortal={false}>
          <Popover.Target>
            <Button
              variant={buttonVariant}
              onClick={() => {
                console.debug('Toggling FilterPhoneNumber open state')
                dispatch({ type: FilterPhoneNumberUpdate.FocusDigit, digit: 0, numberSegment: 0 })
                setOpened((o) => !o)
              }}
              disabled={disabled}
            >
              {numberText || fallbackText}
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <FilterPhoneNumberInputDispatchContextProvider dispatch={dispatch}>
              <Group justify='center' gap='xs' grow preventGrowOverflow={false} wrap="nowrap">
                <ClearPhoneNumberFilterButton hasValue={!!state.value} onClick={onClearClick} />
                {numberSegmentIndexes.map((segmentId) => (
                  <PhoneNumberInputSegment
                    key={segmentId}
                    numberSegmentIndex={segmentId}
                    numberSegmentDigits={numberSegments.get(segmentId)}
                    digitValues={state.digitValues}
                    activeDigit={state.activeDigit}
                  />
                ))}
              </Group>
            </FilterPhoneNumberInputDispatchContextProvider>
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Input.Wrapper>
  )
}

/**
 * @param {boolean} hasValue
 * @param {function} onClick
 */
const ClearPhoneNumberFilterButton = memo(function ClearPhoneNumberFilterButton ({ hasValue, onClick }) {
  console.debug('ClearPhoneNumberFilterButton updating.', { hasValue })

  return (
    <Tooltip label='Clear Filter'>
      <Box>
        <Space h='xs' />
        <ActionIcon variant='light' disabled={!hasValue} onClick={onClick}>
          <IconPhoneOff />
        </ActionIcon>
      </Box>
    </Tooltip>
  )
})

function FilterPhoneNumberInputDispatchContextProvider ({ dispatch, children }) {
  return (
    <FilterReducerContext.Provider value={dispatch}>
      {children}
    </FilterReducerContext.Provider>
  )
}

const preNumberSegmentTexts = ['+', ' (', ') ', ' - ']

/**
 * @param {int} numberSegmentIndex
 * @param {int[]} numberSegmentDigits
 * @param {Map.<int, string|null>} digitValues
 * @param {?int} activeDigit
 */
const PhoneNumberInputSegment = memo(function PhoneNumberInputSegment (
  {
    numberSegmentIndex,
    numberSegmentDigits,
    digitValues,
    activeDigit
  }
) {
  console.debug(
    'PhoneNumberInputSegment updating',
    { numberSegmentIndex, numberSegmentDigits, activeDigit }
  )
  const preSegmentText = preNumberSegmentTexts[numberSegmentIndex] ?? null
  return (
    <>
      {!!preSegmentText && (<Box><Space h='xs' /><Text size='md'>{preSegmentText}</Text></Box>)}
      {numberSegmentDigits.map((digit) => (
        <PhoneNumberInputDigit
          key={digit}
          digit={digit}
          numberSegment={numberSegmentIndex}
          value={digitValues.get(digit) ?? null}
          isActive={activeDigit === digit}
        />
      ))}
    </>
  )
})

/**
 * @param {int} digit
 * @param {int} numberSegment
 * @param {?string} value
 * @param {boolean} isActive
 */
const PhoneNumberInputDigit = memo(function PhoneNumberInputDigit ({ digit, numberSegment, value, isActive }) {
  const dispatch = useContext(FilterReducerContext)
  const selfActivatedRef = useRef(false)

  const onFocus = useCallback(() => {
    console.debug('Called onFocus for PhoneNumberInputDigit', { digit, numberSegment })
    selfActivatedRef.current = true
    dispatch({ type: FilterPhoneNumberUpdate.FocusDigit, digit: digit, numberSegment: numberSegment })
  }, [dispatch, digit, numberSegment])

  const rootRef = useEventListener('focusin', onFocus)
  const ref = useRef(null)

  const onChange = useCallback((newValue) => {
    console.debug('Calling dispatch to update digit value', { newValue, digit, numberSegment })
    dispatch({ type: FilterPhoneNumberUpdate.SetDigitValue, digit: digit, numberSegment: numberSegment, value: newValue })
  }, [digit, numberSegment, dispatch])

  useEffect(() => {
    if (isActive) {
      if (!selfActivatedRef.current) {
        console.debug('Auto-focusing PhoneNumberInputDigit from reducer state change', { digit })
        ref.current?.focus()
      }
    }
    selfActivatedRef.current = false
  }, [isActive, digit])

  console.debug('PhoneNumberInputDigit updating', { numberSegment, digit, value, isActive })

  return (
    <PinInput
      value={value ?? ''}
      size='xs'
      type='alphanumeric'
      length={1}
      rootRef={rootRef}
      ref={ref}
      onChange={onChange}
      autoFocus={digit === 0}
    />
  )
})

export function renderSelectedNumber (nullableNumber) {
  if (!nullableNumber || allCharactersSame(nullableNumber, wildcardCharacter)) {
    return null
  }
  const countryCode = nullableNumber.slice(0, 1).padEnd(1, '*')
  const areaCode = nullableNumber.slice(1, 4).padEnd(3, '*')
  const firstSegment = nullableNumber.slice(4, 7).padEnd(3, '*')
  const lastSegment = nullableNumber.slice(7, 11).padEnd(4, '*')
  return `+${countryCode} (${areaCode}) ${firstSegment}-${lastSegment}`
}
