import { ActionIcon } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { IconHelpCircle } from '@tabler/icons-react'
import { getHelpLink } from '../../js/api/help_link_repository'
import { myAccount } from '../../js/api/account_repository'
import _ from 'lodash'

export function HelpButton () {
  const [account, setAccount] = useState(null)
  const [helpLink, setHelpLink] = useState(null)

  useEffect(() => {
    myAccount('api/v1/accounts').then(response => setAccount(response))
    getHelpLink(window.location.pathname).then(helpLink => setHelpLink(helpLink.help_link))
  }, [])

  if (
    !_.includes(account?.roles.map(r => r.name) ?? [], 'ROLE_PORTAL_USER') &&
    !_.includes(account?.roles.map(r => r.name) ?? [], 'ROLE_PORTAL_CLIENT') &&
    !_.includes(account?.roles.map(r => r.name) ?? [], 'ROLE_ADMIN') &&
    !_.includes(account?.roles.map(r => r.name) ?? [], 'ROLE_DEVELOPER')
  ) {
    return
  }

  return helpLink !== null && <ActionIcon component='a' href={helpLink} target='_blank' ><IconHelpCircle size={22} /></ActionIcon>
}
