import { addEdge, applyEdgeChanges, getConnectedEdges, getIncomers, getOutgoers } from 'reactflow';
import {
  createLogicArrayForQuestionFromNodes,
  deNormalizeLogicArrayForReducer,
  EdgeTypes,
  getNextFakeNodeId,
  NodeTypes, stripContentTags
} from './util';
import { notifications } from '@mantine/notifications';
import { QuestionStateUpdate } from '../QuestionsState';
import { batchUpdateQuestionLogic } from './unpublishedLogicSlice';
import { LogicFeature } from '../../../../../js/generated/enums/LogicFeature';

/**
 * @param {QuestionLogic[]} logics
 * @param {Map.<int: Question>} questions
 * @param {Question} question
 * @param {int} questionIndex
 * @param questionOptions Used for logic node Select
 */
export function mapLogicsToNodes (logics, questions, question, questionIndex, questionOptions) {
  const parsedNodes = []
  const parsedEdges = []
  const logicsByFeature = new Map()
  const featureOptions = Object.values(LogicFeature)
  let depth = 1.25
  let width = 0
  const groupOffsets = new Map()
  const offsetPerGroup = 250
  const offsetPerDepth = 900
  const offsetPerWidth = 300

  console.info('Mapping reducer logic to initial nodes config', logics, questions, question, questionIndex)

  const [sortedInChildren, maxTreeDepth] = sortLogicTreeByDepthChildrenLengthThenNormalizedId({ children: logics })
  const sortedLogics = sortedInChildren.children
  console.debug('Sorted Logics | Max Logic Depth', sortedLogics, maxTreeDepth, logics)
  for (const logic of sortedLogics) {
    if (!logicsByFeature.has(logic.feature)) {
      logicsByFeature.set(logic.feature, [logic])
    } else {
      logicsByFeature.get(logic.feature).push(logic)
    }
  }

  let toParseLogic = [...([...logicsByFeature.values()].flat())]
  let featureOptionIndex = 0.5
  for (const featureOption of featureOptions) {
    if (logicsByFeature.has(featureOption)) {
      const featureNodeId = 'f.' + featureOption
      parsedNodes.push({
        id: featureNodeId,
        data: { feature: featureOption },
        position: { x: depth * offsetPerDepth, y: featureOptionIndex * offsetPerWidth },
        type: NodeTypes.feature
      })
      parsedEdges.push({
        id: 'q.0.to.' + featureNodeId,
        source: 'q.0',
        target: featureNodeId,
        type: EdgeTypes.default
      })
      featureOptionIndex += 1
    }
  }
  depth += 0.35
  console.debug('To parse logic config', logics, sortedLogics, toParseLogic)
  const seenToParseLogicFeatures = new Map()
  const seenGroupIds = new Map()
  for (const logic of toParseLogic) {
    const targetedLogicNodeId = logic.nodeGroupId ?? logic.id
    const firstLogicOfFeature = !seenToParseLogicFeatures.has(logic.feature)
    let previousSibling = null
    let firstGroupSibling = null
    if (firstLogicOfFeature) {
      seenToParseLogicFeatures.set(logic.feature, [logic])
      if (!seenGroupIds.has(targetedLogicNodeId)) {
        seenGroupIds.set(targetedLogicNodeId, targetedLogicNodeId)
        firstGroupSibling = targetedLogicNodeId
      } else {
        firstGroupSibling = seenGroupIds.get(targetedLogicNodeId)
      }
    } else {
      const featureSiblings = seenToParseLogicFeatures.get(logic.feature)
      previousSibling = featureSiblings[featureSiblings.length - 1]
      const firstSibling = featureSiblings[0]
      const firstFeatureSiblingNodeId = firstSibling.nodeGroupId ?? firstSibling.id
      seenToParseLogicFeatures.set(logic.feature, [...featureSiblings, logic])
      if (seenGroupIds.has(firstFeatureSiblingNodeId)) {
        firstGroupSibling = seenGroupIds.get(firstFeatureSiblingNodeId)
        if (!seenGroupIds.has(targetedLogicNodeId)) {
          seenGroupIds.set(targetedLogicNodeId, firstFeatureSiblingNodeId)
        }
      } else {
        if (seenGroupIds.has(targetedLogicNodeId)) {
          firstGroupSibling = seenGroupIds.get(targetedLogicNodeId)
        } else {
          seenGroupIds.set(targetedLogicNodeId, firstFeatureSiblingNodeId)
          firstGroupSibling = firstFeatureSiblingNodeId
        }
      }
    }
    console.debug('Seen group ids for first and connection per feature.', seenGroupIds)
    parsedEdges.push({
      id: 'f.' + logic.feature + '.to.' + targetedLogicNodeId,
      source: 'f.' + logic.feature,
      target: targetedLogicNodeId.toString(),
      hidden: firstGroupSibling !== targetedLogicNodeId,
      type: EdgeTypes.default
    })
    if (previousSibling && !logic.nodeGroupId) { // Note that any logic with nodeGroupId is skipped below.
      parsedEdges.push({
        id: '' + (previousSibling.nodeGroupId ?? previousSibling.id) + '.to.' + targetedLogicNodeId,
        source: (previousSibling.nodeGroupId ?? previousSibling.id).toString(),
        sourceHandle: 'c',
        target: targetedLogicNodeId.toString(),
        type: EdgeTypes.or
      })
    }
  }

  while (toParseLogic.length) {
    const activeLogic = [...toParseLogic]
    toParseLogic = []
    for (const logic of activeLogic) {
      if (!groupOffsets.has(logic.feature)) {
        groupOffsets.set(logic.feature, ([...groupOffsets.values()].slice(-1).pop() ?? -offsetPerGroup) + offsetPerGroup)
      }
      if (logic.nodeGroupId) {
        console.debug('Logic has node group id - skipping parsing of children to avoid duplicates.', logic.id, logic.nodeGroupId, logic, sortedLogics, logics)
        continue
      }
      const groupOffset = groupOffsets.get(logic.feature)
      parsedNodes.push({
        id: logic.id.toString(),
        data: {
          logic: {
            ...logic,
            children: logic.children.map(child => child.id)
          },
          questionOptions: questionOptions,
          questionData: questions
        },
        position: { x: depth * offsetPerDepth, y: groupOffset + (width * offsetPerWidth) },
        type: NodeTypes.logic
      })
      width += 1
      let lastSubLogic = null
      for (const subLogic of logic.children) {
        const targetSubLogicId = (subLogic.nodeGroupId ?? subLogic.id)
        parsedEdges.push({ // Make all edges but the one to first of children hidden, connect subsequent logic.children nodes to each other with OR label.
          id: logic.id.toString() + '.to.' + targetSubLogicId,
          source: logic.id.toString(),
          target: targetSubLogicId.toString(),
          hidden: !!lastSubLogic,
          sourceHandle: 'b',
          type: EdgeTypes.and
        })
        if (lastSubLogic && !subLogic.nodeGroupId) {
          console.debug('Connecting sub-node to previous sub-node.', subLogic, lastSubLogic, logic)
          parsedEdges.push({
            id: (lastSubLogic.nodeGroupId ?? lastSubLogic.id).toString() + '.to.' + targetSubLogicId,
            source: (lastSubLogic.nodeGroupId ?? lastSubLogic.id).toString(),
            target: targetSubLogicId.toString(),
            sourceHandle: 'c',
            type: EdgeTypes.or
          })
        }
        toParseLogic.push(subLogic)
        lastSubLogic = subLogic
      }
    }
    width = 0
    depth += 1
  }
  parsedNodes.push({
    id: 'q.0',
    data: { label: stripContentTags(question.content), startedWithData: !!logics.length },
    position: { x: 0, y: ((featureOptionIndex - 0.5) / 2) * offsetPerWidth },
    type: NodeTypes.target
  })

  console.debug('Returning parsed nodes, edges.', parsedNodes, parsedEdges)
  return [parsedNodes, parsedEdges]
}

function sortLogicTreeByDepthChildrenLengthThenNormalizedId (node) {
  const newChildren = []
  const depthMap = new Map()
  const lengthMap = new Map()
  const normalizedIdMap = new Map()
  for (const logic of node.children) {
    const [newLogic, maxDepth] = sortLogicTreeByDepthChildrenLengthThenNormalizedId(logic)
    newChildren.push(newLogic)
    depthMap.set(logic.id, maxDepth)
    lengthMap.set(logic.id, logic.children.length)
    normalizedIdMap.set(logic.id, getLogicNormalizedId(logic))
  }
  const compareFunction = (a, b) => {
    if (depthMap.get(a.id) !== depthMap.get(b.id)) {
      return depthMap.get(a.id) > depthMap.get(b.id) ? -1 : 1
    }
    if (lengthMap.get(a.id) !== lengthMap.get(b.id)) {
      return lengthMap.get(a.id) > lengthMap.get(b.id) ? -1 : 1
    }
    if (normalizedIdMap.get(a.id) !== normalizedIdMap.get(b.id)) {
      return normalizedIdMap.get(a.id) < normalizedIdMap.get(b.id) ? -1 : 1
    }
    return 0
  }

  return [
    { ...node, children: newChildren.sort(compareFunction) },
    Math.max(...depthMap.values()) + (node.children.length ? 1 : 0)
  ]
}

function getLogicNormalizedId (node) {
  const originalId = node.nodeGroupId ?? node.id
  return originalId < 0 ? 0 - originalId : -1000 - originalId
}

export function onUnmount (currentNodes, currentEdges, originalNodes, originalEdges, allowUpdate, questions, question, dispatch, reduxDispatch) {
  const formatEntityIds = (targetNodes, targetEdges) => {
    return new Set([
      ...targetNodes.map(node => node.type === NodeTypes.logic ? [node.id, (node.data.logic.questionId ?? ''), (node.data.logic.operator ?? ''), (node.data.logic.value ?? '')].join('.') : node.id),
      ...targetEdges.map(edge => edge.id)
    ])
  }
  const currentEntityIds = formatEntityIds(currentNodes, currentEdges)
  const originalEntityIds = formatEntityIds(originalNodes, originalEdges)
  console.debug(
    'On-unmount update for questions logic editor called. Updating store with any new logic.',
    currentNodes,
    currentEdges,
    currentEntityIds,
    originalEntityIds
  )
  const targetSetHasAllElements = (sourceSet, targetSet) => {
    for (const value of sourceSet.values()) {
      if (!targetSet.has(value)) {
        console.debug('Target set did not have element - node/edge change detected.', value, targetSet, sourceSet)
        return false
      }
    }
    return true
  }
  const anyNewMissing = targetSetHasAllElements(currentEntityIds, originalEntityIds)
  const anyChanges = anyNewMissing || targetSetHasAllElements(originalEntityIds, currentEntityIds)
  if (!allowUpdate && !currentEntityIds.size) {
    console.debug('Skipping update from instant dismount.', anyChanges, currentEntityIds, originalEntityIds)
    return
  }
  if (!anyChanges) {
    console.debug('No changes detected - should skip update.', anyChanges, currentEntityIds, originalEntityIds)
    // return  // TODO [full redux] dispatch event to undo check logic / validation state when no changes made.
  }
  console.debug('Submitting currentNodes | currentEdges for conversion.', currentNodes, currentEdges)
  const logicArray = createLogicArrayForQuestionFromNodes(questions, question, currentNodes, currentEdges)
  const deNormalizedLogic = deNormalizeLogicArrayForReducer(logicArray)
  console.debug('On-unmount logic array | de-norm.', logicArray, deNormalizedLogic, question.id)
  if (deNormalizedLogic.length || question.logic.length) {
    console.info('Updating question logic in reducer.', deNormalizedLogic, question)
    dispatch({
      type: QuestionStateUpdate.UpdateQuestion,
      questionId: question.id,
      newAttributes: { logic: deNormalizedLogic }
    })
    console.debug('Updating question logic in redux.', logicArray)
    reduxDispatch(batchUpdateQuestionLogic({ logicArray: logicArray, questionId: question.id }))
    console.debug('Logic unmount updates complete.', question)
  }
}

export function deleteSelected (selected, getEdges, onEdgesChange, onNodesChange) {
  console.debug('Delete button clicked.', selected)
  const selectedNodes = selected.filter(elem => elem.isNode && elem.id !== 'q.0')
  const selectedEdgesFromNodes = new Set()
  if (selectedNodes.length) {
    const currentEdges = getEdges()
    for (const node of selectedNodes) {
      const connectedEdges = currentEdges.filter(edge => (edge.source === node.id) || (edge.target === node.id))
      for (const edge of connectedEdges) {
        selectedEdgesFromNodes.add(edge.id)
      }
    }
    console.debug('Added edge ids to selected edges for deletion.', selectedEdgesFromNodes)
  }
  const selectedEdges = [
    ...selected.filter(elem => !elem.isNode && !selectedEdgesFromNodes.has(elem.id)),
    ...[...selectedEdgesFromNodes].map(edgeId => ({ id: edgeId }))
  ]
  if (selectedEdges.length) {
    console.debug('Deleting selected edges.', selectedEdges)
    onEdgesChange(selectedEdges.map(edge => ({ id: edge.id, type: 'remove' })))
  }
  if (selectedNodes.length) {
    console.debug('Deleting selected nodes.', selectedNodes)
    onNodesChange(selectedNodes.map(node => ({ id: node.id, type: 'remove' })))
  }
}

export function onConnectEndUpdate (screenToFlowPosition, setNodes, setEdges, getNode, nodes, edges, event, connectingNodeId, questions, questionOptions) {
  const targetIsPane = event.target.classList.contains('react-flow__pane')
  if (targetIsPane) {
    if (connectingNodeId === 'q.0') {
      const id = 'f.' + getNextFakeNodeId().toString()
      const newNode = {
        id: id,
        position: screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        }),
        data: { feature: null },
        origin: [0, 0.5],
        type: NodeTypes.feature
      }

      setNodes((nds) => nds.concat(newNode))
      setEdges((eds) =>
        eds.concat({ id: connectingNodeId + '.to.' + id, source: connectingNodeId, target: id, type: EdgeTypes.default })
      )
    } else {
      const id = getNextFakeNodeId().toString()
      const newNode = {
        id: id,
        position: screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        }),
        data: {
          logic: {
            operator: null,
            value: '',
            id: parseInt(id),
            questionId: null
          },
          questionOptions: questionOptions,
          questionData: questions
        },
        origin: [0, 0.5],
        type: NodeTypes.logic
      }
      const newEdges = []
      const sourceNode = getNode(connectingNodeId)
      let nextSiblingNode = null
      console.debug('Add new node from connection drop source node', sourceNode, connectingNodeId, nodes, edges)
      if (sourceNode) {
        const andEdgesOnly = getAndEdges(edges)
        const orEdgesOnly = getOrEdges(edges)
        nextSiblingNode = findLastSiblingFromParent(nodes, andEdgesOnly, orEdgesOnly, sourceNode)
        console.debug('Next sibling node', nextSiblingNode)
        const [edgesForTargetSiblings] = getNewEdgesForTargetSiblingsFromJoinedGroup(
          newNode, nextSiblingNode, connectingNodeId, nodes, andEdgesOnly, orEdgesOnly, false
        )
        if (edgesForTargetSiblings.length) {
          newEdges.push(...edgesForTargetSiblings) // Note that this will only ever be nodes going to the target, as it is newly created with no siblings.
        }
        // Likewise, it is not necessary to loop through target for pre-existing and connections, as they cannot yet exist.
        console.debug('On connect end new edges.', newEdges, edgesForTargetSiblings, newNode, sourceNode)
      } else {
        console.error('Could not find source node in nodes for on connect end.', connectingNodeId, sourceNode, nodes, edges)
      }

      console.debug('On connect end new edges.', newEdges, newNode, sourceNode, nextSiblingNode)
      setNodes((nds) => nds.concat(newNode))
      setEdges((eds) => eds.concat(...newEdges))
    }
  }
}

export function onConnectUpdate (setEdges, getNode, getEdge, params, nodes, edges) {
  if (params.target?.startsWith('f.')) {
    if (params.source === 'q.0') { // Can only connect to feature nodes from target q node
      setEdges((eds) => addEdge({ ...params, type: EdgeTypes.default, id: params.source + '.to.' + params.target }, eds))
    } else {
      console.warn('Attempted to make bad connection to feature node.', params)
      notifications.show({
        id: (params.source ?? 's') + '-to-' + (params.target ?? 't'),
        color: 'yellow',
        title: 'Bad Connection Attempt',
        message: 'Can only connect the target question to features: edit the logic for other questions in their detail panel.'
      })
    }
  } else { // Targeting a logic node
    if (params.source === 'q.0') {
      console.warn('Attempted to make bad connection between target question and logic nodes.', params)
      notifications.show({
        id: (params.source ?? 's') + '-to-' + (params.target ?? 't'),
        color: 'yellow',
        title: 'Bad Connection Attempt',
        message: 'Can only connect the target question to features: connect the target question to a feature first, then connect that feature to questions or logic which should affect it.'
      })
    } else {
      let nextSiblingNode = null
      let hideTargetsPreExistingAndNodes = false
      const sourceNode = getNode(params.source)
      const targetNode = getNode(params.target)
      const newEdges = []
      const newEdgeId = params.source + '.to.' + params.target
      const andEdgesOnly = getAndEdges(edges)
      const orEdgesOnly = getOrEdges(edges)
      console.debug('onConnect source node', newEdgeId, sourceNode, params.source, params)
      if (sourceNode && targetNode) {
        nextSiblingNode = findLastSiblingFromParent(nodes, andEdgesOnly, orEdgesOnly, sourceNode)
        console.debug('onConnect source node last child sibling.', nextSiblingNode, sourceNode, targetNode)
        if (getEdge(newEdgeId) || (nextSiblingNode && getEdge(nextSiblingNode.id + '.to.' + params.target))) {
          console.warn('Existing edge found - skipping loop creation.', params, newEdgeId, nextSiblingNode)
          notifications.show({
            id: (params.source ?? 's') + '-to-' + (params.target ?? 't'),
            color: 'yellow',
            title: 'Bad Connection Attempt',
            message: 'Do not loop logic node connections.'
          })
          return
        }
        const [edgesForTargetSiblings, firstTargetSibling] = getNewEdgesForTargetSiblingsFromJoinedGroup(
          targetNode, nextSiblingNode, params.source, nodes, andEdgesOnly, orEdgesOnly
        )
        if (edgesForTargetSiblings.length) {
          newEdges.push(...edgesForTargetSiblings)
        }
        if (nextSiblingNode) {
          hideTargetsPreExistingAndNodes = firstTargetSibling // Used to hide target (or first target sibling's) pre-existing visible 'and' connections.
        }

        const [edgesForNewSiblings] = getNewAndEdgesForJoinedGroup(
          targetNode, nextSiblingNode, nodes, andEdgesOnly, orEdgesOnly
        )
        if (edgesForNewSiblings.length) {
          newEdges.push(...edgesForNewSiblings)
        }
      }
      console.debug('New edges.', newEdges, params, nextSiblingNode)
      setEdges((eds) => {
        const returnEdges = eds.concat(...newEdges)
        return hideTargetsPreExistingAndNodes
          ? returnEdges.map(ed => {
            if ((ed.type !== EdgeTypes.or) && ed.target === hideTargetsPreExistingAndNodes) {
              ed.hidden = true
            }
            return ed
          })
          : returnEdges
      })
    }
  }
}

export function onEdgesChangeUpdate (setEdges, getNode, getEdge, changes, nodes, edges) {
  const pendingVisibilityChanges = new Set()
  const allChanges = [...changes]

  for (const change of changes) {
    console.debug('Current changes.', allChanges, change)
    if (change?.type === 'remove') {
      const targetEdge = getEdge(change.id)
      if (targetEdge) {
        const sourceNode = getNode(targetEdge.source)
        console.debug('Remove edge source node', sourceNode)
        if (sourceNode) {
          const isOrEdge = targetEdge?.type === EdgeTypes.or
          const targetNode = getNode(targetEdge.target)
          const orEdgesOnly = getOrEdges(edges)
          const andEdgesOnly = getAndEdges(edges)
          const incomingConnectedNodes = getIncomers(targetNode, nodes, andEdgesOnly)
          const outgoingOrConnectedNodes = getOutgoers(targetNode, nodes, orEdgesOnly)
          console.debug('Remove edge source node connected nodes.', incomingConnectedNodes, targetEdge)
          if (isOrEdge) {
            const filteredConnectedNodes = incomingConnectedNodes.filter(connectingNode => !!getOutgoers(connectingNode, nodes, edges).filter(siblingConnectingNode => siblingConnectingNode.id === targetEdge.source).length)
            const associatedAndEdges = getConnectedEdges([...filteredConnectedNodes, targetNode], edges).filter(elem => elem.target === targetEdge.target && elem.id !== targetEdge.id)
            console.debug('Is or edge', change, targetEdge, associatedAndEdges, filteredConnectedNodes, incomingConnectedNodes)
            for (const andEdge of associatedAndEdges) {
              allChanges.push({ id: andEdge.id, type: 'remove' })
            }
            console.debug('Or edge continuing connected or nodes', change, outgoingOrConnectedNodes)
            if (outgoingOrConnectedNodes.length) {
              const associatedOrEdges = getConnectedEdges([...outgoingOrConnectedNodes, targetNode], edges).filter(elem => elem.source === targetNode.id && elem.type === EdgeTypes.or)
              const incomingConnectedOrNodes = getIncomers(targetNode, nodes, orEdgesOnly)
              console.debug('Replacing linked or edges.', associatedOrEdges, incomingConnectedOrNodes, outgoingOrConnectedNodes, targetNode)
              for (const linkedOrEdge of associatedOrEdges) {
                allChanges.push({ id: linkedOrEdge.id, type: 'remove' })
                for (const orNode of incomingConnectedOrNodes) {
                  allChanges.push({ item: { id: orNode.id + '.to.' + linkedOrEdge.target, source: orNode.id, sourceHandle: 'c', target: linkedOrEdge.target, type: EdgeTypes.or }, type: 'add' })
                }
              }
            }
          } else {
            const hasOtherAnds = !!(incomingConnectedNodes.length - 1)
            if (hasOtherAnds) { // Only delete connections between source node and target node/siblings
              const toRemoveAndConnections = new Map()
              const traverseOnNodeCallback = (node) => {
                toRemoveAndConnections.set(node.id, node)
              }
              traverseNode(nodes, orEdgesOnly, targetNode, false, traverseOnNodeCallback)
              traverseNode(nodes, orEdgesOnly, targetNode, true, traverseOnNodeCallback) // Shouldn't be necessary unless an 'and' edge is deleted when it should be hidden, such as via debug tool.
              toRemoveAndConnections.delete(targetNode.id)
              for (const toRemove of toRemoveAndConnections.values()) {
                allChanges.push({ id: sourceNode.id + '.to.' + toRemove.id, type: 'remove' })
              }
            } else {
              for (const orNode of outgoingOrConnectedNodes) {
                const connectedAndEdges = getConnectedEdges([orNode, sourceNode], edges).filter(elem => elem.target === orNode.id && elem.type !== EdgeTypes.or && elem.source === sourceNode.id)
                for (const connectedAndEdge of connectedAndEdges) {
                  pendingVisibilityChanges.add(connectedAndEdge.id)
                }
                const connectedOrEdges = getConnectedEdges([orNode, targetNode], edges).filter(elem => elem.target === orNode.id && elem.type === EdgeTypes.or && elem.source === targetNode.id)
                console.debug('Removing connected or edge(s).', connectedOrEdges, connectedAndEdges, targetEdge.target)
                for (const linkedOrEdge of connectedOrEdges) {
                  allChanges.push({ id: linkedOrEdge.id, type: 'remove' })
                }
              }
            }
          }
          console.debug('All changes.', allChanges, pendingVisibilityChanges)
        }
      }
    }
  }
  for (const change of allChanges) {
    if (change.type === 'remove') {
      if (pendingVisibilityChanges.has(change.id)) {
        console.debug('Was going to show and edge that had pending deletion - skipping.', change, allChanges, pendingVisibilityChanges)
      }
      pendingVisibilityChanges.delete(change.id)
    }
  }
  setEdges((eds) => {
    const changedEdges = applyEdgeChanges(allChanges, eds)
    console.debug('On edges change updated edges.', changedEdges, allChanges)
    if (pendingVisibilityChanges.size) {
      console.debug('Applying visibility changes.', pendingVisibilityChanges, allChanges, changedEdges)
      return changedEdges.map(edge => {
        if (pendingVisibilityChanges.has(edge.id)) {
          edge.hidden = false
        }
        return edge
      })
    }
    return changedEdges
  })
}

function getOrEdges (edges) {
  return edges.filter(edge => edge.type === EdgeTypes.or)
}

function getAndEdges (edges) {
  return edges.filter(edge => edge.type !== EdgeTypes.or)
}

/**
 * Traverse all 'and' connections to last new sibling, add new hidden and connections to target 'or' sibling(s) for each.
 *
 * Also adds 'or' edge between last new sibling and first target sibling if last new sibling exists.
 */
function getNewEdgesForTargetSiblingsFromJoinedGroup (targetNode, newSiblingsLastNode, sourceNodeId, nodes, andEdgesOnly, orEdgesOnly, findTargetSiblings = true) {
  const newEdges = []
  const siblingsExistingAndConnectionNodeIds = newSiblingsLastNode ? getIncomingAndConnectionIds(newSiblingsLastNode, nodes, andEdgesOnly) : new Set([sourceNodeId])
  let firstTargetSibling = targetNode.id
  const targetSiblingNodes = new Map()
  if (findTargetSiblings) {
    const traverseOnNodeCallback = (node) => {
      targetSiblingNodes.set(node.id, node)
    }
    const traverseOnEndCallback = (node) => {
      firstTargetSibling = node.id
    }
    traverseNode(nodes, orEdgesOnly, targetNode, false, traverseOnNodeCallback)
    traverseNode(nodes, orEdgesOnly, targetNode, true, traverseOnNodeCallback, traverseOnEndCallback)
  } else {
    targetSiblingNodes.set(targetNode.id, targetNode)
  }
  for (const targetSiblingNode of targetSiblingNodes.values()) {
    for (const andNodeConnection of siblingsExistingAndConnectionNodeIds.values()) {
      newEdges.push({
        id: andNodeConnection + '.to.' + targetSiblingNode.id,
        source: andNodeConnection,
        sourceHandle: 'b',
        target: targetSiblingNode.id,
        hidden: !!newSiblingsLastNode || firstTargetSibling !== targetSiblingNode.id,
        type: andNodeConnection.startsWith('f.') ? EdgeTypes.default : EdgeTypes.and
      })
    }
  }
  if (newSiblingsLastNode) {
    newEdges.push({
      id: newSiblingsLastNode.id + '.to.' + firstTargetSibling,
      source: newSiblingsLastNode.id,
      sourceHandle: 'c',
      target: firstTargetSibling,
      type: EdgeTypes.or // 'or' connection between new sibling and first target sibling.
    })
  }
  return [newEdges, firstTargetSibling]
}

/**
 * Traverse all 'and' connections to target node, add new hidden and connections to new 'or' sibling(s) for each.
 *
 * Note that if the first return is not empty, the pre-existing and connections to target node (or first target sibling) should be hidden.
 */
function getNewAndEdgesForJoinedGroup (targetNode, newSiblingsLastNode, nodes, andEdgesOnly, orEdgesOnly) {
  const newEdges = []
  const targetsExistingAndConnectionNodes = getIncomingAndConnectionIds(targetNode, nodes, andEdgesOnly)
  let firstSibling = newSiblingsLastNode?.id
  if (newSiblingsLastNode && targetsExistingAndConnectionNodes.size) {
    const newSiblingNodes = new Map()
    const siblingOnNodeCallback = (node) => { newSiblingNodes.set(node.id, node) }
    const siblingOnEndCallback = (node) => { firstSibling = node.id }
    traverseNode(nodes, orEdgesOnly, newSiblingsLastNode, false, siblingOnNodeCallback)
    traverseNode(nodes, orEdgesOnly, newSiblingsLastNode, true, siblingOnNodeCallback, siblingOnEndCallback)

    for (const newSiblingNode of newSiblingNodes.values()) {
      for (const andNodeConnection of targetsExistingAndConnectionNodes.values()) {
        newEdges.push({
          id: andNodeConnection + '.to.' + newSiblingNode.id,
          source: andNodeConnection,
          sourceHandle: 'b',
          target: newSiblingNode.id,
          hidden: firstSibling !== newSiblingNode.id,
          type: andNodeConnection.startsWith('f.') ? EdgeTypes.default : EdgeTypes.and
        })
      }
    }
  }
  return [newEdges, firstSibling]
}

function getIncomingAndConnectionIds (node, nodes, andEdgesOnly) {
  const andConnectionNodeIds = new Set()
  for (const andConnectionNode of getIncomers(node, nodes, andEdgesOnly)) {
    andConnectionNodeIds.add(andConnectionNode.id)
  }
  return andConnectionNodeIds
}

export function findFirstSiblingFromParent (nodes, andEdges, orEdges, parentNode) {
  const siblingNodes = getOutgoers(parentNode, nodes, andEdges)
  if (siblingNodes?.length) {
    const initialSiblingNode = siblingNodes[0]
    return findFirstSibling(nodes, orEdges, initialSiblingNode)
  }
  return null
}

export function findLastSiblingFromParent (nodes, andEdges, orEdges, parentNode) {
  const siblingNodes = getOutgoers(parentNode, nodes, andEdges)
  if (siblingNodes?.length) {
    const initialLastSiblingNode = siblingNodes[siblingNodes.length - 1]
    return findLastSibling(nodes, orEdges, initialLastSiblingNode)
  }
  return null
}

export function findFirstSibling (nodes, edges, node) {
  return findEdgeNodeSibling(nodes, edges, node, true)
}

export function findLastSibling (nodes, edges, node) {
  return findEdgeNodeSibling(nodes, edges, node, false)
}

export function findEdgeNodeSibling (nodes, edges, node, reverse = true) {
  let edgeNodeSibling = null
  traverseNode(nodes, edges, node, reverse, null, (activeNode) => { edgeNodeSibling = activeNode })
  return edgeNodeSibling
}

export function traverseNode (nodes, edges, node, reverse = true, onNodeCallback = null, onEndPath = null) {
  onNodeCallback?.(node)
  const newToTraverse = reverse ? getIncomers(node, nodes, edges) : getOutgoers(node, nodes, edges)

  for (const linkedNode of newToTraverse) {
    traverseNode(nodes, edges, linkedNode, reverse, onNodeCallback, onEndPath)
  }
  if (!newToTraverse.length) {
    onEndPath?.(node)
  }
}

export function newConnectionValid (connection, nodes, edges) {
  const andEdges = getAndEdges(edges)
  const orEdges = getOrEdges(edges)
  const target = nodes.find((node) => node.id === connection.target)
  const hasCycle = (node, checkOr = false, allowReverse = true, allowForward = true, visited = new Set()) => {
    if (visited.has(node.id)) {
      return false
    }
    visited.add(node.id)

    if (checkOr) {
      if (allowReverse) {
        for (const orIncoming of getIncomers(node, nodes, orEdges)) {
          if (orIncoming.id === connection.source) {
            return true
          }
          if (hasCycle(orIncoming, checkOr, allowReverse, false, visited)) {
            return true
          }
        }
      }
      if (allowForward) {
        for (const orOutgoing of getOutgoers(node, nodes, orEdges)) {
          if (orOutgoing.id === connection.source) {
            return true
          }
          if (hasCycle(orOutgoing, checkOr, false, allowForward, visited)) {
            return true
          }
        }
      }
    }

    for (const outgoing of getOutgoers(node, nodes, andEdges)) {
      if (outgoing.id === connection.source) {
        return true
      }
      if (hasCycle(outgoing, false, false, false, visited)) {
        return true
      }
    }
    return false
  }

  if (target.id === connection.source) {
    return false
  }
  return !hasCycle(target, true)
}
