/* eslint react/prop-types: 0 */
import { Button, Fieldset, FileInput, Group, Select, SimpleGrid, Text } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconHeadphones, IconMovie, IconPhoto } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createMedia } from '../../../js/api/media_repository'
import { formatToOptions } from '../../../js/util/DataUtil'
import { mediaTypeIdToExtensions, mediaTypeIdToMimeTypes, mediaTypeIdToTypes } from './MediaHooks'
import RatioInputs from './RatioInputs'
import NameInput from '../../forms/NameInput'
import DescriptionInput from '../../forms/DescriptionInput'
import { MediaType } from '../../../js/generated/enums/MediaType';
import NoticeAlert from '../../core/Alert/NoticeAlert'

export default function MediaCreate ({ onSuccess, onCancel }) {
  const [icon, setIcon] = useState(<IconPhoto size={16} />)
  const [maxSize, setMaxSize] = useState('10MB')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [objectURL, setObjectURL] = useState(/** @type {?string} **/ null)
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      identifier: '',
      description: '',
      type: '1',
      file: null,
      height: null,
      width: null
    },
    validate: {
      identifier: isNotEmpty('Name cannot be empty'),
      file: value => value == null ? 'Please choose a file' : null,
      width: (value, values) => mediaTypeIdToTypes[values.type] === MediaType.Image && (value === null || isNaN(value) || value < 1) ? 'Invalid width' : null,
      height: (value, values) => mediaTypeIdToTypes[values.type] === MediaType.Image && (value === null || isNaN(value) || value < 1) ? 'Invalid height' : null
    }
  })

  const formRef = useRef(form) // Not for use while rendering.

  useEffect(() => {
    formRef.current = form
  }, [form])

  const submit = values => {
    setIsSubmitting(true)

    createMedia(values)
      .then(() => {
        showNotification({
          message: 'The media was successfully uploaded!',
          color: 'success',
          autoClose: 3000
        })
        onSuccess(navigate)
      })
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error uploading this media',
            color: 'red'
          })
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  const resize = (width, height) => {
    form.setFieldValue('height', height ?? form.values.height)
    form.setFieldValue('width', width ?? form.values.width)
  }

  useEffect(() => {
    if (form.values.file) {
      const newObjectURL = URL.createObjectURL(form.values.file)
      setObjectURL(newObjectURL)

      return () => {
        URL.revokeObjectURL(newObjectURL)
      }
    }
  }, [form.values.file])

  useEffect(() => {
    let current = true
    // Get width and height from image only
    if (objectURL && mediaTypeIdToTypes[form.values.type] === MediaType.Image) {
      const img = new Image()
      img.onload = () => {
        console.debug('Img loaded.', current, objectURL)
        if (current) {
          formRef.current.setFieldValue('width', img.width)
          formRef.current.setFieldValue('height', img.height)
        }
      }
      img.src = objectURL
    } else if (objectURL && form.values.type) {
      const video = document.createElement('video')
      video.onloadedmetadata = (event) => {
        console.debug('Video/Audio metadata loaded', video?.videoWidth, video?.videoHeight, event)
        if (current && video) {
          console.warn(
            'Width and height for non-image files not implemented.',
            { width: video.videoWidth, height: video.videoHeight }
          ) // TODO discuss with team whether we want all videos to continue being forced into a 640w x 360h box?
          // formRef.current.setFieldValue('width', video.videoWidth)
          // formRef.current.setFieldValue('height', video.videoHeight)
        }
      }
      video.src = objectURL
      video.load()
    }
    return () => {
      current = false
    }
  }, [objectURL, form.values.type])

  return (
    <>
      <form onSubmit={form.onSubmit(values => submit(values))}>
        <SimpleGrid cols={1}>
          <Fieldset w='50%' legend='Media options'>
            <Select
                label='Type'
                data={formatToOptions(mediaTypeIdToTypes)}
                {...form.getInputProps('type')}
                onChange={value => {
                  switch (value) {
                    case 0:
                      setIcon(<IconHeadphones size={16}/>)
                      setMaxSize('50MB')
                      break;
                    case 1:
                      setIcon(<IconPhoto size={16}/>)
                      setMaxSize('10MB')
                      break;
                    case 2:
                      setIcon(<IconMovie size={16}/>)
                      setMaxSize('50MB')
                      break;
                  }
                  form.setFieldValue('file', null)
                  setObjectURL(null)
                  form.setFieldValue('height', null)
                  form.setFieldValue('width', null)
                  form.setFieldValue('type', value)
                }}
                required
            />
            <NameInput form={form} path='identifier' required />
            <DescriptionInput form={form} />
            <NoticeAlert title='' variant='outline'>
              <Text>
                Files with the following extensions are allowed: <strong>{mediaTypeIdToExtensions[form.values.type].map((ext, i, arr) => `*.${ext}${i === arr.length - 1 ? '' : ', '}`)}</strong>
              </Text>
              <Text>Files must be less than <strong>{maxSize}</strong>.</Text>
            </NoticeAlert>
            <FileInput
              placeholder='Pick file'
              label='File'
              required
              {...form.getInputProps('file')}
              accept={mediaTypeIdToMimeTypes[form.values.type]}
              leftSection={icon}
            />
            <Group gap='xs'>
              <Button type='submit' color='success' loading={isSubmitting}>Save</Button>
              <Button color='gray.6' onClick={() => onCancel(navigate)}>Cancel</Button>
            </Group>
          </Fieldset>
          {(mediaTypeIdToTypes[form.values.type] === MediaType.Image && !!form.values.file && !!objectURL) &&
            <RatioInputs width={form.values.width ?? 1} height={form.values.height ?? 1} onChange={resize} file={objectURL} h='40rem' />
          }
          </SimpleGrid>
      </form>
    </>
  )
}
