/* eslint react/prop-types: 0 */
import { Box, Button, Grid, Group, Space, Title } from '@mantine/core';
import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useIsAdmin } from '../CyclesHooks';
import { useProgressDetail } from './StageProgressesHooks';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { LockableIcon } from '../summaries/LockableIcon';
import { ApplicantSummaryCard } from '../summaries/ApplicantSummaryCard';
import { EmailSummaryCard } from '../summaries/EmailSummaryCard';
import { CycleSummaryCard } from '../summaries/CycleSummaryCard';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { CyclePassSummaryCard } from '../summaries/CyclePassSummaryCard';
import { useCycle } from '../../CycleHooks';
import { useStageProgressLockMutation } from './StageProgressesApi';
import { ToggleLockButton } from '../nav/ToggleLockButton';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';

export function CycleStageProgressDetail () {
  // TODO [timeout forgiveness] button to offer limited retake?
  const { cycleId, progressId } = useParams()
  const [progress, progressQuerying] = useProgressDetail(progressId, cycleId, !progressId)
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()
  const querying = !!(progressQuerying || adminLoading || cycleQuerying)
  return (
    <>
      {!!querying && <CardLoadingPlaceholder />}
      {!querying && !progress && <CardQueryError />}
      {!querying && !!progress && <ProgressCard progress={progress} cycleId={cycleId} cycle={cycle} isAdmin={isAdmin} />}
    </>
  )
}

const cardColSpan = { base: 12, md: 6, lg: 3 }
const cardGridGutter = { base: 'xs', sm: 'md', md: 'xl', lg: 'xxl' }

function ProgressCard ({ progress, cycleId, cycle, isAdmin = false }) {
  console.debug('Stage progress card updating', { progress, isAdmin })
  const progressId = progress.id
  const cyclePass = progress.cycle_pass
  const passId = cyclePass.id
  const invitesLink = !(cycleId && passId) ? '/' : generatePath(CyclePassRoute.CyclePassInvitesList, { cycleId, passId })
  const previewLink = !(progressId && isAdmin) ? null : generatePath('/invites/manage/progresses/:progressId/preview', { progressId })

  return (
    <>
      <Title>Progress #{progressId ?? 'Id Missing'} Detail</Title>
      <Space h='xl' />
      <Box>
        <Group>
          <LockableIcon item={progress} />
          <Button variant='subtle' size='sm' component={Link} to={invitesLink}>Manage Invites</Button>
          {!!previewLink && <Button variant='subtle' size='sm' component='a' href={previewLink} target='_blank'>View Responses</Button>}
          {!!isAdmin && <ToggleProgressLockButton cycleId={cycleId} progressId={progressId} locked={progress.locked} />}
        </Group>
        <Space h='xl' />
        <Grid justify='flex-start' align='stretch' gutter={cardGridGutter} grow>
          <Grid.Col span={cardColSpan}><CyclePassSummaryCard cyclePass={cyclePass} cycleId={cycleId} isAdmin={isAdmin} /></Grid.Col>
          {!!cyclePass.applicant && <Grid.Col span={cardColSpan}><ApplicantSummaryCard applicant={cyclePass.applicant} isAdmin={isAdmin} /></Grid.Col>}
          {!!cyclePass.email && <Grid.Col span={cardColSpan}><EmailSummaryCard email={cyclePass.email} isAdmin={isAdmin} /></Grid.Col>}
          {!!cycle && <Grid.Col span={cardColSpan}><CycleSummaryCard cycle={cycle} isAdmin={isAdmin} /></Grid.Col>}
        </Grid>
        <Space h='xl' />
        <PassActivityTabs activityTarget={progress} activityTargetType='Progress' isAdmin={isAdmin} />
      </Box>
    </>
  )
}

function ToggleProgressLockButton ({ cycleId, progressId, locked = true }) {
  const [toggleLock, { isLoading: toggleLockProcessing }] = useStageProgressLockMutation()
  return (
    <ToggleLockButton
      toggleLock={toggleLock}
      toggleLockProcessing={toggleLockProcessing}
      cycleId={cycleId}
      itemId={progressId}
      itemIdName='progressId'
      locked={locked}
    />
  )
}
