import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { QueryState } from '../../../../assessment/util';
import { addNewPage, reportVersionChanged, setProgressesMatchingResponseTableIds } from './distributionReportPagesSlice';
import { shallowEqual } from 'react-redux';

export const getMatchingProgresses = createAsyncThunk('distributionResponses/getMatchingProgresses', async (navigationParams, { dispatch, getState, rejectWithValue }) => {
  const state = getState()
  const questionId = navigationParams?.questionId
  const answerId = navigationParams?.answerId ? parseInt(navigationParams.answerId) : null
  const matchingSelectionProgressIds = answerId ? new Set(selectLimitedAnswerResponsesByQuestionId(state, questionId).filter(response => response.answerId === answerId || (response.answerIds.includes(answerId))).map(response => response.progressId)) : null
  const freeResponseKey = navigationParams?.answerKey ?? null
  const hasFreeResponseKey = freeResponseKey !== null
  const preprocessedResponseKey = hasFreeResponseKey ? preprocessResponse(freeResponseKey) : null
  const matchingFreeResponseProgressIds = hasFreeResponseKey ? new Set(selectFreeAnswerResponsesByQuestionId(state, questionId).filter(response => preprocessResponse(response.additionalAnswer) === preprocessedResponseKey).map(response => response.progressId)) : null
  const progressIds = [...(new Set([...(matchingSelectionProgressIds ?? []), ...(matchingFreeResponseProgressIds ?? [])]))]
  console.info(
    'Returning ids for matching progresses',
    { progressIds, matchingSelectionProgressIds, matchingFreeResponseProgressIds, navigationParams, state }
  )
  if (!progressIds.length) {
    return rejectWithValue({ error: false, progressIds: progressIds, navigationParams: navigationParams })
  }
  dispatch(setProgressesMatchingResponseTableIds({ progressIds }))
  return progressIds
})

/**
 * @typedef {object} ProgressRespondentAnswer
 * @property {int} id
 * @property {int} questionId
 * @property {?int} answerId
 * @property {int[]} answerIds
 * @property {?string} additional
 * @property {?string} additionalAnswer
 * @property {?string} additionalComment,
 * @property {int} progressId
 */

/**
 * @type {EntityAdapter<{ProgressRespondentAnswer}, int>}
 */
const distributionResponsesAdapter = createEntityAdapter({
  sortComparer: (a, b) => (a.id - b.id)
})

const initialState = distributionResponsesAdapter.getInitialState({
  status: QueryState.Idle,
  error: null
})

const distributionResponsesSlice = createSlice({
  name: 'distributionResponses',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportVersionChanged.pending, (state) => {
        distributionResponsesAdapter.removeAll(state)
      })
      .addCase(addNewPage, (state, action) => {
        distributionResponsesAdapter.upsertMany(state, action.payload.responses)
      })
  }
})

// export const {} = distributionResponsesSlice.actions

export default distributionResponsesSlice.reducer

export const {
  selectAll: selectAllDistributionResponses,
  selectById: selectDistributionResponsesById,
  selectIds: selectDistributionResponseIds,
  selectEntities: selectDistributionResponseMap
} = distributionResponsesAdapter.getSelectors(state => state.distributionResponses)

const maxMemoSize = 300 // Can be thought of as max number of questions to store state for

export const selectResponsesByQuestionId = createSelector(
  [selectAllDistributionResponses, (state, questionId) => questionId],
  (responses, questionId) => responses.filter(response => response.questionId === questionId)
)

export const selectAnsweredOrSkippedResponseIdsByQuestionId = createSelector(
  [selectResponsesByQuestionId],
  (responses) => responses.map(response => response.id)
)

export const selectNumberAnsweredOrSkippedResponsesByQuestionId = createSelector(
  [selectAnsweredOrSkippedResponseIdsByQuestionId],
  (responses) => responses.length
)

export const selectAnyAnswerResponseIdsByQuestionId = createSelector(
  [selectResponsesByQuestionId],
  (responses) => responses.filter(response => !!(response.answerId || response.answerIds.length || response.additionalAnswer)).map(response => response.id)
)

export const selectNumberAnyAnswerResponsesByQuestionId = createSelector(
  [selectAnyAnswerResponseIdsByQuestionId],
  (responses) => responses.length
)

export const selectLimitedAnswerResponsesByQuestionId = createSelector(
  [selectResponsesByQuestionId],
  (responses) => responses.filter(response => !!(response.answerId || response.answerIds.length))
)

export const selectMatchingResponseIdsForQuestion = createSelector(
  [selectLimitedAnswerResponsesByQuestionId],
  (responses) => responses.map(response => response.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: maxMemoSize } }
)

export const selectNumberMatchingResponsesForAnswers = createSelector(
  [selectLimitedAnswerResponsesByQuestionId, (state, questionId, answers) => answers],
  (responses, answers) => Object.fromEntries(
    answers.map(answer => answer.id).map(answerId => [
      answerId,
      responses.filter(response => response.answerId === answerId || (response.answerIds.includes(answerId))).length
    ])
  ),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: maxMemoSize } }
)

export const selectFreeAnswerResponsesByQuestionId = createSelector(
  [selectResponsesByQuestionId],
  (responses) => responses.filter(response => !!response.additionalAnswer)
)

export const selectFreeAnswersByQuestionId = createSelector(
  [selectFreeAnswerResponsesByQuestionId],
  (responses) => Object.fromEntries(responses.map(response => [response.id, response.additionalAnswer])),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: maxMemoSize } }
)

const preprocessResponse = value => value.trim().toLowerCase()

export const selectFreeResponseKeysForQuestionId = createSelector(
  [selectFreeAnswersByQuestionId, (state, questionId, answers) => answers],
  (responses, answers) =>
    Object.entries(responses)
      .map(([responseId, responseValue]) => [responseId, responseValue, preprocessResponse(responseValue)])
      .reduce(
        (accum, [responseId, responseValue, preprocessedValue]) =>
          ({ ...accum, [preprocessedValue]: accum[preprocessedValue] ?? responseValue }),
        Object.fromEntries(answers.map(answer => [preprocessResponse(answer.content), answer.content]))
      ),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: maxMemoSize } }
)

export const selectFreeResponsesForQuestionId = createSelector(
  [selectFreeAnswersByQuestionId, selectFreeResponseKeysForQuestionId],
  (responses, preprocessedToSharedOriginal) =>
    Object.entries(responses)
      .map(([responseId, responseValue]) => [responseId, responseValue, preprocessedToSharedOriginal[preprocessResponse(responseValue)]])
      .reduce(
        (accum, [responseId, responseValue, sharedResponseKey]) => ({ ...accum, [sharedResponseKey]: (accum[sharedResponseKey] ?? 0) + 1 }),
        {}
      ),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: maxMemoSize } }
)
