import React from 'react'
import { Container } from '@mantine/core'
import Base from './Base'
import PropTypes from 'prop-types'
// TODO: I don't know if this is the best place to import this
import '@mantine/charts/styles.css';

export default function Content ({ children }) {
  return (
    <Base>
      <Container fluid px='xs'>
        {children}
      </Container>
    </Base>
  )
}

Content.propTypes = {
  children: PropTypes.node
}
