/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Button, Group, Paper, SimpleGrid, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { previewTextTemplate } from '../../../js/api/applicant_repository'
import { getTemplate } from '../../../js/api/text_template_repository'
import DateSelection from './DateSelection'
import TemplateSelection from './TemplateSelection'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function TextMessage ({ form, index, previewApplicant, templates }) {
  const [preview, setPreview] = useState(false)
  const [body, setBody] = useState('')
  const [previewBody, setPreviewBody] = useState('')

  useEffect(() => {
    if (preview) {
      previewTextTemplate(previewApplicant, { body }).then(preview => {
        setPreviewBody(preview.body)
      })
    }
  }, [previewApplicant, preview])

  return (
    <Paper shadow='sm' p='lg' radius='md' withBorder>
      <SimpleGrid cols={1}>
        <Title order={6} c='dimmed'>Text Message</Title>
        {!preview && <Editor form={form} index={index} previewApplicant={previewApplicant} body={body} setBody={setBody} templates={templates} setPreview={setPreview}/>}
        {preview && <Preview form={form} index={index} previewBody={previewBody} setPreview={setPreview}/>}
      </SimpleGrid>
    </Paper>
  )
}

function Editor ({ form, index, previewApplicant, body, setBody, templates, setPreview }) {
  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' disabled={!previewApplicant} onClick={() => setPreview(true)}>Preview</Button>
        <Button size='xs' variant='light' color='red' onClick={() => form.removeListItem('messages', index)}>Remove</Button>
      </Group>
      <DateSelection form={form} index={index}/>
      <TemplateSelection form={form} index={index} templates={templates} getTemplate={getTemplate} onChange={setBody}/>
      <div>{body}</div>
    </SimpleGrid>
  )
}

function Preview ({ form, index, previewBody, setPreview }) {
  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' onClick={() => setPreview(false)}>Edit</Button>
        <Button size='xs' variant='light' color='red' onClick={() => form.removeListItem('messages', index)}>Remove</Button>
      </Group>
      <Title order={6}>Send At</Title>
      <Text>{dayjs(form.values.messages[index].send_at).format('MM/DD/YYYY')}</Text>
      <Title order={6}>Content</Title>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewBody) }}></div>
    </SimpleGrid>
  )
}
