/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';
import { useCyclePassesTable, useCyclesPasses, useLazyCyclesPasses } from './CyclePassHooks';

export const CyclePassTable = memo(function CyclePassTable ({ cycleId, namespace = 'cycles-passes' }) {
  const navigate = useNavigate()
  const [cyclePasses] = useLazyCyclesPasses(namespace, cycleId)

  const handleRowClicked = useCallback((row) => {
    console.debug('Cycle pass table row clicked.', row)
    if (row === undefined) return true
    const passId = row.dataset.rowid
    const intPassId = parseInt(passId)
    const cyclePass = (cyclePasses?.items?.filter(c => c.id === intPassId) ?? [])[0] ?? null
    if (!cyclePass) {
      console.warn('Unable to find pass matching clicked id.', passId, cycleId, cyclePass, cyclePasses)
      return true
    }
    console.debug('Navigating to cycle pass detail', { passId, cycleId, cyclePass, row })
    const path = generatePath(CyclePassRoute.CyclePassDetail, { cycleId: cycleId.toString(), passId: passId })
    console.debug('Generated path for pass detail', { path, cyclePass })
    navigate(path)
  }, [cyclePasses, cycleId, navigate])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useCyclePassesTable()

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <CyclePassTableDataProvider cycleId={cycleId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </CyclePassTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function CyclePassTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCyclesPasses(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating CyclePassTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Cycle passes table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
