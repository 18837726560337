import { Button, Container, Flex, Loader, Modal, Paper, SimpleGrid, Stack, Text } from '@mantine/core'
import { IconCirclePlus } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import BulletChartEditor from '../../../charts/BulletChartEditor'
import ScoreCard from './ScoreCard'
import { create, deleteInfo, getBatteryInfo, updateInfos, updateInfo } from '../../../../js/api/battery_repository'
import StringUtil from '../../../../js/util/StringUtil'
import { showNotification } from '@mantine/notifications'
import { getZoneString } from '../../../../js/charts/BulletChart'
import styles from './ScoreApp.module.scss'
import WarningAlert from '../../../core/Alert/WarningAlert'
import Content from '../../../layout/Content'

export default function BatteryScoreApp () {
  const battery = JSON.parse(document.getElementById('battery-score-app-container').dataset.battery)
  const wordings = JSON.parse(document.getElementById('battery-score-app-container').dataset.wordings)
  const [infos, setInfos] = useState(battery.battery_infos)
  const [editing, setEditing] = useState(null)
  const [wordingsUsed, setWordingsUsed] = useState(infos.map((info) => info.name))
  const [saving, setSaving] = useState(false)

  const findInfoIndex = (id) => _.findIndex(infos, ['id', id])

  const submitZones = (response) => {
    setInfos(prev => {
      const newData = [...prev]
      newData[findInfoIndex(editing.id)] = {
        ...newData[findInfoIndex(editing.id)],
        zones: response
      }
      return newData
    })
    setEditing(null)
  }

  const updateWording = (id, wording) => {
    setInfos(prev => {
      const newData = [...prev]
      newData[findInfoIndex(id)].name = wording
      return newData
    })
  }

  const saveAll = () => {
    setSaving(true)
    const formValues = infos.map(info => {
      if (info.id.toString().startsWith('new-')) {
        return {
          name: info.name,
          importance: info.importance,
          zones: getZoneString(info.zones),
          notes: info.notes
        }
      } else {
        return {
          id: info.id,
          name: info.name,
          importance: info.importance,
          zones: getZoneString(info.zones),
          notes: info.notes
        }
      }
    })

    updateInfos(battery.id, { infos: formValues }).then(() => {
      setSaving(false)
      showNotification({
        message: `Successfully saved all infos in ${battery.name} battery`,
        color: 'green'
      })
      const event = new Event('batteryinfo:saveall')
      window.dispatchEvent(event)
    }, () => {
      setSaving(false)
      showNotification({
        message: `Failed to save all infos in ${battery.name} battery`,
        color: 'red'
      })
    })
  }

  const saveCard = (id, values, callback = () => {}) => {
    id.toString().includes('new-')
      ? create(battery.id, values).then(response => getBatteryInfo(battery.id, response.headers['x-resource-id']).then(batteryInfo => {
        setInfos(prev => {
          const newData = [...prev]
          const index = findInfoIndex(id)
          newData.splice(index, 1, batteryInfo)
          return newData
        })
        callback(null, { batteryName: battery.name, context: 'add' })
      }))
      : updateInfo(battery.id, id, { id, ...values }).then(response => {
        callback(null, { batteryName: battery.name, context: 'update' })
      })
  }

  const removeCard = (id, callback) => {
    if (id.toString().startsWith('new-')) {
      setInfos(prev => {
        const newData = [...prev]
        _.remove(newData, (d) => d.id === id)
        return newData
      })
      callback()
      return
    }
    deleteInfo(battery.id, id).then(() => {
      setInfos(prev => {
        const newData = [...prev]
        _.remove(newData, (d) => d.id === id)
        return newData
      })
      callback()
    })
  }

  const addCard = () => {
    setInfos(prev => {
      const newData = [...prev]
      newData.push({
        id: `${StringUtil.makeId()}`,
        name: null,
        importance: 8,
        notes: '',
        zones: [
          { start: 0, end: 30, color: 'r' },
          { start: 30, end: 60, color: 'y' },
          { start: 60, end: 100, color: 'g' }
        ]
      })
      return newData
    })
  }

  useEffect(() => {
    setWordingsUsed(infos.map((info) => info.name))
  }, [infos])

  return (
    <Content>
      <Flex justify='center' mb='xs'>
        <WarningAlert>Changes made on this page will affect any cycles that are currently using this battery.</WarningAlert>
      </Flex>
      <Container>
        <Flex direction='column' gap='sm'>
          <SimpleGrid cols={4}>
            {infos.map((info, index) => {
              const _wordings = [...wordings]
              _.remove(_wordings, w => wordingsUsed.includes(w) && w !== info.name)
              return (
                <ScoreCard
                  key={`score-${index}`}
                  info={info}
                  wordings={_wordings}
                  onSubmit={saveCard}
                  onChartClick={setEditing}
                  onDelete={removeCard}
                  onUpdateWording={updateWording}
                />
              )
            })}
            {wordingsUsed.length < wordings.length &&
              <Paper shadow='md' p='md' className={[styles.scoreCard]}>
                <Button
                  w='100%'
                  h='100%'
                  variant='outline'
                  className={[styles.addScoreButton]}
                  onClick={() => addCard()}
                  p='lg'
                  >
                  <Stack align='center'>
                    <Text size='lg'>Add new score</Text>
                    <IconCirclePlus size='2rem' />
                  </Stack>
                </Button>
              </Paper>
            }
          </SimpleGrid>
          <Flex>
            <Button color='green' onClick={() => saveAll()}>{saving ? <Loader size='xs' color='white' /> : 'Save all'}</Button>
          </Flex>
        </Flex>
      </Container>
      <Modal opened={!!editing} onClose={() => setEditing(null)} title='Edit zones' size='50rem' keepMounted>
        <BulletChartEditor editing={editing} onSubmit={submitZones} />
      </Modal>
    </Content>
  )
}
