import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useAccount } from '../../util/Hooks'
import ClientDetails from './ClientDetails'
import EditUser from './EditUser'
import UserHome from './UserHome'
import { UserExport } from './UserExport'
import Content from '../../layout/Content'

export default function UserApp () {
  const account = useAccount()

  return (
    <Content>
      <BrowserRouter>
        <Routes>
          <Route path='/clients/users' element={<UserHome access={account.access} />} />
          <Route path='/clients/users/create' element={<ClientDetails />} />
          <Route path='/clients/users/:id/edit' element={<EditUser />} />
          <Route path='/clients/users/export' element={<UserExport/>} />
        </Routes>
      </BrowserRouter>
    </Content>
  )
}
