/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import { Checkbox, Paper, Grid, NumberInput, TextInput } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { QuestionStateUpdate } from './QuestionsState';
import { hasFixedAnswers } from '../../../../js/modules/Build/Assessment/QuestionType';
import { QuestionType } from '../../../../js/generated/enums/QuestionType';
import { MAX_SCORE_PRECISION } from './util';
import { useDispatch } from 'react-redux';
import { associatedAnswersChanged } from './UnpublishedQuestionLogic/unpublishedLogicSlice';

/**
 * @param {Answer} answer
 * @param {QuestionType} questionType
 * @param {boolean} hasDependentLogic
 * @param dragging
 * @param dispatch
 */
const AnswerEditor = memo(function AnswerEditor ({ answer, position, hasDependentLogic, questionType, dragging, dispatch }) {
  const [content, setContent] = useState(answer.content)
  const contentDirty = useRef(null)
  const reduxDispatch = useDispatch()
  const editContentRef = useClickOutside(() => {
    window.clearTimeout(contentDirty.current)
    if (answer.content !== content) {
      dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }
  })

  const updateAnswerText = useCallback(() => {
    window.clearTimeout(contentDirty.current)
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
    if (hasDependentLogic) {
      reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
    }
  }, [answer.id, answer.questionId, content, dispatch, reduxDispatch, hasDependentLogic])

  const bufferAnswerText = useCallback((event) => {
    window.clearTimeout(contentDirty.current)
    const initialContent = event.currentTarget.value
    setContent(initialContent)
    contentDirty.current = window.setTimeout(() => {
      dispatch({
        type: QuestionStateUpdate.UpdateAnswer,
        answerId: answer.id,
        questionId: answer.questionId,
        newAttributes: { content: initialContent }
      })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }, 1000);
  }, [answer.id, answer.questionId, setContent, dispatch, reduxDispatch, hasDependentLogic])

  const isInterviewOrFillInBlank = useMemo(() => (questionType === QuestionType.FillInTheBlank) || (questionType === QuestionType.Interview), [questionType])
  const isChooseAll = questionType === QuestionType.ChooseAllThatApply
  // TODO use of LeftSection in text input is prevented by styling from elsewhere in site, likely foundation.

  const newPlaceholder = 'New Answer'
  const answerIsNew = content === newPlaceholder
  return (
    <Paper
      bg={dragging ? 'blue.1' : 'gray.2'}
      mt='xs'
      p='xs'
    >
      <Grid justify='flex-start' align='center'>
        <Grid.Col span={isInterviewOrFillInBlank ? 12 : (isChooseAll ? 10 : 8)}>
          <TextInput
            placeholder={answerIsNew ? 'New Answer' : 'Blank Answer'}
            value={answerIsNew ? '' : content}
            onChange={bufferAnswerText}
            onBlur={updateAnswerText}
            disabled={hasFixedAnswers(questionType) && !isInterviewOrFillInBlank}
            ref={editContentRef}
          />
        </Grid.Col>
        {isInterviewOrFillInBlank
          ? null
          : (
          <Grid.Col span={2}>
            <NumberInput
              min={0}
              value={answer.score}
              decimalScale={MAX_SCORE_PRECISION}
              placeholder='points'
              step={1}
              onChange={(value) => dispatch({ type: QuestionStateUpdate.SetAnswerScore, answerId: answer.id, questionId: answer.questionId, newScore: (value || 0) }) }
              disabled={hasFixedAnswers(questionType)}
            />
          </Grid.Col>
            )}
        {(isInterviewOrFillInBlank || isChooseAll)
          ? null
          : (
          <Grid.Col span={2}>
            <Checkbox
              checked={!!answer.correct}
              onChange={(event) => dispatch({ type: QuestionStateUpdate.SetCorrectAnswer, answerId: answer.id, questionId: answer.questionId, newScore: (event.currentTarget.checked ? 1 : 0) })}
            />
          </Grid.Col>
            )}
      </Grid>
    </Paper>
  )
})

export default AnswerEditor
