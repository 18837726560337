/**
 * @typedef {object} DragSelectState
 * @property {Map.<string: Object>} lists <stringKey: ListConfig>
 * @property {Map.<string: Object>} entries <stringCid: ListEntry>
 */

/**
 * @param {Object} lists
 * @returns {DragSelectState}
 */
export function createInitialDragSelectState (lists = {}) {
  let index = 0
  const parsedListConfigs = new Map()
  const parsedListEntries = new Map()
  for (const [listKey, config] of Object.entries(lists)) {
    const parsedList = { label: config.label, index: config.index, key: listKey }
    parsedListConfigs.set(listKey, parsedList)
    for (const entry of config.data) {
      const parsedEntry = { label: entry.label, id: entry.cid, cid: entry.cid, value: index.toString(), list: listKey, highlighted: false }
      parsedListEntries.set(parsedEntry.id, parsedEntry)
      index += 1
    }
  }
  return {
    lists: parsedListConfigs,
    entries: parsedListEntries
  }
}

export const DragSelectUpdate = Object.freeze({
  ToggleHighlight: 'toggle-item-highlight',
  HighlightList: 'add-item-highlights',
  RemoveHighlights: 'remove-item-highlights',
  MoveItemsBetweenLists: 'move-items-between-lists',
  MoveItemWithinList: 'move-item-within-list',
  MoveHighlightedBetweenLists: 'move-highlighted-items-between-lists',
  SetListItems: 'set-list-items'
})

/**
 * @param {*&DragSelectState} state
 * @param {object} action
 * @param {DragSelectUpdate} action.type
 * @param {string?} action.targetList
 * @param {string?} action.defaultList
 * @param {string?} action.fromList
 * @param {(int|null)?} action.targetIndex
 * @param {string?} action.targetItem
 * @param {string?} action.additionalItem
 * @param {array?} action.items
 * @returns {*&DragSelectState}
 */
export function dragSelectStateReducer (state, action) {
  switch (action.type) {
    case DragSelectUpdate.ToggleHighlight: {
      const targetEntryKey = action.targetItem
      const stateEntries = new Map(state.entries)
      const targetEntry = stateEntries.get(targetEntryKey)
      if (!targetEntry) {
        console.error('Unknown list entry - skipping state update.', { targetEntryKey, stateEntries })
        return state
      }
      stateEntries.set(targetEntryKey, { ...targetEntry, highlighted: !targetEntry.highlighted })
      return { ...state, entries: stateEntries }
    }
    case DragSelectUpdate.HighlightList: {
      const targetListKey = action.targetList
      const stateEntries = new Map(state.entries)
      let anyChanged = false
      state.entries.forEach((value, key, map) => {
        if ((value.list === targetListKey) && !value.highlighted) {
          stateEntries.set(key, { ...value, highlighted: true })
          anyChanged = true
        }
      })
      return anyChanged ? { ...state, entries: stateEntries } : state
    }
    case DragSelectUpdate.RemoveHighlights: {
      const targetListKey = action.targetList
      const stateEntries = new Map(state.entries)
      let anyChanged = false
      state.entries.forEach((value, key, map) => {
        if ((value.list === targetListKey) && value.highlighted) {
          stateEntries.set(key, { ...value, highlighted: false })
          anyChanged = true
        }
      })
      return anyChanged ? { ...state, entries: stateEntries } : state
    }
    case DragSelectUpdate.MoveItemWithinList: {
      const targetListKey = action.targetList
      const targetIndex = action.targetIndex ?? -1
      const stateEntryValues = [...state.entries.values()]
      const additionalItemId = action.additionalItem ?? null
      const additionalIds = additionalItemId ? [additionalItemId] : []
      const itemIds = new Set(
        [
          ...additionalIds,
          ...((action.items ?? stateEntryValues.filter(elem => (elem.list === targetListKey) && elem.highlighted))
            .map(elem => elem.id))
        ]
      )

      const newStateEntries = new Map()
      let currentTargetIndex = 0
      let fakeOffsetIndex = 0
      let targetIndexFound = false
      let targetItemPlaced = !additionalItemId
      for (const value of stateEntryValues) {
        if (currentTargetIndex === targetIndex) {
          currentTargetIndex -= fakeOffsetIndex
          for (const movedId of itemIds) { // Move all highlighted or provided to target index in original list.
            if ((currentTargetIndex === targetIndex) && additionalItemId) {
              newStateEntries.set(additionalItemId, state.entries.get(additionalItemId))
              currentTargetIndex += 1
              targetItemPlaced = true
            }
            if (movedId !== additionalItemId) {
              newStateEntries.set(movedId, state.entries.get(movedId))
              currentTargetIndex += 1
            }
          }
          if (!targetItemPlaced) {
            newStateEntries.set(additionalItemId, state.entries.get(additionalItemId))
            targetItemPlaced = true
          }
          targetIndexFound = true
        }

        if (!itemIds.has(value.id)) {
          newStateEntries.set(value.id, value)
          if (value.list === targetListKey) {
            currentTargetIndex += 1
          }
        } else if ((value.list === targetListKey) && !targetIndexFound) {
          currentTargetIndex += 1
          if (value.id !== additionalItemId) {
            fakeOffsetIndex += 1
          }
        }
      }

      if (!targetIndexFound) { // Append to end of target list.
        currentTargetIndex -= fakeOffsetIndex
        for (const movedId of itemIds) {
          if ((currentTargetIndex === targetIndex) && additionalItemId) {
            newStateEntries.set(additionalItemId, state.entries.get(additionalItemId))
            currentTargetIndex += 1
            targetItemPlaced = true
          }
          if (movedId !== additionalItemId) {
            newStateEntries.set(movedId, state.entries.get(movedId))
            currentTargetIndex += 1
          }
        }
        if (!targetItemPlaced) {
          newStateEntries.set(additionalItemId, state.entries.get(additionalItemId))
        }
      }

      return itemIds.size ? { ...state, entries: newStateEntries } : state
    }
    case DragSelectUpdate.MoveItemsBetweenLists: {
      const targetListKey = action.targetList
      const fromListKey = action.fromList
      const targetIndex = action.targetIndex ?? -1
      const stateEntryValues = [...state.entries.values()]
      const additionalItemId = action.additionalItem ?? null
      const additionalIds = additionalItemId ? [additionalItemId] : []
      const itemIds = new Set(
        [
          ...additionalIds,
          ...((action.items ?? stateEntryValues.filter(elem => (elem.list === fromListKey) && elem.highlighted))
            .map(elem => elem.id))
        ]
      )

      const newStateEntries = new Map()
      let currentTargetIndex = 0
      let fakeOffsetIndex = 0
      let targetIndexFound = false
      let targetItemPlaced = !additionalItemId
      for (const value of stateEntryValues) {
        if (currentTargetIndex === targetIndex) {
          currentTargetIndex -= fakeOffsetIndex
          for (const movedId of itemIds) { // Move all highlighted or provided to target index in target list.
            if ((currentTargetIndex === targetIndex) && additionalItemId) {
              newStateEntries.set(additionalItemId, { ...state.entries.get(additionalItemId), list: targetListKey })
              currentTargetIndex += 1
              targetItemPlaced = true
            }
            if (movedId !== additionalItemId) {
              newStateEntries.set(movedId, { ...state.entries.get(movedId), list: targetListKey })
              currentTargetIndex += 1
            }
          }
          if (!targetItemPlaced) {
            newStateEntries.set(additionalItemId, { ...state.entries.get(additionalItemId), list: targetListKey })
            targetItemPlaced = true
          }
          targetIndexFound = true
        }

        if (!itemIds.has(value.id)) {
          newStateEntries.set(value.id, value)
          if (value.list === targetListKey) {
            currentTargetIndex += 1
          }
        } else if ((value.list === targetListKey) && !targetIndexFound) {
          currentTargetIndex += 1
          if (value.id !== additionalItemId) {
            fakeOffsetIndex += 1
          }
        }
      }

      if (!targetIndexFound) { // Append to end of target list.
        currentTargetIndex -= fakeOffsetIndex
        for (const movedId of itemIds) {
          if ((currentTargetIndex === targetIndex) && additionalItemId) {
            newStateEntries.set(additionalItemId, { ...state.entries.get(additionalItemId), list: targetListKey })
            currentTargetIndex += 1
            targetItemPlaced = true
          }
          if (movedId !== additionalItemId) {
            newStateEntries.set(movedId, { ...state.entries.get(movedId), list: targetListKey })
            currentTargetIndex += 1
          }
        }
        if (!targetItemPlaced) {
          newStateEntries.set(additionalItemId, { ...state.entries.get(additionalItemId), list: targetListKey })
        }
      }

      return itemIds.size ? { ...state, entries: newStateEntries } : state
    }
    case DragSelectUpdate.MoveHighlightedBetweenLists: {
      const targetListKey = action.targetList
      const fromListKey = action.fromList
      const stateEntries = new Map(state.entries)
      const movedStateEntries = new Map()
      state.entries.forEach((value, key, map) => {
        if (value.list === fromListKey) {
          if (value.highlighted) {
            movedStateEntries.set(key, { ...value, list: targetListKey })
            stateEntries.delete(key)
          }
        }
      })
      return movedStateEntries.size ? { ...state, entries: new Map([...stateEntries.entries(), ...movedStateEntries.entries()]) } : state
    }
    case DragSelectUpdate.SetListItems: {
      const targetListKey = action.targetList
      const defaultListKey = action.defaultList
      const itemKeys = new Set(action.items.map(elem => elem.cid))
      const targetStateEntries = new Map()
      const defaultedStateEntries = new Map()
      const unchangedStateEntries = new Map()
      let anyChanged = false
      for (const key of itemKeys) {
        const value = state.entries.get(key)
        const itemMovedLists = value.list !== targetListKey
        targetStateEntries.set(key, itemMovedLists ? { ...value, list: targetListKey } : value)
        anyChanged = anyChanged || itemMovedLists
      }
      state.entries.forEach((value, key, map) => {
        if (!itemKeys.has(key)) {
          if (value.list === targetListKey) {
            defaultedStateEntries.set(key, { ...value, list: defaultListKey })
            anyChanged = true
          } else {
            unchangedStateEntries.set(key, value)
          }
        }
      })
      return anyChanged
        ? { ...state, entries: new Map([...targetStateEntries.entries(), ...unchangedStateEntries.entries(), ...defaultedStateEntries.entries()]) }
        : state
    }
    default: {
      console.error('Unknown drag select state update action type - skipping update.', action, state)
      return state
    }
  }
}
