/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, Center, Group, Modal, Space, Stack, Text, Title } from '@mantine/core';
import { AssessmentResponse, getResponsePrimaryDataKey } from '../../js/generated/enums/AssessmentResponse';
import { IconAlertCircle, IconInfoCircle, IconRefresh, IconSend } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { selectSubmissionComplete, selectSubmissionUnexpectedlyFailed } from './pagesSlice';

export const LoadAssessmentError = memo(function LoadAssessmentError () {
  const loadingError = useSelector(state => state.assessments.error)
  const loadingErrorData = useSelector(state => state.assessments.errorData)
  const errorComponent = getErrorComponent(loadingError, loadingErrorData)
  return (
    <Center>
      <Space h='xl' />
      {errorComponent}
    </Center>
  )
})

export const SubmissionResponse = memo(function SubmissionResponse ({ submit }) {
  const success = useSelector(state => selectSubmissionComplete(state))
  const fail = useSelector(state => selectSubmissionUnexpectedlyFailed(state))
  const [open, setOpen] = useState(false)
  const openedSinceLastAttemptRef = useRef(false)

  useEffect(() => {
    if (success || fail) {
      if (!open && !openedSinceLastAttemptRef.current) {
        openedSinceLastAttemptRef.current = true
        setOpen(true)
      }
    } else if (!open && openedSinceLastAttemptRef.current) {
      openedSinceLastAttemptRef.current = false
    }
  }, [success, fail, open, setOpen])

  return (
    <SubmissionResponseModal opened={open} close={() => setOpen(false)}>
      <SubmissionResponseContent submit={submit} />
    </SubmissionResponseModal>
  )
})

const SubmissionResponseModal = memo(function SubmissionResponseModal ({ opened, close, children }) {
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      centered
      overlayProps={{ blur: 3, backgroundOpacity: 0.7 }}
      withinPortal
      zIndex={900}
    >
      {children}
    </Modal>
  )
})

const SubmissionResponseContent = memo(function SubmissionResponseContent ({ submit }) {
  const responseType = useSelector(state => state.pages.lastResponseType)
  const responseData = useSelector(state => state.pages.lastResponseData)
  const errorComponent = getErrorComponent(responseType, responseData, submit)

  return (
    <Box w='100%'>
      {errorComponent}
    </Box>
  )
})

export function getErrorComponent (errorType, errorData, retry = null) {
  switch (errorType) {
    case AssessmentResponse.AlreadyComplete: {
      return <AlreadyCompleteError errorData={errorData} />
    }
    case AssessmentResponse.ProctorFreeRequired: {
      return <ProctorFreeRequiredError errorData={errorData} />
    }
    case AssessmentResponse.Timeout: {
      return <TimeoutError errorData={errorData} />
    }
    case AssessmentResponse.IncompatibleAssessmentType:
    case AssessmentResponse.Redirect: {
      return <RedirectError errorData={errorData} responseType={errorType} />
    }
    case AssessmentResponse.Unauthorized:
    case AssessmentResponse.LoggedOut: {
      return <UnauthorizedError errorData={errorData} responseType={errorType} />
    }
    default: {
      return <UnknownError errorData={errorData} retry={retry} />
    }
  }
}

function AlreadyCompleteError ({ errorData }) {
  console.debug('Already complete error component updating.', errorData)
  const supportEmail = errorData?.data?.[getResponsePrimaryDataKey(AssessmentResponse.AlreadyComplete)] ?? 'support@hirescore.com'
  return (
    <Stack>
      <Alert variant='light' color='red' title='Our records indicate that you have already taken this assessment.' icon={<IconAlertCircle />} fz='xl'>
        <>
          <Text fz='lg'>No further action is needed from you at this time.</Text>
        </>
      </Alert>
      <Center>
        <Box w='90%'>
          <Text fz='md' c='dark.5'>If you think you have received this message in error, please contact us at <Text c='blue.6' component='a' href={'mailto:' + supportEmail}>{supportEmail}</Text></Text>
          <Space h='md' />
          <Text fz='md' c='dark.5'>Thank you!</Text>
        </Box>
      </Center>
    </Stack>
  )
}

function ProctorFreeRequiredError ({ errorData }) {
  console.debug('ProctorFreeRequired error component updating.', errorData)
  const [clickedOnce, setClickedOnce] = useState(false)
  const proctorFreeLink = errorData?.data?.[getResponsePrimaryDataKey(AssessmentResponse.ProctorFreeRequired)] ?? ''
  return (
    <Stack>
      <Alert variant='light' color='red' title='Proctor Free Required.' icon={<IconAlertCircle />} fz='xl'>
        <Text fz='lg'>To complete this assessment, you need to be proctored by ProctorFree.</Text>
      </Alert>
      <Space h='md' />
      <Group justify='center' grow>
        <Button
          color='blue.6'
          onClick={() => {
            if (!clickedOnce) {
              setClickedOnce(true)
            }
            window.open(proctorFreeLink, '_blank')
          }}
          size='lg'
        >
          Start ProctorFree
        </Button>
      </Group>
      <Group justify='center' grow>
        { clickedOnce && (
          <Button color='success' onClick={() => window.location.reload()} size='lg' rightSection={<IconRefresh />}>Refresh</Button>
        )}
      </Group>
      <Space h='md' />
      <Text fz='lg' c='dark.5' ta='center'>Refresh the page when ProctorFree is ready.</Text>
    </Stack>
  )
}

function TimeoutError ({ errorData }) {
  const redirectLink = errorData?.data?.[getResponsePrimaryDataKey(AssessmentResponse.Timeout)] ?? ''
  console.debug('Timeout error component updating.', errorData)
  return (
    <Stack>
      <Alert variant='light' color='red' title='Time Limit Reached' icon={<IconAlertCircle />} fz='xl'>
        <Text fz='lg'>You ran out of time on this assessment.</Text>
      </Alert>
      <Space h='md' />
      <Group justify='center' grow>
        <Button
          color='success'
          component='a'
          href={redirectLink}
          size='lg'
        >
          Continue
        </Button>
      </Group>
    </Stack>
  )
}

function RedirectError ({ errorData, responseType }) {
  const redirectLink = errorData?.data?.[getResponsePrimaryDataKey(responseType)] ?? ''
  console.debug('Redirect error component updating.', responseType, redirectLink, errorData)

  useEffect(() => {
    if (redirectLink) {
      window.location.replace(redirectLink)
    } else {
      notifications.show({
        id: 'refresh-page',
        title: 'Refresh Page',
        message: 'Error when fetching redirect link for next resource - please try refreshing the page.',
        color: 'yellow',
        withBorder: true
      })
    }
  }, [redirectLink])

  return (
    <Stack>
      <Alert variant='light' color='yellow' title='Redirecting to Resource' icon={<IconInfoCircle />} fz='xl'>
        <Text fz='lg'>Redirecting you to the next step - one moment!</Text>
      </Alert>
      <Space h='md' />
      <Group justify='center' grow>
        <Button
          color='success'
          component='a'
          href={redirectLink}
          size='lg'
        >
          Continue
        </Button>
      </Group>
    </Stack>
  )
}

function UnauthorizedError ({ errorData, responseType }) {
  const onClick = useCallback(() => {
    window.location.reload()
  }, [])

  console.debug('Unauthorized error component updating.', { errorData, responseType })

  return (
    <Stack>
      <Title>We&apos;re having trouble authorizing your access to this assessment - please try clicking the button below.</Title>
      <Space h='md' />
      <Group justify='center' grow>
        <Button
          color='success'
          onClick={onClick}
          size='lg'
          rightSection={<IconRefresh />}
        >
          Refresh
        </Button>
      </Group>
    </Stack>
  )
}

function UnknownError ({ errorData, retry = null }) {
  const onClick = useCallback(() => {
    if (retry) {
      retry()
    } else {
      window.location.reload()
    }
  }, [retry])

  console.debug('Unknown error component updating.', errorData, !!retry)

  return (
    <Stack>
      <Title>Error {retry ? 'submitting' : 'requesting'} assessment - please try clicking the button below.</Title>
      <Space h='md' />
      <Group justify='center' grow>
        <Button
          color='success'
          onClick={onClick}
          size='lg'
          rightSection={retry ? <IconSend /> : <IconRefresh />}
        >
          {retry ? 'Retry' : 'Refresh'}
        </Button>
      </Group>
    </Stack>
  )
}
