/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useRef } from 'react'
import { Button, Group, MultiSelect, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectParamsFilters } from './paramsSlice';
import _ from 'lodash';

/**
 * @typedef {Object} Filter
 * @property {string} id - Used as the key and form fields name.
 * @property {string} label - Used as component's label.
 * @property {?array} data? Used for select components' data.
 * @property {mixed} initialValue? - Optional initial value to set. If present the field will reset to
 * this value when the tables reset button is clicked.
 * @property {FormComponentType} type - The type of component rendered for the filter.
 */

/**
 * Each filter must contain an id, label, and type {FormComponentType} property. Select types must include data.
 * Each filter may also provide an initialValue.
 * @example
 * //       type: FormComponentType.MultiSelect,
 * //       data: filteredJobs,
 * //       label: 'Jobs',
 * //       id: 'jobs',
 * //       initialValue: []
 *
 * The form has the name "react-table-form-filters". This allows the state to be controlled outside of this component
 * via createFormActions(). This is how the form is reset when the ReactTables reset button is clicked.
 * @see https://mantine.dev/form/actions/ Form Action Docs
 * @example
 * // const formFiltersAction = createFormActions('react-table-form-filters')
 * // formFiltersAction.reset()
 * @param {Array.<Filter>} formFilters
 * @param {function} onSubmit - Calls the ReactTables batchUpdateSearchParams on submit
 * @param {string} namespace - namespace identifying the corresponding query params
 * @param {string} submitLabel - default 'Search'
 */
export default function FormFilters ({ formFilters, onSubmit, namespace, submitLabel = 'Search' }) {
  const initialValues = useMemo(() => {
    return parseFilterInitialValues(formFilters)
  }, [formFilters]);

  const syncedFilters = useSyncedFormValues(initialValues, namespace)

  const form = useForm({
    name: 'react-table-form-filters',
    initialValues: initialValues
  })

  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  useEffect(() => {
    formRef.current.setValues(syncedFilters)
  }, [syncedFilters]);

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      {formFilters.map((filter) => {
        return <GenericFormComponent key={filter.id} id={filter.id} type={filter.type} data={filter.data} label={filter.label} form={form} />
      })}
      <Group justify='flex-start' mt='md'>
        <Button type="submit">{submitLabel}</Button>
      </Group>
    </form>
  )
}

FormFilters.propTypes = {
  formFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  submitLabel: PropTypes.string
}

export function useSyncedFormValues (formFilters, namespace) {
  const paramsFilters = useSelector(state => selectParamsFilters(state, namespace))

  return useMemo(() => {
    if (paramsFilters) {
      const filters = {}
      for (const [filter, value] of Object.entries(paramsFilters)) {
        // Make sure the filter is a possible form field and has a different value
        if (Object.hasOwn(formFilters, filter) && !_.isEqual(value, formFilters[filter])) {
          // Handle array filters with a single value
          if (formFilters[filter]?.constructor === Array && value.constructor !== Array) {
            filters[filter] = [value]
          } else {
            filters[filter] = value
          }
        }
      }
      return filters
    }
    return null
  }, [paramsFilters, formFilters])
}

function parseFilterInitialValues (filters) {
  const values = {}
  filters.forEach((filter) => {
    if (typeof filter.initialValue !== 'undefined') {
      values[filter.id] = filter.initialValue
    }
  })

  return values
}

function GenericFormComponent ({ id, form, type, data = [], label = '' }) {
  return (
    <>
      {type === FormComponentType.MultiSelect && <MultiSelectFormComponent id={id} form={form} data={data} label={label} />}
      {type === FormComponentType.Text && <TextFormComponent id={id} form={form} label={label} />}
    </>
  )
}

function MultiSelectFormComponent ({ id, form, data, label = '' }) {
  return (
    <MultiSelect
      label={label}
      data={data}
      searchable
      clearable
      {...form.getInputProps(id)}
    />
  )
}

function TextFormComponent ({ id, form, label = '' }) {
  return (
    <TextInput label={label} {...form.getInputProps(id)} />
  )
}

export const FormComponentType = Object.freeze({
  MultiSelect: 'MultiSelect',
  Text: 'Text'
})
