import { Box, Highlight } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';

export default function Logo ({ fz = 38, gray = 'var(--mantine-color-dark-4)' }) {
  return (
    <Box display='inline-block'>
      <Highlight c='blue.6' fz={fz} fw='bolder' highlight='score' highlightStyles={{ backgroundColor: 'transparent', color: gray }}>hirescore</Highlight>
    </Box>
  )
}

Logo.propTypes = {
  fz: PropTypes.number,
  gray: PropTypes.string
}
