import React from 'react'
import { IconSquareX } from '@tabler/icons-react'
import { Alert, Button, Text } from '@mantine/core'

const ErrorLoading = () => {
  const reload = () => {
    window.location.reload()
  }

  return (
    <Alert title='Error loading' icon={<IconSquareX />} color='red'>
      <Text mb={10}>Sorry, we are having some trouble. Try reloading the page.</Text>
      <Button onClick={reload} color='red.6'>Reload page</Button>
    </Alert>
  )
}

export default ErrorLoading
