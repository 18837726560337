import React from 'react'
import Content from '../layout/Content'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SkillbuilderCyclesHome from './SkillbuilderCyclesHome'

export default function SkillbuilderCyclesApp () {
  return (
    <Content>
      <BrowserRouter>
        <Routes>
          <Route path='/skillbuilder/cycles' element={<SkillbuilderCyclesHome />} />
        </Routes>
      </BrowserRouter>
    </Content>
  )
}
