/* eslint react/prop-types: 0 */
import React, { useContext, useMemo } from 'react'
import ReactTable, { ReactTableContextsProvider } from './../../core/ReactTable/ReactTable'
import Content from '../../layout/Content'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData, selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useGetHiringViewCyclesQuery } from '../../../redux/query/hire/hiringViewsApi.slice'
const namespace = 'hiringViewDetails'

export default function DetailTable () {
  const hiringViewId = document.getElementById('hiring-view-detail-container').getAttribute('data-hiring-view-id')

  return (
    <Content>
      <BrowserRouter>
        <Routes>
          <Route path='/hire/report-results/hiring-views/:id/view' element={<WrappedHiringViewDetailTable hiringViewId={hiringViewId} />} />
        </Routes>
      </BrowserRouter>
    </Content>
  )
}

const filters = []
const actions = []
const defaultFilters = {}
const defaultHiddenColumns = []

export function WrappedHiringViewDetailTable ({ hiringViewId }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell: { value, row } }) => {
          return <a href={'/cycles/' + row.original.id + '/results'}>{value}</a>
        }
      },
      {
        Header: 'Organization',
        accessor: 'location.organization.name'
      },
      {
        Header: 'Location',
        accessor: 'location.name'
      }
    ],
    []
  )

  const handleRowClicked = row => {
    if (typeof row === 'undefined') return true
    const id = row.dataset.rowid
    window.location.href = `/cycles/${id}/results`
    return true
  }

  return (
    <div className='HiringViewDetail' style={{ marginBottom: '20px' }}>
      <h3>Cycles</h3>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <HiringViewCyclesTableDataProvider id={hiringViewId}>
          <ReactTable actions={actions} filters={filters} onRowClick={handleRowClicked} notDynamic />
        </HiringViewCyclesTableDataProvider>
      </ReactTableContextsProvider>
    </div>
  )
}

export function useHiringViewCyclesQuery (namespace, id, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetHiringViewCyclesQuery({ ...queryParams, id }, { skip })
  return [collection ?? null, querying]
}

export function HiringViewCyclesTableDataProvider ({ id, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useHiringViewCyclesQuery(namespace, id, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating HiringViewCyclesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Hiring view cycles table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
