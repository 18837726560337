/* eslint react/prop-types: 0 */
import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Box, Button, Space, Tooltip } from '@mantine/core';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider,
  useUpdateNodeInternals
} from 'reactflow';
import 'reactflow/dist/style.css';
import './flow-nodes.css'
import { BaseModal } from './BaseModal';
import { stripContentTags } from './UnpublishedQuestionLogic/util';
import { useDispatch } from 'react-redux';
import {
  mapLogicsToNodes,
  onUnmount,
  newConnectionValid,
  onConnectEndUpdate,
  onConnectUpdate,
  onEdgesChangeUpdate,
  deleteSelected
} from './UnpublishedQuestionLogic/nodeCallbacks';
import { nodeComponentTypes } from './UnpublishedQuestionLogic/Nodes';
import { edgeComponentTypes } from './UnpublishedQuestionLogic/Edges';

/**
 * @param {Question} question
 * @param {int} questionIndex
 * @param {Map.<int: Question>} questions
 * @param dispatch
 */
export const QuestionsLogicEditor = memo(function QuestionsLogicEditor ({ question, questionIndex, questions, dispatch }) {
  const [editingLogic, setEditingLogic] = useState(false)

  return (
    <>
      <Space h='md' />
      <Box w='100%'>
        <Button maw='22vw' onClick={() => setEditingLogic(true)}>
          {question.logic.length ? 'Edit Logic' : 'Add Logic'}
        </Button>
        <BaseModal
          title='Edit Logic'
          showing={editingLogic}
          onClose={() => setEditingLogic(false)}
        >
          <Box w='100%' miw='80vw' h='80vh' mih='80vh' mah='80vh'>
            <ReactFlowProvider>
              <QuestionsLogicFlow
                question={question}
                questionIndex={questionIndex}
                questions={questions}
                dispatch={dispatch}
              />
            </ReactFlowProvider>
          </Box>
        </BaseModal>
      </Box>
    </>
  )
})

const defaultViewportOptions = { x: 0, y: 200, zoom: 0.7 }
const attributionOptions = { hideAttribution: true }

export const QuestionsLogicFlow = memo(function QuestionsLogicFlow ({ question, questionIndex, questions, dispatch }) {
  const questionOptions = useMemo(() => {
    return [...questions.values()].filter((question, index) => (index < questionIndex) && question.isNumbered).map(question => { return { value: question.id.toString(), label: stripContentTags(question.content) } }).reverse()
  }, [questions, questionIndex])

  const [parsedNodes, parsedEdges] = useMemo(() => {
    console.info('Parsing question logic to nodes/edges.', question.id, question.logic, question, questions)
    return mapLogicsToNodes(question.logic, questions, question, questionIndex, questionOptions)
  }, [questions, questionIndex, question, questionOptions])

  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const { screenToFlowPosition, getNodes, getEdges, getNode, getEdge, fitView } = useReactFlow()
  const originalNodes = useRef(parsedNodes)
  const originalEdges = useRef(parsedEdges)
  const connectingNodeId = useRef(null)
  const reduxDispatch = useDispatch()
  const updateNodeInternals = useUpdateNodeInternals()
  const allowUpdate = useRef(false)
  const [selected, setSelected] = useState([])
  const fitViewRef = useRef(fitView)

  useEffect(() => {
    if (fitView !== fitViewRef.current) {
      fitViewRef.current = fitView
    }
  }, [fitView])

  useEffect(() => {
    console.debug('Initial node layout effect updating', parsedNodes, parsedEdges, fitViewRef.current)
    setNodes(parsedNodes)
    setEdges(parsedEdges)
    if (parsedNodes.length > 1) {
      console.debug('Calling fit view due to node length.', parsedNodes.length)
      fitViewRef.current({ padding: 500, minZoom: 0.1, nodes: parsedNodes })
    }
  }, [parsedNodes, parsedEdges])

  const isValidConnection = useCallback((connection) => {
    const nodes = getNodes()
    const edges = getEdges()
    const valid = newConnectionValid(connection, nodes, edges)
    console.debug('New connection valid?', valid, connection, nodes, edges)
    return valid
  }, [getNodes, getEdges])

  useEffect(() => {
    console.debug('Loaded on-unmount updater for questions logic editor. Remaining dormant until unmount for store update.', question.id)
    const currentOriginalNodes = originalNodes.current
    const currentOriginalEdges = originalEdges.current

    return () => {
      const currentNodes = getNodes()
      const currentEdges = getEdges()
      onUnmount(
        currentNodes,
        currentEdges,
        currentOriginalNodes,
        currentOriginalEdges,
        allowUpdate.current,
        questions,
        question,
        dispatch,
        reduxDispatch
      )
    }
  }, [dispatch, getEdges, getNodes, question, questions, reduxDispatch])

  useEffect(() => {
    const timeoutId = allowUpdate.current
      ? null
      : window.setTimeout(() => {
        console.debug('Updating node internals for original nodes.', originalNodes.current, originalEdges.current)
        allowUpdate.current = true
        updateNodeInternals(originalNodes.current.map(elem => elem.id))
      }, 500)

    return () => window.clearTimeout(timeoutId)
  }, [updateNodeInternals])

  const onNodesChange = useCallback((changes) => {
    console.debug('On Nodes change', changes) // Deletion of many nodes/edges at once may introduce unexpected states, untested.
    setNodes((nds) => applyNodeChanges(changes, nds))
  }, [])

  const onEdgesChange = useCallback((changes) => {
    console.debug('On Edges change', changes)
    onEdgesChangeUpdate(setEdges, getNode, getEdge, changes, nodes, edges)
  }, [edges, nodes, getEdge, getNode])

  const onConnectStart = useCallback((_, { nodeId, handleId, handleType }) => {
    console.debug('On connect start', nodeId, handleId, handleType)
    connectingNodeId.current = nodeId
  }, [])

  const onConnectEnd = useCallback((event) => {
    console.debug('On connect end', event)
    if (!connectingNodeId.current) {
      return
    }
    console.debug('Creating new connected node.', connectingNodeId.current)
    const connectingNode = connectingNodeId.current
    onConnectEndUpdate(
      screenToFlowPosition,
      setNodes,
      setEdges,
      getNode,
      nodes,
      edges,
      event,
      connectingNode,
      questions,
      questionOptions
    )
    connectingNodeId.current = null
  }, [screenToFlowPosition, nodes, edges, getNode, questionOptions, questions])

  const onConnect = useCallback((params) => {
    console.debug('On Connect', params)
    connectingNodeId.current = null
    onConnectUpdate(setEdges, getNode, getEdge, params, nodes, edges)
  }, [nodes, edges, getEdge, getNode])

  const onSelectionChange = useCallback((params) => {
    const selectedNodes = (params?.nodes ?? []).map(node => ({ id: node.id, isNode: true }))
    const selectedEdges = (params?.edges ?? []).map(edge => ({ id: edge.id, isNode: false }))
    console.debug('On select change', selectedNodes, selectedEdges, params?.nodes, params?.edges, params)
    setSelected([...selectedNodes, ...selectedEdges])
  }, [])

  const deleteButtonOnClick = useCallback(() => {
    deleteSelected(selected, getEdges, onEdgesChange, onNodesChange)
  }, [selected, onEdgesChange, onNodesChange, getEdges])

  console.debug('Editor nodes | edges', nodes, edges)
  return (
    <ReactFlow
      nodes={nodes}
      onNodesChange={onNodesChange}
      edges={edges}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      defaultViewport={defaultViewportOptions}
      fitView={parsedNodes.length > 1}
      nodeTypes={nodeComponentTypes}
      edgeTypes={edgeComponentTypes}
      isValidConnection={isValidConnection}
      onSelectionChange={onSelectionChange}
      proOptions={attributionOptions}
      minZoom={0.1}
    >
      <Background />
      <Controls />
      <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
        <Tooltip label={selected.length ? 'Backspace to delete' : 'Select to delete'}>
          <Button
            color='red'
            variant={selected.length ? 'light' : 'transparent'}
            onClick={deleteButtonOnClick}
            disabled={!selected.length}
          >
            Delete
          </Button>
        </Tooltip>
      </div>
    </ReactFlow>
  )
})
