const nullFlagFields = {
  collectDemographics: 'filterCollectDemographics',
  proctored: 'filterProctored'
}

export function injectNullFlags (params) {
  if (!params) {
    return params
  }
  const newParams = { ...params }
  for (const [k, v] of Object.entries(nullFlagFields)) {
    if (Object.prototype.hasOwnProperty.call(params, k) && ((params[k] === null) || (params[k] === 'null'))) {
      newParams[v] = true
    }
  }
  return newParams
}
