/* eslint react/prop-types: 0 */
import React from 'react'
import { Anchor, Flex, Text } from '@mantine/core'

/**
 * Component that displays a password label with an optional reset password link.
 * @param {?string} href Link to reset password page.
 * @param {string} link Text for the reset password anchor.
 * @returns {JSX.Element} A password label with an optional reset password link.
 */
export default function PasswordLabel ({
  href = null,
  link = 'Forgot Password?'
}) {
  return (
    <Flex justify='space-between' wrap='wrap'>
      <Text size='lg'>Password</Text>
      {href && <Anchor href={href} fw={700} tabIndex={-1}>{link}</Anchor>}
    </Flex>
  )
}
