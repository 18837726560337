const AssessmentProtection = Object.freeze({ // TODO auto-generate this file.
  Unprotected: 'Unprotected',
  Protected: 'Protected',
  AssessorRecordsResults: 'Assessor Records Results'
})

function protectionFromId (assessmentProtectionId) {
  switch (assessmentProtectionId) {
    case 2:
      return AssessmentProtection.AssessorRecordsResults
    case 1:
      return AssessmentProtection.Protected
    default:
      return AssessmentProtection.Unprotected
  }
}

function idFromProtection (assessmentProtection) {
  switch (assessmentProtection) {
    case AssessmentProtection.AssessorRecordsResults:
      return 2
    case AssessmentProtection.Protected:
      return 1
    default:
      return 0
  }
}

export { AssessmentProtection, protectionFromId, idFromProtection }
