import { useParams } from 'react-router-dom';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Group, Space } from '@mantine/core';
import { CycleNavButton, InviteHomeButton } from '../nav/CycleNavButton';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { CyclePassTable } from './CyclePassTable';
import React from 'react';

export function CyclePassesList () {
  const { cycleId } = useParams()

  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Passports' />
      <Group>
        <InviteHomeButton />
        <CycleNavButton cycleId={cycleId} route={CyclePassRoute.CycleOpenInvitesList} name='Public Links' />
        <CycleNavButton cycleId={cycleId} route={CyclePassRoute.CycleInvitesList} name='Phase Invites' />
      </Group>
      <Space h='md' />
      <CyclePassTable cycleId={cycleId} />
    </>
  )
}
