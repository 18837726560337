import React from 'react'
import Content from '../layout/Content'
import { Button, Center, Group, SimpleGrid, Title } from '@mantine/core'
import { GoBackButton } from '../core/GoBackButton'

export default function RequireAuthentication () {
  const path = document.getElementById('require-authentication-container').getAttribute('data-path')

  const login = function () {
    window.location = path
  }

  return (
    <Content>
      <Center>
        <SimpleGrid>
          <Title order={4}>To access your account details, you will need to authenticate again.</Title>
          <Group spacing='xs' justify='center'>
            {/* eslint-disable-next-line no-return-assign */}
            <Button color='blue.6' onClick={login}>Login</Button>
            <GoBackButton />
          </Group>
        </SimpleGrid>
      </Center>
    </Content>
  )
}
