/* eslint react/prop-types: 0 */
import React, { useContext, useMemo } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable';
import { useBatteriesQuery, useBatteryTable } from './BatteryHooks';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';

const namespace = 'batteries'

export function BatteryTable () {
  const tableProps = useBatteryTable()
  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = tableProps

  return (
    <>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <BatteriesTableDataProvider>
          <ReactTable {...otherTableProps} />
        </BatteriesTableDataProvider>
      </ReactTableContextsProvider>
    </>
  )
}

export function BatteriesTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useBatteriesQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating BatteriesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Batteries table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
