import { Text, View } from '@react-pdf/renderer'
import React, { useContext } from 'react'
import Styles from '.././styles/interview'
import { PdfOptions } from '../PdfPage'

export default function EndOfInterviewPage () {
  const styles = Styles(useContext(PdfOptions)).cover
  return (
    <View style={styles.coverPage}>
      <Text style={styles.coverText}>&quot;End of Interview&quot;</Text>
    </View>
  )
}
