import axios from 'axios'

export function deleteFile (id) {
  return axios({
    method: 'delete',
    url: `/api/v1/files/${id}`
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}
