import React from 'react'
import BatteryManagement from '../../hire/batteries/BatteryManagement'
import { useParams } from 'react-router-dom'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import {
  useAddHiringTemplateBatteryMutation,
  useEditHiringTemplateBatteryMutation,
  useDeleteHiringTemplateBatteryMutation,
  useGetHiringTemplateQuery,
  useToggleHiringTemplateBatteryMutation
} from '../../../redux/query/build/hiringTemplatesApi.slice'

export default function HiringTemplateBatteryManagement () {
  const { id } = useParams()

  const { data: hiringTemplate, error, isLoading } = useGetHiringTemplateQuery(id)

  const [addBattery] = useAddHiringTemplateBatteryMutation()
  const [editBattery] = useEditHiringTemplateBatteryMutation()
  const [deleteBattery] = useDeleteHiringTemplateBatteryMutation()
  const [toggleBattery] = useToggleHiringTemplateBatteryMutation()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <BatteryManagement
      linkedBatteries={hiringTemplate.hiring_template_batteries}
      triggers={hiringTemplate.triggers}
      addBattery={json => addBattery({ id, ...json }).unwrap()}
      editBattery={(batteryId, json) => editBattery({ id, batteryId, ...json }).unwrap()}
      deleteBattery={batteryId => deleteBattery({ id, batteryId }).unwrap()}
      toggleBattery={batteryId => toggleBattery({ id, batteryId }).unwrap()}
    />
  )
}
