/* eslint react/prop-types: 0 */
import React from 'react'

import styles from './SelectTable.module.scss'

export default function SelectTable ({ applicants, callback }) {
  return (
    <div className={styles.container}>
      {
        applicants.map((applicant) => (
          <div key={`applicant-${applicant.id}`} className={styles.element} onClick={() => callback(applicant)}>
            <p className={styles.name}>{applicant.first_name} {applicant.last_name}</p>
            <p className={styles.email}>{applicant.contact_information.email}</p>
          </div>
        ))
      }
    </div>
  )
}
