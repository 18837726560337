import React, { useState } from 'react'
import { Button, Group, SimpleGrid, TextInput } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { sendTwoFactorCode } from '../../js/api/account_repository'
import PropTypes from 'prop-types'

export default function TextTwoFactorAuthentication ({ account, setType, apiBasePath }) {
  const [sent, setSent] = useState(false)
  const [code, setCode] = useState('')
  const [phone, setPhone] = useState(account.two_factor_number || account.phone_number)

  const sendCode = () => {
    showNotification({
      id: 'text-2fa-send-code-notification',
      message: 'Generating code...',
      loading: true,
      autoClose: false,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'text', action: 'send', resend: false, phone: phone })
      .then(() => {
        updateNotification({
          id: 'text-2fa-send-code-notification',
          message: 'Check your text messages for the code!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setSent(true)
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'text-2fa-send-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const resendCode = () => {
    showNotification({
      id: 'text-2fa-resend-code-notification',
      message: 'Generating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'text', action: 'send', resend: true, phone: phone })
      .then(() => {
        updateNotification({
          id: 'text-2fa-resend-code-notification',
          message: 'The code has been sent again. Check your text messages!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'text-2fa-resend-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const validate = () => {
    showNotification({
      id: 'text-2fa-validate-code-notification',
      message: 'Validating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'text', action: 'validate', code: code, phone: phone })
      .then(() => {
        updateNotification({
          id: 'text-2fa-validate-code-notification',
          message: 'The code has been sent successfully validated!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setType('text')
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'text-2fa-validate-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  return (
    <>
      {!sent && (
          <SimpleGrid cols={1} verticalSpacing='xl'>
            <TextInput label='Phone number' value={phone} onChange={ev => setPhone(ev.target.value)}/>
            <Group>
              <Button onClick={() => sendCode()}>Send code</Button>
            </Group>
          </SimpleGrid>
      )}
      {sent && (
          <SimpleGrid cols={1} verticalSpacing='xl'>
            <TextInput label="Write the code we've sent to your phone below" placeholder='Code' value={code} onChange={ev => setCode(ev.target.value)}/>
            <Group>
              <Button color='success' onClick={() => validate()}>Validate</Button>
              <Button onClick={() => resendCode()}>Re-send code</Button>
            </Group>
          </SimpleGrid>
      )}
    </>
  )
}

TextTwoFactorAuthentication.propTypes = {
  account: PropTypes.shape({
    two_factor_number: PropTypes.string,
    phone_number: PropTypes.string
  }),
  setType: PropTypes.func,
  apiBasePath: PropTypes.string
}
