import { paramsToQuery } from '../util/queries';
import { ApiAbstractIds, apiSlice, ApiTags } from '../api';

export const organizationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: (params = { limit: 0 }) => `clients${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Organizations, id: id })),
              { type: ApiTags.Organizations, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Organizations, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetOrganizationsQuery } = organizationsApi
