/* eslint react/prop-types: 0 */
import { useCycle } from '../../CycleHooks';
import { Skeleton, Title } from '@mantine/core';
import React, { memo } from 'react';

export const CyclePageTitle = memo(function CyclePageTitle ({ cycleId, pageName = '' }) {
  const [description, querying] = useCycle(cycleId)
  return (
    <>
      {(!description && querying)
        ? <Skeleton height={40} radius="xl" miw='24rem' w='20%' />
        : (
        <Title>
          {description?.name || (description ? `Cycle #${cycleId ?? 'Id Missing'} Name Missing` : `(Error Fetching Cycle #${cycleId ?? 'Id Missing'} Description)`)}{pageName ? ' - ' : ''}{pageName}
        </Title>
          )}
    </>
  )
})
