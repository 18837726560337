import { Title, Center, Space } from '@mantine/core';
import React from 'react';

export function CyclePassNotFound () {
  return (
    <>
      <Space h='xl' />
      <Center>
        <Title>
          Oops! Doesn&apos;t look like you&apos;re still logged in. Try using your link again!
        </Title>
      </Center>
    </>
  )
}
