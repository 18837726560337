import React, { memo } from 'react';
import { ActionIcon, Code, CopyButton, Group, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export const CopyableLink = memo(function CopyableLink ({ label, contents }) {
  return (
    <CopyButton value={contents} timeout={3000}>
      {({ copied, copy }) => (
        <Group gap='xs' wrap='nowrap'>
          <Text>
            {label}
          </Text>
          <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='right'>
            <Group gap='xs' wrap='nowrap'>
              <Code>
                {contents}
              </Code>
              <ActionIcon color={copied ? 'teal' : 'gray'} variant='subtle' onClick={copy} aria-label='Copy link'>
                {copied
                  ? (
                  <IconCheck size='2rem' />
                    )
                  : (
                  <IconCopy size='2rem' />
                    )}
              </ActionIcon>
            </Group>
          </Tooltip>
        </Group>
      )}
    </CopyButton>
  )
})

CopyableLink.propTypes = {
  label: PropTypes.string,
  contents: PropTypes.string
}
