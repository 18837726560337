import { apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';
import { StageProgressStatus } from '../../../../../js/generated/enums/StageProgressStatus';

export const CycleStagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStagesForCycle: builder.query({
      query: ({ cycleId, ...params }) => `invites/stages/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`,
      transformResponse: (response) => ({ ...response, items: insertStagesActivityData(response.items) }),
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CyclesStages, id: id })),
              { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    }),
    getActiveStagesForCycle: builder.query({
      query: (cycleId) => `invites/stages/cycles/${cycleId}/active`,
      transformResponse: (response) => ({ ...response, items: insertStagesActivityData(response.items) }),
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CyclesStages, id: id })),
              { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    }),
    getStageDetail: builder.query({
      query: ({ stageId }) => `invites/stages/${stageId}`,
      transformResponse: (response) => insertSingleStageActivityData(response),
      providesTags: ({ id }, _, { stageId: stageIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.CyclesStages, id: id ?? stageIdParam }, { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) }]
    }),
    getStageResponseDistributionSlice: builder.query({
      query: ({ stageId, cycleId, ...params }) => `invites/stages/${stageId}/report/distribution/slice${paramsToQuery(params)}`,
      providesTags: ({ id }, _, { stageId: stageIdParam, cycleId: cycleIdParam }) =>
        [
          { type: ApiTags.CyclesStages, id: id ?? stageIdParam },
          { type: ApiTags.CyclesStages, id: cycleListTag(cycleIdParam) },
          { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) }
        ],
      transformResponse: (response) => ({ ...response, ...normalizeDistributionResponse(response.items) })
    })
  })
})

function normalizeDistributionResponse (progressesResponse) {
  console.debug('normalizing distribution response data', { progressesResponse })
  const progressIds = progressesResponse.map(progress => progress.id ?? 0)
  const progresses = []
  const responses = []
  for (const progress of progressesResponse) {
    const respondentAnswerIds = progress.respondent_answers?.map(response => response.id ?? 0) ?? []
    for (const response of (progress.respondent_answers ?? [])) {
      const additional = getDecodedAdditional(response.additional)
      responses.push({
        id: response.id ?? 0,
        questionId: response.question?.id ?? 0,
        answerId: response.answer?.id ?? null,
        answerIds: response.answers?.map(answer => answer.id) ?? (response.answer?.id ? [response.answer.id] : []),
        additional: response.additional ?? null,
        additionalAnswer: additional.answer ?? null,
        additionalComment: additional.comment ?? null,
        progressId: progress.id
      })
    }
    progresses.push({
      id: progress.id ?? 0,
      respondentAnswerIds: respondentAnswerIds,
      status: progress.status ?? StageProgressStatus.IndeterminateClosed,
      timeLimit: progress.time_limit ?? null,
      stageId: progress.stage?.id ?? null
    })
  }
  console.debug('done normalizing distribution response data', { progressIds, progresses, responses, progressesResponse })
  return { progressIds, progresses, responses }
}

function getDecodedAdditional (additional) {
  if (!additional) {
    return {}
  }
  try {
    return JSON.parse(additional)
  } catch (error) {
    console.error('Json parse error for report response additional', { error, additional })
    return {}
  }
}

function insertStagesActivityData (stages) {
  console.debug('Inserting stage activity data', stages)
  return stages.map(stage => insertSingleStageActivityData(stage))
}

function insertSingleStageActivityData (stage) { // TODO [cycle pass full features] this would be handled automatically if put into redux
  const formattedStage = { ...stage }
  const stageStub = { id: stage.id, index: stage.index, assessment: { id: stage.assessment.id, name: stage.assessment.name } }
  if (stage.activity) {
    formattedStage.activity = stage.activity.map(activity => ({ ...activity, stage: stageStub }))
  }
  if (stage.admin_activity) {
    formattedStage.admin_activity = stage.admin_activity.map(activity => ({ ...activity, stage: stageStub }))
  }
  return formattedStage
}

export const { useGetStagesForCycleQuery, useGetActiveStagesForCycleQuery, useGetStageDetailQuery, useGetStageResponseDistributionSliceQuery } = CycleStagesApi
