import React, { useEffect, useState } from 'react'
import Content from '../layout/Content'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Image,
  Paper,
  SimpleGrid,
  Space,
  Text,
  Transition
} from '@mantine/core'
import PropTypes from 'prop-types'
import hirescoreLogo from '../../images/hirescore_black_notagline.svg'
import UsernameInput from '../forms/UsernameInput'
import PasswordInput from '../forms/PasswordInput'
import PasswordLabel from '../forms/PasswordLabel'

const emailLabelProps = { c: 'dimmed' }
const passwordLabelProps = { w: '100%', c: 'dimmed' }

export function LoginPageWrapper ({ children, ...props }) {
  return (
    <Content>
      <LoginPageTransition>
      <Flex justify='center' align='center' mih='70vh' {...props}>
        {children}
      </Flex>
      </LoginPageTransition>
    </Content>
  )
}

export default function LoginPage () {
  return (
    <LoginPageWrapper>
      <Paper px='xl' py='lg' shadow="md" w='25rem'>
          <Center p='lg'>
            <Image src={hirescoreLogo} alt='Logo' w={250} />
          </Center>
          <Space h='xl' />
          <form method='post'>
          <SimpleGrid cols={1}>
            <UsernameInput labelProps={emailLabelProps}/>
            <PasswordInput labelProps={passwordLabelProps} label={<PasswordLabel href='/recover-password'/>}/>
          </SimpleGrid>
          <Checkbox
            name='_remember_me'
            size='md'
            c='dimmed'
            mt='lg'
            mb='xs'
            label={<RememberMeLabel />}
          />
          <Button type='submit' size='lg' fullWidth>Sign In</Button>
          </form>
      </Paper>
    </LoginPageWrapper>
  )
}

export function LoginPageTransition ({ children }) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
      <Transition
        mounted={loaded}
        transition='fade'
        duration={400}
        timingFunction='ease'
        >
          {styles => (
            <Box style={styles}>{children}</Box>
          )}
      </Transition>
  )
}

const rememberMeStyles = { cursor: 'pointer', userSelect: 'none' }

function RememberMeLabel () {
  return (
    <Box style={rememberMeStyles}>
      <Text fw='bold'>Keep me logged in</Text>
    </Box>
  )
}

LoginPageWrapper.propTypes = {
  children: PropTypes.node
}

LoginPageTransition.propTypes = {
  children: PropTypes.node
}
