import { Title, Center, Space } from '@mantine/core';
import React from 'react';

export function CyclePassHome () {
  // TODO [cycle pass full features] list non-locked stage progresses, offer clickable link. Account / logout buttons
  return (
    <>
      <Space h='xl' />
      <Center>
        <Title>
          Hmm, doesn&apos;t look like you have any responses to submit right now. Try refreshing the page - if you&apos;re not redirected, then you&apos;re all set for now!
        </Title>
      </Center>
    </>
  )
}
