import axios from 'axios'

export function getHelpLink (route) {
  return axios({
    method: 'get',
    url: 'api/v1/help-links/',
    params: { route }
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
