import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ActionIcon, Combobox, TextInput, ThemeIcon, useCombobox } from '@mantine/core';
import _ from 'lodash';
import { IconX } from '@tabler/icons-react';
import { useFilterItem } from './FilterHooks';
import PropTypes from 'prop-types';

export function FilterSelect ({ filter, selected, onChange: handleUpdate }) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const filterRef = useRef(filter)
  const selectedRef = useRef(selected)

  const filterProps = useFilterItem({ filter, selected })

  const findOptionLabel = useCallback((value) => {
    return _.find(filter.options, { value })?.label
  }, [filter.options])

  const handleValueSelect = (val) => {
    const newVal = ((selected === val) || (val === filter.clearOptionValue)) ? null : val
    handleUpdate(newVal)
    filterProps.setSearch(findOptionLabel(newVal) || '')
    combobox.closeDropdown()
  }

  useEffect(() => {
    filterRef.current = filter
  }, [filter])

  useEffect(() => {
    selectedRef.current = selected
  }, [selected])

  const setSearch = filterProps.setSearch

  useEffect(() => {
    setSearch(findOptionLabel(selectedRef.current) || '')
  }, [setSearch, findOptionLabel])

  const iconColor = useMemo(() => {
    return selected ? filterRef.current.color ?? 'blue' : 'dimmed'
  }, [selected])

  return (
    <Combobox
      onOptionSubmit={handleValueSelect}
      store={combobox}
      {...filterProps.combobox}
    >
      <Combobox.Target>
        <TextInput
          label={filter.label}
          description={filter.description ?? null}
          placeholder={filter.placeholder ?? 'Pick one'}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            filterProps.setSearch(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            filterProps.setSearch(findOptionLabel(selected) || '')
          }}
          leftSection={filter.leftSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.leftSection}</ThemeIcon>}
          rightSection={
            selected
              ? (
              <ActionIcon
                size="sm"
                onMouseDown={(event) => { event.preventDefault() }}
                onClick={() => {
                  handleUpdate(null)
                  setSearch('')
                }}
                aria-label="Clear value"
              >
                <IconX />
              </ActionIcon>
                )
              : (
              <Combobox.Chevron />
                )
          }
          {...filterProps.input}
        />

      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
            {filterProps.options.length > 0 && filterProps.dropdown}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

FilterSelect.propTypes = {
  filter: PropTypes.object,
  selected: PropTypes.string,
  onChange: PropTypes.func
}
