import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Text, Group, Select, ActionIcon, Flex, Box, TextInput, Tooltip, Popover } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { QuestionStateUpdate } from './QuestionsState';
import { IconBan, IconDeviceFloppy, IconSettingsAutomation, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import PropTypes from 'prop-types';
import { DEFAULT_TEMPLATES } from './util';

export const AnswerTemplates = memo(function AnswerTemplate ({ questionId, dispatch, answersList }) {
  const [newName, setNewName] = useState('')
  const [isActive, { open, close }] = useDisclosure(false)
  const [customTemplates, setCustomTemplates] = useLocalStorage({
    key: 'build-assessments-answer-templates',
    defaultValue: []
  })
  const templates = useMemo(() => ([...customTemplates, ...DEFAULT_TEMPLATES]), [customTemplates])

  const templateItems = useMemo(() => {
    if (!customTemplates) return []
    return [
      {
        group: 'Custom',
        items: customTemplates.map(t => t.name)
      },
      {
        group: 'Default',
        items: DEFAULT_TEMPLATES.map(t => t.name)
      }
    ]
  }, [customTemplates])

  const isExistingTemplate = useMemo(() => {
    return templates.find((t) => t.name === newName)
  }, [templates, newName])

  const saveAnswersToTemplate = useCallback(() => {
    const name = newName.trim()

    if (!name.length) {
      return
    }

    const template = {
      name: name,
      answers: answersList
    }

    if (isExistingTemplate) {
      if (confirm(`Overwrite existing template: "${name}"?`)) {
        setCustomTemplates(prev => {
          const newTemplates = [...prev]
          const filtered = newTemplates.filter((t) => t.name !== name)
          return [...filtered, template]
        })
        showNotification({ message: `Saved over template: "${name}"`, color: 'success' })
      } else {
        showNotification({ message: `Cancelled overwriting template: "${name}"`, color: 'orange' })
      }
    } else {
      setCustomTemplates(prev => {
        const newTemplates = [...prev]
        const filtered = newTemplates.filter((t) => t.name !== name)
        return [...filtered, template]
      })

      showNotification({ message: `Created new template: "${name}"`, color: 'success' })
    }

    setNewName('')
  }, [answersList, newName, setCustomTemplates, isExistingTemplate])

  const isCustomTemplate = (name) => {
    return customTemplates.find((t) => t.name === name)
  }

  const renderTemplateOption = ({ option }) => {
    const handleDeleteTemplate = (e) => {
      e.stopPropagation()
      setCustomTemplates(prev => {
        return prev.filter((t) => t.name !== option.label)
      })

      showNotification({ message: `Successfully deleted template: "${option.label}"`, color: 'success' })
    }

    return (
      <Group flex='1'>
        <Box flex='1'>
          <Text>{option.label}</Text>
        </Box>
        <Flex justify='flex-end'>
          {isCustomTemplate(option.label) && (
          <Tooltip label='Delete template'>
            <ActionIcon onClick={handleDeleteTemplate}>
              <IconX />
            </ActionIcon>
          </Tooltip>
          )}
        </Flex>

      </Group>
    )
  }

  const submit = (value) => {
    const answers = templates.find(t => t.name === value).answers
    dispatch({ questionId: questionId, type: QuestionStateUpdate.ReplaceAnswers, payload: answers })
    showNotification({ message: `Applied Answer Template: "${value}"`, color: 'success' })
    close()
  }

  return (
    <>
    <Group>
      {isActive
        ? (
          <Group>
            <Select
              placeholder='Select a template'
              data={templateItems}
              onOptionSubmit={submit}
              searchable
              renderOption={renderTemplateOption}
              selectFirstOptionOnChange
             />
            <Popover onClose={() => setNewName('')}>
              <Popover.Target>
                <Tooltip label='Create new template'>
                <ActionIcon><IconSettingsAutomation /></ActionIcon>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown>
                <Group align='flex-end'>
                  <TextInput value={newName} onKeyDown={({ key }) => { key === 'Enter' && saveAnswersToTemplate() }} onChange={(e) => setNewName(e.currentTarget.value)} label='Create a new template' description='using the existing answers above' placeholder='Template name' />
                  <Tooltip label={isExistingTemplate ? 'Overwrite existing template' : 'Save new template'}>
                  <ActionIcon
                    variant='light'
                    color={isExistingTemplate ? 'orange' : 'success'}
                    mb='calc(0.3125rem*var(--mantine-scale))'
                    disabled={newName.length === 0}
                    onClick={saveAnswersToTemplate}
                    >
                      <IconDeviceFloppy />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Popover.Dropdown>
            </Popover>
            <Tooltip label='Cancel using template'>
              <ActionIcon onClick={close}><IconBan /></ActionIcon>
             </Tooltip>
          </Group>
          )
        : <Button variant='light' onClick={open}>Use Answer Template</Button>
      }
    </Group>
    </>
  )
})

AnswerTemplates.propTypes = {
  questionId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  answersList: PropTypes.array.isRequired
}
