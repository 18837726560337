import React, { useCallback } from 'react'
import { IconInfoCircle, IconRocket } from '@tabler/icons-react';
import { Tabs } from '@mantine/core';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { usePrefetch } from '../../../../redux/query/email/emailHistoryApi.slice';
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';
import { isGranted } from '../../../../util/account/account.util';
export default function BoostDetail () {
  const { cycleId } = useParams()
  const { data: account } = useGetLoggedInAccountQuery()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const historyStatusPrefetch = usePrefetch('getEmailHistoryStatusCount')
  const historyPrefetch = usePrefetch('getEmailHistories')

  const tabs = {
    invite: `/cycles/${cycleId}/invite`,
    info: `/cycles/${cycleId}/invite/info`
  }

  // Prefetch all email history records on page load and status counts when the info tab is hovered
  historyPrefetch({ cycle: cycleId, tag: 'cycle-invitation' })
  const prefetchEmailHistoryStatusCount = useCallback(() => {
    // startDateInterval is passed null because the default value passed in BoostInfo is null. This prevents a redundant fetch
    historyStatusPrefetch({ cycle: cycleId, tag: 'cycle-invitation', startDateInterval: null })
  }, [historyStatusPrefetch, cycleId])

  return (
    <Tabs
      radius='md'
      variant='default'
      mb='xl'
      value={pathname.slice(1).split('/').pop()}
      onChange={value => navigate(tabs[value])}
    >
      <Tabs.List>
        <Tabs.Tab value='invite' color='green.6' leftSection={<IconRocket size='1rem'/>}>Invite</Tabs.Tab>
        {(account && isGranted(account, 'ROLE_BETA_TESTER')) &&
          <Tabs.Tab onMouseEnter={prefetchEmailHistoryStatusCount} value='info' color='green.6' leftSection={<IconInfoCircle size='1rem'/>}>Info</Tabs.Tab>
        }
      </Tabs.List>
    </Tabs>
  )
}
