import React from 'react'
import { Button, SimpleGrid } from '@mantine/core'
import PropTypes from 'prop-types'
import { LoginPageWrapper } from './LoginPage'
import { LoginTypeCard } from './APLoginPage'
import PasswordInput from '../forms/PasswordInput'

export default function APLoginOnSite () {
  return (
    <LoginPageWrapper>
      <SimpleGrid p='lg' spacing='lg' cols={1}>
        <form method='post'>
          <LoginTypeCard>
            <PasswordInput
              label='Password'
              description='Write the password that was given to you below'
              autoFocus
            />
            <Button type="submit" fullWidth>Login</Button>
          </LoginTypeCard>
        </form>
      </SimpleGrid>
    </LoginPageWrapper>
  )
}

LoginTypeCard.propTypes = {
  children: PropTypes.node
}
