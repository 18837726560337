import React from 'react';
import Content from '../layout/Content';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountTabs from './AccountTabs';

export default function AccountApp () {
  const rootPath = document.getElementById('account-app-container').getAttribute('data-root-path')
  const apiBasePath = document.getElementById('account-app-container').getAttribute('data-api-base-path')

  return (
    <Content>
        <BrowserRouter>
          <Routes>
            <Route path={`${rootPath}/:section`} element={<AccountTabs rootPath={rootPath} apiBasePath={apiBasePath}/>}/>
          </Routes>
        </BrowserRouter>
    </Content>
  )
}
