/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { getCycles } from '../../../js/api/cycle_repository'
import _ from 'lodash'
import WarningAlert from '../../core/Alert/WarningAlert'

export default function StatusSetUsedWarning ({ id }) {
  const [count, setCount] = useState(0)

  useEffect(() => {
    getCycles({ limit: 0, status_set: id }).then(data => setCount(_.size(data.items)))
  }, [])

  return count > 0 && <WarningAlert>You are editing a status set that is being used by {count} cycle{count > 1 ? 's' : ''}.</WarningAlert>
}
