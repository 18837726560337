import { ApiMethods, apiSlice, ApiTags } from '../../../react/api';
import { paramsToQuery } from '../../../react/util/queries';

export const boostApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBoostInvitableApplicants: builder.query({
      query: ({ id, ...params }) => `boost/cycle/${id}/invitable/applicants${paramsToQuery(params)}`,
      providesTags: (result, error, { id: cycleId }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CycleBoostInvitableApplicant, id: id })),
              { type: ApiTags.CyclesBoostInvitableApplicants, id: cycleId }
            ]
          : [{ type: ApiTags.CyclesBoostInvitableApplicants, id: cycleId }]
    }),
    getBoostInvitableLimit: builder.query({
      query: ({ id, ...params }) => `boost/cycle/${id}/invitable/limit`,
      providesTags: (result, error, { id: cycleId }) => [{ type: ApiTags.CyclesBoostInvitableApplicants, id: cycleId }]
    }),
    sendBoostBulkInvites: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `boost/cycle/${id}/bulk/invite`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.CyclesBoostInvitableApplicants, id: id }]
    })
  })
})

export const { useGetBoostInvitableApplicantsQuery, useGetBoostInvitableLimitQuery, useSendBoostBulkInvitesMutation } = boostApiSlice
