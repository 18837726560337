import React from 'react'
import CycleManagementDetail from './CycleManagementDetail'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * This component allows to use the CycleManagementDetail component using the URI to find the ID.
 */
export default function CycleManagementDetailParams ({ selected }) {
  const { id } = useParams()

  return <CycleManagementDetail id={id} selected={selected}/>
}

CycleManagementDetailParams.propTypes = {
  selected: PropTypes.string.isRequired
}
