import { apiSlice, ApiTags, providesList } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'

export const batteriesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getApBatteries: builder.query({
      query: params => `ap-batteries${paramsToQuery(params)}`,
      providesTags: result => providesList(result, ApiTags.Batteries)
    })
  })
})

export const { useGetApBatteriesQuery } = batteriesApi
