/* eslint react/prop-types: 0 */
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Title,
  Center,
  Space,
  Loader,
  Affix,
  Box,
  Stack,
  SimpleGrid,
  RingProgress,
  ActionIcon,
  rem
} from '@mantine/core';
import {
  defaultPublishedAssessmentContext,
  PublishedAssessmentContext
} from '../../assessment/PublishedAssessmentContext';
import { useDispatch, useSelector } from 'react-redux';
import { QueryState } from '../../assessment/util';
import { ExportState, baseMaxWidth, maxScaleFactor, edgeMarginPixels } from '../../assessment/pdfUtil';
import { selectPageById, selectPageIdsByAssessmentId } from '../../assessment/pagesSlice';
import { LoadAssessmentError } from '../../assessment/AssessmentResponseHandler';
import { selectBlockById } from '../../assessment/blocksSlice';
import { SectionPosition } from '../../../js/generated/enums/SectionPosition';
import { PDFQuestion } from '../../assessment/PublishedAssessmentQuestion';
import {
  measureSum,
  captureSum,
  renderSum,
  clearRenderData,
  exportToPDF,
  selectCaptureProgress,
  selectExportInProgress,
  selectMeasureProgress,
  selectRenderProgress,
  selectExportsTotal,
  selectMissingRenders,
  totalSum,
  selectPrintFullColor
} from '../../assessment/exportsSlice';
import { IconCheck, IconX, IconMoodAnnoyed } from '@tabler/icons-react';

export function AssessmentPDFRenderProvider ({ children }) {
  return (
    <>
      <AssessmentPDFRingRenderProgress />
      { children }
    </>
  )
}

function SuccessProgressLabel ({ rendersMissing }) {
  return (
    <Center>
      {rendersMissing
        ? (
        <ActionIcon color="red" variant="light" radius="xl" size="xl">
          <IconMoodAnnoyed style={{ width: rem(22), height: rem(22) }} />
        </ActionIcon>
          )
        : (
        <ActionIcon color="teal" variant="light" radius="xl" size="xl">
          <IconCheck style={{ width: rem(22), height: rem(22) }} />
        </ActionIcon>
          )}
    </Center>
  )
}

const fullSuccessOptions = [
  { value: totalSum, color: 'teal' }
]

function getSuccessOptions (rendersMissing) {
  return !rendersMissing
    ? fullSuccessOptions
    : [
        { value: totalSum - rendersMissing, color: 'teal' },
        { value: rendersMissing, color: 'red' }
      ]
}

function FailureProgressLabel () {
  return (
    <Center>
      <ActionIcon color="red" variant="light" radius="xl" size="xl">
        <IconX style={{ width: rem(22), height: rem(22) }} />
      </ActionIcon>
    </Center>
  )
}

function getFailureOptions (measureProgress, captureProgress, renderProgress) {
  return [
    { value: measureProgress, color: measureProgress === measureSum ? 'indigo' : 'yellow', tooltip: 'Queued' },
    { value: captureProgress, color: captureProgress === captureSum ? 'cyan' : 'orange', tooltip: 'Captured' },
    { value: renderProgress, color: renderProgress === renderSum ? 'teal' : 'pink', tooltip: 'Rendered' }
  ]
}

function InProgressLabel () {
  /**
   * <Text size="xs" ta="center" px="xs" style={{ pointerEvents: 'none' }}>
   *   {measureProgress + captureProgress + renderProgress}%
   * </Text>
   */
  return (
    <Center>
      <Loader color="blue" type="bars" />
    </Center>
  )
}

function getInProgressOptions (measureProgress, captureProgress, renderProgress) {
  const progressOptions = [{ value: measureProgress, color: 'indigo', tooltip: measureProgress === measureSum ? 'Queued' : 'Queuing' }]
  if (captureProgress) {
    progressOptions.push({ value: captureProgress, color: 'cyan', tooltip: captureProgress === captureSum ? 'Captured' : 'Capturing' })
  }
  if (renderProgress) {
    progressOptions.push({ value: renderProgress, color: 'teal', tooltip: renderProgress === renderSum ? 'Rendered' : 'Rendering' })
  }
  return progressOptions
}

export const AssessmentPDFRingRenderProgress = memo(function AssessmentPDFRenderProgress () {
  const exportState = useSelector(state => state.exports.status)
  const measureProgress = useSelector(state => selectMeasureProgress(state))
  const captureProgress = useSelector(state => selectCaptureProgress(state))
  const renderProgress = useSelector(state => selectRenderProgress(state))
  const rendersMissing = useSelector(state => selectMissingRenders(state))
  console.debug('AssessmentPDFRenderProgress updating: sum(', { measureProgress, captureProgress, renderProgress }, ') / 100', rendersMissing, exportState)

  const success = exportState === ExportState.Succeeded
  const failure = (exportState === ExportState.Failed) || (exportState === ExportState.Cancelled)

  return (
    <>
      {!measureProgress
        ? null
        : (
        <>
          <Space h='md' />
          <RingProgress
            size={270}
            thickness={20}
            roundCaps
            label={
              success
                ? <SuccessProgressLabel rendersMissing={rendersMissing} />
                : (
                    failure ? <FailureProgressLabel /> : <InProgressLabel />
                  )
            }
            sections={success ? getSuccessOptions(rendersMissing) : (failure ? getFailureOptions(measureProgress, captureProgress, renderProgress) : getInProgressOptions(measureProgress, captureProgress, renderProgress))}
          />
          <Space h='xl' />
        </>
          )}
    </>
  )
})

export function AssessmentPDFRenderer ({ assessment, pdfConfig, displayQuestionNumbers = false, onComplete }) {
  const [assessmentState] = useState(assessment) // Ignore changes once rendering begins for assessment and pdfConfig.
  const [pdfConfigState] = useState(pdfConfig) // Note: Display numbers is prevented from changing upstream.
  console.debug('AssessmentPDFRendered updating.', displayQuestionNumbers, pdfConfig, assessment)

  return (
    <StaticAssessmentAppFrame assessmentId={assessmentState.id} displayQuestionNumbers={displayQuestionNumbers}>
      <Affix position={{ left: -5000, top: 0 }}>
        <PDFAssessmentFrame pages={assessmentState.pages} pdfConfig={pdfConfigState} onComplete={onComplete} />
      </Affix>
    </StaticAssessmentAppFrame>
  )
}

const auth = {}
const staticPixelRatio = 1
const pixelRatioSetting = { overridden: false }

function StaticAssessmentAppFrame ({ assessmentId, displayQuestionNumbers = false, children }) {
  const fetch = null
  const recordResponse = null
  const currentPageIndex = 0

  if (!pixelRatioSetting.overridden) {
    pixelRatioSetting.overridden = true
    console.debug('Overriding window DPI', window.devicePixelRatio, staticPixelRatio)
    window.devicePixelRatio = staticPixelRatio
  }

  const currentPublishedAssessmentContext = useMemo(() => {
    console.info('Updating static assessment context.')
    return {
      ...defaultPublishedAssessmentContext,
      displayNumbers: displayQuestionNumbers,
      next: 'print', // Arbitrary string.
      recordResponse: recordResponse,
      timeLimit: null,
      assessmentId: assessmentId,
      fetch: fetch,
      currentPageIndex: currentPageIndex,
      auth: auth
    }
  }, [assessmentId, displayQuestionNumbers, recordResponse, fetch, currentPageIndex])

  return (
    <PublishedAssessmentContext.Provider value={currentPublishedAssessmentContext}>
      { children }
    </PublishedAssessmentContext.Provider>
  )
}

function PDFAssessmentFrame ({ pages, pdfConfig = null, logics = null, onComplete }) {
  return (
    <Box bg='#FFFFFF' maw={`${(baseMaxWidth * maxScaleFactor) - (edgeMarginPixels * maxScaleFactor * 2)}px`}>
      <AssessmentStaticPageLoader pages={pages} pdfConfig={pdfConfig} logics={logics} onComplete={onComplete} />
    </Box>
  )
}

function AssessmentStaticPageLoader ({ pages, pdfConfig = null, logics = null, onComplete }) {
  const { assessmentId, displayNumbers, next, fetch, recordResponse, timeLimit, currentPageIndex, auth } = useContext(PublishedAssessmentContext)

  const triggeredExportRef = useRef(false)
  const endedExportRef = useRef(false)
  const cancelRef = useRef(null)
  const [initialLoadConfig] = useState({
    assessmentId: assessmentId,
    config: pdfConfig,
    initializationParams: {
      fetchUrl: fetch,
      startPageIndex: currentPageIndex,
      submitUrl: next,
      recordResponse: recordResponse,
      timeLimit: timeLimit,
      displayNumbers: displayNumbers,
      assessmentId: assessmentId,
      auth: auth,
      pages: pages,
      logics: logics,
      isStatic: true,
      pdfConfig: pdfConfig
    },
    measurementParams: {},
    captureParams: {},
    renderParams: pdfConfig
  })
  const dispatch = useDispatch()
  const exportInProgress = useSelector(state => selectExportInProgress(state))
  const exportState = useSelector(state => state.exports.status)
  const numberExports = useSelector(state => selectExportsTotal(state))
  const rendersMissing = useSelector(state => selectMissingRenders(state))
  const questionsStatus = useSelector(state => state.assessments.status)
  const rejectedByServer = (questionsStatus === QueryState.Rejected)
  const questionsDidError = (questionsStatus === QueryState.Failed) || rejectedByServer
  const questionsLoading = (questionsStatus === QueryState.Idle) || (questionsStatus === QueryState.Loading)

  const pageIds = useSelector(state => selectPageIdsByAssessmentId(state, assessmentId))
  const hasPages = !!(pageIds?.length)
  const questionsLoaded = (questionsStatus === QueryState.Succeeded || ((questionsStatus === QueryState.Submitting) && hasPages))
  console.debug('AssessmentStaticPageLoader updating.', { exportState, displayNumbers, initialLoadConfig })

  useEffect(() => {
    if (!exportInProgress) {
      if (!triggeredExportRef.current) {
        console.info('Triggering export.', exportState)
        triggeredExportRef.current = true
        cancelRef.current = dispatch(exportToPDF(initialLoadConfig))
      } else if (!endedExportRef.current) {
        endedExportRef.current = true
        cancelRef.current = null
        console.info('Export ended.', exportState, rendersMissing)
        onComplete(exportState, rendersMissing)
        if (exportState === ExportState.Succeeded) {
          console.debug('Clearing residual data.', exportState)
          dispatch(clearRenderData({ numberExports }))
        }
      }
    }
  }, [exportInProgress, exportState, numberExports, rendersMissing, initialLoadConfig, dispatch, onComplete])

  useEffect(() => {
    return () => {
      console.info('Unmounting pdf renderer - canceling ref if set.', cancelRef.current)
      cancelRef.current?.abort()
    }
  }, [])

  // Was 80%
  return (
    <Box w='97%' mr='auto' ml='auto'>
      { hasPages && (
        <PDFAssessmentPageController
          pageIds={pageIds}
        />
      )}
      { !hasPages && questionsLoading && (
        <Center>
          <Space h='xl' />
          <Loader variant="dots" />
        </Center>
      )}
      { !hasPages && questionsLoaded && !questionsDidError && (
        <Center>
          <Space h='xl' />
          <Title>No page data found when requesting assessment - please try refreshing the page.</Title>
        </Center>
      )}
      { !hasPages && questionsDidError && (
        <LoadAssessmentError />
      )}
    </Box>
  )
}

export function PDFAssessmentPageController ({ pageIds }) {
  return (
    <Stack gap='xl'>
      <Space h='xs' />
      <PDFAssessmentPages pageIds={pageIds} />
      <Space h='xs' />
    </Stack>
  )
}

const PDFAssessmentPages = memo(function PDFAssessmentPages ({ pageIds }) {
  return (
    <>
      {pageIds.map((pageId) => <PDFAssessmentPage key={pageId} pageId={pageId} />)}
    </>
  )
})

export const PDFAssessmentPage = memo(function PDFAssessmentPage ({ pageId }) {
  const page = useSelector(state => selectPageById(state, pageId))
  console.debug('Rendering blocks for PDFAssessmentPage', page)
  return (
    <Box w='100%'>
      <Stack gap='xl'>
        {!!page?.blockIds && page.blockIds.map(elem => <PDFAssessmentBlock key={elem} blockId={elem} />)}
      </Stack>
    </Box>
  )
})

const PDFAssessmentBlock = memo(function PDFAssessmentBlock ({ blockId }) {
  const block = useSelector(state => selectBlockById(state, blockId))

  console.debug('Rendering sections for PDFAssessmentBlock', block)
  if (!block) {
    return <></>
  }
  return (
    <>
      {block.hasCenter
        ? (
          <SimpleGrid cols={1}>
            <PDFAssessmentSection section={block.sections[SectionPosition.Center]} />
          </SimpleGrid>
          )
        : (
          <SimpleGrid cols={2}>
            <SimpleGrid cols={1}>
              <PDFAssessmentSection section={block.sections[SectionPosition.Left]} />
            </SimpleGrid>
            <SimpleGrid cols={1}>
              <PDFAssessmentSection section={block.sections[SectionPosition.Right]} />
            </SimpleGrid>
          </SimpleGrid>
          )}
    </>
  )
})

/**
 * @param {PublishedAssessmentPageSection} section
 */
function PDFAssessmentSection ({ section }) {
  console.debug('Rendering question ids for PDFAssessmentSection', section)
  const fullColor = useSelector(state => selectPrintFullColor(state))
  return (
    <>
      {section.questionIds.map((questionId, index) => <PDFQuestion key={questionId} questionId={questionId} fullColor={fullColor} />)}
    </>
  )
}
