import React, { useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useGetPhasesQuery } from '../../../../../redux/query/hire/phasesApi.slice'
import ErrorLoading from '../../../../core/ErrorLoading'
import Loading from '../../../../core/Loading'
import PropTypes from 'prop-types'
import { Select, Stack } from '@mantine/core'
import { formatToOptions } from '../../../../../js/util/DataUtil'
import Error from '../../../../forms/Error'
import { IconSignRight } from '@tabler/icons-react'

export default function PhasesDropdown ({ name }) {
  const { control } = useFormContext()

  const { field: { onChange, onBlur, name: fieldName, value } } = useController({
    name: name,
    control: control,
    rules: { required: 'A phase must be selected' }
  })

  const { data: phases, error, isLoading } = useGetPhasesQuery()

  const options = useMemo(() => {
    return formatToOptions(phases?.items ?? [], { label: 'name', value: 'id' })
  }, [phases])

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Stack>
      <Select
        leftSection={<IconSignRight />}
        name={fieldName}
        onChange={onChange}
        onBlur={onBlur}
        value={value?.toString()}
        placeholder='Pick phase'
        data={options}
        clearable
      />
      <Error name={name} />
    </Stack>
  )
}

PhasesDropdown.propTypes = {
  name: PropTypes.string.isRequired
}
