/* eslint react/prop-types: 0 */
import { useAccount } from '../../util/Hooks';
import Content from '../../layout/Content';
import {
  Button,
  Center,
  Checkbox,
  Chip,
  Fieldset,
  Group,
  Loader,
  SimpleGrid,
  Space,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { ExportState } from '../../assessment/pdfUtil';
import { AssessmentPDFRenderer, AssessmentPDFRenderProvider } from './AssessmentPDFRenderer';
import { useDebouncedValue } from '@mantine/hooks';
import { AssessmentPurpose, assessmentPurposeFromId } from '../../../js/generated/enums/AssessmentPurpose';
import { isBetaOnly } from '../../../js/generated/enums/QuestionType';

const classicLimitations = {
  randomSeed: () => 0,
  displayQuestionNumbers: () => true,
  displayPageNumbers: () => false,
  displayAnswerScores: () => false,
  displayLogicIndicators: () => false,
  fullColor: () => true,
  formFullColorField: () => 'Full Color',
  formQuestionOrderingField: (previous) => previous === 'Random' ? 'Original' : previous
}

const maxRandomSeed = 2147483647
const pdfFeaturesMap = {
  displayCorrectAnswers: 'Show correct answer',
  displayAnswerScores: 'Show answer scores',
  displayCompetencies: 'Show competencies',
  displayLogicIndicators: 'Show logic indicators',
  orderByCompetency: 'Order by competency'
}
const pdfParamsMap = {
  displayCompetencies: { label: 'Show competencies' },
  displayCorrectAnswers: { label: 'Show correct answer' },
  displayAnswerScores: { label: 'Show answer scores', requireAdmin: true, classicTooltip: 'Classic style does not support showing question scores.' },
  displayQuestionNumbers: { label: 'Show question numbers', classicTooltip: 'Classic style does not support hiding question numbers.' },
  displayPageNumbers: { label: 'Show page numbers', classicTooltip: 'Classic style does not support embedding page numbers automatically.' },
  displayLogicIndicators: { label: 'Show logic indicators', classicTooltip: 'Classic style does not support question logic.' },
  formQuestionOrderingField: {
    label: 'Question Order',
    options: ['Original', 'By competency', 'Random'],
    multiLabel: true,
    values: {
      Original: {
        randomSeed: () => 0,
        orderByCompetency: () => false
      },
      'By competency': {
        randomSeed: () => 0,
        orderByCompetency: () => true
      },
      Random: {
        randomSeed: () => Math.max(Math.floor(Math.random() * maxRandomSeed), 1),
        orderByCompetency: () => false
      }
    }
  },
  formFullColorField: {
    label: 'Format',
    options: ['Printer Friendly', 'Full Color'],
    multiLabel: true,
    values: {
      'Printer Friendly': {
        fullColor: () => false
      },
      'Full Color': {
        fullColor: () => true
      }
    }
  }
}

const disabledClassicPDFParamsValues = [
  'randomSeed',
  'displayQuestionNumbers',
  'displayPageNumbers',
  'displayAnswerScores',
  'displayLogicIndicators',
  'Printer Friendly',
  'Random'
]

/**
 * @typedef {object} additionalTextFieldProps
 * @property {string?} label?
 * @property {string?} description?
 * @property {string?} placeholder?
 */

/**
 * @typedef {object} additionalTextFieldMap
 * @property {additionalTextFieldProps} fieldProps
 * @property {boolean?} requireAdmin
 */

/**
 * @param {?object} assessment
 * @param {boolean} querying
 * @param {function} updateAssessmentParams
 * @param {function} exportLegacyAssessment
 * @param {Object.<string, additionalTextFieldMap>} additionalTextFields
 */
export function AssessmentPDFExport ({ assessment, querying, updateAssessmentParams, exportLegacyAssessment, additionalTextFields = {} }) {
  const account = useAccount()
  const hasAssessment = !!assessment
  const initialLoad = !hasAssessment && querying
  return (
    <Content>
      {hasAssessment && (
        <PDFExportController
          assessment={assessment}
          querying={querying}
          updateAssessmentParams={updateAssessmentParams}
          exportLegacyAssessment={exportLegacyAssessment}
          account={account}
          additionalTextFields={additionalTextFields}
        />
      )}
      {!hasAssessment && initialLoad && (
        <>
          <Space h='md' />
          <Center>
            <Loader color="blue" type="dots" />
          </Center>
        </>
      )}
      {!hasAssessment && !initialLoad && (
        <>
          <Space h='md' />
          <Text ta='center'>Something went wrong! Please try refreshing the page.</Text>
        </>
      )}
    </Content>
  )
}

/**
 * @param {object} assessment
 * @param {boolean} querying
 * @param {function} updateAssessmentParams
 * @param {function} exportLegacyAssessment
 * @param {object} account
 * @param {Object.<string, additionalTextFieldMap>} additionalTextFields
 */
function PDFExportController ({ assessment, querying, updateAssessmentParams, exportLegacyAssessment, account, additionalTextFields = {} }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [pdfParams, setPDFParams] = useState({
    displayCompetencies: false,
    orderByCompetency: false,
    randomSeed: 0,
    displayQuestionNumbers: false,
    displayPageNumbers: true,
    displayCorrectAnswers: false,
    displayAnswerScores: false,
    displayLogicIndicators: false,
    fullColor: false,
    formQuestionOrderingField: 'Original',
    formFullColorField: 'Printer Friendly',
    exportName: assessment.name,
    title: assessment.name,
    filename: `${assessment.name}_Export.pdf`, // TODO add account name as author, prevent controller from loading until account populated.
    interviewLayout: assessmentPurposeFromId(assessment.type) === AssessmentPurpose.Interview
  })
  const lastUpdatedRef = useRef(pdfParams)
  const [printClassicStyle, setPrintClassicStyle] = useState(false) // TODO [New Q Types] force render as beta style only if any questions with new Q types.
  const [assessmentHasBetaOnlyTypes, setAssessmentHasBetaOnlyTypes] = useState(false)

  useEffect(() => {
    if (lastUpdatedRef.current !== pdfParams) {
      lastUpdatedRef.current = pdfParams
      updateAssessmentParams(pdfParams)
    }
  }, [pdfParams, updateAssessmentParams])

  useEffect(() => {
    if (assessment?.questions) {
      let anyBetaOnlyFound = false
      for (const question of assessment.questions) {
        if (isBetaOnly(question.type)) {
          anyBetaOnlyFound = true
          break
        }
      }
      setAssessmentHasBetaOnlyTypes(anyBetaOnlyFound)
      if (anyBetaOnlyFound) {
        setPrintClassicStyle(false)
      }
    }
  }, [assessment])

  useEffect(() => {
    if (printClassicStyle) {
      let anyReset = false
      const modifiedParams = { ...pdfParams }
      for (const [key, param] of Object.entries(pdfParams)) {
        const classicModified = classicLimitations[key]?.(param) ?? param
        if (classicModified !== param) {
          modifiedParams[key] = classicModified
          anyReset = true
        }
      }
      if (anyReset) {
        showNotification({
          title: 'Classic Option Not Supported',
          message: 'Some of the options you had selected are not available when printing assessments with the classic style. They have been reset.',
          color: 'yellow'
        })
        setPDFParams(modifiedParams)
      }
    }
  }, [printClassicStyle, pdfParams])

  const submit = () => {
    console.info('Exporting to pdf.', printClassicStyle, pdfParams)
    setIsSubmitting(true)

    if (printClassicStyle) {
      exportLegacyAssessment(pdfParams)
        .finally(() => setIsSubmitting(false))
    }
  }

  const onComplete = useCallback((status, rendersMissing) => {
    console.info('Called PDFExport onComplete.', status, rendersMissing)
    setIsSubmitting(false)
    showNotification({
      title: 'Export ' + (status ?? 'Process Exited'),
      message: 'The assessment export process has exited' + (rendersMissing ? ` with ${rendersMissing}% of questions missing` : '') + '. Status: ' + (status ?? 'Unknown'),
      color: (status === ExportState.Succeeded && !rendersMissing) ? 'green' : (status === ExportState.Failed ? 'red' : 'yellow'),
      autoClose: 5000
    })
  }, [])

  const updatePDFParams = (updatedParams) => {
    console.debug('Called updatePDFParams.', updatedParams)
    setPDFParams((prev) => ({ ...prev, ...updatedParams }))
  }

  const pdfConfig = useMemo(() => {
    const features = []
    for (const [featureKey, featureValue] of Object.entries(pdfFeaturesMap)) {
      if (pdfParams[featureKey] ?? false) {
        features.push(featureValue)
      }
    }
    return features.length ? { ...pdfParams, features } : pdfParams
  }, [pdfParams])

  const back = () => isSubmitting ? setIsSubmitting(false) : window.history.back()
  const betaRender = isSubmitting && !!assessment && !printClassicStyle

  return (
    <AssessmentPDFRenderProvider>
      <SimpleGrid cols={1}>
        <Tooltip label='Fast classic look with limited options.' disabled={printClassicStyle} withArrow>
          <Checkbox label='Print with classic style' disabled={isSubmitting || !!assessment?.beta_assessment || assessmentHasBetaOnlyTypes} checked={printClassicStyle} onChange={(event) => setPrintClassicStyle(event.currentTarget.checked)} />
        </Tooltip>
        {Object.entries(pdfParamsMap).map(([fieldKey, fieldMap]) => (
          <PDFExportFieldWrapper
            key={fieldKey}
            pdfParams={pdfParams}
            fieldKey={fieldKey}
            fieldMap={fieldMap}
            updatePDFParams={updatePDFParams}
            isSubmitting={isSubmitting}
            printClassicStyle={printClassicStyle}
            isAdmin={!!account?.access?.ADMIN}
          />
        ))}
        {Object.entries(additionalTextFields).map(([fieldKey, fieldMap]) => (
          <PDFExportTextField
            key={fieldKey}
            pdfParams={pdfParams}
            fieldKey={fieldKey}
            fieldProps={fieldMap.fieldProps}
            updatePDFParams={updatePDFParams}
            disabled={isSubmitting || (fieldMap.requireAdmin && !account?.access?.ADMIN)}
          />
        ))}
        <Group spacing='xs'>
          <Button color='blue.6' loading={isSubmitting} disabled={!!querying} onClick={submit}>Export</Button>
          <Button color='gray.6' onClick={back}>Cancel</Button>
        </Group>
      </SimpleGrid>
      {betaRender && (
        <AssessmentPDFRenderer
          assessment={assessment}
          pdfConfig={pdfConfig}
          displayQuestionNumbers={pdfConfig.displayQuestionNumbers}
          onComplete={onComplete}
        />
      )}
    </AssessmentPDFRenderProvider>
  )
}

function PDFExportFieldWrapper ({ pdfParams, fieldKey, fieldMap, updatePDFParams, isSubmitting, printClassicStyle, isAdmin }) {
  if (!isAdmin && fieldMap.requireAdmin) {
    console.debug('Not displaying PDF param due to permissions check.', isAdmin, fieldKey, fieldMap)
    return <></>
  }
  return (
    <>
      {fieldMap.multiLabel
        ? (
        <PDFExportMultiField
          pdfParams={pdfParams}
          fieldKey={fieldKey}
          label={fieldMap.label}
          options={fieldMap.options}
          valueHandlers={fieldMap.values}
          updatePDFParams={updatePDFParams}
          isSubmitting={isSubmitting}
          printClassicStyle={printClassicStyle}
        />
          )
        : (
        <PDFExportField
          pdfParams={pdfParams}
          fieldKey={fieldKey}
          label={fieldMap.label}
          updatePDFParams={updatePDFParams}
          disabled={isSubmitting || (printClassicStyle && disabledClassicPDFParamsValues.includes(fieldKey))}
          printClassicStyle={printClassicStyle}
          classicTooltip={fieldMap.classicTooltip ?? null}
        />
          )}
    </>
  )
}

function PDFExportMultiField ({ pdfParams, fieldKey, label, options, valueHandlers, updatePDFParams, isSubmitting, printClassicStyle }) {
  const value = pdfParams[fieldKey] ?? options[0]
  const handleChange = useCallback((newValue) => {
    const updateHandler = valueHandlers[newValue] ?? {}
    const newValues = { [fieldKey]: newValue }
    for (const [fieldKey, setter] of Object.entries(updateHandler)) {
      newValues[fieldKey] = setter()
    }
    updatePDFParams(newValues)
  }, [valueHandlers, fieldKey, updatePDFParams])

  const data = useMemo(() => {
    return options.map(option => ({
      value: option,
      label: option,
      disabled: printClassicStyle && disabledClassicPDFParamsValues.includes(option)
    }))
  }, [options, printClassicStyle])
  return (
    <div>
      <Fieldset legend={<Text fw={500}>{label}</Text>} display='inline-block'>
        <Group wrap='nowrap'>
          <Chip.Group multiple={false} value={value} onChange={handleChange} >
            {data.map(element => <Chip key={element.value} value={element.value} disabled={isSubmitting || !!element.disabled}>{element.label}</Chip>)}
          </Chip.Group>
        </Group>
      </Fieldset>
    </div>
  )
}

function PDFExportField ({ pdfParams, fieldKey, label, updatePDFParams, disabled, printClassicStyle, classicTooltip = null }) {
  return (
    <>
      {classicTooltip
        ? (
        <Tooltip label={classicTooltip} disabled={!printClassicStyle}>
          <Checkbox
            label={label}
            disabled={disabled}
            checked={pdfParams[fieldKey] ?? false}
            onChange={(event) => updatePDFParams({ [fieldKey]: event.currentTarget.checked })}
          />
        </Tooltip>
          )
        : (
        <Checkbox
          label={label}
          disabled={disabled}
          checked={pdfParams[fieldKey] ?? false}
          onChange={(event) => updatePDFParams({ [fieldKey]: event.currentTarget.checked })}
        />
          )}
    </>
  )
}

/**
 * @param {object} pdfParams
 * @param {string} fieldKey
 * @param {additionalTextFieldProps} fieldProps
 * @param {function} updatePDFParams
 * @param {boolean} disabled
 */
function PDFExportTextField ({ pdfParams, fieldKey, fieldProps, updatePDFParams, disabled }) {
  const paramValue = pdfParams[fieldKey] ?? ''
  const [value, setValue] = useState(paramValue)
  const [debounced] = useDebouncedValue(value, 1000)
  useEffect(() => {
    if (debounced !== paramValue && debounced === value) {
      console.debug('Updating text field.', fieldKey, debounced, paramValue)
      if (!disabled) {
        updatePDFParams({ [fieldKey]: debounced })
      } else {
        console.warn('Text field disabled when updating - clearing previous change.', { disabled, debounced, paramValue })
        setValue(paramValue)
      }
    }
  }, [debounced, paramValue, updatePDFParams, fieldKey, disabled, value])

  return (
    <TextInput
      required
      { ...fieldProps }
      disabled={disabled}
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      onBlur={() => {
        if (value !== paramValue) {
          console.debug('Updating text field from on blur handler.', { value, paramValue })
          updatePDFParams({ [fieldKey]: value })
        }
      }}
    />
  )
}
