/* eslint react/prop-types: 0 */
import { Button } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { invite } from '../../js/api/account_repository'
import Error from '../forms/Error'

export default function ReferralForm ({ email }) {
  const form = useForm({
    defaultValues: {
      emails: ''
    }
  })

  const onSubmit = data => {
    invite(data)
      .then(() => {
        showNotification({
          message: 'Successfully invited',
          color: 'success',
          autoClose: 3000
        })

        form.reset()
      })
      .catch(() => {
        showNotification({
          message: 'Something went wrong!',
          color: 'red',
          autoClose: 3000
        })
      })
  }

  return (
    <FormProvider {...form}>
      <form>
        <h3>Tell business owners about HireScore, you both get $200!</h3>
        <label>Your email
          <input type='email' value={email} disabled/>
        </label>
        <label>Their email
          <input
            type='email'
            {
              ...form.register('emails', {
                required: 'This field is required'
              })
            }
            placeholder='first@example.com, second@example.com'
          />
        </label>
        <Error name='emails'/>
        <Button onClick={form.handleSubmit(onSubmit)}>Invite</Button>
      </form>
    </FormProvider>
  )
}
