import { useEffect, useState } from 'react'
import { getMedia, getMediaCollection } from '../../../js/api/media_repository'
import {
  MediaType,
  idFromMediaType,
  getDisplayColor,
  getValidExtensions,
  getMimeTypes
} from '../../../js/generated/enums/MediaType';
import axios from 'axios';

export function useMedia (id) {
  const [media, setMedia] = useState(null)

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getMedia(id, cancelSource.token).then(response => { if (response) setMedia(response) })

    return () => cancelSource.cancel()
  }, [id])

  return media
}

export function useMediaCollection (params) {
  const [collection, setCollection] = useState({ total: 0, limit: 0, items: [] })

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getMediaCollection(params, cancelSource.token).then(response => { if (response) setCollection(response) })

    return () => cancelSource.cancel()
  }, [params])

  return collection
}

export const mediaTypeIdToTypes = Object.freeze(Object.assign({}, ...Object.values(MediaType).map(element => {
  return { [idFromMediaType(element)]: element }
})))

export const mediaTypeIdToColors = Object.freeze(Object.assign({}, ...Object.values(MediaType).map(element => {
  return { [idFromMediaType(element)]: getDisplayColor(element) }
})))

export const mediaTypeIdToExtensions = Object.freeze(Object.assign({}, ...Object.values(MediaType).map(element => {
  return { [idFromMediaType(element)]: Object.freeze(getValidExtensions(element)) }
})))

export const mediaTypeIdToMimeTypes = Object.freeze(Object.assign({}, ...Object.values(MediaType).map(element => {
  return { [idFromMediaType(element)]: Object.freeze(getMimeTypes(element)) }
})))

export function useMediaTypes () {
  const types = mediaTypeIdToTypes

  const colors = mediaTypeIdToColors

  const extensions = mediaTypeIdToExtensions

  const mimeTypes = mediaTypeIdToMimeTypes

  return {
    types,
    colors,
    extensions,
    mimeTypes
  }
}
