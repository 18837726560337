/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import SelectTable from './SelectTable'
import Form from './Form'
import { Checkbox, Grid, Group, Select, Text } from '@mantine/core'
import styles from './Contact.module.scss'
import { formatToOptions } from '../../../js/util/DataUtil'

export default function ContactMultipleApp ({ getTemplates, getTextTemplates, getBatteries, getSubmissions, getApplicants, onSuccessRedirect, getStatuses, max, cycleId = null }) {
  const [courtesyLetter, setCourtesyLetter] = useState(false)
  const [unselectedApplicants, setUnselectedApplicants] = useState([])
  const [selectedApplicants, setSelectedApplicants] = useState([])
  const [status, setStatus] = useState('')
  const [statuses, setStatuses] = useState([])
  const [checked, setChecked] = useState(false)

  const maxAllowedToAdd = Math.min(Math.max(max - selectedApplicants.length, 0), unselectedApplicants.length)

  useEffect(() => {
    getStatuses().then(statuses => setStatuses(statuses))
  }, [])

  useEffect(() => {
    getApplicants(status ?? '', courtesyLetter).then(response => addUnselectedApplicants(response.data))
    setChecked(false)
  }, [courtesyLetter, status])

  const addUnselectedApplicants = (applicants) => {
    setUnselectedApplicants(_.sortBy(_.differenceBy(applicants, selectedApplicants, 'id'), ['id']))
  }

  const selectApplicant = (applicant) => {
    if (maxAllowedToAdd > 0) {
      setUnselectedApplicants(prev => _.sortBy(_.without(prev, applicant), ['id']))
      setSelectedApplicants(prev => _.sortBy(_.concat(prev, applicant), ['id']))
    }
  }

  const unselectApplicant = (applicant) => {
    setSelectedApplicants(prev => _.sortBy(_.without(prev, applicant), ['id']))
    setUnselectedApplicants(prev => _.sortBy(_.concat(prev, applicant), ['id']))
  }

  const selectMax = (checked) => {
    setChecked(checked)

    if (checked) {
      setUnselectedApplicants(prev => _.sortBy(_.without(prev, ..._.slice(unselectedApplicants, 0, maxAllowedToAdd)), ['id']))
      setSelectedApplicants(prev => _.sortBy(_.concat(prev, _.slice(unselectedApplicants, 0, maxAllowedToAdd)), ['id']))
    } else {
      setUnselectedApplicants(prev => _.sortBy(_.concat(prev, selectedApplicants), ['id']))
      setSelectedApplicants([])
    }
  }

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <div className={styles.emailControls}>
            <Grid>
              <Grid.Col span={{ sm: 12, md: 6, lg: 3 }}>
                <Select
                  data={formatToOptions(statuses, { value: 'name', label: 'name' })}
                  placeholder='Any status'
                  onChange={setStatus}
                  clearable
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ sm: 12 }}>
                <div className={styles.checkboxWrapper}>
                  <Checkbox onChange={(e) => setCourtesyLetter(e.target.checked)} label={'Hide applicants that were sent a courtesy letter'} />
                </div>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ sm: 12, lg: 10 }}>
                <Group justify='space-between'>
                  <Checkbox checked={checked} onChange={(e) => selectMax(e.target.checked)} label={checked ? 'Unselect All' : `Select Max (${maxAllowedToAdd})` } />
                  <Text>Applicants Selected: {selectedApplicants.length}</Text>
                </Group>
              </Grid.Col>
            </Grid>
          </div>
          <Grid>
            <Grid.Col span={{ sm: 12, md: 6, lg: 5 }}>
              <SelectTable applicants={unselectedApplicants} callback={(applicant) => selectApplicant(applicant)}/>
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6, lg: 5 }}>
              <SelectTable applicants={selectedApplicants} callback={(applicant) => unselectApplicant(applicant)}/>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ sm: 12, lg: 10 }}>
          <Form
            getTemplates={getTemplates}
            getTextTemplates={getTextTemplates}
            getSubmissions={getSubmissions}
            getBatteries={getBatteries}
            applicants={_.map(selectedApplicants, 'id')}
            previewApplicant={_.first(_.map(selectedApplicants, 'id'))}
            onSuccessRedirect={onSuccessRedirect}
            cycleId={cycleId}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
