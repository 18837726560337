import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit'
import { QueryState } from '../../../../assessment/util';
import { addNewPage, reportVersionChanged } from './distributionReportPagesSlice';

/**
 * @typedef {object} DistributionReportProgress
 * @property {int} id
 * @property {int[]} respondentAnswerIds
 * @property {StageProgressStatus} status
 * @property {?int} timeLimit
 * @property {int} stageId
 */

/**
 * @type {EntityAdapter<{DistributionReportProgress}, int>}
 */
const distributionProgressesAdapter = createEntityAdapter()

const initialState = distributionProgressesAdapter.getInitialState({
  status: QueryState.Idle,
  error: null
})

const distributionProgressesSlice = createSlice({
  name: 'distributionProgresses',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportVersionChanged.pending, (state) => {
        distributionProgressesAdapter.removeAll(state)
      })
      .addCase(addNewPage, (state, action) => {
        distributionProgressesAdapter.upsertMany(state, action.payload.progresses)
      })
  }
})

// export const { } = distributionProgressesSlice.actions

export default distributionProgressesSlice.reducer

export const {
  selectAll: selectAllDistributionProgresses,
  selectById: selectDistributionProgressById,
  selectIds: selectDistributionProgressIds,
  selectEntities: selectDistributionProgressMap
} = distributionProgressesAdapter.getSelectors(state => state.distributionProgresses)
