/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { IconAffiliate, IconMapPin } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getOrganizations } from '../../js/api/organization_repository';
import { getLocations } from '../../js/api/skillbuilder_repository';
import { formatToOptions } from '../../js/util/DataUtil';
import { Header, NumberCell } from '../core/ReactTable/ReactTable.js';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../core/ReactTable/paramsSlice';
import { useGetHiringAnalyticsQuery } from './AnalyticsApi';

export function useHiringAnalyticsQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetHiringAnalyticsQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

const defaultFilters = {}
const defaultHiddenColumns = []

export function useAnalyticsTable (account) {
  const [organizations, setOrganizations] = useState([]) // TODO refactor these into RTK
  const [locations, setLocations] = useState([])

  useEffect(() => {
    if (account?.access?.ADMIN) {
      getOrganizations({ limit: 0 }).then(collection => setOrganizations(collection.items))
    }
  }, [account])

  return {
    defaultHiddenColumns: defaultHiddenColumns,
    defaultFilters: defaultFilters,
    filters: useAnalyticsTableFilters(organizations, locations, setLocations, account),
    columns: useAnalyticsTableColumns(account),
    notDynamic: true
  }
}

function useAnalyticsTableFilters (organizations, locations, setLocations, account) {
  return useMemo(() => {
    const filters = []
    if (account.access.ADMIN) {
      filters.push(
        {
          id: 'organization',
          label: 'Organization',
          leftSection: <IconAffiliate />,
          options: formatToOptions(organizations),
          onUpdate: value => {
            if (value !== null) {
              getLocations({ limit: 0, order_by: 'name', organization: value }).then(collection => setLocations(collection.items))
            } else {
              setLocations([])
            }
          },
          resets: ['location']
        },
        {
          id: 'location',
          label: 'Location',
          leftSection: <IconMapPin />,
          options: formatToOptions(locations),
          disabled: locations.length === 0
        })
    }
    return filters
  }, [organizations, locations, account])
}

function useAnalyticsTableColumns (account) {
  const NO_DATA = '-'
  return useMemo(
    () => {
      const columns = []
      columns.push(
        {
          Header: <Header justify='center' ta='center'>Year</Header>,
          id: 'year',
          accessor: 'year',
          Cell: ({ cell: { value } }) => {
            return <NumberCell centered>{ value }</NumberCell>
          },
          filterable: true
        }
      )
      if (account.access.ADMIN) {
        columns.push(
          {
            Header: <Header justify='center' ta='center'>Users</Header>,
            styles: { textAlign: 'center', width: '100%' },
            id: 'accounts',
            accessor: 'accounts',
            Cell: ({ cell: { value } }) => {
              return <NumberCell centered>{(typeof value === 'undefined') ? NO_DATA : value}</NumberCell>
            },
            filterable: true
          },
          {
            Header: <Header justify='center' ta='center'>Organizations</Header>,
            position: 'sticky',
            id: 'organizations',
            accessor: 'organizations',
            Cell: ({ cell: { value } }) => {
              return <NumberCell centered>{ (typeof value === 'undefined') ? NO_DATA : value }</NumberCell>
            },
            filterable: true
          }
        )
      }
      columns.push(
        {
          Header: <Header justify='center' ta='center'>Locations</Header>,
          styles: { textAlign: 'center', width: '100%' },
          position: 'sticky',
          id: 'locations',
          accessor: 'locations',
          Cell: ({ cell: { value } }) => {
            return <NumberCell centered>{ (typeof value === 'undefined') ? NO_DATA : value }</NumberCell>
          },
          filterable: true
        },
        {
          Header: <Header justify='center' ta='center'>Cycles</Header>,
          position: 'sticky',
          id: 'cycles',
          accessor: 'cycles',
          Cell: ({ cell: { value } }) => {
            return <NumberCell centered>{ (typeof value === 'undefined') ? NO_DATA : value }</NumberCell>
          },
          filterable: true
        },
        {
          Header: <Header justify='center' ta='center'>Applicants</Header>,
          position: 'sticky',
          id: 'applicants',
          accessor: 'applicants',
          Cell: ({ cell: { value } }) => {
            return <NumberCell centered>{ (typeof value === 'undefined') ? NO_DATA : value }</NumberCell>
          },
          filterable: true
        },
        {
          Header: <Header justify='center' ta='center'>Applicants Per Cycle</Header>,
          id: 'avg_app',
          Cell: ({ cell: { row } }) => {
            return <NumberCell centered>{ row.original.cycles ? Math.round((row.original.applicants ?? 0) / row.original.cycles) : NO_DATA }</NumberCell>
          },
          filterable: true
        }
      )
      return columns
    },
    [account]
  )
}
