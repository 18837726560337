/* eslint react/prop-types: 0 */
import { generatePath, Link, useParams } from 'react-router-dom';
import { useCycle } from '../../CycleHooks';
import { useOpenInviteDetail } from './OpenInvitesHooks';
import { useCycleAssignedPhases, useIsAdmin } from '../CyclesHooks';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { formatOpenInviteLink, renderDateFromSource } from '../formatUtil';
import { Badge, Button, Center, Group, Space, Title } from '@mantine/core';
import { LockableIcon } from '../summaries/LockableIcon';
import { BackButton, InviteHomeButton, PreviewButton } from '../nav/CycleNavButton';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { AssessmentInvitesTable } from '../sent/AssessmentInvitesTable';
import React from 'react';
import { CycleSummaryCard } from '../summaries/CycleSummaryCard';
import { ToggleLockButton } from '../nav/ToggleLockButton';
import { useOpenInviteLockMutation } from './OpenInvitesApi';
import { ExpirationDateBadge } from '../summaries/ExpirationDateBadge';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';
import { CopyableLink } from '../nav/CopyableLink';

export function PublicLinkDetail () {
  const { cycleId, openInviteId } = useParams()
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [invite, inviteQuerying] = useOpenInviteDetail(openInviteId, cycleId)
  const [phases, phasesQuerying] = useCycleAssignedPhases(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()

  const querying = !!(inviteQuerying || adminLoading || cycleQuerying || phasesQuerying)
  return (
    <>
      {!!querying && <CardLoadingPlaceholder />}
      {!querying && !invite && <CardQueryError />}
      {!querying && !!invite && <PublicLinkCard invite={invite} cycle={cycle} phases={phases} isAdmin={isAdmin} />}
    </>
  )
}

function PublicLinkCard ({ invite, cycle, phases, isAdmin }) {
  const { cycleId, openInviteId } = useParams()

  const editPath = (!cycleId || !openInviteId) ? null : generatePath(CyclePassRoute.CycleOpenInviteEdit, { cycleId, openInviteId })
  const openInviteLink = formatOpenInviteLink(invite?.url_suffix, invite?.invite_config?.link_prefix)
  const previewParams = { title: invite?.invite_config.header, message: invite?.invite_config.message, style: invite?.invite_config.style, locked: invite?.locked }

  console.debug('Cycle open invite detail updating', { cycle, invite })

  const firstPhaseName = phases?.data?.length ? (phases.data[0] ?? null)?.name : null
  return (
    <>
      <Title>
        Public Link{invite?.id ? ` #${invite.id}` : ''} {invite?.name ?? '(Error Fetching Public Link Description)'} Detail
      </Title>
      <Group mb='md' mt='xxs'>
        <Badge color='teal' variant='light'>Public</Badge>
        {!!firstPhaseName && <Badge color='indigo' variant='light'>{firstPhaseName}</Badge>}
        {!!invite && <ExpirationDateBadge inviteConfig={invite.invite_config} locked={invite.locked} />}
        <Badge color='green' variant='transparent'>Updated {renderDateFromSource(invite?.updated_at)}</Badge>
        <Badge color='gray' variant='transparent'>Created {renderDateFromSource(invite?.created_at)}</Badge>
        {!!isAdmin && !!openInviteLink && <Badge color='blue' variant='light'>{openInviteLink}</Badge>}
      </Group>
      <Group>
        <LockableIcon item={invite} />
        <InviteHomeButton />
        <BackButton />
        <PreviewButton cycleId={cycleId} { ...previewParams } />
        {!!editPath && <Button variant='subtle' size='sm' component={Link} to={editPath}>Edit</Button>}
        {!!isAdmin && !!invite && <TogglePublicLinkLockButton cycleId={cycleId} openInviteId={openInviteId} locked={invite?.locked} />}
      </Group>
      <Space h='xl' />
      {!!openInviteLink && (
        <>
          <CopyableLink label='Public Link:' contents={openInviteLink} />
          <Space h='xl' />
        </>
      )}
      {!!invite && <PassActivityTabs activityTarget={invite} activityTargetType='Public Link' isAdmin={isAdmin} />}
      <Space h='xl' />
      <Center>
        {!!cycle && <CycleSummaryCard cycle={cycle} isAdmin={isAdmin} />}
      </Center>
      <Space h='xl' />
      <Title>
        Invite Clicks
      </Title>
      <AssessmentInvitesTable cycleId={cycleId} openInviteId={openInviteId} />
    </>
  )
}

function TogglePublicLinkLockButton ({ cycleId, openInviteId, locked = true }) {
  const [toggleLock, { isLoading: toggleLockProcessing }] = useOpenInviteLockMutation()
  return (
    <ToggleLockButton
      toggleLock={toggleLock}
      toggleLockProcessing={toggleLockProcessing}
      cycleId={cycleId}
      itemId={openInviteId}
      itemIdName='id'
      locked={locked}
    />
  )
}
