/* eslint react/prop-types: 0 */
import { Text, View } from '@react-pdf/renderer'
import React, { useContext } from 'react'
import { Html } from 'react-pdf-html'
import { QuestionType } from '../../../js/generated/enums/QuestionType'
import Styles from '.././styles/interview'
import { PdfOptions } from '../PdfPage'

export default function QuestionPage (props) {
  const options = useContext(PdfOptions)
  const styles = Styles(options).questionPage
  const answerContainer = (props.question.type === QuestionType.Interview
    ? (
    <View style={styles.answerBox}>
        <View style={styles.answerResponse} />
        <View style={styles.answerList}>
          <Text style={{ fontSize: options.defaultFontSize * 1, fontFamily: 'Helvetica-Bold' }}>Rating (Please circle)</Text>
          {props.question.answers.map((answer, index) => (
            <View key={index} style={styles.answerItem}>
              <Text style={styles.answerRatingCol}>{5 - index}</Text>
              <Text style={styles.answerText}>{answer.content}</Text>
            </View>
          ))}
        </View>
      </View>
      )
    : null)
  return (
    <View style={styles.questionBox} key={props.question.id} wrap={false}>
      <View style={styles.questionCompetencyList}>
        {props.question.competencies.map((competency, index) => (
          <View key={index}>
            <Text style={styles.questionCompetency}>{competency.name}</Text>
            {competency.description?.length > 0 ? (<Text style={styles.questionCompetencyDescription}>{competency.description}</Text>) : null}
          </View>
        ))}
      </View>
      <View style={styles.questionHeader}>
        <Text style={styles.questionNumber}>{props.number}</Text>
        <Text><Html collapse={false} stylesheet={styles.htmlStyles}>{props.question.content}</Html></Text>
      </View>
      {answerContainer}
  </View>
  )
}
