import { MoveDirection, OutMode } from './util';

export const ConfigStars = { // 10
  background: {
    color: '#000'
  },
  particles: {
    number: {
      value: 100
    },
    move: {
      direction: MoveDirection.none,
      enable: true,
      outModes: {
        default: OutMode.out
      },
      random: true,
      speed: 0.1,
      straight: false
    },
    opacity: {
      animation: {
        enable: true,
        speed: 1,
        sync: false
      },
      value: { min: 0, max: 1 }
    },
    size: {
      value: { min: 1, max: 3 }
    }
  }
}
