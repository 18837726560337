/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { useCycleOpenInvitesTable, useCyclesOpenInvites, useLazyCyclesOpenInvites } from './OpenInvitesHooks';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';

export const OpenInvitesTable = memo(function OpenInvitesTable ({ cycleId, namespace = 'cycles-open-invites' }) {
  const navigate = useNavigate()
  const [openInvites] = useLazyCyclesOpenInvites(namespace, cycleId)

  const handleRowClicked = useCallback((row) => {
    console.debug('Open invites table row clicked.', row)
    if (row === undefined) return true
    const openInviteId = row.dataset.rowid
    const intOpenInviteId = parseInt(openInviteId)
    const openInvite = (openInvites?.items?.filter(c => c.id === intOpenInviteId) ?? [])[0] ?? null
    if (!openInvite) {
      console.warn('Unable to find open invite matching clicked id.', { openInviteId, intOpenInviteId, cycleId, openInvite, openInvites })
      return true
    }
    console.debug('Navigating to open invite detail', { openInviteId, cycleId, openInvite, row })
    const path = generatePath(CyclePassRoute.CycleOpenInviteDetail, { cycleId: cycleId.toString(), openInviteId: openInviteId })
    console.debug('Generated path for open invite detail', { path, openInvite })
    navigate(path)
  }, [openInvites, cycleId, navigate])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useCycleOpenInvitesTable()

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <OpenInvitesTableDataProvider cycleId={cycleId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </OpenInvitesTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function OpenInvitesTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCyclesOpenInvites(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating OpenInvitesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Open invites table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
