/* eslint react/prop-types: 0 */
import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useCycleStageGenerationStatus, useIsBeta } from '../CyclesHooks';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Group,
  Modal,
  ScrollArea,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
  Tooltip
} from '@mantine/core';
import {
  createInitialStageGenerationState, getStageReplacementTypeOptions,
  stageGenerationStateReducer,
  StageGenerationUpdate, stageReplacementTypeIdToName
} from './StageGenerationState';
import { useGenerateCycleStagesMutation } from '../../../../../redux/query/hire/cyclesApi.slice';
import { hasAssessmentData, hasModuleData, isError } from '../../../../../js/generated/enums/StageReplacementNote';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTriangleFilled, IconTriangleInvertedFilled } from '@tabler/icons-react';

export const CycleStageReplacementApp = memo(function CycleStageReplacementApp ({ cycleId, valid, setValid, generateWithoutOld = true }) {
  const [generationStatus, querying] = useCycleStageGenerationStatus(cycleId)
  console.debug('Stage replacement app updating', { generationStatus, querying, valid, generateWithoutOld })
  return (
    <>
      {!querying && !!generationStatus && (generateWithoutOld || !!generationStatus.data?.old?.length) && (
        <CycleStageReplacementModal
          cycleId={cycleId}
          generationStatus={generationStatus.data}
          setValid={setValid}
        />
      )}
    </>
  )
})

export const CycleStageReplacementAppExternalWrapper = memo(function CycleStageReplacementAppExternalWrapper ({ cycleId }) {
  const [isBetaTester, betaTesterLoading] = useIsBeta()
  const [stagesValid, setStagesValid] = useState(null)
  console.debug('CycleStageReplacementAppExternalWrapper updating', { cycleId, isBetaTester })
  return (
    <>
      {!betaTesterLoading && !!isBetaTester && (
        <CycleStageReplacementApp
          cycleId={cycleId}
          valid={stagesValid}
          setValid={setStagesValid}
          generateWithoutOld={false}
        />)}
    </>
  )
})

const CycleStageReplacementModal = memo(function CycleStageReplacementModal ({ cycleId, generationStatus, setValid }) {
  const [generate, { isLoading: processing }] = useGenerateCycleStagesMutation()

  /**
   * @member {StageGenerationState} state
   */
  const [state, dispatch] = useReducer(
    stageGenerationStateReducer,
    generationStatus,
    createInitialStageGenerationState
  )
  const openedRef = useRef(null)

  const generationNeeded = state.generationNeeded
  const [opened, setOpened] = useState(generationNeeded)
  const stateResolved = !!(state.manualResolved && state.resolvable && state.warningsViewed)
  const stateLocked = state.locked

  const newStageIds = state.new
  const stateStageData = state.stages
  const oldToNewAssignments = state.oldToNewAssignments

  useEffect(() => {
    if (stateLocked) {
      const parsedNewStages = newStageIds.map(stageId => stateStageData.get(stageId)).map(stageData => {
        return {
          config: {
            collectDemographics: stageData.config.collectDemographics,
            proctored: stageData.config.proctored,
            timeLimit: stageData.config.timeLimit,
            phase: stageData.config.phaseId
          },
          index: stageData.index,
          assessment: stageData.assessment.id,
          lookupId: stageData.id
        }
      })
      const parsedAssignments = [...oldToNewAssignments.values()].map(replacementData => {
        return {
          replacedStage: replacementData.oldId,
          type: replacementData.type,
          newIndex: replacementData.index,
          newLookupId: replacementData.newId
        }
      })
      generate({ cycleId: cycleId, assignments: parsedAssignments, stages: parsedNewStages })
        .unwrap()
        .then((result) => {
          console.debug('Generate stages response', result)
          showNotification({
            title: 'Stages Generated',
            message: 'Stage generation was successful!',
            color: 'green',
            autoClose: 3000
          })
          setValid(true)
          setOpened(false)
        })
        .catch((err) => {
          console.error('Generate stages error', err)
          showNotification({
            title: 'Stage Generation Error',
            message: 'There was an unexpected error when generating stages! Refreshing the page may help.',
            color: 'red',
            autoClose: 5000
          })
        })
        .finally(() => {
          dispatch({ type: StageGenerationUpdate.Generate, complete: true })
        })
    }
  }, [stateLocked, setValid, cycleId, newStageIds, stateStageData, oldToNewAssignments, generate, dispatch])

  useEffect(() => {
    if (!openedRef.current) {
      openedRef.current = true
      setValid(!generationNeeded)
    }
  }, [generationNeeded, setValid])

  console.debug('Stage replacement modal updating', { state, generationNeeded, stateResolved, opened })

  const startGeneration = () => {
    dispatch({ type: StageGenerationUpdate.Generate, complete: false, notifyIncomplete: true })
  }
  const viewRemainingIssues = () => {
    dispatch({ type: StageGenerationUpdate.CheckResolved, notifyIncomplete: true })
  }

  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)} title='Cycle Stage Generation' size='95%'>
        <Box mah='90vh'>
          <CycleStageWarningWindow warnings={state.sourceWarnings} errors={state.sourceErrors} viewed={state.warningsViewed} dispatch={dispatch} />
          <StageReplacementPicker state={state} dispatch={dispatch} />
          <Space h='md' />
          {!!generationNeeded && (
            <Center>
              <Button onClick={startGeneration} loading={processing || state.locked} disabled={!stateResolved}>
                Generate
              </Button>
            </Center>
          )}
          {!stateResolved && !!state.loaded && !!generationNeeded && (
            <>
              <Space h='md' />
              <Center>
                <Button onClick={viewRemainingIssues}>
                  View Warnings and Assign Stage Replacements to Continue
                </Button>
              </Center>
            </>
          )}
          {!!state.loaded && !generationNeeded && (<Text ta='center'>No Stage Generation Needed</Text>)}
        </Box>
      </Modal>
    </>
  )
})

const maxWarningsPulses = 1000
const warningPulsesScaleCycle = 4

const CycleStageWarningWindow = memo(function CycleStageWarningWindow ({ warnings, errors, viewed, dispatch }) {
  const [opened, setOpened] = useState(false)

  // console.debug('CycleStageWarningWindow updating', { warnings, errors, opened })
  const hasWarnings = Object.keys(warnings).length
  const hasErrors = Object.keys(errors).length
  const hasAnyIssues = !!(hasWarnings || hasErrors)

  const [pulseCount, setPulseCount] = useState(hasAnyIssues ? 0 : maxWarningsPulses)

  const showWindow = () => {
    dispatch({ type: StageGenerationUpdate.CheckResolved, isWarningView: true })
    setPulseCount(maxWarningsPulses)
    setOpened(true)
  }

  useEffect(() => {
    if (hasAnyIssues && (pulseCount < maxWarningsPulses)) {
      const timeoutId = setTimeout(() => {
        setPulseCount((prev) => prev + 1)
      }, 31)

      return () => clearTimeout(timeoutId)
    }
  }, [opened, hasAnyIssues, pulseCount])

  const dynamic = (pulseCount < maxWarningsPulses)
  // const buttonSize = !dynamic ? 'lg' : (pulseCount % 2 ? 'md': 'xl')
  const variant = (!dynamic && viewed) ? 'filled' : 'gradient'
  const deg = (pulseCount / maxWarningsPulses) * 359
  const from = hasErrors ? (hasWarnings ? 'rgba(255, 0, 0, 1)' : 'rgba(135, 0, 0, 1)') : 'rgba(145, 36, 0, 1)'
  const to = hasErrors ? (hasWarnings ? 'rgba(209, 118, 15, 1)' : 'rgba(255, 0, 0, 1)') : 'rgba(209, 118, 15, 1)'
  const colorProps = (dynamic || !viewed) ? { gradient: { from, to, deg } } : { color: 'violet' }
  const warningPulsesScaleCycleRemainder = (pulseCount % warningPulsesScaleCycle)
  const warningPulsesShrinkCycleRemainder = (pulseCount % (warningPulsesScaleCycle * 2))
  const warningPulsesIgnoreCycleRemainder = (pulseCount % (warningPulsesScaleCycle * 20))
  const showScaleCycle = warningPulsesIgnoreCycleRemainder < (warningPulsesScaleCycle * 2)
  const growScaleCycle = warningPulsesShrinkCycleRemainder < warningPulsesScaleCycle
  const scaleCycleOffset = growScaleCycle ? 0 : warningPulsesScaleCycle
  const scaleCyclePosition = (1 + ((scaleCycleOffset + (growScaleCycle ? warningPulsesScaleCycleRemainder : -warningPulsesScaleCycleRemainder)) / (warningPulsesScaleCycle * 10))).toLocaleString('en-US', { maximumFractionDigits: 2 })
  const styleProps = dynamic && showScaleCycle ? { style: { scale: `${scaleCyclePosition} ${scaleCyclePosition}` } } : {}

  return (
    <>
      {hasAnyIssues && <Center><Button onClick={showWindow} variant={variant} { ...colorProps } { ...styleProps }>View Detected Issues</Button></Center>}
      <Modal opened={opened} onClose={() => setOpened(false)} title='Detected Stage Generation Issues' size='60%'>
        <Box mah='90vh'>
          <StagesWarningList data={errors} isError={true} />
          <Space h='md' />
          <StagesWarningList data={warnings} isError={false} />
        </Box>
      </Modal>
    </>
  )
})

const StagesWarningList = memo(function StagesWarningList ({ data, isError }) {
  console.debug('StagesWarningList updating.', { isError, data })
  const hasItems = Object.keys(data).length
  const color = hasItems ? (isError ? 'red.4' : 'yellow.4') : 'green.4'
  const cssColor = `var(--mantine-color-${color.split('.').join('-')})`
  const name = isError ? 'Errors' : 'Warnings'
  const message = hasItems ? name : `No ${name} Detected`
  return (
    <CollapseBox title={name} titleOrder={2} cssColor={cssColor} mantineColor={color}>
      <ScrollArea.Autosize mah='35vh' scrollbars='y' mx='auto' type='always'>
        <Stack justify='flex-start' align='stretch' gap='md' px='1rem'>
          {!hasItems && <Center mt='1rem'><Title order={3} c={color}>{message}</Title></Center>}
          {Object.entries(data).map(([key, value]) => <StagesWarningListItem key={key} name={key} data={value} />)}
        </Stack>
      </ScrollArea.Autosize>
    </CollapseBox>
  )
})

const StagesWarningListItem = memo(function StagesWarningListItem ({ name, data }) {
  const isErrorItem = isError(name)
  const isModuleList = hasModuleData(name)
  const isAssessmentList = hasAssessmentData(name)
  console.debug('StagesWarningListItem updating.', { name, data, isErrorItem, isModuleList, isAssessmentList })
  const cssColor = isErrorItem ? 'var(--mantine-color-red-4)' : 'var(--mantine-color-yellow-4)'
  const mantineColor = isErrorItem ? 'red.4' : 'yellow.4'
  return (
    <CollapseBox title={name} titleOrder={4} expandedPB='0px' wrapperProps={stagesListItemWrapperProps} cssColor={cssColor} mantineColor={mantineColor}>
      <StagesWarningTable>
        {Object.entries(data).map(([key, value], index) => (
          <StagesWarningTableRow key={key} color={index % 2 ? null : cssColor}>
            {!!isModuleList && <StagesWarningModuleDataItem data={value} />}
            {!!isAssessmentList && <StagesWarningAssessmentDataItem data={value} />}
            {!isModuleList && !isAssessmentList && <StagesWarningStringDataItem data={value} />}
          </StagesWarningTableRow>
        ))}
      </StagesWarningTable>
    </CollapseBox>
  )
})

const StagesWarningTable = memo(function StagesWarningTable ({ children }) {
  return (
    <Box py={0} px={0}>
      <Stack align='stretch' justify='flex-start' gap={0}>
        {children}
      </Stack>
    </Box>
  )
})

function StagesWarningTableRow ({ color, children }) {
  const boxProps = color ? { style: { backgroundColor: color } } : {}
  return (
    <Box { ...boxProps } py='0.5rem' px='1rem'>
      <Group justify='space-between' wrap='nowrap'>
        {children}
      </Group>
    </Box>
  )
}

/**
 * @type {React.NamedExoticComponent<{data: SourceStageGenerationModuleWarning}>}
 */
const StagesWarningModuleDataItem = memo(function StagesWarningModuleDataItem ({ data }) {
  console.debug('StagesWarningModuleDataItem updating.', { data })
  const cProps = data.visible ? {} : { c: 'dimmed' }
  return (
    <>
      <Text ta='left' lineClamp={1} { ...cProps }>#{data.id} {data.name}</Text>
    </>
  )
})

/**
 * @type {React.NamedExoticComponent<{data: SourceStageGenerationAssessmentError}>}
 */
const StagesWarningAssessmentDataItem = memo(function StagesWarningAssessmentDataItem ({ data }) {
  console.debug('StagesWarningAssessmentDataItem updating.', { data })
  return (
    <>
      <Text ta='left' lineClamp={1}>#{data.id} {data.name} was repeated across {data.repeatCount} otherwise unrelated cycles/modules linked to this cycle.</Text>
    </>
  )
})

const StagesWarningStringDataItem = memo(function StagesWarningStringDataItem ({ data }) {
  console.debug('StagesWarningStringDataItem updating.', { data })
  return (
    <>
      <Text ta='left' lineClamp={1}>{data}</Text>
    </>
  )
})

/**
 * @type {React.NamedExoticComponent<{state: StageGenerationState, dispatch}>}
 */
const StageReplacementPicker = memo(function StageReplacementPicker ({ state, dispatch }) {
  return (
    <>
      <SimpleGrid cols={2} spacing={0}>
        <StagesList stages={state.stages} stageIds={state.old} phases={state.phases} isNew={false} dispatch={dispatch} otherStageIds={state.new} replacements={state.oldToNewAssignments} />
        <StagesList stages={state.stages} stageIds={state.new} phases={state.phases} isNew={true} dispatch={dispatch} />
      </SimpleGrid>
    </>
  )
})

/**
 * @type {React.NamedExoticComponent<{stages: Map.<int, TransientStage>, stageIds: int[], phases: CycleStageGenerationPhase[], isNew: boolean, otherStageIds, replacements, dispatch}>}
 */
const StagesList = memo(function StagesList ({ stages, stageIds, phases, isNew, otherStageIds = null, replacements = null, dispatch = null }) {
  console.debug('Stages list updating.', { stageIds, phases, isNew })
  const ignoredPhaseIds = useMemo(() => {
    let ignored = new Set()
    for (const phase of phases) {
      const testList = isNew ? phase.new : phase.old
      if (!testList.length) {
        ignored.add(phase.id)
      } else {
        ignored = new Set()
      }
    }
    return ignored
  }, [phases, isNew])
  return (
    <>
      <Stack justify='flex-start' align='stretch' gap='md'>
        <Divider my='xs' label={<Title order={3}>{isNew ? 'New Stages' : 'Old Stages'}</Title>} />
        <ScrollArea.Autosize mah='50vh' scrollbars='y'>
          <Stack justify='flex-start' align='stretch' gap='md' px='1rem'>
            {!stageIds.length && <Title>{isNew ? 'Unable to generate new stages' : 'No previous stages'}</Title>}
            {!!stageIds.length && phases.map(phase => (
              <PhasesListItem
                key={phase.id}
                phase={phase}
                stages={stages}
                ignored={ignoredPhaseIds.has(phase.id)}
                isNew={isNew}
                otherStageIds={otherStageIds}
                replacements={replacements}
                dispatch={dispatch}
              />))}
          </Stack>
        </ScrollArea.Autosize>
      </Stack>
    </>
  )
})

/**
 * @type {React.NamedExoticComponent<{phase: CycleStageGenerationPhase, stages: Map.<int, TransientStage>, ignored: boolean, isNew: boolean, otherStageIds, replacements, dispatch}>}
 */
const PhasesListItem = memo(function PhasesListItem ({ phase, stages, ignored = false, isNew = false, otherStageIds = null, replacements = null, dispatch = null }) {
  console.debug('PhasesListItem updating.', { phase, isNew })
  const stageIds = isNew ? phase.new : phase.old
  return (
    <>
      {!ignored && (
        <CollapseBox title={phase.name} titleOrder={4}>
          {!stageIds.length && <Center mt='1rem'><Title order={5}>Skipped</Title></Center>}
          {stageIds.map(stageId => (
            <StagesListItem
              key={stageId}
              stage={stages.get(stageId)}
              isNew={isNew}
              dispatch={dispatch}
              stageReplacementProps={replacements ? { newStageIds: otherStageIds, stages: stages, replacement: replacements.get(stageId) } : null}
            />
          ))}
        </CollapseBox>
      )}
    </>
  )
})

function CollapseBox ({ title, children, titleOrder = 4, expandedPB = '1rem', wrapperProps = {}, cssColor = 'var(--mantine-color-blue-4)', mantineColor = 'blue.4' }) {
  const [collapsed, setCollapsed] = useState(false)
  const icon = collapsed ? <IconTriangleFilled style={{ width: '100%', height: '100%' }} stroke={1.5} /> : <IconTriangleInvertedFilled style={{ width: '100%', height: '100%' }} stroke={1.5} />
  return (
    <Box style={{ outline: `2px solid ${cssColor}`, outlineOffset: '-2px' }} pb={collapsed ? '0px' : expandedPB} { ...wrapperProps }>
      <Box style={{ outline: `2px solid ${cssColor}`, outlineOffset: '-2px' }} py='1rem'>
        <Group justify='space-between' wrap='nowrap'>
          <Box maw='75%'>
            <Title mx='1rem' order={titleOrder} lineClamp={1}>
              {title}
            </Title>
          </Box>
          <ActionIcon mx='1rem' size='1rem' variant='subtle' color={mantineColor} aria-label={collapsed ? 'Expand' : 'Collapse'} onClick={() => setCollapsed(!collapsed)}>
            {icon}
          </ActionIcon>
        </Group>
      </Box>
      {!collapsed && children}
    </Box>
  )
}

const stagesListItemWrapperProps = { mx: '1rem', my: '1rem' }

/**
 * @type {React.NamedExoticComponent<{stage: TransientStage, stageReplacementProps: ?object, isNew: boolean, dispatch}>}
 */
const StagesListItem = memo(function StagesListItem ({ stage, stageReplacementProps = null, isNew = false, dispatch = null }) {
  console.debug('Stages list item updating.', { stage })
  return (
    <>
      <CollapseBox title={`${stage.index + 1}. ${stage.assessment.name}${stage.error ? ' (error)' : ''}`} titleOrder={5} expandedPB='0px' wrapperProps={stagesListItemWrapperProps}>
        <StageConfigDetail config={stage.config} isNew={isNew} dispatch={dispatch} />
        {!!stageReplacementProps && <StageReplacementEditor stageId={stage.id} dispatch={dispatch} { ...stageReplacementProps } />}
      </CollapseBox>
    </>
  )
})

const boolConfigOptions = {
  Always: true,
  No: false
}
const boolConfigOptionLabels = Object.keys(boolConfigOptions)
const proctoredSettingLabel = 'Proctor Phase Invites'

/**
 * @type {React.NamedExoticComponent<{config: TransientStageConfig, isNew: boolean, dispatch}>}
 */
const StageConfigDetail = memo(function StageConfigDetail ({ config, isNew = false, dispatch }) {
  console.debug('StageConfigDetail updating.', { config })
  const rows = useMemo(() => {
    return [
      [proctoredSettingLabel, config.proctored ? 'Always' : 'No', isNew ? (value) => dispatch({ type: StageGenerationUpdate.SetNewStageConfigProctored, value: value, targetStage: config.stageId }) : null],
      ['Time Limit', config.timeLimit ? Math.floor(config.timeLimit / 60) + 'm' + (config.timeLimit % 60 ? `${config.timeLimit % 60}s` : '') : 'None', null],
      ['Collect Demographics', config.collectDemographics ? 'Always' : 'No', null]
    ]
  }, [config, isNew, dispatch])
  return (
    <StageConfigTable rows={rows} color='var(--mantine-color-blue-1)' />
  )
})

const StageConfigTable = memo(function StageConfigTable ({ rows, color }) {
  return (
    <Box py={0} px={0}>
      <Stack align='stretch' justify='flex-start' gap={0}>
        {rows.map(([name, value, edit], index) => (
          <StageConfigTableRow
            key={name}
            name={name}
            value={value}
            color={index % 2 ? null : color}
            edit={edit}
          />
        ))}
      </Stack>
    </Box>
  )
})

function StageConfigTableRow ({ name, value, color, edit }) {
  const boxProps = color ? { style: { backgroundColor: color } } : {}
  const showProctorEditor = !!(edit && (name === proctoredSettingLabel))
  return (
    <Box { ...boxProps } py='0.5rem' px='1rem'>
      <Group justify='space-between' wrap='nowrap'>
        <Text lineClamp={1}>
          {name}
        </Text>
        {!showProctorEditor && (
          <Text lineClamp={1} fw={500}>
            {value}
          </Text>
        )}
        {showProctorEditor && (
          <ProctoredSettingEditor value={value} edit={edit} />
        )}
      </Group>
    </Box>
  )
}

function ProctoredSettingEditor ({ value, edit }) {
  return (
    <Select
      data={boolConfigOptionLabels}
      value={value}
      placeholder='Choose proctored setting'
      required
      onChange={(newValue) => edit(newValue ? (boolConfigOptions[newValue] ?? false) : false)}
    />
  )
}

const stageReplacementColumnSpan = { base: 13, md: 6, lg: 5 }
const stageReplacementFirstColumnOffset = { base: 0, md: 0, lg: 1 }
const stageReplacementSecondColumnOffset = { base: 0, md: 1, lg: 1 }
const stageReplacementGridGutter = { base: 'sm', md: 'lg', lg: 0 }
const replacementTypeIconProps = {
  stroke: 2,
  color: 'currentColor',
  opacity: 1,
  size: 22
}

export function renderStageReplacementTypeOption ({ option, checked }) {
  return (
    <Tooltip label={option.description} inline multiline withArrow arrowSize={8} w='30vw'>
      <Group flex='1' gap='xs' wrap='nowrap'>
        {checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...replacementTypeIconProps} />}
        {option.label}
      </Group>
    </Tooltip>
  )
}

/**
 * @type {React.NamedExoticComponent<{replacement: ?TransientStageReassignment, stageId: int, newStageIds, stages, dispatch}>}
 */
const StageReplacementEditor = memo(function StageReplacementEditor ({ replacement, stageId, newStageIds, stages, dispatch }) {
  const replacementTypeOptions = useMemo(() => {
    return getStageReplacementTypeOptions(replacement, stages)
  }, [replacement, stages])
  const replacementStageOptions = useMemo(() => {
    return newStageIds.map(stageId => {
      const newStage = stages.get(stageId)
      return { value: stageId.toString(), label: (newStage.index + 1).toString() + '. ' + newStage.assessment.name }
    })
  }, [newStageIds, stages])
  console.debug('StageReplacementEditor updating.', { replacement, stageId, replacementTypeOptions, replacementStageOptions })
  return (
    <>
      <Space h='md' />
      <Grid columns={13} gutter={stageReplacementGridGutter} align='flex-start'>
        <Grid.Col span={stageReplacementColumnSpan} offset={stageReplacementFirstColumnOffset}>
          <Stack justify='flex-start' align='center' gap='xxs'>
            <Text my={0} py={0} ta='center'>Replacement Stage</Text>
            <Select
              data={replacementStageOptions}
              value={replacement?.newId?.toString() ?? null}
              placeholder='Choose new stage'
              required
              onChange={(value) => { dispatch({ type: StageGenerationUpdate.AssignStageReplacement, targetStage: stageId, replacementStage: value ? parseInt(value) : null }) }}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={stageReplacementColumnSpan} offset={stageReplacementSecondColumnOffset}>
          <Stack justify='flex-start' align='center' gap='xxs'>
            <Text my={0} py={0} ta='center'>Replacement Type</Text>
            {!replacementTypeOptions && <Text fw={700} my={0} py={0} ta='center' c='red.5'>{replacement?.type ? stageReplacementTypeIdToName[replacement.type] : 'Choose stage to select type'}</Text>}
            {!!replacementTypeOptions && (
              <Select
                data={replacementTypeOptions}
                value={replacement?.type?.toString() ?? null}
                placeholder='Transfer stage results?'
                required
                error={replacement?.type ? null : <Text size='xs'>Must select a replacement type</Text>}
                renderOption={renderStageReplacementTypeOption}
                onChange={(value) => { dispatch({ type: StageGenerationUpdate.ChangeStageReplacementType, targetStage: stageId, replacementType: value ? parseInt(value) : null }) }}
              />
            )}
          </Stack>
        </Grid.Col>
      </Grid>
      <Space h='md' />
    </>
  )
})
