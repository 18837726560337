/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Anchor,
  AspectRatio,
  Badge,
  Button,
  Card,
  Flex,
  Group,
  Image,
  Modal, ScrollArea, Select, SimpleGrid, Space,
  Text,
  TextInput
} from '@mantine/core'
import { useSearchParams } from 'react-router-dom'
import { mediaTypeIdToColors, mediaTypeIdToTypes, useMediaCollection } from './MediaHooks'
import _ from 'lodash'
import { useDebouncedState } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { deleteMedia } from '../../../js/api/media_repository'
import { IconExternalLink, IconSearch } from '@tabler/icons-react'
import Paginator from '../../core/ReactTable/Toolbar/Paginator'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { formatToOptions } from '../../../js/util/DataUtil'
import { MediaType } from '../../../js/generated/enums/MediaType';
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function MediaHome ({ queryId, setQueryId, syncParams = true, selectCallback = null, editMediaCallback = null, createMediaCallback = null }) {
  const [searchParams, setSearchParams] = useSearchParams({ limit: '24', page: '1', search: '' })
  const [mediaToDelete, setMediaToDelete] = useState(false)
  const [search, setSearch] = useDebouncedState(searchParams.get('search'), 300)

  useEffect(() => {
    if (syncParams) {
      if (search !== searchParams.get('search')) {
        setSearchParams((prev) => {
          return { ...Object.fromEntries(prev.entries()), search: search, page: '1' }
        })
      }
    }
  }, [search, searchParams, setSearchParams, syncParams])

  const formattedParams = useMemo(() => {
    console.debug('Params or query id changed - returning new params dict.', searchParams, queryId)
    return Object.fromEntries(searchParams.entries())
  }, [searchParams, queryId])

  const collection = useMediaCollection(formattedParams)
  const pageTotal = Math.ceil(collection.total / collection.limit)

  const remove = (targetMedia) => {
    deleteMedia(targetMedia.id)
      .then(() => {
        showNotification({
          message: 'Successfully deleted media',
          color: 'success'
        })
        setQueryId((prev) => prev + 1)
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting this media',
          color: 'red'
        })
      })
      .finally(() => setMediaToDelete(false))
  }

  const mediaList = useMemo(() => (
      <SimpleGrid cols={{ lg: 4, md: 3, sm: 2, xs: 2 }} spacing='xs'>
          {
          _.map(collection.items, media => (
              <Card key={media.id} shadow='sm' padding='xs' radius='md' withBorder>
                  <Card.Section>
                      <AspectRatio ratio={16 / 9}>
                        {
                          mediaTypeIdToTypes[media.type] === MediaType.Image
                            ? <Image src={media.link} fallbackSrc={media.identifier} radius='sm' />
                            : (
                              <video width='100%' style={{ borderRadius: 'var(--mantine-radius-sm)' }}>
                                <source src={media.link} type='video/mp4'/>
                                Your browser does not support the video tag.
                              </video>
                              )
                        }
                      </AspectRatio>
                  </Card.Section>
                  <Card.Section p='xs'>
                    <Text mt='5px' weight={600} truncate>{media.identifier}</Text>
                    <Group justify='space-between' mt='md' mb='xs'>
                      <Badge color={mediaTypeIdToColors[media.type]} variant='light'>
                        {mediaTypeIdToTypes[media.type]}
                      </Badge>
                        {dayjs(media.uploaded).format('MM/DD/YYYY')}
                    </Group>
                    <Flex justify='flex-end'>
                      {mediaTypeIdToTypes[media.type] === MediaType.Image
                        ? (
                        <Text size='sm' c='dimmed'>
                          {media.width}x{media.height}
                        </Text>
                          )
                        : (
                        <Space h='lg' />
                          )}
                    </Flex>
                    <ScrollArea h={75}>
                      <Text size='sm' c='dimmed'>
                          {media.description ? media.description : 'No description provided.'}
                      </Text>
                    </ScrollArea>
                  </Card.Section>
                  {selectCallback
                    ? (
                    <Card.Section>
                      <Group gap='xs' wrap='nowrap' justify='center' grow>
                        <Button leftSection={<IconExternalLink size="1.0rem"/>} color='success' onClick={() => selectCallback(media)}>Select</Button>
                      </Group>
                      <Space h='sm' />
                    </Card.Section>
                      )
                    : null}
                  <Card.Section>
                    <Group gap='xs' grow>
                        {editMediaCallback
                          ? (
                          <Button variant='subtle' color='blue' onClick={() => editMediaCallback(media)}>Edit</Button>
                            )
                          : (
                          <Button variant='subtle' color='blue' component={Anchor} href={`/build/assessments/media/${media.id}/edit`}>Edit</Button>
                            )}
                        <Button variant='subtle' color='red' onClick={() => setMediaToDelete(media)}>Delete</Button>
                    </Group>
                  </Card.Section>
              </Card>
          ))
          }
      </SimpleGrid>
  ), [collection, selectCallback, editMediaCallback])

  return (
    <>
        <Modal opened={!!mediaToDelete} onClose={() => setMediaToDelete(false)} title='Are you sure you want to delete this media?'>
            <Group spacing='xs'>
                <Button color='red.6' onClick={() => remove(mediaToDelete) }>Delete</Button>
                <Button color='gray.6' onClick={() => setMediaToDelete(false)}>Cancel</Button>
            </Group>
        </Modal>
        <SimpleGrid cols={1}>
            <Group>
                {createMediaCallback
                  ? (
                  <Button color='success' onClick={() => createMediaCallback()}>Create</Button>
                    )
                  : (
                  <Button color='success' component={Anchor} href='/build/assessments/media/create'>Create</Button>
                    )}
                <Select
                    data={formatToOptions(mediaTypeIdToTypes)}
                    placeholder='Type'
                    clearable
                    value={searchParams.get('types[]') === null ? null : Number(searchParams.get('types[]'))}
                    onChange={value => {
                      if (value === null) {
                        setSearchParams(prev => {
                          const newParams = { ...Object.fromEntries(prev.entries()), page: '1' }
                          delete newParams['types[]']
                          return newParams
                        })
                      } else {
                        setSearchParams(prev => { return { ...Object.fromEntries(prev.entries()), page: '1', 'types[]': [value] } })
                      }
                    }}
                />
                <TextInput
                    placeholder='Search'
                    icon={<IconSearch size={16}/>}
                    onChange={event => setSearch(event.target.value)}
                    defaultValue={searchParams.get('search') ?? ''}
                />
            </Group>
            {collection.total / collection.limit > 1 && <Paginator total={pageTotal} page={Number(searchParams.get('page')) ?? 1} onUpdate={value => {
              setSearchParams((prev) => { return { ...Object.fromEntries(prev.entries()), page: value } })
            }} />}
            {mediaList}
            {collection.total / collection.limit > 1 && <Paginator total={pageTotal} page={Number(searchParams.get('page')) ?? 1} onUpdate={value => {
              setSearchParams((prev) => { return { ...Object.fromEntries(prev.entries()), page: value } })
            }} />}
        </SimpleGrid>
    </>
  )
}
