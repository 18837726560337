import { useEffect, useRef, useState } from 'react'
import {
  getSkillBuilderCycleAssessment,
  getSkillBuilderLivePDFViewAssessment
} from '../../../js/api/skillbuilder_repository'
import axios from 'axios';

export function useCycleAssessment (cycleId, assessmentId, params = null) {
  const [assessment, setAssessment] = useState(null)
  const [querying, setQuerying] = useState(1)
  const queryIdRef = useRef(0)

  useEffect(() => {
    const queryId = queryIdRef.current + 1
    queryIdRef.current = queryId
    console.info('Starting cycle assessment query id.', queryId)
    setQuerying(queryId)
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getSkillBuilderCycleAssessment(cycleId, assessmentId, params, cancelSource.token)
      .catch(err => {
        console.error('Unexpected request error.', err, cycleId, assessmentId, params)
        return null
      })
      .then(assessment => {
        if (assessment) {
          setAssessment(assessment)
        }
      })
      .finally(() => {
        if (queryIdRef.current === queryId) {
          console.info('Query id ref unchanged - ending querying flag.', queryIdRef.current, queryId)
          setQuerying(prev => prev === queryId ? 0 : prev)
        }
      })

    return () => {
      console.debug('Load cycle assessment data effect unmounted.', cycleId, assessmentId, params, cancelSource)
      cancelSource.cancel()
    }
  }, [cycleId, assessmentId, params])

  return [assessment, querying]
}

export function useLivePDFViewCycleAssessment (cycleId, assessmentId, params = null) {
  const [assessment, setAssessment] = useState(null)
  const [querying, setQuerying] = useState(1)
  const queryIdRef = useRef(0)

  useEffect(() => {
    const queryId = queryIdRef.current + 1
    queryIdRef.current = queryId
    console.info('Starting live PDF view query id.', queryId)
    setQuerying(queryId)
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()

    getSkillBuilderLivePDFViewAssessment(cycleId, assessmentId, params, cancelSource.token)
      .catch(err => {
        console.error('Unexpected request error.', err, cycleId, assessmentId, params)
        return null
      })
      .then(assessment => {
        if (assessment?.data) {
          setAssessment(assessment.data)
        }
      })
      .finally(() => {
        if (queryIdRef.current === queryId) {
          console.info('Query id ref unchanged - ending querying flag.', queryIdRef.current, queryId)
          setQuerying(prev => prev === queryId ? 0 : prev)
        }
      })

    return () => {
      console.debug('Load PDFViewCycleAssessment data effect unmounted.', cycleId, assessmentId, params, cancelSource)
      cancelSource.cancel()
    }
  }, [cycleId, assessmentId, params])

  return [assessment, querying]
}
