/* eslint react/prop-types: 0 */
import { Text, View } from '@react-pdf/renderer'
import React, { useContext, useMemo } from 'react'
import { QuestionType } from '../../../js/generated/enums/QuestionType'
import Styles from '.././styles/interview'
import { PdfOptions } from '../PdfPage'

export default function InterviewSummary (props) {
  const styles = Styles(useContext(PdfOptions)).summary
  for (const question of Object.values(props.questions)) {
    const competenciesSection = []
    let compCount = 0
    for (const competency of question.competencies) {
      competenciesSection.push(
          <Text key={compCount}>{competency.name}</Text>
      )
      compCount++
    }

    const ratingsSection = []
    for (let i = 0; i < props.assessment?.interviewer_count; i++) {
      ratingsSection.push(
        <Text key={i} style={ [styles.blankLine, { width: getRatingWidth(props.assessment) - 1 + '%' }] }></Text>
      )
    }

    ratingsSection.push(<Text key={props.assessment?.interviewer_count} style={[styles.blankLine, { width: getRatingWidth(props.assessment) - 1 + '%' }]}></Text>)
  }

  return (
    <View wrap>
      <Text style={styles.pageHeader}>Interview Summary</Text>
      <InterviewInfoBox assessment={props.assessment} />
      <QuestionTable assessment={props.assessment} questions={props.questions} />
      <SummaryRatingsSection assessment={props.assessment} />
    </View>
  )
}

function InterviewInfoBox (props) {
  const interviewerList = []
  const styles = Styles(useContext(PdfOptions)).summary
  for (let i = 0; i < props.assessment?.interviewer_count; i++) {
    interviewerList.push(
      <View key={i} style={styles.interviewerListItem}>
        <Text>Interviewer {i + 1}:</Text><Text style={[styles.blankLine, { width: '5.75in' }]}></Text>
      </View>
    )
  }

  return (
    <View style={styles.infoBox}>
      <View style={styles.interviewInfo}>
        <View style={styles.ivInfoTextWrapper}><Text>Date:</Text><Text style={[styles.blankLine, { width: '1.5in' }]}></Text></View>
        <View style={styles.ivInfoTextWrapper}><Text>Applicant&apos;s Name / ID:</Text><Text style={[styles.blankLine, { width: '2.5in' }]}> </Text></View>
      </View>
      <View style={styles.interviewerList}>
        {interviewerList}
      </View>
    </View>
  )
}

function QuestionTable (props) {
  const options = {
    ...useContext(PdfOptions),
    ratingWidth: getRatingWidth(props.assessment),
    ratingFontSize: useContext(PdfOptions).font.size - (0.007 * Math.pow(props.assessment?.interviewer_count + 1, 3) + 1.5)
  }

  const styles = Styles(options).questionTable

  const ratingHeaders = []
  const ratingSection = []
  for (let i = 0; i < props.assessment?.interviewer_count; i++) {
    ratingHeaders.push(
      <Text key={i} style={[styles.interviewerHeader]}>Interviewer {i + 1}</Text>
    )
    ratingSection.push(
      <Text key={i} style={ [styles.blankLine, { width: getRatingWidth(props.assessment) + '%' }] }></Text>
    )
  }

  const interviewQuestions = useMemo(() => {
    return props.questions.filter(q => q.type === QuestionType.Interview).toSorted((a, b) => a.position - b.position)
  }, [props.questions])

  return (
    <View style={styles.qTable}>
      <View style={[styles.row, styles.header]} fixed>
        <Text style={[styles.col, styles.questionCol]} >Question</Text>
        <Text style={[styles.col, styles.competencyCol]}>Competency</Text>
        <Text style={[styles.col, styles.ratingsCol]}>Ratings</Text>
      </View>
      <View style={[styles.row, styles.ratingHeaders]} fixed>
        <View style={[styles.col, styles.ratingsCol, { width: '100%' }]}>
          {ratingHeaders}
          <Text style={styles.interviewerHeader}>Consensus</Text>
        </View>
      </View>
      {Object.values(interviewQuestions).map((question, index) => {
        return (
            <View key={question.id} style={styles.row} wrap={false}>
              <View style={[styles.col, styles.questionCol]}>
                <Text>{index + 1}.</Text>
              </View>
              <View style={[styles.col, styles.competencyCol]}>
                <View style={styles.competencyList}>
                  {question.competencies.map(competency => {
                    return (
                      <Text key={competency.id}>{competency.name}</Text>
                    )
                  })}
                </View>
              </View>
              <View style={[styles.col, styles.ratingsCol]}>
                {ratingSection}
                <Text style={ [styles.blankLine, { width: getRatingWidth(props.assessment) - 1 + '%' }] }></Text>
              </View>
            </View>
        )
      })}
        <View key={-1} style={styles.row} wrap={false}>
          <View style={[styles.col, styles.questionCol]}>
          </View>
          <View style={[styles.col, styles.competencyCol]}>
            <View style={styles.competencyList}>
            <Text>Verbal Reasoning</Text>
            </View>
          </View>
          <View style={[styles.col, styles.ratingsCol]}>
            {ratingSection}
            <Text style={ [styles.blankLine, { width: getRatingWidth(props.assessment) - 1 + '%' }] }></Text>
          </View>
        </View>
        <View key={-2} style={styles.row} wrap={false}>
          <View style={[styles.col, styles.questionCol]}>
          </View>
          <View style={[styles.col, styles.competencyCol]}>
            <View style={styles.competencyList}>
              <Text>Listening & Understanding Others</Text>
            </View>
          </View>
          <View style={[styles.col, styles.ratingsCol]}>
            {ratingSection}
            <Text style={ [styles.blankLine, { width: getRatingWidth(props.assessment) - 1 + '%' }] }></Text>
          </View>
        </View>
    </View>
  )
}

function SummaryRatingsSection (props) {
  const options = useContext(PdfOptions)
  const styles = Styles(options).summaryRatings

  return (
    <>
      <View style={[styles.row, styles.summaryRatingsBox]} wrap={false}>
        <View style={styles.summaryRatingsText}>
          <Text>Average Consensus Rating (Total Consensus Points/# of Qs)</Text><View style={[styles.checkbox, styles.consensusRating]}/>
        </View>
        <View style={styles.summaryRatingsText}>
          <Text>Number of 1 ratings (consensus)</Text><View style={[styles.checkbox, styles.consensusRating]}/>
        </View>
      </View>
      <View style={styles.recommendedWrapper} wrap={false}>
        <View style={styles.checkboxWrapper}><View style={styles.checkbox}></View><Text>Recommended (average rating of <Text style={ { fontFamily: options.font.styles.bold } }>{props.assessment?.minimum_average_score}</Text> or higher and no more than one 1 rating)</Text></View>
        <View style={styles.checkboxWrapper}><View style={styles.checkbox}></View><Text>Not Recommended</Text></View>
      </View>
    </>
  )
}

function getRatingWidth (assessment) {
  if (assessment !== null) {
    return 100 / (assessment.interviewer_count + 1) - 1
  } else {
    return 0
  }
}
