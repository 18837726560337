/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { combineApplicantName, renderDateFromSource } from '../formatUtil';
import { Avatar, Box, Button, Card, Center, Group, Text, Title, Tooltip } from '@mantine/core';
import { LockableColor } from './LockableIcon';

export const AccountSummaryCard = memo(function AccountSummaryCard ({ account, isAdmin = false, title = 'Account' }) {
  const accountName = combineApplicantName(account)

  const onDetailsClick = () => {
    console.warn('On account summary card details click - must implement show or navigate to details', account) // TODO [cycle pass full features] remove disabled prop
  }

  const activeColor = account.active ? LockableColor.Active : LockableColor.Locked
  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg={activeColor} py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>{title}</Title>
          </Box>
        </Card.Section>
        <Card.Section mt='xxs' mb='1rem'>
          <Center>
            <Avatar
              color={activeColor}
              alt={accountName || 'Account Name Missing'}
              size='xl'
            >
              {accountName?.match(/\b\w/g)?.join('').toUpperCase() || '?'}
            </Avatar>
          </Center>
        </Card.Section>

        <Text ta='center' size='md'>
          {accountName || 'Account Name Missing'}{(account.id && isAdmin) ? ` #${account.id}` : ''}
        </Text>

        <Text c={account.email ? 'teal' : 'dimmed'} ta='center' size={account.email ? 'md' : 'xxs'}>
          {account.email || 'Email Missing'}
        </Text>

        <Group justify="space-between" mt="auto" mb="xs">
          {!!account.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(account.created_at)}</Text>}
          {!!account.last_activity_at && <Text size="sm" c="dimmed">Seen: {renderDateFromSource(account.last_activity_at)}</Text>}
        </Group>

        <Tooltip label='Feature Coming Soon'>
          <Button color="blue" fullWidth mt={0} radius="md" onClick={onDetailsClick} disabled={true}>
            View Details
          </Button>
        </Tooltip>
      </Card>
    </>
  )
})
