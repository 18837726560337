import { SimpleGrid } from '@mantine/core'
import React from 'react'
import CreateButtons from './CreateButtons'
import SkillbuilderCyclesTable from './SkillbuilderCyclesTable'

export default function SkillbuilderCyclesHome () {
  return (
    <SimpleGrid>
      <CreateButtons/>
      <SkillbuilderCyclesTable />
    </SimpleGrid>
  )
}
