import React, { useMemo, useState } from 'react'
import { Button, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import PropTypes from 'prop-types'
import { showNotification } from '@mantine/notifications'

export default function BatteryAddForm ({ addBattery, batteries }) {
  const [processing, setProcessing] = useState(false)
  const form = useForm({
    initialValues: {
      battery: null
    },
    validate: {
      battery: value => value === null ? 'Please choose a new battery' : null
    },
    transformValues: values => ({
      battery: parseInt(values.battery)
    })
  })

  const submit = values => {
    setProcessing(true)
    addBattery(values)
      .then(() => {
        showNotification({
          message: 'Battery added successfully!',
          color: 'success',
          autoClose: 3000
        })
        form.reset()
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error adding this battery',
            color: 'red'
          })
        }
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const batteryOptions = useMemo(() => {
    return batteries.map(battery => ({
      value: battery.id.toString(),
      label: battery.name
    }))
  }, [batteries])

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Select
        mb={10}
        label='Choose a new battery'
        placeholder='Pick battery'
        data={batteryOptions}
        searchable
        withAsterisk
        {...form.getInputProps('battery')}
      />
      <Button color='teal.6' type='submit' loading={processing}>Add</Button>
    </form>
  )
}

BatteryAddForm.propTypes = {
  addBattery: PropTypes.func,
  batteries: PropTypes.array
}
