import { ApiMethods, apiSlice, ApiTags } from '../../../react/api'

export const triggersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCycleTrigger: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `triggers/cycle/${id}/create`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Cycles, id: id }]
    }),
    createHiringTemplateTrigger: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `triggers/program/${id}/create`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }]
    }),
    updateTrigger: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `triggers/${id}/edit`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Triggers, id: id }]
    })
  })
})

export const {
  useCreateCycleTriggerMutation,
  useCreateHiringTemplateTriggerMutation,
  useUpdateTriggerMutation
} = triggersApi
