import { Text, View } from '@react-pdf/renderer'
import React, { useContext } from 'react'
import Styles from '.././styles/interview'
import { PdfOptions } from '../PdfPage'

const directions = [
  'Don\'t start immediately with the interview. Instead, break the ice with a few minutes of non-controversial small talk. For example, ask the applicant if they found their way okay and tell them a bit of trivia about your work location such as when you first opened and how many people you employ',
  'Read each of the questions word-for-word and relatively slowly so that the applicant understands them. Ask the questions in the prescribed order.',
  'If necessary, paraphrase the applicant\'s answers to make certain that you have heard them correctly. This, of course, will be needed with only questions where the applicant gives you a long and complicated answer.',
  'Do not tell the applicant how well or poorly he or she answered any question, nor give the applicant any hint of his or her overall performance on the structured interview. You should remain "poker-faced" throughout the session.',
  'Be sure to guard against asking the applicant any illegal questions.',
  'Use Interviewer 1\'s Interview Guide to record consensus ratings. Consensus ratings can be listed in half point increments (e.g., 3.5) when warranted. In cases where there are more then two interviewers, attach additional interviews as necessary',
  'After the interview, return all interview guides, to HR for documentation purposes. Interview results should be stored in accordance with Company\'s document retention policy. Results for hired candidates should NOT be kept in their personnel files.'
]

export default function DirectionsPage () {
  const styles = Styles(useContext(PdfOptions)).directions
  return (
    <View>
      <Text style={styles.pageHeader}>Interviewer Directions</Text>
      <View style={styles.list}>
        {directions.map((d, index) => {
          return (
            <View key={index} style={styles.item}>
              <Text style={styles.number}>{index + 1}. </Text>
              <Text style={styles.direction}>{d}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}
