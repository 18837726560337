import { useParams } from 'react-router-dom';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Group, Space } from '@mantine/core';
import { CycleNavButton, InviteHomeButton } from '../nav/CycleNavButton';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { OpenInvitesTable } from './OpenInvitesTable';
import React from 'react';

export function PublicLinksList () {
  const { cycleId } = useParams()

  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Public Links' />
      <Group>
        <InviteHomeButton />
        <CycleNavButton cycleId={cycleId} route={CyclePassRoute.CycleOpenInviteCreate} name='Create' />
      </Group>
      <Space h='md' />
      <OpenInvitesTable cycleId={cycleId} />
    </>
  )
}
