import axios from 'axios'

const endpoint = '/api/v1/users'

export function createUser (json) {
  return axios({
    method: 'post',
    url: endpoint,
    data: json
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function editUser (id, json) {
  return axios({
    method: 'patch',
    url: `${endpoint}/${id}`,
    data: json
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function exportUsers (json) {
  return axios({
    method: 'post',
    url: `${endpoint}/export`,
    data: json,
    responseType: 'arraybuffer'
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}
