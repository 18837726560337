import React, { useMemo } from 'react'
import { Button, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import PropTypes from 'prop-types'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import WarningAlert from '../../core/Alert/WarningAlert'

export default function BatteryReplaceForm ({ editBattery, battery, batteries, triggers }) {
  const form = useForm({
    initialValues: {
      battery: null,
      battery_score_mappings: battery.battery_score_mappings.map(batteryScoreMapping => ({ portal_module_info: batteryScoreMapping.portal_module_info.id, ap_module_info: batteryScoreMapping.ap_module_info }))
    },
    enhanceGetInputProps: payload => {
      if (payload.field === 'battery') {
        return {
          label: 'Choose a new battery',
          placeholder: 'Pick battery',
          data: batteries.map(battery => ({
            value: battery.id.toString(),
            label: battery.name
          })),
          onChange: value => {
            payload.form.setFieldValue('battery', value)
            _.forEach(payload.form.values.battery_score_mappings, (value, idx) => payload.form.setFieldValue(`battery_score_mappings.${idx}.ap_module_info`, null))
          },
          allowDeselect: false,
          searchable: true,
          withAsterisk: true
        }
      }
    },
    validate: {
      battery: value => value === null ? 'Please choose a new battery' : null
    },
    transformValues: values => {
      const batteryScoreMappings = []

      _.forEach(values.battery_score_mappings, batteryScoreMapping => {
        if (batteryScoreMapping.ap_module_info !== null) {
          batteryScoreMappings.push({
            portal_module_info: parseInt(batteryScoreMapping.portal_module_info),
            ap_module_info: parseInt(batteryScoreMapping.ap_module_info)
          })
        }
      })

      return {
        ...values,
        battery: parseInt(values.battery),
        battery_score_mappings: batteryScoreMappings
      }
    }
  })

  const selectedBattery = _.find(batteries, { id: parseInt(form.values.battery) })

  const isBatteryUsedInTriggers = useMemo(() => {
    const batteriesIncludedInTriggers = _.remove(_.flatten(triggers.map(trigger => trigger.metadata.emails?.map(email => parseInt(email.email_program)))), e => !isNaN(e))
    return _.includes(batteriesIncludedInTriggers, battery.cycle.id)
  }, [battery, triggers])

  const submit = values => {
    editBattery(values)
      .then(() => {
        showNotification({
          message: 'Battery edited successfully!',
          color: 'success',
          autoClose: 3000
        })
        form.reset()
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error editing this battery',
            color: 'red'
          })
        }
      })
  }

  const selectedBatteryInfos = selectedBattery?.module_infos
  const batterySelectOptions = useMemo(() => {
    return selectedBatteryInfos?.map(moduleInfo => ({
      value: moduleInfo.id.toString(),
      label: moduleInfo.module.name
    })) ?? []
  }, [selectedBatteryInfos])

  return (
    <>
      {isBatteryUsedInTriggers && <WarningAlert>This battery is being used in triggers. The triggers will be updated with the new battery</WarningAlert>}
      <form onSubmit={form.onSubmit(submit)}>
        <Select mb={10} {...form.getInputProps('battery')}/>
        {battery.battery_score_mappings.map((batteryScoreMapping, idx) => (
          <Select
            mb={10}
            key={batteryScoreMapping.id}
            label={'New score mapping for ' + batteryScoreMapping.portal_module_info.module.name.charAt(0).toUpperCase() + batteryScoreMapping.portal_module_info.module.name.slice(1).toLowerCase()}
            data={batterySelectOptions}
            placeholder='No link'
            {...form.getInputProps(`battery_score_mappings.${idx}.ap_module_info`)}
          />
        ))}
        <Button color='blue.6' type='submit'>Replace</Button>
      </form>
    </>
  )
}

BatteryReplaceForm.propTypes = {
  editBattery: PropTypes.func,
  battery: PropTypes.object,
  batteries: PropTypes.array,
  triggers: PropTypes.array
}
