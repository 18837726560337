/* eslint react/prop-types: 0 */
import { useSortable } from '@dnd-kit/sortable'
import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import { ActionIcon, List } from '@mantine/core'
import { IconGripHorizontal } from '@tabler/icons-react'
import DraggableItem from './DraggableItem'
import styles from './DragSelect.module.scss'

export default function SortableItem ({
  item,
  handleMouseDown,
  highlightItem,
  highlighted,
  mouseDown,
  doHighlight,
  activelyDragging
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id })

  const draggingStyles = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  }

  const handleHover = () => {
    if ((highlighted === doHighlight) || activelyDragging) return
    highlightItem(item)
  }

  return (
      <List.Item
        ref={setNodeRef}
        onMouseDown={() => handleMouseDown(item)}
        onMouseEnter={() => { if (mouseDown) handleHover() }}
        style={draggingStyles}
        className={[styles.draggableItem, isDragging ? styles.dragging : ' ']}
        bg={highlighted ? '#2DB1FFAA' : '#f7f7f7AA'}
        icon={(
          <ActionIcon
            className={[styles.itemGripButton, isDragging && styles.dragging]}
            variant='transparent'
            color='gray'
            onMouseDown={e => e.stopPropagation()}
            {...attributes}
            {...listeners}
            >
            <IconGripHorizontal />
          </ActionIcon>
        )}
      >
      <DraggableItem highlighted={highlighted}>
        {item.label}
      </DraggableItem>
    </List.Item>
  )
}
