/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';
import {
  useStageProgressesMatchingIds,
  useStageProgressesTable
} from './StageProgressesHooks';

export const StageProgressesFromIdsTable = memo(function StageProgressesFromIdsTable ({ progressIds, namespace = 'stage-progresses' }) {
  const handleRowClicked = useCallback((row) => {
    console.debug('Stage progresses table row clicked.', row)
    if (!row) return true
    const progressId = row.dataset.rowid
    const redirectPath = `/invites/manage/progresses/${progressId}/preview`

    console.debug('Generated redirect path for stage progress', { redirectPath, progressId, row })
    window.open(redirectPath, '_blank')
  }, [])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useStageProgressesTable(null, null)

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <StageProgressesFromIdsTableDataProvider progressIds={progressIds}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </StageProgressesFromIdsTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function StageProgressesFromIdsTableDataProvider ({ progressIds, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useStageProgressesMatchingIds(namespace, progressIds, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating StageProgressesFromIdsTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('StageProgressesFromIdsTableDataProvider updated.', { collection, querying, namespace, progressIds })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
