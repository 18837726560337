/* eslint react/prop-types: 0 */
import { Select } from '@mantine/core'
import React from 'react'
import { formatToOptions } from '../../../js/util/DataUtil'

export default function TierSelect ({ tiers, form, index }) {
  return (
    <Select
      maxDropdownHeight={280}
      data={formatToOptions(tiers)}
      {...form.getInputProps(`statuses.${index}.tier`)}
    />
  )
}
