/* eslint react/prop-types: 0 */
import { Text, View } from '@react-pdf/renderer'
import React, { useContext } from 'react'
import Styles from '.././styles/interview'
import { PdfOptions } from '../PdfPage'

export default function CoverPage (props) {
  const styles = Styles(useContext(PdfOptions)).cover
  return (
    <View style={styles.coverPage}>
      <Text style={styles.coverText}>{props.organization?.name}</Text>
      <Text style={styles.coverText}>{props.assessment?.name}</Text>
      <Text style={styles.coverSubtext}>Confidential Materials</Text>
    </View>
  )
}
