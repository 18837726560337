/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { Avatar, Box, Button, Card, Center, Group, Text, Title } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { getLockableItemBasicColor, LockableIcon } from './LockableIcon';
import { renderDateFromSource } from '../formatUtil';
import { generatePath, Link } from 'react-router-dom';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';

export const OpenInviteRecipientSummaryCard = memo(function OpenInviteRecipientSummaryCard ({ recipient, cycleId, isAdmin = false }) {
  const openInviteId = recipient.open_invite?.id
  const publicLinkDetailPath = !(cycleId && openInviteId) ? null : generatePath(CyclePassRoute.CycleOpenInviteDetail, { cycleId, openInviteId })

  const activeColor = getLockableItemBasicColor(recipient)
  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg={activeColor} py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>Public Link Click</Title>
          </Box>
        </Card.Section>
        <Card.Section mt='xxs' mb='1rem'>
          <Center>
            <Avatar
              color={activeColor}
              alt={`Public Link Click #${recipient.id}`}
              size='xl'
            >
              <IconLink size='3rem' />
            </Avatar>
          </Center>
        </Card.Section>

        <Text ta='center' size='md'>
          Public Link Click #{recipient.id}
        </Text>

        {!!recipient.open_invite && (
          <Group justify='center' gap='xs'>
            <LockableIcon item={recipient.open_invite} />
            <Text>
              Public Link: {recipient.open_invite.name} #{recipient.open_invite.id}
            </Text>
          </Group>
        )}

        {!!recipient.open_invite?.invite_config?.expiration_date && (
          <Text ta='center' c='dimmed'>
            Expires {renderDateFromSource(recipient.open_invite?.invite_config?.expiration_date)}
          </Text>
        )}

        {!!recipient.open_invite?.invite_config?.internal_note && (
          <Text size='sm' c='dimmed' ta='center'>
            {recipient.open_invite?.invite_config?.internal_note}
          </Text>
        )}

        <Group justify="space-between" mt="auto" mb="xs">
          {!!recipient.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(recipient.created_at)}</Text>}
          {!!recipient.updated_at && <Text size="sm" c="dimmed">Updated: {renderDateFromSource(recipient.updated_at)}</Text>}
        </Group>

        <Button color="blue" fullWidth mt={0} radius="md" component={Link} to={publicLinkDetailPath} disabled={!publicLinkDetailPath}>
          View Details
        </Button>
      </Card>
    </>
  )
})
