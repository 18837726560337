/* eslint react/prop-types: 0 */
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from '@mantine/form';
import {
  combineApplicantName
} from '../formatUtil';
import { notifications } from '@mantine/notifications';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { Box, Button, Center, Group, Stack, TextInput, Title } from '@mantine/core';
import { useDebouncedField } from '../../../../forms/UpdateOnSubmitField';
import { useCyclePassDetail } from './CyclePassHooks';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';
import { useCyclePassEditEmailMutation } from './CyclePassesApi';
import { useConfirmNavigate } from '../CycleInviteHooks';

export function CyclePassEditEmail () {
  const { cycleId, passId } = useParams()
  const [cyclePass, , loading] = useCyclePassDetail(passId, cycleId, !passId)
  return (
    <>
      {!!loading && <CardLoadingPlaceholder />}
      {!loading && !cyclePass && <CardQueryError />}
      {!loading && !!cyclePass && <CyclePassEditEmailForm cycleId={cycleId} passId={passId} cyclePass={cyclePass} />}
    </>
  )
}

function CyclePassEditEmailForm ({ cycleId, passId, cyclePass }) {
  const navigate = useNavigate()
  const [sendData, { isLoading: processing }] = useCyclePassEditEmailMutation()

  const currentEmail = cyclePass.email?.id ?? cyclePass.applicant?.email_address ?? ''
  const applicantName = combineApplicantName(cyclePass.applicant)

  const form = useForm({
    initialValues: {
      email: (currentEmail ?? '')
    },

    transformValues: (values) => ({
      id: values.email
    }),

    validate: {
      email: (value) => (
        value
      )
        ? (!(currentEmail === value)
            ? null
            : 'Email must be different from original value')
        : 'Email cannot be empty'
    }
  })

  const setConfirmNavigate = useConfirmNavigate(form.isDirty())

  const submitForm = useCallback((values) => {
    sendData({ ...values, passId, cycleId })
      .unwrap()
      .then(response => {
        console.debug('Got post edit cycle pass email form response', { response, passId, currentEmail })
        notifications.show(
          {
            color: 'green',
            title: 'Edit Email Success',
            message: 'Passport email successfully edited!',
            autoClose: 8000
          }
        )
        const path = generatePath(CyclePassRoute.CyclePassDetail, { cycleId: cycleId.toString(), passId: passId.toString() })
        console.debug('Generated path for cycle pass detail - redirecting', { path, passId, cycleId })
        navigate(path)
      })
      .catch(e => {
        console.error('Post edit cycle pass email form error response', e)
        notifications.show( // TODO [cycle pass full features] validation errors on fields
          {
            color: 'red',
            title: 'Submission Error',
            message: 'There was an error saving your email changes for this passport! That email may already be in use for this cycle.',
            autoClose: 8000
          }
        )
      })
  }, [cycleId, passId, currentEmail, sendData, navigate])

  return (
    <Box miw='100%'>
      <Center>
        <Box miw='50rem' maw='50rem'>
          <form onSubmit={form.onSubmit(values => submitForm(values))}>
            <Stack>
              <Title ta='center'>
                Edit Passport #{passId} Email
              </Title>
              <Group align='flex-start' grow>
                <EmailFieldEditor form={form} applicantName={applicantName} />
              </Group>
              <Group position='left' mt='md'>
                <Button type='submit' color='green.6' loading={processing}>Save</Button>
                <Button
                  color='gray.6'
                  onClick={() => {
                    setConfirmNavigate(false)
                    navigate(-1)
                  }}
                >
                  Discard
                </Button>
              </Group>
            </Stack>
          </form>
        </Box>
      </Center>
    </Box>
  )
}

function EmailFieldEditor ({ form, applicantName }) {
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const updateFormEmail = useCallback((value) => {
    formRef.current.setValues({ email: value })
  }, [])

  const emailProps = useDebouncedField(form.values.email, updateFormEmail)

  console.debug('Edit passport email form updating.', { applicantName: applicantName, email: form.values.email })
  const description = 'Change email address for passport' + (applicantName ? ' and its associated candidate' : '') + '. This will disable any invites sent to the previous email, and invites sent to an account.'
  return (
    <Stack gap='md'>
      <TextInput
        label='Email'
        description={description}
        { ...form.getInputProps('email') }
        { ...emailProps }
      />
      <TextInput
        label='Applicant'
        description='Applicant associated with passport'
        value={applicantName || 'No Associated Applicant'}
        disabled={true}
      />
    </Stack>
  )
}
