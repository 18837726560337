import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { QueryState } from '../../../../assessment/util';

/**
 * @typedef {object} DistributionReportPage
 * @property {int} id page
 * @property {string} version
 * @property {int[]} progressIds Move to slice, make ids array
 */

export const reportVersionChanged = createAsyncThunk('distribution/reportVersionChanged', async (versionParams, { dispatch, getState, rejectWithValue, requestId }) => {
  console.info('Clearing state data for distribution report', { versionParams })
  dispatch(versionChanged({ ...versionParams, version: requestId }))
  const state = getState()
  const stateVersion = state.distributionReportPages.version
  if (stateVersion !== requestId) {
    console.debug('Not dispatching first page update due to version change', { requestId, stateVersion, versionParams })
    return rejectWithValue({
      error: false, versionChange: true, pageVersion: requestId, stateVersion: stateVersion
    })
  }
  dispatch(addNewPage({ ...versionParams, version: requestId }))
  return false
})

export const reportPageReceived = createAsyncThunk('distribution/reportPageReceived', async (pageParams, { dispatch, getState, rejectWithValue }) => {
  console.info('Updating page for distribution report', { pageParams })
  const state = getState()
  const stateVersion = state.distributionReportPages.version // .entities for entries
  const pageVersion = pageParams?.version
  if (stateVersion !== pageVersion) {
    console.debug('Not dispatching page update due to version change', { pageVersion, stateVersion, pageParams })
    return rejectWithValue({
      error: false, versionChange: true, pageVersion: pageVersion, stateVersion: stateVersion
    })
  }
  dispatch(addNewPage(pageParams))
  return false
})

/**
 * @type {EntityAdapter<{DistributionReport}, int>}
 */
const distributionReportPagesAdapter = createEntityAdapter()

const initialState = distributionReportPagesAdapter.getInitialState({
  status: QueryState.Idle,
  error: null,
  namespace: 'unknown',
  version: '0',
  total: 0,
  limit: 0,
  maxPage: 1,
  initialized: false,
  tableProgressIds: null
})

const distributionReportPagesSlice = createSlice({
  name: 'distributionReportPages',
  initialState: initialState,
  reducers: {
    setProgressesMatchingResponseTableIds (state, action) {
      console.debug('Setting state table progress ids for progresses matching selected response', { action })
      state.tableProgressIds = action.payload?.progressIds ?? null
    },
    versionChanged (state, action) {
      const pageId = action.payload.page ?? 1
      const version = action.payload.version
      if (version === state.version) {
        console.debug('Setting initial page', { version, pageId })
        distributionReportPagesAdapter.setOne(
          state,
          {
            id: pageId,
            version: version,
            progressIds: action.payload.progressIds ?? []
          }
        )
        state.namespace = action.payload.namespace ?? 'unknown'
        const total = action.payload.total ?? 0
        const limit = Math.max(action.payload.limit ?? 1, 1)
        state.total = total
        state.limit = limit
        state.maxPage = Math.max(1, Math.ceil(total / limit))
        state.initialized = true
      } else {
        console.debug(
          'Skipping setting initial page due to version mismatch',
          { stateVersion: state.version, version: version, pageId: pageId }
        )
      }
    },
    addNewPage (state, action) {
      const pageId = action.payload?.page ?? 1
      const version = action.payload?.version ?? '0'
      if (version === state.version) {
        distributionReportPagesAdapter.setOne(
          state,
          {
            id: pageId,
            version: version,
            progressIds: action.payload?.progressIds ?? []
          }
        )
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportVersionChanged.pending, (state, action) => {
        state.version = action.meta.requestId
        state.initialized = false
        state.tableProgressIds = null
        distributionReportPagesAdapter.removeAll(state)
      })
  }
})

export const { versionChanged, addNewPage, setProgressesMatchingResponseTableIds } = distributionReportPagesSlice.actions

export default distributionReportPagesSlice.reducer

export const {
  selectAll: selectAllDistributionReportPages,
  selectById: selectDistributionReportPageById,
  selectIds: selectDistributionReportPageIds,
  selectEntities: selectDistributionReportPageMap
} = distributionReportPagesAdapter.getSelectors(state => state.distributionReportPages)

const noMissingPagesResult = []

export const selectMissingPages = createSelector(
  [selectDistributionReportPageIds, (state) => state.distributionReportPages.maxPage, (state) => state.distributionReportPages.initialized],
  (pageIds, maxPage, initialized) => !initialized || (!!pageIds.length && (maxPage === Math.max(pageIds)) && (maxPage === pageIds.length) && (Math.min(pageIds) === 1)) ? noMissingPagesResult : Array.from(Array(maxPage).keys()).map(page => page + 1).filter(page => !pageIds.includes(page))
)

export const selectNonFirstPages = createSelector(
  [selectDistributionReportPageIds, (state) => state.distributionReportPages.maxPage, (state) => state.distributionReportPages.initialized],
  (pageIds, maxPage, initialized) => !initialized ? noMissingPagesResult : Array.from(Array(maxPage).keys()).map(page => page + 1).filter(page => page !== 1)
)

export const selectAllPagesLoaded = createSelector(
  [selectMissingPages, (state) => state.distributionReportPages.initialized],
  (pageIds, initialized) => !!initialized && !pageIds.length
)
