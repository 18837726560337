import { LandingPage } from './LandingPage';
import React from 'react';

export function AuthLandingPage () {
  const dataContainer = document.getElementById('react-app-container')
  const invalid = convertDataBoolean(dataContainer.getAttribute('data-invalid'))
  const locked = convertDataBoolean(dataContainer.getAttribute('data-locked'))
  const title = parseTitle(dataContainer.getAttribute('data-title'), invalid, locked)
  const message = parseMessage(dataContainer.getAttribute('data-message'), invalid, locked)
  const styleString = dataContainer.getAttribute('data-style') ?? null
  const style = styleString ? parseInt(styleString) : null
  const redirectUrl = dataContainer.getAttribute('data-redirect') ?? null
  return (
    <LandingPage title={title} message={message} style={style} locked={locked || invalid} redirectUrl={redirectUrl} />
  )
}

function parseTitle (title = null, invalid = false, locked = false) {
  if (title) {
    return title
  }
  if (invalid) {
    return 'Invalid Link'
  }
  if (locked) {
    return 'Expired Link'
  }
  return null
}

function parseMessage (message = null, invalid = false, locked = false) {
  if (message) {
    return message
  }
  if (invalid || locked) {
    return 'If you think this is a mistake, please email us at support@hirescore.com'
  }
  return null
}

function convertDataBoolean (data = null) {
  return data ? ((data === 'true') || (data === '1')) : false
}
