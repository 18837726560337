import React, { useState } from 'react'
import { Button, Dialog, Flex, Text } from '@mantine/core'
import Content from '../layout/Content'

export default function CookieBox () {
  const [closed, setClosed] = useState(localStorage.getItem('closedCookiesBox') === 'true')

  const handleClose = () => {
    localStorage.setItem('closedCookiesBox', 'true')
    setClosed(true)
  }

  return (
    <Content>
      <Dialog opened={!closed} size='xl' radius='md' shadow='xl' withBorder>
          <Flex gap='xs' justify='center' align='center' direction='row' wrap='nowrap'>
            <Text fz='lg'>This website uses cookies to improve user experience. By continuing to browse the site, you are agreeing to our use of cookies.</Text>
            <Button variant='subtle' onClick={() => handleClose()} miw='6rem'>Accept</Button>
          </Flex>
      </Dialog>
    </Content>
  )
}
