import {
  IconArrowRoundaboutRight,
  IconCloudComputing, IconCloudDataConnection,
  IconCloudDownload,
  IconCloudLock,
  IconCloudOff,
  IconCloudStorm,
  IconCloudUpload,
  IconCodePlus, IconDoorEnter, IconDoorOff,
  IconEdit,
  IconLock,
  IconLockOff,
  IconReplace
} from '@tabler/icons-react';
import { CyclePassActivityType } from '../../../../../js/generated/enums/CyclePassActivityType';
import { AdminPassActivityType } from '../../../../../js/generated/enums/AdminPassActivityType';
import React from 'react';

export const cyclePassActivityTypeIdToName = Object.freeze(
  Object.fromEntries(
    Object.entries(CyclePassActivityType)
      .map(([key, value]) => [value, key.split(/(?=[A-Z])/).join(' ')])
  )
)

export const adminPassActivityTypeIdToName = Object.freeze(
  Object.fromEntries(
    Object.entries(AdminPassActivityType)
      .map(([key, value]) => [value, key.split(/(?=[A-Z])/).join(' ')])
  )
)

export function getActivityName (activityTypeId, isAdminActivity) {
  return isAdminActivity ? adminPassActivityTypeIdToName[activityTypeId] : cyclePassActivityTypeIdToName[activityTypeId]
}

export function getBulletIconForActivity (activityTypeId, isAdminTimeline = false, size = 12) {
  return isAdminTimeline ? getBulletIconForAdminActivity(activityTypeId, size) : getBulletIconForCyclePassActivity(activityTypeId, size)
}

function getBulletIconForAdminActivity (adminPassActivityTypeId, size = 12) {
  switch (adminPassActivityTypeId) {
    case 6: // AdminPassActivityType.Unlock
      return <IconLockOff size={size} />
    case 5: // AdminPassActivityType.Skip
      return <IconArrowRoundaboutRight size={size} />
    case 4: // AdminPassActivityType.Replace
      return <IconReplace size={size} />
    case 3: // AdminPassActivityType.Edit
      return <IconEdit size={size} />
    case 2: // AdminPassActivityType.Lock
      return <IconLock size={size} />
    default: // AdminPassActivityType.Create
      return <IconCodePlus size={size} />
  }
}

function getBulletIconForCyclePassActivity (cyclePassActivityTypeId, size = 12) {
  switch (cyclePassActivityTypeId) {
    case 9: //  CyclePassActivityType.RequestAssessmentApiData
      return <IconCloudDownload size={size} />
    case 8: //  CyclePassActivityType.AssessmentBeginDenied
      return <IconCloudOff size={size} />
    case 7: //  CyclePassActivityType.AssessmentSubmitDenied
      return <IconCloudLock size={size} />
    case 6: //  CyclePassActivityType.AnswerSubmitDenied
      return <IconCloudStorm size={size} />
    case 5: //  CyclePassActivityType.ResumeAssessment
      return <IconCloudComputing size={size} />
    case 4: //  CyclePassActivityType.SubmitAssessment
      return <IconCloudUpload size={size} />
    case 3: //  CyclePassActivityType.BeginAssessment
      return <IconCloudDataConnection size={size} />
    case 2: //  CyclePassActivityType.AccessDenied
      return <IconDoorOff size={size} />
    default: //  CyclePassActivityType.AccessGranted
      return <IconDoorEnter size={size} />
  }
}
