/* eslint react/prop-types: 0 */
import { Select } from '@mantine/core'
import React, { useMemo } from 'react'
import { formatToOptions } from '../../../js/util/DataUtil'
import useOrganizations from './StatusSetHooks'

export default function OrganizationSelect ({ onChange, withAllOption = true, extraData = [], ...props }) {
  const organizations = useOrganizations()

  const options = useMemo(() => {
    return withAllOption
      ? [
          { id: '-1', name: 'All Organizations', group: 'General' },
          ...organizations,
          ...extraData
        ]
      : [
          ...organizations,
          ...extraData
        ]
  }, [organizations, extraData, withAllOption])

  return (
    <Select
      label='Organization'
      maxDropdownHeight={280}
      onChange={onChange}
      data={formatToOptions(options, { group: 'By organization' })}
      searchable
      {...props}
    />
  )
}
