/* eslint react/prop-types: 0 */
import { ActionIcon, Flex, UnstyledButton } from '@mantine/core'
import { IconGripHorizontal } from '@tabler/icons-react'
import React from 'react'
import styles from './DragSelect.module.scss'

export default function DraggableItem ({ dragOverlay, children, highlighted }) {
  const backgroundColor = highlighted ? '#2DB1FFAA' : '#f7f7f7AA'
  return dragOverlay
    ? (
        <Flex gap={12} align='center' bg={backgroundColor}>
          <ActionIcon component='a' variant='transparent' className={[styles.itemGripButton, styles.dragging]}><IconGripHorizontal /></ActionIcon>
            <UnstyledButton className={[styles.draggableItem, styles.dragging]}>{children}</UnstyledButton>
        </Flex>
      )
    : (
        <UnstyledButton className={[styles.draggableItem]}>{children}</UnstyledButton>
      )
}
