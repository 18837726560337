/* eslint react/prop-types: 0 */
import { useDroppable } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { Checkbox, Flex, List, Text, Tooltip } from '@mantine/core'
import React, { useState } from 'react'
import SortableItem from './SortableItem'
import { useClickOutside } from '@mantine/hooks'
import styles from './DragSelect.module.scss'

export default function DroppableList ({
  id,
  list,
  highlightedItems = [],
  handleItemClick,
  activelyDragging,
  clearHighlightedItems,
  highlightAll
}) {
  const [doHighlight, setDoHighlight] = useState(true)
  const [mouseDown, setMouseDown] = useState(false)
  const mouseUp = useClickOutside(() => { setMouseDown(false) }, ['mouseup'])
  const { setNodeRef } = useDroppable({ id })

  const handleMouseDown = value => {
    setMouseDown(true)
    setDoHighlight(!value.highlighted)
    handleItemClick(value)
  }

  const toggleHighlightAll = (value) => {
    !value ? clearHighlightedItems(id) : highlightAll(id)
  }

  return (
    <Flex ref={setNodeRef} w='100%' direction='column'>
      <Flex direction='row' justify='space-between' py={2} px={3}>
        <Text fz='lg' fw={700}>{list.label}</Text>
        <Tooltip label={highlightedItems.length > 0 ? 'Unselect' : 'Select All'}>
          <Checkbox
            labelPosition='left'
            checked={highlightedItems.length > 0}
            disabled={list.data.length === 0}
            onChange={e => toggleHighlightAll(e.currentTarget.checked)}
          />
        </Tooltip>
      </Flex>
      <List
        ref={mouseUp}
        className={[styles.droppableList]}
        center
        onMouseDown={() => { setMouseDown(true) }}
        onMouseUp={() => { setMouseDown(false) }}
        mah={600}
        >
        <SortableContext
          id={id}
          items={Object.values(list.data).map(i => i.id)}
          strategy={rectSortingStrategy}
          >
            {list.data.map(item => (
              <SortableItem
                key={item.id}
                item={item}
                highlightItem={handleItemClick}
                handleMouseDown={handleMouseDown}
                highlighted={item.highlighted}
                mouseDown={mouseDown}
                doHighlight={doHighlight}
                activelyDragging={activelyDragging}
              />
            ))}
        </SortableContext>
      </List>
    </Flex>
  )
}
