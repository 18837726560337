/* eslint react/prop-types: 0 */
import React from 'react'
import { PasswordInput as MantinePasswordInput } from '@mantine/core'
import { IconLock } from '@tabler/icons-react'

const defaultLabelProps = { w: '100%' }

/**
 * Component that displays a password input.
 * @param {?JSX.Element} resetPassword  Optional component to render a reset password link.
 * @param {object} props Any valid Mantine PasswordInput prop.
 * @returns {JSX.Element} A decorated PasswordInput with defaults set.
 */
export default function PasswordInput ({
  resetPassword = null,
  ...props
}) {
  return <MantinePasswordInput
    name='password'
    size='lg'
    labelProps={defaultLabelProps}
    leftSection={<IconLock />}
    required
    withAsterisk={false}
    {...props}
  />
}
