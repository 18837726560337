import { ApiAbstractIds, apiSlice, ApiTags } from '../../api';
import { paramsToQuery } from '../../util/queries';

export const jobsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ ...params }) => `jobs${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Jobs, id: id })),
              { type: ApiTags.Jobs, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Jobs, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetJobsQuery } = jobsApi
