import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';

export const OpenInvitesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOpenInvitesForCycle: builder.query({
      query: ({ cycleId, ...params }) => `invites/open/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CycleOpenInvites, id: id })),
              { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) }]
    }),
    getOpenInviteDetail: builder.query({
      query: ({ openInviteId }) => `invites/open/${openInviteId}`,
      providesTags: ({ id }, _, { openInviteId: openInviteIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.CycleOpenInvites, id: id ?? openInviteIdParam }, { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) }]
    }),
    createOpenInvite: builder.mutation({
      query: ({ cycleId, ...body }) => ({
        url: `invites/open/cycles/${cycleId}/create`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { cycleId: cycleIdParam }) => [
        { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CycleOpenInvites, id: ApiAbstractIds.PartialList }
      ]
    }),
    editOpenInvite: builder.mutation({
      query: ({ id, cycleId, ...body }) => ({
        url: `invites/open/${id}/edit`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id: idParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CycleOpenInvites, id: idParam }
      ]
    }),
    openInviteLock: builder.mutation({
      query: ({ id, cycleId, unlock }) => ({
        url: unlock ? `invites/open/${id}/unlock` : `invites/open/${id}/lock`,
        method: ApiMethods.Post,
        body: {}
      }),
      invalidatesTags: (result, error, { id: idParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.CycleOpenInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CycleOpenInvites, id: idParam }
      ]
    })
  })
})

export const { useGetOpenInvitesForCycleQuery, useGetOpenInviteDetailQuery, useCreateOpenInviteMutation, useEditOpenInviteMutation, useOpenInviteLockMutation } = OpenInvitesApi
