import { ActionIcon, Anchor, Group, Table, Text, Tooltip } from '@mantine/core'
import { IconPencil } from '@tabler/icons-react'
import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

export function Scores ({ applicant, editing, modules }) {
  const getApplicantScore = (moduleId) => {
    let score = _.find(applicant.scores, { module_id: moduleId })?.percentile_score
    score < 1 && (score = 1)
    score > 99 && (score = 99)
    return score ? Math.round(score) : '-'
  }

  return modules && (
    <Group wrap='nowrap' w='60%'>
      <Table>
        <Table.Tbody>
          {modules.map(module => (
            <tr key={module.id}>
              <td style={{ padding: '0.25rem' }}>
                <Text>{module.name}</Text>
              </td>
              <td style={{ textAlign: 'right', padding: '0.25rem' }}>
                <Text>{getApplicantScore(module.id)}</Text>
              </td>
            </tr>
          ))}
        </Table.Tbody>
      </Table>
      {editing && (
        <Tooltip label='Edit scores'>
          <ActionIcon
            component={Anchor}
            href={`/cycles/${applicant.cycle_id}/edit/applicants/${applicant.id}`}
            target='_blank'
            >
            <IconPencil />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  )
}

Scores.propTypes = {
  applicant: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  modules: PropTypes.array.isRequired
}
