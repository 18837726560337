import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../api';
import { paramsToQuery } from '../util/queries';

export const skillbuilderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkillbuilderCycles: builder.query({
      query: (params) => `skillbuilder/cycles${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.SkillbuilderCycles, id: id })),
              { type: ApiTags.SkillbuilderCycles, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.SkillbuilderCycles, id: ApiAbstractIds.PartialList }]
    }),
    deleteSkillbuilderCycle: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `skillbuilder/cycles/${id}/delete`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.SkillbuilderCycles, id: id }]
    })
  })
})

export const { useGetSkillbuilderCyclesQuery, useDeleteSkillbuilderCycleMutation } = skillbuilderApi
