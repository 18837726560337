import React from 'react'
import { Button } from '@mantine/core'
import EmailTemplateHomeTable from './EmailTemplateHomeTable'
import { Link } from 'react-router-dom'

export default function EmailTemplateHome () {
  return (
    <>
      <Button component={Link} to='/build/email/templates/create' color='success'>Create Template</Button>
      <EmailTemplateHomeTable />
    </>
  )
}
