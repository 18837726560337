/* eslint react/prop-types: 0 */
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, Divider, Paper, Space, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';

export function LandingPageMessage ({ title = null, message = null, locked = false, style = InviteStyle.Default, redirectUrl = null }) {
  const navigate = useNavigate()

  const redirect = () => {
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      navigate(-1)
    }
  }
  const isDarkTheme = ((style === InviteStyle.Sunrise) || (style === InviteStyle.Stars)) && !locked
  const paperStyleDict = isDarkTheme ? { background: 'rgba(0, 0, 0, 0.65)' } : {}
  const textPropsDict = isDarkTheme ? { c: 'blue.5' } : {}
  const dividerPropsDict = isDarkTheme ? { color: 'blue.5' } : {}
  return (
    <Center>
      <Paper style={{ zIndex: 1000, ...paperStyleDict }} p='md' shadow='xl'>
        <Box miw='30rem' maw='35rem' mah='100rem'>
          <Stack>
            <Space h='sm' />
            <Center>
              <Title c={locked ? 'gray.7' : 'blue'} ta='center' px='1rem'>{locked ? (title ?? 'Expired Link') : (title ?? 'Open Opportunity')}</Title>
            </Center>
            <Divider my="xs" { ...dividerPropsDict } />
          </Stack>
        </Box>
        <Box maw='35rem'>
          {!!message && !locked && (
            <>
              <Space h='xxs' />
              <Text ta='center' size='2rem' c='blue.5' fw={700} px='1rem'>{message}</Text>
            </>
          )}
          <Space h='lg' />
          <Center>
            {locked
              ? (
              <Text size='2.6rem' fw={700} c='gray.7' ta='center'>
                {message ?? 'Sorry! This link has expired. If you believe this is an error, please contact support@hirescore.com.'}
              </Text>
                )
              : (
              <Box miw='15rem'>
                <Button variant={isDarkTheme ? 'outline' : 'filled'} color='blue' size='xl' onClick={redirect} radius={isDarkTheme ? 0 : 50} fullWidth>
                  <Text size='2.6rem' fw={700} { ...textPropsDict }>
                    Start
                  </Text>
                </Button>
              </Box>
                )}
          </Center>
          <Space h='lg' />
        </Box>
      </Paper>
    </Center>
  )
}
