import axios from 'axios'

export function updateZones (id, data) {
  return axios({
    method: 'POST',
    url: `/api/v1/competencies/${id}/zones`,
    data: data
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateCycleCompetencyZones (competencyId, cycleId, data) {
  return axios({
    method: 'POST',
    url: `/api/v1/competencies/${competencyId}/cycles/${cycleId}/zones`,
    data: data
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getAvailableCompetencies (data = {}, cancelToken = null) {
  const config = {
    method: 'GET',
    url: 'api/v1/competencies',
    data: data
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios competencies request via token.', config)
      } else {
        console.error('Available assessment competencies request error.', err)
        throw err
      }
    })
}
