import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useRef } from 'react';

export function useStableSearchParams () {
  const [searchParams, setSearchParams] = useSearchParams()
  const setSearchParamsRef = useRef(setSearchParams) // setSearchParams changes.

  const stableSetSearchParams = useCallback((paramsOrCallback, options = null) => {
    setSearchParamsRef.current(paramsOrCallback, { replace: true, ...(options ?? {}) })
  }, [])

  return [searchParams, stableSetSearchParams]
}

export function parseAllSearchParamsToDict (searchParams) {
  const paramsDict = {}
  searchParams.forEach(function (item, key) {
    if (searchParams.getAll(key).length > 1) {
      paramsDict[key] = searchParams.getAll(key)
    } else {
      paramsDict[key] = item
    }
  })
  return paramsDict
}

function parseNamespacedSearchParams (searchParams, paramsNamespace) {
  const namespacedParams = {}
  searchParams.forEach(function (item, key) {
    if (key.startsWith(paramsNamespace)) {
      if (searchParams.getAll(key).length > 1) {
        namespacedParams[key.slice(paramsNamespace.length)] = searchParams.getAll(key)
      } else {
        namespacedParams[key.slice(paramsNamespace.length)] = item
      }
    }
  })
  return namespacedParams
}

function parseOtherNamespacedSearchParams (searchParams, paramsNamespace) {
  const namespacedParams = {}
  searchParams.forEach(function (item, key) {
    if (!key.startsWith(paramsNamespace)) {
      if (searchParams.getAll(key).length > 1) {
        namespacedParams[key] = searchParams.getAll(key)
      } else {
        namespacedParams[key] = item
      }
    }
  })
  return namespacedParams
}

function splitNamespacedSearchParams (searchParams, paramsNamespace) {
  const namespacedParams = {}
  const otherParams = {}
  searchParams.forEach(function (item, key) {
    if (key.startsWith(paramsNamespace)) {
      if (searchParams.getAll(key).length > 1) {
        namespacedParams[key.slice(paramsNamespace.length)] = searchParams.getAll(key)
      } else {
        namespacedParams[key.slice(paramsNamespace.length)] = item
      }
    } else {
      if (searchParams.getAll(key).length > 1) {
        otherParams[key] = searchParams.getAll(key)
      } else {
        otherParams[key] = item
      }
    }
  })
  return [namespacedParams, otherParams]
}

function insertParamsNamespace (params, paramsNamespace) {
  const paramsWithNamespace = {}
  for (const [paramKey, param] of Object.entries(params)) {
    paramsWithNamespace[paramsNamespace + paramKey] = param
  }
  return paramsWithNamespace
}

/**
 * Note that this is removing any methods from searchParams such as getAll() and is instead returning a plain object.
 */
export function useNamespacedStableSearchParams (namespace) {
  const [searchParams, setSearchParams] = useStableSearchParams()
  const lastNamespacedSearchParamsRef = useRef(parseNamespacedSearchParams(searchParams, namespace))

  const namespacedSetSearchParams = useCallback((paramsOrCallback, options = null) => {
    if (typeof paramsOrCallback === 'function') {
      setSearchParams((prev) => {
        const [namespacedParams, otherParams] = splitNamespacedSearchParams(prev, namespace)
        const newParams = paramsOrCallback(namespacedParams)
        return { ...otherParams, ...(insertParamsNamespace(newParams, namespace)) }
      }, options)
      return
    }
    setSearchParams((prev) => {
      const otherParams = parseOtherNamespacedSearchParams(prev, namespace)
      return { ...otherParams, ...(insertParamsNamespace(paramsOrCallback, namespace)) }
    }, options)
  }, [setSearchParams, namespace])

  const namespacedSearchParams = useMemo(() => {
    const newNamespacedSearchParams = parseNamespacedSearchParams(searchParams, namespace)
    if (JSON.stringify(newNamespacedSearchParams) !== JSON.stringify(lastNamespacedSearchParamsRef.current)) {
      console.debug(
        'Namespaced search params changed - returning new object.',
        newNamespacedSearchParams, lastNamespacedSearchParamsRef.current, namespace
      )
      lastNamespacedSearchParamsRef.current = newNamespacedSearchParams
      return newNamespacedSearchParams
    }
    console.debug(
      'Namespaced search params did not change - returning previous object.',
      newNamespacedSearchParams, lastNamespacedSearchParamsRef.current, namespace
    )
    return lastNamespacedSearchParamsRef.current
  }, [searchParams, namespace])

  return [namespacedSearchParams, namespacedSetSearchParams]
}
