/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import {
  useAssessmentInvitesTable,
  useCyclesAssessmentInvites,
  useLazyCyclesAssessmentInvites
} from './AssessmentInvitesHooks';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';

export const AssessmentInvitesTable = memo(function AssessmentInvitesTable ({ cycleId, openInviteId = null, cyclePassId = null, namespace = null }) {
  const navigate = useNavigate()
  const resolvedNamespace = namespace ?? (cyclePassId ? 'cycle-pass-sent-invites' : (openInviteId ? 'open-invites-sent-invites' : 'cycles-sent-invites'))
  const [assessmentInvites] = useLazyCyclesAssessmentInvites(resolvedNamespace, cycleId, openInviteId, cyclePassId)

  const handleRowClicked = useCallback((row) => {
    console.debug('Assessment invites table row clicked.', row)
    if (row === undefined) return true
    const inviteId = row.dataset.rowid
    const intInviteId = parseInt(inviteId)
    const assessmentInvite = (assessmentInvites?.items?.filter(c => c.id === intInviteId) ?? [])[0] ?? null
    if (!assessmentInvite || (!assessmentInvite.cycle_pass?.id)) {
      console.warn('Unable to find invite matching clicked id or cycle pass id missing.', { inviteId, cycleId, assessmentInvite, assessmentInvites })
      return true
    }
    console.debug('Navigating to assessment invite detail', { openInviteId, cycleId, assessmentInvite, row })
    const path = generatePath(CyclePassRoute.CyclePassInviteDetail, { cycleId: cycleId.toString(), passId: assessmentInvite.cycle_pass.id.toString(), inviteId: inviteId })
    console.debug('Generated path for invite detail', { path, assessmentInvite })
    navigate(path)
  }, [assessmentInvites, cycleId, openInviteId, navigate])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useAssessmentInvitesTable()

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={resolvedNamespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <AssessmentInvitesTableDataProvider cycleId={cycleId} openInviteId={openInviteId} cyclePassId={cyclePassId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </AssessmentInvitesTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function AssessmentInvitesTableDataProvider ({ cycleId, openInviteId, cyclePassId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCyclesAssessmentInvites(namespace, cycleId, openInviteId, cyclePassId, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating AssessmentInvitesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Assessment invites table data provider updated.', { collection, querying, namespace, cycleId, openInviteId, cyclePassId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
