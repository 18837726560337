import { apiSlice, ApiTags } from '../api';
import { paramsToQuery } from '../util/queries';

export const semanticApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchApplicants: builder.query({
      query: ({ ...params }) => `semantic/search${paramsToQuery(params)}`,
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.CyclesBoostInvitableApplicants, id: id }]
    })
  })
})

export const { useSearchApplicantsQuery } = semanticApi
