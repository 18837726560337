import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mantine/core'
import {
  useDeleteCycleTriggerMutation,
  useGetCycleEmailTemplatesQuery,
  useGetCycleQuery,
  useToggleCycleTriggerMutation
} from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import TriggersList from '../triggers/TriggersList'

export default function CycleTriggerManagement () {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetCycleEmailTemplatesQuery(id)

  const [toggleTrigger] = useToggleCycleTriggerMutation()
  const [deleteTrigger] = useDeleteCycleTriggerMutation()

  if (cycleError || emailTemplatesError) {
    return <ErrorLoading />
  }

  if (cycleLoading || emailTemplatesLoading) {
    return <Loading />
  }

  return (
    <>
      <TriggersList
        triggers={cycle.triggers}
        emailTemplates={emailTemplates.data}
        toggleTrigger={trigger => toggleTrigger({ id: id, triggerId: trigger.id }).unwrap()}
        editTrigger={trigger => navigate(`/hire/cycles/${cycle.id}/triggers/${trigger.id}/edit`)}
        deleteTrigger={trigger => deleteTrigger({ id: id, triggerId: trigger.id }).unwrap()}
      />
      <Button onClick={() => navigate(`/hire/cycles/${id}/triggers/create`)} color='teal.6' mt='1rem'>Create Trigger</Button>
    </>
  )
}
