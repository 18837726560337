import React from 'react'
import { showNotification } from '@mantine/notifications'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetHiringTemplateEmailTemplatesQuery,
  useGetHiringTemplateQuery
} from '../../../../redux/query/build/hiringTemplatesApi.slice'
import ErrorLoading from '../../../core/ErrorLoading'
import Loading from '../../../core/Loading'
import {
  useCreateHiringTemplateTriggerMutation
} from '../../../../redux/query/hire/triggersApi.slice'
import TriggerCreateForm from './TriggerCreateForm'
import LinkedBatteriesContext from '../../../../contexts/LinkedBatteriesContext'
import EmailTemplatesContext from '../../../../contexts/EmailTemplatesContext'

export default function HiringTemplateTriggerCreate () {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: hiringTemplate, error: hiringTemplateError, isLoading: hiringTemplateIsLoading } = useGetHiringTemplateQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesIsLoading } = useGetHiringTemplateEmailTemplatesQuery(id)

  const [createTrigger] = useCreateHiringTemplateTriggerMutation()

  const onSubmit = data => {
    createTrigger({ id, ...data })
      .unwrap()
      .then(function () {
        showNotification({
          message: 'Successfully created a trigger',
          color: 'success'
        })
        navigate(`/build/hiring-templates/${id}/triggers`)
      }).catch((error) => {
        if (error.response) {
          showNotification({
            title: 'Something went wrong',
            message: error.response.data.errors.join(),
            color: 'red'
          })
        }
      })
  }

  if (hiringTemplateError || emailTemplatesError) {
    return <ErrorLoading />
  }

  if (hiringTemplateIsLoading || emailTemplatesIsLoading) {
    return <Loading />
  }

  return (
    <LinkedBatteriesContext.Provider value={hiringTemplate.hiring_template_batteries}>
      <EmailTemplatesContext.Provider value={emailTemplates.data}>
        <TriggerCreateForm
          onSubmit={onSubmit}
          statusSet={hiringTemplate.status_set}
        />
      </EmailTemplatesContext.Provider>
    </LinkedBatteriesContext.Provider>
  )
}
