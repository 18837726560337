import {
  createSlice,
  createEntityAdapter,
  createSelector,
  prepareAutoBatched
} from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux';

/**
 * @typedef {object} TableRow
 * @property {int|string} id
 * @property {string} namespace
 * @property {string} __namespacedId `${row.namespace}-${row.id}` - used by redux.
 * @property {int} __activeIndex
 * @property {string} __activeRequestId
 * @property {*} [props]
 */

const makeId = (rowId, namespace) => `${namespace}-${rowId}`

const selectId = (row) => row.__namespacedId

/**
 * @type {EntityAdapter<{TableRow}, string>}
 */
const tableRowsAdapter = createEntityAdapter({
  selectId: selectId,
  sortComparer: (a, b) => (a.__activeIndex - b.__activeIndex)
})

const initialState = tableRowsAdapter.getInitialState({
  error: null,
  namespaceActiveIds: {},
  namespaceErrors: {},
  namespaceTotals: {},
  namespaceRequestIds: {},
  namespaceExtendedRequestIds: {}
})

const tableRowsSlice = createSlice({
  name: 'tableRows',
  initialState: initialState,
  reducers: {
    showRow: {
      reducer: (state, action) => {
        const { namespace: namespaceParam, id } = action.payload
        const namespace = namespaceParam ?? 'unknown'
        console.debug('sync showRow', { namespace, id, action })
        if (!((state.namespaceActiveIds[namespace] ?? {})[id] ?? false)) {
          state.namespaceActiveIds = { ...state.namespaceActiveIds, [namespace]: { ...(state.namespaceActiveIds[namespace] ?? {}), [id]: true } }
        } else {
          console.debug('Skipping redundant update', { namespace: namespace, id: id, action: action, active: state.namespaceActiveIds })
        }
      },
      prepare: prepareAutoBatched()
    },
    rowsLoaded: (state, action) => {
      const { namespace: namespaceParam, data, requestId: requestIdParam, __startIndex: startIndexParam } = action.payload
      const namespace = namespaceParam ?? 'unknown'
      const startIndex = startIndexParam ?? 0
      const requestId = startIndex ? (state.namespaceRequestIds[namespace] ?? requestIdParam) : requestIdParam
      console.info('rowsLoaded', { namespace, data, requestId, startIndex, action })
      const rowData = data?.items?.map((row, index) => ({
        ...row,
        id: row.id,
        namespace: namespace,
        __namespacedId: makeId(row.id, namespace),
        __activeRequestId: requestId,
        __activeIndex: index + startIndex,
        [`_request${requestId}`]: index + startIndex + 1
      })) ?? []
      console.debug('Upserting data', { namespace, rowData })
      tableRowsAdapter.upsertMany(state, rowData)
      if (state.namespaceErrors[namespace] ?? false) {
        state.namespaceErrors = { ...state.namespaceErrors, [namespace]: null }
      }
      const total = data?.total ?? ((data?.items?.length ?? 0) + startIndex)
      if ((state.namespaceTotals[namespace] ?? -1) !== total) {
        state.namespaceTotals = { ...state.namespaceTotals, [namespace]: total }
      }
      if (!startIndex) {
        state.namespaceRequestIds = { ...state.namespaceRequestIds, [namespace]: requestId }
        state.namespaceActiveIds = { ...state.namespaceActiveIds, [namespace]: {} }
        state.namespaceExtendedRequestIds = { ...state.namespaceExtendedRequestIds, [namespace]: null }
      } else {
        state.namespaceExtendedRequestIds = { ...state.namespaceExtendedRequestIds, [namespace]: requestIdParam }
      }
    },
    rowsFailed: (state, action) => {
      const { namespace, error } = action.payload
      console.warn('rowsFailed', { namespace, error, action })
      state.namespaceErrors = { ...state.namespaceErrors, [namespace ?? 'unknown']: error ?? true }
    }
  }
})

export const { rowsLoaded, rowsFailed, showRow } = tableRowsSlice.actions

export default tableRowsSlice.reducer

export const { selectAll: selectAllTableRows, selectById: selectTableRowById, selectIds: selectTableRowIds, selectEntities: selectTableRowMap } =
  tableRowsAdapter.getSelectors(state => state.tableRows)

export const selectTableRowIdsByNamespace = createSelector(
  [selectAllTableRows, (state, namespace) => namespace],
  (rows, namespace) => rows.filter(row => row.namespace === namespace).map(row => row.id)
)

export const selectTableRowProperty = createSelector(
  [selectTableRowById, (state, id, property) => property],
  (row, property) => row?.[property] ?? null
)

export const selectTableTotal = createSelector(
  [(state, namespace) => state.tableRows.namespaceTotals, (state, namespace) => namespace],
  (totals, namespace) => totals[namespace] ?? 0
)

export const selectTableRootRequestId = createSelector(
  [(state, namespace) => state.tableRows.namespaceRequestIds, (state, namespace) => namespace],
  (requestIds, namespace) => requestIds[namespace] ?? null
)

export const selectTableActiveRequestId = createSelector(
  [selectTableRootRequestId, (state, namespace) => state.tableRows.namespaceExtendedRequestIds, (state, namespace) => namespace],
  (rootRequestId, extendedIds, namespace) => extendedIds[namespace] ?? rootRequestId ?? null
)

export const selectTableRowIdsByActiveRequestId = createSelector(
  [selectAllTableRows, selectTableRootRequestId],
  (rows, activeRequestId) => rows.filter(row => row.__activeRequestId === activeRequestId).map(row => row.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 4 } }
)

export const selectTableRowsByActiveRequestId = createSelector(
  [selectTableRowIdsByActiveRequestId, selectTableRowMap, (state, namespace) => namespace],
  (rowIds, rowMap, namespace) => rowIds.map(rowId => rowMap[makeId(rowId, namespace)] ?? null)
)

export const selectTableHasAnyRows = createSelector(
  [selectTableRowIdsByActiveRequestId],
  (rows) => !!rows?.length
)

export const selectRowActive = createSelector(
  [(state, namespace, id) => state.tableRows.namespaceActiveIds, (state, namespace, id) => namespace, (state, namespace, id) => id],
  (activeIds, namespace, id) => (activeIds[namespace] ?? {})[id] ?? false
)
