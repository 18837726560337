/* eslint react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { TextInput, ThemeIcon } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

/**
 * @typedef {Object} InputFilter
 * @property {string} label Label used for rendering in badges and control
 * @property {?string} description Sub-label used for rendering in filter control menu
 * @property {string} id Id used as query parameter name.
 * @property {?string} color Optional, color used for badge and control highlight if any option is selected.
 * @property {?object} badgeProps Optional, props to pass to the badge component when an option is selected.
 * @property {?array} options Optional, array of objects containing { label: string, value: string } indicating possible filter values.
 * @property {?any} leftSection Optional, node passed to left section of input component and rendered in badges.
 * @property {?any} rightSection Optional, node passed to right section of input component and rendered in badges.
 * @property {?boolean} multiSelect Optional, indicates filter should be rendered with MultiSelect component if input property is not set.
 * @property {?function} validateUpdate Optional, first argument is input value. Returns false if selected value is not valid.
 * @property {?function} getDisabled Optional, first argument is object mapping all selected values. Returns true if this filter should be disabled.
 * @property {?function} getRequired Optional, first argument is object mapping all selected values. Returns true if this filter should be marked required.
 * @property {?string} errorText Optional, help text to display if validateUpdate returns false.
 * @property {?string} placeholder Optional, help text to display when no option is selected.
 * @property {?boolean} useValue Optional, indicates badge should show the input value rather than searching options for a matching label.
 * @property {?object} input Optional, indicates this is an input filter.
 * @property {?string} input.type Optional, indicates the type of input filter. 'text'|'number' - defaults 'number' if input is supplied without a type.
 * @property {?object} numberProps Optional, props to pass to the number input component. Defaults { allowDecimal: false } for number input types.
 * @property {?string} clearOptionValue Optional, magic value associated with a filter option which indicates the selection should be cleared.
 * @property {?boolean} hidden Optional, *Never update/change filter properties*, indicates filter should not be shown if not an input filter.
 * @property {?boolean} disabled Optional, *Never update/change filter properties*, indicates filter should never accept input if not an input filter.
 */

/**
 * @param {InputFilter} filter
 * @param {string} selected
 * @param {function} updateFilter
 * @param {object} allSelected
 */
export function FilterTextInput ({ filter, selected, updateFilter, allSelected }) {
  const [value, setValue] = useState(selected)
  const [debounced] = useDebouncedValue(value, 700)
  const lastDebouncedRef = useRef(debounced)
  const updateFilterRef = useRef(updateFilter)
  const filterId = filter.id
  const validateUpdate = filter.validateUpdate ?? null
  const invalid = !!validateUpdate && !validateUpdate(debounced)

  useEffect(() => {
    updateFilterRef.current = updateFilter
  }, [updateFilter])

  useEffect(() => {
    if (debounced !== selected) {
      if (lastDebouncedRef.current === selected) {
        if (!validateUpdate || validateUpdate(debounced)) {
          lastDebouncedRef.current = debounced
          updateFilterRef.current(filterId, debounced)
        }
      } else {
        lastDebouncedRef.current = selected
        setValue(selected)
      }
    }
  }, [debounced, selected, validateUpdate, filterId])

  const iconColor = selected ? (filter.color ?? 'blue') : 'dimmed'
  const disabled = filter.getDisabled?.(allSelected) ?? false
  const required = filter.getRequired?.(allSelected) ?? false

  return (
    <TextInput
      label={filter.label}
      description={filter.description ?? null}
      placeholder={filter.placeholder ?? 'Type here'}
      leftSection={filter.leftSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.leftSection}</ThemeIcon>}
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      rightSection={filter.rightSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.rightSection}</ThemeIcon>}
      disabled={disabled}
      required={required}
      error={invalid ? (filter.errorText ?? 'Enter a valid value') : null}
    />
  )
}
