import React, { useContext, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Select, Stack } from '@mantine/core'
import Error from '../../../../forms/Error'
import { formatToOptions } from '../../../../../js/util/DataUtil'
import { IconMailQuestion } from '@tabler/icons-react'
import PropTypes from 'prop-types'
import EmailTemplatesContext from '../../../../../contexts/EmailTemplatesContext'

export default function TemplateSelect ({ index }) {
  const { control } = useFormContext()
  const emailTemplates = useContext(EmailTemplatesContext)

  const {
    field: { onChange, onBlur, name, value }
  } = useController({
    name: `metadata.emails.${index}.template`,
    control: control,
    rules: { required: 'You must select a template' }
  })

  const templateOptions = useMemo(() => {
    return formatToOptions(emailTemplates, { label: 'title', value: 'id' })
  }, [emailTemplates])

  return (
    <Stack>
      <Select
        leftSection={<IconMailQuestion />}
        onChange={onChange}
        onBlur={onBlur}
        value={value?.toString()}
        name={name}
        placeholder='Pick template'
        data={templateOptions}
        searchable
        clearable
        nothingFoundMessage='Nothing found...'
      />
      <Error name={`metadata.emails.${index}.template`}/>
    </Stack>
  )
}

TemplateSelect.propTypes = {
  index: PropTypes.number
}
