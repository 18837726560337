import React from 'react'
import {
  getHiringViewApplicants,
  getHiringViewBatteries,
  getHiringViewBatteriesLastSubmissions,
  getHiringViewTemplates,
  getHiringViewTextTemplates
} from '../../../js/api/hiring_view_repository'
import ContactMultipleApp from './ContactMultipleApp'
import _ from 'lodash'
import CONSTANT from '../../../constants'
import { useParams } from 'react-router-dom'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { useGetHiringViewDetailQuery } from '../../../redux/query/hire/hiringViewsApi.slice'

export default function ContactHiringViewApp () {
  const max = CONSTANT.MAX_CONTACT_APPLICANTS
  const { id } = useParams()
  const { data: hiringViewDetail, error, isLoading } = useGetHiringViewDetailQuery(id)

  const filterCategories = (template) => {
    return template.category?.tag !== 'boost-invite' && template.category?.tag !== 'prescreen-completed'
  }

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <ContactMultipleApp
      getTemplates={() => getHiringViewTemplates(id, filterCategories)}
      getTextTemplates={() => getHiringViewTextTemplates(id)}
      getSubmissions={batteryId => getHiringViewBatteriesLastSubmissions(id, batteryId)}
      getBatteries={() => getHiringViewBatteries(id)}
      getApplicants={(status, courtesyLetter) => getHiringViewApplicants(id, status, courtesyLetter)}
      onSuccessRedirect={`/hire/report-results/hiring-views/${id}/view`}
      getStatuses={() => Promise.resolve(_.unionBy(..._.map(hiringViewDetail.cycles, cycle => cycle.status_set.statuses), 'name'))}
      max={max}
    />
  )
}
