/* eslint react/prop-types: 0 */
import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { BaseModal } from './BaseModal';
import {
  Box,
  Button,
  Container,
  Group,
  LoadingOverlay,
  Space,
  Stack,
  Text
} from '@mantine/core';
import { formatQuestionsFromJson, QuestionStateUpdate } from './QuestionsState';
import { loadAssessmentData } from '../AssessmentLoader';
import { ImportQuestionListItem } from './ImportQuestionsModal'
import { ImportAssessmentTable } from './ImportAssessmentTable';
import { useSearchParams } from 'react-router-dom';

export function ImportAssessmentModal ({ showing, setShowing, dispatch }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const hideAndCleanParams = useCallback(() => {
    setShowing(false)
    setSearchParams({})
  }, [setShowing, setSearchParams])

  if (showing) {
    console.debug('Import assessment modal search params.', searchParams)
  }

  return (
    <BaseModal
      title='Import Assessment as Template'
      showing={showing}
      onClose={() => hideAndCleanParams()}
    >
      <ReactTableAssessmentImport showing={showing} hideAndCleanParams={hideAndCleanParams} globalDispatch={dispatch} />
    </BaseModal>
  )
}

function ReactTableAssessmentImport ({ showing, hideAndCleanParams, globalDispatch }) {
  const [selected, setSelected] = useState(null)
  const [selectedData, setSelectedData] = useState(null)
  const [selectedQuestions, setSelectedQuestions] = useState(new Map())
  const [confirmationShowing, setConfirmationShowing] = useState(false)
  const [isPending, startTransition] = useTransition()

  useEffect(() => {
    if (selected) {
      const callback = loadAssessmentData(selected.id, setSelectedData, (reload) => { console.debug('Selected assessment reload status would have been set.', reload) })

      return () => callback()
    }
    return () => { console.debug('Load selected assessment data effect dismounted with no selection.', selected) }
  }, [selected])

  useEffect(() => {
    if (selectedData && selected && selectedData.id === selected.id) {
      if (selectedData.questions.length) {
        startTransition(() => {
          const formattedQuestions = formatQuestionsFromJson(selectedData.questions)
          setSelectedQuestions(formattedQuestions)
        })
      }
    } else if (selectedData?.id !== selected?.id) {
      setSelectedQuestions(new Map())
    }
  }, [selected, selectedData, setSelectedQuestions])

  const importEnabled = useMemo(() => {
    if ((selected?.id === selectedData?.id) && (!!selected?.id) && (selectedQuestions.size || !selectedData.questions.length)) {
      return !isPending
    }
    return false
  }, [selectedQuestions, selected?.id, selectedData?.id, selectedData?.questions.length, isPending])

  const confirmImportSelected = useCallback((assessment) => {
    if (assessment?.id) {
      console.debug('Import assessment confirmation call - displaying modal.', assessment)
      setSelected(assessment)
      setConfirmationShowing(true)
    } else {
      console.debug('Skipping import confirmation call.', assessment)
    }
  }, [setSelected, setConfirmationShowing])

  const importSelected = useCallback(() => {
    if ((selected?.id === selectedData?.id) && (selectedQuestions.size) && selected?.id && importEnabled) {
      globalDispatch({ type: QuestionStateUpdate.ImportQuestions, newQuestions: selectedQuestions, preservePageBreaks: true })
      hideAndCleanParams()
    } else {
      console.debug('Skipping import call.', selected.id, selectedData.id, selectedQuestions.size, selectedQuestions, importEnabled)
    }
  }, [selected?.id, selectedData?.id, importEnabled, selectedQuestions, globalDispatch, hideAndCleanParams])

  return (
    <div>
      <Group align='flex-start'>
        <ImportAssessmentTable
          importAssessment={confirmImportSelected}
        />
      </Group>
      <BaseModal
        title='Confirm assessment template selection'
        showing={confirmationShowing}
        onClose={() => {
          setConfirmationShowing(false)
          setSelected(null)
        }}
      >
        <div>
          <Group align='flex-start' grow>
            <Container maw='90%' w='90%' h='70vh'>
              <div>
                <Box pos='relative'>
                  <LoadingOverlay visible={!importEnabled} overlayProps={{ blur: 4, backgroundOpacity: 0.6 }} />
                  <Stack justify='flex-start' gap={0}>
                    <Space h='md' />
                    <Group position='apart' wrap='nowrap' grow>
                      <div />
                      <Button color='blue' disabled={!selectedQuestions.size} maw='25%' onClick={() => { importSelected(); setConfirmationShowing(false) }}>Import as Template</Button>
                      <div />
                    </Group>
                    <Space h='md' />
                    {Array.from(selectedQuestions.values()).map(question => <ImportQuestionListItem key={question.id} question={question} selected={true} />)}
                    {(importEnabled && !selectedQuestions.size) ? <Text size='xl' ta='center'>No questions to import found for {selectedData.name} - please try again or select another assessment.</Text> : null}
                  </Stack>
                </Box>
              </div>
            </Container>
          </Group>
        </div>
      </BaseModal>
    </div>
  )
}
