import { Button, Flex, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import Dropdown from '../../core/Dropdown'
import AdminModal from './AdminModal'
export default function CreateButtons () {
  const [opened, { open, close }] = useDisclosure(false)

  const createUserOptions = [
    {
      label: 'Create Client',
      href: '/clients/users/create'
    },
    {
      label: 'Invite Administrator',
      onClick: open
    }
  ]

  const CreateButton = <Button variant='filled' color='success' rightSection={<IconChevronDown size='1.25rem' />}>Create</Button>

  return (
    <>
      <Flex w='auto'>
        <Dropdown target={CreateButton} items={createUserOptions} />
      </Flex>
      <Modal opened={opened} onClose={close} title='Invite Administrator' centered><AdminModal /></Modal>
    </>
  )
}
