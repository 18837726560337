import React from 'react'
import { Paper, Button, Flex, Box, SimpleGrid } from '@mantine/core'
import { useForm } from '@mantine/form'
import { DateInput } from '@mantine/dates'
import OrganizationSelect from '../../build/status_set/OrganizationSelect'
import _ from 'lodash'
import { showNotification } from '@mantine/notifications'
import { exportUsers } from '../../../js/api/user_repository'
import NoticeAlert from '../../core/Alert/NoticeAlert'

export function UserExport () {
  const form = useForm({
    initialValues: {
      organization: '-1',
      startDate: '',
      stopDate: ''
    },
    validate: {
      organization: value => value === null ? 'Please select an option' : null,
      startDate: value => value.length < 1 ? 'Please select a date' : null,
      stopDate: value => value.length < 1 ? 'Please select a date' : null
    },
    transformValues: values => ({
      organization: values.organization === '-1' ? null : values.organization,
      startDate: window.iso8601(values.startDate),
      stopDate: window.iso8601(values.stopDate)
    })
  });

  const submit = values => {
    exportUsers(values)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'UserExport.xlsx')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch(err => {
        const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

        if (err.response.status === 400 && data?.type === 'validation_error') {
          _.forEach(data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  };

  return (
    <>
      <h1>
        Export Users
      </h1>
      <NoticeAlert title=''>
        This is an export of all accounts who had activity on HireScore within the provided time frame below from the selected Organization:
      </NoticeAlert>
      <form onSubmit={form.onSubmit(values => submit(values))}>
      <Paper component={Flex} gap={'lg'} direction={'column'}>
        <OrganizationSelect value={form.values.organization} onChange={value => form.setFieldValue('organization', value)}/>
        <SimpleGrid cols={2}>
          <DateInput label="Start At" placeholder="Date input" withAsterisk {...form.getInputProps('startDate')}/>
          <DateInput label="Stop At" placeholder="Date input" withAsterisk {...form.getInputProps('stopDate')}/>
        </SimpleGrid>
        <Box>
          <Button w={'auto'} type='submit'>Export</Button>
        </Box>
      </Paper>
      </form>
    </>
  )
}
