/* eslint react/prop-types: 0 */
import React, { useState, useMemo, useCallback, memo, useRef, useEffect } from 'react';
import {
  Button,
  Group,
  Select,
  Text,
  ActionIcon,
  Chip,
  Center,
  Space,
  Grid,
  Indicator,
  Paper,
  rem,
  Divider,
  Stack,
  HoverCard,
  Avatar,
  Box,
  ScrollArea
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconTrashX, IconArrowsShuffle, IconX } from '@tabler/icons-react';
import { QuestionStateUpdate, RandomizedPoolEvent } from './QuestionsState';
import QuestionEditor, { getMediaIconForType, QuestionMediaDisplay } from './QuestionEditor';
import { isBetaOnly, QuestionType } from '../../../../js/generated/enums/QuestionType';
import { useAssessmentCompetencies } from '../AssessmentHooks';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { RatingScaleModal } from './RatingScaleModal';
import { MediaType } from '../../../../js/generated/enums/MediaType';
import { QuestionsLogicEditor } from './QuestionsLogicEditor';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertQuestionMapData,
  createLogicArrayFromQuestionsMap,
  ValidationState
} from './UnpublishedQuestionLogic/util';
import {
  potentialQuestionPositionsChanged, questionDeleted, selectUserShouldCheckQuestionIdLogic,
  unpublishedLogicLoaded,
  validateLogic
} from './UnpublishedQuestionLogic/unpublishedLogicSlice';
import { useQuestionEditor } from './QuestionContentEditor';
import { getQuestionContentIsNewPlaceholder } from './util';
import { notifications } from '@mantine/notifications';

/**
 * @param id
 * @param {Map.<int, Question>} questions
 * @param {?int} activeQuestionId
 * @param {?int} activeAnswerId
 * @param dispatch
 * @param {boolean} mediaPopupShowing
 * @param setMediaPopup
 * @param {int} undoHistoryIndex
 */
export const QuestionsList = memo(function QuestionsList (
  {
    id,
    questions,
    activeQuestionId,
    activeAnswerId,
    dispatch,
    mediaPopupShowing,
    setMediaPopup,
    undoHistoryIndex
  }
) {
  const competencies = useAssessmentCompetencies()

  const questionTypeOptions = useMemo(() => {
    return Object.values(QuestionType)
  }, [])

  const activeQuestionContent = useMemo(() => {
    return questions.has(activeQuestionId) ? questions.get(activeQuestionId).content : ''
  }, [activeQuestionId, questions])

  const editorConfig = useMemo(() => {
    return { questionId: activeQuestionId, content: activeQuestionContent, dispatch: dispatch }
  }, [activeQuestionId, activeQuestionContent, dispatch])

  const editor = useQuestionEditor(editorConfig)

  const reduxDispatch = useDispatch()
  const lastLogicValidationStatus = useRef(null)
  const activeQuestionIdRef = useRef(activeQuestionId)
  const changesSinceLastValidation = useRef({ activeQuestionIds: new Set(), lastActiveQuestionIds: [] }) // consider removing these, likely not needed.
  const lastUndoHistoryIndex = useRef(undoHistoryIndex)
  const logicValidationStatus = useSelector(state => state.unpublishedLogic.status)

  useEffect(() => {
    if (lastUndoHistoryIndex.current !== undoHistoryIndex) {
      lastUndoHistoryIndex.current = undoHistoryIndex
      const logicArray = createLogicArrayFromQuestionsMap(questions)
      console.info('Loading unpublished logic into redux store from undo/redo.', logicArray, questions)
      reduxDispatch(unpublishedLogicLoaded({ logicArray }))
    }
  }, [questions, undoHistoryIndex, reduxDispatch])

  useEffect(() => {
    const activeQuestionIdBeforeChange = activeQuestionIdRef.current
    if (activeQuestionIdBeforeChange) {
      changesSinceLastValidation.current.activeQuestionIds.add(activeQuestionIdBeforeChange)
      if (!changesSinceLastValidation.current.lastActiveQuestionIds.includes(activeQuestionIdBeforeChange)) {
        changesSinceLastValidation.current.lastActiveQuestionIds.splice(2, 1, activeQuestionIdBeforeChange)
      }
    }
    if (logicValidationStatus !== lastLogicValidationStatus.current) {
      console.info('Logic validation status changed.', logicValidationStatus, lastLogicValidationStatus.current)
      const loadLogic = lastLogicValidationStatus.current === null
      lastLogicValidationStatus.current = logicValidationStatus
      if (loadLogic) {
        const logicArray = createLogicArrayFromQuestionsMap(questions)
        console.info('Loading unpublished logic into redux store.', logicArray, questions)
        reduxDispatch(unpublishedLogicLoaded({ logicArray }))
      }
      if (logicValidationStatus === ValidationState.Validating) {
        const lastActiveQuestionIds = changesSinceLastValidation.current.lastActiveQuestionIds
        changesSinceLastValidation.current = { activeQuestionIds: new Set(), lastActiveQuestionIds: activeQuestionIdBeforeChange ? [activeQuestionIdBeforeChange] : [] }
        reduxDispatch(validateLogic({ questionsMapData: convertQuestionMapData(questions), lastActiveQuestionIds: lastActiveQuestionIds }))
      }
    }
  }, [logicValidationStatus, questions, reduxDispatch])

  useEffect(() => {
    if (activeQuestionId !== activeQuestionIdRef.current) {
      activeQuestionIdRef.current = activeQuestionId
    }
  }, [activeQuestionId])

  const questionList = useMemo(() => {
    return Array.from(questions.values())
  }, [questions])

  const onDragStart = useCallback((dragStart) => {
    console.debug('Called on drag start.', dragStart)
  }, [])

  const onDragEnd = useCallback((result) => {
    console.debug('Called on drag end.', result)
    if (!result.destination) {
      return
    }
    if (result.destination.droppableId !== id.toString()) {
      dispatch({ type: QuestionStateUpdate.SortAnswers, questionId: parseInt(result.destination.droppableId), answerId: parseInt(result.draggableId), newPosition: result.destination.index })
    } else {
      dispatch({ type: QuestionStateUpdate.SortQuestions, questionId: parseInt(result.draggableId), newPosition: result.destination.index })
      reduxDispatch(potentialQuestionPositionsChanged({ questionId: parseInt(result.draggableId) }))
    }
  }, [dispatch, id, reduxDispatch])

  return (
    <div>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId={id.toString()} type='QUESTION' isDropDisabled={!!activeQuestionId}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ overflowY: 'visible' }}
            >
            {questionList.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={!!activeQuestionId}>
                {(
                  draggableProvided,
                  draggableSnapshot
                ) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getDropStyle(draggableProvided.draggableProps.style, draggableSnapshot)}
                  >
                    <QuestionListItemWrapper questionId={item.id} hasLogic={!!item.logic?.length} pageBreak={!!item.pageBreak}>
                      <QuestionListItem
                        question={item}
                        position={item.originalNumber ?? item.number}
                        active={item.id === activeQuestionId}
                        anyActive={!!activeQuestionId}
                        dragging={draggableSnapshot.isDragging && !draggableSnapshot.isDropAnimating}
                        dispatch={dispatch}
                      />
                      { item.id === activeQuestionId
                        ? (
                          <>
                            <QuestionEditor
                              question={item}
                              activeAnswerId={activeAnswerId}
                              competencies={competencies}
                              mediaPopupShowing={mediaPopupShowing}
                              editor={editor}
                              dispatch={dispatch}
                              setMediaPopup={setMediaPopup}
                            />
                            <QuestionsLogicEditor
                              question={item}
                              questionIndex={index}
                              questions={questions}
                              dispatch={dispatch}
                            />
                          </>
                          )
                        : null }
                    </QuestionListItemWrapper>
                  </div>
                )}
              </Draggable>
            ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Space h="md" />
      <AddNewQuestion
        questionTypes={questionTypeOptions}
        dispatch={dispatch}
        setMediaPopup={setMediaPopup}
      />
    </div>
  )
})

function getDropStyle (style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { curve, duration } = snapshot.dropAnimation;
  return {
    ...style,
    marginTop: 0,
    marginBottom: 0,
    transition: `all ${curve} ${duration > 0.05 ? 0.05 : duration}s`
  };
}

function QuestionListItemWrapper ({ questionId, hasLogic, pageBreak, children }) {
  const userCheckLogic = useSelector((state) => selectUserShouldCheckQuestionIdLogic(state, questionId))
  return (
    <div>
      <Indicator color={userCheckLogic ? 'red' : 'blue'} size={userCheckLogic ? 17 : 10} position='top-start' label={userCheckLogic ? 'Logic' : ''} processing={userCheckLogic} disabled={!userCheckLogic && !hasLogic}>
        {children}
      </Indicator>
      { pageBreak
        ? (
        <div>
          <Space h='md'/>
          <Divider size='lg' label="Page Break" labelPosition="center" />
          <Space h='xs'/>
        </div>
          )
        : null}
    </div>
  )
}

/**
 * @param {Question} question
 * @param {int} position
 * @param {boolean} active
 * @param {boolean} anyActive
 * @param {boolean} dragging
 * @param dispatch
 */
const QuestionListItem = memo(function QuestionListItem ({ question, position, active, anyActive, dragging, dispatch }) {
  const { hovered, ref } = useHover()
  const reduxDispatch = useDispatch()
  const deleteQuestion = useCallback((e) => {
    e.stopPropagation()
    dispatch({ type: QuestionStateUpdate.RemoveQuestion, questionId: question.id })
    reduxDispatch(questionDeleted({ questionId: question.id }))
  }, [question.id, dispatch, reduxDispatch])

  const focusQuestion = useCallback((e) => {
    // Note this hack is only necessary for the checkbox + its labels due to how that event propagates.
    if (!(e.target?.type === 'checkbox') && !((new Set(['Required', 'Not Required'])).has(e.target?.innerText))) {
      dispatch({ type: QuestionStateUpdate.FocusQuestion, questionId: question.id })
    } else {
      console.debug('Skipping focus question for checkbox.', e.target)
    }
  }, [question.id, dispatch])

  const toggleRequireQuestion = useCallback((e) => {
    e.stopPropagation()
    dispatch({ type: QuestionStateUpdate.UpdateQuestion, questionId: question.id, newAttributes: { required: e.target.checked ? 0 : 1 } })
  }, [question.id, dispatch])

  const handlePoolRandomization = useCallback((e) => {
    if (e.button !== 2) {
      e.stopPropagation()
      const poolEvent = (e.button === 0) ? RandomizedPoolEvent.Increment : RandomizedPoolEvent.Reset
      dispatch({
        type: QuestionStateUpdate.RandomizeQuestion,
        poolEvent: poolEvent,
        questionId: question.id
      })
      reduxDispatch(potentialQuestionPositionsChanged({ questionId: question.id }))
    }
  }, [dispatch, question.id, reduxDispatch])

  const handlePoolRandomizationRightClick = useCallback((e) => {
    e.preventDefault()
    dispatch({
      type: QuestionStateUpdate.RandomizeQuestion,
      poolEvent: RandomizedPoolEvent.Decrement,
      questionId: question.id
    })
    reduxDispatch(potentialQuestionPositionsChanged({ questionId: question.id }))
  }, [dispatch, question.id, reduxDispatch])

  const cursor = dragging ? 'grabbing' : (!anyActive ? 'grab' : (active ? 'zoom-out' : 'zoom-in'))
  const background = dragging ? 'blue.1' : (hovered ? 'gray.2' : 'gray.1')
  return (
    <Paper
      bg={background}
      mt='xs'
      mb={0}
      onClick={focusQuestion}
      style={{ userSelect: 'none', cursor: cursor }}
      ref={ref}
    >
      <Grid columns={24} justify='flex-start' align='center'>
        {question.isNumbered
          ? (
          <Grid.Col span={1}>
            <Group wrap='nowrap' gap={0} justify='flex-end'>
              <Text fw={700} size='lg'>{(position).toString()}.</Text>
            </Group>
          </Grid.Col>
            )
          : null}
        <Grid.Col span={5}>
          <Group wrap='nowrap' gap={0}>
            <Space w='xs' />
            <Text ta='left' fw={500} size='lg' truncate='end'>{question.type}</Text>
          </Group>
        </Grid.Col>
        {question.isNumbered
          ? (
          <Grid.Col span={4}>
            <Center>
              <Chip
                icon={<IconX style={{ width: rem(16), height: rem(16) }} />}
                color='red'
                variant={question.required ? 'outline' : 'filled'}
                checked={!question.required}
                onClick={toggleRequireQuestion}
                size='md'
              >
                { question.required ? <Text c='dimmed' size='md' mt='.3rem'>Required</Text> : <Text size='md' mt='.3rem'>Not Required</Text>}
              </Chip>
            </Center>
          </Grid.Col>
            )
          : null }
        <Grid.Col span={question.isNumbered ? 12 : 17}>
          <QuestionTitleAndCompetencies
            content={question.content}
            competencies={question.competencies}
            active={active}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <ActionIcon
            variant='transparent'
            size='md'
            aria-label='Randomized pool'
            color={ question.randomizedPool ? 'blue' : 'gray' }
            onClick={handlePoolRandomization}
            onAuxClick={handlePoolRandomization}
            onContextMenu={handlePoolRandomizationRightClick}
          >
            <Indicator
              disabled={!question.randomizedPool}
              size={20}
              label={question.randomizedPool?.toString()}
              position='bottom-end'
              offset={4}
            >
              <IconArrowsShuffle />
            </Indicator>
          </ActionIcon>
        </Grid.Col>
        <Grid.Col span={1}>
          <OnHoverGradientAction
            rowHovered={hovered}
            onClick={deleteQuestion}
            normalStart='gray'
            normalStop='gray'
            hoveredStart='red'
            hoveredStop='gray'
          />
        </Grid.Col>
      </Grid>
    </Paper>
  )
})

export const QuestionTitleAndCompetencies = memo(function QuestionTitleAndCompetencies (
  { content, competencies, active = false, textSize = 'lg', maxShowCompetencies = 2, maxShownCompetencyLength = 30, media = null }
) {
  const sanitizedContent = useMemo(() => {
    return content.replace(/<[^>]*>?/gm, '').replace(/&[^;]{0,9};/gm, '')
  }, [content])

  const questionCompetenciesTitles = useMemo(() => {
    const numberCompetencies = competencies.length
    return competencies.slice(0, maxShowCompetencies).map((element, index) =>
      <Text ta='right' size='xs' truncate='end' key={element.id}>{
        (((index < (maxShowCompetencies - 1)) || (maxShowCompetencies === numberCompetencies))
          ? ((element.name.length > maxShownCompetencyLength) ? element.name.slice(0, maxShownCompetencyLength - 3) + '...' : element.name)
          : '(and ' + (numberCompetencies - index).toString() + ' more)')
      }</Text>
    )
  }, [competencies, maxShowCompetencies, maxShownCompetencyLength])

  const mediaSizeProps = useMemo(() => {
    if (media?.width && media?.height) {
      return { width: media.width, height: media.height }
    }
    return {}
  }, [media])

  const contentIsPlaceholder = getQuestionContentIsNewPlaceholder(content)

  return (
    <HoverCard shadow='md' openDelay={200} disabled={active} withArrow withinPortal>
      <HoverCard.Target>
        <Group position='apart' gap='xs' wrap='nowrap' grow>
          <Text ta='left' size={textSize} truncate='end' c={contentIsPlaceholder ? 'dimmed' : 'inherit'}>{sanitizedContent}</Text>
          {competencies.length
            ? (
            <Stack align='flex-end' gap={0}>
              {questionCompetenciesTitles}
            </Stack>
              )
            : null}
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div>
          <ScrollArea maw='70vw' mah='40vh'>
            <Box maw='70vw' mah='40vh'>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Box>
          </ScrollArea>
          {media
            ? (
            <QuestionMediaDisplay
              id={media.id}
              type={media.type}
              link={media.link}
              description={media.description}
              identifier={media.identifier}
              name={media.name}
              mediaIcon={getMediaIconForType(media.type)}
              mediaSizeProps={mediaSizeProps}
            >
              {media.type === MediaType.Image
                ? (
                  <Avatar size='xl' src={media.link} />
                  )
                : (
                  <ActionIcon
                    variant='outline'
                    size='xl'
                    radius='lg'
                  >
                    {getMediaIconForType(media.type)}
                  </ActionIcon>
                  )}
            </QuestionMediaDisplay>
              )
            : null}
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  )
})

const OnHoverGradientAction = memo(function OnHoverGradientAction ({ rowHovered, onClick, normalStart, normalStop, hoveredStart, hoveredStop }) {
  const actionNormal = useMemo(() => {
    return { from: normalStart, to: normalStop, deg: 135 }
  }, [normalStart, normalStop])

  const actionHovered = useMemo(() => {
    return { from: hoveredStart, to: hoveredStop, deg: 180 }
  }, [hoveredStart, hoveredStop])

  const { hovered, ref } = useHover();

  const styleProps = useMemo(() => {
    if (rowHovered) {
      return {}
    }
    return { style: { opacity: 0 } }
  }, [rowHovered])

  return (
    <div ref={ref}>
      <ActionIcon
        variant='gradient'
        size='md'
        aria-label='Delete question'
        gradient={hovered ? actionHovered : actionNormal}
        onClick={onClick}
        { ...styleProps }
      >
        <IconTrashX/>
      </ActionIcon>
    </div>
  )
})

const AddNewQuestion = memo(function AddNewQuestion ({ questionTypes, dispatch, setMediaPopup }) {
  const [addingQuestion, setAddingQuestion] = useState(false)
  const [ratingScaleShowing, setRatingScaleShowing] = useState(false)

  const makeNewQuestion = useCallback((questionType) => {
    setAddingQuestion(false)
    if (questionType === QuestionType.RatingScale) {
      setRatingScaleShowing(true)
    } else {
      dispatch({ questionType: questionType, type: QuestionStateUpdate.NewQuestion })
      if (isBetaOnly(questionType)) {
        notifications.show(
          {
            color: 'yellow',
            title: 'Beta Only',
            message: 'Question type ' + questionType + ' can only be published in Beta-style assessments.'
          }
        )
      }
    }
  }, [setAddingQuestion, setRatingScaleShowing, dispatch])

  const startAddingQuestion = useCallback(() => {
    setAddingQuestion(true)
  }, [setAddingQuestion])

  const stopAddingQuestion = useCallback(() => {
    setAddingQuestion(false)
  }, [setAddingQuestion])

  return (
    <div>
      {addingQuestion
        ? (
        <Select data={questionTypes} placeholder="Select type for new question" onChange={makeNewQuestion} />
          )
        : null }
      {addingQuestion
        ? (
        <div>
          <Space h='sm' />
          <Button color='gray.6' onClick={stopAddingQuestion}>Cancel</Button>
        </div>
          )
        : (
        <Button tcolor='success' onClick={startAddingQuestion}>Add question</Button>
          )}
      <div>
        <RatingScaleModal
          showing={ratingScaleShowing}
          setShowing={setRatingScaleShowing}
          dispatch={dispatch}
        />
      </div>
    </div>
  )
})
