import axios from 'axios'

export function getMediaCollection (params, cancelToken = null) {
  const config = {
    method: 'GET',
    url: 'api/v1/media',
    params: params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios media request via token.', config)
      } else {
        console.error('Media request error.', err)
        throw err
      }
    })
}

export function getMedia (id, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/media/${id}`
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled getMedia via token.', config)
      } else {
        console.error('getMedia error.', err)
        throw err
      }
    })
}

export async function createMedia (data) {
  const formData = new FormData()
  formData.append('identifier', data.identifier)
  formData.append('description', data.description)
  formData.append('type', data.type)
  formData.append('file', data.file)

  if (data.width !== null) {
    formData.append('width', data.width)
  }

  if (data.height !== null) {
    formData.append('height', data.height)
  }

  return axios({
    method: 'POST',
    url: '/api/v1/media',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function editMedia (id, json) {
  return axios({
    method: 'PATCH',
    url: `/api/v1/media/${id}`,
    data: json
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export async function deleteMedia (id) {
  return axios({
    method: 'DELETE',
    url: `/api/v1/media/${id}`
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}
