import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { useMemo, useRef } from 'react';

export function useStableLocalStorage (storageConfig) {
  const [savedData, setSavedData, resetSavedData] = useLocalStorage(storageConfig) // Does not read on first render.
  const savedDataInstantRead = readLocalStorageValue(storageConfig)
  const stableDataRef = useRef(savedDataInstantRead) // savedDataInstantRead changes each render.
  const loadedDataRef = useRef(false)

  const stableSavedData = useMemo(() => {
    if (!loadedDataRef.current) {
      console.debug('Initial load - returning original stable data ref.', savedData, stableDataRef.current)
      loadedDataRef.current = true
      return stableDataRef.current
    }
    if (JSON.stringify(savedData instanceof Set ? [...savedData] : savedData) !== JSON.stringify(stableDataRef.current instanceof Set ? [...stableDataRef.current] : stableDataRef.current)) {
      console.debug('Stable local data changed - returning new object.', savedData, stableDataRef.current)
      stableDataRef.current = savedData
      return savedData
    }
    console.debug('Stable local data did not change - returning previous object.', savedData, stableDataRef.current)
    return stableDataRef.current
  }, [savedData])

  return [stableSavedData, setSavedData, resetSavedData]
}
