/* eslint react/prop-types: 0 */
import React, { useContext, useMemo, useState } from 'react'
import ReactTable, { ReactTableContextsProvider } from './../../core/ReactTable/ReactTable'
import { Button, Group, Modal } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import Content from '../../layout/Content'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { selectAllParamsQueryData, selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useSelector } from 'react-redux';
import { apiSlice } from '../../api';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useDeleteHiringViewMutation, useGetHiringViewsQuery } from '../../../redux/query/hire/hiringViewsApi.slice'

const namespace = 'hiringViews'

export default function HomeTable () {
  const hasAdminPermissions = document.getElementById('is-admin').getAttribute('value')

  return (
    <Content>
      <BrowserRouter>
        <Routes>
          <Route path='/cycles' element={<WrappedHomeTable hasAdminPermissions={hasAdminPermissions} />} />
        </Routes>
      </BrowserRouter>
    </Content>
  )
}

const filters = []
const defaultFilters = {}
const defaultHiddenColumns = []

export function WrappedHomeTable (hasAdminPermissions) {
  const [collection] = useLazyHiringViewsQuery(namespace)
  const [deleteHiringView] = useDeleteHiringViewMutation()
  const [view, setView] = useState(false)

  const columns = useMemo(
    () => {
      const cols = [
        {
          Header: 'Name',
          accessor: 'name',
          sortable: false,
          Cell: ({ cell: { value, row } }) => {
            return <a href={'/hire/report-results/hiring-views/' + row.original.id + '/view'} className='TableLink'>{value}</a>
          }
        }
      ]
      return [
        {
          Header: 'Hiring Views',
          columns: cols
        }
      ]
    },
    []
  )

  const actions = useMemo(
    () => hasAdminPermissions
      ? [
          {
            label: 'Edit',
            leftSection: <IconPencil />,
            href: (id) => `/hire/report-results/hiring-views/${id}/edit`
          },
          {
            label: 'Delete',
            leftSection: <IconTrash />,
            onClick: (id) => setView(id),
            variant: 'danger-subtle'
          }
        ]
      : [], [hasAdminPermissions]
  )

  const remove = () => {
    deleteHiringView({ id: view })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted hiring view',
          color: 'success'
        })
        setView(false)
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting this hiring view',
          color: 'red'
        })
      })
  }

  const handleRowClicked = row => {
    if (typeof row === 'undefined') return true
    const id = row.dataset.rowid
    window.location.href = `/hire/report-results/hiring-views/${id}/view`
    return true
  }

  return (
    <>
      <Modal opened={!!view} onClose={() => setView(false)} title='Are you sure you want to delete this hiring view?'>
        <Group spacing='xs'>
          <Button color='red.6' onClick={remove}>Delete</Button>
          <Button color='gray.6' onClick={() => setView(false)}>Cancel</Button>
        </Group>
      </Modal>
      <div className='HiringViewHome' style={{ marginBottom: '20px', display: collection?.items.length ? 'block' : 'none' }}>
        <ReactTableContextsProvider
          columns={columns}
          namespace={namespace}
          defaultFilters={defaultFilters}
          defaultHiddenColumns={defaultHiddenColumns}
        >
          <HiringViewTableDataProvider>
            <ReactTable actions={actions} filters={filters} onRowClick={handleRowClicked} noBorders notDynamic />
          </HiringViewTableDataProvider>
        </ReactTableContextsProvider>
      </div>
    </>
  )
}

export function useLazyHiringViewsQuery (namespace) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getHiringViews.useQueryState(queryParams)
  return [collection ?? null, querying]
}

export function useHiringViewsQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetHiringViewsQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function HiringViewTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useHiringViewsQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating HiringViewTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Hiring view table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
