import { Button, Tabs } from '@mantine/core';
import { IconArrowLeft, IconLock, IconSettings, IconShieldLock } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { myAccount } from '../../js/api/account_repository';
import styles from './AccountTabs.module.scss';
import General from './General';
import Privacy from './Privacy';
import Security from './Security';

export default function AccountTabs ({ rootPath, apiBasePath }) {
  const [account, setAccount] = useState(null)
  const navigate = useNavigate()
  const { section } = useParams()

  useEffect(() => {
    myAccount(apiBasePath).then(response => setAccount(response))
  }, [apiBasePath])

  return (
    <Tabs defaultValue='general' value={section} onChange={val => navigate(`/${rootPath}/${val}`)}>
      <Button
        onClick={() => navigate(-1)}
        leftSection={<IconArrowLeft />}
        mb='xs'
        >
        Back
      </Button>
      <Tabs.List>
        <Tabs.Tab value='general' rightSection={<IconSettings size='1rem'/>}>General</Tabs.Tab>
        <Tabs.Tab value='security' rightSection={<IconLock size='1rem'/>}>Security</Tabs.Tab>
        {
        account?.roles !== undefined &&
        account?.roles.find(role => role.name === 'ROLE_AP') !== undefined &&
        account?.roles.find(role => role.name === 'ROLE_PORTAL_CLIENT') === undefined &&
        account?.roles.find(role => role.name === 'ROLE_ADMIN') === undefined &&
      <Tabs.Tab value='privacy' rightSection={<IconShieldLock size='1rem'/>}>Privacy & Data</Tabs.Tab>
        }
      </Tabs.List>

      <Tabs.Panel value='general' className={styles.panel}>
        {account !== null && <General account={account} apiBasePath={apiBasePath}/>}
      </Tabs.Panel>

      <Tabs.Panel value='security' className={styles.panel}>
        {account !== null && <Security account={account} apiBasePath={apiBasePath}/>}
      </Tabs.Panel>
      {
      account?.roles.find(role => role.name === 'ROLE_AP') !== undefined &&
      account?.roles.find(role => role.name === 'ROLE_PORTAL_CLIENT') === undefined &&
      account?.roles.find(role => role.name === 'ROLE_ADMIN') === undefined &&
        <Tabs.Panel value='privacy' className={styles.panel}>
          {account !== null && <Privacy/>}
        </Tabs.Panel>
      }
    </Tabs>
  )
}

AccountTabs.propTypes = {
  rootPath: PropTypes.string,
  apiBasePath: PropTypes.string
}
