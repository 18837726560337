import axios from 'axios'

export function invite (applicantId, cycleId, ksFormId) {
  return axios.post(`/api/v1/keysurvey/invite?applicantId=${applicantId}&cycleId=${cycleId}&ksFormId=${ksFormId}`)
    .then(response => response.data.data.redirect)
    .catch(err => {
      console.error(err)
      throw err
    })
}
