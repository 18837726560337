import React, { useMemo, useState } from 'react'
import { CheckIcon, Combobox, Group, ScrollArea } from '@mantine/core'
import StringUtil from '../../../../js/util/StringUtil'
import _ from 'lodash'

const sizes = {
  WIDTH: '20rem',
  HEIGHT: '30rem'
}

export function useFilterItem ({ filter, selected }) {
  const [search, setSearch] = useState('')
  const shouldFilterOptions = useMemo(() => filter?.options.every((option) => option.label.toString() !== search), [filter, search]);
  const filteredOptions = useMemo(() => {
    return shouldFilterOptions
      ? filter.options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase().trim()))
      : filter.options;
  }, [filter, search, shouldFilterOptions]);

  const groups = useMemo(() => _.uniq([...filteredOptions.map(fo => fo.group ?? '')]).map(group => {
    let options = filteredOptions
    if (group !== '') {
      options = filteredOptions.filter(fo => fo.group === group)
    }
    return (
      <Combobox.Group
        label={group}
        key={`${filter.label}-${StringUtil.makeId()}`}
        >
        {options.map((item) => {
          return (
            <Combobox.Option
              value={item.value}
              key={`${filter.label}-${item.value}-${StringUtil.makeId()}`}
              active={selected?.includes(item.value)}
            >
              <Group gap="sm">
                {selected?.includes(item.value.toString()) ? <CheckIcon size={12} /> : null}
                <span>{item.label}</span>
              </Group>
            </Combobox.Option>
          )
        })}
    </Combobox.Group>
    )
  }), [filter, filteredOptions, selected])

  const dropdown = useMemo(() => (
    <ScrollArea.Autosize mah={sizes.HEIGHT}>
      {groups}
    </ScrollArea.Autosize>
  ), [groups])

  return {
    combobox: {
      withinPortal: false,
      w: sizes.WIDTH,
      style: { display: filter.hidden ? 'none' : 'block' }
    },
    input: {
      value: search,
      disabled: filter.disabled ?? filter.options.length === 0
    },
    dropdown: dropdown,
    value: selected,
    search: search,
    options: filteredOptions,
    setSearch: setSearch
  }
}
