/* eslint-disable camelcase */
import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { orderColumn } from './tableColumnsSlice';
import { shallowEqual } from 'react-redux';

export const batchUpdateParams = createAsyncThunk('params/batchUpdateParams', async (batchParams, { dispatch, getState }) => {
  const { queryId, fieldsToValues } = batchParams
  const state = getState()
  const filters = selectParamsFilters(state, queryId) ?? {}
  const indexes = selectParamsIndexes(state, queryId)
  const newFilters = { ...filters }
  const newIndexes = { ...indexes }
  let anyFiltersChanged = false
  let anyIndexesChanged = false
  for (const [field, value] of Object.entries(fieldsToValues)) {
    if (isMetaParamsKey(field)) {
      console.debug('Skipping batch update meta param key.', { field, value })
      continue
    }
    if ((value === null) || (value === undefined)) {
      if (isIndexParamsKey(field)) {
        if ((indexes[field] !== null) && (indexes[field] !== undefined)) {
          newIndexes[field] = null
          anyIndexesChanged = true
        }
      } else {
        if ((filters[field] !== null) && (filters[field] !== undefined)) {
          delete newFilters[field]
          anyFiltersChanged = true
        }
      }
    } else {
      if (isIndexParamsKey(field)) {
        if (indexes[field] !== value) {
          newIndexes[field] = value
          anyIndexesChanged = true
        }
      } else {
        if (filters[field] !== value) {
          newFilters[field] = value
          anyFiltersChanged = true
        }
      }
    }
  }
  const newParams = {}
  if (anyIndexesChanged) {
    newParams.indexes = newIndexes
  }
  if (anyFiltersChanged) {
    newParams.filters = newFilters
  }
  if (anyFiltersChanged || anyIndexesChanged) {
    dispatch(conditionallyUpdateParams({ ...newParams, queryId }))
    // const newOrderBy = newParams.indexes?.order_by ?? null
    // if (anyIndexesChanged && (newOrderBy || (!newOrderBy && (indexes.order_by ?? null)))) {  // Note this version doesn't account for changing just the abstract order value (e.g. column id)
    //   syncColumnOrderBy(queryId, newParams, dispatch, batchParams)
    // }
    syncColumnOrderBy(queryId, { indexes, filters }, { indexes: newIndexes, filters: newFilters }, dispatch, batchParams)
    return { queryId: queryId, changed: true, error: false, cancelled: false }
  }
  console.debug('Batch update search param fields would have been a no-op.', { fieldsToValues, filters, indexes })
  return { queryId: queryId, changed: false, error: false, cancelled: false }
})

export const updateParamField = createAsyncThunk('params/updateParamField', async (fieldParams, { dispatch, getState }) => {
  const { queryId, field, value } = fieldParams
  const state = getState()
  const filters = selectParamsFilters(state, queryId) ?? {}
  const indexes = selectParamsIndexes(state, queryId)

  if (isMetaParamsKey(field)) {
    console.debug('Update search param field is meta and would be a no-op.', { field, value })
    return
  }
  if (isIndexParamsKey(field)) {
    if (indexes[field] !== (value ?? null)) {
      dispatch(conditionallyUpdateParams({ queryId: queryId, indexes: { ...indexes, [field]: value } }))
      syncColumnOrderBy(
        queryId, { indexes, filters }, { indexes: { ...indexes, [field]: value }, filters: filters }, dispatch, fieldParams
      )
      return { queryId: queryId, changed: true, error: false, cancelled: false }
    }
    console.debug('Update search param field would have been a no-op.', { field, value, filters, indexes })
    return { queryId: queryId, changed: false, error: false, cancelled: false }
  }
  if (filters[field] !== (value ?? undefined)) {
    dispatch(conditionallyUpdateParams({ queryId: queryId, filters: { [field]: value }, preserveOtherFilters: true }))
    // syncColumnOrderBy(  // Note: unhandled due to implementation (must use batch update for abstract sort)
    //   queryId, { indexes, filters }, { indexes: indexes, filters: { ...filters, [field]: value } }, dispatch, fieldParams
    // )
    return { queryId: queryId, changed: true, error: false, cancelled: false }
  }
  console.debug('Update search param field would have been a no-op.', { field, value, filters, indexes })
  return { queryId: queryId, changed: false, error: false, cancelled: false }
})

export const updateQuery = createAsyncThunk('params/updateQuery', async (queryParams, { dispatch, rejectWithValue, signal }) => {
  const { queryId, params } = queryParams
  dispatch(conditionallyUpdateParams({ ...params, queryId }))
  if (signal.aborted) {
    return rejectWithValue({ queryId: queryId, changed: null, error: true, cancelled: true })
  }
  syncColumnOrderBy(queryId, { indexes: {}, filters: {} }, params, dispatch, queryParams)
  return { queryId: queryId, changed: true, error: false, cancelled: false }
})

function syncColumnOrderBy (queryId, oldParams, newParams, dispatch, actionParams) { // TODO ensure abstractOrderVariants always passed or put in state
  console.debug('Checking sync column order id', { queryId, oldParams, newParams, dispatch, actionParams })
  const fallbackOrderBy = newParams.indexes?.order_by ?? oldParams.indexes?.order_by
  if ( // If order by changed, if order direction changed, or if order by is/was an abstract order by key and the filter abstract order by value changed.
    (oldParams.indexes?.order_by !== (newParams.indexes?.order_by ?? null)) ||
    (oldParams.indexes?.order_direction !== (newParams.indexes?.order_direction ?? null)) ||
    (actionParams.abstractOrderVariants && fallbackOrderBy && (fallbackOrderBy in actionParams.abstractOrderVariants) && ((newParams.filters?.[actionParams.abstractOrderVariants[fallbackOrderBy]] ?? null) !== (oldParams.filters?.[actionParams.abstractOrderVariants[fallbackOrderBy]] ?? null)))
  ) {
    const orderBy = newParams.indexes?.order_by ?? '_NONE_'
    const orderDirection = newParams.indexes?.order_direction ?? 'ASC'
    if (actionParams.abstractOrderVariants && (actionParams.abstractOrderVariants[orderBy] ?? false)) {
      // If orderScore is the abstract filter (value of abstract variant),
      // and score is the abstract key (key of variant - orderBy value),
      // then orderScore is the filter key, and the id (or sortId) of the order column is the filter value
      // and score (abstract key) is the value of order_by in indexes
      const abstractOrder = newParams.filters?.[actionParams.abstractOrderVariants[orderBy]] ?? null
      dispatch(orderColumn({
        namespace: queryId,
        columnId: abstractOrder,
        orderBy: abstractOrder ? orderBy : null,
        order: orderDirection,
        parentId: actionParams.parentId ?? null,
        updateSortId: actionParams.updateSortId ?? null
      }))
    } else {
      if ('abstractOrderVariants' in actionParams) {
        dispatch(orderColumn({
          namespace: queryId,
          columnId: newParams.indexes?.order_by ? orderBy : null,
          orderBy: newParams.indexes?.order_by ? orderBy : null,
          order: orderDirection,
          parentId: actionParams.parentId ?? null,
          updateSortId: actionParams.updateSortId ?? null
        }))
      } else {
        console.warn(
          'Skipping column order update on update query due to missing param - is legacy update? Please update this table!',
          { actionParams }
        )
      }
    }
  }
}

/**
 * @typedef {Object} Params
 * @property {string} id
 * @property {null|string|int} limit
 * @property {null|string} search
 * @property {null|string} order_direction
 * @property {null|string} order_by
 * @property {null|string|int} page
 * @property {Object} filters
 * @property {Object} meta
 * @property {string?} meta.metaQueryUrl?
 * @property {string?} meta.metaMethod?
 */

/**
 * @type {EntityAdapter<{Params}, string>}
 */
const paramsAdapter = createEntityAdapter()

const INDEX_PARAM_KEYS = ['limit', 'page', 'search', 'order_direction', 'order_by']

const NUMERIC_INDEX_PARAM_KEYS = ['limit', 'page']

export function isIndexParamsKey (paramKey) {
  return INDEX_PARAM_KEYS.includes(paramKey)
}

const META_PARAM_KEYS = ['tab']

/**
 * This includes params such as metaQueryUrl and metaMethod never intended to be put into the URL.
 * This is the method which should be used by default when parsing URL params into the front-end entity.
 */
export function isMetaParamsKey (paramKey) {
  return META_PARAM_KEYS.includes(paramKey)
}

const initialState = paramsAdapter.getInitialState({})

export function segmentParams (params) {
  const filters = {}
  const indexes = {}
  const meta = {}
  for (const [paramKey, param] of Object.entries(params)) {
    if (INDEX_PARAM_KEYS.includes(paramKey)) {
      indexes[paramKey] = (NUMERIC_INDEX_PARAM_KEYS.includes(paramKey) && (typeof param === 'string' || param instanceof String)) ? parseInt(param) : param
    } else if (META_PARAM_KEYS.includes(paramKey)) {
      meta[paramKey] = param
    } else {
      filters[paramKey] = param
    }
  }
  return { filters, indexes, meta }
}

function anyFilterFieldsChanged (newFilters, oldFilters) {
  for (const [filterField, filterValue] of Object.entries(newFilters)) {
    if (oldFilters[filterField] !== filterValue) {
      return true
    }
  }
  return false
}

const paramsSlice = createSlice({
  name: 'params',
  initialState: initialState,
  reducers: {
    conditionallyUpdateParams: (state, action) => {
      const { queryId, filters, indexes, meta, preserveOtherFilters, dropOtherMetaFields, forceUpdate } = action.payload
      const oldParams = state.entities[queryId]
      if (!oldParams?.id) {
        console.info('Registering new params.', queryId, { filters, indexes, meta })
        paramsAdapter.setOne(
          state,
          {
            limit: null,
            search: null,
            order_direction: null,
            order_by: null,
            page: null,
            ...(indexes ?? {}),
            id: queryId,
            filters: filters ?? {},
            meta: meta ?? {}
          }
        )
        return
      }
      let resetPage = false
      let didUpdate = !!forceUpdate
      if (filters && (
        (!preserveOtherFilters && filters !== oldParams.filters && JSON.stringify(filters) !== JSON.stringify(oldParams.filters)) ||
        (preserveOtherFilters && anyFilterFieldsChanged(filters, oldParams.filters))
      )) {
        if (Object.keys(filters).length) {
          resetPage = true
        }
        oldParams.filters = preserveOtherFilters ? { ...oldParams.filters, ...filters } : filters
        didUpdate = true
      }
      if (indexes) {
        for (const [indexKey, indexValue] of Object.entries(indexes)) {
          if (!resetPage) {
            if (indexKey === 'search') {
              const newSearch = indexValue ?? ''
              const oldSearch = oldParams.search ?? ''
              if ((newSearch !== oldSearch) && !oldSearch.includes(newSearch)) {
                resetPage = true
              }
            } else if (indexKey === 'limit') {
              const newLimit = indexValue ?? 0
              const oldLimit = oldParams.limit ?? 0
              if (
                ((newLimit !== oldLimit) || ((oldParams.limit === null) && (indexValue !== null))) &&
                ((oldLimit < newLimit) || (newLimit === 0) || ((oldParams.limit === null) && (indexValue !== null)))
              ) {
                resetPage = true
              }
            }
          }

          if (oldParams[indexKey] !== indexValue) {
            oldParams[indexKey] = indexValue
            didUpdate = true
          }
        }
      }
      if (resetPage && ((indexes?.page ?? oldParams.page ?? 1) !== 1)) {
        oldParams.page = 1
        didUpdate = true
      }
      if (meta && meta !== oldParams.meta && JSON.stringify(meta) !== JSON.stringify(oldParams.meta)) {
        oldParams.meta = dropOtherMetaFields ? meta : { ...oldParams.meta, ...meta } // Note: no didUpdate
      }
      console.debug('Finished params update.', { didUpdate, resetPage })
    }
  }
})

const { conditionallyUpdateParams } = paramsSlice.actions

// export const {} = paramsSlice.actions

export default paramsSlice.reducer

export const { selectAll: selectAllParams, selectById: selectParamsById, selectIds: selectParamIds, selectEntities: selectParamsMap } =
  paramsAdapter.getSelectors(state => state.params)

export const selectParamsPage = createSelector( // TODO use makeUniqueSelectorInstance for per-Params memoization when used in many components.
  [selectParamsById],
  (params) => params?.page ?? null
)

export const selectParamsSearch = createSelector(
  [selectParamsById],
  (params) => params?.search ?? null
)

export const selectParamsLimit = createSelector(
  [selectParamsById],
  (params) => params?.limit ?? null
)

export const selectParamsOrderDirection = createSelector(
  [selectParamsById],
  (params) => params?.order_direction ?? null
)

export const selectParamsOrderBy = createSelector(
  [selectParamsById],
  (params) => params?.order_by ?? null
)

export const selectParamsIndexes = createSelector(
  [selectParamsPage, selectParamsSearch, selectParamsLimit, selectParamsOrderDirection, selectParamsOrderBy],
  (page, search, limit, order_direction, order_by) => ({ page, search, limit, order_direction, order_by }),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectParamsFilters = createSelector(
  [selectParamsById],
  (params) => params?.filters ?? null
)

export const selectAllParamsQueryData = createSelector(
  [selectParamsFilters, selectParamsIndexes],
  (filters, indexes) => ({ ...(filters ?? {}), ...indexes })
)

export const selectParamsFilterField = createSelector(
  [selectParamsFilters, (state, paramId, fieldName) => fieldName],
  (filters, fieldName) => filters?.[fieldName] ?? null
)

export const selectParamsLoaded = createSelector(
  [selectParamsById],
  (params) => !!params?.id
)

export const selectParamsLastResponseData = createSelector( // TODO refactor usages to api and remove
  [selectParamsById],
  (params) => null
)

export const selectParamsLastResponseErrorData = createSelector( // TODO refactor usages to api and remove
  [selectParamsById],
  (params) => null
)
