/* eslint react/prop-types: 0 */
import { ActionIcon, Button, Container, Divider, Fieldset, Grid, Group } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Conditions from './conditions/Conditions'
import Consequents from './consequents/Consequents'
import GeneralSettings from './general/GeneralSettings'
import { GoBackButton } from '../../../core/GoBackButton'

const smallGrid = { sm: 12, md: 12, lg: 2 }

const mediumGrid = { sm: 12, md: 12, lg: 4 }

const largeGrid = { sm: 12, md: 12, lg: 6 }

export default function DisplayTrigger ({ statusSet, integrations = {} }) {
  const { control } = useFormContext()
  const {
    fields: conditionFields,
    append: conditionAppend,
    remove: conditionRemove
  } = useFieldArray(
    { control: control, name: 'conditions', shouldUnregister: true })
  const {
    fields: consequentFields,
    append: consequentAppend,
    remove: consequentRemove
  } = useFieldArray({ control: control, name: 'consequents', shouldUnregister: true })

  return (
    <Container size='xl'>
      <Grid style={{ overflow: 'visible' }}>
        <Grid.Col span={smallGrid}>
          <GeneralSettings/>
        </Grid.Col>
        <Grid.Col span={largeGrid}>
          <Fieldset legend='Conditions'>
            {conditionFields.map((item, index) => (
              <Conditions
                statusSet={statusSet}
                key={item.id}
                index={index}
                control={control}
                value={item}
                remove={conditionRemove}
              />
            ))}
            <Divider my='xs' label='Add Condition' labelPosition='center'/>
            <ActionIcon onClick={() => { conditionAppend({ code: 'default' }) }}>
              <IconPlus/>
            </ActionIcon>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={mediumGrid}>
          <Fieldset legend='Consequents'>
            {consequentFields.map((item, index) => {
              return (
                <Consequents
                  statusSet={statusSet}
                  integrations={integrations}
                  key={item.id}
                  index={index}
                  control={control}
                  value={item}
                  remove={consequentRemove}
                />
              )
            })}
            <Divider my='xs' label='Add Consequent' labelPosition='center'/>
            <ActionIcon onClick={() => { consequentAppend({ code: 'default' }) }}>
              <IconPlus/>
            </ActionIcon>
          </Fieldset>
          <Group my='1rem'>
            <Button type='submit' color='teal.6'>Save</Button>
            <GoBackButton/>
          </Group>
        </Grid.Col>
      </Grid>
    </Container>
  )
}
