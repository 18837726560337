/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { ActionIcon, Box, Button, Flex, Group, Loader, Modal, NumberInput, Select, Switch, Text, Tooltip, UnstyledButton } from '@mantine/core'
import BulletChart, { getChartData, getZoneString } from '../../../../js/charts/BulletChart'
import { Chart } from 'chart.js'
import { IconBan, IconDeviceFloppy } from '@tabler/icons-react'
import BulletChartEditor from '../../../charts/BulletChartEditor'
import _ from 'lodash'
import { getProfile, updateProfileInfo, updateProfileInfos } from '../../../../js/api/profile_repository'
import styles from './ScoreApp.module.scss'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useWindowEvent } from '@mantine/hooks'
import { useFirstRender } from '../../../util/Hooks'
import { formatToOptions } from '../../../../js/util/DataUtil'
import WarningAlert from '../../../core/Alert/WarningAlert'
import Content from '../../../layout/Content'

export default function ProfileScoreApp () {
  const profile = JSON.parse(document.getElementById('profile-score-app-container').dataset.profile)
  const [infos, setInfos] = useState(JSON.parse(document.getElementById('profile-score-app-container').dataset.data))
  const [editing, setEditing] = useState(null)
  const [cloneId, setCloneId] = useState(profile.id.toString())
  const [isLoading, setIsLoading] = useState(false)
  const firstRender = useFirstRender()
  const findInfoIndex = id => _.findIndex(infos.map(info => info.profileInfo), info => info.id === id)

  const findInfoByDimensionId = id => _.find(infos.map(info => info.profileInfo), info => info.dimension.id === id)

  const profileList = formatToOptions(JSON.parse(document.getElementById('profile-score-app-container').dataset.profiles), { label: 'title' })

  const submitZones = (response, id = null) => {
    if (id === null) id = editing.id
    setInfos(prev => {
      const newData = [...prev]
      newData[findInfoIndex(id)] = {
        ...newData[findInfoIndex(id)],
        zones: response
      }
      return newData
    })
    setEditing(null)
  }

  const updateInfo = (profileInfo, callback = () => {}) => {
    setInfos(prev => {
      const newData = [...prev]
      newData[findInfoIndex(profileInfo.id)].profileInfo = profileInfo
      return newData
    })
    callback()
  }

  const saveAll = () => {
    setIsLoading(true)
    const formValues = infos.map(info => {
      return {
        id: info.profileInfo.id,
        importance: info.profileInfo.importance,
        visible: info.profileInfo.visible,
        zones: getZoneString(info.zones)
      }
    })
    updateProfileInfos(profile.id, { infos: formValues })
      .then(() => {
        showNotification({
          message: 'Successfully saved all dimensions',
          color: 'green'
        })
        const event = new Event('bviprofileinfo:saveall')
        window.dispatchEvent(event)
        setIsLoading(false)
      })
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          const messages = []

          for (const message of err.response.data.errors) {
            if (!_.includes(messages, message)) {
              showNotification({
                title: 'Validation error',
                message: message,
                color: 'red'
              })
              messages.push(message)
            }
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error when trying to update the profile',
            color: 'red'
          })
        }
        setIsLoading(false)
      })
  }

  const clone = () => {
    setIsLoading(true)
    getProfile(cloneId).then(profile => {
      setInfos(function () {
        const result = []

        for (const property in profile.dimensions.dimensions) {
          result.push({
            zones: profile.zones.zones[profile.dimensions.dimensions[property].id],
            profileInfo: {
              id: findInfoByDimensionId(profile.infos[profile.dimensions.dimensions[property].id].dimension.id).id,
              dimension: profile.infos[profile.dimensions.dimensions[property].id].dimension,
              importance: profile.infos[profile.dimensions.dimensions[property].id].importance,
              visible: profile.infos[profile.dimensions.dimensions[property].id].visible
            }
          })
        }

        return result
      })
      showNotification({
        message: `Successfully cloned profile ${profile.profile.title}. Remember to hit "Save all" to keep any changes.`,
        color: 'blue'
      })
      setIsLoading(false)
      const event = new Event('bviprofileinfo:saveall')
      window.dispatchEvent(event)
    },
    () => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (firstRender) return
    if (cloneId) {
      clone()
    }
  }, [cloneId])

  return (
    <Content>
      <Flex justify='center'>
        <WarningAlert>Changes made to the weights/zones will affect any cycles that are currently using this profile.</WarningAlert>
      </Flex>
      <Flex direction='row' align='flex-end' gap='xs'>
        <Button color='green' onClick={() => saveAll()}>Save all</Button>
        <Flex justify='flex-end' align='flex-end' className={[styles.cloneProfileWrapper]}>
          {isLoading && <Flex px='xs'><Loader /></Flex>}
            <Select
              defaultValue={profile.id.toString()}
              value={cloneId}
              onChange={setCloneId}
              label='Clone profile'
              placeholder='Select profile'
              data={profileList.sort((a, b) => a.label.localeCompare(b.label))}
              searchable
            />
        </Flex>
      </Flex>
      <Box className={styles.dimensionTable}>
        <Box className={styles.body}>
        <Box className={[styles.row, styles.header]}>
          <Box className={styles.cell}>Dimension</Box>
          <Box className={styles.cell}>Importance</Box>
          <Box className={styles.cell}>Visible</Box>
          <Box className={styles.cell}>Zones</Box>
          <Box className={styles.cell}></Box>
        </Box>
        {infos?.map((info) => {
          info.profileInfo.zones = info.zones
          return (
            <DimensionRow
              key={`row-${info.profileInfo.dimension.id}`}
              profile={profile}
              info={info.profileInfo}
              onChartClick={setEditing}
              onUpdate={updateInfo}
              onUpdateZones={submitZones}
              childInfos={infos.filter(childInfo => childInfo.profileInfo.dimension.parent?.id === info.profileInfo.dimension.id)}
            />
          )
        }
        ) ?? (<Text>No infos found.</Text>)}
        </Box>
      </Box>
      <Button color='green' onClick={() => saveAll()}>Save all</Button>
      <Modal opened={!!editing} onClose={() => setEditing(null)} title='Edit zones' size='50rem' keepMounted>
        <BulletChartEditor
          editing={editing}
          onSubmit={submitZones}
          onChartClick={setEditing} />
      </Modal>
    </Content>
  )
}

function DimensionRow ({
  profile,
  info,
  onChartClick,
  onUpdate,
  onUpdateZones
}) {
  const chartRef = useRef(null)

  const form = useForm({
    initialValues: {
      visible: info.visible === 1,
      importance: info.importance,
      zones: getZoneString(info.zones)
    }
  })

  const initChart = () => {
    if (info.zones.length === 0) return
    const existingChart = Chart.getChart(chartRef.current)
    existingChart?.destroy()
    return new BulletChart(
      chartRef.current,
      getChartData(info.zones, info.name ?? '') ?? {},
      false
    )
  }

  const save = (values) => {
    const formData = {
      ...info,
      visible: values.visible ? 1 : 0,
      importance: values.importance,
      zones: values.zones
    }

    onUpdate(formData, () => {
      updateProfileInfo(profile.id, info.id, { id: info.id, importance: values.importance, visible: values.visible ? 1 : 0, zones: values.zones })
        .then(() => {
          form.resetDirty()
          showNotification({
            message: 'Successfully saved dimension',
            color: 'green'
          })
        })
        .catch(err => {
          console.log(err)
          if (err.response?.data?.type === 'validation_error') {
            for (const message of err.response.data.errors) {
              showNotification({
                title: 'Validation error',
                message: message,
                color: 'red'
              })
            }
          } else {
            showNotification({
              title: 'Something went wrong',
              message: 'There was an error when trying to update the dimension',
              color: 'red'
            })
          }
        })
    })
  }

  const clear = () => {
    onUpdateZones([], info.id)
    Chart.getChart(chartRef.current).update()
  }

  useEffect(() => {
    initChart()
    form.setFieldValue('zones', getZoneString(info.zones))
    form.getInputProps('zones').onChange(getZoneString(info.zones))
  }, [info.zones])

  useEffect(() => {
    form.setFieldValue('importance', info.importance)
    form.getInputProps('importance').onChange(info.importance)

    form.setFieldValue('visible', info.visible === 1)
    form.getInputProps('visible').onChange(info.visible === 1)
  }, [info.importance, info.visible])

  useEffect(() => {
    initChart()
  }, [])

  useWindowEvent('bviprofileinfo:saveall', () => {
    form.resetDirty()
  })

  useWindowEvent('beforeunload', (event) => {
    if (form.isDirty()) {
      event.preventDefault()
      event.returnValue = ''
    }
  })

  return (
    <form onSubmit={form.onSubmit(values => save(values))} className={styles.row}>
      <Box className={styles.cell}>
        <Text fz='md' fw={info.dimension.parent ? 400 : 700}>{info.dimension.name}</Text>
      </Box>
      <Box className={[styles.cell, styles.importanceCell]}>
        <NumberInput
          max={10}
          min={0}
          onChange={(val) => form.setFieldValue('importance', val)}
          {...form.getInputProps('importance')}
          />
      </Box>
      <Box className={[styles.cell, styles.visibilityCell]}>
        <Switch
            onChange={(val) => form.setFieldValue('visible', val)}
            {...form.getInputProps('visible', { type: 'checkbox' })}
          />
      </Box>
      <Box className={styles.cell}>
        <input
        type='hidden'
        {...form.getInputProps('zones')}
        />
        <Tooltip label='Click to edit zones'>
          {info.zones.length > 0
            ? <UnstyledButton
                onClick={() => onChartClick(info)}
                >
                <canvas
                  ref={chartRef}
                  id={`bulletchart-${info.id}`}
                  width='400'
                  height='25'
                  style={{ borderRadius: '0.25rem' }}
                ></canvas>
              </UnstyledButton>
            : <Button onClick={() => onChartClick(info)}>Define Zones</Button>
          }
        </Tooltip>
      </Box>
      <Box className={styles.cell}>
        <Group className={[styles.rowActions]}>
          <ActionIcon
            type='submit'
            color='green'
            variant={form.isDirty() ? 'filled' : 'subtle'}
            disabled={!form.isDirty()}
          >
            <IconDeviceFloppy />
          </ActionIcon>
          <Tooltip label='Clear zones'>
            <ActionIcon
              color='gray'
              variant='subtle'
              onClick={() => clear()}
              disabled={info.zones.length === 0}
            >
              <IconBan />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Box>
    </form>
  )
}
