const blankDetailViewState = Object.freeze({
  currentApplicant: null,
  selectedApplicants: [],
  loadedCards: []
})

function createInitialDetailViewState () {
  return blankDetailViewState
}

const DetailViewStateUpdate = Object.freeze({
  UpdateCurrentApplicant: 'update-current-applicant',
  SelectApplicant: 'select-applicant',
  DeselectApplicant: 'deselect-applicant'
})

function detailViewReducer (state, action) {
  switch (action.type) {
    case DetailViewStateUpdate.UpdateCurrentApplicant:
      return { ...state, currentApplicant: action.payload && (state.currentApplicant === action.payload) ? null : action.payload }
    case DetailViewStateUpdate.SelectApplicant:
      return { ...state, selectedApplicants: [...state.selectedApplicants, action.payload] }
    case DetailViewStateUpdate.DeselectApplicant:
      return { ...state, selectedApplicants: state.selectedApplicants.filter(applicant => applicant.id !== action.payload.id) }
    default:
      return state
  }
}

export { createInitialDetailViewState, blankDetailViewState, DetailViewStateUpdate, detailViewReducer }
