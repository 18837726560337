import { Anchor, Button, Flex, Paper, SimpleGrid, Text } from '@mantine/core'
import React from 'react'
import PropTypes from 'prop-types'
import { LoginPageWrapper } from './LoginPage';
import UsernameInput from '../forms/UsernameInput'
import PasswordInput from '../forms/PasswordInput'
import PasswordLabel from '../forms/PasswordLabel'

const cardSpan = { xs: 1, md: 2 }
const passwordLabelProps = { w: '100%' }

export default function APLoginPage () {
  return (
    <LoginPageWrapper>
      <SimpleGrid p='lg' spacing='lg' cols={cardSpan} w='65vw'>
        <form method='post'>
        <LoginTypeCard>
          <Text fw='bold' ta='center' fz='md'>If you are taking an assessment from home, please log in below. If you don&apos;t have a password or have forgotten your password, click &quot;Reset password&quot;.</Text>
          <UsernameInput placeholder='Same email used to apply' />
          <PasswordInput labelProps={passwordLabelProps} label={<PasswordLabel link='Reset Password' href='/assessment-platform/recover-password'/>}/>
          <Button size='xl' type='submit' fullWidth>Sign In</Button>
        </LoginTypeCard>
        </form>
        <LoginTypeCard>
          <Flex direction='column' justify='space-between'>
            <Text fw='bold' ta='center' fz='lg'>If you are a Candidate or a Proctor On-Site, click the button below.</Text>
            <Button component={Anchor} size='xl' href='/assessment-platform/on-site/login'>I am On-Site</Button>
          </Flex>
        </LoginTypeCard>
      </SimpleGrid>
    </LoginPageWrapper>
  )
}

export function LoginTypeCard ({ children, ...props }) {
  return (
    <Paper p='xl' bg='gray.2' style={{ border: '1px solid lightgrey' }} component={SimpleGrid} {...props}>
      {children}
    </Paper>
  )
}

LoginTypeCard.propTypes = {
  children: PropTypes.node
}
