/* eslint react/prop-types: 0 */
import { rem, Tabs } from '@mantine/core';
import {
  IconActivityHeartbeat,
  IconUsers
} from '@tabler/icons-react';
import React from 'react';
import { PassActivityTimeline } from './PassActivityTimeline';

const activityIconStyle = { width: rem(12), height: rem(12) }
const noActivityFallback = []

export function PassActivityTabs ({ activityTarget, activityTargetType, adminTargetType = null, isAdmin = false }) {
  return (
    <Tabs defaultValue="activity" allowTabDeactivation>
      <Tabs.List>
        <Tabs.Tab value="activity" leftSection={<IconActivityHeartbeat style={activityIconStyle} />}>
          {activityTargetType} Activity
        </Tabs.Tab>

        {!!isAdmin && (
          <Tabs.Tab value="admin" leftSection={<IconUsers style={activityIconStyle} />}>
            Admin Activity
          </Tabs.Tab>
        )}
      </Tabs.List>

      <Tabs.Panel value="activity">
        <PassActivityTimeline passActivity={activityTarget.activity ?? noActivityFallback} isAdmin={isAdmin} isAdminTimeline={false} />
      </Tabs.Panel>

      {!!isAdmin && (
        <Tabs.Panel value="admin">
          <PassActivityTimeline passActivity={activityTarget.admin_activity ?? noActivityFallback} adminTargetType={adminTargetType} isAdmin={isAdmin} isAdminTimeline={true} />
        </Tabs.Panel>
      )}
    </Tabs>
  )
}
