/* eslint react/prop-types: 0 */
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import ContactConsequent from './ContactConsequent'
import UpdateStatusConsequent from './UpdateStatusConsequent'
import Error from '../../../../forms/Error'
import NotifySubscribersConsequent from './NotifySubscribersConsequent'
import { ActionIcon, Divider } from '@mantine/core'
import { IconMinus } from '@tabler/icons-react'

export default function Consequents ({ statusSet, index, remove, integrations = {} }) {
  const { register } = useFormContext();
  const consequents = useWatch({ name: 'consequents' })
  const code = useWatch({ name: `consequents.${index}.code` })

  // Render success factor conditionally
  return (
    <>
      <select
        {...register(`consequents.${index}.code`, {
          required: 'Select a consequent',
          validate: value => value !== 'default'
        })}
      >
        <option value='default'>Choose a consequent</option>
        <option
          value='UPDATE_APPLICANT_STATUS'
          hidden={consequents?.filter(e => e.code === 'UPDATE_APPLICANT_STATUS').length > 0}
        >
          Update applicant&apos;s status
        </option>

        <option
          value='CONTACT_APPLICANT'
          hidden={consequents?.filter(e => e.code === 'CONTACT_APPLICANT').length > 0}
        >
          Contact applicant
        </option>

        <option
          value='CHANGE_APPLICANT_ASSESSMENT_STATUS_IN_ADP'
          hidden={!integrations.adp || consequents?.filter(e => e.code === 'CHANGE_APPLICANT_ASSESSMENT_STATUS_IN_ADP').length > 0}
        >
          Change an applicant&apos;s assessment status in ADP
        </option>
        <option
          value='SEND_APPLICANT_TO_SUCCESS_FACTOR'
          hidden={!integrations.successfactor || consequents?.filter(e => e.code === 'SEND_APPLICANT_TO_SUCCESS_FACTOR').length > 0}
        >
          Send applicant to SuccessFactor
        </option>
        <option
          value="NOTIFY_SUBSCRIBERS"
          hidden={consequents?.filter(e => e.code === 'NOTIFY_SUBSCRIBERS').length > 0}>
          Send subscribed PMs a notification
        </option>
      </select>
      {code === 'CONTACT_APPLICANT' && <ContactConsequent /> }
      {code === 'UPDATE_APPLICANT_STATUS' && <UpdateStatusConsequent statusSet={statusSet}/>}
      {code === 'NOTIFY_SUBSCRIBERS' && <NotifySubscribersConsequent index={index}/>}

      <Error name={`consequents.${index}.code`} customMessage='Select a consequent' />
      {(consequents?.length > 0 && code !== 'CONTACT_APPLICANT') && <ActionIcon onClick={() => remove(index)}><IconMinus /></ActionIcon>}
      {index !== (consequents?.length - 1) && <Divider/>}
    </>
  )
}
