/* eslint react/prop-types: 0 */
import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';

export function CardQueryError () {
  return (
    <Alert variant='light' title='Query Error' icon={<IconInfoCircle />}>
      Oh no! There was a query error. Try refreshing the page while we work on a way to fix it!
    </Alert>
  )
}
