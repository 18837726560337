import { SimpleGrid } from '@mantine/core'
import PropTypes from 'prop-types'
import React from 'react'
import CreateButtons from './CreateButtons'
import UserTable from './UserTable'

export default function UserHome ({ access }) {
  return (
    <SimpleGrid>
      <CreateButtons/>
      <UserTable access={access}/>
    </SimpleGrid>
  )
}

UserHome.propTypes = {
  access: PropTypes.object
}
