import React from 'react'
import {
  getCycleApplicants,
  getCycleBatteryLastSubmissions,
  getCycleStatuses,
  getCycleTemplates,
  getCycleTextTemplates
} from '../../../js/api/cycle_repository'
import ContactMultipleApp from './ContactMultipleApp'
import CONSTANT from '../../../constants'
import { useParams } from 'react-router-dom'
import { useGetCycleQuery } from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'

const filterCategories = (template) => {
  return template.category?.tag !== 'boost-invite' && template.category?.tag !== 'prescreen-completed'
}

export default function ContactCycleApp () {
  const max = CONSTANT.MAX_CONTACT_APPLICANTS
  const { cycleId: id } = useParams()
  const { data: cycle, error, isLoading } = useGetCycleQuery(id)

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <ContactMultipleApp
      getTemplates={() => getCycleTemplates(id, filterCategories)}
      getTextTemplates={() => getCycleTextTemplates(id)}
      getSubmissions={batteryId => getCycleBatteryLastSubmissions(id, batteryId)}
      getBatteries={() => Promise.resolve({ data: cycle.cycle_batteries.filter(linkedBattery => linkedBattery.active).map(linkedBattery => linkedBattery.cycle) })}
      getApplicants={(status, courtesyLetter) => getCycleApplicants(id, status, courtesyLetter)}
      onSuccessRedirect={`/cycles/${id}/results`}
      getStatuses={() => getCycleStatuses(id).then(response => response.items)}
      max={max}
      cycleId={id}
    />
  )
}
