import { createSelector, createSlice } from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux'

const defaultCardState = (id) => {
  return {
    applicantId: id
  }
}

const initialState = {
  openedCards: {}
}

export const detailViewSlice = createSlice({
  name: 'detailView',
  initialState: initialState,
  reducers: {
    openCard: (state, action) => {
      const { id } = action.payload
      state = state.openedCards[`applicant-card-${id}`] = defaultCardState(id)
    },
    closeCard: (state, action) => {
      const { id } = action.payload
      delete state.openedCards[id]
    }
  }
})

const openedCards = (state) => state.detailView.openedCards

export const selectCard = createSelector(
  [openedCards, (state, id) => id],
  (opened, id) => {
    return opened[id]
  }
)

export const selectAllOpenedKeys = createSelector(
  [openedCards],
  (opened) => {
    return Object.keys(opened)
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
)

export const { openCard, closeCard } = detailViewSlice.actions

export default detailViewSlice.reducer
