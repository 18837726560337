/* eslint react/prop-types: 0 */
import { LogicOperator } from '../../../../../js/generated/enums/LogicOperator';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { shouldValidateLogicDataQuestionAnswersForQuestionType, stripContentTags } from './util';
import { Autocomplete, Center, Group, Paper, Select, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { Handle, Position } from 'reactflow';
import { LogicFeature } from '../../../../../js/generated/enums/LogicFeature';

const operatorTypeOptions = Object.freeze(Object.keys(LogicOperator).map(element => {
  return Object.freeze({ label: element.split(/(?=[A-Z])/).join(' '), value: LogicOperator[element] })
}))

const LogicNode = memo(function LogicNode ({ id, data, isConnectable }) {
  const { logic, questionOptions, questionData } = data
  const [questionIdState, setQuestionIdState] = useState(logic.questionId?.toString() ?? null)
  const [operatorState, setOperatorState] = useState(logic.operator)
  const [valueState, setValueState] = useState(logic.value)

  const cachedLabel = useMemo(() => {
    if (questionIdState || questionIdState === '0') {
      return stripContentTags(questionData.get(parseInt(questionIdState))?.content ?? 'Text for question ' + questionIdState + ' missing.')
    }
    return 'Select Question'
  }, [questionIdState, questionData])

  const cachedAnswerOptions = useMemo(() => {
    if (questionIdState || questionIdState === '0') {
      const selectedQuestion = questionData.get(parseInt(questionIdState))
      if (shouldValidateLogicDataQuestionAnswersForQuestionType(selectedQuestion?.type)) {
        return [...(new Set([...selectedQuestion.answers.values()].map(answer => answer.content ?? '')))]
      }
    }
    return null
  }, [questionIdState, questionData])

  const validQuestionChoice = (!!questionIdState || (questionIdState === '0')) && questionData?.has(parseInt(questionIdState))

  return (
    <Paper classNames={{ root: 'logic-node' }}>
      <Stack>
        {validQuestionChoice
          ? (<Text size='xl' fz='32px' lh='32px'>{cachedLabel}</Text>)
          : (
            <Select
              placeholder='Select Question'
              data={questionOptions}
              value={questionIdState}
              onChange={(value) => {
                const resetValue = (!!questionIdState || (questionIdState === '0'))
                if (value || (value === '0')) {
                  logic.questionId = parseInt(value)
                } else {
                  logic.questionId = null
                }
                if (resetValue) {
                  logic.value = ''
                  setValueState('')
                }
                setQuestionIdState(value)
              }}
              required={true}
              classNames={{ root: 'nodrag' }}
            />
            )}
        <Group justify='center' wrap='nowrap' grow>
          <Select
            placeholder='Select Comparison'
            data={operatorTypeOptions}
            value={operatorState}
            onChange={(value) => {
              logic.operator = value
              setOperatorState(value)
            }}
            required={true}
            maw='15vw'
            classNames={{ root: 'nodrag' }}
          />
          {cachedAnswerOptions === null
            ? (
              <TextInput
                placeholder='Blank Value'
                value={valueState}
                onChange={(event) => {
                  const newValue = event.currentTarget.value
                  logic.value = newValue
                  setValueState(newValue)
                }}
                maw='15vw'
                classNames={{ root: 'nodrag' }}
              />
              )
            : (
              <Autocomplete
                data={cachedAnswerOptions}
                value={valueState}
                onChange={(newValue) => {
                  logic.value = newValue
                  setValueState(newValue)
                }}
                maw='15vw'
                placeholder='Blank Value'
                required={true}
                classNames={{ root: 'nodrag' }}
              />
              )}
        </Group>
      </Stack>
      <Handle type="target" position={Position.Left} id='a' isConnectable={isConnectable} />
      <Handle type="source" position={Position.Left} id='c' isConnectable={isConnectable} style={{ visibility: 'hidden' }} />
      <Handle type="source" position={Position.Right} id='b' isConnectable={isConnectable} />
    </Paper>
  )
})

const featureTypeOptions = Object.freeze(Object.keys(LogicFeature).map(element => {
  return Object.freeze({ label: element.split(/(?=[A-Z])/).join(' '), value: LogicFeature[element] })
}))

const FeatureNode = memo(function FeatureNode ({ data, isConnectable }) {
  const { feature } = data
  const [featureState, setFeatureState] = useState(feature)
  return (
    <Paper classNames={{ root: 'feature-node' }}>
      <Center>
        {feature
          ? (<Text size='xl' fz='32px' lh='32px'>{feature} if:</Text>)
          : (
            <Select
              placeholder='Select Action'
              data={featureTypeOptions}
              value={featureState}
              onChange={(value) => { data.feature = value; setFeatureState(value) }}
              required={true}
              maw='15vw'
              classNames={{ root: 'nodrag' }}
            />
            )}
      </Center>
      <Handle type="target" position={Position.Left} id='a' isConnectable={isConnectable} />
      <Handle type="source" position={Position.Right} id='b' isConnectable={isConnectable} />
    </Paper>
  )
})

const TargetQuestionNode = memo(function TargetQuestionNode ({ data, isConnectable }) {
  const { label, startedWithData } = data
  const [tooltipOpen, setTooltipOpen] = useState(!startedWithData)
  const hideTooltip = !!startedWithData

  useEffect(() => {
    if (!hideTooltip) {
      const timeoutId = window.setTimeout(() => { setTooltipOpen(false) }, 1500)

      return () => window.clearTimeout(timeoutId)
    }
  }, [hideTooltip])

  return (
    <Paper classNames={{ root: 'question-node' }}>
      <Center><Text size='xl' fz='32px' lh='32px'>{label}</Text></Center>
      {!hideTooltip && <Tooltip label='Drag me to get started!' opened={tooltipOpen} withArrow><Handle type="source" position={Position.Right} id='b' isConnectable={isConnectable} /></Tooltip>}
      {hideTooltip && <Handle type="source" position={Position.Right} id='b' isConnectable={isConnectable} />}
    </Paper>
  )
})

export const nodeComponentTypes = { logic: LogicNode, feature: FeatureNode, target: TargetQuestionNode }
