/* eslint react/prop-types: 0 */
import React, { memo, useMemo } from 'react';
import { Space, Text, Timeline, Title } from '@mantine/core';
import { combineApplicantName, renderDateFromSource } from '../formatUtil';
import { getActivityName, getBulletIconForActivity } from './util';

export const PassActivityTimeline = memo(function PassActivityTimeline ({ passActivity, adminTargetType = null, isAdmin = false, isAdminTimeline = false }) {
  const filteredActivity = useMemo(() => {
    if (!isAdminTimeline || !passActivity?.length || (adminTargetType === null)) {
      return passActivity
    }
    return passActivity.filter(activity => activity.target === adminTargetType)
  }, [passActivity, adminTargetType, isAdminTimeline])
  console.debug('Pass activity timeline updating', { filteredActivity, isAdminTimeline, adminTargetType, passActivity })
  return (
    <>
      {!filteredActivity?.length && (
        <>
          <Space h='lg' />
          <Title order={4}>
            No {isAdminTimeline ? 'Admin ' : ''}Activity
          </Title>
        </>
      )}
      {!!filteredActivity?.length && (
        <>
          <Space h='lg' />
          <Timeline active={filteredActivity.length - 1} bulletSize={24} lineWidth={filteredActivity.length - 1 ? 2 : 0}>
            {filteredActivity.map((activity) => (
              <Timeline.Item
                key={activity.id}
                bullet={getBulletIconForActivity(activity.type, isAdminTimeline)}
                title={getActivityName(activity.type, isAdminTimeline)}
              >
                <PassActivityTimelineItem activity={activity} isAdmin={isAdmin} isAdminTimeline={isAdminTimeline} />
              </Timeline.Item>
            ))}
          </Timeline>
        </>
      )}
    </>
  )
})

const PassActivityTimelineItem = memo(function PassActivityTimelineItem ({ activity, isAdmin = false, isAdminTimeline = false }) {
  return (
    <>
      {isAdminTimeline ? <AdminActivityItem activity={activity} isAdmin={isAdmin} /> : <PassActivityItem activity={activity} isAdmin={isAdmin} />}
    </>
  )
})

const PassActivityItem = memo(function PassActivityItem ({ activity, isAdmin }) {
  return (
    <>
      <Text size="xs" mt={4}>{activity.stage?.assessment?.name ? `${activity.stage.assessment.name} @ ` : ''}{renderDateFromSource(activity.created_at, null, true)}{isAdmin && activity.ip_address ? ` [${activity.ip_address}]` : ''}</Text>
    </>
  )
})

const AdminActivityItem = memo(function AdminActivityItem ({ activity, isAdmin }) { // TODO [cycle pass full features] associated stage progress/stage progress replacement detail in expanded summary
  const performedByName = activity.system_action ? 'System Action' : (combineApplicantName(activity.admin_account) || `Admin Account${activity.admin_account ? ' Name' : ''} Missing`)
  return (
    <>
      <Text size="xs" mt={4}>{performedByName} @ {renderDateFromSource(activity.created_at, null, true)}</Text>
    </>
  )
})
