/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useMemo, useState } from 'react';
import { generatePath, useParams, Link } from 'react-router-dom';
import {
  Alert,
  Group,
  Space,
  Text,
  Box,
  Timeline,
  Title,
  Collapse,
  Button,
  Grid,
  Badge
} from '@mantine/core';
import { IconInfoCircle, IconCheck, IconX, IconHourglassHigh } from '@tabler/icons-react';
import { StageProgressesTable } from '../progresses/StageProgressesTable';
import dayjs from 'dayjs';
import { isClosed } from '../../../../../js/generated/enums/StageProgressStatus';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { useIsAdmin } from '../CyclesHooks';
import { useCyclePassDetail } from './CyclePassHooks';
import { ApplicantSummaryCard } from '../summaries/ApplicantSummaryCard';
import { EmailSummaryCard } from '../summaries/EmailSummaryCard';
import { CycleSummaryCard } from '../summaries/CycleSummaryCard';
import { LockableColor, LockableIcon } from '../summaries/LockableIcon';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { stageProgressStatusIdToName } from '../progresses/util';
import { renderDateFromSource } from '../formatUtil';
import { useParsedPassActivity } from '../CycleInviteHooks';
import { useCyclePassLockMutation, useSyncCyclePassScoresMutation } from './CyclePassesApi';
import { notifications } from '@mantine/notifications';
import { useCycle } from '../../CycleHooks';
import { AdminPassActivityTarget } from '../../../../../js/generated/enums/AdminPassActivityTarget';
import { ToggleLockButton } from '../nav/ToggleLockButton';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';

/**
 * Loads and displays CyclePass details such as status, a table of related stage progresses, invites table link,
 * the related applicant detail link / missing data placeholder, the related email detail link / missing data placeholder,
 * and the related cycle link.
 *
 * TODO [timeout forgiveness] button to offer limited retake?
 * TODO [cycle pass full features] provide option for mass-editing related cycle pass fields in invites, changing applicant/email, etc. -Regenerate uuids and lock for any modified phase invites (without an open invite)
 */
export function CyclePassDetail () {
  const { cycleId, passId } = useParams()
  const [cyclePass, passQuerying] = useCyclePassDetail(passId, cycleId, !passId)
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()
  const querying = !!(passQuerying || adminLoading || cycleQuerying)
  return (
    <>
      {!!querying && <CardLoadingPlaceholder />}
      {!querying && !cyclePass && <CardQueryError />}
      {!querying && !!cyclePass && <CyclePassCard passId={passId} cyclePass={cyclePass} cycleId={cycleId} cycle={cycle} isAdmin={isAdmin} />}
      {!querying && !!cyclePass && <CyclePassProgressesTable cycleId={cycleId} cyclePassId={cyclePass.id} />}
    </>
  )
}

const cardColSpan = { base: 12, md: 6, lg: 3 }
const cardGridGutter = { base: 'xs', sm: 'md', md: 'xl', lg: 'xxl' }

function CyclePassCard ({ passId, cyclePass, cycleId, cycle, isAdmin = false }) {
  // TODO toggle lock mutation for cycle passes
  // TODO [timeout forgiveness] button to offer limited retake?
  console.debug('Cycle pass card updating', { cyclePass, isAdmin })
  const invitesLink = !(cycleId && passId) ? '/' : generatePath(CyclePassRoute.CyclePassInvitesList, { cycleId, passId })
  const emailEditsLink = cycleId && passId ? generatePath(CyclePassRoute.CyclePassEditEmail, { cycleId, passId }) : null

  return (
    <>
      <Title>Cycle Passport #{passId ?? 'Id Missing'} Detail</Title>
      <Space h='xl' />
      <Box>
        <Group>
          <LockableIcon item={cyclePass} />
          <Button variant='subtle' size='sm' component={Link} to={invitesLink}>Manage Invites</Button>
          {!!isAdmin && <SyncCyclePassScoresButton cycleId={cycleId} passId={passId} passActive={cyclePass.active} isAdmin={isAdmin} />}
          {!!isAdmin && <TogglePassLockButton cycleId={cycleId} passId={passId} locked={cyclePass.locked} />}
          {!!isAdmin && !!emailEditsLink && <Button variant='subtle' size='sm' component={Link} to={emailEditsLink}>Edit</Button>}
        </Group>
        <Space h='xl' />
        {cyclePass.stage_progresses?.length ? <CyclePassProgressSummary cyclePass={cyclePass} isAdmin={isAdmin} /> : <CyclePassNoProgressSummary cyclePass={cyclePass} />}
        <Space h='xl' />
        <Grid justify='flex-start' align='stretch' gutter={cardGridGutter} grow>
          {!!cyclePass.applicant && <Grid.Col span={cardColSpan}><ApplicantSummaryCard applicant={cyclePass.applicant} isAdmin={isAdmin} /></Grid.Col>}
          {!!cyclePass.email && <Grid.Col span={cardColSpan}><EmailSummaryCard email={cyclePass.email} isAdmin={isAdmin} /></Grid.Col>}
          {!!cycle && <Grid.Col span={cardColSpan}><CycleSummaryCard cycle={cycle} isAdmin={isAdmin} /></Grid.Col>}
        </Grid>
        <Space h='xl' />
        <PassActivityTabs activityTarget={cyclePass} activityTargetType='Passport' adminTargetType={AdminPassActivityTarget.CyclePass} isAdmin={isAdmin} />
      </Box>
    </>
  )
}

export const SyncCyclePassScoresButton = memo(function SyncCyclePassScoresButton ({ cycleId, passId = null, passActive = false, isAdmin = false }) {
  const [sendData, { isLoading: processing }] = useSyncCyclePassScoresMutation()

  const onClick = useCallback(() => {
    sendData({ cycleId, passId })
      .unwrap()
      .then(response => {
        console.debug('Got sync cycle pass scores response', response, cycleId, passId)
        if (response?.success) {
          notifications.show(
            {
              color: 'green',
              title: 'Score Success',
              message: 'Passport scores synced!' + (response.syncCount ? ` Count: ${response.syncCount}` : ''),
              autoClose: 4000
            }
          )
        }
        if (response?.errorId) {
          console.error('Sync cycle pass scores expected error response', response.errorId, cycleId, passId)
          notifications.show(
            {
              color: 'red',
              title: 'Score Error',
              message: `There was an error syncing a passport's scores, which stopped further syncing. Passport ID: #${response.errorId}`,
              autoClose: 8000
            }
          )
        }
      })
      .catch(e => {
        console.error('Sync cycle pass scores error response', e)
        notifications.show(
          {
            color: 'red',
            title: 'Score Error',
            message: 'There was an unexpected error syncing passport scores!',
            autoClose: 8000
          }
        )
      })
  }, [passId, cycleId, sendData])

  return (
    <>
      {!!isAdmin && <Button variant='subtle' size='sm' onClick={onClick} disabled={passActive} loading={processing}>Sync Scores</Button>}
    </>
  )
})

function TogglePassLockButton ({ cycleId, passId, locked = true }) {
  const [toggleLock, { isLoading: toggleLockProcessing }] = useCyclePassLockMutation()
  return (
    <ToggleLockButton
      toggleLock={toggleLock}
      toggleLockProcessing={toggleLockProcessing}
      cycleId={cycleId}
      itemId={passId}
      itemIdName='passId'
      locked={locked}
    />
  )
}

const CyclePassProgressSummary = memo(function CyclePassProgressSummary ({ cyclePass, isAdmin = false }) {
  const progressMap = useMemo(() => {
    const parsedMap = new Map()
    for (const progress of (cyclePass.stage_progresses ?? [])) {
      parsedMap.set(progress.id, progress)
    }
    return parsedMap
  }, [cyclePass])
  const indexToFinalProgressMap = useMemo(() => {
    const parsedMap = new Map()
    for (const progress of (cyclePass.stage_progresses ?? [])) {
      if (!progress.forward_to && progress.stage) {
        if (parsedMap.has(progress.stage.index)) {
          console.error('Index to final progress map already has entry for stage index', { progress, parsedMap })
        }
        parsedMap.set(progress.stage.index, progress)
      }
    }
    return parsedMap
  }, [cyclePass])

  const sortedProgresses = useMemo(() => {
    const sortedIndexes = [...indexToFinalProgressMap.keys()].sort((a, b) => a - b)
    return sortedIndexes.map(stageIndex => indexToFinalProgressMap.get(stageIndex))
  }, [indexToFinalProgressMap])

  console.debug('CyclePassProgressSummary updating', { sortedProgresses, cyclePass })
  return (
    <Timeline active={sortedProgresses.length - 1} bulletSize={24} lineWidth={sortedProgresses.length - 1 ? 2 : 0}>
      {sortedProgresses.map((progress) => (
        <Timeline.Item
          key={progress.id}
          bullet={isClosed(progress.status) ? <IconCheck size={12} strokeWidth={4} /> : (progress.active ? <IconHourglassHigh size={12} /> : <IconX size={12} />)}
          title={progress.stage.assessment?.name ?? 'Assessment Missing'}
        >
          <CyclePassProgressSummaryTimelineItem progress={progress} progressMap={progressMap} isAdmin={isAdmin} />
        </Timeline.Item>
      ))}
    </Timeline>
  )
})

function CyclePassProgressSummaryTimelineItem ({ progress, progressMap, isAdmin = false }) {
  const [open, setOpen] = useState(false)
  const [firstStart, lastActivity, lastSubmit] = useParsedPassActivity(progress)
  return (
    <>
      <Button variant='subtle' size='sm' onClick={() => setOpen((prev) => !prev)}>{open ? 'Hide Stage Details' : 'Stage Details'}</Button>
      <Collapse in={open}>
        <Space h='xs' />
        {!!lastActivity && (
          <Text c="dimmed" size="sm">
            {firstStart ? `Started: ${renderDateFromSource(firstStart.created_at, null, true)} ` : ''}{lastSubmit ? `Submitted: ${renderDateFromSource(lastSubmit.created_at, null, true)} ` : ''} Last Seen: {renderDateFromSource(lastActivity.created_at, null, true)}
          </Text>
        )}
        {!lastActivity && (
          <Text c="dimmed" size="sm">
            No activity for stage
          </Text>
        )}
        {!!progress.forwarded_from?.length && <ProgressForwardedFromSummary progress={progress} progressMap={progressMap} isAdmin={isAdmin} />}
        <Space h='xs' />
        <PassActivityTabs activityTarget={progress} activityTargetType='Stage' adminTargetType={AdminPassActivityTarget.StageProgress} isAdmin={isAdmin} />
        <Space h='xl' />
      </Collapse>
      <Space h='xs' />
      <Group>
        <Badge color={isClosed(progress.status) ? LockableColor.Locked : LockableColor.Active} variant='light'>{stageProgressStatusIdToName[progress.status]}</Badge>
        <Text size="xs" mt={4}>Updated {renderDateFromSource(progress.updated_at, null, true)}</Text>
      </Group>
    </>
  )
}

function ProgressForwardedFromSummary ({ progress, progressMap, isAdmin = false }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Space h='xs' />
      <Button variant='subtle' size='sm' onClick={() => setOpen((prev) => !prev)}>{open ? 'Hide Replaced Stage Details' : 'Replaced Stage Details'}</Button>
      <Collapse in={open}>
        <Space h='xs' />
        {!progress.forwarded_from.length && <Text>No replaced stage progresses found.</Text>}
        {(progress.forwarded_from.length === 1) && <CyclePassProgressSummaryTimelineItem progress={progressMap.get(progress.forwarded_from[0].replaced_progress)} progressMap={progressMap} isAdmin={isAdmin}/>}
        {(progress.forwarded_from.length > 1) && <ProgressForwardedFromTimeline progress={progress} progressMap={progressMap} isAdmin={isAdmin} />}
      </Collapse>
    </>
  )
}

const ProgressForwardedFromTimeline = memo(function ProgressForwardedFromTimeline ({ progress, progressMap, isAdmin = false }) {
  return (
    <Timeline active={progress.forwarded_from.length - 1} bulletSize={24} lineWidth={progress.forwarded_from.length - 1 ? 2 : 0}>
      {progress.forwarded_from.map((progressReplacement) => {
        const replacedProgress = progressMap.get(progressReplacement.replaced_progress)
        return (
          <Timeline.Item
            key={replacedProgress.id}
            bullet={isClosed(replacedProgress.status) ? <IconCheck size={12} strokeWidth={4}/> : (replacedProgress.active ? <IconHourglassHigh size={12}/> : <IconX size={12}/>)}
            title={replacedProgress.stage.assessment?.name ?? 'Assessment Missing'}
          >
            <CyclePassProgressSummaryTimelineItem progress={replacedProgress} progressMap={progressMap} isAdmin={isAdmin}/>
          </Timeline.Item>
        )
      })}
    </Timeline>
  )
})

function CyclePassNoProgressSummary ({ cyclePass }) {
  const everSeen = cyclePass.activity?.last_seen_at
  return (
    <Alert variant='light' title='No Assessment Data' icon={<IconInfoCircle />}>
      {everSeen ? `This cycle pass was last used at ${dayjs(everSeen).format('MM-DD-YYYY')}. It has not begun an assessment.` : 'This cycle pass has never been used!'}
    </Alert>
  )
}

function CyclePassProgressesTable ({ cycleId, cyclePassId }) {
  console.debug('Cycle pass progresses table updating', cyclePassId)
  const [open, setOpen] = useState(false)
  const [showButton, setShowButton] = useState(!open)
  return (
    <>
      <Space h='xl' />
      <Collapse in={open} onTransitionEnd={() => setShowButton(!open)}>
        <StageProgressesTable cycleId={cycleId} passId={cyclePassId} namespace='passes-progresses' />
      </Collapse>
      {!!showButton && <Button onClick={() => setOpen(true)}>View Progress Table</Button>}
    </>
  )
}
