import React from 'react'
import { Text, Button, Space, Flex, Container, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import PropTypes from 'prop-types';

export default function ConfirmationModal (props) {
  const [opened, { open, close }] = useDisclosure(false);
  const submit = () => {
    props.onSubmit()
    close()
  }

  return (
    <>
      <Button color={props.openButtonColor} onClick={open} disabled={props.openButtonDisabled}>
        {props.openButtonContent}
      </Button>
      <Modal opened={opened} onClose={close} withCloseButton={false} padding={0}>
        <Flex mih={65} gap="md" bg={props.headerBgColor} justify="flex-start" align="center" direction="row" wrap="wrap">
          <Space w="xs" />
          {props.headerIcon}
          <Text size={'xl'} color={'white'}>{props.header}</Text>
        </Flex>

        <Container fluid>
          <Space h="lg" />
          <Space w="sm" />
          <Text size={'sm'}>
            {props.contentText}
          </Text>
          {props.contentElement}
        </Container>

        <Flex mih={70} gap="md" justify="flex-end" align="center" direction="row" wrap="wrap">
          <Button onClick={close} color={'gray'} >Cancel</Button>
          <Button onClick={submit} color={props.submitButtonColor} >Submit</Button>
          <Space w="xs" />
        </Flex>
      </Modal>
    </>
  )
}

ConfirmationModal.propTypes = {
  openButtonContent: PropTypes.string,
  openButtonColor: PropTypes.string,
  openButtonDisabled: PropTypes.bool,
  headerIcon: PropTypes.element,
  header: PropTypes.string,
  headerBgColor: PropTypes.string,
  contentText: PropTypes.string,
  contentElement: PropTypes.element,
  submitButtonColor: PropTypes.string,
  onSubmit: PropTypes.func
}
