/* eslint react/prop-types: 0 */
import React from 'react'
import { TextInput } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'

/**
 * Component that displays an username input.
 * @param {object} props Any valid Mantine TextInput prop.
 * @returns {JSX.Element} A decorated TextInput with defaults set.
 */
export default function UsernameInput ({
  ...props
}) {
  return <TextInput
    name='username'
    size='lg'
    label='Email Address'
    leftSection={<IconMail />}
    autoFocus
    required
    withAsterisk={false}
    {...props}
  />
}
