import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'
import { previousQuestionAnswered, fetchPublishedAssessmentQuestions } from './assessmentsSlice';
import { submitPublishedAssessmentPage } from '../../js/api/published_assessment_repository';
import { getCurrentSeconds, QueryState } from './util';
import { selectRespondentAnswersByPageId, submitAnswer } from './respondentAnswersSlice';
import {
  AssessmentResponse
} from '../../js/generated/enums/AssessmentResponse';

/**
 * @type {EntityAdapter<{PublishedAssessmentPage}, string>}
 */
const pagesAdapter = createEntityAdapter()

const initialState = pagesAdapter.getInitialState({
  status: QueryState.Idle,
  error: null,
  lastResponseType: null,
  lastResponseData: null
})

export const submitPages = createAsyncThunk('pages/submitPages', async (submitParams, { getState, rejectWithValue }) => {
  const { next, assessmentId, recordResponse } = submitParams
  console.info('Submitting pages.', next, assessmentId, recordResponse)
  const state = getState()
  const pages = selectPageIdsByAssessmentId(state, assessmentId)
  console.debug('Submit state', state, pages)
  const assessment = state.assessments.entities[assessmentId] ?? null
  const responses = []
  if (recordResponse) {
    let pageCounter = 0
    for (const pageId of pages) {
      pageCounter += 1
      const isLastPage = pageCounter === pages.length
      const page = state.pages.entities[pageId]
      if (!page) {
        console.error('No page found for page id in state.', state, pages, pageId, page)
        continue
      }
      const respondentAnswers = selectRespondentAnswersByPageId(state, pageId)
      const formattedResponse = { questionId: {}, comments: {}, answersText: {}, selectedAnswers: {}, skippedOrHidden: {} }
      if (assessment?.timeLimit) {
        const startTime = assessment.startTime
        const currentTime = getCurrentSeconds()
        const remainingTime = assessment.timeLimit - (currentTime - startTime)
        formattedResponse.time = Math.max(Math.floor(remainingTime), isLastPage ? 0 : 1)
        console.debug('Submitting page with remaining time. actual|sent', remainingTime, formattedResponse.time, startTime, currentTime, assessment.timeLimit)
      }
      const auth = assessment?.auth ?? {}
      for (const answer of respondentAnswers) {
        const question = state.questions.entities[answer.id]
        if (answer.answerId) {
          formattedResponse.questionId[question.id] = (answer.skipped || question?.hideFromLogic) ? null : answer.answerId
        }
        if (answer.additionalAnswer) {
          formattedResponse.answersText[question.id] = (answer.skipped || question?.hideFromLogic) ? '' : answer.additionalAnswer
        }
        if (answer.answerIds.length && !(answer.skipped || question?.hideFromLogic)) {
          formattedResponse.selectedAnswers[question.id] = answer.answerIds
        }
        if (answer.additionalComment) {
          formattedResponse.comments[question.id] = (answer.skipped || question?.hideFromLogic) ? '' : answer.additionalComment
        }
        if (answer.skipped || question?.hideFromLogic) {
          formattedResponse.skippedOrHidden[question.id] = true
        }
      }
      const submissionData = {
        ...auth,
        ...formattedResponse,
        page: page.index + 1
      }
      console.debug('Sending submission data.', submissionData)
      let currentTries = 0
      const maxTries = 3
      let accepted = false
      let response = null
      while (currentTries < maxTries) {
        response = await submitPublishedAssessmentPage(next, submissionData)
        console.debug('Got submit page response.', response, respondentAnswers)
        switch (response?.responseType) {
          case AssessmentResponse.Start:
          case AssessmentResponse.Continue:
          case AssessmentResponse.ResponseAccepted:
          case AssessmentResponse.Redirect: {
            accepted = true
            break
          }
          default: {
            console.warn('Got error page submission response.', response)
          }
        }
        currentTries += (accepted ? maxTries : 1)
      }
      responses.push(response)
      if (!accepted) {
        break;
      }
    }
  } else {
    console.debug('Skipping sending responses - no record response location.', next, assessmentId, recordResponse)
    const fakeResponseData = { data: { next } }
    responses.push({ data: fakeResponseData, responseType: AssessmentResponse.Redirect, error: true })
  }
  console.info('submit pages responses.', responses)
  let lastResponse = null
  for (const response of responses) {
    if (response) {
      lastResponse = response
    }
  }
  if (lastResponse) {
    return rejectWithValue(lastResponse)
  }
  return rejectWithValue({ data: {}, responseType: AssessmentResponse.UnexpectedError, error: true })
})

const pagesSlice = createSlice({
  name: 'pages',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('pages slice builder fulfilled', state, action)
        pagesAdapter.upsertMany(state, action.payload.pages)
      })
      .addCase(submitPages.fulfilled, (state, action) => {
        console.info('pages slice submit f-response', state, action)
        state.status = action.payload.responseType === AssessmentResponse.UnexpectedError ? QueryState.Failed : QueryState.Succeeded
        state.lastResponseType = action.payload.responseType
        state.lastResponseData = action.payload.data
        switch (action.payload.responseType) {
          case AssessmentResponse.Redirect:
          case AssessmentResponse.Start:
          case AssessmentResponse.Continue:
          case AssessmentResponse.ResponseAccepted:
          case AssessmentResponse.Timeout: {
            state.error = null
            break
          }
          default: {
            state.error = action.payload.responseType
          }
        }
      })
      .addCase(submitPages.rejected, (state, action) => {
        console.info('pages slice submit r-response - not necessarily an error, valid final response is redirect.', state, action)
        state.status = action.payload.responseType === AssessmentResponse.UnexpectedError ? QueryState.Failed : QueryState.Succeeded
        state.lastResponseType = action.payload.responseType
        state.lastResponseData = action.payload.data
        switch (action.payload.responseType) {
          case AssessmentResponse.Redirect:
          case AssessmentResponse.Start:
          case AssessmentResponse.Continue:
          case AssessmentResponse.ResponseAccepted:
          case AssessmentResponse.Timeout: {
            state.error = null
            break
          }
          default: {
            state.error = action.payload.responseType
          }
        }
      })
      .addCase(submitPages.pending, (state, action) => {
        console.debug('pages slice submit pending', state, action)
        state.status = QueryState.Submitting
      })
      .addCase(previousQuestionAnswered, (state, action) => {
        const { pageId } = action.payload
        const existingPage = state.entities[pageId]
        console.debug('Checking if new pages/blocks should be shown from previous question being answered.', pageId, existingPage)
        if (existingPage) {
          if (!existingPage.show) {
            existingPage.show = true
          }
        }
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        console.debug('respondent answers submitAnswer rejected - updating pagesSlice response data', state, action)
        const responseType = action.payload?.responseType ?? null
        if (state.status === QueryState.Succeeded || state.status === QueryState.Submitting) {
          console.debug('Not surfacing submit answer error due to submission completed or in progress.', state.status, action)
        }
        if (responseType === AssessmentResponse.Unauthorized || responseType === AssessmentResponse.LoggedOut || responseType === AssessmentResponse.ProctorFreeRequired) {
          if (state.status === QueryState.Succeeded || state.status === QueryState.Submitting) {
            console.debug('Not surfacing submit answer error due to submission completed or in progress.', state.status, action)
          } else {
            state.lastResponseType = responseType
            state.lastResponseData = action.payload.responseData ?? {}
            state.error = responseType
            state.status = QueryState.Failed
          }
        }
      })
  }
})

// export const {} = pagesSlice.actions

export default pagesSlice.reducer

export const { selectAll: selectAllPages, selectById: selectPageById, selectIds: selectPageIds } =
  pagesAdapter.getSelectors(state => state.pages)

export const selectPageIdsByAssessmentId = createSelector(
  [selectAllPages, (state, assessmentId) => assessmentId],
  (pages, assessmentId) => pages.filter(page => page.assessmentId === assessmentId).map(page => page.id)
)

export const selectSubmissionInProgress = createSelector(
  [(state) => state.pages.status],
  (status) => status === QueryState.Submitting
)

export const selectSubmissionComplete = createSelector(
  [(state) => state.pages.status],
  (status) => status === QueryState.Succeeded
)

export const selectSubmissionUnexpectedlyFailed = createSelector(
  [(state) => state.pages.status],
  (status) => status === QueryState.Failed
)
