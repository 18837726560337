import React from 'react'
import { Alert, Text } from '@mantine/core'
import PropTypes from 'prop-types'

export default function CustomAlert ({ children, color, icon, title, variant = 'light', mb = 'sm' }) {
  return <Alert variant={variant} color={color} title={title} icon={icon} mb={mb}><Text component='div' fz='md'>{children}</Text></Alert>
}

CustomAlert.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.string,
  mb: PropTypes.string
}
