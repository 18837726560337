/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useMemo } from 'react';
import {
  ActionIcon,
  Affix,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  Paper,
  ScrollArea,
  Stack,
  Stepper, Transition
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAnsweredQuestionIdsAlreadyDisplayedToUser,
  selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUser,
  selectQuestionById,
  selectNotAnsweredNotSkippedQuestionIdsAlreadyDisplayedToUser,
  selectDeclinedQuestionIdsAlreadyDisplayedToUser
} from './questionSlice';
import { selectSubmissionInProgress } from './pagesSlice';
import { transitionToFirstUnansweredQuestion, transitionToLastShownQuestion } from './logicSlice';
import { notifications } from '@mantine/notifications';
import { selectRespondentAnswerById } from './respondentAnswersSlice';
import { selectAssessmentHasTransitionToTarget, transitionToQuestion } from './assessmentsSlice';
import { useHover } from '@mantine/hooks';
import { PublishedQuestionTextContent } from './PublishedAssessmentQuestion';
import { IconArrowBarToRight } from '@tabler/icons-react';

export const ResponseReviewFrame = memo(function ResponseReviewFrame ({ timeLimit, showSearch, hasAnyUnansweredNotSkipped, confirmSubmitAssessmentIfMissing, reviewOpen, openReview, closeReview }) {
  return (
    <Affix position={{ top: timeLimit ? 100 : 60, left: 40 }}>
      <Transition transition='slide-up' duration={150} exitDuration={50} mounted={showSearch || !hasAnyUnansweredNotSkipped}>
        {(transitionStyles) => (
          <Box
            style={transitionStyles}
          >
            <ResponseReview
              opened={reviewOpen}
              open={openReview}
              close={closeReview}
              submit={confirmSubmitAssessmentIfMissing}
              allQuestionsViewed={!hasAnyUnansweredNotSkipped}
            />
          </Box>
        )}
      </Transition>
    </Affix>
  )
})

export const ResponseReview = memo(function ResponseReview ({ opened, open, close, submit, allQuestionsViewed, size = 'xl' }) {
  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        withCloseButton={false}
        size={size}
        padding={0}
        overlayProps={{ backgroundOpacity: 0.05, blur: 0 }}
        styles={{ content: { overflowX: 'hidden' }, body: { paddingRight: '1rem' } }}
      >
        <ResponseReviewDrawer allQuestionsViewed={allQuestionsViewed} submit={submit} />
      </Drawer>
      <Box w='100%'>
        <Center>
          <Button onClick={open}>Review Questions</Button>
        </Center>
      </Box>
    </>
  )
})

const ResponseReviewDrawer = memo(function ResponseReviewDrawer ({ allQuestionsViewed, submit }) {
  const untouchedSearchableQuestionIds = useSelector(state => selectNotAnsweredNotSkippedQuestionIdsAlreadyDisplayedToUser(state))
  const skippedSearchableQuestionIds = useSelector(state => selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUser(state))
  const declinedQuestionIds = useSelector(state => selectDeclinedQuestionIdsAlreadyDisplayedToUser(state))
  const searchableQuestionIds = useSelector(state => selectAnsweredQuestionIdsAlreadyDisplayedToUser(state))
  console.debug('ResponseReviewDrawer updating', untouchedSearchableQuestionIds, skippedSearchableQuestionIds, declinedQuestionIds, searchableQuestionIds)
  const hasAnyUnanswered = !allQuestionsViewed || !!skippedSearchableQuestionIds.length || !!untouchedSearchableQuestionIds.length

  return (
    <Stack>
      <ResponseProgressStepper
        allQuestionsViewed={allQuestionsViewed}
        hasAnyUnanswered={hasAnyUnanswered}
        submit={submit}
      />
      <ResponseReviewSegment segmentIds={untouchedSearchableQuestionIds} segmentPrefix={'unanswered'} />
      <ResponseReviewSegment segmentIds={skippedSearchableQuestionIds} segmentPrefix={'skipped'} />
      <ResponseReviewSegment segmentIds={declinedQuestionIds} segmentPrefix={'declined'} />
      <ResponseReviewSegment segmentIds={searchableQuestionIds} segmentPrefix={'answered'} />
    </Stack>
  )
})

const ResponseReviewSegment = memo(function ResponseReviewSegment ({ segmentIds, segmentPrefix = 'unknown' }) {
  return (
    <>
      {segmentIds.map(questionId => <ResponseReviewItem key={`${segmentPrefix}${questionId}`} questionId={questionId} />)}
    </>
  )
})

// hasAnyUnansweredNotSkipped = stage 0, hasAnyUnanswered = stage 1 (can submit with warning), !hasAnyUnanswered = stage 2 (can submit), submitInProgress = 3 (submitting, disable submit buttons)
const ResponseProgressStepper = memo(function ResponseProgressStepper ({ allQuestionsViewed, hasAnyUnanswered, submit }) {
  const submitting = useSelector(state => selectSubmissionInProgress(state))
  const activeStage = !allQuestionsViewed ? 0 : (submitting ? 3 : (hasAnyUnanswered ? 1 : 2))
  console.debug('ResponseProgressStepper updating.', allQuestionsViewed, hasAnyUnanswered, submitting, activeStage)
  const dispatch = useDispatch()

  const onStepClick = useCallback((stepNumber) => {
    console.debug(
      'On step click called.',
      stepNumber,
      activeStage
    )
    switch (stepNumber) {
      case 0: {
        dispatch(transitionToLastShownQuestion())
        break
      }
      case 1: {
        dispatch(transitionToFirstUnansweredQuestion())
        break
      }
      default: {
        if ((activeStage === 2) || (activeStage === 1)) {
          console.debug('Starting submission dialogue.', stepNumber, activeStage)
          submit()
        } else if (activeStage === 3) {
          console.debug('Submission already in progress - skipping.', stepNumber, activeStage)
          notifications.show({
            id: 'assessment-submission-in-progress',
            title: 'Submission Already in Progress',
            message: 'Your responses are currently being submitted.',
            color: 'yellow',
            withBorder: true
          })
        } else {
          console.debug('Assessment not ready to submit - skipping.', stepNumber, activeStage)
          notifications.show({
            id: 'must-complete-assessment',
            title: 'Assessment Incomplete',
            message: 'Please complete the assessment before submitting.',
            color: 'red',
            withBorder: true
          })
          dispatch(transitionToLastShownQuestion())
        }
      }
    }
  }, [activeStage, submit, dispatch])

  return (
    <Stepper
      active={activeStage}
      onStepClick={onStepClick}
    >
      <Stepper.Step label='Complete' desciption='View all questions'>
      </Stepper.Step>
      <Stepper.Step label='Review' desciption='Finalize responses'>
      </Stepper.Step>
      <Stepper.Step label='Submit' desciption='Submit assessment' loading={submitting}>
      </Stepper.Step>
      <Stepper.Completed>
        Submission in progress
      </Stepper.Completed>
    </Stepper>
  )
})

const ResponseReviewItem = memo(function ResponseReviewItem ({ questionId }) {
  const dispatch = useDispatch()
  const question = useSelector(state => selectQuestionById(state, questionId))
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const assessmentHasTarget = useSelector(state => selectAssessmentHasTransitionToTarget(state, respondentAnswer?.assessmentId))
  const { hovered, ref } = useHover();
  console.debug('ResponseReviewItem updating', questionId, !!question, !!respondentAnswer, assessmentHasTarget, hovered)

  const answerId = respondentAnswer?.answerId ?? null
  const answerContent = useMemo(() => {
    return respondentAnswer?.answered
      ? (answerId
          ? (question?.answers.filter(answer => answer.id.toString() === answerId)[0]?.content ?? '')
          : (respondentAnswer?.additionalAnswer ?? ''))
      : ((respondentAnswer?.skipped && !respondentAnswer?.declined)
          ? 'Skipped for now'
          : (respondentAnswer?.declined
              ? (question?.answerRequired ? 'Declined to Answer' : 'Skipped')
              : 'Not Answered'))
  }, [respondentAnswer, question?.answers, answerId, question?.answerRequired])

  const missingAnswer = (respondentAnswer?.skipped || respondentAnswer?.declined || !respondentAnswer?.answered)
  const paperParams = missingAnswer ? { style: { boxShadow: '0 0 10px #ff8f8f' } } : {}
  const responsePaperParams = missingAnswer ? { style: { color: '#8b0000' } } : {}

  if (!question) {
    return (
      <></>
    )
  }

  return (
    <Paper
      ref={ref}
      shadow="sm"
      variant={assessmentHasTarget ? 'q-text-preview-disabled' : 'q-text-preview'}
      withBorder={true}
      onClick={() => dispatch(transitionToQuestion({ questionId: questionId, assessmentId: respondentAnswer.assessmentId }))}
      { ...paperParams }
    >
      <ScrollArea.Autosize mah='30vh' type='auto' scrollbars='y' offsetScrollbars>
        <Paper variant='q-block'>
          <PublishedQuestionTextContent questionContent={question.content} />
        </Paper>
      </ScrollArea.Autosize>
      <Box pos='relative'>
        <Divider my="md" />
        <ActionIcon
          variant='subtle'
          aria-label={`Go To Question ${question.id}`}
          pos='absolute'
          right={(!hovered || assessmentHasTarget) ? 0 : '-4%'}
          top='50%'
          style={{ transform: 'translate(110%, -50%)', transition: 'right 1.0s cubic-bezier(0.85, 0, 0.15, 1)' }}
        >
          <IconArrowBarToRight />
        </ActionIcon>
      </Box>
      <ScrollArea.Autosize mah='30vh' type='auto' scrollbars='y' offsetScrollbars>
        <Paper variant='q-block' {...responsePaperParams} >
          <PublishedQuestionTextContent
            questionContent={answerContent}
          />
        </Paper>
      </ScrollArea.Autosize>
    </Paper>
  )
})
