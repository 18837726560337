import React from 'react'
import { useClipboard } from '@mantine/hooks'
import { ActionIcon, Button, Container, Grid, Paper, Space, Text, Tooltip } from '@mantine/core'
import { IconBrandTwitter } from '@tabler/icons-react'
import ReferralForm from './ReferralForm'

export default function ReferralInvite () {
  const url = document.getElementById('marketing-container').getAttribute('data-url')
  const email = document.getElementById('marketing-container').getAttribute('data-email')

  const clipboard = useClipboard()

  return (
    <Container size='xl' mt='md'>
    <Grid>
      <Grid.Col span={{ sm: 12, md: 8, lg: 6 }} offset={{ md: 2, lg: 3 }}>
        <Paper withBorder>
        <ReferralForm email={email}/>
        <Space h='lg'/>
        <h3>Or copy and share this link</h3>
        <input type='text' id='link' value={url} readOnly/>
        <Button color={clipboard.copied ? 'success' : 'blue.6'} onClick={() => clipboard.copy(url)}>
          {clipboard.copied ? 'Copied' : 'Copy'}
        </Button>
        <Space h='lg'/>
        <Text size='md'>Share on</Text>
        <Tooltip label='Twitter' position='bottom' withArrow>
          <ActionIcon variant='filled' color='#1DA1F2' component='a' href={`https://twitter.com/intent/tweet?text=Hire%20better%20using%20HireScore%2C%20a%20%40StangDS%20Hiring%20Service!%20&url=${url}`} target='_blank'>
            <IconBrandTwitter size={18} />
          </ActionIcon>
        </Tooltip>
        <Space h='xs'/>
        <Text size='md'>
          Referral bonuses are distributed in Bitcoin within 30 days of first bill. Does not apply if accounts are
          closed before the bonuses are issued.
        </Text>
        </Paper>
      </Grid.Col>
    </Grid>
    </Container>
  )
}
