import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'
import { answerQuestion, fetchPublishedAssessmentQuestions, transitionToComplete } from './assessmentsSlice';
import { getCurrentSeconds, QueryState } from './util';
import {
  submitPublishedAssessmentSingleQuestionResponse
} from '../../js/api/published_assessment_repository';
import { AssessmentResponse } from '../../js/generated/enums/AssessmentResponse';

/**
 * @type {EntityAdapter<{PublishedAssessmentRespondentAnswer}, int>}
 */
const respondentAnswersAdapter = createEntityAdapter()

const initialState = respondentAnswersAdapter.getInitialState({
  status: QueryState.Idle,
  error: null
})

export const submitAnswer = createAsyncThunk('respondentAnswers/submitOne', async (submitParams, { getState, rejectWithValue }) => {
  const { id } = submitParams
  console.debug('Submitting respondentAnswer.', id)
  const state = getState()
  const answer = state.respondentAnswers.entities[id]
  const question = state.questions.entities[id]
  const assessment = state.assessments.entities[answer?.assessmentId ?? question?.location.assessmentId ?? '0'] ?? null

  console.debug('Submit answer state', id, answer?.lastModificationId, answer?.lastSyncId, assessment?.updateUrl, assessment?.timeLimit, assessment?.startTime, question?.hideFromLogic)
  const lastModificationId = answer?.lastModificationId
  if (assessment?.updateUrl && answer?.id && (answer.lastSyncId < lastModificationId)) {
    const sendData = {
      ...assessment.auth,
      questionId: answer.id
    }
    if (assessment?.timeLimit) {
      const startTime = assessment.startTime
      const currentTime = getCurrentSeconds()
      const remainingTime = assessment.timeLimit - (currentTime - startTime)
      sendData.time = Math.max(Math.floor(remainingTime), 1)
      console.debug('Sending update answer remaining time. actual|sent', remainingTime, sendData.time, startTime, currentTime, assessment.timeLimit)
    }

    const isEmptySubmission = (answer.skipped || question?.hideFromLogic)
    if (answer.answerId) {
      sendData.answerId = isEmptySubmission ? null : answer.answerId
    }
    if (answer.answerIds) {
      sendData.selectedAnswers = isEmptySubmission ? [] : answer.answerIds
    }
    if (answer.additionalAnswer) {
      sendData.answerText = isEmptySubmission ? '' : answer.additionalAnswer
    }
    if (answer.additionalComment) {
      sendData.comment = isEmptySubmission ? '' : answer.additionalComment
    }
    if (isEmptySubmission) {
      sendData.skippedOrHidden = true
    }
    console.info('Submitting answer data.', sendData)
    const response = await submitPublishedAssessmentSingleQuestionResponse(assessment.updateUrl, sendData)
    const updateData = { data: { id: id, response: response.responseCode, lastModificationId: lastModificationId, answered: answer.shouldAnswer ? (!!answer.answerId) || (!!answer.additionalAnswer) || (!!answer.answerIds.length) : null } }
    console.info('Got submit answer response.', response, answer)
    if (!response) {
      console.warn('No response from submit response - was cancelled?', response)
      return rejectWithValue({ ...updateData, responseType: null, responseCode: null, responseData: null, error: true })
    }
    switch (response.responseType) {
      case AssessmentResponse.Start:
      case AssessmentResponse.Continue:
      case AssessmentResponse.ResponseAccepted: {
        return { ...updateData, responseType: response.responseType, responseCode: response.responseCode, responseData: response.data, error: false }
      }
      default: {
        return rejectWithValue({ ...updateData, responseType: response.responseType, responseCode: response.responseCode, responseData: response.data, error: true })
      }
    }
  } else {
    console.info('Skipping updating answer with submission.', id, answer?.lastSyncId, answer?.lastModificationId, assessment?.updateUrl)
  }
  return { data: { id: id, response: null, answered: null, lastModificationId: lastModificationId }, error: false, responseType: null, responseData: null }
})

export const submitPendingAnswers = createAsyncThunk('respondentAnswers/batchSubmitPending', async (submitParams, { dispatch }) => {
  const { ids } = submitParams
  const pendingIds = new Set(ids ?? [])
  console.debug('submitPendingAnswers respondentAnswers.', pendingIds, submitParams)
  for (const id of pendingIds) {
    console.debug('Batch dispatching submitAnswer for id', id)
    await dispatch(submitAnswer({ id }))
    console.debug('Batch dispatch proceeding to next id after current: ', id)
  }
  return { processed: ids ?? [] }
})

export const checkSubmitPendingAnswers = createAsyncThunk('respondentAnswers/checkSubmitPending', async (submitParams, { dispatch, getState }) => {
  const { count } = submitParams
  const state = getState()
  console.debug('checkSubmitPendingAnswers respondentAnswers.', state.respondentAnswers.status, submitParams)
  if (state.respondentAnswers.status === QueryState.Idle) {
    const pendingUpdates = selectRespondentAnswersPendingUpdates(state)
    if (pendingUpdates.length) {
      await dispatch(submitPendingAnswers({ ids: pendingUpdates }))
      console.debug('Check submit pending answers finished previous pending, calling recursively.')
      dispatch(checkSubmitPendingAnswers({ count: count + 1 }))
    } else {
      console.debug('checkSubmitPendingAnswers respondentAnswers not calling due to pendingUpdates length.', pendingUpdates.length, pendingUpdates, state.respondentAnswers.status, submitParams)
    }
  } else {
    console.debug('checkSubmitPendingAnswers respondentAnswers not calling submitBatch due to query state.', state.respondentAnswers.status, submitParams)
  }
})

export const BatchTransitionToQuestionComplete = createAsyncThunk('respondentAnswers/BatchTransitionToQuestionComplete', async (transitionParams, { getState, dispatch }) => {
  console.debug('BatchTransitionToQuestionComplete respondentAnswers.', transitionParams)
  dispatch(transitionToComplete(transitionParams))
  const state = getState()
  if (state.respondentAnswers.status === QueryState.Idle) {
    console.debug('BatchTransitionToQuestionComplete starting batch submission check due to idle status.', state.respondentAnswers.status, transitionParams)
    dispatch(checkSubmitPendingAnswers({ count: 0 }))
  } else {
    console.debug('BatchTransitionToQuestionComplete not starting batch submission - state is not idle.', state.respondentAnswers.status, transitionParams)
  }
})

const respondentAnswersSlice = createSlice({
  name: 'respondentAnswers',
  initialState: initialState,
  reducers: {
    updateVisibleQuestions: (state, action) => {
      const { data } = action.payload
      console.debug('updateVisibleQuestions in respondentAnswersSlice', data, action, state)
      for (const [id, hide] of data) {
        const response = state.entities[id]
        if (hide && response?.id && (response.answered || response.answerId || response.additionalAnswer || response.answerIds.length)) {
          console.info('Updating response answers from hide state change.', id, response, hide, response.answered, response.answerId, response.additionalAnswer)
          response.answered = false
          response.answerId = null
          response.answerIds = []
          response.additionalAnswer = ''
          response.skipped = false
          response.declined = false
          response.lastModificationId = response.lastModificationId + 1
        } else {
          console.debug('Not updating response answers from logic hide state removal.', id, response, hide, response.answered, response.answerId, response.additionalAnswer)
        }
      }
    },
    updateAnswerComment: (state, action) => {
      const { id, additionalComment } = action.payload
      const existingRespondentAnswer = state.entities[id]
      console.debug('Updating answer additionalComment', id, existingRespondentAnswer, additionalComment)
      if (existingRespondentAnswer) {
        if (existingRespondentAnswer.additionalComment !== additionalComment) {
          existingRespondentAnswer.lastModificationId = existingRespondentAnswer.lastModificationId + 1
        }
        existingRespondentAnswer.additionalComment = additionalComment
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(answerQuestion, (state, action) => {
        const { id, answerId, answerIds, isAdditional, isMultiple, isSkip, isRequiredIfSkip, additionalAnswer } = action.payload
        const existingRespondentAnswer = state.entities[id]
        console.debug('Updating answer', id, !!existingRespondentAnswer, { answerId, answerIds, additionalAnswer, isAdditional, isMultiple, isSkip }, existingRespondentAnswer?.answered, existingRespondentAnswer?.answerId, existingRespondentAnswer?.additionalAnswer)
        if (existingRespondentAnswer && !isSkip) {
          if (!isAdditional && !isMultiple && existingRespondentAnswer.answerId !== answerId) {
            existingRespondentAnswer.lastModificationId = existingRespondentAnswer.lastModificationId + 1
            if (answerId) {
              existingRespondentAnswer.answerIds = [answerId]
            } else {
              existingRespondentAnswer.answerIds = []
            }
          } else if (isAdditional && existingRespondentAnswer.additionalAnswer !== additionalAnswer) {
            existingRespondentAnswer.lastModificationId = existingRespondentAnswer.lastModificationId + 1
            existingRespondentAnswer.additionalAnswer = additionalAnswer
          } else if (isMultiple && existingRespondentAnswer.answerIds !== answerIds) {
            existingRespondentAnswer.lastModificationId = existingRespondentAnswer.lastModificationId + 1
            existingRespondentAnswer.answerId = answerIds[0] ?? answerId ?? null
            existingRespondentAnswer.answerIds = answerIds
          }
          const nowAnswered = !!((!isAdditional && !isMultiple && answerId) || (isAdditional && additionalAnswer) || (isMultiple && answerIds?.length))
          existingRespondentAnswer.answered = nowAnswered
          if (nowAnswered && existingRespondentAnswer.skipped) {
            existingRespondentAnswer.skipped = false
          }
          if (nowAnswered && existingRespondentAnswer.declined) {
            existingRespondentAnswer.declined = false
          }
        } else if (existingRespondentAnswer && isSkip) {
          console.debug('skipping existing respondent answer!', isRequiredIfSkip, answerId, existingRespondentAnswer.answerId)
          if (!existingRespondentAnswer.skipped) {
            existingRespondentAnswer.skipped = true
          }
          if (!isRequiredIfSkip) {
            existingRespondentAnswer.declined = true
          }
          if (existingRespondentAnswer.answered) {
            existingRespondentAnswer.answered = false
            existingRespondentAnswer.answerId = null
            existingRespondentAnswer.answerIds = []
            existingRespondentAnswer.additionalAnswer = ''
            existingRespondentAnswer.lastModificationId = existingRespondentAnswer.lastModificationId + 1
          }
        }
      })
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('respondent answers slice inserting initial state from assessment response', state, action)
        respondentAnswersAdapter.upsertMany(state, action.payload.respondentAnswers)
        // state.status = QueryState.Succeeded
      })
      .addCase(submitPendingAnswers.pending, (state, action) => {
        console.debug('respondent answers submitPendingAnswers pending', state, action, state.status)
        if (state.status === QueryState.Idle) {
          state.status = QueryState.Submitting
        } else {
          console.error('submitPendingAnswers.pending when state status was not idle.', state.status, action)
        }
      })
      .addCase(submitPendingAnswers.fulfilled, (state, action) => {
        console.debug('respondent answers submitPendingAnswers fulfilled', state, action, state.status)
        if (state.status === QueryState.Submitting) {
          state.status = QueryState.Idle
        } else {
          console.error('submitPendingAnswers.fulfilled when state status was not Submitting.', state.status, action)
        }
      })
      .addCase(submitPendingAnswers.rejected, (state, action) => {
        console.error('respondent answers submitPendingAnswers rejected', state, action, state.status)
        if (state.status === QueryState.Submitting) {
          state.status = QueryState.Idle
        } else {
          console.error('submitPendingAnswers.rejected when state status was not Submitting.', state.status, action)
        }
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        console.debug('respondent answers submitAnswer fulfilled', state, action)
        if (action.payload?.data?.id && !action.payload?.error) {
          respondentAnswersAdapter.updateOne(state, {
            id: action.payload.data.id,
            changes: { lastSyncId: action.payload.data.lastModificationId }
          })
        } else {
          console.error(
            'Unexpected submitAnswer fulfilled payload.',
            action.payload?.responseType,
            action.payload?.error,
            action.payload?.data,
            action.payload
          )
        }
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        console.debug('respondent answers submitAnswer rejected', state, action)
        const userResolveIssue = action.payload?.responseType === AssessmentResponse.Unauthorized || action.payload?.responseType === AssessmentResponse.LoggedOut || action.payload?.responseType === AssessmentResponse.ProctorFreeRequired
        if (action.payload?.data?.id && (userResolveIssue || action.payload?.responseCode === 500)) {
          console.error('500 or not currently able to submit question response due to requirements - stopping submission cycle.', action.payload)
          respondentAnswersAdapter.updateOne(state, {
            id: action.payload.data.id,
            changes: { lastSyncId: action.payload.data.lastModificationId }
          })
          if (userResolveIssue) {
            state.error = action.payload.responseType
          }
        }
      })
  }
})

export const { updateVisibleQuestions, updateAnswerComment } = respondentAnswersSlice.actions

export default respondentAnswersSlice.reducer

export const { selectAll: selectAllRespondentAnswers, selectById: selectRespondentAnswerById, selectIds: selectRespondentAnswerIds, selectEntities: selectRespondentAnswerMap } =
  respondentAnswersAdapter.getSelectors(state => state.respondentAnswers)

export const selectRespondentAnswersByPageId = createSelector(
  [selectAllRespondentAnswers, (state, pageId) => pageId],
  (respondentAnswers, pageId) => respondentAnswers.filter(respondentAnswer => respondentAnswer.pageId === pageId)
)

export const selectPreviousQuestionAnswered = createSelector(
  [selectRespondentAnswerById],
  (respondentAnswer) => respondentAnswer?.answered || respondentAnswer?.skipped || respondentAnswer?.declined
)

export const selectRespondentAnswersPendingUpdates = createSelector(
  [selectAllRespondentAnswers],
  (respondentAnswers) => respondentAnswers.filter(respondentAnswer => respondentAnswer.lastModificationId > respondentAnswer.lastSyncId).map(respondentAnswer => respondentAnswer.id)
)
