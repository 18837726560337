import {
  createSlice,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'
import { isErrorStatus, shouldValidateLogicDataQuestionAnswersForQuestionType, ValidationState } from './util';
import { notifications } from '@mantine/notifications';

const unpublishedLogicAdapter = createEntityAdapter()

const initialState = unpublishedLogicAdapter.getInitialState({
  status: ValidationState.Valid,
  error: null,
  validateAll: true,
  changedQuestionIds: [],
  lastCompleteStatus: ValidationState.Valid,
  userCheckQuestionIds: []
})

const unpublishedLogicSlice = createSlice({
  name: 'unpublishedLogic',
  initialState: initialState,
  reducers: {
    associatedAnswersChanged: (state, action) => {
      // Note: logicPotentiallyInvalidatedByChanges / hasDependentLogic ensured before this event is dispatched.
      const { questionId } = action.payload
      console.debug('called associatedAnswersChanged', state, action, questionId, state.status)
      state.lastCompleteStatus = isErrorStatus(state.status) ? state.status : state.lastCompleteStatus
      state.status = ValidationState.Validating
      if (!state.changedQuestionIds.includes(questionId)) {
        state.changedQuestionIds = [...state.changedQuestionIds, questionId]
      }
    },
    potentialQuestionPositionsChanged: (state, action) => {
      // Long term, once everything is in redux, we can select all questions that share a randomization pool id and just check those dependent&associated logic rather than ALL logic
      const { questionId } = action.payload
      console.debug('called potentialQuestionPositionsChanged - updating state status to validating.', state.status, state, action, questionId)
      state.lastCompleteStatus = isErrorStatus(state.status) ? state.status : state.lastCompleteStatus
      state.status = ValidationState.Validating
      if (!state.validateAll) {
        state.validateAll = true
      }
    },
    questionDeleted: (state, action) => {
      const { questionId } = action.payload
      console.debug('called questionDeleted', state, action, questionId)
      const existingLogicIds = [...selectLogicIdsByParentQuestionId({ unpublishedLogic: state }, questionId)]
      const changedQuestionIds = new Set(state.changedQuestionIds)
      if (existingLogicIds.length) {
        console.debug('Removing pre-existing logic ids associated with question recently deleted.', existingLogicIds, questionId)
        unpublishedLogicAdapter.removeMany(state, existingLogicIds)
        if (changedQuestionIds.has(questionId)) {
          changedQuestionIds.delete(questionId)
        }
      }
      const logicInvalidatedByChanges = selectLogicIdsByQuestionId({ unpublishedLogic: state }, questionId).map(id => state.entities[id])
      if (logicInvalidatedByChanges.length || isErrorStatus(state.status)) {
        console.debug('Updating state status to validating.', state.status, questionId, logicInvalidatedByChanges.length)
        state.lastCompleteStatus = isErrorStatus(state.status) ? state.status : state.lastCompleteStatus
        state.status = ValidationState.Validating
        state.changedQuestionIds = [...new Set([...changedQuestionIds.values(), ...logicInvalidatedByChanges.map(elem => elem.parentQuestionId)]).values()]
      } else {
        state.changedQuestionIds = [...changedQuestionIds.values()]
        console.debug('Skipping logic validation after question deleted due to no dependent logic.', questionId)
      }
    },
    unpublishedLogicLoaded: (state, action) => {
      const { logicArray } = action.payload
      console.debug('called unpublishedLogicLoaded', state, action, logicArray)
      if (logicArray) {
        unpublishedLogicAdapter.setAll(state, logicArray)
        if (!state.validateAll) {
          state.validateAll = true
          state.changedQuestionIds = []
        }
        if (state.status !== ValidationState.Validating) {
          state.lastCompleteStatus = isErrorStatus(state.status) ? state.status : state.lastCompleteStatus
          state.status = ValidationState.Validating
        }
      }
    },
    batchUpdateQuestionLogic: (state, action) => {
      const { logicArray, questionId } = action.payload
      console.debug('called batchUpdateQuestionLogic', state, action, logicArray, questionId)
      if (logicArray) {
        const logicArrayIds = new Set(logicArray.map(logic => logic.id))
        const previousQuestionLogicIds = selectLogicIdsByParentQuestionId({ unpublishedLogic: state }, questionId)
        const toDeleteIds = [...previousQuestionLogicIds.filter(previousLogicId => !logicArrayIds.has(previousLogicId))]
        unpublishedLogicAdapter.upsertMany(state, logicArray)
        if (toDeleteIds.length) {
          console.debug('Removing pre-existing logic ids associated with question not found in batch update.', toDeleteIds, questionId)
          unpublishedLogicAdapter.removeMany(state, toDeleteIds)
        }
        if (state.status !== ValidationState.Validating) {
          state.lastCompleteStatus = isErrorStatus(state.status) ? state.status : state.lastCompleteStatus
          state.status = ValidationState.Validating
        }
        if (!state.changedQuestionIds.includes(questionId)) {
          state.changedQuestionIds = [...state.changedQuestionIds, questionId]
        }
      }
    },
    validateLogic: (state, action) => { // TODO [full redux] separate validation for any time randomization pool / randomized question position changes (state.validateAll) to avoid repeated lookups
      const { questionsMapData, lastActiveQuestionIds } = action.payload
      const questionsMap = new Map(questionsMapData)
      const filteredOriginalChangedQuestions = state.changedQuestionIds.filter(elem => questionsMap.has(elem))
      const originalChangedQuestions = new Set(filteredOriginalChangedQuestions)
      const originalChangedQuestionIdsLength = originalChangedQuestions.size
      const filteredLastActiveQuestionIds = (lastActiveQuestionIds ?? []).filter(elem => questionsMap.has(elem))
      const toCheckIds = state.validateAll ? [...selectQuestionIdsWithChildLogic({ unpublishedLogic: state })] : [...new Set([...filteredLastActiveQuestionIds, ...originalChangedQuestions])]
      console.debug('validateLogic running', state, action, questionsMap, lastActiveQuestionIds, toCheckIds, state.changedQuestionIds, state.validateAll)
      const questionIdIndexLookup = new Map([...questionsMap.keys()].map((questionId, index) => [questionId, index]))
      const errorLogicIds = new Map()
      const minGroupPositions = new Map()
      const maxGroupPositions = new Map()
      const newChangedQuestions = new Set()
      const newChangedQuestionsErrors = new Map()
      for (const questionId of toCheckIds) {
        const potentiallyNewLogic = selectLogicIdsByParentQuestionId({ unpublishedLogic: state }, questionId).map(id => state.entities[id])
        const logicPotentiallyInvalidatedByChanges = selectLogicIdsByQuestionId({ unpublishedLogic: state }, questionId).map(id => state.entities[id])
        const newLogicWithNoErrors = potentiallyNewLogic.filter(logic => !errorLogicIds.has(logic.id))
        const dependentLogicWithNoErrors = logicPotentiallyInvalidatedByChanges.filter(logic => !errorLogicIds.has(logic.id))
        console.debug('Validating Question ID | potentially new logic | potentially invalidated logic', questionId, potentiallyNewLogic, logicPotentiallyInvalidatedByChanges)
        const changedLogicErrorIds = validateLogicForQuestionData(newLogicWithNoErrors, questionsMap, questionId)
        const affectedLogicErrorIds = validateLogicForQuestionData(dependentLogicWithNoErrors, questionsMap, questionId)

        // Validate question order, all associated randomization pool question order.
        const associatedLogicDataQuestionIds = new Set(potentiallyNewLogic.map(logic => logic.questionId)) // Must come before questionId
        const dependentLogicQuestionIds = new Set(logicPotentiallyInvalidatedByChanges.map(logic => logic.parentQuestionId)) // Must come after questionId
        const [beforeErrorQuestionId, beforeError] = validateAllQuestionsComeBefore(associatedLogicDataQuestionIds, questionsMap, questionId, minGroupPositions, maxGroupPositions)
        const [afterErrorQuestionId, afterError] = validateAllQuestionsComeAfter(dependentLogicQuestionIds, questionsMap, questionId, minGroupPositions, maxGroupPositions)
        if (beforeError) {
          newChangedQuestions.add(beforeErrorQuestionId)
          newChangedQuestionsErrors.set(beforeErrorQuestionId, new Set([beforeError]))
        }
        if (afterError) {
          newChangedQuestions.add(afterErrorQuestionId)
          newChangedQuestionsErrors.set(afterErrorQuestionId, new Set([afterError]))
        }

        // TODO [full redux] if state.validateAll then only need to check associatedLogicDataQuestionIds on each questionId iteration?
        for (const [errorId, error] of changedLogicErrorIds.entries()) {
          errorLogicIds.set(errorId, error)
        }
        for (const [errorId, error] of affectedLogicErrorIds) {
          errorLogicIds.set(errorId, error)
        }
      }
      console.debug('Validate logic error ids.', errorLogicIds, questionsMap, lastActiveQuestionIds)
      if (!errorLogicIds.size && !newChangedQuestionsErrors.size) {
        state.status = ValidationState.Valid
        const alwaysShowValidation = !!state.validateAll && isErrorStatus(state.lastCompleteStatus)
        if (state.validateAll) {
          state.validateAll = false
        }
        if (!!originalChangedQuestionIdsLength || alwaysShowValidation) {
          state.changedQuestionIds = []
          notifications.show({
            id: 'logic-good',
            color: 'green',
            title: 'Logic Looks Good',
            message: 'Your question logic that may have been impacted checks out! Note that logic will only apply to assessments published under the new "beta" style.'
          })
        }
        if (state.userCheckQuestionIds.length) {
          console.debug('Clearing user check question ids in check all validated branch.', newChangedQuestions, state.userCheckQuestionIds)
          state.userCheckQuestionIds = []
        }
        state.lastCompleteStatus = ValidationState.Valid
      } else {
        // TODO [full redux] store id => error lookup in state for triage?
        state.status = errorLogicIds.size ? errorLogicIds.values().next().value : ValidationState.QuestionOrderError
        state.lastCompleteStatus = state.status
        for (const errorLogicId of errorLogicIds.keys()) {
          const logicEntity = state.entities[errorLogicId]
          const errorParentQuestionId = logicEntity?.parentQuestionId
          newChangedQuestions.add(errorParentQuestionId)
          if (!newChangedQuestionsErrors.has(errorParentQuestionId)) {
            newChangedQuestionsErrors.set(errorParentQuestionId, new Set([errorLogicIds.get(errorLogicId)]))
          } else {
            newChangedQuestionsErrors.get(errorParentQuestionId).add(errorLogicIds.get(errorLogicId))
          }
        }
        if (!state.validateAll) {
          let anyNewFound = false
          for (const questionId of newChangedQuestions) {
            const errorQuestion = questionsMap.get(questionId)
            notifications.show({
              id: 'logic-error-for-id-' + questionId,
              color: 'red',
              title: 'Invalid Question Logic',
              message: 'Question logic error for question #' +
                (errorQuestion?.originalNumber ?? (errorQuestion?.number ?? ('(no number index ' + (questionIdIndexLookup.get(questionId) ?? 'not found in question data') + ')'))) +
                ': ' + ([...(newChangedQuestionsErrors.get(questionId) ?? new Set())].join(','))
            })
            if (!originalChangedQuestions.has(questionId)) {
              anyNewFound = true
            }
          }
          if (anyNewFound) {
            console.debug('Updating state changed question ids to track those still needing validation.', originalChangedQuestions, newChangedQuestions)
            state.changedQuestionIds = [...newChangedQuestions]
          }
          console.debug('Setting user check question ids in specific validation error branch.', newChangedQuestions, state.userCheckQuestionIds)
          state.userCheckQuestionIds = [...newChangedQuestions]
        } else {
          for (const questionId of newChangedQuestions) {
            const errorQuestion = questionsMap.get(questionId)
            notifications.show({
              id: 'logic-error-for-id-' + questionId,
              color: 'red',
              title: 'Invalid Question Logic',
              message: 'Question logic error for question #' +
                (errorQuestion?.originalNumber ?? (errorQuestion?.number ?? ('(no number index ' + (questionIdIndexLookup.get(questionId) ?? 'not found in question data') + ')'))) +
                ': ' + ([...(newChangedQuestionsErrors.get(questionId) ?? new Set())].join(','))
            })
          }
          console.debug('Setting user check question ids in check all validation error branch.', newChangedQuestions, state.userCheckQuestionIds)
          state.userCheckQuestionIds = [...newChangedQuestions]
        }
      }
    }
  }
})

/**
 *
 * @param {Set} dataQuestionIds
 * @param {Map.<int, Question>} questionsMap
 * @param {int} actionQuestionId
 * @param {Map.<int, int>} minGroupPositions
 * @param {Map.<int, int>} maxGroupPositions
 * @returns {[int, ValidationState]|[null, null]}
 */
function validateAllQuestionsComeBefore (dataQuestionIds, questionsMap, actionQuestionId, minGroupPositions, maxGroupPositions) {
  const actionQuestion = questionsMap.get(actionQuestionId)
  if (!actionQuestion) {
    return [actionQuestionId, ValidationState.MissingRequiredLogicFields]
  }
  const actionHasRandomPool = actionQuestion.randomizedPool !== null
  const minActionGroupPosition = !actionHasRandomPool
    ? actionQuestion.position
    : (
        minGroupPositions.get(actionQuestion.randomizedPool) ?? getMinPositionOfQuestions(getAllQuestionsMatchingPool(questionsMap, actionQuestion.randomizedPool))
      )
  if (actionHasRandomPool && !minGroupPositions.has(actionQuestion.randomizedPool)) {
    minGroupPositions.set(actionQuestion.randomizedPool, minActionGroupPosition)
  }
  for (const questionId of dataQuestionIds.values()) {
    const dataQuestion = questionsMap.get(questionId)
    if (!dataQuestion) {
      return [actionQuestionId, ValidationState.MissingRequiredLogicFields]
    }
    const dataHasRandomPool = dataQuestion.randomizedPool !== null
    if (dataHasRandomPool && dataQuestion.randomizedPool === actionQuestion.randomizedPool) {
      return [actionQuestionId, ValidationState.QuestionRandomizationError]
    }
    if (dataHasRandomPool) {
      const maxDataGroupPosition = maxGroupPositions.get(dataQuestion.randomizedPool) ?? getMaxPositionOfQuestions(getAllQuestionsMatchingPool(questionsMap, dataQuestion.randomizedPool))
      if (!maxGroupPositions.has(dataQuestion.randomizedPool)) {
        maxGroupPositions.set(dataQuestion.randomizedPool, maxDataGroupPosition)
      }
      if (minActionGroupPosition <= maxDataGroupPosition) {
        return [actionQuestionId, ValidationState.QuestionRandomizationError]
      }
    } else {
      if (minActionGroupPosition <= dataQuestion.position) {
        return [actionQuestionId, ValidationState.QuestionOrderError]
      }
    }
  }
  return [null, null]
}

/**
 *
 * @param {Set} actionQuestionIds
 * @param {Map.<int, Question>} questionsMap
 * @param {int} dataQuestionId
 * @param {Map.<int, int>} minGroupPositions
 * @param {Map.<int, int>} maxGroupPositions
 * @returns {[int, ValidationState]|[null, null]}
 */
function validateAllQuestionsComeAfter (actionQuestionIds, questionsMap, dataQuestionId, minGroupPositions, maxGroupPositions) {
  const dataQuestion = questionsMap.get(dataQuestionId)
  if (!dataQuestion) {
    return [actionQuestionIds.values().next().value ?? dataQuestionId, ValidationState.MissingRequiredLogicFields]
  }
  const dataHasRandomPool = dataQuestion.randomizedPool !== null
  const maxDataGroupPosition = !dataHasRandomPool
    ? dataQuestion.position
    : (
        maxGroupPositions.get(dataQuestion.randomizedPool) ?? getMaxPositionOfQuestions(getAllQuestionsMatchingPool(questionsMap, dataQuestion.randomizedPool))
      )
  if (dataHasRandomPool && !maxGroupPositions.has(dataQuestion.randomizedPool)) {
    maxGroupPositions.set(dataQuestion.randomizedPool, maxDataGroupPosition)
  }
  for (const questionId of actionQuestionIds.values()) {
    const actionQuestion = questionsMap.get(questionId)
    if (!actionQuestion) {
      return [questionId, ValidationState.MissingRequiredLogicFields]
    }
    const actionHasRandomPool = actionQuestion.randomizedPool !== null
    if (actionHasRandomPool && actionQuestion.randomizedPool === dataQuestion.randomizedPool) {
      return [questionId, ValidationState.QuestionRandomizationError]
    }
    if (actionHasRandomPool) {
      const minActionGroupPosition = minGroupPositions.get(actionQuestion.randomizedPool) ?? getMinPositionOfQuestions(getAllQuestionsMatchingPool(questionsMap, actionQuestion.randomizedPool))
      if (!minGroupPositions.has(actionQuestion.randomizedPool)) {
        minGroupPositions.set(actionQuestion.randomizedPool, minActionGroupPosition)
      }
      if (minActionGroupPosition <= maxDataGroupPosition) {
        return [questionId, ValidationState.QuestionRandomizationError]
      }
    } else {
      if (actionQuestion.position <= maxDataGroupPosition) {
        return [questionId, dataHasRandomPool ? ValidationState.QuestionRandomizationError : ValidationState.QuestionOrderError]
      }
    }
  }
  return [null, null]
}

function getAllQuestionsMatchingPool (questionsMap, poolId) {
  return [...questionsMap.values()].filter(question => question.randomizedPool === poolId)
}

function getMinPositionOfQuestions (questions) {
  return questions.length > 1 ? Math.min(...questions.map(question => question.position)) : (questions[0]?.position ?? 0)
}

function getMaxPositionOfQuestions (questions) {
  return questions.length > 1 ? Math.max(...questions.map(question => question.position)) : (questions[0]?.position ?? 0)
}

function validateLogicForQuestionData (potentiallyAffectedLogic, questionsMap, questionId) {
  const errorLogicIds = new Map()
  for (const logic of potentiallyAffectedLogic) {
    console.debug('Validating potentially affected logic:', logic.id, logic)
    if (!logic.questionId) {
      errorLogicIds.set(logic.id, ValidationState.MissingRequiredLogicFields)
      console.warn('No question id for logic.', logic.id, logic, questionId)
      continue
    }
    const dataQuestion = questionsMap.get(logic.questionId)
    if (!dataQuestion) {
      errorLogicIds.set(logic.id, ValidationState.MissingRequiredLogicFields)
      console.warn('No question data found for logic data question id.', logic.id, logic.questionId, logic, questionId, questionsMap)
      continue
    }
    if (!logic.operator) {
      errorLogicIds.set(logic.id, ValidationState.MissingRequiredLogicFields)
      console.warn('No operator for logic.', logic.id, logic.operator, logic, questionId)
      continue
    }
    const logicValue = logic.value ?? ''
    const valueValid = validateExistingLogicValueAgainstDataQuestionAnswers(dataQuestion, logicValue)
    if (!valueValid) {
      console.warn('No question answer match for logic value.', logic.id, logicValue, logic.questionId, logic, questionId, dataQuestion.answers, dataQuestion.type, dataQuestion)
      errorLogicIds.set(logic.id, ValidationState.MatchAnswerContentError)
    }
  }
  return errorLogicIds
}

function validateExistingLogicValueAgainstDataQuestionAnswers (dataQuestion, logicValue) {
  if ((logicValue === '') || (logicValue === null) || (logicValue === undefined)) {
    return true
  }
  if (shouldValidateLogicDataQuestionAnswersForQuestionType(dataQuestion.type)) {
    for (const answer of dataQuestion.answers) {
      if (answer.content === logicValue) {
        return true
      }
    }
    return false
  }
  return true
}

export const {
  associatedAnswersChanged,
  potentialQuestionPositionsChanged,
  questionDeleted,
  unpublishedLogicLoaded,
  batchUpdateQuestionLogic,
  validateLogic
} = unpublishedLogicSlice.actions

export default unpublishedLogicSlice.reducer

export const {
  selectAll: selectAllUnpublishedLogic,
  selectById: selectUnpublishedLogicById,
  selectIds: selectUnpublishedLogicIds,
  selectEntities: selectUnpublishedLogicMap
} = unpublishedLogicAdapter.getSelectors(state => state.unpublishedLogic)

export const selectLogicIdsByParentQuestionId = createSelector(
  [selectAllUnpublishedLogic, (state, parentQuestionId) => parentQuestionId],
  (unpublishedLogic, parentQuestionId) => unpublishedLogic.filter(logic => logic.parentQuestionId === parentQuestionId).map(logic => logic.id)
)

export const selectLogicIdsByQuestionId = createSelector(
  [selectAllUnpublishedLogic, (state, questionId) => questionId],
  (unpublishedLogic, questionId) => unpublishedLogic.filter(logic => logic.questionId === questionId).map(logic => logic.id)
)

export const hasAnyLogicDependentOnResponse = createSelector(
  [selectLogicIdsByQuestionId],
  (
    unpublishedLogicArray
  ) => !!unpublishedLogicArray.length
)

export const selectQuestionIdsWithChildLogic = createSelector(
  [selectAllUnpublishedLogic],
  (
    unpublishedLogic
  ) => [...new Set(unpublishedLogic.map(logic => logic.parentQuestionId))]
)

export const selectIsValid = createSelector(
  [(state) => state.unpublishedLogic.status],
  (status) => status === ValidationState.Valid
)

export const selectUserShouldCheckQuestionIdLogic = createSelector(
  [(state, questionId) => questionId, (state) => state.unpublishedLogic.userCheckQuestionIds],
  (questionId, userCheckQuestionIds) => userCheckQuestionIds.includes(questionId)
)
