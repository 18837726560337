/* eslint react/prop-types: 0 */
import { Button, FileButton, Group, Paper, Stack, TagsInput, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconFileUpload } from '@tabler/icons-react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GoBackButton } from '../../core/GoBackButton'

export default function FileUpload ({ back, files, submit }) {
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const extensions = ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'txt', 'jpg', 'png']
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      file: null,
      description: '',
      tags: []
    },
    validate: {
      file: value => value !== null ? null : 'Please choose a file'
    }
  })

  useEffect(() => {
    setTags(_.union(...files.map(file => file.tags)))
  }, [files])

  const onSubmit = values => {
    setLoading(true)

    submit(values)
      .then(() => {
        navigate(back)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form onSubmit={form.onSubmit(values => onSubmit(values))}>
      <Paper size='md' p='md' withBorder>
        <Text size='md'>Data files with the following extensions are allowed: <strong>*.{_.join(extensions, ', *.')}</strong>.</Text>
        <Text size='md'>Individual data files must be less than <strong>10 MB</strong>.</Text>
        <Stack>
          {form.errors?.file &&
            <Text c='red' weight={700}>{form.errors?.file}</Text>
          }
          <Group bg='gray.2' p='xs' w='auto' display='flex' style={{ borderRadius: 'var(--mantine-radius-default)', border: 'solid 1px lightgray' }}>
            <FileButton
              {...form.getInputProps('file')}
              accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,text/plain,image/png,image/jpeg'
              >
              {props => <Button {...props} disabled={loading}>Select file</Button>}
            </FileButton>
            {/* {form.values.file?.name && */}
            <Text fw={700} style={{ flexGrow: 1 }}>{form.values.file?.name}</Text>
            {/* } */}
          </Group>
            <TextInput placeholder='Description' {...form.getInputProps('description')}/>
            <TagsInput
              data={tags}
              placeholder='Tags'
              onOptionSubmit={query => {
                const item = query
                setTags(current => [...current, item])
                return item
              }}
              {...form.getInputProps('tags')}
            />
        </Stack>
      </Paper>
      <Group spacing='xs' mt='sm'>
        <Button color='blue' leftSection={<IconFileUpload size={18}/>} type='submit' disabled={loading}>{loading ? 'Loading...' : 'Upload'}</Button>
        <GoBackButton component={Link} to={back} disabled={loading} />
      </Group>
    </form>
  )
}
