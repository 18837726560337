import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux';

/**
 * @typedef {object} TableColumn
 * @property {string} id
 * @property {string} namespace
 * @property {string} __namespacedId `${column.namespace}-${column.id}` - used by redux.
 * @property {int} index
 * @property {int} depth starts at 1, otherwise multiples of ten.
 * @property {?bool} ignore true if column.collapsedGroup [legacy artifact]
 * @property {bool} hidden
 * @property {int} collapsed
 * @property {?bool} hideable
 * @property {?bool} collapsable
 * @property {?bool} defaultCollapsed
 * @property {?bool} defaultHidden
 * @property {?(string[])} columnIds
 * @property {?string} parentId
 * @property {?bool} isSelection
 * @property {*} [props]
 */

const getCollapseValueForDepth = (collapsed, depth) => !!Math.floor((collapsed / (depth || 1)) % 10)

const getColumnActive = (column) => !column.hidden && !column.collapsed && !column.ignore && !column.columnIds?.length

export const makeId = (columnId, namespace) => `${namespace}-${columnId}`

const selectId = (column) => column.__namespacedId

/**
 * @type {EntityAdapter<{TableColumn}, string>}
 */
const tableColumnsAdapter = createEntityAdapter({
  selectId
  // sortComparer: (a, b) => (a.index - b.index),
})

const initialState = tableColumnsAdapter.getInitialState({
  error: null,
  namespaceOrderByField: {}
})

export const orderColumn = createAsyncThunk('tableColumns/orderColumn', async (orderParams, { dispatch, rejectWithValue }) => {
  const { namespace, columnId, orderBy, order } = (orderParams ?? {})
  console.debug('orderColumn', { namespace, columnId, order, orderBy, orderParams })
  dispatch(updateColumnOrderBy(orderParams))
})

export const resetColumns = createAsyncThunk('tableColumns/resetColumns', async (resetParams, { getState, dispatch }) => {
  const { namespace } = (resetParams ?? {})
  console.debug('Resetting columns for namespace', { resetParams })
  const state = getState()
  const hiddenColumns = selectHiddenColumnIdsByNamespace(state, namespace)
  const collapsedColumns = selectCollapsedColumnIdsByNamespace(state, namespace)
  const defaultCollapsed = selectDefaultCollapsedColumnIdsByNamespace(state, namespace)
  const defaultHidden = selectDefaultHiddenColumnIdsByNamespace(state, namespace)
  console.debug('Resetting ids', { hiddenColumns, collapsedColumns, defaultCollapsed, defaultHidden })
  dispatch(batchUpdateColumns({ namespace: namespace, hideIds: defaultHidden, collapseIds: defaultCollapsed, expandIds: collapsedColumns, showIds: hiddenColumns }))
})

export const showExpandAllColumns = createAsyncThunk('tableColumns/showExpandAllColumns', async (namespace, { getState, dispatch }) => {
  console.debug('Showing and expanding columns for namespace', namespace)
  const state = getState()
  const hiddenColumns = selectHiddenColumnIdsByNamespace(state, namespace)
  const collapsedColumns = selectCollapsedColumnIdsByNamespace(state, namespace)
  console.debug('Showing ids', { hiddenColumns })
  console.debug('Expanding ids', { collapsedColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, showIds: hiddenColumns, expandIds: collapsedColumns }))
})

export const showAllColumnsAndResetExpansion = createAsyncThunk('tableColumns/showAllColumnsAndResetExpansion', async (namespace, { getState, dispatch }) => {
  console.debug('Showing all columns and resetting expansion for namespace', namespace)
  const state = getState()
  const hiddenColumns = selectHiddenColumnIdsByNamespace(state, namespace)
  const expandIds = selectCollapsedColumnIdsByNamespace(state, namespace)
  const collapsedColumns = selectDefaultCollapsedColumnIdsByNamespace(state, namespace)
  console.debug('Showing ids', { hiddenColumns })
  console.debug('Expanding ids', { expandIds })
  console.debug('Then collapsing ids', { collapsedColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, showIds: hiddenColumns, collapseIds: collapsedColumns, expandIds: expandIds }))
})

export const showAllColumns = createAsyncThunk('tableColumns/showAllColumns', async (namespace, { getState, dispatch }) => {
  console.debug('Showing all columns for namespace', namespace)
  const state = getState()
  const hiddenColumns = selectHiddenColumnIdsByNamespace(state, namespace)
  console.debug('Showing ids', { hiddenColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, showIds: hiddenColumns }))
})

export const hideAllColumns = createAsyncThunk('tableColumns/hideAllColumns', async (namespace, { getState, dispatch }) => {
  console.debug('Hiding all columns for namespace', namespace)
  const state = getState()
  const allColumns = selectTableColumnIdsByNamespace(state, namespace)
  console.debug('Hiding ids', { allColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, hideIds: allColumns }))
})

export const expandAllColumns = createAsyncThunk('tableColumns/expandAllColumns', async (namespace, { getState, dispatch }) => {
  console.debug('Expanding all columns for namespace', namespace)
  const state = getState()
  const collapsedColumns = selectCollapsedColumnIdsByNamespace(state, namespace)
  console.debug('Expanding ids', { collapsedColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, expandIds: collapsedColumns }))
})

export const collapseAllColumns = createAsyncThunk('tableColumns/collapseAllColumns', async (namespace, { getState, dispatch }) => {
  console.debug('Collapsing all columns for namespace', namespace)
  const state = getState()
  const allColumns = selectTableColumnIdsByNamespace(state, namespace)
  console.debug('Collapsing ids', { allColumns })
  dispatch(batchUpdateColumns({ namespace: namespace, collapseIds: allColumns }))
})

const tableColumnsSlice = createSlice({
  name: 'tableColumns',
  initialState: initialState,
  reducers: {
    columnsLoaded: (state, action) => {
      const { namespace, columns, hiddenIds, isDefaultUpdate } = action.payload
      console.debug('Loading columns', { namespace, columns, hiddenIds, isDefaultUpdate })
      const defaultHidden = new Set(hiddenIds)
      const parsedColumns = []
      let index = 0
      const parseColumns = (currentColumns, depth, parentId = null, canHideParent = false, parentHidden = false, parentCollapsed = 0) => {
        for (const column of (currentColumns ?? [])) {
          const canHide = !!(column.hideable || canHideParent)
          const selfHidden = canHide && (parentHidden || defaultHidden.has(column.id))
          const selfCollapsed = (column.collapsable && column.columns?.length) && !column.summaryColumnGroup
          const collapsedValue = selfCollapsed ? parentCollapsed + depth : (!column.summaryColumnGroup ? parentCollapsed : 0) // TODO [long term] pass parentCollapsed to children for deeper nesting support
          parsedColumns.push({
            ...column,
            namespace: namespace,
            __namespacedId: makeId(column.id, namespace),
            columnIds: column.columns?.map(column => column.id) ?? null,
            columns: null,
            parentId: parentId,
            depth: depth,
            index: index,
            ignore: !!column.collapsedGroup,
            collapsable: !!(column.collapsable || collapsedValue),
            hideable: canHide,
            showHideOption: !!column.hideable && !parentId,
            defaultCollapsed: selfCollapsed,
            defaultHidden: column.hideable && defaultHidden.has(column.id),
            Cell: null, // TODO [long term] refactor into string enum
            Header: null, // TODO [long term] refactor into string enum
            _Header: null, // TODO [long term] remove once selectable column refactored
            isSelection: column.id === 'checkbox', // TODO [long term] consider refactoring selections?
            ...(isDefaultUpdate ? {} : { hidden: selfHidden, collapsed: collapsedValue, accessor: column.accessor ?? null }) // Avoids resetting collapsed on save. TODO [accessors] select state directly in row cell
          })
          console.debug('Parsed column in redux', { id: column.id, column: column, parsedColumn: parsedColumns[parsedColumns.length - 1] })
          index += 1
          if (column.columns) {
            parseColumns(column.columns, depth * 10, column.id, canHide, selfHidden, collapsedValue)
          }
        }
      }
      const startDepth = 1
      parseColumns(columns, startDepth)
      console.debug('Loaded parsed columns', { parsedColumns, columns, namespace })
      tableColumnsAdapter.upsertMany(state, parsedColumns)
    },
    batchUpdateColumns: (state, action) => {
      const { hideIds, collapseIds, showIds, expandIds, namespace } = action.payload
      console.debug('batchUpdateColumns columns', { hideIds, collapseIds, showIds, expandIds, namespace })
      // Note that order of operations (show -> then hide, expand -> then collapse) is important here.
      for (const id of (showIds ?? [])) {
        const existing = state.entities[makeId(id, namespace)] ?? null
        if (existing?.hideable) {
          existing.hidden = false
          let nextIds = existing.columnIds
          while (nextIds?.length) {
            console.debug('Chain hiding sub columns', { nextIds })
            const currentIds = nextIds
            nextIds = []
            for (const currentId of currentIds) {
              const subExisting = state.entities[makeId(currentId, namespace)] ?? null
              if (subExisting?.hideable) {
                subExisting.hidden = false
                if (subExisting.columnIds?.length) {
                  nextIds.push(...subExisting.columnIds)
                }
              }
            }
          }
        }
      }
      for (const id of (hideIds ?? [])) {
        const existing = state.entities[makeId(id, namespace)] ?? null
        if (existing?.hideable) {
          existing.hidden = true
          let nextIds = existing.columnIds
          while (nextIds?.length) {
            console.debug('Chain hiding sub columns', { nextIds })
            const currentIds = nextIds
            nextIds = []
            for (const currentId of currentIds) {
              const subExisting = state.entities[makeId(currentId, namespace)] ?? null
              if (subExisting?.hideable) {
                subExisting.hidden = true
                if (subExisting.columnIds?.length) {
                  nextIds.push(...subExisting.columnIds)
                }
              }
            }
          }
        }
      }
      for (const id of (expandIds ?? [])) {
        const existing = state.entities[makeId(id, namespace)] ?? null
        if (existing) {
          const collapseDepth = existing.depth
          const depthValue = collapseDepth
          if (existing.collapsable && !existing.summaryColumnGroup && getCollapseValueForDepth(existing.collapsed, collapseDepth)) {
            existing.collapsed -= depthValue
          }
          let nextIds = existing.columnIds
          while (nextIds?.length) {
            console.debug('Chain expanding sub columns', { nextIds, collapseDepth })
            const currentIds = nextIds
            nextIds = []
            for (const currentId of currentIds) {
              const subExisting = state.entities[makeId(currentId, namespace)] ?? null
              if (subExisting?.collapsable && getCollapseValueForDepth(subExisting.collapsed, collapseDepth)) {
                subExisting.collapsed -= depthValue
                if (subExisting.columnIds?.length) {
                  nextIds.push(...subExisting.columnIds)
                }
              }
            }
          }
        }
      }
      for (const id of (collapseIds ?? [])) {
        const existing = state.entities[makeId(id, namespace)] ?? null
        if (existing) {
          const collapseDepth = existing.depth
          const depthValue = collapseDepth
          if (existing.collapsable && !existing.summaryColumnGroup && !getCollapseValueForDepth(existing.collapsed, collapseDepth)) {
            existing.collapsed += depthValue
          }
          let nextIds = existing.columnIds
          while (nextIds?.length) {
            console.debug('Chain collapsing sub columns', { nextIds, collapseDepth })
            const currentIds = nextIds
            nextIds = []
            for (const currentId of currentIds) {
              const subExisting = state.entities[makeId(currentId, namespace)] ?? null
              if (subExisting?.collapsable && !getCollapseValueForDepth(subExisting.collapsed, collapseDepth) && !subExisting.summaryColumnGroup) { // TODO [long term] iterate children if summary column group to allow deeper nesting
                subExisting.collapsed += depthValue
                if (subExisting.columnIds?.length) {
                  nextIds.push(...subExisting.columnIds)
                }
              }
            }
          }
        }
      }
    },
    updateColumnVisibility: (state, action) => {
      const { id, namespace, hidden: hiddenParam } = action.payload
      console.debug('Updating column visibility', { id, namespace, hiddenParam })
      const existing = state.entities[makeId(id, namespace)] ?? null
      const hidden = hiddenParam ?? !existing?.hidden
      console.debug('New hidden value', { hidden })
      if (existing?.hideable && existing.hidden !== hidden) {
        existing.hidden = hidden
        let nextIds = existing.columnIds
        while (nextIds?.length) {
          console.debug('Chain updating sub columns visibility', { nextIds, hidden })
          const currentIds = nextIds
          nextIds = []
          for (const currentId of currentIds) {
            const subExisting = state.entities[makeId(currentId, namespace)] ?? null
            if (subExisting?.hideable && subExisting.hidden !== hidden) {
              subExisting.hidden = hidden
              if (subExisting.columnIds?.length) {
                nextIds.push(...subExisting.columnIds)
              }
            } else {
              console.debug('Not updating sub-column', { id: subExisting?.id, wasHidden: subExisting?.hidden, canHide: subExisting?.hideable, hidden: hidden })
            }
          }
        }
      } else {
        console.debug('Not updating column', { wasHidden: existing?.hidden, canHide: existing?.hideable, hidden: hidden })
      }
    },
    updateColumnCollapsed: (state, action) => {
      const { id, namespace, collapsed } = action.payload
      console.debug('Updating column collapsed', { id, namespace, collapsed })
      const existing = state.entities[makeId(id, namespace)] ?? null
      if (existing) {
        const collapseDepth = existing.depth
        const depthValue = ((collapsed ? 1 : -1) * collapseDepth)
        if (existing.collapsable && !existing.summaryColumnGroup && (getCollapseValueForDepth(existing.collapsed, collapseDepth) !== collapsed)) {
          existing.collapsed += depthValue
        }
        let nextIds = existing.columnIds
        while (nextIds?.length) {
          console.debug('Chain updating sub columns collapsed', { nextIds, collapsed, collapseDepth })
          const currentIds = nextIds
          nextIds = []
          for (const currentId of currentIds) {
            const subExisting = state.entities[makeId(currentId, namespace)] ?? null
            if (subExisting?.collapsable && (getCollapseValueForDepth(subExisting.collapsed, collapseDepth) !== collapsed) && !subExisting.summaryColumnGroup) { // TODO iterate children if summary column group to allow deeper nesting
              subExisting.collapsed += depthValue
              if (subExisting.columnIds?.length) {
                nextIds.push(...subExisting.columnIds)
              }
            }
          }
        }
      }
    },
    updateColumnOrderBy: (state, action) => {
      const { namespace, columnId, orderBy, order, parentId, updateSortId } = action.payload
      if (namespace) { // Note: currently only supports one order by per table. Additional order-by entries are so parents can visually hint a child is the current sort by.
        console.debug('Updating column order by', { namespace, columnId, orderBy, order, parentId, updateSortId, action })
        const newOrder = { orderBy, order }
        const newNamespaceDict = columnId ? { [columnId]: newOrder } : null
        if (columnId) {
          if (parentId && updateSortId) {
            newNamespaceDict[parentId] = newOrder
            newNamespaceDict[updateSortId] = newOrder
          } else if (parentId) {
            newNamespaceDict[parentId] = newOrder
          } else if (updateSortId) {
            newNamespaceDict[updateSortId] = newOrder
          } else if (orderBy && columnId && ([...Object.keys(state.namespaceOrderByField[namespace] ?? {})].length > 1) && (columnId in state.namespaceOrderByField[namespace])) {
            // Handling for when parentId may not be available - not currently used.
            for (const otherParentId in Object.keys(state.namespaceOrderByField[namespace])) {
              newNamespaceDict[otherParentId] = newOrder
            }
          }
          if (parentId) {
            let targetParentId = parentId
            while (targetParentId) {
              const existingParent = state.entities[makeId(targetParentId, namespace)] ?? null
              console.debug('Checking for parent column ancestors', { parentId, targetParentId, existingParent, action })
              if (existingParent?.id) {
                newNamespaceDict[existingParent.sortId ?? existingParent.id] = newOrder
              }
              const hasNextParentId = (existingParent?.parentId && (existingParent.parentId !== targetParentId))
              targetParentId = hasNextParentId ? existingParent.parentId : null
            }
          }
        }
        state.namespaceOrderByField = { ...state.namespaceOrderByField, [namespace]: newNamespaceDict }
      } else {
        console.error('Missing required action params', { namespace, columnId, orderBy, action })
      }
    }
  }
})

export const { columnsLoaded, updateColumnCollapsed, updateColumnVisibility, batchUpdateColumns, updateColumnOrderBy } = tableColumnsSlice.actions

export default tableColumnsSlice.reducer

export const { selectAll: selectAllTableColumns, selectById: selectTableColumnById, selectIds: selectTableColumnIds, selectEntities: selectTableColumnMap } =
  tableColumnsAdapter.getSelectors(state => state.tableColumns)

export const selectTableColumnOrderBy = createSelector(
  [(state, namespace, sortId) => (state.tableColumns.namespaceOrderByField[namespace] ?? {})[sortId] ?? null],
  (orderBy) => orderBy?.order ?? (orderBy ? true : null)
)

export const selectTableColumnsByNamespace = createSelector(
  [selectAllTableColumns, (state, namespace) => namespace],
  (columns, namespace) => columns.filter(column => column.namespace === namespace)
)

export const selectTableColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectDefaultHiddenColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.defaultHidden).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectDefaultCollapsedColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.defaultCollapsed).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectHiddenColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.hidden).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectCollapsedColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.collapsed).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectActiveColumnIdsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => getColumnActive(column)).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectToggleColumnMenuColumnsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.showHideOption && !column.summaryColumnGroup),
  { memoizeOptions: { maxSize: 2 } }
)

export const selectToggleColumnMenuColumnIdsByNamespace = createSelector(
  [selectToggleColumnMenuColumnsByNamespace],
  (columns) => columns.map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectHiddenColumnMenuColumnIdsByNamespace = createSelector(
  [selectToggleColumnMenuColumnsByNamespace],
  (columns) => columns.filter(column => !!column.hidden).map(column => column.id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual, maxSize: 2 } }
)

export const selectToggleVisibilityColumnsByNamespace = createSelector(
  [selectTableColumnsByNamespace],
  (columns) => columns.filter(column => !!column.hideable && !column.summaryColumnGroup),
  { memoizeOptions: { maxSize: 2 } }
)

export const selectTableColumnActive = createSelector(
  [selectTableColumnById],
  (column) => getColumnActive(column)
)

export const selectTableColumnAccessor = createSelector(
  [selectTableColumnById],
  (column) => column?.accessor ?? null
)

export const selectTableColumnSpan = createSelector( // TODO use or remove
  [selectTableColumnById],
  (column) => column?.colSpan ?? column?.minColSpan ?? 3
)

export const selectTableColumnProperty = createSelector(
  [selectTableColumnById, (state, id, property) => property],
  (column, property) => column?.[property] ?? null
)
