import { Flex, Tabs } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import AssessmentTable from './AssessmentTable'
import { BatteryTable } from './BatteryTable'
import CreateButtons from './CreateButtons'
import { useSearchParams } from 'react-router-dom'
import { parseAllSearchParamsToDict } from '../../core/useStableSearchParams';

export function AssessmentHome () {
  const [searchParams, setSearchParams] = useSearchParams()
  const paramTab = searchParams.get('tab') ?? 'assessments'
  const [activeTab, setActiveTab] = useState(paramTab)

  useEffect(() => {
    if (activeTab === paramTab) return
    setSearchParams((prev) => ({ ...(parseAllSearchParamsToDict(prev)), tab: activeTab }))
  }, [activeTab, paramTab, setSearchParams])

  const changeTab = (tab) => {
    console.debug('Changing tab.', tab)
    setActiveTab(tab)
    setSearchParams((prev) => ({ ...(parseAllSearchParamsToDict(prev)), tab }))
  }

  return (
    <Flex direction='column' gap='sm'>
      <Tabs value={activeTab} onChange={changeTab}>
        <Tabs.List>
          <Tabs.Tab value='assessments'>Assessments</Tabs.Tab>
          <Tabs.Tab value='batteries'>Batteries</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <CreateButtons context={activeTab} />
      {activeTab === 'assessments'
        ? <AssessmentTable />
        : <BatteryTable />
      }
    </Flex>
  )
}
