/**
 * @typedef {object} FilterPhoneNumberState
 * @property {Map.<int, int[]>} numberSegments segmentIndex => digitIndexes
 * @property {Map.<int, string|null>} digitValues digitIndex => ?char (single string value)
 * @property {?int} activeNumberSegment
 * @property {?int} activeDigit
 * @property {?string} value
 */

const maxNumberLength = 11
export const wildcardCharacter = '*'

/**
 * @param {?string} selected
 * @returns {FilterPhoneNumberState}
 */
export function createInitialFilterPhoneNumberState (selected = null) {
  const digitValues = initializeDigitValuesFromSelected(selected)
  const numberSegments = makeNumberSegments()
  const activeDigit = null
  const activeNumberSegment = null
  const value = initializeSelectedValue(selected)
  return {
    numberSegments,
    digitValues,
    activeNumberSegment,
    activeDigit,
    value
  }
}

/**
 * @param {?string} selected
 * @returns {Map.<int, string|null>}
 */
function initializeDigitValuesFromSelected (selected) {
  const digitValues = new Map()
  let i = 0
  if (selected) {
    for (const char of selected) {
      if (i >= maxNumberLength) {
        console.info(
          'Unexpected selected value length for filter phone number state selected parsing - truncating.',
          { maxNumberLength, selected, i }
        )
        break
      }
      digitValues.set(i, isNumericChar(char) ? char : wildcardCharacter)
      i += 1
    }
  }
  return makeDigitValues(digitValues, i)
}

/**
 * @param {string} c Single-length string
 * @returns {boolean}
 */
function isNumericChar (c) {
  return /^\d$/.test(c)
}

/**
 * @param {?Map.<int, string|null>} existingMap
 * @param {int} startDigit
 * @param {?int} customEndLength
 * @returns {Map.<int, string|null>}
 */
function makeDigitValues (existingMap = null, startDigit = 0, customEndLength = null) {
  const endLength = customEndLength ?? maxNumberLength
  const digitValues = existingMap ?? new Map()
  let i = startDigit
  while (i < endLength) {
    digitValues.set(i, null)
    i += 1
  }
  return digitValues
}

/**
 * @param {int[]} segmentLengths
 * @returns {Map<int, int[]>}
 */
export function makeNumberSegments (segmentLengths = [1, 3, 3, 4]) {
  let i = 0
  let digit = 0
  const numberSegments = new Map()
  for (const length of segmentLengths) {
    let currentLengthCounter = 0
    const currentDigits = []
    while (currentLengthCounter < length) {
      if (digit >= maxNumberLength) {
        console.error(
          'Segment lengths exceeded max number length. Additional changes in state initialization required.',
          { i, digit, segmentLengths, maxNumberLength }
        )
        break
      }
      currentDigits.push(digit)
      digit += 1
      currentLengthCounter += 1
    }
    numberSegments.set(i, currentDigits)
    i += 1
  }
  return numberSegments
}

/**
 * @param {?string} selected
 * @returns {string|null}
 */
function initializeSelectedValue (selected) {
  const newSelected = selected ? replaceNonNumbersWithWildcard(selected, maxNumberLength) : null
  if (!newSelected || allCharactersSame(newSelected, wildcardCharacter)) {
    return null
  }
  return newSelected
}

/**
 * @param {string} selected
 * @param {int} maxLength
 */
function replaceNonNumbersWithWildcard (selected, maxLength) {
  return selected.slice(0, maxLength).replace(/\D/g, wildcardCharacter)
}

export function allCharactersSame (s, testValue = null) {
  return s?.split('').every(c => c === (testValue ?? s[0]))
}

export const FilterPhoneNumberUpdate = Object.freeze({
  SetDigitValue: 'set-digit-value',
  ClearSelectedValue: 'clear-selected-value',
  ReplaceSelectedValue: 'replace-selected-value',
  FocusDigit: 'focus-digit'
})

function calculateNextActiveDigit (allNumberSegments, digit, numberSegment, segmentsDigits) {
  if (segmentsDigits.includes(digit + 1)) {
    return { activeDigit: digit + 1 }
  } else if (allNumberSegments.has(numberSegment + 1)) {
    return { activeDigit: digit + 1, activeNumberSegment: numberSegment + 1 }
  }
  console.debug('Reached end of number segments - clearing focus', { numberSegment, digit, allNumberSegments })
  return { activeDigit: null, activeNumberSegment: null }
}

/**
 * @param {*&FilterPhoneNumberState} state
 * @param {object} action
 * @param {FilterPhoneNumberUpdate} action.type
 * @param {int|undefined} action.digit
 * @param {int|undefined} action.numberSegment
 * @param {string|undefined} action.value
 * @returns {*&ExportState}
 */
export function filterPhoneNumberReducer (state, action) {
  switch (action.type) {
    case FilterPhoneNumberUpdate.SetDigitValue: {
      console.debug('Updating phone number filter digit value', { state, action })
      const numberSegment = action.numberSegment ?? -1
      const digit = action.digit ?? -1
      const segmentsDigits = state.numberSegments.get(numberSegment)
      if (!segmentsDigits || !segmentsDigits.includes(digit)) {
        console.error(
          'Incorrect number segment or digit index provided in state digit value update - skipping.',
          { numberSegment, digit, segmentsDigits, state, action }
        )
        return state
      }
      const value = action.value ? replaceNonNumbersWithWildcard(action.value, 1) : null
      if (value === (state.digitValues.get(digit) ?? null)) {
        if (digit === state.activeDigit) {
          console.debug(
            'Updating phone number filter digit value to pre-existing value, only updating focus.',
            { value, state, action }
          )
          return { ...state, ...calculateNextActiveDigit(state.numberSegments, digit, numberSegment, segmentsDigits) }
        } else {
          console.debug(
            'State update for unfocused digit setting same value - skipping.',
            { value, state, action }
          )
          return state
        }
      }
      const tempDigitValues = new Map(state.digitValues)
      tempDigitValues.set(digit, value)
      const currentSelectedLength = (state.value?.length ?? 0)
      const newSelectedLength = Math.max(digit + 1, currentSelectedLength)
      const tempSelected = []
      let selectedIndex = 0
      while (selectedIndex < newSelectedLength) {
        tempSelected.push(tempDigitValues.get(selectedIndex) ?? wildcardCharacter)
        selectedIndex += 1
      }
      const newSelected = replaceNonNumbersWithWildcard(tempSelected.join(''), maxNumberLength)
      const newDigitValues = initializeDigitValuesFromSelected(newSelected)

      console.debug('New filter phone number selected value', { newSelected, action })
      return {
        ...state,
        ...calculateNextActiveDigit(state.numberSegments, digit, numberSegment, segmentsDigits),
        digitValues: newDigitValues,
        value: newSelected
      }
    }
    case FilterPhoneNumberUpdate.ClearSelectedValue: {
      console.debug('Clearing selected phone number filter', { state, action })
      return {
        ...state,
        digitValues: initializeDigitValuesFromSelected(null),
        activeNumberSegment: 0,
        activeDigit: 0,
        value: null
      }
    }
    case FilterPhoneNumberUpdate.ReplaceSelectedValue: {
      console.debug('Replacing selected phone number filter', { state, action })
      const value = initializeSelectedValue(action.value)
      return {
        ...state,
        digitValues: initializeDigitValuesFromSelected(value),
        activeNumberSegment: 0,
        activeDigit: 0,
        value: value
      }
    }
    case FilterPhoneNumberUpdate.FocusDigit: {
      console.debug('Focusing phone number filter digit', { state, action })
      const numberSegment = action.numberSegment ?? -1
      const digit = action.digit ?? -1
      const segmentsDigits = state.numberSegments.get(numberSegment)
      if (!segmentsDigits || !segmentsDigits.includes(digit)) {
        console.error(
          'Incorrect number segment or digit index provided in state focus update - skipping.',
          { numberSegment, digit, segmentsDigits, state, action }
        )
        return state
      }
      if (digit === state.activeDigit) {
        console.debug(
          'State update for focused digit setting focus again - skipping.',
          { digit, numberSegment, state, action }
        )
        return state
      }
      return {
        ...state,
        activeNumberSegment: numberSegment,
        activeDigit: digit
      }
    }
    default: {
      console.error('Unknown filter phone number state update action type - skipping update.', action, state)
      return state
    }
  }
}
