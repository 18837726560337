import { ApiMethods, apiSlice, ApiTags, providesList } from '../../../react/api'

export const hiringTemplatesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHiringTemplate: builder.query({
      query: id => `hiring-templates/${id}`,
      providesTags: (result, error, id) => {
        if (typeof result === 'undefined') {
          return [{ type: ApiTags.HiringTemplates, id: id }];
        }

        return [
          { type: ApiTags.HiringTemplates, id: id },
          ...result.triggers.map(trigger => ({ type: ApiTags.Triggers, id: trigger.id }))
        ]
      }
    }),
    getHiringTemplateEmailTemplates: builder.query({
      query: id => `hiring-templates/${id}/email-templates`,
      providesTags: (result, error, id) => [
        { type: ApiTags.HiringTemplates, id: id },
        ...providesList(result, ApiTags.EmailTemplates)
      ]
    }),
    addHiringTemplateBattery: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `hiring-templates/${id}/batteries`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }]
    }),
    editHiringTemplateBattery: builder.mutation({
      query: ({ id, batteryId, ...body }) => ({
        url: `hiring-templates/${id}/batteries/${batteryId}`,
        method: ApiMethods.Patch,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }]
    }),
    deleteHiringTemplateBattery: builder.mutation({
      query: ({ id, batteryId }) => ({
        url: `hiring-templates/${id}/batteries/${batteryId}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }]
    }),
    toggleHiringTemplateBattery: builder.mutation({
      query: ({ id, batteryId }) => ({
        url: `hiring-templates/${id}/batteries/${batteryId}/toggle`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }],

      /**
       * This function assumes the toggling will succeed in order to update the toggle button quicker.
       * See: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      onQueryStarted: async ({ id, batteryId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getHiringTemplate', id, draft => {
            const linkedBattery = draft.hiring_template_batteries.find(linkedBattery => linkedBattery.id === batteryId)

            if (linkedBattery) {
              linkedBattery.active = !linkedBattery.active
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteHiringTemplateTrigger: builder.mutation({
      query: ({ id, triggerId }) => ({
        url: `hiring-templates/${id}/triggers/${triggerId}`,
        method: ApiMethods.Delete
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }]
    }),
    toggleHiringTemplateTrigger: builder.mutation({
      query: ({ id, triggerId }) => ({
        url: `hiring-templates/${id}/triggers/${triggerId}/toggle`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringTemplates, id: id }],
      /**
       * This function assumes the toggling will succeed in order to update the toggle button quicker.
       * See: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      onQueryStarted: async ({ id, triggerId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getHiringTemplate', id, draft => {
            const trigger = draft.triggers.find(trigger => trigger.id === triggerId)

            if (trigger) {
              trigger.enabled = !trigger.enabled
            }
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  })
})

export const {
  useGetHiringTemplateQuery,
  useGetHiringTemplateEmailTemplatesQuery,
  useAddHiringTemplateBatteryMutation,
  useEditHiringTemplateBatteryMutation,
  useDeleteHiringTemplateBatteryMutation,
  useToggleHiringTemplateBatteryMutation,
  useDeleteHiringTemplateTriggerMutation,
  useToggleHiringTemplateTriggerMutation
} = hiringTemplatesApi
