import React, { useMemo } from 'react'
import { formatToOptions } from '../../js/util/DataUtil'
import { IconAffiliate } from '@tabler/icons-react'
import { useOrganizations } from '../util/OrganizationHooks';

export function useOrganizationsFilter ({ withLocation, ...props }) {
  const organizations = useOrganizations()
  const options = useMemo(() => {
    return formatToOptions(organizations)
  }, [organizations])

  return {
    id: 'organizations',
    label: 'Organizations',
    options: options,
    resets: ['locations'],
    leftSection: <IconAffiliate />,
    badgeProps: {
      label: 'Organization'
    },
    ...props
  }
}
