import React from 'react'
import {
  getCycleBatteryLastSubmissions,
  getCycleTemplates,
  getCycleTextTemplates
} from '../../../js/api/cycle_repository'
import ApplicantPanel from './ApplicantPanel'
import Form from './Form'
import { Grid, SimpleGrid } from '@mantine/core'
import { useGetCycleQuery } from '../../../redux/query/hire/cyclesApi.slice'
import { useParams } from 'react-router-dom'
import { useGetApplicantQuery } from '../../../redux/query/hire/applicantsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'

export default function ContactApplicantApp () {
  const { cycleId: id, applicantId } = useParams()
  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: applicant, error: applicantError, isLoading: applicantLoading } = useGetApplicantQuery(applicantId)

  const filterCategories = (template) => {
    return template.category?.tag !== 'boost-invite' && template.category?.tag !== 'prescreen-completed'
  }

  if (applicantError || cycleError) {
    return <ErrorLoading />
  }

  if (cycleLoading || applicantLoading) {
    return <Loading />
  }

  return (
    <Grid>
      <Grid.Col sm={12} lg={10}>
        <SimpleGrid cols={1}>
          <ApplicantPanel name={applicant.first_name} email={applicant.contact_information.email} />
          <Form
            getTemplates={() => getCycleTemplates(cycle.id, filterCategories)}
            getTextTemplates={() => getCycleTextTemplates(cycle.id)}
            getSubmissions={batteryId => getCycleBatteryLastSubmissions(cycle.id, batteryId)}
            getBatteries={() => Promise.resolve({ data: cycle.cycle_batteries.filter(linkedBattery => linkedBattery.active).map(linkedBattery => linkedBattery.cycle) })}
            applicants={[applicant.id]}
            previewApplicant={applicant.id}
            onSuccessRedirect={`/cycles/${id}/results`}
          />
        </SimpleGrid>
      </Grid.Col>
    </Grid>
  )
}
