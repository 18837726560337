import React from 'react'
import BatteryManagement from '../batteries/BatteryManagement'
import { useParams } from 'react-router-dom'
import {
  useAddCycleBatteryMutation,
  useDeleteCycleBatteryMutation,
  useEditCycleBatteryMutation,
  useGetCycleQuery,
  useToggleCycleBatteryMutation
} from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'

export default function CycleBatteryManagement () {
  const { id } = useParams()

  const { data: cycle, error, isLoading } = useGetCycleQuery(id)

  const [addBattery] = useAddCycleBatteryMutation()
  const [editBattery] = useEditCycleBatteryMutation()
  const [deleteBattery] = useDeleteCycleBatteryMutation()
  const [toggleBattery] = useToggleCycleBatteryMutation()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <BatteryManagement
      linkedBatteries={cycle.cycle_batteries}
      triggers={cycle.triggers}
      addBattery={json => addBattery({ id, ...json }).unwrap()}
      editBattery={(batteryId, json) => editBattery({ id, batteryId, ...json }).unwrap()}
      deleteBattery={batteryId => deleteBattery({ id, batteryId }).unwrap()}
      toggleBattery={batteryId => toggleBattery({ id, batteryId }).unwrap()}
      reviewBattery={batteryId => window.open(`/proctorfree/cycles/${id}/batteries/${batteryId}/review`, '_blank')}
    />
  )
}
