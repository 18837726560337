/* eslint-disable react/prop-types */
import { Anchor, Box, Button, Container, Paper, SimpleGrid, Text, Title } from '@mantine/core'
import React from 'react'

export default function Home () {
  const BviCard = ({ children, href, title }) => {
    return (
      <Paper ta='center' display='flex' style={{ flexDirection: 'column' }}>
          <Title>{title}</Title>
          <Box style={{ flexGrow: 1 }}>
            {children}
          </Box>
        <Button component={Anchor} href={href} variant='light' color='blue' size='lg' mt='xs'>View {title}</Button>
      </Paper>
    )
  }

  return (
    <Container size='xl'>
      <Container ta='center'>
        <Title>BVI</Title>
        <Text>The Big Five Index measures an applicant&apos;s survey score compared to the ideal candidate for a job and calculates their Personality Fit Index to see if their personality is right for the job.</Text>
      </Container>
      <SimpleGrid cols={{ md: 3, sm: 1 }} mt='sm'>
        <BviCard title='Surveys' href='/hire/setup/bvi/surveys'>
          <Text>A BVI Survey is a test given to an applicant to get their score for calculating their Personality Fit Index.</Text>
        </BviCard>
        <BviCard title='Profiles' href='/bvi/profiles'>
          <Text>A BVI Profile contains the weight information that is different for evey job. This is what we compare applicant scores to calculate their Personality Fit Index.</Text>
        </BviCard>
        <BviCard title='Batteries' href='/bvi/batteries'>
          <Text>A Battery is a group of assessments used for calculating Cognitive Index.</Text>
        </BviCard>
      </SimpleGrid>
    </Container>

  )
}
