/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { ActionIcon, Button, Flex, Loader, NumberInput, Paper, Select, Stack, Textarea, Tooltip, UnstyledButton } from '@mantine/core'
import BulletChart, { getChartData, getZoneString } from '../../../../js/charts/BulletChart'
import styles from './ScoreApp.module.scss'
import { Chart } from 'chart.js'
import { IconDeviceFloppy, IconX } from '@tabler/icons-react'
import { useForm } from '@mantine/form'
import { useWindowEvent } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'

export default function ScoreCard ({
  info,
  wordings,
  onSubmit,
  onChartClick,
  onDelete,
  onUpdateWording
}) {
  const chartRef = useRef()
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(info.id.toString().includes('new-'))

  const form = useForm({
    initialValues: {
      importance: info.importance,
      name: info.name ?? '',
      notes: info.notes ?? '',
      zones: getZoneString(info.zones)
    },
    validate: (values) => ({
      name: values.name.length === 0 ? 'Must have a name' : null,
      importance: values.importance < 0 ? 'Must be greater than zero' : null
    })
  })

  const remove = () => {
    if (info.id.toString().includes('new-')) {
      onDelete(info.id)
      return
    }
    if (confirm(`do you want to delete ${info.name ?? 'this score'}?`)) {
      onDelete(info.id, () => {
        showNotification({
          title: 'Score successfully deleted',
          color: 'green',
          message: `${info.name ?? 'Score'} was deleted`
        })
      })
    }
  }

  const submit = (values) => {
    setLoading(true)
    onSubmit(info.id, values, (error, response) => {
      if (!error) {
        showNotification({
          title: 'Score successfully updated',
          message: `Successfully ${response.context}ed ${info.name} of ${response.batteryName} battery`,
          color: 'green'
        })
      } else {
        showNotification({
          title: 'Score update failed',
          message: `Failed to update ${info.name} of ${response} battery`,
          color: 'red'
        })
      }
      setLoading(false)
      setIsNew(false)
      form.resetDirty()
    })
  }

  const updateWording = (val) => {
    form.setFieldValue(val)
    onUpdateWording(info.id, val)
    form.getInputProps('name').onChange(val)
  }

  const initChart = () => {
    const existingChart = Chart.getChart(chartRef.current)
    if (existingChart !== undefined) {
      existingChart.destroy()
    }

    if (!chartRef.current) return
    return new BulletChart(
      chartRef.current,
      getChartData(info.zones, info.name ?? '')
    )
  }

  useEffect(() => {
    if (isNew) {
      form.setDirty({ name: true })
      initChart()
    }
  }, [])

  useEffect(() => {
    initChart()

    form.setFieldValue('importance', info.importance)
    form.setFieldValue('name', info.name ?? '')
    form.setFieldValue('notes', info.notes ?? '')
    form.setFieldValue('zones', getZoneString(info.zones))
    form.getInputProps('zones').onChange(getZoneString(info.zones))
    setIsNew(info.id.toString().includes('new-'))
  }, [info])

  useWindowEvent('beforeunload', (event) => {
    if (form.isDirty()) {
      event.preventDefault()
      event.returnValue = ''
    }
  })

  useWindowEvent('batteryinfo:saveall', () => {
    form.resetDirty()
    setIsNew(false)
  })

  return (
    <Paper
      shadow='md'
      p='xs'
      className={[isNew && styles.newCard, styles.scoreCard]}
    >
      <form onSubmit={form.onSubmit((values) => submit(values))}>
        <Flex justify='flex-end' mb='sm'>
          <Tooltip label='Remove'>
            <ActionIcon color='gray' variant='danger-subtle' onClick={() => remove()}><IconX /></ActionIcon>
          </Tooltip>
        </Flex>
        <Stack>
          <Select
            data={wordings.map(wording => {
              return { label: wording, value: wording }
            })}
            placeholder='Select a name'
            {...form.getInputProps('name')}
            onChange={updateWording}
          />
              { info.zones.length > 0
                ? <Flex direction='column' align='stretch' gap='xs'>
                    <Tooltip label='Click to edit zones'>
                      <UnstyledButton onClick={() => onChartClick(info)}>
                        <canvas
                          ref={chartRef}
                          id={`bulletchart-${info.id}`}
                          width='100%'
                          height='20'
                          style={{ borderRadius: '0.25rem' }}
                        ></canvas>
                      </UnstyledButton>
                    </Tooltip>
                  </Flex>
                : <Button onClick={() => onChartClick(info)}>Define zones</Button>
              }
          <Flex w={'50%'}>
            <NumberInput
              label='Importance'
              min={0}
              max={10}
              onChange={(val) => form.setFieldValue('importance', val)}
              {...form.getInputProps('importance')}
            />
          </Flex>
          <Flex direction='column' align='stretch'>
            <Textarea
              onChange={(e) => form.setFieldValue(e.currentTarget.value)}
              placeholder='Notes' width='100%'
              {...form.getInputProps('notes')}
            />
          </Flex>
          <Flex justify='flex-end'>
            <Button
              color='green'
              type='submit'
              disabled={!form.isDirty()}
            >
              {loading
                ? <Loader color='white' size='sm' />
                : <IconDeviceFloppy />
              }
            </Button>
          </Flex>
        </Stack>
        <input type='hidden' data-infoid={info.id} {...form.getInputProps('zones')}/>
      </form>
    </Paper>
  )
}
