export default class StringUtil {
  static makeId () {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length

    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return 'new-' + result
  }

  static shortContent (content, maxLen = 120) {
    const c = StringUtil.stripHtml(content)

    if (c.length > maxLen) {
      return c.substr(0, maxLen - 3) + '...'
    }

    return c
  }

  static stripHtml (html) {
    const tmp = document.createElement('div')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }
}
