/* eslint react/prop-types: 0 */
import {
  Box,
  Button,
  Center,
  Collapse,
  Group,
  Space,
  Text,
  Title
} from '@mantine/core';
import React, { useState } from 'react';
import { StageProgressesTable } from '../progresses/StageProgressesTable';
import { generatePath, Link, useParams } from 'react-router-dom';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { LockableIcon } from '../summaries/LockableIcon';
import { CycleSummaryCard } from '../summaries/CycleSummaryCard';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { useParsedPassActivity } from '../CycleInviteHooks';
import { passMetadataCallback, StageActivitySummary } from './CycleStages';
import { renderDateFromSource } from '../formatUtil';
import { useStageDetail } from './CycleStagesHooks';
import { useIsAdmin } from '../CyclesHooks';
import { useCycle } from '../../CycleHooks';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';

export function CycleStageDetail () {
  const { cycleId, stageId } = useParams()
  const [stage, stageQuerying] = useStageDetail(stageId, cycleId, !stageId)
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()
  const querying = !!(stageQuerying || adminLoading || cycleQuerying)
  console.debug('Cycle stage detail updating', { cycleId, cycle, stage, isAdmin, querying })
  return (
    <>
      {!!querying && <CardLoadingPlaceholder />}
      {!querying && !stage && <CardQueryError />}
      {!querying && !!stage && <CycleStageCard stage={stage} cycle={cycle} isAdmin={isAdmin} />}
      {!querying && !!stage && <CycleStageProgressesTable cycleId={cycleId} stageId={stage.id} />}
    </>
  )
}

function CycleStageCard ({ stage, cycle, isAdmin = false }) {
  // TODO [cycle pass full features] toggle lock mutation for cycle stages
  const cycleId = cycle.id
  const stagesLink = !(cycleId) ? '/' : generatePath(CyclePassRoute.CycleStages, { cycleId })
  const [firstStart, lastActivity, lastSubmit, metadata] = useParsedPassActivity(stage, passMetadataCallback)
  const stageNumber = (stage.index ?? -1) + 1
  console.debug('Cycle stage card updating', { stage, isAdmin, stagesLink })
  return (
    <>
      <Title>{stage.stage_replacement ? 'Replaced ' : ''}Cycle Stage #{stageNumber || 'Number Missing'} (Id {stage.id ?? 'Id missing'}) Detail</Title>
      <Space h='md' />
      <Box>
        <Group>
          <LockableIcon item={stage} />
          <Button variant='subtle' size='sm' component={Link} to={stagesLink}>All Stages</Button>
        </Group>
        <Space h='md' />
        {!!lastActivity && (
          <Text c="dimmed" size="sm">
            {firstStart ? `First Start: ${renderDateFromSource(firstStart.created_at, null, true)} ` : ''}{lastSubmit ? `Last Submitted: ${renderDateFromSource(lastSubmit.created_at, null, true)} ` : ''} Last Activity Seen: {renderDateFromSource(lastActivity.created_at, null, true)}
          </Text>
        )}
        {!lastActivity && (
          <Text c="dimmed" size="sm">
            No activity for stage
          </Text>
        )}
        <Space h='xs' />
        {!!metadata?.starts?.size && <StageActivitySummary stage={stage} cycle={cycle} metadata={metadata} isAdmin={isAdmin} />}
        <Space h='xl' />
        <Center>
          {!!cycle && <CycleSummaryCard cycle={cycle} isAdmin={isAdmin} />}
        </Center>
        <Space h='xl' />
        <PassActivityTabs activityTarget={stage} activityTargetType='Stage' isAdmin={isAdmin} />
      </Box>
    </>
  )
}

function CycleStageProgressesTable ({ cycleId, stageId }) {
  console.debug('Cycle stage progresses table updating', stageId)
  const [open, setOpen] = useState(false)
  const [showButton, setShowButton] = useState(!open)
  return (
    <>
      <Space h='xl' />
      <Collapse in={open} onTransitionEnd={() => setShowButton(!open)}>
        <StageProgressesTable cycleId={cycleId} stageId={stageId} namespace='stages-progresses' />
      </Collapse>
      {!!showButton && <Button onClick={() => setOpen(true)}>View Progress Table</Button>}
    </>
  )
}
