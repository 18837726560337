/* eslint react/prop-types: 0 */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  AspectRatio,
  Button,
  Grid,
  Group,
  Select,
  SimpleGrid
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useNavigate, useParams } from 'react-router-dom'
import { editMedia } from '../../../js/api/media_repository'
import { formatToOptions } from '../../../js/util/DataUtil'
import MediaAssessmentUsage from './MediaAssessmentUsage'
import { mediaTypeIdToTypes, useMedia } from './MediaHooks'
import RatioInputs from './RatioInputs'
import DescriptionInput from '../../forms/DescriptionInput'
import NameInput from '../../forms/NameInput'
import { getMimeTypes, MediaType } from '../../../js/generated/enums/MediaType';

export default function MediaEdit ({ onSuccess, onCancel }) {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleSuccess = useCallback(() => onSuccess(navigate), [onSuccess, navigate])
  const handleCancel = useCallback(() => onCancel(navigate), [onCancel, navigate])

  return (
    <MediaEditor id={id} onSuccess={handleSuccess} onCancel={handleCancel} />
  )
}

export function MediaEditor ({ id, onSuccess, onCancel }) {
  const media = useMedia(id)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      identifier: '',
      description: '',
      type: '0',
      width: '',
      height: ''
    },
    validate: {
      identifier: isNotEmpty('Name cannot be empty'),
      width: (value, values) => mediaTypeIdToTypes[values.type] === MediaType.Image && (value === null || isNaN(value) || value < 1) ? 'Invalid width' : null,
      height: (value, values) => mediaTypeIdToTypes[values.type] === MediaType.Image && (value === null || isNaN(value) || value < 1) ? 'Invalid height' : null
    },
    transformValues: values => ({
      identifier: values.identifier,
      description: values.description,
      type: parseInt(values.type),
      width: mediaTypeIdToTypes[values.type] === MediaType.Image ? Math.round(values.width) : null,
      height: mediaTypeIdToTypes[values.type] === MediaType.Image ? Math.round(values.height) : null
    })
  })

  const formRef = useRef(form) // Not for use while rendering.

  useEffect(() => {
    formRef.current = form
  }, [form])

  useEffect(() => {
    formRef.current.setValues({
      identifier: media?.identifier ?? '',
      description: media?.description ?? '',
      type: media?.type.toString() ?? '0',
      width: media?.width ?? '',
      height: media?.height ?? ''
    })
    formRef.current.resetDirty()
  }, [media])

  const submit = values => {
    setIsSubmitting(true)

    editMedia(id, values)
      .then(() => {
        showNotification({
          message: 'The media was successfully edited!',
          color: 'success',
          autoClose: 3000
        })
        onSuccess()
      })
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error editing this media',
            color: 'red'
          })
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  const resize = (width = media.width, height = media.height) => {
    form.setFieldValue('width', width)
    form.setFieldValue('height', height)
  }

  return (
    <>
      <form onSubmit={form.onSubmit(values => submit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <SimpleGrid cols={1}>
              <NameInput form={form} path='identifier' required />
              <Select
                label='Type'
                data={formatToOptions(mediaTypeIdToTypes)}
                {...form.getInputProps('type')}
                required
              />
              <DescriptionInput form={form} />
            </SimpleGrid>
          </Grid.Col>
          <Grid.Col span={6}>
            {media && <MediaAssessmentUsage media={media} />}
          </Grid.Col>
          <Grid.Col span={12}>
          <Group gap='xs' mb='xs'>
              <Button type='submit' color='success' loading={isSubmitting}>Save</Button>
              <Button color='gray.6' onClick={() => onCancel() }>Cancel</Button>
          </Group>
          {
            media &&
            (mediaTypeIdToTypes[media.type] === MediaType.Image
              ? <RatioInputs width={form.values.width ? form.values.width : 1} height={form.values.height ? form.values.height : 1} onChange={resize} file={media.link} h='40rem' />
              : (
                <AspectRatio maw={400} ratio={16 / 9}>
                  <video>
                    <source src={media.link} type={getMimeTypes(MediaType.Video).shift()}/>
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
                )
            )
        }
          </Grid.Col>
        </Grid>
      </form>
    </>
  )
}
