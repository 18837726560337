import React, { useEffect, useState } from 'react'
import { Button, Container, Grid, Group, Select, Stack, Text, Tooltip } from '@mantine/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Error from '../../forms/Error'
import { getOrganizations } from '../../../js/api/organization_repository'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { upload } from '../../../js/api/assessment_repository'
import { IconHelp, IconFileUpload } from '@tabler/icons-react'

export default function ImporterApp () {
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    getOrganizations({ limit: 0 }).then(data => setOrganizations(data.items))
  }, [])

  const form = useForm({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      organization: null,
      file: null,
      test: 1,
      rescale: false
    }
  })

  const { control } = form

  const help = () => {
    window.open('https://stangds.atlassian.net/wiki/spaces/ops/pages/1736441857/Assessment+Upload+File+Format', '_blank')
  }

  const onSubmit = data => {
    data.rescale = data.rescale ? 1 : 0

    upload(data)
      .then(() => {
        window.location.href = '/build/assessments/import'
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
          _.forEach(err.response.data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }

  return (
    <Container fluid px='xs'>
      <Grid>
        <Grid.Col span={12}>
          <Text size='md'>This form allows you to create assessments from an import file directly.</Text>
        </Grid.Col>
      </Grid>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Col span={4}>
              <input type='text' placeholder='Job Title (If applicable)' {...form.register('name')} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Error name='name'/>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Controller
                name='organization'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    maxDropdownHeight={280}
                    clearable
                    searchable
                    placeholder='Organization'
                    data={
                      organizations.map(organization => {
                        return { value: organization.id.toString(), label: organization.name }
                      })
                    }
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Error name='organization'/>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <input type='file' {...form.register('file', { required: 'A file is required' })} accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'/>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Error name='file'/>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <label>Test Run</label>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={4}>
              <Stack spacing='xs'>
                <label><input style={{ margin: 0 }} type='radio' {...form.register('test', { required: true })} value={1} defaultChecked={true}/> Yes</label>
                <label><input type='radio' {...form.register('test', { required: true })} value={0} /> No</label>
              </Stack>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <input type='checkbox' {...form.register('rescale')} /><label><Tooltip label='If this checkbox is checked, all answer scores will be rescaled between 0 and 1 points.'><Text>Rescale questions</Text></Tooltip></label>
            </Grid.Col>
          </Grid>
          <Group spacing='xs'>
            <Button color='blue.6' leftSection={<IconFileUpload size={18} />} type='submit'>Import</Button>
            <Button color='blue.6' leftSection={<IconHelp size={18} />} onClick={() => help()}>Help</Button>
            <Button color='gray.6' onClick={() => window.history.back()}>Cancel</Button>
          </Group>
        </form>
      </FormProvider>
    </Container>
  )
}
