/* eslint react/prop-types: 0 */
import { DateInput } from '@mantine/dates'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function DateSelection ({ form, index }) {
  return (
    <DateInput
      label='Sending date'
      withAsterisk
      popoverProps={{ withinPortal: false }}
      {...form.getInputProps(`messages.${index}.send_at`)}
      minDate={dayjs().add(1, 'day').toDate()}
      maxDate={dayjs().add(1, 'year').toDate()}
    />
  )
}
