/* eslint react/prop-types: 0 */
import React, { useContext, useMemo } from 'react'
import ReactTable, { ReactTableContextsProvider } from '../core/ReactTable/ReactTable'
import { useAnalyticsTable, useHiringAnalyticsQuery } from './AnalyticsHooks.js'
import { useAccount } from '../util/Hooks'
import NoticeAlert from '../core/Alert/NoticeAlert'
import { NamespaceContext, TableDataContext } from '../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../core/ReactTable/paramsSlice';

const namespace = 'hiringAnalytics'

export default function AnalyticsTable () {
  const account = useAccount()
  const tableProps = useAnalyticsTable(account)
  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = tableProps

  return (
    <>
      {
        account.access.PORTAL_CLIENT &&
        <NoticeAlert title='Table Information'>
          This table shows you the number of Locations and Cycles that were created each year for your Organization as
          well as the number of applicants that applied to all your Organization&apos;s positions for each year.
        </NoticeAlert>
      }
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <AnalyticsTableDataProvider>
          <ReactTable {...otherTableProps} />
        </AnalyticsTableDataProvider>
      </ReactTableContextsProvider>
    </>
  )
}

export function AnalyticsTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useHiringAnalyticsQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating AnalyticsTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Analytics table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
