import axios from 'axios'

export async function contact (json) {
  return axios({
    method: 'post',
    url: '/api/v1/emails/contact',
    data: json
  })
    .then(() => true)
    .catch(function (error) {
      console.error(error)
      return false
    })
}
