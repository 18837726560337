import React, { useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import Error from '../../../../forms/Error'
import { groupStatuses } from '../../../../../util/hire/status.util'
import { Select } from '@mantine/core'
import { IconUserHeart } from '@tabler/icons-react'
import PropTypes from 'prop-types'

const defaultStatuses = []

export default function UpdateStatusConsequent ({ statusSet }) {
  const { control } = useFormContext()

  const { field: { onChange, onBlur, name, value } } = useController({
    name: 'metadata.update_applicant_status',
    control: control,
    rules: { required: 'A status must be selected.' }
  })

  const groupedStatuses = useMemo(() => (
    groupStatuses(statusSet?.statuses ?? defaultStatuses)
  ), [statusSet])

  return (
    <>
      <Select
        leftSection={<IconUserHeart />}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder='Pick status'
        data={groupedStatuses}
        searchable
        clearable
      />
      <Error name={'metadata.update_applicant_status'} />
    </>
  )
}

UpdateStatusConsequent.propTypes = {
  statusSet: PropTypes.object
}
