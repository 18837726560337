import { MantineProvider } from '@mantine/core'
import React from 'react'
import { theme } from './Theme'
import { Provider } from 'react-redux'
import { setupStore } from '../store'
import PropTypes from 'prop-types'

const store = setupStore()

export default function Base ({ children }) {
  return (
    <Provider store={store}>
      <MantineProvider withCSSVariables withNormalizeCSS theme={theme}>
        {children}
      </MantineProvider>
    </Provider>
  )
}

Base.propTypes = {
  children: PropTypes.node
}
