import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { QueryState } from './util';
import { previousQuestionAnswered, fetchPublishedAssessmentQuestions } from './assessmentsSlice';

/**
 * @type {EntityAdapter<{PublishedAssessmentBlock}, string>}
 */
const blocksAdapter = createEntityAdapter()

const initialState = blocksAdapter.getInitialState({
  status: QueryState.Idle,
  error: null
})

const blocksSlice = createSlice({
  name: 'blocks',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('blocks slice builder fulfilled', state, action)
        blocksAdapter.upsertMany(state, action.payload.blocks)
      })
      .addCase(previousQuestionAnswered, (state, action) => {
        const { blockId } = action.payload
        const existingBlock = state.entities[blockId]
        console.debug('Checking if new block should be shown from previous question being answered.', action.payload, blockId, existingBlock)
        if (existingBlock) {
          if (!existingBlock.show) {
            existingBlock.show = true
          }
        }
      })
  }
})

// export const { } = blocksSlice.actions

export default blocksSlice.reducer

export const { selectAll: selectAllBlocks, selectById: selectBlockById, selectIds: selectBlockIds } =
  blocksAdapter.getSelectors(state => state.blocks)

export const selectBlockIdsByPageId = createSelector(
  [selectAllBlocks, (state, pageId) => pageId],
  (blocks, pageId) => blocks.filter(block => block.pageId === pageId).map(block => block.id)
)
