/* eslint react/prop-types: 0 */
import { useDisclosure } from '@mantine/hooks';
import { useIsAdmin } from '../CyclesHooks';
import { notifications } from '@mantine/notifications';
import { Button, Group, Modal } from '@mantine/core';
import { IconLock, IconLockOff } from '@tabler/icons-react';
import React, { memo } from 'react';
import WarningAlert from '../../../../core/Alert/WarningAlert';

export const ToggleLockButton = memo(function ToggleLockButton ({ toggleLock, toggleLockProcessing, cycleId, itemId, itemIdName = 'id', locked = true }) {
  const [opened, { open, close }] = useDisclosure(false)
  const [isAdmin] = useIsAdmin()

  const actionString = locked ? 'Unlock' : 'Lock'
  const changeLockSetting = () => {
    const newLockedSetting = !locked
    toggleLock({ [itemIdName]: itemId, cycleId: cycleId, unlock: !!locked })
      .unwrap()
      .then(response => {
        console.debug('Got lock toggle response', response)
        notifications.show(
          {
            color: 'green',
            title: 'Lock Toggle Success',
            message: `Successfully ${newLockedSetting ? 'locked' : 'unlocked'}!`,
            autoClose: 8000
          }
        )
        close()
      })
      .catch(e => {
        console.error('Post lock toggle error response', { error: e, [itemIdName]: itemId })
        notifications.show(
          {
            color: 'red',
            title: 'Lock Toggle Error',
            message: 'There was an error changing the lock setting!',
            autoClose: 8000
          }
        )
      })
  }
  return (
    <>
      {!!isAdmin && <Button rightSection={locked ? <IconLockOff size={14} /> : <IconLock size={14} />} onClick={open}>{actionString}</Button>}
      <Modal
        opened={opened}
        onClose={close}
        title={`Danger: Really ${actionString}?`}
      >
        <WarningAlert>Are you sure you want to {actionString.toLowerCase()} this item? This will immediately affect any related candidates.</WarningAlert>
        <Group justify='center' grow>
          <Button variant='light' color='blue' onClick={close}>
            Cancel
          </Button>
          <Button variant='filled' color='red' onClick={changeLockSetting} loading={toggleLockProcessing}>
            {actionString}
          </Button>
        </Group>
      </Modal>
    </>
  )
})
