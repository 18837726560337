/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { useCycleStages, useCycleStagesTable, useLazyCycleStages } from './CycleStagesHooks';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';

export const CycleStagesTable = memo(function CycleStagesTable ({ cycleId, namespace = 'cycles-stages' }) {
  const navigate = useNavigate()
  const [stages] = useLazyCycleStages(namespace, cycleId)

  const handleRowClicked = useCallback((row) => {
    console.debug('Stages table row clicked.', row)
    if (row === undefined) return true
    const stageId = row.dataset.rowid
    const intStageId = parseInt(stageId)
    const stage = (stages?.items?.filter(c => c.id === intStageId) ?? [])[0] ?? null
    if (!stage) {
      console.warn('Unable to find stage matching clicked id.', { stageId, cycleId, stage, stages })
      return true
    }
    console.debug('Navigating to stage detail', { stageId, cycleId, stage, row })
    const path = generatePath(CyclePassRoute.CycleStageDetail, { cycleId, stageId })
    console.debug('Generated path for stage', { path, stage })
    navigate(path)
  }, [stages, cycleId, navigate])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useCycleStagesTable()

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <CycleStagesTableDataProvider cycleId={cycleId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </CycleStagesTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function CycleStagesTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCycleStages(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating CycleStagesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Cycle stages table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
