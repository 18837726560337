/**
 * @param {URLSearchParams} oldSearchParams
 * @param {Object.<string: mixed>} newParamsDict
 * @returns {boolean}
 */
export function searchParamsChanged (oldSearchParams, newParamsDict) {
  const oldParams = {}
  oldSearchParams.forEach(function (item, key) {
    if (oldSearchParams.getAll(key).length > 1) {
      oldParams[key] = oldSearchParams.getAll(key)
    } else {
      oldParams[key] = item
    }
  })
  return (Object.entries(newParamsDict).join(',') !== Object.entries(oldParams).join(','))
}

/**
 * @param {Object.<string: mixed>} oldParamsDict
 * @param {Object.<string: mixed>} newParamsDict
 * @returns {boolean}
 */
export function paramsDictChanged (oldParamsDict, newParamsDict) {
  return (Object.entries(newParamsDict).join(',') !== Object.entries(oldParamsDict).join(','))
}

const excludedParams = ['limit', 'page', 'search', 'order_direction', 'order_by', 'tab']

/**
 * @param {URLSearchParams} searchParams
 * @param {boolean} includeTab
 * @returns {Object.<string: mixed>}
 */
export function getFiltersFromParams (searchParams, includeTab = false) {
  const filters = {}
  searchParams.forEach(function (value, key) {
    if (!excludedParams.includes(key) || (includeTab && key === 'tab')) {
      if (searchParams.getAll(key).length > 1) {
        filters[key] = searchParams.getAll(key)
      } else {
        filters[key] = value
      }
    }
  })
  return filters
}

export function getFiltersFromDict (paramsDict, includeTab = false) {
  const filters = {}
  Object.entries(paramsDict).forEach(function ([key, value]) {
    if (!excludedParams.includes(key) || (includeTab && key === 'tab')) {
      filters[key] = value
    }
  })
  return filters
}

export function areSetsEqual (setOne, setTwo) {
  const oneInTwo = [...setOne].filter((x) => setTwo.has(x))
  const twoInOne = [...setTwo].filter((x) => setOne.has(x))
  return (oneInTwo.length === setOne.size) && (twoInOne.length === setTwo.size)
}

export function getNonNullParamsFromDict (paramDict) {
  return Object.fromEntries(Object.entries(paramDict).sort(([aKey, aValue], [bKey, bValue]) => aKey.localeCompare(bKey)).filter(([key, value]) => (value !== null) && (value !== undefined)))
}
