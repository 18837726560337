import React from 'react'
import { IconAlertTriangle } from '@tabler/icons-react'
import PropTypes from 'prop-types'
import CustomAlert from './CustomAlert'

export default function WarningAlert ({ children, title = 'Warning!', ...props }) {
  return <CustomAlert title={title} icon={<IconAlertTriangle />} color='yellow' {...props}>{children}</CustomAlert>
}

WarningAlert.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
