import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Content from '../../../layout/Content';
import { useParams } from 'react-router-dom';
import {
  Text, Tooltip, Card, Center, RingProgress, Title, SimpleGrid, Tabs, Stack, Grid, Box, LoadingOverlay
} from '@mantine/core'
import {
  useGetEmailHistoriesQuery,
  useGetEmailHistoryStatusCountQuery
} from '../../../../redux/query/email/emailHistoryApi.slice';
import { LineChart, BarChart } from '@mantine/charts';
import classes from './ChartDateTab.module.scss';
import { useGetBoostInvitableLimitQuery } from '../../../../redux/query/hire/boostApi.slice';
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';
import { isGranted } from '../../../../util/account/account.util';
import { WebhookEventType } from '../../../../js/generated/enums/WebhookEventType';

const defaultLimitData = { percent: 0, sent: 0, limit: 0 };
const emptyChartData = []
const noEngagements = 0

export default function BoostInfo () {
  const { cycleId } = useParams()
  const { data: account } = useGetLoggedInAccountQuery()
  const { data: inviteLimit, isFetching: isInviteLimitQuerying } = useGetBoostInvitableLimitQuery({ id: cycleId })
  const { data: histories, isFetching: isHistoryQuerying } = useGetEmailHistoriesQuery({ cycle: cycleId, tag: 'cycle-invitation' })

  const limitData = useMemo(() => {
    if (isInviteLimitQuerying) {
      return defaultLimitData
    }

    if (isGranted(account, 'ROLE_ADMIN')) {
      return {
        percent: (inviteLimit.sent / inviteLimit.limit) * 100,
        sent: inviteLimit.sent,
        limit: inviteLimit.limit,
        client_percent: (inviteLimit.client_sent / inviteLimit.client_limit) * 100,
        client_sent: inviteLimit.client_sent,
        client_limit: inviteLimit.client_limit
      }
    } else {
      return {
        percent: (inviteLimit.sent / inviteLimit.limit) * 100,
        sent: inviteLimit.sent,
        limit: inviteLimit.limit
      }
    }
  }, [inviteLimit, account, isInviteLimitQuerying]);

  const opens = useMemo(() => {
    if (isHistoryQuerying) {
      return noEngagements
    }
    return histories.items.filter(history => history.events.some(event => event.type === WebhookEventType.Open)).length
  }, [isHistoryQuerying, histories])

  const clicks = useMemo(() => {
    if (isHistoryQuerying) {
      return noEngagements
    }
    return histories.items.filter(history => history.events.some(event => event.type === WebhookEventType.Click)).length
  }, [isHistoryQuerying, histories])

  return (
    <Content>
      <Stack>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }}>
          <CardDisplay title='Total Sent' loading={isHistoryQuerying}>
            <Title c="blue" fw={700} ta="center" size="5.8rem">{histories?.total ?? 0}</Title>
          </CardDisplay>

          <CardDisplay title='Opens' loading={isHistoryQuerying}>
            <Title c="blue" fw={700} ta="center" size="5.8rem">{opens}</Title>
          </CardDisplay>

          <CardDisplay title='Clicks' loading={isHistoryQuerying}>
            <Title c="blue" fw={700} ta="center" size="5.8rem">{clicks}</Title>
          </CardDisplay>

          <CardDisplay title='Invite Limit' loading={isInviteLimitQuerying}>
            <Center>
              <Tooltip label={`${limitData.sent}/${limitData.limit} Invites Sent`}>
                <RingProgress
                  sections={[{ value: limitData.percent, color: 'blue' }]}
                  label={
                    <Text c="blue" fw={700} ta="center" size="xl">
                      {`${limitData.sent}/${limitData.limit}`}
                    </Text>
                  }
                />
              </Tooltip>
            </Center>
          </CardDisplay>

          {account && isGranted(account, 'ROLE_ADMIN') &&
            <CardDisplay title='Client Invite Limit' loading={isInviteLimitQuerying}>
              <Center>
                <Tooltip label={`${limitData.client_sent}/${limitData.client_limit} Invites Sent`}>
                  <RingProgress
                    sections={[{ value: limitData.client_percent, color: 'blue' }]}
                    label={
                      <Text c="blue" fw={700} ta="center" size="xl">
                        {`${limitData.client_sent}/${limitData.client_limit}`}
                      </Text>
                    }
                  />
                </Tooltip>
              </Center>
            </CardDisplay>
          }
        </SimpleGrid>

        <TotalStatusCountChart cycle={cycleId} />

        <InviteTimeSeriesChart cycle={cycleId} />
      </Stack>
    </Content>
  )
}

const InviteTimeSeriesChart = memo(function InviteTimeSeriesChart ({ cycle }) {
  const [dateInterval, setDateInterval] = useState('-1 week');
  const { data: inviteCounts, isFetching: inviteCountsQuerying } = useGetEmailHistoryStatusCountQuery({ cycle: cycle, tag: 'cycle-invitation', groupBy: 'date', startDateInterval: dateInterval })

  const inviteTimeSeriesData = useMemo(() => {
    if (!inviteCountsQuerying) {
      let sent = 0
      let bounced = 0
      let softBounced = 0
      let rejected = 0
      return inviteCounts.map((item) => ({
        date: item.date,
        sent: (sent += Number(item.sent)),
        bounced: (bounced += Number(item.bounced)),
        soft_bounced: (softBounced += Number(item.soft_bounced)),
        rejected: (rejected += Number(item.rejected))
      }))
    }
    return emptyChartData;
  }, [inviteCounts, inviteCountsQuerying])

  return (
    <CardDisplay title='Invites Status Series' loading={inviteCountsQuerying}>
      <Stack>
        <Grid justify="flex-start" align="center">
          <Grid.Col span={'content'}>
            <ChartStartDateIntervalTab
              tabOptions={[
                { value: '-1 week', label: '1W' },
                { value: '-1 month', label: '1M' },
                { value: '-3 month', label: '3M' },
                { value: '-6 month', label: '6M' },
                { value: '-1 year', label: '1Y' }
              ]}
              defaultTab={dateInterval}
              setTab={setDateInterval}
            />
          </Grid.Col>
{/* TODO: Support time intervals
          <Grid.Col span={2}>
            <Select
              placeholder='Unit'
              data={['Day', 'Week', 'Month']}
            />
          </Grid.Col>
*/}
        </Grid>
        <LineChart
          h={300}
          data={inviteTimeSeriesData}
          dataKey="date"
          series={[
            { name: 'sent', label: 'Sent', color: 'blue' },
            { name: 'bounced', label: 'Bounced', color: 'red' },
            { name: 'soft_bounced', label: 'Soft Bounced', color: 'yellow' },
            { name: 'rejected', label: 'Rejected', color: 'orange' }
          ]}
          curveType="linear"
          withLegend
        />
      </Stack>
    </CardDisplay>
  )
})

const TotalStatusCountChart = memo(function TotalStatusCountChart ({ cycle }) {
  const [dateInterval, setDateInterval] = useState('all');
  const { data: statusCounts, isFetching: statusCountsQuerying } = useGetEmailHistoryStatusCountQuery({ cycle: cycle, tag: 'cycle-invitation', startDateInterval: dateInterval === 'all' ? null : dateInterval })

  const data = useMemo(() => {
    if (statusCountsQuerying) {
      return emptyChartData
    }
    return [{ Statuses: 'Statuses', ...statusCounts[0] }]
  }, [statusCountsQuerying, statusCounts])

  return (
    <CardDisplay title='Invite Statuses' loading={statusCountsQuerying}>
      <Stack>
        <Grid justify="flex-start" align="center">
          <Grid.Col span={'content'}>
            <ChartStartDateIntervalTab
              tabOptions={[
                { value: '-1 day', label: '1D' },
                { value: '-1 week', label: '1W' },
                { value: '-1 month', label: '1M' },
                { value: '-3 month', label: '3M' },
                { value: '-6 month', label: '6M' },
                { value: '-1 year', label: '1Y' },
                { value: 'all', label: 'All' }
              ]}
              defaultTab={dateInterval}
              setTab={setDateInterval}
            />
          </Grid.Col>
        </Grid>
        <BarChart
          h={300}
          data={data}
          dataKey="Statuses"
          orientation="vertical"
          yAxisProps={{ width: 80 }}
          series={[
            { name: 'sent', label: 'Sent', color: 'blue' },
            { name: 'bounced', label: 'Bounced', color: 'red' },
            { name: 'soft_bounced', label: 'Soft Bounced', color: 'yellow' },
            { name: 'rejected', label: 'Rejected', color: 'orange' }
          ]}
          withLegend
        />
      </Stack>
    </CardDisplay>
  )
})

function CardDisplay ({ title, loading = false, children }) {
  return (
    <Card withBorder shadow="lg" radius="md">
      <Card.Section inheritPadding py="xs">
        <Text size='lg' fw={700}>{title}</Text>
      </Card.Section>

      <Box pos="relative">
        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
        {children}
      </Box>
    </Card>
  )
}

/**
 * @param {Object[]} tabOptions
 * @param {string} defaultTab
 * @param {function} setTab
 * @returns {JSX.Element}
 */
function ChartStartDateIntervalTab ({ tabOptions, defaultTab, setTab }) {
  const tabs = tabOptions.map((tab) =>
    <Tabs.Tab value={tab.value} key={tab.key ?? tab.value}>{tab.label}</Tabs.Tab>
  )
  return (
    <Tabs
      classNames={classes}
      variant="unstyled"
      defaultValue={defaultTab}
      onChange={setTab}
    >
      <Tabs.List>
        {tabs}
      </Tabs.List>
    </Tabs>
  )
}

InviteTimeSeriesChart.propTypes = {
  cycle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

TotalStatusCountChart.propTypes = {
  cycle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

ChartStartDateIntervalTab.propTypes = {
  tabOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  defaultTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired
}

CardDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired
}
