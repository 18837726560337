import { createContext } from 'react';

/**
 * @typedef {Object} MinMaxDateFiltersContext
 * @property {?InputFilter} filter
 * @property {?string} parsedMin
 * @property {?string} parsedMax
 * @property {?Date} formattedMin
 * @property {?Date} formattedMax
 * @property {function} updateRange
 */

/**
 * @type {MinMaxDateFiltersContext}
 */
export const defaultTableFiltersContext = {
  filter: null, parsedMin: null, parsedMax: null, formattedMin: null, formattedMax: null, updateRange: (value, isMin) => { console.error('Filter context missing update range', { value, isMin }) }
}

/**
 * @type {React.Context<MinMaxDateFiltersContext>}
 */
export const MinMaxDateFiltersContext = createContext({ ...defaultTableFiltersContext })

/**
 * @typedef {function} FilterReducerContext
 */

function defaultFilterReducerContext (action) {
  console.error('Filter reducer context missing dispatch for action', { action })
}

/**
 * @type {React.Context<FilterReducerContext>}
 */
export const FilterReducerContext = createContext(defaultFilterReducerContext)
