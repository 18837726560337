/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Grid, Group, TextInput, Tooltip } from '@mantine/core'
import { isEmail, isNotEmpty, matches, useForm } from '@mantine/form'
import { IconAt, IconPhone } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { editUser } from '../../../js/api/user_repository'

export default function AdminDetails ({ account }) {
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      active: true,
      demographics: false
    },
    validate: {
      first_name: isNotEmpty('First name cannot be empty'),
      last_name: isNotEmpty('First Last cannot be empty'),
      email: isEmail('Invalid email') && matches(/^.+@stangds\.com$/, 'Email must be part of the stangds.com domain')
    }
  })

  useEffect(() => {
    form.setValues({
      first_name: account.first_name,
      last_name: account.last_name,
      email: account.email,
      phone_number: account.phone_number ?? '',
      active: account.active === 1,
      demographics: !_.isEmpty(_.filter(account.roles, { name: 'ROLE_DISC_DEMO' }))
    })
  }, [account])

  const submit = values => {
    setLoading(true)

    editUser(account.id, values)
      .then(() => {
        window.location.href = '/clients/users'
      })
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error editing administrator',
            color: 'red'
          })
        }

        setLoading(false)
      })
  }

  // eslint-disable-next-line no-return-assign
  const back = () => window.location.href = '/clients/users'

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Grid>
        <Grid.Col span={{ lg: 6, md: 9, sm: 12 }}>
          <Grid>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput label='First name' placeholder='John' {...form.getInputProps('first_name')} required />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput label='Last name' placeholder='Smith' {...form.getInputProps('last_name')} required />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput label='Email' icon={<IconAt size='0.8rem' />} placeholder='john@stangds.com' {...form.getInputProps('email')} required />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput label='Phone number' icon={<IconPhone size='0.8rem' />} placeholder='(XXX) XXX-XXXX' {...form.getInputProps('phone_number')} />
            </Grid.Col>
            <Grid.Col span={{ sm: 12 }}>
              <Checkbox label='Active' {...form.getInputProps('active', { type: 'checkbox' })} />
            </Grid.Col>
            <Grid.Col span={{ sm: 12 }}>
              <Tooltip label={'Grant this user permission to see demographic information about applicants'}>
                <Checkbox label='Demographics' {...form.getInputProps('demographics', { type: 'checkbox' })} />
              </Tooltip>
            </Grid.Col>
            <Grid.Col span={{ sm: 12 }}>
              <Group spacing='xs'>
                <Button type='submit' color='success' loading={loading}>Save</Button>
                <Button color='gray.6' onClick={() => back()}>Cancel</Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </form>
  )
}

AdminDetails.propTypes = {
  account: PropTypes.object
}
