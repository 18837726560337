/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { paramsToQuery } from '../../../../util/queries';

export const CycleNavButton = memo(function CycleNavButton ({ name, route = '', to = null, ...params }) {
  const path = to ?? generatePath(route, params)
  console.debug('Cycle Nav Button updating', { route, name, params, path })

  return (
    <Button variant='subtle' size='sm' component={Link} to={path}>{name}</Button>
  )
})

export const InviteHomeButton = memo(function InviteHomeButton () {
  const { cycleId } = useParams()

  return (
    <CycleNavButton cycleId={cycleId} route={CyclePassRoute.CycleInvitesHome} name='Invites Home' />
  )
})

export const BackButton = memo(function BackButton () {
  return (
    <CycleNavButton to='..' name='Back' />
  )
})

export const PreviewButton = memo(function PreviewButton ({ cycleId, ...previewParams }) {
  const previewRedirectLink = cycleId ? generatePath(CyclePassRoute.CycleInvitesCreatePreview, { cycleId }) : null
  console.debug('Preview Button updating', { cycleId, previewParams, previewRedirectLink })

  return (
    <>
      {!!previewRedirectLink && (
        <OpenInNewWindowButton text='Preview' href={previewRedirectLink + `${paramsToQuery(previewParams)}`} />
      )}
    </>
  )
})

export const ReviewProctorFreeButton = memo(function ReviewProctorFreeButton ({ cycleId }) {
  console.debug('ReviewProctorFreeButton updating', { cycleId })

  return (
    <OpenInNewWindowButton text='ProctorFree' href={`/invites/manage/cycles/${cycleId}/review-phases`} />
  )
})

const OpenInNewWindowButton = memo(function OpenInNewWindowButton ({ href, text }) {
  return (
    <Button
      variant='subtle'
      size='sm'
      component='a'
      href={href}
      target='_blank'
    >
      {text}
    </Button>
  )
})
