import { generatePath, Link, useParams } from 'react-router-dom';
import { useIsAdmin } from '../CyclesHooks';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { Button, Group, Space } from '@mantine/core';
import { InviteHomeButton } from '../nav/CycleNavButton';
import { SyncCyclePassScoresButton } from '../passes/CyclePassDetail';
import { StageProgressesTable } from './StageProgressesTable';
import React from 'react';

export function StageProgressesList () {
  const { cycleId } = useParams()
  const [isAdmin] = useIsAdmin()
  const stagesPath = generatePath(CyclePassRoute.CycleStages, { cycleId })
  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Progress List' />
      <Group>
        <InviteHomeButton />
        <Button variant='subtle' size='sm' component={Link} to={stagesPath}>Stages</Button>
        {!!isAdmin && <SyncCyclePassScoresButton cycleId={cycleId} isAdmin={isAdmin} />}
      </Group>
      <Space h='md' />
      <StageProgressesTable cycleId={cycleId} namespace='cycles-progresses' />
    </>
  )
}
