import { QuestionType } from '../../../../js/generated/enums/QuestionType';
import { idFromSectionPosition } from '../../../../js/generated/enums/SectionPosition';
import { idFromProtection } from '../../../../js/modules/Build/Assessment/AssessmentProtection';
import { idFromAssessmentPurpose } from '../../../../js/generated/enums/AssessmentPurpose';

/**
 * @param {T[]} elements
 * @param {number} elements[].position Sorted property must be found in all members of elements.
 * @param {boolean} reverse default false (ASC)
 * @returns {T[]} sorted elements
 * @template T
 */
function sortByPosition (elements, reverse = false) {
  if (reverse) {
    return elements.slice().sort((a, b) => b.position - a.position)
  }
  return elements.slice().sort((a, b) => a.position - b.position)
}

/**
 * @param {Map.<int: T>} entries
 * @param {int} entries[].id
 * @param {int} entries[].position
 * @param {T} targetEntry
 * @param {int} newPosition
 * @returns {Map.<int: T>} Returns input if no changes were made.
 * @template T
 */
function sortStateEntriesWithInsertedPosition (entries, targetEntry, newPosition) {
  const targetEntryId = targetEntry.id
  const entriesSortedByOldPosition = sortByPosition(Array.from(entries.values()))
  const sortedEntries = new Map()
  let entryCount = 0
  let inserted = false
  let anyChanged = false
  for (const entry of entriesSortedByOldPosition) {
    if (entryCount === newPosition) {
      if (newPosition === targetEntry.position) {
        sortedEntries.set(targetEntryId, targetEntry)
      } else {
        sortedEntries.set(targetEntryId, {
          ...targetEntry,
          position: newPosition
        })
        anyChanged = true
      }
      inserted = true
      entryCount += 1
    }
    if (entry.id !== targetEntryId) {
      if (entry.position === entryCount) {
        sortedEntries.set(entry.id, entry)
      } else {
        sortedEntries.set(entry.id, {
          ...entry,
          position: entryCount
        })
        anyChanged = true
      }
      entryCount += 1
    }
  }
  if (!inserted) {
    if (targetEntry.position === entryCount) {
      sortedEntries.set(targetEntryId, targetEntry)
    } else {
      sortedEntries.set(targetEntryId, {
        ...targetEntry,
        position: entryCount
      })
      anyChanged = true
    }
  }
  return anyChanged ? sortedEntries : entries
}

/**
 * @param {Map.<int: T>} entries
 * @param {int} entries[].id
 * @param {int} entries[].position
 * @param {int} entries[].number
 * @param {bool} entries[].isNumbered
 * @param {T} targetEntry
 * @param {int} newPosition
 * @returns {Map.<int: T>} Returns input if no changes were made.
 * @template T
 */
function setStateEntryPosition (entries, targetEntry, newPosition) {
  const targetEntryId = targetEntry.id
  const sortedEntries = new Map()
  let entryCount = 0
  let inserted = false
  let anyChanged = false
  let nonNumberedCount = 0
  for (const entry of entries.values()) {
    if (entryCount === newPosition) {
      if (!targetEntry.isNumbered) {
        nonNumberedCount += 1
      }
      const targetEntryNumber = (entryCount + 1) - nonNumberedCount
      if ((newPosition === targetEntry.position) && (targetEntryNumber === targetEntry.number)) {
        sortedEntries.set(targetEntryId, targetEntry)
      } else {
        sortedEntries.set(targetEntryId, {
          ...targetEntry,
          position: newPosition,
          number: targetEntryNumber
        })
        anyChanged = true
      }
      inserted = true
      entryCount += 1
    }
    if (entry.id !== targetEntryId) {
      if (!entry.isNumbered) {
        nonNumberedCount += 1
      }
      const newNumber = (entryCount + 1) - nonNumberedCount
      if ((entry.position === entryCount) && (entry.number === newNumber)) {
        sortedEntries.set(entry.id, entry)
      } else {
        sortedEntries.set(entry.id, {
          ...entry,
          position: entryCount,
          number: newNumber
        })
        anyChanged = true
      }
      entryCount += 1
    }
  }
  if (!inserted) {
    if (!targetEntry.isNumbered) {
      nonNumberedCount += 1
    }
    const newNumber = (entryCount + 1) - nonNumberedCount
    if ((targetEntry.position === entryCount) && (targetEntry.number === newNumber)) {
      sortedEntries.set(targetEntryId, targetEntry)
    } else {
      sortedEntries.set(targetEntryId, {
        ...targetEntry,
        position: entryCount,
        number: newNumber
      })
      anyChanged = true
    }
  }
  return anyChanged ? sortedEntries : entries
}

/**
 * @param {number} total Total entities
 * @param {number} limit Entities per page
 * @returns {number}
 */
function calculateMaxPage (total, limit) {
  return Math.max(Math.ceil(total / limit), 1)
}

/**
 * @param {int} newLimit
 * @param {object} oldPaginationAttributes
 * @param {int} oldPaginationAttributes.limit
 * @param {int} oldPaginationAttributes.page
 * @param {int} oldPaginationAttributes.maxPage
 * @param {int} filteredLength
 * @returns {(*&{limit, page: number, maxPage: number})|*}
 */
function calcNewLimitPaginationAttributes (newLimit, oldPaginationAttributes, filteredLength) {
  const { limit, page } = oldPaginationAttributes
  if (newLimit === limit) {
    return oldPaginationAttributes
  }
  const currentStart = limit * (page - 1)
  const newMaxPage = calculateMaxPage(filteredLength, newLimit)
  let newCurrentPage = Math.floor(currentStart / newLimit) + 1
  if (newCurrentPage > newMaxPage) {
    newCurrentPage = newMaxPage
  }
  return { ...oldPaginationAttributes, page: newCurrentPage, maxPage: newMaxPage, limit: newLimit }
}

/**
 * @param {int} newPage
 * @param {object} oldPaginationAttributes
 * @param {int} oldPaginationAttributes.limit
 * @param {int} oldPaginationAttributes.page
 * @param {int} oldPaginationAttributes.maxPage
 * @param {int} filteredLength
 * @returns {(*&{limit, page: number, maxPage: number})|*}
 */
function calcNewPagePaginationAttributes (newPage, oldPaginationAttributes, filteredLength) {
  const { limit, page } = oldPaginationAttributes
  if (newPage === page) {
    return oldPaginationAttributes
  }
  if ((newPage > 1) && (((newPage - 1) * limit) >= filteredLength)) {
    console.error(
      'Cannot set state page to new value due to exceeding limit.',
      newPage,
      limit,
      filteredLength
    )
    return oldPaginationAttributes
  }
  return { ...oldPaginationAttributes, page: newPage }
}

function getSignificantDecimals (value, logTen) {
  const strValue = String(value)
  if (!strValue.includes('.')) {
    return 0
  }

  let n = Math.abs(strValue.split('.')[1]) // Truncate everything before decimal and convert back to number.
  if (n === 0) return 0;
  while (n !== 0 && n % 10 === 0) n /= 10; // Remove the 0s at the end of n.
  return Math.floor(Math.log(n) / logTen) + 1;
}

function getMaximumDisplayedDecimalsForScale (start, stop, step, maxDecimalsPossible = 10) {
  const logTen = Math.log(10)
  return Math.min(
    maxDecimalsPossible,
    Math.max(
      getSignificantDecimals(start, logTen),
      getSignificantDecimals(stop, logTen),
      getSignificantDecimals(step, logTen)
    )
  )
}

function calculateMaxDecimalsForScale (start, stepSize, stepCount) {
  const stop = start + (stepSize * Math.max(stepCount - 1, 0))
  return getMaximumDisplayedDecimalsForScale(start, stop, stepSize)
}

const MAX_SCORE_PRECISION = 6;

/**
 * @param {number} score
 * @returns {string}
 */
function answerScoreToString (score) {
  return score.toLocaleString('fullwide', { maximumFractionDigits: MAX_SCORE_PRECISION })
}

/**
 * Internal/db format -> Legacy form format conversions.
 * @param {{}} values
 * @returns {{}}
 */
function legacyFormatEditAssessmentFormData (values) {
  const questionFields = {
    keywords: {},
    verticals: {},
    tops: {},
    comments: {},
    mediaPlayOnce: {},
    isDropdown: {},
    isScored: {},
    contents: {},
    positions: {},
    required: {},
    section: {},
    pageBreaks: {},
    fileIds: {}, // Media, '0' string for null
    randomizedPool: {}, // 'null' string for null
    competencyIds: {},
    types: {},
    logic: {},
    directLinkIds: {},
    variedLinkIds: {},
    validationTypes: {}
  }
  const questionAnswerFields = {
    answerContents: {},
    answerCorrects: {},
    answerPositions: {},
    answerScores: {}
  }
  const questionCategoryFields = {
    categoryTypes: {},
    categorySubTypes: {},
    categoryCustomFieldNames: {}
  }
  const submitValues = {
    ...questionFields,
    ...questionAnswerFields,
    ...questionCategoryFields,
    answerIds: {},
    categoryIds: {},
    correctAnswers: {}, // For true/yes/fill-in-the-blank. 1 if true/yes correct, comma separated answer content for fill in the blank.
    questionIds: [] // add "new" to id for negative questionIds.
  }
  const formatQuestionId = (questionId) => {
    return questionId < 0 ? 'new' + questionId.toString() : questionId.toString()
  }
  const formatLogic = (logic, fromParent = {}) => {
    const nodeGroupData = logic.nodeGroupId ? { nodeGroup: logic.nodeGroupId } : {}
    return {
      ...fromParent,
      ...nodeGroupData,
      id: logic.id,
      question: formatQuestionId(logic.questionId ?? 0),
      operator: logic.operator,
      value: logic.value,
      feature: logic.feature,
      children: logic.children.map((child) => formatLogic(child, { parent: logic.id }))
    }
  }

  const getFieldFromQuestion = (fieldName, question) => {
    switch (fieldName) {
      case 'competencyIds': {
        return question.competencies.map(competency => competency.id)
      }
      case 'fileIds': {
        return question.media?.id ?? '0'
      }
      case 'types': {
        return question.type
      }
      case 'section': {
        return idFromSectionPosition(question.section)
      }
      case 'randomizedPool': {
        return question.randomizedPool === null ? 'null' : question.randomizedPool
      }
      case 'logic': {
        return !question.logic?.length ? null : question.logic.map((logic) => formatLogic(logic, { parentQuestion: formatQuestionId(question.id) }))
      }
      default: {
        return question[fieldName] ?? question[Array.from(fieldName).slice(0, -1).join('')] ?? null
      }
    }
  }
  const correctAnswerContent = (question) => {
    const fillInBlankChoices = []
    for (const answer of question.answers.values()) {
      if (answer.correct) {
        switch (question.type) {
          case QuestionType.FillInTheBlank:
            fillInBlankChoices.push(answer.content)
            break
          case QuestionType.TrueFalse:
          case QuestionType.YesNo:
            return (answer.content === 'True' || answer.content === 'Yes') ? 1 : 0
          default:
            return answer.content
        }
      }
    }
    if (fillInBlankChoices.length) {
      return fillInBlankChoices.join(',')
    }
    return null
  }
  const getFieldFromAnswer = (fieldName, answer) => {
    switch (fieldName) {
      case 'answerCorrects': {
        return answer.correct ? 1 : 0
      }
      case 'answerContents': {
        return answer.content
      }
      case 'answerPositions': {
        return answer.position
      }
      case 'answerScores': {
        return answer.score
      }
      default: {
        return answer[fieldName] ?? answer[Array.from(fieldName).slice(0, -1).join('')] ?? null
      }
    }
  }
  const getFieldFromCategory = (fieldName, category) => {
    switch (fieldName) {
      case 'categoryTypes': {
        return category.type
      }
      case 'categorySubTypes': {
        return category.subType ?? null
      }
      case 'categoryCustomFieldNames': {
        return category.customFieldName ?? null
      }
      default: {
        console.error('Unknown category field name.', category)
        return null
      }
    }
  }

  for (const question of values.questions) {
    const questionId = formatQuestionId(question.id)
    submitValues.questionIds.push(questionId)
    for (const field of Object.keys(questionFields)) {
      submitValues[field][questionId] = getFieldFromQuestion(field, question)
    }
    if (question.answers.size) {
      const correctContent = correctAnswerContent(question)
      if (correctContent !== null) {
        submitValues.correctAnswers[questionId] = correctContent
      }
      if (question.answers.size) {
        submitValues.answerIds[questionId] = []
        for (const field of Object.keys(questionAnswerFields)) {
          submitValues[field][questionId] = {}
        }
        for (const answer of question.answers.values()) {
          submitValues.answerIds[questionId].push(answer.id.toString())
          for (const field of Object.keys(questionAnswerFields)) {
            submitValues[field][questionId][answer.id.toString()] = getFieldFromAnswer(field, answer)
          }
        }
      }
    }
    if (question.categories?.length) {
      submitValues.categoryIds[questionId] = []
      for (const field of Object.keys(questionCategoryFields)) {
        submitValues[field][questionId] = {}
      }
      for (const category of question.categories) {
        const categoryId = category.id?.toString?.() ?? category.id ?? null
        if (!categoryId) {
          console.error('No category id for category.', category, question)
        }
        submitValues.categoryIds[questionId].push(categoryId)
        for (const field of Object.keys(questionCategoryFields)) {
          submitValues[field][questionId][categoryId] = getFieldFromCategory(field, category)
        }
      }
    }
  }
  const formFields = {
    'average-hours': values.averageHours,
    'average-minutes': values.averageMinutes,
    'internal-name': values.internalName,
    'interviewer-count': values.numberInterviewers,
    'minimum-average-score': values.minimumAverageScore,
    name: values.name,
    description: values.description,
    protected: idFromProtection(values.protection),
    'client-id': parseInt(values.organization ?? '0'),
    'assessment-type-select': idFromAssessmentPurpose(values.purpose), // Purpose
    'assessment-category-select': parseInt(values.type ?? '0'), // Sub-type
    'hiring-assessment-select': parseInt(values.type ?? '0') // Sub-type
  }
  return { ...submitValues, ...formFields }
}

function getQuestionContentIsNewPlaceholder (content) {
  return content === '<h4><span style="color: rgb(99, 99, 99)">New Header</span></h4>' || content === '<p><span style="color: rgb(99, 99, 99)">New Question</span></p>' || content === '<h4><span style="color: #636363">New Header</span></h4>' || content === '<p><span style="color: #636363">New Question</span></p>'
}

const stateList = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const DEFAULT_TEMPLATES = Object.freeze([
  {
    name: 'Yes/No',
    answers: [
      {
        content: 'Yes',
        score: 0,
        correct: false
      },
      {
        content: 'No',
        score: 0,
        correct: false
      }
    ]
  },
  {
    name: 'True/False',
    answers: [
      {
        content: 'True',
        score: 0,
        correct: false
      },
      {
        content: 'False',
        score: 0,
        correct: false
      }
    ]
  },
  {
    name: 'States',
    answers: [
      ...stateList.map(state => ({
        content: state,
        score: 0,
        correct: false
      }))
    ]
  }
])

export {
  sortByPosition,
  sortStateEntriesWithInsertedPosition,
  setStateEntryPosition,
  calculateMaxPage,
  calcNewLimitPaginationAttributes,
  calcNewPagePaginationAttributes,
  calculateMaxDecimalsForScale,
  MAX_SCORE_PRECISION,
  answerScoreToString,
  legacyFormatEditAssessmentFormData,
  getQuestionContentIsNewPlaceholder,
  DEFAULT_TEMPLATES
}
