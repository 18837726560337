import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../../core/ReactTable/paramsSlice';
import { useGetOpenInviteDetailQuery, useGetOpenInvitesForCycleQuery } from './OpenInvitesApi';
import {
  BLANK_TABLE_ACTIONS,
  DEFAULT_HIDDEN_OPEN_INVITE_COLUMNS,
  DEFAULT_OPEN_INVITE_FILTERS,
  OPEN_INVITE_COLUMNS,
  OPEN_INVITE_FILTERS
} from '../CycleInviteHooks';
import { apiSlice } from '../../../../api';

export function useOpenInviteDetail (openInviteId, cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetOpenInviteDetailQuery({ openInviteId, cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCyclesOpenInvites (namespace, cycleId, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetOpenInvitesForCycleQuery({ ...queryParams, cycleId }, { skip })
  return [collection ?? null, querying]
}

export function useLazyCyclesOpenInvites (namespace, cycleId) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getOpenInvitesForCycle.useQueryState({ ...queryParams, cycleId })
  return [collection ?? null, querying]
}

export function useCycleOpenInvitesTable () {
  return {
    defaultHiddenColumns: DEFAULT_HIDDEN_OPEN_INVITE_COLUMNS,
    defaultFilters: DEFAULT_OPEN_INVITE_FILTERS,
    columns: OPEN_INVITE_COLUMNS,
    filters: OPEN_INVITE_FILTERS,
    actions: BLANK_TABLE_ACTIONS,
    searchable: true
  }
}
