import axios from 'axios'
import { formatResponseData } from '../util/DataUtil'

export function getBatteries (json, cancelToken = null) {
  console.log(json)
  const config = {
    method: 'GET',
    url: 'api/v1/cycles/batteries',
    params: json
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => formatResponseData(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios assessment data request via token.', config)
      } else {
        console.error('Assessment data request error.', err)
        throw err
      }
    })
}

export function getBatteryInfo (batteryId, batteryInfoId) {
  return axios({
    method: 'GET',
    url: `api/v1/batteries/${batteryId}/infos/${batteryInfoId}`
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
export function create (id, data) {
  return axios({
    method: 'POST',
    url: `api/v1/batteries/${id}/infos`,
    data: data
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateInfo (batteryId, batteryInfoId, data) {
  return axios({
    method: 'POST',
    url: `api/v1/batteries/${batteryId}/infos/${batteryInfoId}`,
    data: data
  })
    .then((response) => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateInfos (id, data) {
  return axios({
    method: 'PUT',
    url: `api/v1/batteries/${id}/infos`,
    data: data
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function deleteInfo (batteryId, batteryInfoId) {
  return axios({
    method: 'DELETE',
    url: `api/v1/batteries/${batteryId}/infos/${batteryInfoId}`
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}
