/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { combineApplicantName, renderDateFromSource } from '../formatUtil';
import { Avatar, Box, Button, Card, Center, Group, Text, Title } from '@mantine/core';
import { generatePath, Link } from 'react-router-dom';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { getLockableItemBasicColor } from './LockableIcon';

export const CyclePassSummaryCard = memo(function CyclePassSummaryCard ({ cyclePass, cycleId, isAdmin = false }) {
  const applicantName = combineApplicantName(cyclePass.applicant)
  const accountName = combineApplicantName(cyclePass.account)
  const passId = cyclePass.id
  const cyclePassDetailPath = !(cycleId && passId) ? null : generatePath(CyclePassRoute.CyclePassDetail, { cycleId, passId })

  const activeColor = getLockableItemBasicColor(cyclePass)
  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg={activeColor} py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>Passport</Title>
          </Box>
        </Card.Section>
        <Card.Section mt='xxs' mb='1rem'>
          <Center>
            <Avatar
              color={activeColor}
              alt={`Cycle Pass #${cyclePass.id}`}
              size='xl'
            >
              {cyclePass.id}
            </Avatar>
          </Center>
        </Card.Section>

        <Text ta='center' c={cyclePass.applicant ? 'inherit' : 'dimmed'} size={cyclePass.applicant ? 'md' : 'xxs'}>
          Applicant: {(applicantName ? `${applicantName}${(isAdmin && cyclePass.applicant?.id) ? ` #${cyclePass.applicant.id}` : ''}` : null) || (cyclePass.applicant?.id ? `#${cyclePass.applicant.id} (No Name)` : null) || 'None Assigned'}
        </Text>

        <Text c={cyclePass.email ? 'teal' : 'dimmed'} ta='center' size={cyclePass.email ? 'md' : 'xxs'}>
          Email: {cyclePass.email?.id || 'No Email Assigned'}
        </Text>

        {!!isAdmin && (
          <Text c={cyclePass.account ? 'inherit' : 'dimmed'} ta='center' size={cyclePass.account ? 'md' : 'xxs'}>
            Account: {accountName || cyclePass.account?.email || 'None Assigned'}{cyclePass.account?.id ? ` #${cyclePass.account.id}` : ''}
          </Text>
        )}

        <Group justify="space-between" mt="auto" mb="xs">
          {!!cyclePass.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(cyclePass.created_at)}</Text>}
          {!!cyclePass.updated_at && <Text size="sm" c="dimmed">Updated: {renderDateFromSource(cyclePass.updated_at)}</Text>}
        </Group>

        <Button color="blue" fullWidth mt={0} radius="md" component={Link} to={cyclePassDetailPath} disabled={!cyclePassDetailPath}>
          View Details
        </Button>
      </Card>
    </>
  )
})
