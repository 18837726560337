import { apiSlice, ApiTags, providesList } from '../../../react/api'

export const phasesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPhases: builder.query({
      query: id => 'phases',
      providesTags: (result, error, id) => providesList(result, ApiTags.Phases)
    })
  })
})

export const {
  useGetPhasesQuery
} = phasesApi
