import _ from 'lodash'

const roles = new Map()
roles.set('ROLE_PORTAL_USER', ['ROLE_PORTAL_USER'])
roles.set('ROLE_PORTAL_CLIENT', ['ROLE_PORTAL_CLIENT', 'ROLE_PORTAL_USER'])
roles.set('ROLE_SB_USER', ['ROLE_SB_USER'])
roles.set('ROLE_SB_FACILITATOR', ['ROLE_SB_USER', 'ROLE_SB_FACILITATOR'])
roles.set('ROLE_SB_LOCATION_ADMIN', ['ROLE_SB_USER', 'ROLE_SB_FACILITATOR', 'ROLE_SB_LOCATION_ADMIN'])
roles.set('ROLE_SB_SUPER_ADMIN', ['ROLE_SB_USER', 'ROLE_SB_FACILITATOR', 'ROLE_SB_LOCATION_ADMIN', 'ROLE_SB_SUPER_ADMIN'])
roles.set('ROLE_ADMIN', ['ROLE_PORTAL_CLIENT', 'ROLE_PORTAL_USER', 'ROLE_GAME', 'ROLE_SB_USER', 'ROLE_SB_FACILITATOR', 'ROLE_SB_LOCATION_ADMIN', 'ROLE_SB_SUPER_ADMIN', 'ROLE_ALLOWED_TO_SWITCH', 'ROLE_ADMIN'])
roles.set('ROLE_DEVELOPER', ['ROLE_PORTAL_CLIENT', 'ROLE_PORTAL_USER', 'ROLE_GAME', 'ROLE_SB_USER', 'ROLE_SB_FACILITATOR', 'ROLE_SB_LOCATION_ADMIN', 'ROLE_SB_SUPER_ADMIN', 'ROLE_ALLOWED_TO_SWITCH', 'ROLE_ADMIN', 'ROLE_DEVELOPER'])
roles.set('ROLE_AP_ON_SITE', ['ROLE_AP_ON_SITE'])
roles.set('ROLE_AP_PROCTOR', ['ROLE_AP_ON_SITE', 'ROLE_AP_PROCTOR'])
roles.set('ROLE_AP_CANDIDATE', ['ROLE_AP_ON_SITE', 'ROLE_AP_CANDIDATE'])
roles.set('ROLE_AP', ['ROLE_AP'])
roles.set('ROLE_BETA_TESTER', ['ROLE_BETA_TESTER'])
roles.set('ROLE_DISC_DEMO', ['ROLE_DISC_DEMO'])
roles.set('ROLE_TESTPREP', ['ROLE_TESTPREP'])

/**
 * This function uses the roles Map to figure out if an account has the given role, either directly or through inheritance.
 */
export const isGranted = (account, roleString) => _.some(account.roles, role => _.includes(roles.get(role.name), roleString))
