import React, { useState } from 'react'
import { Button, Grid, SimpleGrid, TextInput } from '@mantine/core'
import { isEmail, isNotEmpty, matches, useForm } from '@mantine/form'
import { IconAt, IconPhone } from '@tabler/icons-react'
import { inviteAdmin } from '../../../js/api/account_repository'
import { showNotification } from '@mantine/notifications'

export default function AdminModal () {
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: ''
    },
    validate: {
      first_name: isNotEmpty('First name cannot be empty'),
      last_name: isNotEmpty('First Last cannot be empty'),
      email: isEmail('Invalid email') && matches(/^.+@stangds\.com$/, 'Email must be part of the stangds.com domain')
    }
  })

  const submit = values => {
    setLoading(true)

    inviteAdmin(values)
      .then(() => {
        window.location.href = '/clients/users'
      })
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error inviting administrator',
            color: 'red'
          })
        }

        setLoading(false)
      })
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <SimpleGrid cols={1}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput label='First name' placeholder='John' {...form.getInputProps('first_name')} required />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput label='Last name' placeholder='Smith' {...form.getInputProps('last_name')} required />
          </Grid.Col>
        </Grid>
        <TextInput label='Email' icon={<IconAt size='0.8rem' />} placeholder='john@stangds.com' {...form.getInputProps('email')} required />
        <TextInput label='Phone number' icon={<IconPhone size='0.8rem' />} placeholder='(XXX) XXX-XXXX' {...form.getInputProps('phone_number')} />
        <Button type='submit' color='success' size='md' loading={loading}>Invite</Button>
      </SimpleGrid>
    </form>
  )
}
