import {
  Container,
  Text
} from '@mantine/core';
import React from 'react';
import SemanticSearchTable from './SemanticSearchTable';

export default function SemanticSearch () {
  return (
    <Container mt='md'>
      <Text fw={700} size='xl'>Find Candidates</Text>
      <SemanticSearchTable/>
    </Container>
  )
}
