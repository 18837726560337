/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Anchor, Flex, ScrollArea, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { IconActivity, IconAdjustments, IconEdit, IconInfoCircle } from '@tabler/icons-react';
import { useOrganizationsFilter } from '../../account/AccountFilters';
import { Header, NumberCell } from '../../core/ReactTable/ReactTable';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../core/ReactTable/paramsSlice';
import { useGetBatteriesQuery } from '../../../redux/query/hire/cyclesApi.slice';

const defaultFilters = { active: '0' }
const defaultHiddenColumns = ['id']

export function useBatteriesQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetBatteriesQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function useBatteryTable () {
  const columns = useBatteryColumns()
  const filters = useBatteryFilters()
  const actions = useBatteryActions()

  return {
    defaultFilters: defaultFilters,
    defaultHiddenColumns: defaultHiddenColumns,
    columns: columns,
    filters: filters,
    actions: actions,
    onRowClick: onRowClick,
    searchable: true
  }
}

function onRowClick (row) {
  if (row === undefined) return true
  const batteryId = row.dataset.rowid
  console.debug('Battery row clicked - redirecting to battery results', batteryId)
  window.location.href = `/cycles/${batteryId}/legacy` // Note that for /results, ap cycles always redirect to /legacy
}

function useBatteryColumns () {
  const ROW_HEIGHT = '6rem'
  return useMemo(() => [
    {
      Header: <Header>ID</Header>,
      id: 'id',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      sortable: true,
      hideable: true
    }, {
      Header: <Header>Name</Header>,
      id: 'name',
      accessor: 'name',
      Cell: ({ cell: { value } }) => {
        return <Text>{value}</Text>
      },
      sortable: true
    }, {
      Header: <Header>Assessments</Header>,
      id: 'assessments',
      accessor: 'ap_cycle_surveys',
      Cell: ({ cell: { value, row } }) => {
        return (
          <ScrollArea h={ROW_HEIGHT}>
            <Flex direction='column'>
            {value.map((assessment) => {
              const unpublishedAssessment = assessment.published_survey.unpublished_assessment
              if (!unpublishedAssessment) {
                if (assessment.published_survey) {
                  return <Text key={`${row.id}-${assessment.id}`}>{assessment.published_survey.name}</Text>
                }
                return null
              }
              return <Anchor
                key={`${row.id}-${assessment.id}`}
                href={`/build/assessments/${unpublishedAssessment.id}/edit`}
                >
                {unpublishedAssessment.name}
              </Anchor>
            })}
            </Flex>
          </ScrollArea>
        )
      },
      hideable: true
    }, {
      Header: <Header ta='right'># Completed</Header>,
      id: 'completed',
      accessor: 'number_completed',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='right'># Cycles</Header>,
      id: 'cycles',
      accessor: 'number_cycles',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='right'># Organizations</Header>,
      id: 'organizations',
      accessor: 'number_organizations',
      Cell: ({ cell: { value } }) => {
        return <NumberCell>{value}</NumberCell>
      },
      hideable: true,
      sortable: true
    }, {
      Header: <Header ta='center'>Created Date</Header>,
      id: 'created_at',
      accessor: 'created_at',
      Cell: ({ cell: { value } }) => {
        return <NumberCell centered>{dayjs(value).format('MM-DD-YYYY')}</NumberCell>
      },
      hideable: true,
      sortable: true
    }
  ], [])
}

function useBatteryFilters () {
  const organizationsFilter = useOrganizationsFilter({ multiSelect: true })

  return useMemo(() => [
    organizationsFilter,
    {
      id: 'active',
      label: 'Status',
      leftSection: <IconActivity />,
      options: [{ label: 'Open', value: '0' }, { label: 'Closed', value: '1' }]
    }
  ], [organizationsFilter])
}

function useBatteryActions () {
  return useMemo(() => [
    {
      label: 'Edit',
      leftSection: <IconEdit />,
      href: (id) => {
        return `/hire/report-results/${id}/edit`
      }
    }, {
      label: 'Details',
      leftSection: <IconInfoCircle />,
      href: (id) => {
        return `/cycles/${id}/cycleDetails`
      }
    }, {
      label: 'Specs',
      leftSection: <IconAdjustments />,
      href: (id) => {
        return `/hire/report-results/${id}/specs`
      }
    }
  ], [])
}
