import { Anchor, Button, Flex } from '@mantine/core'
import React from 'react'

export default function CreateButtons () {
  return (
    <>
      <Flex w='auto'>
        <Button variant='filled' color='success' href='/skillbuilder/processes' component={Anchor} >Create Cycle</Button>
      </Flex>
    </>
  )
}
