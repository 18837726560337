import React from 'react'
import HiringTemplateDetail from './HiringTemplateDetail'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

/**
 * This component allows to use the HiringTemplateDetail component using the URI to find the ID.
 */
export default function HiringTemplateDetailParams ({ selected }) {
  const { id } = useParams()

  return <HiringTemplateDetail id={id} selected={selected}/>
}

HiringTemplateDetailParams.propTypes = {
  selected: PropTypes.string.isRequired
}
