/* eslint react/prop-types: 0 */
import { Modal } from '@mantine/core';
import React from 'react';

export function BaseModal ({ title, showing, onClose, withCloseButton = true, keepMounted = false, size = '90%', children }) {
  return (
    <Modal
      opened={showing}
      onClose={onClose}
      title={title}
      overlayProps={{
        color: 'gray.3',
        opacity: 0.55,
        blur: 3
      }}
      size={size}
      withCloseButton={withCloseButton}
      keepMounted={keepMounted}
      trapFocus={false}
    >
      {children}
    </Modal>
  )
}
