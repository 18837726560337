import axios from 'axios'

export function getQuestions (params, cancelToken = null) {
  const config = {
    method: 'GET',
    url: 'api/v1/questions',
    params: params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios Questions request via token.', config)
      } else {
        console.error('Questions request error.', err)
        throw err
      }
    })
}
