import Base from '../../../layout/Base';
import { Info } from './Info';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActionIcon, Anchor, Box, Button, Card, Collapse, Flex, Group, Indicator, Modal, Paper, ScrollArea, SemiCircleProgress, Skeleton, Stack, Text, ThemeIcon, Tooltip, Transition } from '@mantine/core';
import { IconArrowsMaximize, IconArrowsMinimize, IconChevronDown, IconCircleXFilled, IconClipboardData, IconFile, IconFlag, IconFlagOff, IconId, IconList, IconMail, IconSend, IconSquareX, IconTrash, IconUserCircle, IconX } from '@tabler/icons-react';
import Discussion from '../Discussion';
import { useAccount } from '../../../util/Hooks';
import { Scores } from './Scores';
import { useDisclosure, useHover, useOs, useWindowEvent } from '@mantine/hooks';
import { getApplicant, getFlagContent, getRezview } from '../../../../js/api/applicant_repository';
import { getCycleModules, getCycleStatuses } from '../../../../js/api/cycle_repository';
import PropTypes from 'prop-types'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { closeCard, selectCard } from '../detailViewSlice';
import { useGetApplicantQuery, useGetFlagsContentQuery, useGetRezviewContentQuery } from '../../../../redux/query/hire/applicantsApi.slice';
import { animated, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';

const ICON_SIZE = '2rem'

const urls = {
  applicantReport: (cycleId, applicantId) => `/hire/cycles/${cycleId}/applicants/${applicantId}/report`,
  files: (cycleId, applicantId) => `/hire/report-results/${cycleId}/applicants/${applicantId}/files`,
  applicantResponses: (cycleId, applicantId) => `/cycles/${cycleId}/applicants/${applicantId}/responses`,
  applicantDelete: (cycleId, applicantId) => `/hire/report-results/${cycleId}/applicants/${applicantId}/delete`,
  applicantContact: (cycleId, applicantId) => `/cycles/${cycleId}/contact/${applicantId}`,
  ksResponses: (cycleId, applicantId) => `/cycles/${cycleId}/applicants/${applicantId}/responses`
}

export function ApplicantCardWrapper () {
  const cid = document.getElementById('applicant-card').dataset.cycleId
  const simple = 'simple' in document.getElementById('applicant-card').dataset

  const os = useOs()
  const isMobile = useMemo(() => ['ios', 'android'].includes(os), [os])

  const props = useMemo(() => {
    const defaultProps = {
      bg: 'white',
      mih: '70vh',
      mah: '90vh',
      p: 0,
      maw: '35rem',
      style: { zIndex: 195 }
    }

    return !simple
      ? {
          ...defaultProps,
          w: '100%'

        }
      : {
          ...defaultProps,
          w: '35rem',
          pos: 'fixed'
        }
  }, [simple])

  return (
    <Base>
    {isMobile
      ? (
      <MobileWrapper cid={cid} />
        )
      : (
      <Paper {...props}>
        <ApplicantCardFrame cid={cid} isSimple={simple} />
      </Paper>
        )
    }
    </Base>
  )
}

function MobileWrapper ({ cid, applicantId: appId = null, betaView }) {
  const [mobileModalOpened, setMobileModalOpened] = useState(!!appId)
  const [applicantId, setApplicantId] = useState(appId)

  useWindowEvent('appcard:opened', ({ detail }) => {
    setApplicantId(detail.id)
    setMobileModalOpened(true)
  })

  useWindowEvent('appcard:open', () => {
    setMobileModalOpened(true)
  })

  const handleClose = () => {
    setMobileModalOpened(false)
    window.dispatchEvent(new CustomEvent('appcard:close'))
  }

  return (
    <Modal.Root fullScreen opened={mobileModalOpened} onClose={handleClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Body p={0}>
          <ApplicantCardFrame applicantId={parseInt(applicantId)} cid={cid} onClose={handleClose} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}

export function ApplicantCardFrame ({ applicantId, cid, isSimple = false, onClose }) {
  const [applicant, setApplicant] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [rezview, setRezview] = useState(null)
  const [flagContent, setFlagContent] = useState('')
  const [modules, setModules] = useState([])
  const [statuses, setStatuses] = useState([])
  const account = useAccount()
  const cancelSourceRef = useRef(null)

  const [cycleId, setCycleId] = useState(cid)

  const isReady = !!(applicant && rezview && flagContent && modules.length > 0 && statuses.length > 0)

  const fetchApplicant = useCallback((id) => {
    if (!id) return
    setFetching(true)

    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel('Operation canceled due to new request.')
    }

    cancelSourceRef.current = axios.CancelToken.source()
    getApplicant(id, cancelSourceRef.current.token)
      .then((response) => {
        setApplicant(response)
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.debug(`Cancelled fetch for applicant (id: ${id})`)
        } else {
          console.error(`Failed to fetch applicant (id: ${id})`, err)
        }
      })

    getRezview(id)
      .then((response) => {
        setRezview(response)
      })
      .catch((err) => {
        console.error(`Failed to fetch rezview for applicant (id: ${id})`, err)
      })

    getFlagContent(id)
      .then((response) => {
        setFlagContent(response)
      })
      .catch((err) => {
        console.error(`Failed to fetch flag report for applicant (id: ${id})`, err)
      })
  }, [])

  useEffect(() => {
    if (!applicant) return
    if (applicant.cycle_id !== cycleId) {
      setFetching(true)
      setModules([])
      setStatuses([])
      setCycleId(applicant.cycle_id)
    }
  }, [applicant, cycleId, applicantId])

  useEffect(() => {
    if (!cycleId) return
    getCycleModules(cycleId)
      .then(response => {
        if (response.data.length === 0) {
          setModules([])
        } else {
          setModules(response.data.filter(module => (module.type.name !== 'Text' && module.visible === 1)).map(module => ({ id: module.id, name: module.name })))
        }
      })
      .catch((err) => {
        console.error(`Failed to fetch cycle (cycleId: ${cycleId}) modules`, err)
      })

    getCycleStatuses(cycleId)
      .then(response => {
        setStatuses(response.items.map(status => ({ value: status.id, label: status.name, tier: status.tier })) ?? [])
      })
      .catch((err) => {
        console.error(`Failed to fetch cycle (cycleId: ${cycleId}) statuses`, err)
      })
  }, [cycleId])

  useEffect(() => {
    if (!applicant && !fetching) return
    setFetching(!isReady)
  }, [isReady, fetching, applicant])

  useEffect(() => {
    if (!applicantId) return
    setApplicant(null)
    fetchApplicant(applicantId)
  }, [applicantId, fetchApplicant])

  useWindowEvent('appcard:opened', ({ detail }) => {
    setApplicant(null)
    fetchApplicant(detail.id)
  })

  return fetching
    ? <LoadingScreen simple={isSimple} />
    : (isReady
        ? <ApplicantCard
            applicant={applicant}
            rezview={rezview}
            modules={modules}
            statuses={statuses}
            account={account}
            flagContent={flagContent}
            isSimple={isSimple}
            onClose={onClose}
            />
        : applicant ? <FailureScreen /> : <IdleScreen />)
}

const CardControl = ({ onClick, children, forwardRef, iconProps, tooltipLabel, ...props }) => {
  const handleClick = useCallback((e) => {
    e.stopPropagation()
    onClick()
  }, [onClick])

  return (
  <Tooltip label={tooltipLabel}>
  <ActionIcon size='xl' onClick={handleClick} ref={forwardRef} {...props}>
    <ThemeIcon color='gray' variant='subtle' {...iconProps}>
      {children}
    </ThemeIcon>
  </ActionIcon>
  </Tooltip>
  )
}

const springConfigParams = {
  scale: 1,
  zoom: 0,
  x: 0,
  y: 0
}

export function BetaCardWrapper ({ cardId, cycleData, access }) {
  const { statuses, modules } = cycleData
  const { applicantId } = useSelector(state => selectCard(state, cardId))
  const [dragging, setDragging] = useState(false)
  const { data: applicant } = useGetApplicantQuery(applicantId)
  const { data: rezviewContent } = useGetRezviewContentQuery({ id: applicantId })
  const { data: flagContent } = useGetFlagsContentQuery(applicantId)
  const [minimized, setMinimized] = useState(false)

  const springConfig = useCallback(() => {
    return springConfigParams
  }, [])

  const dispatch = useDispatch()

  const focus = useCallback(() => {
    const currentCard = document.querySelector(`.beta-applicant-card[data-applicant-id="${applicantId}"]`)

    if (currentCard.style.zIndex === '195') return

    console.debug('Focusing applicant card', applicantId)

    document.querySelectorAll('.beta-applicant-card').forEach(card => {
      card.style.zIndex = 190
    })

    currentCard.style.zIndex = 195
  }, [applicantId])

  const handleClose = useCallback(() => {
    dispatch(closeCard({ id: cardId }))
  }, [cardId, dispatch])

  const toggleMinimize = useCallback(() => {
    setMinimized(!minimized)
  }, [minimized, setMinimized])

  const { hovered, ref: hoveredRef } = useHover()

  const ref = useRef(null)

  const fullName = `${applicant?.first_name} ${applicant?.last_name}`

  const baselineScore = useMemo(() => {
    const BASELINE_MODULE_ID = 32
    if (!applicant || !applicant.scores) return 1
    console.log(applicant.scores)
    let score = Math.round(applicant.scores.find(score => score.module_id === BASELINE_MODULE_ID)?.percentile_score ?? 1)

    if (score > 99) {
      score = 99
    } else if (score < 1) {
      score = 1
    }

    return score
  }, [applicant])

  const [{ x, y }, api] = useSpring(
    springConfig
  )

  useGesture(
    {
      onDragStart: () => {
        focus()
      },
      onDrag: (state) => {
        const { active, offset: [x, y] } = state
        api({ x, y })
        setDragging(active)
      },
      onDragEnd: (state) => {
        const { active, canceled, offset: [x, y] } = state
        console.debug('Finished ApplicantCard drag', { active, x, y, canceled, state })
      }
    },
    { target: ref }
  )

  return (
    <animated.div
      style={{
        x: x,
        y: y,
        position: 'fixed',
        width: 'auto',
        right: 100,
        top: 100,
        zIndex: 190,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,.2)'
      }}
      className='beta-applicant-card'
      data-applicant-id={applicantId}
      ref={hoveredRef}
    >
    {!minimized &&
      <Transition mounted={hovered} keepMounted transition='fade-up' exitDelay={350}>
      {(styles) => (
        <Box
          style={styles}
          w='100%'
          pos='absolute'
          >
          <Group
            component={Paper}
            justify='flex-end'
            pb='sm'
            pt='xs'
            ref={ref}
            style={{
              cursor: dragging ? 'grabbing' : 'grab',
              touchAction: 'none',
              transform: 'translateY(-90%)',
              borderRadius: '4px'
            }}
            bg='indigo.0'
            onDoubleClick={toggleMinimize}
            onMouseDown={(e) => e.preventDefault()}
            >
            <CardControl size='md' onClick={toggleMinimize} tooltipLabel='Minimize card'>
              <IconArrowsMinimize />
            </CardControl>
            <CardControl size='md' onClick={handleClose} tooltipLabel='Close card' variant='danger-subtle'>
              <IconX />
            </CardControl>
          </Group>
        </Box>
      )}
      </Transition>
    }
    <Card
      style={{ overflow: 'visible' }}
      mih={!minimized && '70vh'}
      mah='90vh'
      w='35rem'
      p={0}
      onMouseDown={focus}
      >
      {minimized && <MinimizedApplicantCard
        name={fullName}
        baselineScore={baselineScore}
        forwardRef={ref}
        toggleMinimize={toggleMinimize}
        handleClose={handleClose}
        isDragging={dragging}
        />
      }
      <Collapse in={!minimized}>
        {applicant
          ? <ApplicantCard
              applicant={applicant}
              access={access}
              modules={modules}
              statuses={statuses}
              rezview={rezviewContent}
              flagContent={flagContent}
              onClose={handleClose}
              isSimple={true}
              />
          : <LoadingScreen />
        }
      </Collapse>
    </Card>
    </animated.div>
  )
}

function MinimizedApplicantCard ({ name, baselineScore, forwardRef, isDragging, toggleMinimize, handleClose }) {
  let baselineColor = 'green'
  if (baselineScore < 49) {
    baselineColor = 'red'
  } else if (baselineScore < 69) {
    baselineColor = 'orange'
  }

  return (
    <Box ref={forwardRef} style={{ cursor: isDragging ? 'grabbing' : 'grab', touchAction: 'none' }} onDoubleClick={toggleMinimize}>
    <Group justify='space-between' p='sm' bg='indigo.0' onMouseDown={(e) => e.preventDefault()}>
      <Text fz='xl' fw='bold'>{name}</Text>
      <Group style={{ pointerEvents: isDragging && 'none' }}>
        <SemiCircleProgress label={baselineScore} fz={26} filledSegmentColor={baselineColor} value={baselineScore} size={120} />
        <Group gap='xs'>
        <CardControl size='md' onClick={toggleMinimize} tooltipLabel='Maximize card'>
          <IconArrowsMaximize />
        </CardControl>
        <CardControl size='md' onClick={handleClose} tooltipLabel='Close card' variant='danger-subtle'>
          <IconX />
        </CardControl>
        </Group>
      </Group>
    </Group>
    </Box>
  )
}

export function ApplicantCard ({ applicant, rezview, modules, statuses, access, flagContent, isSimple = false, onClose }) {
  const [editing, { open, close }] = useDisclosure(false)
  const [rezviewModal, { open: openRezview, close: closeRezview }] = useDisclosure(false)
  const [flagsModal, { open: openFlags, close: closeFlags }] = useDisclosure(false)

  const isAdmin = useMemo(() => {
    if (!access) return false
    return access.ADMIN || access.DEVELOPER
  }, [access])

  const closeCard = () => {
    if (onClose) {
      onClose()
    } else {
      const event = new CustomEvent('appcard:close')
      window.dispatchEvent(event)
    }
  }

  const flagModalTitle = (
    <Text fw='bold'>Flags: {applicant.first_name} {applicant.last_name}</Text>
  )

  return (
    <>
      <ScrollArea.Autosize type='auto' mah='90vh'>
        <Box p='xs'>
          <Group px='xs' mb='xs' justify='space-between'>
            <Group gap='xs'>
              <CardAction icon={<IconId size={ICON_SIZE} />} label='Show summary' onClick={openRezview} disabled={!rezview} />
              {applicant?.flags.length > 0
                ? <Indicator size={16} offset={6} label={applicant.flags.length}>
                    <CardAction
                      icon={<IconFlag size={ICON_SIZE} />}
                      label='Show flags'
                      disabled={!flagContent}
                      onClick={openFlags}
                    />
                  </Indicator>
                : <CardAction icon={<IconFlagOff size={ICON_SIZE} />} label='No flags' />
              }
              <CardAction
                icon={<IconClipboardData size={ICON_SIZE} />}
                label='Candidate Report'
                component={Anchor}
                href={urls.applicantReport(applicant.cycle_id, applicant.id)}
                />
              {
                (isAdmin && applicant?.ks_respondent?.id) &&
                <CardAction icon={<IconList size={ICON_SIZE} />} label='KeySurvey Data' component={Anchor} href={urls.ksResponses(applicant.cycle_id, applicant.id)} />
              }
            </Group>
            <Group>
              <CardAction icon={<IconFile size={ICON_SIZE} />} label='Files' component={Anchor} href={urls.files(applicant.cycle_id, applicant.id)} />
              <CardAction icon={<IconMail size={ICON_SIZE} />} label='Contact Applicant' component={Anchor} href={urls.applicantContact(applicant.cycle_id, applicant.id)} />
              <CardAction icon={<IconTrash size={ICON_SIZE} />} label='Delete/Refresh Applicant' variant='danger-subtle' component={Anchor} href={urls.applicantDelete(applicant.cycle_id, applicant.id)} />
              {!isSimple && <CardAction icon={<IconSquareX size={ICON_SIZE} />} label='Close Card' onClick={closeCard} />}
            </Group>
          </Group>
          {!editing && <Flex justify='flex-end' px='xs'><Button onClick={open}>Edit</Button></Flex>}
          <Info applicant={applicant} editing={editing} closeEdit={close} isAdmin={isAdmin} statuses={statuses} />
          <Flex justify='center' p='xs'>
            {modules &&
              <Scores applicant={applicant} editing={editing} modules={modules} statuses={statuses} />
            }
          </Flex>
          <Box hidden={editing}>
            <Discussion applicantId={applicant.id} />
          </Box>
        </Box>
      </ScrollArea.Autosize>

      <Modal opened={flagsModal} onClose={closeFlags} title={flagModalTitle}>
        {flagContent && <div dangerouslySetInnerHTML={{ __html: flagContent }}></div>}
      </Modal>

      <Modal opened={rezviewModal} onClose={closeRezview} size='xl'>
        {rezview && <div dangerouslySetInnerHTML={{ __html: rezview }}></div>}
      </Modal>
    </>
  )
}

function CardAction ({ icon, label, ...props }) {
  return (
    <Tooltip label={label}>
      <ActionIcon size='xl' {...props}>
        {icon}
      </ActionIcon>
    </Tooltip>
  )
}

function LoadingScreen ({ simple }) {
  return (
    <Box p='xs'>
      <Group px='xs' mb='xs' justify='space-between'>
        <Group gap='xs'>
          <CardAction icon={<IconId size={ICON_SIZE} />} label='Show summary' disabled={true} />
          <CardAction icon={<IconFlag size={ICON_SIZE} />} label='Show flags' disabled={true} />
          <CardAction icon={<IconClipboardData size={ICON_SIZE} />} label='Candidate Report' disabled={true} />
          <CardAction icon={<IconList size={ICON_SIZE} />} label='Keysurvey Data' disabled={true} />
        </Group>
        <Group>
          <CardAction icon={<IconFile size={ICON_SIZE} />} label='Files' disabled={true} />
          <CardAction icon={<IconMail size={ICON_SIZE} />} label='Contact Applicant' disabled={true} />
          <CardAction icon={<IconTrash size={ICON_SIZE} />} label='Delete/Refresh Applicant' variant='danger-subtle' disabled={true} />
          {!simple && <CardAction icon={<IconSquareX size={ICON_SIZE} />} label='Close Card' disabled={true} />}
        </Group>
      </Group>
      <Flex justify='flex-end'>
        <Button disabled={true} mx='xs'>Edit</Button>
      </Flex>
      <Flex justify='center' mt='xs'>
        <Skeleton width='8rem' height={14} />
      </Flex>
      <Flex px='sm' justify='space-between' mt='lg'>
        <Stack>
          <Group>
            <Skeleton circle height={16} />
            <Skeleton width='8rem' height={8} />
          </Group>
          <Group>
            <Skeleton circle height={16} />
            <Skeleton width='8rem' height={8} />
          </Group>
          <Group>
            <Skeleton circle height={16} />
            <Skeleton width='8rem' height={8} />
          </Group>
        </Stack>
        <Box w='10rem'>
          <Flex direction='column' justify='center' align='center' gap='xs'>
            <Skeleton height={84} circle />
            <Skeleton width='4rem' height={14} />
          </Flex>
        </Box>
      </Flex>
      <Flex p='xl' mt='xs' justify='center'>
        <Stack gap={2}>
          <Group gap={2}>
            <Skeleton width={300} height={16} />
            <Skeleton width={20}height={16} />
          </Group>
          <Group gap={2}>
            <Skeleton width={300} height={16} />
            <Skeleton width={20}height={16} />
          </Group>
          <Group gap={2}>
            <Skeleton width={300} height={16} />
            <Skeleton width={20}height={16} />
          </Group>
          <Group gap={2}>
            <Skeleton width={300} height={16} />
            <Skeleton width={20}height={16} />
          </Group>
          <Group gap={2}>
            <Skeleton width={300} height={16} />
            <Skeleton width={20}height={16} />
          </Group>
        </Stack>
      </Flex>
      <Flex p='xl' justify='center'>
        <Group align='center'>
          <Skeleton width={360} height={50} />
          <Button.Group>
            <Button disabled={true} size='sm'><IconSend /></Button>
            <Button disabled={true} px='0.15rem' size='sm'><IconChevronDown /></Button>
          </Button.Group>
        </Group>
      </Flex>
    </Box>
  )
}

function FailureScreen () {
  return (
    <Flex align='center' justify='center' h='70vh'>
      <Stack align='center' justify='center'>
        <h1>Failed to load applicant</h1>
        <ThemeIcon size='xl' color='red' variant='subtle'><IconCircleXFilled size='4rem' /></ThemeIcon>
      </Stack>
    </Flex>
  )
}

function IdleScreen () {
  return (
    <Flex align='center' justify='center' h='70vh'>
      <Stack align='center' justify='center'>
        <h1>Select an applicant from the table</h1>
        <ThemeIcon size='xl' variant='subtle'><IconUserCircle size='4rem' /></ThemeIcon>
      </Stack>
    </Flex>
  )
}

ApplicantCardFrame.propTypes = {
  applicantId: PropTypes.number,
  cid: PropTypes.string,
  isSimple: PropTypes.bool,
  onClose: PropTypes.func
}

CardControl.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  forwardRef: PropTypes.object,
  iconProps: PropTypes.object,
  tooltipLabel: PropTypes.string
}

LoadingScreen.propTypes = {
  simple: PropTypes.bool
}

MobileWrapper.propTypes = {
  cid: PropTypes.string,
  applicantId: PropTypes.number,
  betaView: PropTypes.bool
}

BetaCardWrapper.propTypes = {
  cardId: PropTypes.string,
  cycleData: PropTypes.object,
  access: PropTypes.object
}

MinimizedApplicantCard.propTypes = {
  name: PropTypes.string,
  baselineScore: PropTypes.number,
  forwardRef: PropTypes.object,
  isDragging: PropTypes.bool,
  toggleMinimize: PropTypes.func,
  handleClose: PropTypes.func
}

ApplicantCard.propTypes = {
  applicant: PropTypes.object,
  rezview: PropTypes.string,
  modules: PropTypes.array,
  statuses: PropTypes.array,
  access: PropTypes.object,
  flagContent: PropTypes.string,
  isSimple: PropTypes.bool,
  onClose: PropTypes.func
}

CardAction.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string
}
