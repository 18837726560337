/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ReactTable, { ReactTableContextsProvider } from '../../../../core/ReactTable/ReactTable';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { NamespaceContext, TableDataContext } from '../../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';
import { useLazyStageProgresses, useStageProgresses, useStageProgressesTable } from './StageProgressesHooks';

export const StageProgressesTable = memo(function StageProgressesTable ({ cycleId, stageId = null, passId = null, namespace = 'stage-progresses' }) {
  const navigate = useNavigate()
  const [stageProgresses] = useLazyStageProgresses(namespace, cycleId, stageId, passId)

  const handleRowClicked = useCallback((row) => {
    console.debug('Stage progresses table row clicked.', row)
    if (row === undefined) return true
    const progressId = row.dataset.rowid
    const intProgressId = parseInt(progressId)
    const stageProgress = stageProgresses.items?.filter(c => c.id === intProgressId)[0] ?? null
    if (!stageProgress) {
      console.warn('Unable to find stage progress with id matching clicked id.', progressId, cycleId, stageProgress, stageProgresses)
      return true
    }
    console.debug('Navigating to stage progress detail', { progressId, cycleId, stageProgress, row })
    const path = generatePath(CyclePassRoute.CycleStageProgressDetail, { cycleId, progressId })
    console.debug('Generated path for stage progress', { path, stageProgress })
    navigate(path)
  }, [stageProgresses, cycleId, navigate])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useStageProgressesTable(stageId, passId)

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <StageProgressesTableDataProvider cycleId={cycleId} stageId={stageId} passId={passId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} />
      </StageProgressesTableDataProvider>
    </ReactTableContextsProvider>
  )
})

export function StageProgressesTableDataProvider ({ cycleId, stageId, passId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useStageProgresses(namespace, cycleId, stageId, passId, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating StageProgressesTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Stage progresses table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
