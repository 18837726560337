/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useMemo } from 'react'
import Content from '../../layout/Content'
import { useAssessments } from './AssessmentHooks'
import { usePublishedAssessments } from '../../util/PublishedAssessmentHooks'
import PropTypes from 'prop-types'
import { Anchor, List, Space, Title } from '@mantine/core'
import _ from 'lodash'
import { randomId } from '@mantine/hooks'

export default function MediaAssessmentUsage ({ media }) {
  const assessmentsParams = useMemo(() => {
    return { limit: 0, media: media.id }
  }, [media.id])
  const [assessments] = useAssessments(assessmentsParams)
  const [publishedAssessments] = usePublishedAssessments({ limit: 0, media: media.id })

  return (
    <Content>
      {
        assessments.length > 0 &&
        (
          <>
            <Title order={5} color='dimmed'>Used in the following unpublished assessments</Title>
            <List
              listStyleType='none'
              withPadding
            >
              {
                _.map(assessments, assessment => <List.Item key={randomId()}><Anchor href={`/build/assessments/${assessment.id}/preview`}>{assessment.internal_name}</Anchor></List.Item>)
              }
            </List>
          </>
        )
      }
      <Space h='lg' />
      {
        publishedAssessments.items.length > 0 &&
        (
          <>
            <Title order={5} color='dimmed'>Used in the following published assessments</Title>
            <List
              listStyleType='none'
              withPadding
            >
              {
                _.map(publishedAssessments.items, publishedAssessment => <List.Item key={randomId()}><Anchor href={`/testenv/assessments/${publishedAssessment.id}/view`}>{publishedAssessment.name}</Anchor></List.Item>)
              }
            </List>
          </>
        )
      }
    </Content>
  )
}

MediaAssessmentUsage.propTypes = {
  media: PropTypes.shape({
    id: PropTypes.number
  })
}
