/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Box, Button, Group, Paper, Radio, SimpleGrid, TextInput, Title } from '@mantine/core'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { previewTemplate } from '../../../js/api/applicant_repository'
import { getTemplate } from '../../../js/api/email_template_repository'
import EmailEditor from '../../core/email/EmailEditor'
import DateSelection from './DateSelection'
import TemplateSelection from './TemplateSelection'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Email ({ form, index, templates, previewApplicant }) {
  const [preview, setPreview] = useState(false)
  const [previewBody, setPreviewBody] = useState('')
  const [previewSubject, setPreviewSubject] = useState('')
  const [previewSendAt, setPreviewSendAt] = useState()

  useEffect(() => {
    if (preview) {
      previewTemplate(previewApplicant, { subject: form.values.messages[index].subject || '', body: form.values.messages[index].body || '' }).then(preview => {
        setPreviewSubject(preview.subject)
        setPreviewBody(preview.body)
        setPreviewSendAt(form.values.messages[index].send_at)
      })
    }
  }, [previewApplicant, preview])

  return (
    <Paper shadow='sm' p='lg' radius='md' withBorder>
      <SimpleGrid cols={1}>
        <Title order={6} c='dimmed'>Email</Title>
        <Box display={!preview ? 'block' : 'none'}><Editor previewApplicant={previewApplicant} setPreview={setPreview} form={form} index={index} templates={templates}/></Box>
        <Box display={preview ? 'block' : 'none'}><Preview form={form} setPreview={setPreview} index={index} previewSendAt={previewSendAt} previewSubject={previewSubject} previewBody={previewBody}/></Box>
      </SimpleGrid>
    </Paper>
  )
}

function Editor ({ previewApplicant, setPreview, form, index, templates }) {
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (form.values.messages[index].template !== null) {
      getTemplate(form.values.messages[index].template).then(t => setTemplate(t))
    } else {
      setTemplate(null)
    }
  }, [form.values.messages[index].template])

  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' disabled={!previewApplicant} onClick={() => setPreview(true)}>Preview</Button>
        <Button size='xs' variant='light' color='red' onClick={() => form.removeListItem('messages', index)}>Remove</Button>
      </Group>
      <Date form={form} index={index}/>
      <TemplateSelection form={form} index={index} templates={templates} getTemplate={getTemplate} />
      <TextInput label='Subject' withAsterisk {...form.getInputProps(`messages.${index}.subject`)} />
      <EmailEditor form={form} name={`messages.${index}.body`} content={template?.template ?? ''} category={template?.category?.id} platform={0}/>
    </SimpleGrid>
  )
}

function Date ({ form, index }) {
  const [when, setWhen] = useState('immediately')

  const updateWhen = (value) => {
    setWhen(value)

    if (value === 'immediately') {
      form.setFieldValue(`messages.${index}.send_at`, null)
    } else {
      form.setFieldValue(`messages.${index}.send_at`, dayjs().add(1, 'day').toDate())
    }
  }

  return (
    <>
      <SimpleGrid cols={1}>
        <Radio.Group
          name={`send-now.${index}`}
          label='When do you want to send this message?'
          value={when}
          onChange={(value) => updateWhen(value)}
        >
          <Radio value='immediately' label='Send immediately' style={{ marginBottom: '5px' }}/>
          <Radio value='later' label='Send later'/>
        </Radio.Group>
        {
          when === 'later' &&
          <DateSelection form={form} index={index}/>
        }
      </SimpleGrid>
    </>
  )
}

function Preview ({ form, setPreview, index, previewSendAt, previewSubject, previewBody }) {
  return (
    <SimpleGrid cols={1}>
      <Group spacig='xs'>
        <Button size='xs' variant='light' color='blue' onClick={() => setPreview(false)}>Edit</Button>
        <Button size='xs' variant='light' color='red' onClick={() => form.removeListItem('messages', index)}>Remove</Button>
      </Group>
      <h4>Send At</h4>
      <div style={{ marginBottom: '20px' }}>{previewSendAt ? dayjs(previewSendAt).format('MM/DD/YYYY') : 'Immediately'}</div>
      <h4>Subject</h4>
      <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewSubject) }}></div>
      <h4>Body</h4>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewBody) }}></div>
    </SimpleGrid>
  )
}
