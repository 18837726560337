const allGeneratedModules = require.context('../enums', false, /\.js$/)

const allGenerated = Object.fromEntries(allGeneratedModules.keys().map(key => [key, allGeneratedModules(key)]))

const generatedOptionsDict = {}

function buildOptionsDict () {
  for (const [generatedName, generatedModule] of Object.entries(allGenerated)) {
    let fromIdFunction = null
    let idFromFunction = null
    let enumDict = null
    const enumName = generatedName.substring(2, generatedName.length - 3)
    for (const [generatedMemberName, generatedMember] of Object.entries(generatedModule)) {
      // console.debug('Generated: member_name|enum|module|member', generatedMemberName, enumName, generatedName, generatedMember)
      if (generatedMemberName === enumName) {
        enumDict = generatedMember
      } else if (generatedMemberName.startsWith('idFrom') && generatedMemberName.includes(enumName)) {
        idFromFunction = generatedMember
      } else if (generatedMemberName.endsWith('FromId') && generatedMemberName.includes(enumName.charAt(0).toLowerCase() + enumName.slice(1))) {
        fromIdFunction = generatedMember
      }
      if (fromIdFunction && idFromFunction && enumDict) {
        break
      }
    }
    if (fromIdFunction && idFromFunction && enumDict) {
      generatedOptionsDict[enumName] = Object.freeze(Object.values(enumDict).map(element => {
        return Object.freeze({ label: element, value: idFromFunction(element).toString() })
      }))
    } else {
      console.error(
        'Unable to find expected generated functions in module.',
        { enumDict, fromIdFunction, idFromFunction, enumName, generatedName, generatedModule }
      )
    }
  }
}

/**
 * To get the enum name from its dict, use Object.keys({ specifiedEnumDict })[0]
 * @param {string} enumName
 */
export function getOptionsFromEnumName (enumName) {
  if (!Object.keys(generatedOptionsDict).length) {
    console.debug('Initializing enum options dict.', enumName, generatedOptionsDict)
    buildOptionsDict()
  }
  return generatedOptionsDict[enumName ?? ''] ?? []
}
