/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { Avatar, Card, Group, Space, Stack, Text } from '@mantine/core';
import { renderDateFromSource } from '../formatUtil';
import { LockableColor } from './LockableIcon';

export const LocationSummaryCard = memo(function LocationSummaryCard ({ location, isAdmin = false }) {
  const activeColor = location.organization?.active ? LockableColor.Active : LockableColor.Locked
  return (
    <>
      <Card shadow="sm" padding={0} radius="md" withBorder>
        <Card.Section mb='xs'>
          <Group justify='center'>
            <Avatar
              color={activeColor}
              alt={`${(location.organization?.name || 'Organization Name Missing')} at ${(location.name || 'Location Name Missing')}`}
              size='xl'
            >
              {(location.organization?.name || location.name)?.match(/\b\w/g)?.join('').toUpperCase() || '?'}
            </Avatar>
            <Space w='md' />
            <Stack gap='xxs'>
              <Text size='md' ta='center'>
                {location.name || 'Location Name Missing'}{(location.id && isAdmin) ? ` #${location.id}` : ''}
              </Text>
              <Text c={location.organization ? 'inherit' : 'dimmed'} size={location.organization ? 'lg' : 'xxs'} ta='center'>
                {location.organization?.name || (location.organization ? 'Organization Name Missing' : 'Organization Missing')}
              </Text>
            </Stack>
          </Group>
        </Card.Section>

        <Group justify='center' my={0}>
          {!!location.created_at && <Stack gap={0}><Text size="sm" c="dimmed" ta='center'>Created</Text><Text size="sm" c="dimmed" ta='center'>{renderDateFromSource(location.created_at)}</Text></Stack>}
          {!!(location.created_at && location.updated_at) && <Space w='md' />}
          {!!location.updated_at && <Stack gap={0}><Text size="sm" c="dimmed" ta='center'>Updated</Text><Text size="sm" c="dimmed" ta='center'>{renderDateFromSource(location.updated_at)}</Text></Stack>}
        </Group>
      </Card>
    </>
  )
})
