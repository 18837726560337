import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ActionIcon,
  Anchor,
  Badge,
  Center,
  Group,
  Skeleton,
  Table,
  Tabs,
  Tooltip
} from '@mantine/core'
import {
  IconCalendarRepeat,
  IconClipboardText,
  IconEdit,
  IconInfoCircle,
  IconLink,
  IconRefresh,
  IconSettingsAutomation,
  IconTool,
  IconTrash
} from '@tabler/icons-react'
import ErrorLoading from '../../core/ErrorLoading'
import { useGetCycleQuery } from '../../../redux/query/hire/cyclesApi.slice'
import styles from './CycleManagementDetail.module.scss'
import { format } from '../../../util/date.util'
import { useGetLoggedInAccountQuery } from '../../../redux/query/account/accountsApi.slice'
import { isGranted } from '../../../util/account/account.util'
import { CycleStageReplacementAppExternalWrapper } from './invites/stages/CycleStageReplacementApp';

const CycleManagementDetail = memo(function CycleManagementDetail ({ id, selected }) {
  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: account, error: accountError, isLoading: accountLoading } = useGetLoggedInAccountQuery()

  if (cycleError || accountError) {
    return <ErrorLoading />
  }

  if (cycleLoading || accountLoading) {
    return <>
      <Skeleton height={25} width={350} mb='sm' />
      <Skeleton height={25} width={100} mb='sm' />
      <Skeleton height={25} width={350} mb='xs'/>
      <Skeleton height={25} width={350} mb='md' />
      <Skeleton height={25} mb='lg' />
    </>
  }

  const tabs = {
    edit: `/hire/report-results/${id}/edit`,
    specs: `/hire/report-results/${id}/specs`,
    details: `/cycles/${id}/cycleDetails`,
    batteries: `/hire/cycles/${id}/batteries`,
    triggers: `/hire/cycles/${id}/triggers`
  }

  return (
    <>
      <Group><Anchor href={`/cycles/${cycle.id}/results`} size='xl' truncate='end' fw={700} span>{cycle.name}</Anchor><Badge color={cycle.active === 0 ? 'green.6' : 'red.6'}>{cycle.active === 0 ? 'Open' : 'Closed'}</Badge></Group>
      <ActionIcon.Group>
        {
          cycle.job_posting_site !== '' &&
          <Tooltip label='Job Posting Site'>
            <ActionIcon color='gray.6' variant='subtle' aria-label='Job Posting Site' onClick={() => window.open(cycle.job_posting_site, '_blank')}>
              <IconLink />
            </ActionIcon>
          </Tooltip>
        }
        <Tooltip label='Update Cycle'>
          <ActionIcon color='gray.6' variant='subtle' aria-label='Update Cycle' onClick={() => (window.location.href = `/cycles/${id}/update`)}>
            <IconCalendarRepeat />
          </ActionIcon>
        </Tooltip>
        <Tooltip label='Refresh Cycle'>
          <ActionIcon color='gray.6' variant='subtle' aria-label='Refresh Cycle' onClick={() => (window.location.href = `/cycles/${id}/refresh`)}>
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
        {
          isGranted(account, 'ROLE_DEVELOPER') &&
          <Tooltip label='Delete Cycle'>
            <ActionIcon color='gray.6' variant='subtle' aria-label='Delete Cycle' onClick={() => (window.location.href = `/cycles/${id}/delete`)}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        }
      </ActionIcon.Group>
      <Table className={styles.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th><Center>Organization</Center></Table.Th>
            <Table.Th><Center>Location</Center></Table.Th>
            <Table.Th><Center>Open Date</Center></Table.Th>
            <Table.Th><Center>Close Date</Center></Table.Th>
            <Table.Th><Center>Project Manager</Center></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td><Center>{cycle.location.organization.name}</Center></Table.Td>
            <Table.Td><Center>{cycle.location.name}</Center></Table.Td>
            <Table.Td><Center>{format(cycle.start_date)}</Center></Table.Td>
            <Table.Td><Center>{typeof cycle.end_date !== 'undefined' ? format(cycle.end_date) : '-'}</Center></Table.Td>
            <Table.Td><Center>{cycle.location.organization.manager?.email ?? '-'}</Center></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Tabs
        radius='md'
        variant='default'
        mb='xl'
        value={tabs[selected]}
        /* eslint-disable-next-line no-return-assign */
        onChange={value => window.location.href = value}
      >
        <Tabs.List>
          <Tabs.Tab value={tabs.edit} color='yellow.6' leftSection={<IconEdit size='1rem'/>}>Edit</Tabs.Tab>
          <Tabs.Tab value={tabs.specs} color='green.6' leftSection={<IconTool size='1rem'/>}>Specs</Tabs.Tab>
          <Tabs.Tab value={tabs.details} color='green.6' leftSection={<IconInfoCircle size='1rem'/>}>Details</Tabs.Tab>
          <Tabs.Tab value={tabs.batteries} color='blue.6' leftSection={<IconClipboardText size='1rem'/>}>Batteries</Tabs.Tab>
          <Tabs.Tab value={tabs.triggers} color='violet.6' leftSection={<IconSettingsAutomation size='1rem'/>}>Triggers</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <CycleStageReplacementAppExternalWrapper cycleId={id} />
    </>
  )
})

export default CycleManagementDetail;

CycleManagementDetail.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  selected: PropTypes.string.isRequired
}
