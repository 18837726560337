/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState } from 'react'
import { getPublishedAssessments } from '../../js/api/published_assessment_repository'

export function usePublishedAssessments (params) {
  const [collection, setCollection] = useState({ items: [], total: 0, limit: 0, page: 1 })

  const refresh = () => getPublishedAssessments(params).then(collection => setCollection(collection))

  useEffect(() => {
    getPublishedAssessments(params).then(collection => setCollection(collection))
  }, [])

  return [collection, refresh]
}
