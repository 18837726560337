/* eslint react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { NumberInput, ThemeIcon } from '@mantine/core';

/**
 * @param {InputFilter} filter
 * @param {string|number} selected
 * @param {function} updateFilter
 * @param {object} allSelected
 */
export function FilterNumberInput ({ filter, selected, updateFilter, allSelected }) {
  const [value, setValue] = useState(selected === '' ? null : selected);
  const [debounced] = useDebouncedValue(value, 400)
  const lastDebouncedRef = useRef(selected)
  const updateFilterRef = useRef(updateFilter)
  const filterId = filter.id

  useEffect(() => {
    updateFilterRef.current = updateFilter
  }, [updateFilter])

  useEffect(() => {
    if (debounced !== selected) {
      if (lastDebouncedRef.current === selected) {
        lastDebouncedRef.current = debounced
        updateFilterRef.current(filterId, debounced === '' ? null : debounced)
      } else {
        lastDebouncedRef.current = selected
        setValue(selected)
      }
    }
  }, [debounced, selected, filterId])

  const iconColor = selected ? (filter.color ?? 'blue') : 'dimmed'
  const numberProps = filter.numberProps ?? { allowDecimal: false }
  const disabled = filter.getDisabled?.(allSelected) ?? false
  const required = filter.getRequired?.(allSelected) ?? false

  return (
    <NumberInput
      { ...numberProps }
      label={filter.label}
      description={filter.description ?? null}
      placeholder={filter.placeholder ?? 'Type here'}
      leftSection={filter.leftSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.leftSection}</ThemeIcon>}
      value={value}
      onChange={setValue}
      rightSection={filter.rightSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.rightSection}</ThemeIcon>}
      disabled={disabled}
      required={required}
    />
  )
}
