import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorLoading from '../../../core/ErrorLoading'
import Loading from '../../../core/Loading'
import {
  useGetHiringTemplateEmailTemplatesQuery,
  useGetHiringTemplateQuery
} from '../../../../redux/query/build/hiringTemplatesApi.slice'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'
import { useUpdateTriggerMutation } from '../../../../redux/query/hire/triggersApi.slice'
import TriggerEditForm from './TriggerEditForm'
import LinkedBatteriesContext from '../../../../contexts/LinkedBatteriesContext'
import EmailTemplatesContext from '../../../../contexts/EmailTemplatesContext'

export default function HiringTemplateTriggerEdit () {
  const { id, triggerId } = useParams()
  const navigate = useNavigate()
  const { data: hiringTemplate, error: hiringTemplateError, isLoading: hiringTemplateLoading } = useGetHiringTemplateQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetHiringTemplateEmailTemplatesQuery(id)

  const trigger = _.find(hiringTemplate?.triggers ?? [], { id: parseInt(triggerId) })

  const [updateTrigger] = useUpdateTriggerMutation()

  if (hiringTemplateError || emailTemplatesError) {
    return <ErrorLoading />
  }

  if (hiringTemplateLoading || emailTemplatesLoading) {
    return <Loading />
  }

  const onSubmit = data => {
    updateTrigger({ id: trigger.id, ...data })
      .unwrap()
      .then(function () {
        showNotification({
          message: 'Successfully edited a trigger',
          color: 'green'
        })
        navigate(`/build/hiring-templates/${hiringTemplate.id}/triggers`)
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error updating this trigger',
            color: 'red'
          })
        }
      })
  }

  return (
    <LinkedBatteriesContext.Provider value={hiringTemplate.hiring_template_batteries}>
      <EmailTemplatesContext.Provider value={emailTemplates.data}>
        <TriggerEditForm
          trigger={trigger}
          onSubmit={onSubmit}
          statusSet={hiringTemplate.status_set}
          emailTemplates={emailTemplates}
        />
      </EmailTemplatesContext.Provider>
    </LinkedBatteriesContext.Provider>
  )
}
