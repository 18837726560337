/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react/prop-types: 0 */

import { ActionIcon, Anchor, Box, Button, Collapse, Divider, Menu, NavLink, ScrollArea, ThemeIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCaretDownFilled, IconChevronRight } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import classes from './Dropdown.module.scss';

/**
 * @param {object} params
 * @param params.target - The target component
 * @param params.items - The items to display in the dropdown
 * @param params.children - The children to display in the dropdown
 * @param params.openDelay - The delay before opening the dropdown (useful for hover)
 * @param params.closeDelay - The delay before closing the dropdown
 * @param params.row - The row to pass to the items
 * @param params.extraAlwaysOpen - If true, the dropdown will always be open and the collapse options will be hidden
 * @param params.noHover - If true, the dropdown will only open on click  - note 'noHover' does not exist in signature, may be passed through ...props.
 * @param params.targetProps Props passed to dropdown target.
 * @param props - The props to pass to the dropdown
 * @returns {JSX.Element} The rendered dropdown component.
 */
export default function Dropdown ({
  target,
  items = [],
  children,
  openDelay = 200,
  closeDelay = 250,
  row,
  extraAlwaysOpen = false,
  trigger = 'hover',
  targetProps = null,
  ...props
}) {
  items = Object.values(items)

  const [opened, handlers] = useDisclosure(false)
  const [extraOpened, extraHandlers] = useDisclosure(false)
  const [frozen, setFrozen] = useState(false)
  const mah = props.mah ?? 350

  const freeze = () => {
    setFrozen(true)
    handlers.open()
  }

  const unfreeze = () => {
    setFrozen(false)
    handlers.close()
  }

  const allTargetProps = {
    ...(targetProps ?? {}),
    variant: frozen ? `active-${target.props?.variant || 'subtle'}` : target.props?.variant || 'subtle', // either active-{variant} or {variant}, defaults to subtle
    onClick: frozen ? unfreeze : freeze
  }

  const menuProps = useMemo(() => {
    return {
      opened: trigger === 'hover' ? undefined : opened,
      openDelay: openDelay,
      closeDelay: closeDelay,
      onKeyDown: (event) => { console.log(event); event.key === 'Escape' && handlers.close() },
      closeOnEscape: true,
      closeOnClickOutside: true,
      trigger: frozen ? 'click' : trigger ?? 'hover',
      onClose: unfreeze,
      ...props
    }
  }, [frozen])

  const OpenedIcon = () => {
    return (
      <ThemeIcon variant='transparent' size='sm' color='dark' className={classes.itemExpandedIcon}><IconChevronRight /></ThemeIcon>
    )
  }

  return items.length > 0
    ? (
    <Menu closeOnItemClick className={classes.root} {...menuProps}>
      <Menu.Target {...allTargetProps} className={classes.target}>
        {target}
      </Menu.Target>
      <Menu.Dropdown className={classes.drodpown}>
      <ScrollArea.Autosize mah={mah} className={classes.dropdownContent}>
        {items.map((item, index) => {
          let itemProps = { ...item }

          if (row) {
            if (item.metadata) {
              if (item.metadata.visible && item.metadata.visible(row) === false) {
                return null
              }
            }

            if (item.onClick) {
              itemProps.onClick = () => item.onClick(row.id, row)
            }

            if (typeof item.href === 'function') {
              itemProps.href = item.href(row.id)
            }
          }

          delete itemProps.metadata

          if (item.metadata) {
            if (!item.href || item.component === Button) {
              itemProps = {
                rightSection: item.metadata.items?.length > 0 && <OpenedIcon />,
                fullWidth: true,
                unstyled: true,
                ...itemProps
              }
            }
          }

          if (item.href) {
            itemProps.component = Anchor
          }

          return item.metadata?.items?.length === 0
            ? (
            <Menu.Item key={`item-${index}`} className={classes.dropdownItem} {...itemProps}>
              {item.label}
            </Menu.Item>
              )
            : (
              <NavLink
              component={Button}
              key={`item-${index}`}
              className={classes.dropdownItem}
              childrenOffset={0}
              {...itemProps}>
              {item.metadata?.items?.map((subItem, subIndex) => {
                const subItemProps = { ...subItem }

                delete subItemProps.metadata
                return (
                  <Menu.Item component={Box} className={classes.menuItem} key={`subitem-${index}-${subIndex}`}>
                  <NavLink
                    className={classes.dropdownItem}
                    {...subItemProps}
                    />
                  </Menu.Item>
                )
              })}
            </NavLink>
              )
        })}
        </ScrollArea.Autosize>
        {children &&
        <>
          {extraAlwaysOpen
            ? (
              <Box mt='sm'>
                { children }
              </Box>
              )
            : (
              <>
                <Divider label={<ActionIcon variant='subtle' onClick={() => extraHandlers.toggle()} className={classes.extrasTarget} data-expanded={extraOpened}><IconCaretDownFilled /></ActionIcon>} />
                <Collapse in={extraOpened} mt='sm'>
                  {children}
                </Collapse>
              </>
              )
        }
        </>
        }
      </Menu.Dropdown>
    </Menu>
      )
    : (
    <Menu {...menuProps}>
      <Menu.Target {...allTargetProps}>
          {target}
        </Menu.Target>
      <Menu.Dropdown p='sm'>
        {children}
      </Menu.Dropdown>
    </Menu>
      )
}
