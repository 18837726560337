/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { Progress } from '@mantine/core'
import zxcvbn from 'zxcvbn'

export default function PasswordStrength ({ password, options = [], size = 'xl' }) {
  const score = zxcvbn(password, options).score
  const [meter, setMeter] = useState({
    label: 'Very weak',
    color: 'red'
  })

  useEffect(() => {
    setMeter(() => {
      if (score === 0) {
        return {
          label: 'Very weak',
          color: 'red'
        }
      } else if (score === 1) {
        return {
          label: 'Weak',
          color: 'yellow'
        }
      } else if (score === 2) {
        return {
          label: 'Medium',
          color: 'orange'
        }
      } else if (score === 3) {
        return {
          label: 'Strong',
          color: 'lime'
        }
      } else if (score === 4) {
        return {
          label: 'Very strong',
          color: 'green'
        }
      }
    })
  }, [password])

  return <Progress value={password === '' ? 0 : ((score + 1) / 5) * 100} label={meter.label} aria-label={'New password strength: ' + meter.label} color={meter.color} size={size} radius='xl'/>
}
