import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries'
import _ from 'lodash'

export const hiringViewsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHiringViews: builder.query({
      query: (params) => `hiring-views${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.HiringViews, id: id })),
              { type: ApiTags.HiringViews, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.HiringViews, id: ApiAbstractIds.PartialList }]
    }),
    getHiringViewDetail: builder.query({
      query: id => `hiring-views/${id}/details`,
      providesTags: (result, error, id) => [{ type: ApiTags.HiringViews, id: id }]
    }),
    deleteHiringView: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `hiring-views/${id}`,
        method: ApiMethods.Delete,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.HiringViews, id: id }, { type: ApiTags.HiringViewsDetails, id: id }]
    }),
    getHiringViewCycles: builder.query({
      query: ({ id, ...params }) => `hiring-views/${id}/details${paramsToQuery(params)}`,
      transformResponse: (response) => ({
        items: response.cycles, page: 1, limit: _.size(response.cycles), total: _.size(response.cycles)
      }),
      providesTags: (result, error, { id: hiringViewId }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.HiringViewsCycles, id: id })),
              { type: ApiTags.HiringViewsDetails, id: hiringViewId }
            ]
          : [{ type: ApiTags.HiringViewsDetails, id: hiringViewId }]
    })
  })
})

export const { useGetHiringViewsQuery, useGetHiringViewDetailQuery, useDeleteHiringViewMutation, useGetHiringViewCyclesQuery } = hiringViewsApi
