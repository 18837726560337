import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Group, Modal, SimpleGrid } from '@mantine/core'
import TriggerView from './TriggerView'
import { showNotification } from '@mantine/notifications'

export default function TriggersList ({ triggers, emailTemplates, toggleTrigger, editTrigger, deleteTrigger }) {
  const [triggerToDelete, setTriggerToDelete] = useState(false)

  const remove = trigger => {
    deleteTrigger(trigger)
      .then(() => {
        showNotification({
          message: 'Trigger deleted successfully!',
          color: 'success',
          autoClose: 3000
        })
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error deleting this trigger',
            color: 'red'
          })
        }
      })
      .finally(setTriggerToDelete(false))
  }

  return (
    <>
      <Modal opened={!!triggerToDelete} onClose={() => setTriggerToDelete(false)} title='Are you sure you want to delete this trigger?'>
        <Group spacing='xs'>
          <a className='tiny button radius alert' onClick={() => remove(triggerToDelete) }>Delete</a>
          <a className='tiny button radius secondary' onClick={() => setTriggerToDelete(false)}>Cancel</a>
        </Group>
      </Modal>
      <SimpleGrid cols={1}>
        {triggers.map(trigger => (
          <TriggerView
            key={trigger.id}
            trigger={trigger}
            emailTemplates={emailTemplates}
            toggleTrigger={toggleTrigger}
            editTrigger={editTrigger}
            deleteTrigger={setTriggerToDelete}
          />
        ))
        }
      </SimpleGrid>
    </>
  )
}

TriggersList.propTypes = {
  triggers: PropTypes.array.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  toggleTrigger: PropTypes.func.isRequired,
  editTrigger: PropTypes.func.isRequired,
  deleteTrigger: PropTypes.func.isRequired
}
