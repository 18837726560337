import React from 'react'
import { Button, Grid, Group, SimpleGrid, TextInput, Title, Tooltip } from '@mantine/core'
import styles from './AccountTabs.module.scss'
import { useForm } from '@mantine/form'
import { updateMyAccount } from '../../js/api/account_repository'
import { showNotification } from '@mantine/notifications'
import PropTypes from 'prop-types'

export default function General ({ account, apiBasePath }) {
  const form = useForm({
    initialValues: {
      first_name: account.first_name,
      last_name: account.last_name,
      phone_number: account.phone_number
    }
  })

  const submit = (data) => {
    updateMyAccount(apiBasePath, data)
      .then(() =>
        showNotification({
          message: 'Your account was successfully updated!',
          color: 'success',
          autoClose: 3000
        }))
      .catch(() =>
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error updating your account',
          color: 'red'
        }))
  }

  return (
    <form onSubmit={form.onSubmit(values => submit(values))}>
      <Grid className={styles.grid}>
        <Grid.Col span={2}>
          <Title order={4}>Contact Information</Title>
        </Grid.Col>
        <Grid.Col span={4}>
          <SimpleGrid cols={1} verticalSpacing='xl'>
              <TextInput label='First name' {...form.getInputProps('first_name')} />
              <TextInput label='Last name' {...form.getInputProps('last_name')} />
              <Tooltip label='Contact support if you need to update your email address' position='right'>
                <TextInput label='Email' value={account?.email} disabled/>
              </Tooltip>
              <TextInput label='Phone number' {...form.getInputProps('phone_number')} />
              <Group>
                <Button type='submit' color='success'>Save changes</Button>
              </Group>
          </SimpleGrid>
        </Grid.Col>
      </Grid>
    </form>
  )
}

General.propTypes = {
  account: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string
  }),
  apiBasePath: PropTypes.string
}
