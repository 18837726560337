/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath, getSmoothStepPath } from 'reactflow';
import { Text } from '@mantine/core';

const AndEdge = memo(function AndEdge ({ id, source, target, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, markerStart, markerEnd, style = {}, data }) {
  const curvature = 0.2
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    curvature
  })

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerStart={markerStart} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <Text
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
          }}
          size='xxl'
          fw={700}
        >
          And
        </Text>
      </EdgeLabelRenderer>
    </>
  )
})

const OrEdge = memo(function OrEdge ({ id, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, data }) {
  const borderRadius = 0
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius
  })

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <Text
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
          }}
          size='xxl'
          fw={700}
        >
          Or
        </Text>
      </EdgeLabelRenderer>
    </>
  )
})

export const edgeComponentTypes = { and: AndEdge, or: OrEdge } // Also includes default | straight | step | smoothstep | simplebezier
