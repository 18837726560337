/* eslint react/prop-types: 0 */
import { Text, View, Page } from '@react-pdf/renderer'
import Styles from './styles/base'
import React, { useState, createContext } from 'react'

export const PdfOptions = createContext()

const defaultOptions = {
  font: {
    size: 10,
    styles: { normal: 'Helvetica', bold: 'Helvetica-Bold', italic: 'Helvetica-Oblique', boldItalic: 'Helvetica-BoldOblique' },
    header: { size: 20 }
  },
  padding: { horizontal: 0.85, vertical: 0.75, unit: 'in' }
}

export default function PdfPage (props) {
  const [options] = useState(defaultOptions)

  const styles = Styles(defaultOptions)

  const footer = (props.cover
    ? null
    : (<View style={styles.footer} fixed><Text style={styles.footerText} >&copy; { new Date().getFullYear() } Stang Decision Systems</Text><Text style={{ color: '#2DB1FF' }}>www.stangds.com</Text></View>)
  )

  return (
    <Page size="A4" style={styles.page}>
      {props.cover
        ? null
        : <Text render={({ pageNumber }) => (
          `${pageNumber}`
        )} style={styles.pageNumber} fixed />}
      <PdfOptions.Provider value={options}>
        {props.children}
      </PdfOptions.Provider>
      {footer}
    </Page>
  )
}
