/* eslint react/prop-types: 0 */
import { Tooltip } from '@mantine/core';
import React from 'react';

export function ComingSoonTooltip ({ children }) {
  return (
    <Tooltip label='Feature coming soon!'>
      {children}
    </Tooltip>
  )
}
