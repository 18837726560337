/* eslint react/prop-types: 0 */
import {
  Affix,
  Alert,
  Button,
  Grid,
  Group,
  NumberInput,
  Paper,
  ScrollArea,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip,
  Transition
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconArrowBadgeLeftFilled, IconArrowBadgeRightFilled, IconAlertTriangle } from '@tabler/icons-react';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { postEditForm } from '../../../js/api/assessment_repository';
import {
  AssessmentPurpose,
  assessmentPurposeFromId
} from '../../../js/generated/enums/AssessmentPurpose';
import {
  AssessmentProtection,
  protectionFromId
} from '../../../js/modules/Build/Assessment/AssessmentProtection';
import { useDebouncedField } from '../../forms/UpdateOnSubmitField';
import { useOrganizations } from '../../util/OrganizationHooks';
import { useAssessmentPerPurposeTypes } from './AssessmentHooks';
import { ImportAssessmentModal } from './EditorComponents/ImportAssessmentModal';
import { ImportQuestionsModal } from './EditorComponents/ImportQuestionsModal';
import { MediaSelectorModal } from './EditorComponents/MediaSelectorModal';
import { QuestionsList } from './EditorComponents/QuestionsList';
import { QuestionStateUpdate, createInitialQuestionsState, questionsReducer } from './EditorComponents/QuestionsState';
import { legacyFormatEditAssessmentFormData } from './EditorComponents/util';
import { useSelector } from 'react-redux';
import { selectIsValid } from './EditorComponents/UnpublishedQuestionLogic/unpublishedLogicSlice';
import WarningAlert from '../../core/Alert/WarningAlert'

export default function AssessmentEditor ({ id = null, sourceData = null, sourceQuestions = null, sourceOrganization = null }) {
  // Internal data
  const [mediaPopup, setMediaPopup] = useState({ showing: false, selected: null })
  const confirmNavigate = useRef(false)
  const [submitting, setSubmitting] = useState(false)
  const [state, dispatch] = useReducer(
    questionsReducer,
    sourceQuestions,
    createInitialQuestionsState
  )
  const [hasLegacyMaxScoreQuestion] = useState(!![...state.questions.values()].filter(elem => !!elem.hasLegacyMaxScore).length)
  const [hasLegacyMinScoreQuestion] = useState(!![...state.questions.values()].filter(elem => !!elem.hasLegacyMinScore).length)
  const logicValid = useSelector(state => selectIsValid(state))

  const isEdit = !!id

  const originalHours = useMemo(() => {
    return getTimeFromSourceData(sourceData, 0)
  }, [sourceData])

  const originalMinutes = useMemo(() => {
    return getTimeFromSourceData(sourceData, 1)
  }, [sourceData])

  const form = useForm({
    initialValues: {
      name: (sourceData?.name ?? ''),
      internalName: (sourceData?.internal_name ?? ''),
      description: (sourceData?.description ?? ''),
      organization: (sourceOrganization?.id?.toString() ?? null),
      purpose: (sourceData?.type ? assessmentPurposeFromId(sourceData.type) : AssessmentPurpose.Generic),
      type: (sourceData?.hiring_assessment?.id?.toString() ?? sourceData?.skillbuilder_assessment?.id.toString() ?? null),
      protection: (sourceData?.protected?.toString() ? protectionFromId(sourceData.protected) : null),
      questions: [],
      numberInterviewers: (sourceData?.interviewer_count ?? 0),
      minimumAverageScore: (sourceData?.minimum_average_score ?? 0),
      averageHours: originalHours,
      averageMinutes: originalMinutes
    },

    transformValues: (values) => ({
      ...values,
      questions: [...state.questions.values()],
      protection: values.purpose === AssessmentPurpose.SkillBuilder ? (values.protection ?? AssessmentProtection.Unprotected) : null,
      numberInterviewers: values.purpose === AssessmentPurpose.Interview ? values.numberInterviewers : null,
      minimumAverageScore: values.purpose === AssessmentPurpose.Interview ? values.minimumAverageScore : null,
      averageHours: values.purpose === AssessmentPurpose.Interview ? values.averageHours : null,
      averageMinutes: values.purpose === AssessmentPurpose.Interview ? values.averageMinutes : null
    }),

    validate: {
      name: isNotEmpty('Name cannot be empty'),
      internalName: isNotEmpty('Internal name cannot be empty'),
      description: isNotEmpty('Description cannot be empty'),
      type: (value, values) => (
        value || (values.purpose === AssessmentPurpose.Interview) || (values.purpose === AssessmentPurpose.Generic)
      )
        ? null
        : 'A type must be selected for this assessment purpose',
      protection: (value, values) => (
        value || (values.purpose !== AssessmentPurpose.SkillBuilder)
      )
        ? null
        : 'Protection type must be selected for SkillBuilder assessments'
    }
  })

  const formDirty = form.isDirty();

  useEffect(() => {
    if (formDirty || (state.questionsHistory.length > 1)) {
      confirmNavigate.current = true
    }
  }, [formDirty, state.questionsHistory])

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (confirmNavigate.current) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [])

  const submitForm = useCallback((values) => {
    setSubmitting(true)
    const cleanData = legacyFormatEditAssessmentFormData(values)
    console.info('Final cleaned data for form submission.', cleanData)
    postEditForm(cleanData, id)
      .then(r => {
        console.info('Got post edited form data response.', r, id)
        if (r.success && (r.status === 202) && r.next) {
          confirmNavigate.current = false
          window.location.replace(r.next)
        } else {
          const fixString = !r.nonRecoverable ? ' It is possible this issue could be fixed by saving again.' : ''
          notifications.show(
            {
              color: 'red',
              title: 'Submission Error',
              message: 'There was an error submitting the assessment!' + fixString
            }
          )
          setSubmitting(false)
        }
      })
  }, [id, setSubmitting])

  const stackConfig = submitting ? { style: { cursor: 'wait' } } : {}

  return (
    <Stack {...stackConfig}>
      {hasLegacyMaxScoreQuestion && (
        <Alert color='red' title="Scoring Will Be Modified - Questions' Effective Max Scores" icon={<IconAlertTriangle />}>
          {"Danger! This assessment has at least one question where the max answer score value is different from what is currently being used as the question's max score. Saving this assessment with or without modification will result in the question's actual max possible answer score value being used from now on. Currently, it may be possible to get more than 100% for such questions. This change is to resolve that issue."}
        </Alert>
      )}
      {hasLegacyMinScoreQuestion && (
        <Alert color='red' title="Scoring Will Be Modified - Questions' Effective Min Scores" icon={<IconAlertTriangle />}>
          {"Danger! This assessment has at least one question where the min answer score value is different from what is currently being used as the question's minimum score. Saving this assessment with or without modification will result in the question's actual min possible answer score value being used from now on. Currently, it is not possible to get below zero points for such questions. This change is to resolve that issue."}
        </Alert>
      )}
      {isEdit &&
        <WarningAlert>Once published, these edits will be made across all active batteries this assessment is tied to. Are you sure you wish to continue editing this assessment?</WarningAlert>
      }
      <form onSubmit={ form.onSubmit(values => submitForm(values)) }>
        <Group align='flex-start' grow>
          <Stack justify='flex-start'>
            <AssessmentMetadataEditor form={form} />
            <AssessmentPurposeEditor form={form} />
          </Stack>
          <Group align='flex-end' grow>
            <Stack justify='space-between'>
              <div>
                <Space h='md' />
                <AssessmentQuestionMetadata questions={state.questions} />
              </div>
              <AssessmentImportButtons hasQuestions={!!state.questions.size} dispatch={dispatch} />
            </Stack>
          </Group>
        </Group>
        <Space h='md' />
        <QuestionsList
          id={0}
          questions={state.questions}
          activeQuestionId={state.activeQuestionId}
          activeAnswerId={state.activeAnswerId}
          dispatch={dispatch}
          mediaPopupShowing={mediaPopup.showing}
          setMediaPopup={setMediaPopup}
          undoHistoryIndex={state.activeQuestionsHistoryIndex < state.questionsHistory.length - 1 ? state.activeQuestionsHistoryIndex : state.maxQuestionsHistoryLength}
        />
        <Group position="left" mt="md">
          {logicValid
            ? <Button type="submit" color='green.6' disabled={submitting || !logicValid}>Save</Button>
            : (
            <Tooltip label='Resolve logic errors to save'>
              <Button type="submit" color='green.6' disabled={submitting || !logicValid}>Save</Button>
            </Tooltip>
              )}
          <Button
            color='gray.6'
            onClick={() => {
              confirmNavigate.current = false
              window.history.go(-3)
            }}
          >
            Discard
          </Button>
        </Group>
      </form>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Grid miw='12vw' maw='12vw' gutter='xs'>
          <Grid.Col span={6}>
            <Transition transition='slide-up' duration={150} exitDuration={50} mounted={state.activeQuestionsHistoryIndex > 0}>
              {(transitionStyles) => (
                <Button
                  leftSection={<IconArrowBadgeLeftFilled size='1rem' />}
                  style={transitionStyles}
                  onClick={() => dispatch({ type: QuestionStateUpdate.UndoQuestionUpdate })}
                  disabled={submitting}
                >
                  Undo
                </Button>
              )}
            </Transition>
          </Grid.Col>
          <Grid.Col span={6}>
            <Transition transition='slide-up' duration={150} exitDuration={50} mounted={state.activeQuestionsHistoryIndex < (state.questionsHistory.length - 1)}>
              {(transitionStyles) => (
                <Button
                  rightSection={<IconArrowBadgeRightFilled size='1rem' />}
                  style={transitionStyles}
                  onClick={() => dispatch({ type: QuestionStateUpdate.RedoQuestionUpdate })}
                  disabled={submitting}
                >
                  Redo
                </Button>
              )}
            </Transition>
          </Grid.Col>
        </Grid>
      </Affix>
      <MediaSelectorModal
        mediaPopup={mediaPopup}
        setMediaPopup={setMediaPopup}
        dispatch={dispatch}
      />
    </Stack>
  )
}

function getTimeFromSourceData (sourceData, index = 0) {
  const value = sourceData?.average_time?.split?.(':')[index]
  if (value && (value !== '')) {
    const valueFloat = parseFloat(value)
    const valueInt = parseInt(value)
    if (valueFloat || (valueFloat === 0)) {
      if (valueInt || (valueInt === 0)) {
        if (Math.abs(valueInt - valueFloat) > 0.0001) {
          return valueFloat
        }
        return valueInt
      }
      return valueFloat
    }
    return valueInt ?? 0
  }
  return 0
}

function AssessmentQuestionMetadata ({ questions }) {
  const [competencyData, setCompetencyData] = useState([])

  useEffect(() => {
    const newCompetencyData = new Map()
    for (const question of questions.values()) {
      for (const competency of question.competencies) {
        const existingCompetencyData = newCompetencyData.get(competency.id) ?? { name: competency.name, count: 0, id: competency.id }
        newCompetencyData.set(competency.id, { ...existingCompetencyData, count: existingCompetencyData.count + 1 })
      }
    }
    setCompetencyData(Array.from(newCompetencyData.values()))
  }, [questions, setCompetencyData])

  return (
    <ScrollArea.Autosize mah='65vh' mx="auto">
      <Paper shadow="md" radius="md" p="md" withBorder>
        <Stack justify='flex-start' gap={0}>
          <Text size='xl' fw={700}>Assessment Information</Text>
          {questions.size
            ? (
            <Text size='md' fw={500} c="dimmed">{questions.size} question{(questions.size > 1) ? 's' : ''}</Text>
              )
            : (
            <Text size='md'>{'Click below to copy an existing assessment\'s questions.'}</Text>
              )}
          <Space h='md' />
          {questions.size
            ? (
            <Stack justify='flex-start' gap={0}>
              {competencyData.length
                ? <Text size='md' td='underline'>Competenc{(competencyData.length > 1) ? 'ies' : 'y'}</Text>
                : (
                <Text size='md'>No Question Competencies Assigned</Text>
                  )}
              {competencyData.map(element => <Text key={element.id} size='sm'>{element.name}: {element.count}</Text>)}
            </Stack>
              )
            : null }
        </Stack>
      </Paper>
    </ScrollArea.Autosize>
  )
}

function AssessmentImportButtons ({ hasQuestions, dispatch }) {
  const [importShowing, setImportShowing] = useState(false)
  const [templateShowing, setTemplateShowing] = useState(false)

  return (
    <div>
      <Group position='apart' gap='xl' align='flex-end' grow>
        <Button onClick={() => setImportShowing(true)}>Import Questions</Button>
        <Tooltip label='Clear questions to copy an assessment' openDelay={(hasQuestions ? 100 : 500)} withArrow>
          <Button color={hasQuestions ? 'gray' : 'blue'} onClick={() => setTemplateShowing(!hasQuestions)}>Use Assessment as Template</Button>
        </Tooltip>
      </Group>
      <ImportQuestionsModal
        showing={importShowing}
        setShowing={setImportShowing}
        globalDispatch={dispatch}
      />
      <ImportAssessmentModal
        showing={templateShowing}
        setShowing={setTemplateShowing}
        dispatch={dispatch}
      />
    </div>
  )
}

function AssessmentMetadataEditor ({ form }) {
  const organizations = useOrganizations()

  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const updateFormName = useCallback((value) => {
    formRef.current.setValues({ name: value })
  }, [])

  const updateFormInternalName = useCallback((value) => {
    formRef.current.setValues({ internalName: value })
  }, [])

  const updateFormDescription = useCallback((value) => {
    formRef.current.setValues({ description: value })
  }, [])

  const nameProps = useDebouncedField(form.values.name, updateFormName)
  const internalNameProps = useDebouncedField(form.values.internalName, updateFormInternalName)
  const descriptionProps = useDebouncedField(form.values.description, updateFormDescription)

  const organizationOptions = useMemo(() => {
    return organizations.map(organization => ({ value: organization.id.toString(), label: organization.name }))
  }, [organizations])

  return (
    <Stack justify='flex-start'>
      <TextInput
        label='Name'
        placeholder='Public Name'
        required
        { ...form.getInputProps('name') }
        { ...nameProps }
      />
      <TextInput
        label='Internal Name'
        placeholder='Something memorable'
        required
        { ...form.getInputProps('internalName') }
        { ...internalNameProps }
      />
      <TextInput
        label='Description'
        placeholder='Description'
        required
        { ...form.getInputProps('description') }
        { ...descriptionProps }
      />
      <Select
        label='Organization'
        placeholder='Optional'
        searchable
        clearable
        data={ organizationOptions }
        { ...form.getInputProps('organization') }
      />
    </Stack>
  )
}

function AssessmentPurposeEditor ({ form }) {
  const [lastPurpose, setLastPurpose] = useState(form.values.purpose)
  const [skillBuilderAssessmentTypes, hiringAssessmentTypes] = useAssessmentPerPurposeTypes()
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const updateFormNumberInterviewers = useCallback((value) => {
    formRef.current.setValues({ numberInterviewers: value })
  }, [])

  const updateFormMinimumAverageScore = useCallback((value) => {
    formRef.current.setValues({ minimumAverageScore: value })
  }, [])

  const updateFormAverageHours = useCallback((value) => {
    formRef.current.setValues({ averageHours: value })
  }, [])

  const updateFormAverageMinutes = useCallback((value) => {
    formRef.current.setValues({ averageMinutes: value })
  }, [])

  const interviewerProps = useDebouncedField(form.values.numberInterviewers, updateFormNumberInterviewers)
  const minimumAverageScoreProps = useDebouncedField(form.values.minimumAverageScore, updateFormMinimumAverageScore)
  const averageHoursProps = useDebouncedField(form.values.averageHours, updateFormAverageHours)
  const averageMinutesProps = useDebouncedField(form.values.averageMinutes, updateFormAverageMinutes)

  useEffect(() => {
    if (lastPurpose !== form.values.purpose) {
      formRef.current.setFieldValue('type', null)
      setLastPurpose(form.values.purpose)
    }
  }, [form.values.purpose, lastPurpose, setLastPurpose]) // TODO add org to dependency array if assessment type is org-specific?

  const protectionOptions = useMemo(() => {
    return Object.values(AssessmentProtection)
  }, [])

  const purposeOptions = useMemo(() => {
    return Object.values(AssessmentPurpose)
  }, [])

  const skillBuilderAssessmentTypeOptions = useMemo(() => {
    return skillBuilderAssessmentTypes.map(assessmentType => ({ value: assessmentType.id.toString(), label: assessmentType.name }))
  }, [skillBuilderAssessmentTypes])

  const hiringAssessmentTypeOptions = useMemo(() => {
    return hiringAssessmentTypes.map(assessmentType => ({ value: assessmentType.id.toString(), label: assessmentType.internal_name }))
  }, [hiringAssessmentTypes])

  return (
    <Stack justify='flex-start'>
      <Select
        label='Purpose'
        placeholder='Must be selected'
        required
        data={ purposeOptions }
        { ...form.getInputProps('purpose') }
      />
      { form.values.purpose === AssessmentPurpose.SkillBuilder
        ? (
        <Select
          label='Protection'
          placeholder='Required for SkillBuilder'
          required
          data={ protectionOptions }
          { ...form.getInputProps('protection') }
        />
          )
        : null }
      { form.values.purpose === AssessmentPurpose.SkillBuilder
        ? (
        <Select
          label='Assessment Type'
          placeholder='Select a Type'
          required
          searchable
          data={ skillBuilderAssessmentTypeOptions }
          { ...form.getInputProps('type') }
        />
          )
        : null }
      { form.values.purpose === AssessmentPurpose.Hiring
        ? (
        <Select
          label='Assessment Type'
          placeholder='Select a Type'
          required
          searchable
          data={ hiringAssessmentTypeOptions }
          { ...form.getInputProps('type') }
        />
          )
        : null }
      { form.values.purpose === AssessmentPurpose.Interview
        ? (
        <div>
          <NumberInput
            label='Number of Interviewers'
            placeholder='Set Team Size'
            required
            allowDecimal={false}
            allowNegative={false}
            clampBehavior='strict'
            min={0}
            max={100000}
            { ...form.getInputProps('numberInterviewers') }
            { ...interviewerProps }
          />
          <Space h='sm' />
          <NumberInput
            label='Minimum Average Score'
            placeholder='Five point scale'
            required
            allowNegative={false}
            clampBehavior='strict'
            min={0}
            max={5}
            suffix={' out of 5'}
            decimalScale={3}
            { ...form.getInputProps('minimumAverageScore') }
            { ...minimumAverageScoreProps }
          />
          <Space h='sm' />
          <Group>
            <Text c='dimmed' size='sm' ta='left'>Average Time:</Text>
            <NumberInput
              label=''
              placeholder='hours'
              allowNegative={false}
              clampBehavior='strict'
              min={0}
              max={100000}
              suffix={' hours'}
              decimalScale={3}
              { ...form.getInputProps('averageHours') }
              { ...averageHoursProps }
            />
            <NumberInput
              label=''
              placeholder='minutes'
              allowNegative={false}
              clampBehavior='strict'
              min={0}
              max={100000}
              suffix=' minutes'
              decimalScale={3}
              { ...form.getInputProps('averageMinutes') }
              { ...averageMinutesProps }
            />
          </Group>
        </div>
          )
        : null }
    </Stack>
  )
}
