import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { evaluateLogicForQuestion, evaluateNodeInnerLogic, QueryState } from './util';
import {
  answerQuestion,
  fetchPublishedAssessmentQuestions,
  transitionToQuestion
} from './assessmentsSlice';
import {
  checkSubmitPendingAnswers,
  selectRespondentAnswerById,
  selectRespondentAnswerMap,
  updateVisibleQuestions
} from './respondentAnswersSlice';
import {
  selectQuestionById,
  selectQuestionsMap,
  selectPotentialZoomToTargetIds,
  selectAllQuestions,
  selectShownAnswerableQuestions
} from './questionSlice';
import { LogicFeature } from '../../js/generated/enums/LogicFeature';

/**
 * @type {EntityAdapter<{PublishedQuestionConditionalLogicJson}, int>}
 */
const logicAdapter = createEntityAdapter()

const initialState = logicAdapter.getInitialState({
  status: QueryState.Idle,
  error: null
})

export const logicValidate = createAsyncThunk('logic/Validate', async (submitParams, { dispatch, getState }) => {
  const { id, isAdditional, additionalAnswer } = submitParams
  console.info('Logic validate dispatching submit params to answer question', id, isAdditional, additionalAnswer, submitParams)
  dispatch(answerQuestion(submitParams))
  const state = getState()
  console.debug('logicValidate now calculating based on new state', state, submitParams)
  const associatedLogic = selectLogicByDataQuestionId(state, id)
  if (associatedLogic?.length) {
    console.debug('Updating associated logic for question.', id)
    const updateQuestionIds = new Set(associatedLogic.map(node => node.rootParentQuestionId))
    const updateQuestionMap = new Map()
    console.debug('Associated ids.', updateQuestionIds)
    for (const updateQuestionId of updateQuestionIds) {
      const toUpdateLogic = selectLogicByRootParentQuestionId(state, updateQuestionId)
      const rootLogicIds = selectRootLogicIdsByRootParentQuestionId(state, updateQuestionId)
      const toUpdateLogicMap = new Map()
      for (const node of toUpdateLogic) {
        toUpdateLogicMap.set(node.id, node)
      }
      const hideFromLogic = evaluateLogicForQuestion(
        rootLogicIds,
        (childId) => toUpdateLogicMap.get(childId),
        (questionId) => selectQuestionById(state, questionId),
        (questionId, question) => selectRespondentAnswerById(state, questionId)

      )
      if (hideFromLogic !== selectQuestionById(state, updateQuestionId)?.hideFromLogic) {
        updateQuestionMap.set(updateQuestionId, hideFromLogic)
      }
    }
    if (updateQuestionMap.size) {
      let currentData = [...updateQuestionMap.entries()]
      while (currentData.length) {
        const returnData = { data: currentData }
        console.info('Logic validate chain dispatching update visible questions', returnData)
        dispatch(updateVisibleQuestions(returnData))
        const newState = getState()
        currentData = selectChainedLogicResultChangedQuestionIds(newState)
        console.debug('Logic validate new chained visible question updates: ', currentData)
      }
      console.debug('Logic validate exiting.', submitParams)
    } else {
      console.debug('Skipping logic update - no changes.', updateQuestionMap, updateQuestionIds)
    }
  }
  dispatch(onAnswerZoomTargetUpdate({ id }))
})

export const onAnswerZoomTargetUpdate = createAsyncThunk('logic/onAnswerZoomTargetUpdate', async (zoomParams, { dispatch, getState }) => {
  const { id } = zoomParams
  console.debug('Logic onAnswerZoomTargetUpdate processing zoomParams', id, zoomParams)
  const state = getState()
  const modifiedQuestion = selectQuestionById(state, id)
  const minimumQuestionIndex = (modifiedQuestion?.location.indexInAssessment ?? 0) - 1
  const nextZoomTargets = selectPotentialZoomToTargetIds(state, minimumQuestionIndex)
  console.debug('Logic onAnswerZoomTargetUpdate ZoomTargets', minimumQuestionIndex, modifiedQuestion?.id, modifiedQuestion?.location.indexInAssessment, nextZoomTargets)
  let targetQuestionId = null
  let seenQuestionIds = 0
  const maxSeenQuestionIds = 5
  for (const questionId of nextZoomTargets) {
    if (questionId !== modifiedQuestion?.id) {
      const targetQuestion = state.questions.entities[questionId]
      if (targetQuestion?.show) {
        if (!targetQuestion?.hideFromLogic) {
          targetQuestionId = questionId
          break
        }
      } else {
        seenQuestionIds += 1
      }
    }
    if (seenQuestionIds >= maxSeenQuestionIds) {
      break
    }
  }
  console.debug('Logic onAnswerZoomTargetUpdate optimistic question id | seen', targetQuestionId, seenQuestionIds)

  if (targetQuestionId) {
    dispatch(transitionToQuestion({ questionId: targetQuestionId, assessmentId: modifiedQuestion?.location.assessmentId, doNotOverwritePrompt: true }))
  } else {
    const finalZoomTargets = selectShownAnswerableQuestions(state)
    const lastQuestion = finalZoomTargets?.length ? (finalZoomTargets[finalZoomTargets.length - 1] ?? null) : null
    const lastQuestionId = lastQuestion?.id ?? null
    if (lastQuestionId && lastQuestion?.location.assessmentId && lastQuestion.show && !lastQuestion?.hideFromLogic && (lastQuestion.location.indexInAssessment > (minimumQuestionIndex + 1))) {
      console.debug('Logic onAnswerZoomTargetUpdate fallback scrolling to last question id', lastQuestionId, lastQuestion.location.indexInAssessment)
      dispatch(transitionToQuestion({ questionId: lastQuestionId, assessmentId: lastQuestion.location.assessmentId, doNotOverwritePrompt: true }))
    } else {
      console.debug('No valid fallback zoom question found for logic onAnswerZoomTargetUpdate fallback.', lastQuestionId, finalZoomTargets)
    }
  }
  dispatch(checkSubmitPendingAnswers({ count: 0 }))
})

export const transitionToFirstUnansweredQuestion = createAsyncThunk('logic/transitionToFirstUnansweredQuestion', async (scrollParams, { dispatch, getState }) => {
  console.info('starting transitionToFirstUnansweredQuestion', scrollParams)
  const state = getState()
  const questionIds = selectPotentialZoomToTargetIds(state, -1)
  const firstQuestionId = questionIds?.[0] ?? 0
  const firstQuestion = selectQuestionById(state, firstQuestionId) ?? null
  if (firstQuestionId && firstQuestion?.location.assessmentId) {
    dispatch(transitionToQuestion({ questionId: firstQuestionId, assessmentId: firstQuestion.location.assessmentId }))
  } else {
    console.debug('No first unanswered question found for transition to first unanswered question - falling back to last shown question.', questionIds, firstQuestionId, firstQuestion)
    dispatch(transitionToLastShownQuestion())
  }
})

export const transitionToLastShownQuestion = createAsyncThunk('logic/transitionToLastShownQuestion', async (scrollParams, { dispatch, getState }) => {
  console.info('starting transitionToLastShownQuestion', scrollParams)
  const state = getState()
  const questions = selectShownAnswerableQuestions(state)
  const lastQuestion = questions?.length ? (questions[questions.length - 1] ?? null) : null
  const lastQuestionId = lastQuestion?.id ?? null
  if (lastQuestionId && lastQuestion?.location.assessmentId) {
    dispatch(transitionToQuestion({ questionId: lastQuestionId, assessmentId: lastQuestion.location.assessmentId }))
  } else {
    console.warn('No last question found for transition to last shown question.', lastQuestionId, lastQuestion, questions)
  }
  return false
})

const logicSlice = createSlice({
  name: 'logic',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('logic slice builder fulfilled', state, action)
        logicAdapter.upsertMany(state, action.payload.logic) // Note show/hide for initial state is processed in state parser.
      })
  }
})

// export const {} = logicSlice.actions

export default logicSlice.reducer

export const { selectAll: selectAllLogic, selectById: selectLogicById, selectIds: selectLogicIds, selectEntities: selectLogicMap } =
  logicAdapter.getSelectors(state => state.logic)

export const selectLogicByRootParentQuestionId = createSelector(
  [selectAllLogic, (state, questionId) => questionId],
  (logic, questionId) => logic.filter(node => node.rootParentQuestionId === questionId)
)

export const selectRootLogicIdsByRootParentQuestionId = createSelector(
  [selectLogicByRootParentQuestionId],
  (
    logic
  ) => logic.filter(node => !!node.parentQuestionId).map(node => node.id)
)

export const selectLogicIdsByParentQuestionId = createSelector(
  [selectAllLogic, (state, questionId) => questionId],
  (logic, questionId) => logic.filter(node => node.parentQuestionId === questionId).map(node => node.id)
)

function recursivelyCheckLogic (logic, logicMap, questionsMap, respondentAnswerMap) {
  const childrenResult = !logic?.children.length || logic.children.some(childId => recursivelyCheckLogic(logicMap[childId], logicMap, questionsMap, respondentAnswerMap))
  if (!childrenResult || !logic?.id) {
    return false
  }
  const dataQuestion = questionsMap[logic.questionId]
  const dataAnswer = respondentAnswerMap[logic.questionId]
  if (!dataAnswer.id || !dataQuestion.id) {
    console.error('Missing logic data in recursive chained logic check.', dataQuestion?.id, dataAnswer?.id, logic.id)
    return false
  }
  return evaluateNodeInnerLogic(logic, dataQuestion, dataAnswer)
}

export const selectLogicHidingQuestion = createSelector(
  [selectLogicIdsByParentQuestionId, selectLogicMap, selectQuestionsMap, selectRespondentAnswerMap],
  (
    logicIds, logicMap, questionsMap, respondentAnswerMap
  ) => {
    const featureMap = new Map()
    for (const logicId of logicIds) {
      const logic = logicMap[logicId]
      featureMap.set(logic?.feature, featureMap.get(logic?.feature) ? true : recursivelyCheckLogic(logic, logicMap, questionsMap, respondentAnswerMap))
    }
    let showQuestion = null
    let hideQuestion = null
    for (const [feature, result] of featureMap.entries()) {
      if (feature === LogicFeature.Hide) {
        hideQuestion = result
      } else if (feature === LogicFeature.Show) {
        showQuestion = result
      }
    }
    return (showQuestion === null) ? !!hideQuestion : !showQuestion
  }
)

export const selectChainedLogicResultChangedQuestionIds = createSelector(
  [selectAllQuestions, (state) => state],
  (questions, state) => questions.filter(question => selectLogicHidingQuestion(state, question.id) !== question.hideFromLogic).map(question => [question.id, !question.hideFromLogic])
)

export const selectLogicByDataQuestionId = createSelector(
  [selectAllLogic, (state, questionId) => questionId],
  (logic, questionId) => logic.filter(node => node.questionId === questionId)
)
