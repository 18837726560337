import { useEffect, useState } from 'react';
import { getOrganizations } from '../../../js/api/organization_repository';

export default function useOrganizations (params = null) {
  const [organizations, setOrganizations] = useState(null)

  useEffect(() => {
    getOrganizations(params ?? { limit: 0 }).then(data => {
      setOrganizations(data.items)
    })
  }, [params])

  return organizations || []
}
