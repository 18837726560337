/* eslint react/prop-types: 0 */
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import Content from '../layout/Content';
import {
  Title,
  Center,
  Box,
  Stack,
  Space,
  Loader,
  Transition,
  Affix,
  SemiCircleProgress,
  Tooltip
} from '@mantine/core';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { PublishedAssessmentPage } from './PublishedAssessmentPage';
import {
  defaultPublishedAssessmentContext,
  PublishedAssessmentContext
} from './PublishedAssessmentContext';
import { notifications } from '@mantine/notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPublishedAssessmentQuestions,
  onlySkippedQuestionsLeft
} from './assessmentsSlice';
import {
  selectPageIdsByAssessmentId,
  submitPages
} from './pagesSlice';
import { QueryState } from './util';
import {
  selectHasAnyUnansweredNotHiddenByLogicQuestions,
  selectShowingManyQuestions,
  selectHasAnyUnansweredNotSkippedNotHiddenByLogicQuestions,
  selectSeenQuestionPercentage
} from './questionSlice';
import { AssessmentTimerFrame } from './AssessmentTimer';
import { LoadAssessmentError } from './AssessmentResponseHandler';
import { ResponseReviewFrame } from './ResponsesReview';
import { AssessmentSubmit } from './AssessmentSubmitHandler';
import { FreeResponseTransitionButton } from './FreeResponseTransitionButton';
import { DistributionReportApp } from '../hire/cycle/invites/stages/DistributionReportApp';
import { useSpringGestures } from '../core/useSpringGestures';

export function PublishedAssessmentApp () {
  /**
   /assessment-platform/ -> published
   /assessment-platform/on-site -> on-site published
   /testenv/assessments/ -> preview published
   /build/assessments/ -> preview unpublished
   skillbuilder/assessment-assignments/ -> published skillbuilder with password
   skillbuilder/assessorlinks/ -> published skillbuilder with code (assessor)
   /prescreen/assessment/ -> published prescreen
   /testprep/ -> published testprep
   */
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublishedAssessmentAppFrame />}>
          <Route path='/invites/manage/cycles/:cycleId/stages/:stageId/report/oa' element={<DistributionReportApp />} />
          <Route path='/invites/report/oa/:progressId' element={<OAReportAPIPageLoader />} />
          <Route path='/invites/manage/progresses/:progressId/preview' element={<OAReportAPIPageLoader showScores={true} />} />
          <Route path='/*' element={<PublishedAssessmentAPIPageLoader />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

function PublishedAssessmentAppFrame () {
  const container = document.getElementById('published-assessment-app-container')
  const assessmentId = container.getAttribute('data-assessment-id') ?? 'unknownId'
  const currentPageIndex = container.getAttribute('data-current-page-index') ?? 0
  const timeLimit = parseInt(container.getAttribute('data-time-limit') ?? '') ?? null
  const displayNumbers = !container.getAttribute('data-hide-question-numbers')
  const next = container.getAttribute('data-next')
  const recordResponse = container.getAttribute('data-record-response') ?? null
  const fetch = container.getAttribute('data-fetch') ?? null
  const key = container.getAttribute('data-key') ?? null
  const password = container.getAttribute('data-password') ?? null
  const currentPublishedAssessmentContext = useMemo(() => {
    console.info('Updating published assessment context.')
    const auth = key ? { key } : (password ? { password } : {})
    return {
      ...defaultPublishedAssessmentContext,
      displayNumbers: displayNumbers,
      next: next,
      recordResponse: recordResponse,
      timeLimit: timeLimit || null,
      assessmentId: assessmentId,
      fetch: fetch,
      currentPageIndex: currentPageIndex,
      auth: auth
    }
  }, [assessmentId, displayNumbers, recordResponse, timeLimit, next, fetch, currentPageIndex, key, password])

  return (
    <Content>
      <PublishedAssessmentContext.Provider value={currentPublishedAssessmentContext}>
        <Outlet />
      </PublishedAssessmentContext.Provider>
    </Content>
  )
}

function PublishedAssessmentAPIPageLoader () {
  const { assessmentId, displayNumbers, next, fetch, recordResponse, timeLimit, currentPageIndex, auth } = useContext(PublishedAssessmentContext)
  console.debug('APIPageLoader updating - context display numbers | current page index', displayNumbers, currentPageIndex)

  const dispatch = useDispatch()
  const questionsStatus = useSelector(state => state.assessments.status)
  const rejectedByServer = (questionsStatus === QueryState.Rejected)
  const questionsDidError = (questionsStatus === QueryState.Failed) || rejectedByServer
  const questionsLoading = (questionsStatus === QueryState.Idle) || (questionsStatus === QueryState.Loading)

  const pageIds = useSelector(state => selectPageIdsByAssessmentId(state, assessmentId))
  const hasPages = !!(pageIds?.length)
  const questionsLoaded = (questionsStatus === QueryState.Succeeded || ((questionsStatus === QueryState.Submitting) && hasPages))

  const dispatchLoadAssessment = useCallback(() => {
    dispatch(fetchPublishedAssessmentQuestions({
      fetchUrl: fetch,
      startPageIndex: currentPageIndex,
      submitUrl: next,
      recordResponse: recordResponse,
      timeLimit: timeLimit,
      displayNumbers: displayNumbers,
      assessmentId: assessmentId,
      auth: auth
    }))
  }, [dispatch, fetch, currentPageIndex, next, recordResponse, timeLimit, displayNumbers, assessmentId, auth])

  useEffect(() => {
    if (questionsStatus === QueryState.Idle) {
      console.info('Triggering redux fetch assessment data from idle query state.', questionsStatus)
      dispatchLoadAssessment()
    } else {
      console.debug('Questions Status changed.', questionsStatus)
      if (questionsStatus === QueryState.Failed) {
        const errorMessage = 'We\'re having trouble contacting the server for your assessment data. Try reloading and hang tight!'
        notifications.show({
          id: 'refresh-page',
          title: 'Refresh Page',
          message: errorMessage,
          color: 'red',
          withBorder: true
        })
      }
    }
  }, [questionsStatus, dispatchLoadAssessment])

  return (
    <>
      { hasPages && (
        <PublishedAssessmentDynamicPageController
          pageIds={pageIds}
          assessmentId={assessmentId}
        />
      )}
      { !hasPages && questionsLoading && (
        <Center>
          <Space h='xl' />
          <Loader variant="dots" />
        </Center>
      )}
      { !hasPages && questionsLoaded && !questionsDidError && (
        <Center>
          <Space h='xl' />
          <Title>No page data found when requesting assessment - please try refreshing the page.</Title>
        </Center>
      )}
      { !hasPages && questionsDidError && (
        <LoadAssessmentError />
      )}
    </>
  )
}

function OAReportAPIPageLoader ({ showScores = false }) {
  const { assessmentId, displayNumbers, next, fetch, recordResponse, timeLimit, currentPageIndex, auth } = useContext(PublishedAssessmentContext)
  console.debug('OAReportAPIPageLoader updating', { showScores, displayNumbers, currentPageIndex })

  const dispatch = useDispatch()
  const questionsStatus = useSelector(state => state.assessments.status)
  const rejectedByServer = (questionsStatus === QueryState.Rejected)
  const questionsDidError = (questionsStatus === QueryState.Failed) || rejectedByServer
  const questionsLoading = (questionsStatus === QueryState.Idle) || (questionsStatus === QueryState.Loading)

  const pageIds = useSelector(state => selectPageIdsByAssessmentId(state, assessmentId))
  const hasPages = !!(pageIds?.length)
  const questionsLoaded = (questionsStatus === QueryState.Succeeded || ((questionsStatus === QueryState.Submitting) && hasPages))

  const dispatchLoadAssessment = useCallback(() => {
    dispatch(fetchPublishedAssessmentQuestions({
      fetchUrl: fetch,
      startPageIndex: currentPageIndex,
      submitUrl: next,
      recordResponse: recordResponse,
      timeLimit: timeLimit,
      displayNumbers: displayNumbers,
      assessmentId: assessmentId,
      auth: auth,
      isOAReport: true,
      showScores: showScores
    }))
  }, [dispatch, fetch, currentPageIndex, next, recordResponse, timeLimit, displayNumbers, assessmentId, auth, showScores])

  useEffect(() => {
    if (questionsStatus === QueryState.Idle) {
      console.info('Triggering redux fetch assessment data from idle query state.', questionsStatus)
      dispatchLoadAssessment()
    } else {
      console.debug('Questions Status changed.', questionsStatus)
      if (questionsStatus === QueryState.Failed) {
        const errorMessage = 'We\'re having trouble contacting the server for your assessment data. Try reloading and hang tight!'
        notifications.show({
          id: 'refresh-page',
          title: 'Refresh Page',
          message: errorMessage,
          color: 'red',
          withBorder: true
        })
      }
    }
  }, [questionsStatus, dispatchLoadAssessment])

  return (
    <>
      { hasPages && (
        <PublishedAssessmentDynamicPageController
          pageIds={pageIds}
          assessmentId={assessmentId}
        />
      )}
      { !hasPages && questionsLoading && (
        <Center>
          <Space h='xl' />
          <Loader variant="dots" />
        </Center>
      )}
      { !hasPages && questionsLoaded && !questionsDidError && (
        <Center>
          <Space h='xl' />
          <Title>No page data found when requesting assessment - please try refreshing the page.</Title>
        </Center>
      )}
      { !hasPages && questionsDidError && (
        <LoadAssessmentError />
      )}
    </>
  )
}

export function PublishedAssessmentDynamicPageController ({ pageIds, assessmentId = '' }) {
  const { timeLimit, next, recordResponse } = useContext(PublishedAssessmentContext)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)
  const [reviewOpen, setReviewOpen] = useState(false)
  const dispatch = useDispatch()
  const hasAnyUnanswered = useSelector(state => selectHasAnyUnansweredNotHiddenByLogicQuestions(state)) // TODO [one page app] additional by-assessmentId entity segmentation if using global store state
  const hasAnyUnansweredNotSkipped = useSelector(state => selectHasAnyUnansweredNotSkippedNotHiddenByLogicQuestions(state))
  const showSearch = useSelector(state => selectShowingManyQuestions(state))

  useEffect(() => {
    if (!hasAnyUnansweredNotSkipped && hasAnyUnanswered) {
      dispatch(onlySkippedQuestionsLeft({ assessmentId }))
    }
  }, [hasAnyUnansweredNotSkipped, hasAnyUnanswered, assessmentId, dispatch])

  useSpringGestures()

  const submitAssessment = useCallback(() => {
    dispatch(submitPages({ next, assessmentId, recordResponse }))
  }, [dispatch, next, assessmentId, recordResponse])

  const confirmSubmitAssessmentIfMissing = useCallback(() => {
    if (!hasAnyUnanswered) {
      submitAssessment()
    } else {
      setShowSubmitConfirmation(true)
    }
  }, [submitAssessment, hasAnyUnanswered, setShowSubmitConfirmation])

  const openReview = useCallback(() => {
    setReviewOpen(true)
  }, [setReviewOpen])

  const closeReview = useCallback(() => {
    setReviewOpen(false)
  }, [setReviewOpen])

  return (
    <Stack gap='xl'>
      <SeenQuestionsProgressBar />
      <PublishedAssessmentPages pageIds={pageIds} />
      <AssessmentTimerFrame timeLimit={timeLimit} submitAssessment={submitAssessment} />
      <ResponseReviewFrame
        timeLimit={timeLimit}
        showSearch={showSearch}
        hasAnyUnansweredNotSkipped={hasAnyUnansweredNotSkipped}
        confirmSubmitAssessmentIfMissing={confirmSubmitAssessmentIfMissing}
        reviewOpen={reviewOpen}
        closeReview={closeReview}
        openReview={openReview}
      />
      <FreeResponseTransitionButton assessmentId={assessmentId} />
      <AssessmentSubmit
        hasAnyUnansweredNotSkipped={hasAnyUnansweredNotSkipped}
        hasAnyUnanswered={hasAnyUnanswered}
        confirmSubmitAssessmentIfMissing={confirmSubmitAssessmentIfMissing}
        showSubmitConfirmation={showSubmitConfirmation}
        setShowSubmitConfirmation={setShowSubmitConfirmation}
        submitAssessment={submitAssessment}
        openReview={openReview}
      />
      <Box w='100%' h='12rem' />
    </Stack>
  )
}

export const PublishedAssessmentPages = memo(function PublishedAssessmentPages ({ pageIds }) {
  return (
    <>
      {pageIds.map((pageId) => <PublishedAssessmentPage key={pageId} pageId={pageId} />)}
    </>
  )
})

const progressBarBoxProps = { style: { transform: 'rotate(-90deg) translate(-25%, 0%)' } }
const progressBarPositionProps = { bottom: '50vh', right: -50 }

const SeenQuestionsProgressBar = memo(function SeenQuestionsProgressBar () {
  const seenQuestionsPercentage = useSelector(state => selectSeenQuestionPercentage(state))

  return (
    <Affix position={progressBarPositionProps}>
      <Transition transition='fade-left' duration={500} exitDuration={500} mounted={!!seenQuestionsPercentage && (seenQuestionsPercentage < 1)}>
        {(transitionStyles) => (
          <Box
            style={transitionStyles}
          >
            <Tooltip label='Progress'>
              <Box {...progressBarBoxProps}>
                <SemiCircleProgress
                  fillDirection="right-to-left"
                  orientation="up"
                  filledSegmentColor="blue"
                  emptySegmentColor="var(--mantine-color-dimmed)"
                  size={200}
                  thickness={16}
                  value={seenQuestionsPercentage * 100}
                  transitionDuration={1000}
                  label=""
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Transition>
    </Affix>
  )
})
