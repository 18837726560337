import React, { useMemo } from 'react';
import { ActionIcon, Combobox, Pill, PillsInput, ScrollArea, ThemeIcon, useCombobox } from '@mantine/core';
import _ from 'lodash'
import { useFilterItem } from './FilterHooks';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export function FilterMultiSelect ({ filter, selected, onChange: handleUpdate }) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  });

  const selectedArr = useMemo(() => {
    if (selected !== undefined && selected?.constructor !== Array) {
      return [selected]
    }
    return selected ?? []
  }, [selected])

  const filterProps = useFilterItem({ filter: filter, selected: selectedArr })

  const handleValueSelect = (val) => {
    if (val === filter.clearOptionValue) {
      handleUpdate([])
      return
    }
    const selectedValues = selectedArr.includes(val) ? selectedArr.filter((v) => v !== val) : [...selectedArr, val]
    handleUpdate(selectedValues)
  }

  const handleValueRemove = (val) =>
    handleUpdate(selectedArr.filter((v) => v !== val))

  const values = selectedArr?.map((item) => {
    const option = _.find(filter.options, { value: item })
    if (!option) return null
    return (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {option.label}
    </Pill>
    )
  });

  const iconColor = useMemo(() => {
    return selectedArr.length > 0 ? filter.color ?? 'blue' : 'dimmed'
  }, [selectedArr, filter.color])

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      {...filterProps.combobox}
      >
      <Combobox.DropdownTarget>
        <PillsInput
          label={filter.label}
          description={filter.description ?? null}
          leftSection={filter.leftSection && <ThemeIcon size='sm' color={iconColor} variant='transparent'>{filter.leftSection}</ThemeIcon>}
          rightSection={
            selectedArr.length > 0
              ? (
              <ActionIcon
                size="sm"
                onMouseDown={(event) => { event.preventDefault() }}
                onClick={() => handleUpdate([]) }
                aria-label="Clear value"
              >
                <IconX />
              </ActionIcon>
                )
              : (
                  <Combobox.Chevron />
                )
          }
          onClick={() => combobox.openDropdown()}
        >
        <ScrollArea.Autosize mah={'4rem'} type='auto' style={{ cursor: 'default' }}>
          <Pill.Group>
            {values.length > 0 && values}
            <Combobox.EventsTarget>
                <PillsInput.Field
                  onBlur={() => combobox.closeDropdown()}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && filterProps.search.length === 0) {
                      event.preventDefault()
                      handleValueRemove(selectedArr[selectedArr.length - 1])
                    }
                  }}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex()
                    filterProps.setSearch(event.currentTarget.value)
                  }}
                  placeholder={!selectedArr.length ? (filter.placeholder ?? 'Pick one or more') : null}
                  {...filterProps.input}
                />
            </Combobox.EventsTarget>
          </Pill.Group>
        </ScrollArea.Autosize>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          {filterProps.options.length > 0 && filterProps.dropdown}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

FilterMultiSelect.propTypes = {
  filter: PropTypes.object.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired
};
