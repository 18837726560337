import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';
import {
  Group,
  Button,
  MultiSelect,
  Textarea,
  Select
} from '@mantine/core';
import { TableControlContext } from '../core/ReactTable/TableContexts';
import { formatToOptions } from '../../js/util/DataUtil';
import { useJobsQuery } from '../hire/jobs/JobHooks';
import PropTypes from 'prop-types';
import { useExpertiseCategoryQuery } from './SemanticSearchHooks';
import { useSyncedFormValues } from '../core/ReactTable/FormFilters';

const emptyJobs = []
const emptyExpertiseCategories = []
const initialValues = {
  job: null,
  expertise: [],
  prompt: ''
}

export default function SemanticSearchTableForm ({ namespace }) {
  const { batchUpdateSearchParams } = useContext(TableControlContext)
  const [jobs, isLoadingJobs] = useJobsQuery({ archived: 0, limit: 0, order_direction: 'ASC' })
  const [expertiseQuery, setExpertiseQuery] = useState({ limit: 0, job: null, order_direction: 'ASC' });
  const [expertiseCategories, isLoadingCategories] = useExpertiseCategoryQuery(expertiseQuery)
  const syncedFormValues = useSyncedFormValues(initialValues, namespace)

  const formattedJobs = useMemo(() => {
    if (!isLoadingJobs) {
      return formatToOptions(jobs.items)
    }
    return emptyJobs
  }, [jobs, isLoadingJobs])

  const formattedCategories = useMemo(() => {
    if (isLoadingCategories || expertiseCategories?.items === undefined) {
      return emptyExpertiseCategories
    }
    return expertiseCategories.items.map(category => ({ label: category.label, value: category.id.toString() }))
  }, [expertiseCategories, isLoadingCategories]);

  const form = useForm({
    mode: 'uncontrolled',
    name: 'semantic-form',
    initialValues: initialValues,
    validate: {
      prompt: isNotEmpty('Prompt cannot be empty')
    }
  })

  const formRef = useRef(form)
  useEffect(() => {
    if (!isLoadingJobs && !isLoadingCategories) {
      formRef.current.initialize(syncedFormValues)
    }
  }, [isLoadingJobs, isLoadingCategories, syncedFormValues]);

  form.watch('job', ({ value }) => {
    // Clear field if the past job had potential expertise category options
    if (expertiseCategories.length > 0) {
      form.setFieldValue('expertise', [])
    }

    value
      ? form.setFieldValue('prompt', `Applied for or expressed interest in the role of ${formattedJobs.find((job) => job.value === value).label}. `)
      : form.setFieldValue('prompt', '')

    setExpertiseQuery({ limit: 0, job: value, order_direction: 'ASC' })
  })

  form.watch('expertise', ({ value }) => {
    let prompt = ''
    if (form.getValues().job) {
      prompt += `Applied for or expressed interest in the role of ${jobs.items.find((job) => job.id.toString() === form.getValues().job).name}. `
    }

    if (value.length > 0) {
      value.forEach((id) => { prompt += `Demonstrated skills in ${expertiseCategories.items.find((category) => category.id.toString() === id).label}. ` })
    }

    form.setFieldValue('prompt', prompt)
  })

  return (
    <form onSubmit={form.onSubmit((values) => batchUpdateSearchParams(values))}>
      <Select
        data={formattedJobs}
        label={'Job'}
        placeholder={'Select a job'}
        searchable
        clearable
        key={form.key('job')}
        {...form.getInputProps('job')}
      />
      <MultiSelect
        data={formattedCategories}
        label='Expertise'
        disabled={formattedCategories.length === 0}
        searchable
        clearable
        key={form.key('expertise')}
        {...form.getInputProps('expertise')}
      />
      <Textarea
        label='Prompt'
        description={'Build the prompt via the fields above. Feel free to make edits.'}
        key={form.key('prompt')}
        {...form.getInputProps('prompt')}
      />
      <Group justify='flex-start' mt='md'>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  )
}

SemanticSearchTableForm.propTypes = {
  namespace: PropTypes.string.isRequired
}
