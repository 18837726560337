import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRespondentAnswerById,
  updateAnswerComment
} from './respondentAnswersSlice';
import { useDebouncedValue } from '@mantine/hooks';
import { logicValidate, onAnswerZoomTargetUpdate } from './logicSlice';
import { IconMail, IconPhone } from '@tabler/icons-react';
import { selectQuestionValidationType } from './questionSlice';
import { selectIsTransitionToTarget, transitionToQuestion } from './assessmentsSlice';

const validate = (ans, pattern) => (!ans || pattern.test(ans))

export function CorrectAnswerIndicator () {
  return (
    <div style={{ color: 'green', fontSize: '30px', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: '&#10004' }} />
  )
}

export function useRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answer, setAnswer] = useState(respondentAnswer?.answerId ?? null)
  const [debounced] = useDebouncedValue(answer, 500)

  useEffect(() => {
    if (hideFromLogic && answer) {
      setAnswer(null)
    }
  }, [hideFromLogic, answer, setAnswer])

  useEffect(() => {
    if ((debounced === answer) && (answer !== respondentAnswer?.answerId)) {
      console.debug('Answer id changed.', respondentAnswer?.answerId, answer, questionId)
      dispatch(logicValidate({ id: questionId, isAdditional: false, isSkip: false, answerId: answer, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
    }
  }, [respondentAnswer?.answerId, respondentAnswer?.pageId, answer, questionId, debounced, respondentAnswer?.assessmentId, dispatch])

  return [answer, setAnswer]
}

export function useMultipleRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answers, setAnswers] = useState(respondentAnswer?.answerIds ?? [])
  const [debounced] = useDebouncedValue(answers, 1200)

  useEffect(() => {
    if (hideFromLogic && answers.length) {
      setAnswers([])
    }
  }, [hideFromLogic, answers, setAnswers])

  useEffect(() => {
    if ((debounced === answers) && (answers !== respondentAnswer?.answerIds)) {
      console.debug('Answer ids changed.', respondentAnswer?.answerIds, answers, questionId)
      dispatch(logicValidate({ id: questionId, isAdditional: false, isSkip: false, isMultiple: true, answerIds: answers, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
    }
  }, [respondentAnswer?.answerIds, respondentAnswer?.pageId, answers, questionId, debounced, respondentAnswer?.assessmentId, dispatch])

  return [answers, setAnswers]
}

export function useAdditionalRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const [forceUpdateId, setForceUpdateId] = useState(0)
  const forceUpdateIdRef = useRef(0)
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answer, setAnswer] = useState(respondentAnswer?.additionalAnswer ?? '')
  const [debounced] = useDebouncedValue(answer, 500)
  const validationType = useSelector(state => selectQuestionValidationType(state, questionId))
  const validation = useOpenEndedValidation(validationType, debounced)

  const answerValid = validation.isValid

  useEffect(() => {
    if (hideFromLogic && answer) {
      setAnswer(null)
    }
  }, [hideFromLogic, answer, setAnswer])

  useEffect(() => {
    const forcePostTransition = forceUpdateId !== forceUpdateIdRef.current
    if (forcePostTransition) {
      forceUpdateIdRef.current = forceUpdateId
    }
    if ((((debounced === answer) && (answer !== respondentAnswer?.additionalAnswer)) || forcePostTransition) && getValidationFunctionForValidationType(validationType)(answer)) {
      console.debug('Additional answer changed - starting timeout.', respondentAnswer?.additionalAnswer, answer, questionId, forcePostTransition)
      dispatch(logicValidate({ id: questionId, isAdditional: true, isSkip: false, additionalAnswer: answer, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
    } else if (forcePostTransition) {
      console.debug('Transitioning to next question from enter key press', { forceUpdateId, questionId })
      dispatch(onAnswerZoomTargetUpdate({ id: questionId }))
    }
    if (forcePostTransition) {
      dispatch(transitionToQuestion({ assessmentId: respondentAnswer.assessmentId, defaultPromptTransitionQuestionId: questionId }))
    }
  }, [respondentAnswer?.additionalAnswer, respondentAnswer?.pageId, answer, questionId, debounced, respondentAnswer?.assessmentId, forceUpdateId, validationType, dispatch])

  return [answer, setAnswer, setForceUpdateId, validation, answer && answerValid, (respondentAnswer?.lastSyncId !== respondentAnswer?.lastModificationId) || (answer !== debounced), !!respondentAnswer?.lastModificationId || (answer !== debounced)]
}

export function useFreeResponseAnswerTakeFocus (questionId, assessmentId, hideFromLogic, show, setForceUpdate = null) {
  const isTransitionToTarget = useSelector(state => selectIsTransitionToTarget(state, assessmentId, questionId))
  const focusRef = useRef(null)
  const focusOnChangeRef = useRef(false)
  const canFocus = show && !hideFromLogic
  const shouldFocus = isTransitionToTarget && canFocus

  useEffect(() => {
    if (shouldFocus) {
      if (!focusOnChangeRef.current) {
        console.debug('Queuing auto-focus on transition completion', { shouldFocus, focusRef })
        focusOnChangeRef.current = true
      }
    } else if (focusOnChangeRef.current) {
      console.debug('Auto-focusing or clearing queued focus', { shouldFocus, focusRef })
      if (canFocus) {
        focusRef.current?.focus()
      }
      focusOnChangeRef.current = false
    }
  }, [shouldFocus, canFocus])

  useEffect(() => {
    if (setForceUpdate) {
      console.debug('Setting up non-multiline enter listener', { setForceUpdate, focusRef })
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          // Perform action on Enter key press
          console.info('Enter key pressed!', { event })
          setForceUpdate(prev => prev + 1)
        }
      }

      const currentInput = focusRef.current
      if (currentInput) {
        currentInput.addEventListener('keydown', handleKeyPress);
      }
      return () => {
        if (currentInput) {
          currentInput.removeEventListener('keydown', handleKeyPress)
        }
      }
    }
  }, [setForceUpdate])
  return [focusRef]
}

export function useRespondentAnswerAdditionalComment (questionId) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [additionalComment, setAdditionalComment] = useState(respondentAnswer?.additionalComment ?? '')
  const [debounced] = useDebouncedValue(additionalComment, 500)

  useEffect(() => {
    if ((debounced === additionalComment) && (additionalComment !== respondentAnswer?.additionalComment)) {
      console.debug('Additional comment changed.', respondentAnswer?.additionalComment, additionalComment, questionId)
      dispatch(updateAnswerComment({ id: questionId, additionalComment: additionalComment }))
    }
  }, [respondentAnswer?.additionalComment, additionalComment, questionId, debounced, dispatch])

  return [additionalComment, setAdditionalComment]
}

export function useOpenEndedValidation (validationType, answer) {
  switch (validationType) {
    case 'Email':
      return {
        isValid: getValidationFunctionForValidationType(validationType)(answer),
        error: 'Please enter a valid email address.',
        placeholder: 'Enter email address',
        icon: <IconMail />
      }
    case 'Phone Number':
      return {
        isValid: getValidationFunctionForValidationType(validationType)(answer),
        error: 'Please enter a valid phone number.',
        placeholder: 'Enter phone number',
        icon: <IconPhone />
      }
    default:
      return {
        isValid: getValidationFunctionForValidationType(validationType)(answer),
        message: null,
        icon: null,
        placeholder: 'Enter response'
      }
  }
}

// this function matches `validateOpenEndedAnswer` function in `src/EntityManager/Build/AssessmentAssignmentManager.php`. Update both if updating this file
function getValidationFunctionForValidationType (validationType) {
  switch (validationType) {
    case 'Email':
      return (answer) => validate(answer, /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/)
    case 'Phone Number':
      return (answer) => validate(answer, /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) // found this phone number regex on SO. can be improved
    default:
      return () => true
  }
}

export function getExpertiseLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'None'
    }
    case 4: {
      return 'Expert'
    }
    default: {
      return <br />
    }
  }
}

export function getAdequacyLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'Poor'
    }
    case 2: {
      return 'Adequate'
    }
    case 4: {
      return 'Impressive'
    }
    default: {
      return <br />
    }
  }
}

export function getSatisfactoryLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'Poor'
    }
    case 1: {
      return 'Fair'
    }
    case 2: {
      return 'Satisfactory'
    }
    case 3: {
      return 'Good'
    }
    case 4: {
      return 'Excellent'
    }
    default: {
      return <br />
    }
  }
}
