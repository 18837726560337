/* eslint react/prop-types: 0 */
import React, { memo, useReducer, useRef, useState, useMemo, useEffect } from 'react'
import {
  Affix,
  Button,
  Grid,
  Group,
  MultiSelect,
  Text,
  Fieldset,
  Center,
  Select,
  NumberInput,
  Space,
  Stack,
  Switch,
  Chip,
  Transition,
  Title
} from '@mantine/core'
import { IconArrowBadgeLeftFilled, IconArrowBadgeRightFilled, IconPlus, IconMinus, IconX } from '@tabler/icons-react'
import {
  createInitialExportState,
  customExportReducer,
  CustomExportUpdate,
  legacyFormatCustomExportState,
  LegacyNodeField,
  assessmentCategoryOptions,
  filterTypeOptions
} from './CustomExportState'
import { notifications } from '@mantine/notifications'
import { requestSBCustomExport } from '../../js/api/skillbuilder_repository'
import { useOrganizationLocations, useSkillBuilderFacilitators, useSkillBuilderJobs } from './SkillbuilderCyclesHooks'
import Content from '../layout/Content'

export function SBCustomExport () {
  const clientId = document.getElementById('sb-custom-export-app-container').getAttribute('data-client-id')
  const documentStoreId = document.getElementById('sb-custom-export-app-container').getAttribute('data-store-id')
  const documentStoreName = document.getElementById('sb-custom-export-app-container').getAttribute('data-store-name')
  const storeId = (documentStoreId !== '') ? documentStoreId : null
  const storeName = (storeId !== null) ? documentStoreName : null

  return (
    <Content>
      <div>
        <CustomExportBase
          clientId={clientId}
          storeId={storeId}
          storeName={storeName}
        />
      </div>
    </Content>
  )
}

function CustomExportBase ({ clientId, storeId = null, storeName = null }) {
  const confirmNavigate = useRef(false)
  const [submitting, setSubmitting] = useState(false)
  const [state, dispatch] = useReducer(
    customExportReducer,
    (storeId !== null) ? storeId.toString() : null,
    createInitialExportState
  )
  const jobs = useSkillBuilderJobs(clientId)
  const locations = useOrganizationLocations(clientId)
  const [facilitators, facilitatorsQuerying] = useSkillBuilderFacilitators(clientId, state.locations)

  const jobsOptions = useMemo(() => {
    const sortedJobs = jobs.toSorted(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    return sortedJobs.map(element => { return { value: element.id.toString(), label: element.name } })
  }, [jobs])

  const locationsOptions = useMemo(() => {
    if (storeId !== null) {
      return [{ value: storeId.toString(), label: storeName }]
    }
    const sortedLocations = locations.toSorted(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    return sortedLocations.map(element => { return { value: element.id.toString(), label: element.name } })
  }, [storeId, storeName, locations])

  const facilitatorsOptions = useMemo(() => {
    const sortedFacilitators = facilitators.toSorted(function (a, b) {
      return a.account.first_name.localeCompare(b.account.first_name);
    });
    return sortedFacilitators.map(element => { return { value: element.id.toString(), label: element.account.first_name + ' ' + element.account.last_name } })
  }, [facilitators])

  useEffect(() => {
    if (locations && state.locations.length && state.facilitators.length && !facilitatorsQuerying) {
      const foundFacilitators = new Set(facilitators.map(elem => elem.id.toString()))
      const validFacilitators = new Set()
      for (const facilitator of state.facilitators) {
        if (foundFacilitators.has(facilitator)) {
          validFacilitators.add(facilitator)
        } else {
          console.debug(
            'Excluding previously selected facilitator as it is not found in location results.',
            facilitator,
            foundFacilitators
          )
        }
      }
      if (validFacilitators.size !== state.facilitators.length) {
        console.debug(
          'Filtering selected facilitators based on location results.',
          validFacilitators,
          state.facilitators,
          foundFacilitators
        )
        dispatch({ type: CustomExportUpdate.SetFacilitators, value: [...validFacilitators.values()] })
      }
    }
  }, [locations, state.locations, state.facilitators, facilitators, facilitatorsQuerying, dispatch])

  const doSubmit = () => {
    setSubmitting(true)
    const cleanData = legacyFormatCustomExportState(state)
    console.info('Cleaned data for export form submission from state.', cleanData, state)
    requestSBCustomExport(cleanData)
      .then(r => {
        console.info('Got post export data response.', r)
        confirmNavigate.current = false
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `SBCustomExport-on-${(new Date()).toLocaleDateString()}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        notifications.show(
          {
            color: 'green',
            title: 'Export Success',
            message: 'Export complete!'
          }
        )
      })
      .catch(err => {
        notifications.show(
          {
            color: 'red',
            title: 'Export Error',
            message: 'Export failed!'
          }
        )
        console.error('Export error.', err)
      })
      .finally(() => setSubmitting(false))
  }

  const stackConfig = submitting ? { style: { cursor: 'wait' } } : {}
  return (
    <Stack {...stackConfig}>
      <Group justify='apart' grow>
        <MultiSelect
          label='Jobs'
          placeholder={state.jobs.length ? '' : 'Filter by generic job'}
          data={ jobsOptions }
          value={state.jobs}
          onChange={(value) => dispatch({ type: CustomExportUpdate.SetJobs, value: value })}
          searchable
          clearable
        />
        <MultiSelect
          label='Locations'
          placeholder={state.locations.length ? '' : 'Filter by location'}
          data={ locationsOptions }
          value={state.locations}
          onChange={(value) => dispatch({ type: CustomExportUpdate.SetLocations, value: value })}
          searchable
          disabled={storeId !== null}
          clearable
        />
        <MultiSelect
          label='Facilitators'
          placeholder={state.facilitators.length ? '' : 'Filter by facilitator'}
          data={ facilitatorsOptions }
          value={state.facilitators}
          onChange={(value) => dispatch({ type: CustomExportUpdate.SetFacilitators, value: value })}
          searchable
          clearable
        />
      </Group>
      <Space h='md' />
      <Group justify='flex-end'>
        <Switch
          label='Advanced Score Filters'
          description={state.legacyModeEnabled ? 'Switch to advanced score filters' : 'Switch to simple score filters'}
          disabled={submitting}
          checked={!state.legacyModeEnabled}
          onChange={(event) => dispatch({ type: CustomExportUpdate.SetLegacyMode, value: !event.currentTarget.checked })}
        />
      </Group>
      <Space h='md' />
      {state.legacyModeEnabled
        ? (
        <LegacyNodes
          legacyNodes={state.legacyNodes}
          legacyModeAnd={state.legacyModeOperatorAnd}
          dispatch={dispatch}
        />
          )
        : (
        <NodesList
          nodes={state.nodes}
          dispatch={dispatch}
        />
          )}
      <Space h='md' />
      <Group justify='flex-start' wrap='no-wrap' grow>
        <Button miw='12vw' maw='12vw' disabled={submitting || facilitatorsQuerying} onClick={doSubmit}>Download</Button>
        <Button
          color='gray.6'
          miw='12vw'
          maw='12vw'
          onClick={() => {
            confirmNavigate.current = false
            window.history.go(-1)
          }}
        >
          Discard
        </Button>
      </Group>
      <UndoRedoButtons
        showUndo={(state.activeExportHistoryIndex > 0) && !state.legacyModeEnabled}
        showRedo={(state.activeExportHistoryIndex < (state.exportHistory.length - 1)) && !state.legacyModeEnabled}
        disabled={submitting}
        dispatch={dispatch}
      />
    </Stack>
  )
}

const CustomExportTextSummary = memo(function CustomExportTextSummary ({ rootNode, nodes }) {
  const firstChildId = rootNode.nodes.size ? rootNode.nodes.values().next().value : null
  const lastChildId = rootNode.nodes.size > 1 ? Array.from(rootNode.nodes.values()).pop() : firstChildId

  const textSummary = [...rootNode.nodes.values()].map(id =>
    getCustomExportTreeNodeText(nodes.get(id), true, (id === firstChildId), (id === lastChildId), nodes)
  ).join('')
  return (
    <div>
      <Text>If{textSummary}</Text>
      <Space h='md' />
    </div>
  )
})

function getCustomExportTreeNodeText (node, isRootNodeChild, isFirstChild, isLastChild, nodes) {
  const firstChildId = node.nodes.size ? node.nodes.values().next().value : null
  const lastChildId = node.nodes.size > 1 ? Array.from(node.nodes.values()).pop() : firstChildId
  let text = ' ';
  if (isFirstChild) {
    if (!isLastChild && !isRootNodeChild) {
      text += '(('
    } else {
      text += '('
    }
  } else {
    text += ' OR ('
  }
  if (node.nodes.size) {
    text += '('
  }
  text += node.assessmentCategory + ' Score ' + node.type + ' ' + (node.value ?? '{choose score}') + ((node.value || node.value === 0) ? '%' : '') + (node.nodes.size ? ') AND' : '')
  text += [...node.nodes.values()].map(id => getCustomExportTreeNodeText(nodes.get(id), false, (id === firstChildId), (id === lastChildId), nodes)).join('')
  text += ((isLastChild && !isFirstChild && !isRootNodeChild) ? '))' : ')')
  return text
}

const LegacyNodes = memo(function LegacyNodes ({ legacyNodes, legacyModeAnd, dispatch }) {
  const activeFilters = useMemo(() => {
    return [...legacyNodes.keys()]
  }, [legacyNodes])
  return (
    <div>
      <Title order={3}>Score Filters</Title>
      <Space h='md' />
      <Grid>
        <Grid.Col span={3}>
          <Chip.Group multiple value={activeFilters} onChange={(value) => dispatch({ type: CustomExportUpdate.SetLegacyNode, field: LegacyNodeField.ActiveNodes, value: value })}>
            <Stack align='center' justify='flex-start'>
              {assessmentCategoryOptions.map(elem => <Chip key={elem} value={elem}>{elem}</Chip>)}
            </Stack>
          </Chip.Group>
        </Grid.Col>
        <Grid.Col span={3}>
          {(legacyNodes.size > 1)
            ? (
            <Switch
              label='Score filter operation'
              description={legacyModeAnd ? 'Require all score criteria met' : 'Require any score criteria met'}
              onLabel='And'
              offLabel='Or'
              size='lg'
              checked={legacyModeAnd}
              onChange={(event) => dispatch({ type: CustomExportUpdate.SetLegacyModeOperator, value: event.currentTarget.checked })}
            />
              )
            : null}
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack align='center' justify='flex-start'>
            {activeFilters.map(elem => <LegacyNodeFieldEditor key={elem} node={legacyNodes.get(elem)} dispatch={dispatch} />)}
          </Stack>
        </Grid.Col>
      </Grid>
    </div>
  )
})

function LegacyNodeFieldEditor ({ node, dispatch }) {
  return (
    <Group justify='flex-start' wrap='nowrap' grow>
      <Select
        placeholder='Select Score Category'
        data={assessmentCategoryOptions}
        value={node.assessmentCategory}
        onChange={(value) => dispatch({ type: CustomExportUpdate.SetLegacyNode, field: LegacyNodeField.AssessmentCategory, value: value, category: node.assessmentCategory })}
        required={true}
        maw='15vw'
        disabled={true}
      />
      <Select
        placeholder='Select Comparison'
        data={filterTypeOptions}
        value={node.type}
        onChange={(value) => dispatch({ type: CustomExportUpdate.SetLegacyNode, field: LegacyNodeField.Type, value: value, category: node.assessmentCategory })}
        required={true}
        maw='15vw'
      />
      <NumberInput
        allowDecimal={false}
        min={0}
        max={100}
        clampBehavior='strict'
        placeholder='Filter score'
        suffix='%'
        value={node.value}
        onChange={(value) => { if (value !== '') dispatch({ type: CustomExportUpdate.SetLegacyNode, field: LegacyNodeField.Value, value: value, category: node.assessmentCategory }) }}
        required={true}
        maw='15vw'
      />
    </Group>
  )
}

const NodesList = memo(function NodesList ({ nodes, dispatch }) {
  const rootNode = nodes.get(0);
  return (
    <div>
      {rootNode.nodes.size ? <CustomExportTextSummary rootNode={rootNode} nodes={nodes} /> : null }
      <NodesEntry
        key={rootNode.id}
        filledFieldset={false}
        isFirstChild={false}
        isLastChild={false}
        node={rootNode}
        subNodeData={nodes}
        dispatch={dispatch}
      />
    </div>
  )
})

const NodesEntry = memo(function NodesEntry ({ node, filledFieldset, isFirstChild, isLastChild, subNodeData, dispatch }) {
  const firstChildId = node.nodes.size ? node.nodes.values().next().value : null
  const lastChildId = node.nodes.size > 1 ? Array.from(node.nodes.values()).pop() : firstChildId
  return (
    <div>
      <Fieldset legend={(node.id === 0) ? 'Score Filters' : (isFirstChild ? ((node.parentId === 0) ? 'If Branch' : 'AND Branch') : 'OR Branch')} variant='unstyled'>
        {(node.id === 0)
          ? null
          : (
          <Fieldset legend='Condition' variant={filledFieldset ? 'filled' : 'default'}>
            <Group justify='flex-start' wrap='nowrap' grow>
              <Select
                placeholder='Select Score Category'
                data={assessmentCategoryOptions}
                value={node.assessmentCategory}
                onChange={(value) => dispatch({ type: CustomExportUpdate.SetNodeCategory, value: value, nodeId: node.id })}
                required={true}
                maw='15vw'
              />
              <Select
                placeholder='Select Comparison'
                data={filterTypeOptions}
                value={node.type}
                onChange={(value) => dispatch({ type: CustomExportUpdate.SetNodeType, value: value, nodeId: node.id })}
                required={true}
                maw='15vw'
              />
              <NumberInput
                allowDecimal={false}
                min={0}
                max={100}
                clampBehavior='strict'
                placeholder='Filter score'
                suffix='%'
                value={node.value}
                onChange={(value) => { if (value !== '') dispatch({ type: CustomExportUpdate.SetNodeValue, value: value, nodeId: node.id }) }}
                required={true}
                maw='15vw'
              />
            </Group>
            <Group justify='flex-start' wrap='nowrap' grow>
              {isLastChild
                ? (
                <AddOrButton
                  nodeId={node.parentId}
                  hasAnyNodes={true}
                  dispatch={dispatch}
                />
                  )
                : null}
              {node.nodes.size
                ? <Text>And {node.nodes.size > 1 ? 'one of the following branches:' : 'this branch:'}</Text>
                : (
                <AddAndButton
                  nodeId={node.id}
                  dispatch={dispatch}
                />
                  )}
            </Group>
            {[...node.nodes.values()].map(id =>
              <NodesEntry
                key={id}
                node={subNodeData.get(id)}
                filledFieldset={!filledFieldset}
                isFirstChild={id === firstChildId}
                isLastChild={id === lastChildId}
                subNodeData={subNodeData}
                dispatch={dispatch}
              />
            )}
          </Fieldset>
            )}
        {(node.id === 0)
          ? (
              [...node.nodes.values()].map(id =>
            <NodesEntry
              key={id}
              node={subNodeData.get(id)}
              filledFieldset={!filledFieldset}
              isFirstChild={id === firstChildId}
              isLastChild={id === lastChildId}
              subNodeData={subNodeData}
              dispatch={dispatch}
            />
              )
            )
          : null}
        {((node.id === 0) && (!node.nodes.size))
          ? (
          <AddOrButton
            nodeId={node.id}
            hasAnyNodes={subNodeData.size > 1}
            dispatch={dispatch}
          />
            )
          : null}
        {(node.id !== 0)
          ? (
          <RemoveNodeButton
            nodeId={node.id}
            hasChildren={!!node.nodes.size}
            dispatch={dispatch}
          />
            )
          : null}
      </Fieldset>
    </div>
  )
})

const AddAndButton = memo(function AddAndButton ({ nodeId, dispatch }) {
  return (
    <Button
      leftSection={<IconPlus size='1rem' />}
      onClick={() => dispatch({ type: CustomExportUpdate.AddNode, parentNodeId: nodeId })}
      maw='12vw'
      miw='12vw'
    >
      And
    </Button>
  )
})

const AddOrButton = memo(function AddOrButton ({ nodeId, hasAnyNodes, dispatch }) {
  return (
    <Center>
      <Button
        leftSection={<IconPlus size='1rem' />}
        onClick={() => dispatch({ type: CustomExportUpdate.AddNode, parentNodeId: nodeId })}
        maw='12vw'
        miw='12vw'
      >
        {hasAnyNodes ? 'Or' : 'Add Score Filter'}
      </Button>
    </Center>
  )
})

const RemoveNodeButton = memo(function RemoveNodeButton ({ nodeId, hasChildren, dispatch }) {
  return (
    <Group wrap='nowrap' justify='flex-start' grow>
      {hasChildren
        ? (
        <Button
          leftSection={<IconMinus size='1rem' />}
          onClick={() => dispatch({ type: CustomExportUpdate.RemoveNode, nodeId: nodeId, andChildren: false })}
          maw='12vw'
          color='red.4'
        >
          Remove Single Condition
        </Button>
          )
        : null}
      <Button
        leftSection={<IconX size='1rem' />}
        onClick={() => dispatch({ type: CustomExportUpdate.RemoveNode, nodeId: nodeId, andChildren: true })}
        maw='12vw'
        color='red.6'
      >
        Remove Branch
      </Button>
    </Group>
  )
})

const UndoRedoButtons = memo(function UndoRedoButtons ({ showUndo, showRedo, disabled, dispatch }) {
  return (
    <Affix position={{ bottom: 20, right: 20 }}>
      <Grid miw='12vw' maw='12vw' gutter='xs'>
        <Grid.Col span={6}>
          <Transition transition='slide-up' duration={150} exitDuration={50} mounted={showUndo}>
            {(transitionStyles) => (
              <Button
                leftSection={<IconArrowBadgeLeftFilled size='1rem' />}
                style={transitionStyles}
                onClick={() => dispatch({ type: CustomExportUpdate.UndoExportUpdate })}
                disabled={disabled}
              >
                Undo
              </Button>
            )}
          </Transition>
        </Grid.Col>
        <Grid.Col span={6}>
          <Transition transition='slide-up' duration={150} exitDuration={50} mounted={showRedo}>
            {(transitionStyles) => (
              <Button
                rightSection={<IconArrowBadgeRightFilled size='1rem' />}
                style={transitionStyles}
                onClick={() => dispatch({ type: CustomExportUpdate.RedoExportUpdate })}
                disabled={disabled}
              >
                Redo
              </Button>
            )}
          </Transition>
        </Grid.Col>
      </Grid>
    </Affix>
  )
})
