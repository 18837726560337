import React, { useCallback, useState } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Content from '../../layout/Content'
import MediaCreate from './MediaCreate'
import MediaHome from './MediaHome'
import MediaEdit from './MediaEdit'

export default function MediaApp () {
  const [queryId, setQueryId] = useState(0)

  const onSuccess = useCallback((navigate) => {
    navigate('/build/assessments/media')
  }, [])

  const onCancel = useCallback((navigate) => {
    navigate(-1)
  }, [])

  return (
    <Content>
        <BrowserRouter>
            <Routes>
              <Route path='/build/assessments/media' element={<MediaAppFrame />}>
                <Route path='' element={<MediaHome queryId={queryId} setQueryId={setQueryId} />} />
                <Route path='create' element={<MediaCreate onSuccess={onSuccess} onCancel={onCancel} />} />
                <Route path=':id/edit' element={<MediaEdit onSuccess={onSuccess} onCancel={onCancel} />} />
              </Route>
            </Routes>
        </BrowserRouter>
    </Content>
  )
}

function MediaAppFrame () {
  return (
    <Content>
      <Outlet />
    </Content>
  )
}
