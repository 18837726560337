/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Group } from '@mantine/core'
import { IconUserCircle } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import Dropdown from '../../core/Dropdown'
import SystemNotification from '../../core/SystemNotification'
import { HelpButton } from '../../core/HelpButton';
import Content from '../Content'

export default function ProfileControls () {
  const assumedUser = document.getElementById('profile-controls-container').getAttribute('data-assumeduser')
  const userName = document.getElementById('profile-controls-container').getAttribute('data-triggercontent')
  const cyclePassId = document.getElementById('profile-controls-container').getAttribute('data-cycle-pass')
  const isAdmin = document.getElementById('profile-controls-container').hasAttribute('data-has-admin-role')

  const MenuTarget = (
    <Button rightSection={<IconUserCircle />} size='compact-md' color='gray'>
      <span className={'hide-for-small-only'}>
        {userName} {assumedUser && `(${assumedUser})`}
      </span>
    </Button>
  )

  const menuItems = useMemo(() => {
    const items = [
      ...(!userName && cyclePassId
        ? (
            []
          )
        : (
            [
              {
                href: '/account/general',
                label: 'Account'
              }
            ]
          )),
      {
        href: '/invite',
        target: '_blank',
        variant: 'important',
        label: 'Invite people and get paid!'
      },
      {
        href: '/terms-of-service',
        target: '_blank',
        label: 'Terms of Service'
      }
    ]
    if (assumedUser) {
      items.push({
        href: '/?_switch_user=_exit',
        label: `Unassume ${assumedUser}`
      })
    }
    items.push({
      href: (cyclePassId && !userName) ? '/your/invites/logout' : '/logout',
      label: 'Logout'
    })
    return items
  }, [cyclePassId, userName, assumedUser])

  return (
  <Content>
    <Group gap='xs' align='flex-start'>
      {isAdmin && <SystemNotification />}
      {(!cyclePassId || userName) && <HelpButton />}
      <Dropdown target={MenuTarget} items={menuItems} openDelay={100} closeDelay={300} />
    </Group>
  </Content>
  )
}
