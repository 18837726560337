/* eslint react/prop-types: 0 */
import React, { useCallback, useState } from 'react';
import { mediaTypeFromId } from '../../../../js/generated/enums/MediaType';
import { Box, Container } from '@mantine/core';
import { QuestionStateUpdate } from './QuestionsState';
import { BaseModal } from './BaseModal';
import { useSearchParams } from 'react-router-dom';
import MediaHome from '../MediaHome';
import { MediaEditor } from '../MediaEdit';
import MediaCreate from '../MediaCreate';

export function MediaSelectorModal ({ mediaPopup, setMediaPopup, dispatch }) {
  const [queryId, setQueryId] = useState(0)
  const [editingMedia, setEditingMedia] = useState(null)
  const [creatingMedia, setCreatingMedia] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  if (mediaPopup.showing) {
    console.debug('Media selector modal search params.', searchParams)
  }

  const handleSelectMedia = useCallback((media) => {
    console.debug('Confirming media popup selection.', media)
    const newMedia = { ...media, typeId: media.type, type: mediaTypeFromId(media.type) }
    dispatch({ type: QuestionStateUpdate.MediaPopupSelect, newMedia: newMedia })
    setMediaPopup({ showing: false, selected: null })
    setSearchParams({})
  }, [dispatch, setMediaPopup, setSearchParams])

  const handleCloseMedia = useCallback(() => {
    setMediaPopup({ showing: false, selected: null })
    setSearchParams({})
  }, [setMediaPopup, setSearchParams])

  const handleEditMedia = useCallback((media) => {
    console.debug('Editing media.', media)
    setEditingMedia(media)
  }, [setEditingMedia])

  const handleCancelEditingMedia = useCallback(() => {
    setEditingMedia(null)
  }, [setEditingMedia])

  const handleFinishEditingMedia = useCallback(() => {
    setEditingMedia(null)
    setQueryId((prev) => prev + 1)
  }, [setEditingMedia, setQueryId])

  const handleCreateMedia = useCallback(() => {
    setCreatingMedia(true)
  }, [setCreatingMedia])

  const handleCancelCreatingMedia = useCallback(() => {
    setCreatingMedia(false)
  }, [setCreatingMedia])

  const handleFinishCreatingMedia = useCallback(() => {
    setCreatingMedia(false)
    setQueryId((prev) => prev + 1)
  }, [setCreatingMedia, setQueryId])

  return (
    <BaseModal
      title='Attach Media'
      showing={mediaPopup.showing}
      onClose={handleCloseMedia}
    >
      <Box w='100%' mih='80vh' mah='80vh'>
        <Container fluid px='xs'>
          <MediaHome
            queryId={queryId}
            setQueryId={setQueryId}
            syncParams={mediaPopup.showing}
            selectCallback={handleSelectMedia}
            editMediaCallback={handleEditMedia}
            createMediaCallback={handleCreateMedia}
          />
        </Container>
        <BaseModal
          title='Edit Media'
          showing={!!editingMedia}
          onClose={handleCancelEditingMedia}
        >
          <MediaEditor
            id={editingMedia?.id}
            onSuccess={handleFinishEditingMedia}
            onCancel={handleCancelEditingMedia}
          />
        </BaseModal>
        <BaseModal
          title='Create Media'
          showing={creatingMedia}
          onClose={handleCancelCreatingMedia}
        >
          <MediaCreate
            onSuccess={handleFinishCreatingMedia}
            onCancel={handleCancelCreatingMedia}
          />
        </BaseModal>
      </Box>
    </BaseModal>
  )
}
