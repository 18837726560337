import { useParams } from 'react-router-dom';
import { useCyclePassDetail } from '../passes/CyclePassHooks';
import { Group, Space, Title, Text } from '@mantine/core';
import { LockableIcon } from '../summaries/LockableIcon';
import { CycleNavButton, InviteHomeButton } from '../nav/CycleNavButton';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { AssessmentInvitesTable } from './AssessmentInvitesTable';
import React from 'react';
import { CyclePageTitle } from '../nav/CyclePageTitle';
import { ComingSoonTooltip } from '../nav/ComingSoonTooltip';

export function PhaseInvitesList () {
  const { cycleId } = useParams()

  return (
    <>
      <CyclePageTitle cycleId={cycleId} pageName='Phase Invites' />
      <Group>
        <InviteHomeButton />
        <CycleNavButton cycleId={cycleId} route={CyclePassRoute.CycleInvitesCreate} name='Create' />
      </Group>
      <Space h='md' />
      <AssessmentInvitesTable cycleId={cycleId} />
    </>
  )
}

export function PassportsPhaseInvitesList () {
  const { cycleId, passId } = useParams()
  const [cyclePass, querying] = useCyclePassDetail(passId, cycleId, !passId)

  return (
    <>
      <Title>Cycle Passport #{passId ?? 'Id Missing'} Phase Invites</Title>
      <Space h='md' />
      <Group>
        <LockableIcon item={cyclePass} querying={querying} />
        <InviteHomeButton />
        <ComingSoonTooltip>
          <Text>Create</Text>
        </ComingSoonTooltip>
      </Group>
      <Space h='md' />
      <AssessmentInvitesTable cycleId={cycleId} cyclePassId={passId} />
    </>
  )
}
