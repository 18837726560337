/* eslint react/prop-types: 0 */
import React, { Fragment, useMemo, memo, useCallback } from 'react';
import {
  Text,
  Center,
  Box,
  ScrollArea,
  Grid,
  Title
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMatchingProgresses,
  selectFreeResponsesForQuestionId,
  selectMatchingResponseIdsForQuestion,
  selectNumberAnsweredOrSkippedResponsesByQuestionId,
  selectNumberAnyAnswerResponsesByQuestionId,
  selectNumberMatchingResponsesForAnswers
} from './distributionResponsesSlice';
import { selectAllPagesLoaded } from './distributionReportPagesSlice';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom'

const answerScoresFormat = (value) => value.toLocaleString('fullwide', { maximumFractionDigits: 2 })

export function AnswersDistributionReport ({ question, vertical = true, toPDF = false }) {
  const loaded = useSelector(state => selectAllPagesLoaded(state))
  console.debug(
    'Answers distribution report checking load state for question',
    {
      id: question.id,
      loaded: loaded,
      question: question
    }
  )
  return (
    <ScrollArea.Autosize mah='20rem' scrollbars='y'>
      <>
        {!!loaded && (<AnswersDistributionCharts question={question} vertical={vertical} toPDF={toPDF} />)}
      </>
    </ScrollArea.Autosize>
  )
}

function AnswersDistributionCharts ({ question, vertical = true, toPDF = false }) {
  const answers = question.answers
  const questionId = question.id
  const dispatch = useDispatch()
  const answeredOrSkippedCount = useSelector(state => selectNumberAnsweredOrSkippedResponsesByQuestionId(state, questionId))
  const answeredCount = useSelector(state => selectNumberAnyAnswerResponsesByQuestionId(state, questionId))
  const matchingResponseCounts = useSelector(
    state => selectNumberMatchingResponsesForAnswers(state, questionId, answers)
  )
  const selectionResponseIds = useSelector(state => selectMatchingResponseIdsForQuestion(state, questionId))
  const freeResponseCounts = useSelector(state => selectFreeResponsesForQuestionId(state, questionId, answers))
  const totalSelectionResponders = selectionResponseIds.length
  const hasSelectedResponses = !!totalSelectionResponders
  const hasFreeResponses = !!Object.keys(freeResponseCounts).length
  console.debug(
    'Answers distribution charts updating',
    { question, answers, matchingResponseCounts, freeResponseCounts, totalSelectionResponders, vertical, toPDF }
  )

  const selectedResponseData = useMemo(() => {
    console.debug('Memo recalculating selected chart data', { matchingResponseCounts, totalSelectionResponders, answers })
    const answerContents = Object.fromEntries(answers.map(answer => [answer.id, answer.content]))
    return Object.entries(matchingResponseCounts).map(([answerId, answerCount]) =>
      ({
        answer: answerContents[answerId] ?? `Unknown answer #${answerId}`,
        answerId: answerId,
        answerKey: null,
        percentage: (answerCount / totalSelectionResponders) * 100,
        count: answerCount
      })
    )
  }, [matchingResponseCounts, totalSelectionResponders, answers])

  const totalFreeResponders = useMemo(() => {
    return Object.values(freeResponseCounts).reduce(
      (totalCount, currentCount) => totalCount + currentCount,
      0
    )
  }, [freeResponseCounts])

  const freeResponseData = useMemo(() => {
    console.debug('Memo recalculating free response chart data', { freeResponseCounts, totalFreeResponders })
    const numberResponses = Math.max(1, totalFreeResponders)
    return Object.entries(freeResponseCounts).sort(([keyA, countA], [keyB, countB]) => countB - countA).map(([responseContent, answerCount]) =>
      ({
        answer: responseContent,
        answerId: null,
        answerKey: responseContent,
        percentage: (answerCount / numberResponses) * 100,
        count: answerCount
      })
    )
  }, [freeResponseCounts, totalFreeResponders])

  const navigateToMatchingProgresses = useCallback(async (answerData) => {
    console.debug('Navigating to matching progresses', { answerData, questionId })
    dispatch(getMatchingProgresses({ ...answerData, questionId }))
      .unwrap()
      .catch((err) => {
        console.error('Matching progresses error', { questionId, answerData, err })
        notifications.show(
          {
            color: 'red',
            title: 'Navigation Error',
            message: 'There was an error navigating to matching responses - please try again!',
            autoClose: 8000
          }
        )
      })
  }, [questionId, dispatch])

  console.debug('Chart data', { selectedResponseData, freeResponseData })
  return (
    <Box>
      {!!hasSelectedResponses && (
        <ResponseDistributionGrid responseCounts={selectedResponseData} anyTotal={answeredOrSkippedCount} answeredTotal={answeredCount} divTotal={totalSelectionResponders} onRowClick={navigateToMatchingProgresses} />
      )}
      {!!hasFreeResponses && (
        <ResponseDistributionGrid responseCounts={freeResponseData} anyTotal={answeredOrSkippedCount} answeredTotal={answeredCount} divTotal={totalFreeResponders} onRowClick={navigateToMatchingProgresses} />
      )}
      {!(hasSelectedResponses || hasFreeResponses) && (
        <Center>
          <Text>No selection or free response submissions</Text>
        </Center>
      )}
    </Box>
  )
}

const ResponseDistributionGrid = memo(function ResponseDistributionGrid ({ responseCounts, anyTotal, answeredTotal, divTotal, onRowClick }) {
  console.debug('ResponseDistributionGrid updating', { responseCounts, anyTotal, answeredTotal, divTotal })
  const indexOffset = responseCounts.length <= (barColors.length - 2) ? 2 : 0
  return (
    <Grid columns={24} overflow='hidden'>
      <Grid.Col span={6}>
        <Title order={4} ta='left' td='underline'>Response</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <div />
      </Grid.Col>
      <Grid.Col span={3}>
        <Title order={4} ta='center' td='underline'>Percentage</Title>
      </Grid.Col>
      <Grid.Col span={3}>
        <Title order={4} ta='center' td='underline'>Total</Title>
      </Grid.Col>
      {responseCounts.map((response, index) => (
        <Fragment key={response.answer}>
          <Grid.Col span={6}>
            <Text ta='left'>{response.answer}</Text>
          </Grid.Col>
          <Grid.Col span={12}>
            <SingleResponseBar index={index + indexOffset} responsePercentage={answerScoresFormat(response.percentage)} />
          </Grid.Col>
          <Grid.Col span={3}>
            <Text ta='center'>{answerScoresFormat(response.percentage)}%</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Center>
              <Link onClick={() => onRowClick(response)} to=''><Text ta='center'>{response.count}</Text></Link>
            </Center>
          </Grid.Col>
        </Fragment>
      ))}
      <Grid.Col span='auto'>
        <div/>
      </Grid.Col>
      <Grid.Col span='content'>
        <Text ta='right' c='dimmed' size='sm'>
          Statistics based on <Text fw={700} span>{divTotal}</Text> matching respondents.
        </Text>
      </Grid.Col>
    </Grid>
  )
})

const barColors = ['red', 'yellow', 'blue', 'green', 'purple', 'orange']
const borderRadiusStyle = { borderRadius: 8 }

function SingleResponseBar ({ index, responsePercentage }) {
  console.debug('Single response bar updating', { index, responsePercentage })
  return (
    <Box h='1rem' mih='1rem' mah='1rem' my='auto' miw='100%' maw='100%' w='100%' pos='relative'>
      <Box style={borderRadiusStyle} bg='gray.2' miw='100%' maw='100%' mih='100%' pos='absolute' top={0} left={0}>
      </Box>
      <Box
        style={borderRadiusStyle}
        bg={barColors[index % barColors.length]}
        w={`${responsePercentage}%`}
        miw={`${responsePercentage}%`}
        maw={`${responsePercentage}%`}
        mih='100%'
        mah='100%'
        pos='absolute'
        top={0}
        left={0}
      >
      </Box>
    </Box>
  )
}
