import _ from 'lodash'

export function groupStatuses (statuses) {
  return [
    {
      group: 'Pending',
      items: _.map(_.filter(statuses, { tier: { name: 'pending' } }), status => ({ value: status.name, label: status.name }))
    },
    {
      group: 'Not Recommended',
      items: _.map(_.filter(statuses, { tier: { name: 'not recommended' } }), status => ({ value: status.name, label: status.name }))
    },
    {
      group: 'Withdrew',
      items: _.map(_.filter(statuses, { tier: { name: 'withdrew' } }), status => ({ value: status.name, label: status.name }))
    },
    {
      group: 'Duplicate',
      items: _.map(_.filter(statuses, { tier: { name: 'duplicate' } }), status => ({ value: status.name, label: status.name }))
    },
    {
      group: 'Hired',
      items: _.map(_.filter(statuses, { tier: { name: 'hired' } }), status => ({ value: status.name, label: status.name }))
    }
  ]
}
