import { createContext } from 'react';

/**
 * @typedef {string} NamespaceContext
 */

/**
 * @type {NamespaceContext}
 */
export const defaultNamespaceContext = ''

/**
 * @type {React.Context<NamespaceContext>}
 */
export const NamespaceContext = createContext(defaultNamespaceContext)

/**
 * @typedef {Object} TableDataContext
 * @property {?Object} collection
 * @property {boolean} querying
 */

/**
 * @type {TableDataContext}
 */
export const defaultTableDataContext = {
  collection: null, querying: true
}

/**
 * @type {React.Context<TableDataContext>}
 */
export const TableDataContext = createContext({ ...defaultTableDataContext })

/**
 * @typedef {Object} TableStylesContext
 * @property {?Function} generateRowStyles
 */

/**
 * @type {TableStylesContext}
 */
export const defaultTableStylesContext = {
  generateRowStyles: null
}

/**
 * @type {React.Context<TableStylesContext>}
 */
export const TableStylesContext = createContext({ ...defaultTableStylesContext })

/**
 * @typedef {(int|string)[]} TableRowIdsContext
 */

/**
 * @type {TableRowIdsContext}
 */
export const defaultTableRowIdsContext = []

/**
 * @type {React.Context<TableRowIdsContext>}
 */
export const TableRowIdsContext = createContext(defaultTableRowIdsContext)

/**
 * @typedef {boolean} TableQueryingContext
 */

/**
 * @type {TableQueryingContext}
 */
export const defaultTableQueryingContext = true

/**
 * @type {React.Context<TableQueryingContext>}
 */
export const TableQueryingContext = createContext(defaultTableQueryingContext)

/**
 * @typedef {Object} TableFiltersContext
 * @property {Object} defaultFilters
 * @property {?function} clearSavedFilters
 * @property {?function} saveFilters
 * @property {boolean} showSaveFilters
 * @property {boolean} showClearSavedFilters
 */

/**
 * @type {TableFiltersContext}
 */
export const defaultTableFiltersContext = {
  defaultFilters: {}, clearSavedFilters: null, saveFilters: null, showSaveFilters: false, showClearSavedFilters: false
}

/**
 * @type {React.Context<TableFiltersContext>}
 */
export const TableFiltersContext = createContext({ ...defaultTableFiltersContext })

/**
 * @typedef {boolean} TableFiltersLoadedContext
 */

/**
 * @type {TableFiltersLoadedContext}
 */
export const defaultTableFiltersLoadedContext = false

/**
 * @type {React.Context<TableFiltersLoadedContext>}
 */
export const TableFiltersLoadedContext = createContext(defaultTableFiltersLoadedContext)

/**
 * @typedef {Object} TableColumnsContext
 * @property {Array} columns
 * @property {Array} toggleableVisibilityColumns Array of columns which can be hidden.
 * @property {Array} defaultHiddenColumns Array of columns which can be hidden.
 * @property {Set} hiddenColumnsSet Set of hidden column ids, is otherwise determined through array iteration multiple times per render.
 * @property {?function} onColumnVisibilityChange Optional callback when column visibility is altered. Called with (null, null) for reset, (null, visible) for show/hide all, or (id, visible) for single column.
 * @property {?function} clearSavedColumns
 * @property {?function} saveColumns
 * @property {boolean} showSaveColumns
 * @property {boolean} showClearSavedColumns
 */

/**
 * @type {TableColumnsContext}
 */
export const defaultColumnsContext = {
  columns: [], toggleableVisibilityColumns: [], defaultHiddenColumns: [], hiddenColumnsSet: new Set(), onColumnVisibilityChange: null, clearSavedColumns: null, saveColumns: null, showSaveColumns: false, showClearSavedColumns: false
}

/**
 * @type {React.Context<TableColumnsContext>}
 */
export const TableColumnsContext = createContext({ ...defaultColumnsContext })

/**
 * @typedef {Object} TableCollapseContext
 * @property {Array} defaultCollapsed Array of columns which are collapsed by default.
 * @property {Set} collapsed Set of collapsed column ids.
 * @property {?function} onCollapseChange Optional callback when column collapse state is altered. Called with (null, null) for reset, (null, collapsed) for collapse/expand all, or (column.collapseGroup ?? column.id, collapsed) for single column.
 * @property {Map} collapseMap CollapsedGroupId => summaryColumnId
 * @property {Map} collapseChildren CollapsedGroupId => childModuleIds[]
 * @property {Array} expandedSummaries Array of summary column ids currently expanded
 */

/**
 * @type {TableCollapseContext}
 */
export const defaultCollapseContext = {
  defaultCollapsed: [], collapsed: new Set(), onCollapseChange: null, collapseMap: new Map(), collapseChildren: new Map(), expandedSummaries: []
}

/**
 * @type {React.Context<TableCollapseContext>}
 */
export const TableCollapseContext = createContext({ ...defaultCollapseContext })

/**
 * @typedef {Object} TableControlContext
 * @property {?function} updateSearchParamField
 * @property {?function} batchUpdateSearchParams
 * @property {Object} activeFilters
 * @property {Object} activeIndexes
 */

/**
 * @type {TableControlContext}
 */
export const defaultControlContext = {
  updateSearchParamField: null, batchUpdateSearchParams: null, activeFilters: {}, activeIndexes: {}
}

/**
 * @type {React.Context<TableControlContext>}
 */
export const TableControlContext = createContext({ ...defaultControlContext })

/**
 * @typedef {string} CycleContext
 */

/**
 * @type {CycleContext}
 */
export const defaultCycleContext = ''

/**
 * @type {React.Context<CycleContext>}
 */
export const CycleContext = createContext(defaultCycleContext)
