import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mantine/core'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import TriggersList from '../../hire/triggers/TriggersList'
import {
  useDeleteHiringTemplateTriggerMutation,
  useGetHiringTemplateEmailTemplatesQuery,
  useGetHiringTemplateQuery,
  useToggleHiringTemplateTriggerMutation
} from '../../../redux/query/build/hiringTemplatesApi.slice'

export default function HiringTemplateTriggerManagement () {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: hiringTemplate, error: hiringTemplateError, isLoading: hiringTemplateLoading } = useGetHiringTemplateQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetHiringTemplateEmailTemplatesQuery(id)

  const [toggleTrigger] = useToggleHiringTemplateTriggerMutation()
  const [deleteTrigger] = useDeleteHiringTemplateTriggerMutation()

  if (hiringTemplateError || emailTemplatesError) {
    return <ErrorLoading />
  }

  if (hiringTemplateLoading || emailTemplatesLoading) {
    return <Loading />
  }

  return (
    <>
      <TriggersList
        triggers={hiringTemplate.triggers}
        emailTemplates={emailTemplates.data}
        toggleTrigger={trigger => toggleTrigger({ id: id, triggerId: trigger.id }).unwrap()}
        editTrigger={trigger => navigate(`/build/hiring-templates/${hiringTemplate.id}/triggers/${trigger.id}/edit`)}
        deleteTrigger={trigger => deleteTrigger({ id: id, triggerId: trigger.id }).unwrap()}
      />
      <Button onClick={() => navigate(`/build/hiring-templates/${id}/triggers/create`)} color='teal.6' mt='1rem'>Create Trigger</Button>
    </>
  )
}
