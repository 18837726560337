import { ApiMethods, apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';

export const CyclePassesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCyclePassesForCycle: builder.query({
      query: ({ cycleId, ...params }) => `invites/passes/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`,
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.CyclePasses, id: id })),
              { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) }]
    }),
    getCyclePassDetail: builder.query({
      query: ({ cyclePassId }) => `invites/passes/${cyclePassId}`,
      providesTags: ({ id }, _, { cyclePassId: cyclePassIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.CyclePasses, id: id ?? cyclePassIdParam }, { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) }]
    }),
    getCyclePassAnalytics: builder.query({
      query: (cycleId) => `invites/passes/cycles/${cycleId}/analytics`,
      providesTags: (__, _, cycleIdParam) =>
        [{ type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) }]
    }),
    syncCyclePassScores: builder.mutation({
      query: ({ cycleId, passId }) => ({
        url: passId ? `invites/passes/${passId}/sync` : `invites/passes/cycles/${cycleId}/sync`,
        method: ApiMethods.Post
      }),
      invalidatesTags: (result, error, { passId: passIdParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        ...(result?.syncedIds?.map((id) => ({ type: ApiTags.CyclePasses, id: id })) ?? []),
        ...(passIdParam ? [{ type: ApiTags.CyclePasses, id: passIdParam }] : [])
      ]
    }),
    cyclePassLock: builder.mutation({
      query: ({ passId, cycleId, unlock }) => ({
        url: unlock ? `invites/passes/${passId}/unlock` : `invites/passes/${passId}/lock`,
        method: ApiMethods.Post,
        body: {}
      }),
      invalidatesTags: (result, error, { passId: passIdParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: passIdParam }
      ]
    }),
    cyclePassEditEmail: builder.mutation({
      query: ({ passId, cycleId, ...params }) => ({
        url: `invites/passes/${passId}/edit/email`,
        method: ApiMethods.Post,
        body: params
      }),
      invalidatesTags: (result, error, { passId: passIdParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: passIdParam }
      ]
    })
  })
})

export const { useGetCyclePassesForCycleQuery, useGetCyclePassDetailQuery, useGetCyclePassAnalyticsQuery, useSyncCyclePassScoresMutation, useCyclePassLockMutation, useCyclePassEditEmailMutation } = CyclePassesApi
