/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from 'react'
import { Input, Menu } from '@mantine/core'
import { RichTextEditor, Link } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { IconPercentage } from '@tabler/icons-react'
import _ from 'lodash'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Dropdown from '../Dropdown'
import { useTimeout } from '@mantine/hooks'

export default function EmailEditor ({ form, name, content, platform, category }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Color,
      TextStyle
    ],
    content: content,
    onUpdate: function ({ editor }) {
      start(editor)
    }
  })

  const update = ([e]) => {
    form.setFieldValue(name, e?.getHTML() ?? '')
    return clear()
  }

  const { start, clear } = useTimeout(update, 300)

  useEffect(() => {
    editor?.commands.setContent(content)
    form.setFieldValue(name, editor?.getHTML() ?? '')
  }, [content, editor])

  return (
    <Input.Wrapper
      aria-label='body'
      error={form.errors[name]}
      label='Body'
      withAsterisk
    >
      <RichTextEditor editor={editor} mb='calc(10px / 2)' mih='400px' aria-labelledby='body'>
        <RichTextEditor.Toolbar sticky stickyOffset={50}>
          <RichTextEditor.ControlsGroup>
          <RichTextEditor.ColorPicker
            colors={[
              '#25262b',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14'
            ]}
          />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <PlaceholdersControl editor={editor} platform={platform} category={category} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content h='100%' onClick={() => editor.commands.focus(!editor.view.hasFocus() && 'end') } />
      </RichTextEditor>
    </Input.Wrapper>
  )
}

export function PlaceholdersControl ({ editor, platform, category }) {
  const applicant = []
  const candidate = []
  const facilitator = []
  const cycle = []
  const links = []

  if (platform === 0) {
    applicant.push(
      { wildcard: '%CANDIDATE%', label: 'Applicant\'s full name' },
      { wildcard: '%CANDIDATE_FIRST_NAME%', label: 'Applicant\'s first name' }
    )

    cycle.push(
      { wildcard: '%COMPANY%', label: 'Organization\'s name' },
      { wildcard: '%LOCATION%', label: 'Location\'s name' },
      { wildcard: '%JOB%', label: 'Job\'s name' },
      { wildcard: '%DEADLINE_DATE%', label: 'Deadline date' }
    )

    if (category === 3 || category === 6) {
      links.push({ wildcard: '%LINK%', label: 'Assessment Platform invite' })
    }

    if (category === 7 || category === 8 || category === 12) {
      links.push({ wildcard: '%KEYSURVEY_INVITE_LINK%', label: 'KeySurvey Prescreen invite' })
    }

    if (category === 9 || category === 10 || category === 12) {
      links.push({ wildcard: '%PRESCREEN_INVITE_LINK%', label: 'HireScore Prescreen invite' })
    }

    if (category === 12) {
      links.push({ wildcard: '%JOB_LINK%', label: 'Cycle\'s Job Posting URL' })
      links.push({ wildcard: '%UNSUBSCRIBE%', label: 'Unsubscribe Link' })
    }
  } else if (platform === 1) {
    candidate.push(
      { wildcard: '%CANDIDATE%', label: 'Candidate\'s full name' },
      { wildcard: '%CANDIDATE_EMAIL%', label: 'Candidate\'s email address' },
      { wildcard: '%CANDIDATE_PASSWORD%', label: 'Candidate\'s login password' }
    )

    facilitator.push(
      { wildcard: '%FACILITATOR%', label: 'Facilitator\'s full name' },
      { wildcard: '%FACILITATOR_EMAIL%', label: 'Facilitator\'s email address' },
      { wildcard: '%FACILITATOR_PHONE%', label: 'Facilitator\'s phone number' }
    )

    cycle.push(
      { wildcard: '%JOB%', label: 'Job\'s name' }
    )

    links.push(
      { wildcard: '%URL%', label: 'Login page' }
    )
  } else if (platform === 2) { // 2 === Edit Questions
    cycle.push(
      { wildcard: '%COMPANY%', label: 'Organization\'s name' },
      { wildcard: '%JOB%', label: 'Job\'s name' }
    )
  }

  const PlaceholdersButton = (
    <RichTextEditor.Control
      aria-label='Insert placeholder'
      title='Insert placeholder'
    >
      <IconPercentage size='1rem' />
    </RichTextEditor.Control>
  )

  return (
    <Dropdown closeOnItemClick={false} trigger='click' target={PlaceholdersButton}>
        {
          _.size(candidate) > 0 && (
            <>
              <Menu.Label>Candidate</Menu.Label>
              {_.map(candidate, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
            </>
          )
        }
        {
          _.size(applicant) > 0 && (
            <>
              <Menu.Label>Applicant</Menu.Label>
              {_.map(applicant, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
            </>
          )
        }
        {
          _.size(facilitator) > 0 && (
            <>
              <Menu.Label>Facilitator</Menu.Label>
              {_.map(facilitator, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
            </>
          )
        }
        {
          _.size(cycle) > 0 && (
            <>
              <Menu.Label>Cycle</Menu.Label>
              {_.map(cycle, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
            </>
          )
        }
        {
          _.size(links) > 0 && (
            <>
              <Menu.Label>Links</Menu.Label>
              {_.map(links, placeholder => (<Menu.Item key={placeholder.wildcard} onClick={() => editor?.chain().insertContent(placeholder.wildcard).focus(null, { scrollIntoView: true }).run()}>{placeholder.label}</Menu.Item>))}
            </>
          )
        }
    </Dropdown>
  )
}
