/* eslint react/prop-types: 0 */
import { Badge } from '@mantine/core';
import { renderDateFromSource } from '../formatUtil';
import React from 'react';

export function ExpirationDateBadge ({ inviteConfig, locked = false }) {
  const hasExpirationDate = !!inviteConfig?.expiration_date

  return (
    <>
      {locked
        ? (
        <Badge color='red' variant='light'>{hasExpirationDate ? `Expired by ${renderDateFromSource(inviteConfig.expiration_date, null, true)}` : 'Never expires if activated'}</Badge>
          )
        : (
        <Badge color={hasExpirationDate ? 'yellow' : 'green'} variant='light'>{hasExpirationDate ? `Expires: ${renderDateFromSource(inviteConfig.expiration_date, null, true)}` : 'Never expires'}</Badge>
          )}
    </>
  )
}
