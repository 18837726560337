import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPromptTransitionToQuestionId, transitionToQuestion } from './assessmentsSlice';
import { useHotkeys } from '@mantine/hooks';
import { Affix, Box, Button, Kbd, Transition } from '@mantine/core';
import PropTypes from 'prop-types';

export const FreeResponseTransitionButton = memo(function FreeResponseTransitionButton ({ assessmentId }) {
  const dispatch = useDispatch()
  const promptTransitionToQuestionId = useSelector(state => selectPromptTransitionToQuestionId(state, assessmentId))
  useHotkeys([['ctrl+shift+Q', () => {
    console.debug('Transition hotkey triggered.', { promptTransitionToQuestionId })
    if (promptTransitionToQuestionId) {
      dispatch(transitionToQuestion({ questionId: promptTransitionToQuestionId, assessmentId: assessmentId }))
    }
  }]], [])

  return (
    <Affix position={{ bottom: 40, right: 40 }}>
      <Transition transition='slide-up' duration={100} exitDuration={75} mounted={!!promptTransitionToQuestionId}>
        {(transitionStyles) => (
          <Box
            style={transitionStyles}
          >
            <QuestionTransitionButton
              assessmentId={assessmentId}
              promptTransitionToQuestionId={promptTransitionToQuestionId}
            />
          </Box>
        )}
      </Transition>
    </Affix>
  )
})

const QuestionTransitionButton = memo(function QuestionTransitionButton ({ assessmentId, promptTransitionToQuestionId }) {
  const dispatch = useDispatch()
  return (
    <Button
      disabled={!promptTransitionToQuestionId}
      onClick={() => { dispatch(transitionToQuestion({ questionId: promptTransitionToQuestionId, assessmentId: assessmentId })) }}
      size='xl'
    >
      <Box>
        <div>
          View Next
        </div>
        <div>
          <Kbd>Ctrl</Kbd> + <Kbd>Shift</Kbd> + <Kbd>Q</Kbd>
        </div>
      </Box>
    </Button>
  )
})

FreeResponseTransitionButton.propTypes = {
  assessmentId: PropTypes.string.isRequired
}

QuestionTransitionButton.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  promptTransitionToQuestionId: PropTypes.number
}
