/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Group, SimpleGrid } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import { isNotEmpty, useForm } from '@mantine/form'
import Messages from './Messages'
import { randomId } from '@mantine/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Program from './Program'
import { contact } from '../../../js/api/email_repository'
import { showNotification } from '@mantine/notifications'
import FormError from '../../forms/FormError'
import NoticeAlert from '../../core/Alert/NoticeAlert'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Form ({
  applicants,
  getTemplates,
  getTextTemplates,
  getBatteries,
  getSubmissions,
  onSuccessRedirect,
  previewApplicant,
  cycleId = null
}) {
  const [show, setShow] = useState(false)
  const [cycles, setCycles] = useState([])
  const [templates, setTemplates] = useState([])
  const [textTemplates, setTextTemplates] = useState([])
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      applicants: applicants,
      messages: [
        {
          key: randomId(),
          type: 'email',
          send_at: null,
          template: null,
          subject: '',
          body: ''
        }
      ],
      retakes: [],
      ap_cycle: null
    },
    transformValues: values => {
      const transformedValues = {
        applicants: applicants,
        emails: [],
        texts: [],
        retakes: [],
        ap_cycle: values.ap_cycle
      }

      values.messages.forEach(message => {
        if (message.type === 'email') {
          transformedValues.emails.push({
            send_at: message.send_at !== null ? dayjs(message.send_at).format() : null,
            template: message.template,
            subject: message.subject,
            body: message.body
          })
        } else {
          transformedValues.texts.push({
            send_at: dayjs(message.send_at).format(),
            template: message.template,
            body: message.body
          })
        }
      })

      values.retakes.forEach(retake => {
        transformedValues.retakes.push({ applicant: retake.submission.id, retake: retake.retake ? '1' : '0' })
      })

      return transformedValues
    },
    validate: {
      applicants: isNotEmpty('At least one applicant needs to be selected'),
      messages: {
        subject: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === 'email' && value === '') {
            return 'Subject cannot be empty'
          }

          return null
        },
        body: value => value !== '' && value !== '<p></p>' ? null : 'Body cannot be empty',
        template: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === 'text' && value === null) {
            return 'Please choose a template'
          }

          return null
        }
      },
      messagesNotEmpty: (value, values) => _.size(values.messages) < 1 ? 'At least one message needs to be added' : null,
      ap_cycle: (value, values) => {
        let hasInvitation = false

        values.messages.forEach(message => {
          if (message.type === 'email') {
            for (const template of templates) {
              if (message.template === template.id) {
                if (template.category?.tag === 'proctorfree-invitation' || template.category?.tag === 'proctorfree-reminder') {
                  hasInvitation = true
                }
              }
            }
          } else {
            for (const template of textTemplates) {
              if (message.template === template.id) {
                if (template.category?.tag === 'proctorfree-invitation' || template.category?.tag === 'proctorfree-reminder') {
                  hasInvitation = true
                }
              }
            }
          }
        })

        return hasInvitation && value === null ? 'Please select a battery to invite to' : null
      }
    }
  })

  useEffect(() => {
    getTemplates().then(response => setTemplates(response.data))
    getTextTemplates().then(response => setTextTemplates(response.data))
    getBatteries().then(response => setCycles(response.data))
  }, [])

  useEffect(() => {
    form.setFieldValue('applicants', applicants)
  }, [applicants])

  useEffect(() => {
    let show = false

    for (const message of form.values.messages) {
      if (message.type === 'email') {
        for (const template of templates) {
          if (message.template === template.id.toString()) {
            if (template.category?.tag === 'proctorfree-invitation' || template.category?.tag === 'proctorfree-reminder') {
              show = true
            }
          }
          if (show) {
            break
          }
        }
      } else {
        for (const template of textTemplates) {
          if (message.template === template.id.toString()) {
            if (template.category?.tag === 'proctorfree-invitation' || template.category?.tag === 'proctorfree-reminder') {
              show = true
            }
          }
          if (show) {
            break
          }
        }
      }
      if (show) {
        break
      }
    }

    setShow(show)
  }, [form.values.messages])

  const numEmails = _.size(_.filter(form.values.messages, { type: 'email' }))
  const numTexts = _.size(_.filter(form.values.messages, { type: 'text' }))

  const onSubmit = async values => {
    setLoading(true)

    const success = await contact(values)

    if (success) {
      window.location.replace(onSuccessRedirect)
    } else {
      showNotification({
        title: 'Something went wrong',
        message: 'There was an error contacting the applicants',
        color: 'red'
      })
      setLoading(false)
    }
  }

  return (
    <form onSubmit={form.onSubmit(values => onSubmit(values))}>
      <SimpleGrid cols={1}>
        <Messages form={form} templates={templates} textTemplates={textTemplates} previewApplicant={previewApplicant}/>
        {show && <Program form={form} getSubmissions={getSubmissions} applicants={applicants} cycles={cycles} cycleId={cycleId} />}
        <NoticeAlert>
          <SimpleGrid cols={1}>
            <div>{_.size(form.values.messages) > 0 && _.size(applicants) > 0 && <>You are about to send {numEmails > 0 && ' ' + numEmails + ' email' + (numEmails === 1 ? '' : 's')} {numTexts > 0 && (numEmails > 0 ? ' and ' : ' ') + numTexts + ' text message' + (numTexts === 1 ? '' : 's')} to {_.size(applicants)} applicant{_.size(applicants) === 1 ? '' : 's'}</>}</div>
            <div>Be sure to include your email address to your email if you need a reply from an applicant(s). A work email address is recommended.</div>
          </SimpleGrid>
        </NoticeAlert>
        <FormError form={form} name='applicants'/>
        <FormError form={form} name='messagesNotEmpty'/>
        <Group>
          <Button color='blue' leftSection={<IconMail size={18} />} type='submit' disabled={loading}>{loading ? 'Loading...' : 'Send'}</Button>
          <Button color='gray' onClick={() => window.history.back()}>Cancel</Button>
        </Group>
      </SimpleGrid>
    </form>
  )
}
