import React from 'react'
import { showNotification } from '@mantine/notifications'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetCycleEmailTemplatesQuery,
  useGetCycleIntegrationsQuery,
  useGetCycleQuery
} from '../../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../../core/ErrorLoading'
import Loading from '../../../core/Loading'
import { useCreateCycleTriggerMutation } from '../../../../redux/query/hire/triggersApi.slice'
import TriggerCreateForm from './TriggerCreateForm'
import LinkedBatteriesContext from '../../../../contexts/LinkedBatteriesContext'
import EmailTemplatesContext from '../../../../contexts/EmailTemplatesContext'

export default function CreateCycleTriggerApp () {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: cycle, error: cycleError, isLoading: cycleLoading } = useGetCycleQuery(id)
  const { data: integrations, error: integrationsError, isLoading: integrationsLoading } = useGetCycleIntegrationsQuery(id)
  const { data: emailTemplates, error: emailTemplatesError, isLoading: emailTemplatesLoading } = useGetCycleEmailTemplatesQuery(id)

  const [createTrigger] = useCreateCycleTriggerMutation()

  const onSubmit = data => {
    createTrigger({ id, ...data })
      .unwrap()
      .then(function () {
        showNotification({
          message: 'Successfully created a trigger',
          color: 'success'
        })
        navigate(`/hire/cycles/${cycle.id}/triggers`)
      }).catch((error) => {
        if (error.response) {
          showNotification({
            title: 'Something went wrong',
            message: error.response.data.errors.join(),
            color: 'red'
          })
        }
      })
  }

  if (cycleError || integrationsError || emailTemplatesError) {
    return <ErrorLoading />
  }

  if (cycleLoading || integrationsLoading || emailTemplatesLoading) {
    return <Loading />
  }

  return (
    <LinkedBatteriesContext.Provider value={cycle.cycle_batteries}>
      <EmailTemplatesContext.Provider value={emailTemplates.data}>
        <TriggerCreateForm
          onSubmit={onSubmit}
          statusSet={cycle.status_set}
          integrations={integrations}
        />
      </EmailTemplatesContext.Provider>
    </LinkedBatteriesContext.Provider>
  )
}
