/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import { renderDateFromSource } from '../formatUtil';
import { Box, Button, Card, Group, Space, Text, Title, Tooltip } from '@mantine/core';
import { LocationSummaryCard } from './LocationSummaryCard';
import { LockableColor } from './LockableIcon';

export const CycleSummaryCard = memo(function CycleSummaryCard ({ cycle, isAdmin = false }) {
  const onDetailsClick = () => {
    console.warn('On cycle summary card details click - must implement show or navigate to details', cycle) // TODO [cycle pass full features] remove disabled prop
  }

  const activeColor = (cycle.is_active || (cycle.active === 0)) ? LockableColor.Active : LockableColor.Locked
  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder h='100%'>
        <Card.Section>
          <Box bg={activeColor} py='1rem' px='0.5rem'>
            <Title ta='center' order={3} c='white'>Cycle</Title>
          </Box>
        </Card.Section>

        <Text ta='center' size='lg' my='sm'>
          {cycle.name || 'Cycle Name Missing'}{(cycle.id && isAdmin) ? ` #${cycle.id}` : ''}
        </Text>

        {!cycle.location && (
          <Text c='dimmed' ta='center' size='xxs'>
            Location Missing
          </Text>
        )}
        {!!cycle.location && <LocationSummaryCard location={cycle.location} isAdmin={isAdmin} />}

        <Space h='xs' />

        <Group justify="space-between" mt='auto' mb="xs">
          {!!cycle.created_at && <Text size="sm" c="dimmed">Created: {renderDateFromSource(cycle.created_at)}</Text>}
          {!!cycle.start_date && <Text size="sm" c="dimmed">Start: {renderDateFromSource(cycle.start_date)}</Text>}
          {!!cycle.end_date && <Text size="sm" c="dimmed">End: {renderDateFromSource(cycle.end_date)}</Text>}
        </Group>

        <Tooltip label='Feature Coming Soon'>
          <Button color="blue" fullWidth mt={0} radius="md" onClick={onDetailsClick} disabled={true}>
            View Details
          </Button>
        </Tooltip>
      </Card>
    </>
  )
})
