import React from 'react'
import UpdatePassword from './UpdatePassword'
import TwoFactorAuthentication from './TwoFactorAuthentication'
import PropTypes from 'prop-types'

export default function Security ({ account, apiBasePath }) {
  return (
    <>
      <UpdatePassword account={account} apiBasePath={apiBasePath}/>
      <TwoFactorAuthentication account={account} apiBasePath={apiBasePath}/>
    </>
  )
}

Security.propTypes = {
  account: PropTypes.object,
  apiBasePath: PropTypes.string
}
