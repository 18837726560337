import { apiSlice, ApiTags, ApiAbstractIds, ApiMethods, ApiResponseTypes } from '../../api';
import { paramsToQuery } from '../../util/queries';

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => `accounts${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Users, id: id })),
              { type: ApiTags.Users, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Users, id: ApiAbstractIds.PartialList }]
    }),
    exportUsers: builder.query({
      query: (body) => ({
        url: 'users/export',
        method: ApiMethods.Post,
        body: body,
        headers: { responseType: ApiResponseTypes.ArrayBuffer }
      }),
      providesTags: [{ type: ApiTags.Users, id: ApiAbstractIds.Export }]
    }),
    deleteUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: ApiMethods.Delete,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Users, id: id }]
    }),
    editUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: ApiMethods.Patch,
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Users, id: id }]
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: 'users',
        method: ApiMethods.Post,
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ApiTags.Users, id: id }]
    })
  })
})

export const { useGetUsersQuery, useDeleteUserMutation } = usersApi
