import React, { useEffect, useState } from 'react'
import { useOrganizations } from '../../util/OrganizationHooks'
import { useAssessment } from './AssessmentHooks'
import { useForm } from '@mantine/form'
import { Button, Checkbox, Grid, Group, Radio, Select, SimpleGrid } from '@mantine/core'
import Content from '../../layout/Content'
import Information from './Information'
import { publish } from '../../../js/api/assessment_repository'
import { showNotification } from '@mantine/notifications'
import { QuestionType, isBetaOnly } from '../../../js/generated/enums/QuestionType';

export default function Publish () {
  return <Content><WrappedPublish /></Content>
}

function WrappedPublish () {
  const [assessment] = useAssessment(document.getElementById('publish-assessment-container').getAttribute('data-id'))
  const organizations = useOrganizations()
  const [restrict, setRestrict] = useState('0')
  const [assessmentHasLogic, setAssessmentHasLogic] = useState(false)
  const [assessmentHasBetaOnlyTypes, setAssessmentHasBetaOnlyTypes] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      organization: null,
      beta_assessment: false,
      hide_question_numbers: false
    }
  })

  useEffect(() => {
    if (assessment?.questions?.length) {
      let anyLogicFound = false
      let anyBetaOnlyFound = false
      for (const question of assessment.questions) {
        if (isBetaOnly(question.type) || (question.type === QuestionType.MultipleChoice && question.is_dropdown)) {
          anyBetaOnlyFound = true
          if (anyLogicFound) {
            break
          }
        }
        if (question?.logic?.length) {
          anyLogicFound = true
          if (anyBetaOnlyFound) {
            break
          }
        }
      }
      setAssessmentHasLogic(anyLogicFound)
      setAssessmentHasBetaOnlyTypes(anyBetaOnlyFound)
    }
  }, [assessment])

  useEffect(() => {
    if ((assessmentHasBetaOnlyTypes || assessmentHasLogic) && !form.values.beta_assessment) {
      form.setFieldValue('beta_assessment', true)
    }
    if (assessmentHasLogic && !form.values.hide_question_numbers) {
      form.setFieldValue('hide_question_numbers', true)
    }
  }, [assessmentHasLogic, assessmentHasBetaOnlyTypes, form])

  const updateRestrict = restrict => {
    setRestrict(restrict)

    if (restrict) {
      form.setFieldValue('organization', null)
    }
  }

  const submit = values => {
    setSubmitting(true)
    publish(assessment.id, values)
      .then(() => {
        window.location.href = 'build/assessments'
      })
      .catch(err => {
        setSubmitting(false)
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error publishing this assessment',
            color: 'red'
          })
        }
      })
  }

  const back = () => window.history.back()

  return (
      <form onSubmit={form.onSubmit(values => submit(values))}>
        <Grid align='flex-start'>
          <Grid.Col span={5}>
            <SimpleGrid cols={1}>
              <Checkbox label='Publish With Beta Assessment Style (required for beta features)' checked={form.values.beta_assessment} { ...form.getInputProps('beta_assessment') } disabled={assessmentHasLogic || assessmentHasBetaOnlyTypes} />
              {form.values.beta_assessment && <Checkbox label='Hide Question Numbers (required for question logic)' checked={form.values.hide_question_numbers} { ...form.getInputProps('hide_question_numbers') } disabled={assessmentHasLogic} />}
              <Radio.Group
                value={restrict}
                onChange={updateRestrict}
                label='Would you like to limit the use of this assessment to a specific client?'
                withAsterisk
              >
                <SimpleGrid cols={1} verticalSpacing='xs'>
                  <Radio value='0' label='No'/>
                  <Radio value='1' label='Yes'/>
                </SimpleGrid>
              </Radio.Group>
              {
                restrict === '1' &&
                <Select
                  label='Organization'
                  placeholder='Pick One'
                  searchable
                  clearable
                  data={organizations.map(organization => ({ value: organization.id.toString(), label: organization.name })) ?? []}
                  {...form.getInputProps('organization')}
                  withAsterisk
                  required={true}
                />
              }
              <Group spacing='xs'>
                <Button color='success' type='submit' loading={!assessment || submitting}>Publish</Button>
                <Button color='gray.6' onClick={back}>Cancel</Button>
              </Group>
            </SimpleGrid>
          </Grid.Col>
          <Grid.Col span={7}>
            {assessment && <Information assessment={assessment}/>}
          </Grid.Col>
        </Grid>
      </form>
  )
}
