/* eslint react/prop-types: 0 */
import { Select } from '@mantine/core'
import React from 'react'

export default function StatusSelect ({ form, index }) {
  return (
    <Select
      label={`${form.values.deleted_statuses[index].name} applicants will become...`}
      placeholder='Pick One'
      maxDropdownHeight={280}
      data={form.values.statuses.filter(s => s.name !== '').map(status => { return { value: status.name, label: status.name } })}
      {...form.getInputProps(`deleted_statuses.${index}.replacement`)}
      withAsterisk
    />
  )
}
