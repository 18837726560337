/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Button, ActionIcon, Popover, Input } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { MinMaxDateFiltersContext } from './FilterContexts';
import { IconArrowsMoveHorizontal, IconMathLower, IconCalendarOff } from '@tabler/icons-react'

/**
 * @param {InputFilter} filter
 * @param {string} selected
 * @param {function} updateFilter
 * @param {object} allSelected
 */
export function FilterDateRangeInput ({ filter, selected, updateFilter }) {
  const [parsedMin, parsedMax] = splitSelectedDateRange(selected)
  const [joinedValue, setJoinedValue] = useState(selected || null)
  const lastDebouncedRef = useRef(joinedValue)
  const updateFilterRef = useRef(updateFilter)
  const parsedMinRef = useRef(parsedMin)
  const parsedMaxRef = useRef(parsedMax)
  const validateUpdate = filter.validateUpdate ?? null
  const filterId = filter.id

  useEffect(() => {
    updateFilterRef.current = updateFilter
  }, [updateFilter])

  useEffect(() => {
    parsedMinRef.current = parsedMin
  }, [parsedMin])

  useEffect(() => {
    parsedMaxRef.current = parsedMax
  }, [parsedMax])

  const updateBothFilters = useCallback((newValue, isMin) => {
    console.debug('Filter date range input updating filters', { newValue, isMin })
    const newMin = isMin ? newValue : parsedMinRef.current
    const newMax = !isMin ? newValue : parsedMaxRef.current
    if (newMin && newMax) {
      setJoinedValue(`${newMin}${fullRangeSeparator}${newMax}`)
    } else if (newMin) {
      setJoinedValue(`${minOnlyLineStart}${newMin}`)
    } else if (newMax) {
      setJoinedValue(`${maxOnlyLineStart}${newMax}`)
    } else {
      setJoinedValue(null)
    }
  }, [])

  useEffect(() => {
    console.debug('joinedValue or selected updated', { joinedValue, selected, filterId })
    if ((joinedValue || selected) && (joinedValue !== selected)) {
      if (lastDebouncedRef.current === (selected || null)) {
        if (!validateUpdate || validateUpdate(joinedValue)) {
          console.debug(
            'Updating parent with current joinedValue',
            { selected, joinedValue }
          )
          const newValue = joinedValue || null
          lastDebouncedRef.current = newValue
          updateFilterRef.current(filterId, newValue)
        } else {
          console.debug('Skipping parent update for invalid date joinedValue', { selected, joinedValue })
        }
      } else {
        console.debug(
          'Overwriting current joinedValue with value from parent',
          { selected, joinedValue }
        )
        const newValue = selected || null
        lastDebouncedRef.current = newValue
        setJoinedValue(newValue)
      }
    }
  }, [joinedValue, selected, validateUpdate, filterId])

  console.debug('Filter date input updating', { selected, parsedMin, parsedMax })

  return (
    <FilterDateRangeContextProvider filter={filter} parsedMin={parsedMin} parsedMax={parsedMax} updateRange={updateBothFilters}>
      <Input.Wrapper label={filter.label} description={filter.description}>
        <Group justify='center'>
          <FilterDateRangeSingleInput
            min={true}
          />
          <FilterDateRangeCenterIcon
            setJoinedValue={setJoinedValue}
          />
          <FilterDateRangeSingleInput
            min={false}
          />
        </Group>
      </Input.Wrapper>
    </FilterDateRangeContextProvider>
  )
}

const fullRangeSeparator = ' <=< '
const minOnlyLineStart = ' >= '
const maxOnlyLineStart = ' <= '

/**
 * @param {?string} selected
 */
function splitSelectedDateRange (selected) {
  if (!selected) {
    return [null, null]
  }
  if (selected.includes(fullRangeSeparator)) {
    return selected.split(fullRangeSeparator, 2)
  }
  if (selected.startsWith(minOnlyLineStart)) {
    return [selected.split(minOnlyLineStart)[1], null]
  }
  if (selected.startsWith(maxOnlyLineStart)) {
    return [null, selected.split(maxOnlyLineStart)[1]]
  }
  return [null, null]
}

function FilterDateRangeContextProvider ({ filter, parsedMin, parsedMax, updateRange, children }) {
  const lastParsedMinRef = useRef(null)
  const lastParsedMaxRef = useRef(null)

  const lastFormattedMinRef = useRef(null)
  const lastFormattedMaxRef = useRef(null)

  const filtersContext = useMemo(() => {
    const formattedMin = parsedMin === lastParsedMinRef.current ? lastFormattedMinRef.current : formatDateFromSource(parsedMin)
    const formattedMax = parsedMax === lastParsedMaxRef.current ? lastFormattedMaxRef.current : formatDateFromSource(parsedMax)

    lastParsedMinRef.current = parsedMin
    lastParsedMaxRef.current = parsedMax
    lastFormattedMinRef.current = formattedMin
    lastFormattedMaxRef.current = formattedMax

    console.debug('Filter date context provider recalculated context', { filter, parsedMin, parsedMax, formattedMin, formattedMax })

    return {
      filter,
      parsedMin,
      parsedMax,
      formattedMin,
      formattedMax,
      updateRange
    }
  }, [filter, parsedMin, parsedMax, updateRange])

  console.debug('Filter date context provider updating', { filtersContext })

  return (
    <MinMaxDateFiltersContext.Provider value={filtersContext}>
      {children}
    </MinMaxDateFiltersContext.Provider>
  )
}

const FilterDateRangeCenterIcon = memo(function FilterDateRangeCenterIcon ({ setJoinedValue }) {
  const { parsedMin, parsedMax } = useContext(MinMaxDateFiltersContext)
  const hasBothMinMax = !!(parsedMin && parsedMax)
  const hasNeitherMinMax = !(parsedMin || parsedMax)

  const onClick = useCallback((event) => {
    console.debug('Clicked filter date range center icon', { event })
    event.preventDefault()
    setJoinedValue('')
  }, [setJoinedValue])

  return (
    <>
      {!!hasBothMinMax && (<ActionIcon variant='light' onClick={onClick}><IconArrowsMoveHorizontal /></ActionIcon>) }
      {!hasBothMinMax && !hasNeitherMinMax && (<ActionIcon variant='light' onClick={onClick}><IconMathLower /></ActionIcon>) }
      {!!hasNeitherMinMax && (<ActionIcon variant='light' disabled={true} onClick={onClick}><IconCalendarOff /></ActionIcon>) }
    </>
  )
})

const emptyLimit = {}
const defaultDate = new Date()

const FilterDateRangeSingleInput = memo(function FilterDateRangeSingleInput ({ min = true }) {
  const [opened, setOpened] = useState(false)
  const { filter, parsedMin, parsedMax, formattedMin, formattedMax, updateRange } = useContext(MinMaxDateFiltersContext)
  const date = min ? formattedMin : formattedMax
  const otherDate = min ? formattedMax : formattedMin
  const fallbackDate = date ?? (formattedMin ?? formattedMax) ?? defaultDate
  const [activeDate, setActiveDate] = useState(null)

  const disabled = filter?.disabled ?? false

  console.debug('Filter date start/stop updating', { date, min, parsedMin, parsedMax, activeDate, fallbackDate })
  const fallbackText = min ? 'Select Start' : 'Select Stop'

  useEffect(() => {
    setActiveDate(null)
  }, [otherDate])

  const updateDate = useCallback((value) => {
    console.debug('Updating scrolled date value', { value })
    setActiveDate(value)
  }, [])

  const updateDateOnChange = useCallback((value) => {
    console.debug('Picked single date value', { value, date, min })
    const parsedValue = renderDateFromSource(value)
    const parsedLastDate = renderDateFromSource(date)
    if (parsedValue === parsedLastDate) {
      updateRange(null, min)
    } else {
      updateRange(parsedValue, min)
    }
    setOpened(false)
  }, [min, date, updateRange])

  const rangeProps = min ? (formattedMax ? { maxDate: formattedMax } : emptyLimit) : (formattedMin ? { minDate: formattedMin } : emptyLimit)
  const buttonVariant = date ? 'subtle' : 'outline'

  return (
    <Popover opened={opened} onChange={setOpened} position='right-end' offset={10} withArrow withinPortal={false}>
      <Popover.Target>
        <Button
          variant={buttonVariant}
          onClick={() => setOpened((o) => !o)}
          disabled={disabled}
        >
          {date ? renderDateFromSource(date) : fallbackText}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <DatePicker
          date={activeDate ?? fallbackDate}
          onDateChange={updateDate}
          value={date}
          onChange={updateDateOnChange}
          defaultLevel={date ? 'month' : 'year'}
          allowDeselect
          { ...rangeProps }
        />
      </Popover.Dropdown>
    </Popover>
  )
})

function formatDateFromSource (nullableDate) {
  return nullableDate ? new Date(dayjs(nullableDate).local().format()) : null
}

function renderDateFromSource (nullableDate, format = null, defaultIncludeTime = false) {
  return nullableDate ? dayjs(nullableDate).local().format(format ?? `MM-DD-YYYY${defaultIncludeTime ? ' hh:mm:ss A' : ''}`) : ''
}
