import { ApiAbstractIds, apiSlice, ApiTags } from '../../../api';
import { paramsToQuery } from '../../../util/queries';
import { formatResponseData } from '../../../../js/util/DataUtil';

export const assessmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: (params) => `assessments${paramsToQuery(params)}`,
      transformResponse: (response) => formatResponseData(response),
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.Assessments, id: id })),
              { type: ApiTags.Assessments, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.Assessments, id: ApiAbstractIds.PartialList }]
    }),
    getAssessmentTypes: builder.query({
      query: () => 'assessments/subtypes',
      providesTags: (result) =>
        result
          ? [
              ...result.hiring.map(({ id }) => ({ type: ApiTags.HiringAssessmentTypes, id: id })),
              ...result.skillbuilder.map(({ id }) => ({ type: ApiTags.SkillbuilderAssessmentTypes, id: id })),
              { type: ApiTags.AssessmentTypes, id: ApiAbstractIds.List },
              { type: ApiTags.HiringAssessmentTypes, id: ApiAbstractIds.List },
              { type: ApiTags.SkillbuilderAssessmentTypes, id: ApiAbstractIds.List }
            ]
          : [
              { type: ApiTags.SkillbuilderAssessmentTypes, id: ApiAbstractIds.List },
              { type: ApiTags.HiringAssessmentTypes, id: ApiAbstractIds.List },
              { type: ApiTags.AssessmentTypes, id: ApiAbstractIds.List }
            ]
    })
  })
})

export const { useGetAssessmentTypesQuery, useGetAssessmentsQuery } = assessmentsApi
