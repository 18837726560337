/* eslint react/prop-types: 0 */
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useBoostQuery, useBoostTable, useLazyBoostQuery } from './BoostHooks';
import ReactTable, { ReactTableContextsProvider } from '../../../core/ReactTable/ReactTable';
import { useDisclosure } from '@mantine/hooks';
import BoostBulkInviteModal from './BoostBulkInviteModal';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { showNotification } from '@mantine/notifications';
import { createFormActions } from '@mantine/form';
import { NamespaceContext, TableDataContext } from '../../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../../core/ReactTable/paramsSlice';
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';

export default function BoostTable ({ job, namespace = 'boostInvites' }) {
  const { cycleId } = useParams()
  const { data: account } = useGetLoggedInAccountQuery()

  /** @member {BoostCollection} collection */
  const [collection,, error] = useLazyBoostQuery(namespace, cycleId)
  const [opened, { open, close }] = useDisclosure(false)
  const formFiltersAction = createFormActions('react-table-form-filters')
  const formFiltersActionRef = useRef(formFiltersAction)

  useEffect(() => {
    if (collection?.reformattedAddress) {
      formFiltersActionRef.current.setFieldValue('address', collection.reformattedAddress)

      showNotification({
        title: 'Address Formatting',
        message: `The address was reformatted to: ${collection.reformattedAddress}`,
        color: 'blue',
        autoClose: 3000
      })
    }
  }, [collection])

  useEffect(() => {
    if (error !== null) {
      switch (error.status) {
        case 400:
          showNotification({
            title: 'Address Lookup Error',
            message: error.data.errors.join(),
            color: 'red',
            autoClose: 7000
          })
          break
        default:
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error searching for applicants',
            color: 'red',
            autoClose: 7000
          })
      }
    }
  }, [error])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherParams } = useBoostTable(account, job, open)

  return (
    <>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <BoostTableDataProvider id={cycleId}>
          <ReactTable { ...otherParams } />
        </BoostTableDataProvider>
      </ReactTableContextsProvider>
      <BoostBulkInviteModal
        cycle={cycleId}
        opened={opened}
        close={close}
      />
    </>
  )
}

BoostTable.propTypes = {
  namespace: PropTypes.string
}

/**
 * @typedef {object} BoostCollection
 * @property {array} items
 * @property {number} limit
 * @property {number} page
 * @property {number} total
 * @property {string} reformattedAddress
 */

export function BoostTableDataProvider ({ id, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useBoostQuery(namespace, id, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating BoostTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Boost table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
