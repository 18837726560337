import React, { useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { groupStatuses } from '../../../../../util/hire/status.util'
import PropTypes from 'prop-types'
import { MultiSelect, Stack } from '@mantine/core'
import { IconUserHeart } from '@tabler/icons-react'
import Error from '../../../../forms/Error'

const defaultStatuses = []

export default function StatusCondition ({ statusSet }) {
  const { control } = useFormContext()

  const { field: { onChange, onBlur, name, value } } = useController({
    name: 'metadata.applicant_status',
    control: control,
    rules: { required: 'One or more statuses must be selected.' }
  })

  const groupedStatuses = useMemo(() => (
    groupStatuses(statusSet?.statuses ?? defaultStatuses)
  ), [statusSet])

  const data = useMemo(() => (
    [
      {
        group: 'All',
        items: [{ value: 'all', label: 'All' }]
      },
      ...groupedStatuses
    ]
  ), [groupedStatuses])

  return (
    <Stack>
      <MultiSelect
        placeholder='Pick statuses'
        leftSection={<IconUserHeart />}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        data={data}
        searchable
        clearable
        checkIconPosition='right'
        nothingFoundMessage='Nothing found...'
      />
      <Error name={'metadata.applicant_status'} />
    </Stack>
  )
}

StatusCondition.propTypes = {
  statusSet: PropTypes.object
}
