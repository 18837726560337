export const MoveDirection = {
  bottom: 'bottom',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
  left: 'left',
  none: 'none',
  right: 'right',
  top: 'top',
  topLeft: 'top-left',
  topRight: 'top-right',
  outside: 'outside',
  inside: 'inside'
}

export const OutMode = {
  bounce: 'bounce',
  none: 'none',
  out: 'out',
  destroy: 'destroy',
  split: 'split'
}
