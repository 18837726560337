import { Center, Loader } from '@mantine/core'
import { useShallowEffect } from '@mantine/hooks'
import _ from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAccount } from '../../../js/api/account_repository'
import AdminDetails from './AdminDetails'
import ClientDetails from './ClientDetails'

export default function EditUser () {
  const { id } = useParams()
  const [account, setAccount] = useState(null)

  useShallowEffect(() => {
    if (id !== undefined) {
      getAccount(id).then(account => setAccount(account))
    }
  }, [id])

  return (
    <>
      {account === null && <Center><Loader/></Center>}
      {(_.includes(_.map(account?.roles ?? [], role => role.name), 'ROLE_PORTAL_CLIENT') || _.includes(_.map(account?.roles ?? [], role => role.name), 'ROLE_PORTAL_USER')) && <ClientDetails account={account} />}
      {(_.includes(_.map(account?.roles ?? [], role => role.name), 'ROLE_ADMIN') || _.includes(_.map(account?.roles ?? [], role => role.name), 'ROLE_DEVELOPER')) && <AdminDetails account={account} />}
    </>
  )
}
