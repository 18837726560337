import { MoveDirection, OutMode } from './util';

export const ConfigDefaultLinks = { // 10
  // background: {
  //   color: {
  //     value: '#323840',
  //   }
  // },
  fpsLimit: 60,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: 'push'
      },
      onHover: {
        enable: true,
        mode: 'repulse'
      }
    },
    modes: {
      push: {
        quantity: 4
      },
      repulse: {
        distance: 400,
        duration: 0.4
      },
      grab: {
        distance: 400,
        links: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3
      },
      remove: {
        quantity: 2
      }
    }
  },
  particles: {
    color: {
      value: ['#33b1f8'] // ["#aa73ff", "#f8c210", "#83d238", "#33b1f8"],
    },
    links: {
      color: '#33b1f8',
      distance: 110,
      enable: true,
      opacity: 0.25,
      width: 1
    },
    move: {
      direction: MoveDirection.none,
      enable: true,
      outModes: {
        default: OutMode.out
      },
      random: false,
      speed: 1.6,
      straight: false,
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 12000
      }
    },
    number: {
      density: {
        enable: true,
        value_area: 700
      },
      value: 88
    },
    opacity: {
      value: 0.5
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#000000'
      },
      polygon: {
        nb_sides: 15
      }
    },
    size: {
      value: 2.5,
      random: false,
      anim: {
        enable: true,
        speed: 2,
        size_min: 0.15,
        sync: false
      }
    }
  },
  detectRetina: true
}
