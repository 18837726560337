import { Title, Center, Space } from '@mantine/core';
import React from 'react';

export function CyclePassThankYou () {
  return (
    <>
      <Space h='xl' />
      <Center>
        <Title>
          Thank you for your time! Be sure to check your email regularly for additional information.
        </Title>
      </Center>
    </>
  )
}
