import React from 'react'
import BulletChartEditor from '../charts/BulletChartEditor'
import { getZoneString, parseZoneString } from '../../js/charts/BulletChart'
import { updateCycleCompetencyZones, updateZones } from '../../js/api/competency_repository'
import { showNotification } from '@mantine/notifications'
import Content from '../layout/Content'

export default function CompetencyEditApp () {
  const id = document.getElementById('competency-edit-app-container').dataset.id
  const zones = parseZoneString(document.getElementById('competency-edit-app-container').dataset.zones)
  const cycleId = document.getElementById('competency-edit-app-container').dataset.cycleid

  const submit = (response) => {
    const zoneString = getZoneString(response)
    const data = { zones: zoneString }
    if (cycleId) {
      updateCycleCompetencyZones(id, cycleId, data)
        .then(() => {
          if ('referrer' in document) {
            window.location = document.referrer
          } else {
            window.history.back()
          }
        })
        .catch(() => {
          showNotification({
            message: 'Error saving competency',
            color: 'red'
          })
        })
    } else {
      updateZones(id, data)
        .then(() => {
          if ('referrer' in document) {
            window.location = document.referrer
          } else {
            window.history.back()
          }
        })
        .catch(() => {
          showNotification({
            message: 'Error saving competency',
            color: 'red'
          })
        })
    }
  }

  return <Content><BulletChartEditor editing={{ id, zones }} onSubmit={submit} /></Content>
}
