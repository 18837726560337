import React from 'react'
import Error from '../../../../forms/Error'
import { useController, useFormContext } from 'react-hook-form'
import { Checkbox, Fieldset, HoverCard, List, Select, Stack, Text, TextInput, Title } from '@mantine/core'
import { IconHelpCircle } from '@tabler/icons-react'

const options = [
  { value: '1', label: 'Unanimous' },
  { value: '2', label: 'Affirmative' }
]

export default function GeneralSettings () {
  const { register, control } = useFormContext()

  const {
    field: { onChange, onBlur, name, value }
  } = useController({
    name: 'strategy',
    control: control,
    rules: { required: 'Strategy is required' }
  })

  return (
    <Fieldset legend='Settings'>
      <Stack>
        <TextInput
          label='Name'
          placeholder='Name'
          {...register('name', { required: 'The name is required' })}
        />
        <Error name='name' />
        <Select
          label='Strategy'
          data={options}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value?.toString()}
          clearable={false}
        />
        <Error name='strategy' />
        <Checkbox
          label='Run Once'
          {...register('onetime')}
        />
        <Error name='onetime' />
        <GeneralSettingsHelp/>
      </Stack>
    </Fieldset>
  )
}

function GeneralSettingsHelp () {
  return (
    <HoverCard width={400} shadow='md' openDelay={200} closeDelay={200} position='right-start'>
      <HoverCard.Target>
        <IconHelpCircle color='#00abfb' />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack gap='xs'>
          <Title order={6}>Strategy</Title>
          <List withPadding>
            <List.Item><Text size='sm'><b>Unanimous:</b> All conditions have to be met in order for the consequents to happen.</Text></List.Item>
            <List.Item><Text size='sm'><b>Affirmative:</b> At least one condition has to be met in order for the consequents to happen.</Text></List.Item>
          </List>
          <Title order={6}>Run once</Title>
          <Text size='sm'>When checked, the trigger will only run once for each applicant.</Text>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
