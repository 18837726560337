/* eslint react/prop-types: 0 */
import React, { useContext, useMemo } from 'react';
import { NamespaceContext, TableDataContext } from '../core/ReactTable/TableContexts';
import { useSemanticQuery, useSemanticTable } from './SemanticSearchHooks';
import { ReactTable, ReactTableContextsProvider } from '../core/ReactTable/ReactTable';
import SemanticSearchTableForm from './SemanticSearchTableForm';
import { useGetLoggedInAccountQuery } from '../../redux/query/account/accountsApi.slice';

const namespace = 'semantic'

export default function SemanticSearchTable () {
  const { data: account } = useGetLoggedInAccountQuery()
  const tableProps = useSemanticTable(account)

  return (
      <ReactTableContextsProvider
        columns={tableProps.columns}
        namespace={namespace}
      >
        <SemanticSearchTableForm namespace={namespace}/>
        <SemanticTableDataProvider>
          <ReactTable
            notDynamic={true}
            {...tableProps}
          />
        </SemanticTableDataProvider>

      </ReactTableContextsProvider>
  )
}

export function SemanticTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const [collection, querying] = useSemanticQuery(namespace)

  const currentTableDataContext = useMemo(() => {
    console.info('Updating SemanticTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])

  console.debug('Semantic table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
