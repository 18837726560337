import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import PhasesDropdown from './PhasesDropdown'
import { NumberInput, Stack } from '@mantine/core'
import Error from '../../../../forms/Error'

export default function BaselineCondition () {
  const { control } = useFormContext()

  const {
    field: { onChange, onBlur, name, value }
  } = useController({
    name: 'metadata.baseline',
    control: control,
    rules: {
      required: 'The Baseline score limit is required.',
      pattern: {
        value: /^\d+$/,
        message: 'The Baseline score limit must be a number.'
      },
      max: {
        value: 99,
        message: 'The Baseline score limit cannot be over 99.'
      },
      min: {
        value: 1,
        message: 'The Baseline score limit cannot be less than 1.'
      }
    }
  })

  return (
    <>
      <Stack>
        <NumberInput
          w={120}
          placeholder='Baseline'
          min={1}
          max={99}
          allowDecimal={false}
          allowNegative={false}
          clampBehavior='strict'
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
        />
        <Error name='metadata.baseline' />
      </Stack>
      <PhasesDropdown name='metadata.phase' />
    </>
  )
}
