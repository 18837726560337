/* eslint react/prop-types: 0 */
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Space,
  Group,
  Paper, Modal
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStageResponseDistributionSlice } from './CycleStagesHooks';
import { selectParamsLoaded } from '../../../../core/ReactTable/paramsSlice';
import {
  ReactTableFiltersContextProvider,
  ReduxTableControlContextProvider
} from '../../../../core/ReactTable/ReactTable';
import { NamespaceContext, TableControlContext } from '../../../../core/ReactTable/TableContexts';
import {
  reportPageReceived,
  reportVersionChanged,
  selectAllPagesLoaded,
  selectNonFirstPages
} from './distributionReportPagesSlice';
import FilterControl from '../../../../core/ReactTable/Toolbar/FilterControl';
import { IconActivity, IconLock, IconMailbox } from '@tabler/icons-react';
import { StageProgressStatus } from '../../../../../js/generated/enums/StageProgressStatus';
import { selectDistributionProgressIds } from './distributionProgressesSlice';
import { useDisclosure } from '@mantine/hooks';
import { StageProgressesFromIdsTable } from '../progresses/StageProgressesFromIdsTable';

const distributionReporterStyles = { position: 'sticky', top: '1rem', boxShadow: 'none', zIndex: 150 }
const namespacePrefix = 'csdrep'

export const DistributionReporter = memo(function DistributionReporter ({ assessmentId }) {
  const { cycleId, stageId } = useParams()
  console.debug('Stage DistributionReporter updating', { cycleId, stageId, assessmentId })

  const namespace = `${namespacePrefix}${stageId}`
  const defaultFilters = useMemo(() => {
    return { cycles: [cycleId], stage: [stageId], limit: 100, page: 1 }
  }, [cycleId, stageId])

  return (
    <DistributionReportFiltersProvider namespace={namespace} defaultFilters={defaultFilters}>
      <Paper shadow='none' withBorder={true} style={distributionReporterStyles}>
        <Group justify='center' my='md' grow>
          <Space h='md' />
        </Group>
        <DistributionFilters stageId={stageId} cycleId={cycleId} />
        <DistributionReportLoader stageId={stageId} cycleId={cycleId} />
        <MatchingProgressIdsTable />
      </Paper>
    </DistributionReportFiltersProvider>
  )
})

function DistributionReportFiltersProvider ({ namespace, defaultFilters = {}, children }) {
  const [initialDefaultFilters] = useState(defaultFilters ?? {})
  return (
    <NamespaceContext.Provider value={namespace}>
      <ReactTableFiltersContextProvider defaultFilters={initialDefaultFilters}>
        <ReduxTableControlContextProvider>
          {children}
        </ReduxTableControlContextProvider>
      </ReactTableFiltersContextProvider>
    </NamespaceContext.Provider>
  )
}

const EMAIL_FILTER_INPUT = {
  id: 'email',
  label: 'Email',
  leftSection: <IconMailbox />,
  input: {
    type: 'text'
  },
  placeholder: 'Enter email',
  useValue: true
}

const CLEAR_OPTION_VALUE = '__SHOW_ALL__'

const STATUS_FILTER_NO_OPTIONS = {
  id: 'statuses',
  label: 'Status',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: CLEAR_OPTION_VALUE,
  multiSelect: true
}

const STATUS_FILTER = {
  ...STATUS_FILTER_NO_OPTIONS,
  options: [
    { label: 'All', value: CLEAR_OPTION_VALUE },
    ...Object.entries(StageProgressStatus).map(([key, value]) => ({ label: key.split(/(?=[A-Z])/).join(' '), value: value.toString() }))
  ]
}

const PHASE_FILTER = {
  id: 'phase',
  label: 'Phase',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: CLEAR_OPTION_VALUE,
  options: [{ label: 'All', value: CLEAR_OPTION_VALUE }, ...Array.from(Array(10).keys()).map(phase => phase + 1).map(phase => ({ label: `Phase ${phase}`, value: phase.toString() }))]
}

const TRUE_FALSE_FILTER_OPTIONS = Object.freeze([
  Object.freeze({ label: 'True', value: '1' }),
  Object.freeze({ label: 'False', value: '0' })
])

const TOGGLE_ACTIVE_FILTERS = [
  {
    id: 'locked',
    label: 'Locked',
    leftSection: <IconLock />,
    options: TRUE_FALSE_FILTER_OPTIONS
  },
  {
    id: 'passLocked',
    label: 'Passport Locked',
    leftSection: <IconLock />,
    options: TRUE_FALSE_FILTER_OPTIONS
  }
]

const STAGE_FILTER_NO_OPTIONS = {
  id: 'stage',
  label: 'Stage',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: CLEAR_OPTION_VALUE,
  multiSelect: true
}

const PROGRESS_FILTER_NO_OPTIONS = {
  id: 'ids',
  label: 'Progress',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: CLEAR_OPTION_VALUE,
  multiSelect: true
}

const CYCLE_FILTER_NO_OPTIONS = {
  id: 'cycles',
  label: 'Cycles',
  leftSection: <IconActivity />,
  placeholder: 'Current: This cycle',
  clearOptionValue: CLEAR_OPTION_VALUE,
  multiSelect: true
}

const DistributionFilters = memo(function DistributionFilters ({ stageId, cycleId }) {
  // const namespace = useContext(NamespaceContext)  // TODO [distribution report filter options long term] query for potential cycles, stages. Resolve allowing only progresses included in currently selected cycles/stages to be selected without preventing multiselect.
  const progressIds = useSelector(state => selectDistributionProgressIds(state))
  const filters = useMemo(() => {
    console.debug('Updating filters', { progressIds })
    return [
      { ...CYCLE_FILTER_NO_OPTIONS, options: [{ label: 'Current', value: CLEAR_OPTION_VALUE }, { label: `#${cycleId}`, value: cycleId.toString() }] },
      ...TOGGLE_ACTIVE_FILTERS,
      STATUS_FILTER,
      PHASE_FILTER,
      EMAIL_FILTER_INPUT,
      { ...STAGE_FILTER_NO_OPTIONS, options: [{ label: 'All', value: CLEAR_OPTION_VALUE }, { label: `#${stageId}`, value: stageId.toString() }] },
      { ...PROGRESS_FILTER_NO_OPTIONS, options: [{ label: 'All', value: CLEAR_OPTION_VALUE }, ...progressIds.map(progressId => ({ label: `#${progressId}`, value: progressId.toString() }))] }
    ]
  }, [progressIds, stageId, cycleId])

  return (
    <DistributionFiltersControl filters={filters} />
  )
})

const DistributionFiltersControl = memo(function DistributionFiltersControl ({ filters }) {
  const { batchUpdateSearchParams, activeFilters } = useContext(TableControlContext)
  const loaded = useSelector(state => selectAllPagesLoaded(state))
  console.debug('DistributionFiltersControl updating', { loaded, filters, activeFilters })
  return (
    <FilterControl filters={filters} selected={activeFilters} onChange={batchUpdateSearchParams} querying={!loaded} />
  )
})

const DistributionReportLoader = memo(function DistributionReportLoader ({ stageId, cycleId, page = 1 }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [data, querying, loading] = useStageResponseDistributionSlice(namespace, stageId, cycleId, !loaded, page)
  const activeVersion = useSelector(state => state.distributionReportPages.version)
  const subPageIds = useSelector(state => selectNonFirstPages(state))

  useEffect(() => {
    console.debug('Distribution data change triggered report loader effect', { data })
    if (data) {
      dispatch(reportVersionChanged({ ...data, namespace }))
    }
  }, [data, namespace, dispatch])

  console.debug('DistributionReportLoader updating', { namespace, stageId, cycleId, page, loaded, data, querying, loading, subPageIds })
  return (
    <>
      {subPageIds.map(pageId => <SubPageLoader key={`${activeVersion}-${pageId}`} stageId={stageId} cycleId={cycleId} page={pageId} />)}
    </>
  )
})

const SubPageLoader = memo(function SubPageLoader ({ stageId, cycleId, page }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const activeVersion = useSelector(state => state.distributionReportPages.version)
  const [version] = useState(activeVersion)
  const [data, querying, loading] = useStageResponseDistributionSlice(namespace, stageId, cycleId, !loaded || (activeVersion !== version), page)

  useEffect(() => {
    console.debug('Distribution data change triggered report SubPageLoader effect', { data })
    if (data) {
      dispatch(reportPageReceived({ ...data, namespace, version }))
    }
  }, [data, namespace, version, dispatch])

  console.debug('SubPageLoader updating', { page, data, querying, loading, version, activeVersion })
  return (
    <>
    </>
  )
})

const progressModalTransitionProps = { transition: 'fade', duration: 200 }

const MatchingProgressIdsTable = memo(function MatchingProgressIdsTable () {
  const [opened, { close, open }] = useDisclosure(false)
  const progressIds = useSelector(state => state.distributionReportPages.tableProgressIds)

  useEffect(() => {
    console.debug('MatchingProgressIdsTable triggering check open on change effect', { progressIds })
    if (progressIds) {
      open()
    }
  }, [progressIds, open])

  console.debug('MatchingProgressIdsTable updating', { progressIds })
  return (
    <>
      {!!progressIds && (
        <Modal
          opened={opened}
          onClose={close}
          fullScreen
          radius={0}
          transitionProps={progressModalTransitionProps}
          title='Matching respondents'
        >
          <StageProgressesFromIdsTable progressIds={progressIds} />
        </Modal>
      )}
    </>
  )
})
