/* eslint react/prop-types: 0 */
import React, { useContext, useMemo, useState } from 'react'
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable'
import { Anchor, Button, Group, Modal, Text } from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData, selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import {
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery
} from '../../../redux/query/build/emailTemplatesApi.slice'

const namespace = 'emailTemplates'

const defaultFilters = {}
const defaultHiddenColumns = []
const filters = []

export default function EmailTemplateHomeTable () {
  const [deleteTemplate] = useDeleteEmailTemplateMutation()
  const [templateToDelete, setTemplateToDelete] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: <Text>Title</Text>,
        id: 'title',
        accessor: 'title',
        sortable: true
      },
      {
        Header: <Text>Subject</Text>,
        id: 'subject',
        accessor: 'subject',
        sortable: true
      },
      {
        Header: <Text>Platform</Text>,
        id: 'platform',
        accessor: 'platform',
        Cell: ({ cell: { value } }) => {
          return <Text>{value === 0 ? 'Portal' : 'SkillBuilder'}</Text>
        },
        sortable: true
      },
      {
        Header: <Text>Organization</Text>,
        id: 'organization',
        accessor: 'organization.name',
        sortable: true
      },
      {
        Header: <Text>Location</Text>,
        id: 'location',
        accessor: 'location.name',
        sortable: true
      },
      {
        Header: <Text>Category</Text>,
        id: 'category',
        accessor: 'category.name',
        sortable: true
      }
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        label: 'Edit',
        leftSection: <IconPencil />,
        href: (id) => `/build/email/templates/${id}/edit`,
        component: Anchor
      },
      {
        label: 'Delete',
        leftSection: <IconTrash />,
        onClick: (id) => setTemplateToDelete(id)
      }
    ], []
  )

  const remove = () => {
    deleteTemplate({ id: templateToDelete })
      .unwrap()
      .then(() => {
        showNotification({
          message: 'Successfully deleted email template',
          color: 'success'
        })
        setTemplateToDelete(false)
      })
      .catch(() =>
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error deleting email template',
          color: 'red'
        })
      )
  }

  return (
    <>
      <Modal opened={!!templateToDelete} onClose={() => setTemplateToDelete(false)} title='Are you sure you want to delete this template?'>
        <Group gap='xs'>
          <Button color='red.6' onClick={() => remove() }>Delete</Button>
          <Button color='gray.6' onClick={() => setTemplateToDelete(false)}>Cancel</Button>
        </Group>
      </Modal>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <EmailTemplateTableDataProvider>
          <ReactTable actions={actions} filters={filters} searchable />
        </EmailTemplateTableDataProvider>
      </ReactTableContextsProvider>
    </>
  )
}

export function useEmailTemplatesQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetEmailTemplatesQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function EmailTemplateTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useEmailTemplatesQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating EmailTemplateTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Email template table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
