/* eslint react/prop-types: 0 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Group,
  Text,
  Title,
  Button,
  Grid,
  Stack,
  NumberInput,
  Checkbox,
  Slider,
  Space
} from '@mantine/core';
import { BaseModal } from './BaseModal';
import { QuestionStateUpdate } from './QuestionsState';
import { QuestionType } from '../../../../js/generated/enums/QuestionType';
import { calculateMaxDecimalsForScale } from './util';

export function RatingScaleModal ({ showing, setShowing, dispatch }) {
  return (
    <BaseModal
      title=''
      showing={showing}
      onClose={() => setShowing(false)}
    >
      <RatingScaleForm setShowing={setShowing} dispatch={dispatch} />
    </BaseModal>
  )
}

const RatingTypes = Object.freeze({
  Numeric: 'Numeric',
  Custom: 'Custom'
})

function RatingScaleForm ({ setShowing, dispatch }) {
  const [selectingRating, setSelectingRating] = useState(true)
  const typeOptions = useMemo(() => Object.values(RatingTypes), [])
  const onTypeSelected = useCallback((selectedType) => {
    console.debug('Selected rating scale type.', selectedType)
    switch (selectedType) {
      case RatingTypes.Custom:
        dispatch({ questionType: QuestionType.RatingScale, ratingScaleConfig: { start: 1, stepSize: 1, stepCount: 2 }, type: QuestionStateUpdate.NewQuestion })
        setShowing(false)
        break
      default:
        setSelectingRating(false)
    }
  }, [setSelectingRating, setShowing, dispatch])
  const onConfirm = useCallback((config) => {
    dispatch({ questionType: QuestionType.RatingScale, ratingScaleConfig: config, type: QuestionStateUpdate.NewQuestion })
    setShowing(false)
  }, [dispatch, setShowing])

  return (
    <Box miw='40vw' mih={selectingRating ? '20vh' : '40vh'}>
      <Group position='center' grow>
        {selectingRating ? <RatingScaleTypeSelection onTypeSelected={onTypeSelected} types={typeOptions} /> : null}
        {!selectingRating ? <RatingScaleConfigurationSelection onConfirm={onConfirm} /> : null}
      </Group>
    </Box>
  )
}

function RatingScaleTypeSelection ({ onTypeSelected, types }) {
  const miw = Math.floor(90 / types.length).toFixed() + '%'
  return (
    <Box w='70%' h='100%'>
      <Group position='center' wrap='nowrap' grow>
        <Text size='xl' fw={700}>Select Scale Type</Text>
      </Group>
      <Space h='xl' />
      <Group position='apart' align='center' wrap='nowrap' grow>
        {types.map(elem => <Button miw={miw} size='xl' key={elem} onClick={() => onTypeSelected(elem)}>{elem} Rating Scale</Button>)}
      </Group>
    </Box>
  )
}

function RatingScaleConfigurationSelection ({ onConfirm }) {
  const [stepSize, setStepSize] = useState(1)
  const [numberSteps, setNumberSteps] = useState(2)
  const [startAtZero, setStartAtZero] = useState(false)
  const [startAt, setStartAt] = useState(1)
  const [sliderValue, setSliderValue] = useState(0);

  const maxDecimals = useMemo(() => {
    return calculateMaxDecimalsForScale((startAtZero ? 0 : startAt), stepSize, numberSteps)
  }, [stepSize, numberSteps, startAtZero, startAt])

  const roundValue = useCallback(
    (value) => value.toLocaleString('fullwide', { maximumFractionDigits: maxDecimals }),
    [maxDecimals]
  )

  const marks = useMemo(() => {
    const newStartAt = (startAtZero ? 0 : startAt)
    const newStopAt = newStartAt + (stepSize * Math.max(numberSteps - 1, 0))
    const maxLabeledIntermediateMarks = 20
    const labelStepInterval = (maxLabeledIntermediateMarks >= (numberSteps - 2)) ? 1 : Math.floor((numberSteps - 1) / (maxLabeledIntermediateMarks + 1))
    const newMarks = []
    let currentValue = newStartAt
    let currentStep = 0
    while (currentStep < numberSteps) {
      if ((currentValue === newStartAt) || (currentValue === newStopAt) || (!(currentStep % labelStepInterval))) {
        if (!((labelStepInterval !== 1) && (currentStep < (numberSteps - 1)) && ((currentStep + labelStepInterval) > numberSteps))) {
          newMarks.push({ value: currentValue, label: roundValue(currentValue) })
        }
      }
      currentValue += stepSize
      currentStep += 1
    }
    if (newStopAt < newStartAt) {
      newMarks.reverse()
    }
    return newMarks
  }, [roundValue, stepSize, numberSteps, startAtZero, startAt])

  const selectedRange = useMemo(() => {
    const minSliderValue = (startAtZero ? 0 : startAt)
    const maxSliderValue = (startAtZero ? 0 : startAt) + (stepSize * Math.max(numberSteps - 1, 0))
    return (minSliderValue <= maxSliderValue) ? [minSliderValue, maxSliderValue] : [maxSliderValue, minSliderValue]
  }, [stepSize, numberSteps, startAtZero, startAt])

  const formatLabel = useCallback((value) => {
    const annotateMin = () => roundValue(value) + ' (min)'
    const annotateMax = () => roundValue(value) + ' (max)'

    if (value === selectedRange[0]) {
      if (selectedRange[0] > selectedRange[1]) {
        return annotateMax()
      }
      return annotateMin()
    } else if (value === selectedRange[1]) {
      if (selectedRange[0] > selectedRange[1]) {
        return annotateMin()
      }
      return annotateMax()
    }
    return roundValue(value)
  }, [roundValue, selectedRange])

  useEffect(() => {
    if ((sliderValue < selectedRange[0]) || (sliderValue > selectedRange[1])) {
      let selectedIndex = 0
      if (Math.abs(selectedRange[1] - sliderValue) <= Math.abs(startAt)) {
        selectedIndex = 1
      }
      setSliderValue(selectedRange[selectedIndex])
    }
  }, [selectedRange, sliderValue, startAt, setSliderValue])

  return (
    <Box w='100%' h='100%'>
      <Group position='left'>
        <Title>Numeric Scale Configuration</Title>
      </Group>
      <Grid align='flex-start'>
        <Grid.Col span={2} >
          <Stack align='flex-start' justify='flex-start'>
            <NumberInput
              label='Size'
              clampBehavior='strict'
              min={-100000000}
              max={100000000}
              suffix={' per step'}
              decimalScale={3}
              value={stepSize}
              onChange={(value) => { if (value !== '') setStepSize(value) }}
              required
            />
            <NumberInput
              label='Count'
              allowNegative={false}
              clampBehavior='strict'
              min={1}
              max={100000}
              suffix={' steps'}
              allowDecimal={false}
              value={numberSteps}
              onChange={(value) => { if (value !== '') setNumberSteps(value) }}
              required
            />
            <NumberInput
              clampBehavior='strict'
              min={-100000000}
              max={100000000}
              prefix={'Start at '}
              decimalScale={3}
              value={startAtZero ? 0 : startAt}
              onChange={(value) => { if ((value !== '') && !startAtZero) setStartAt(value) }}
              disabled={startAtZero}
              required={!startAtZero}
            />
            <Checkbox
              checked={startAtZero}
              onChange={() => setStartAtZero(!startAtZero)}
              label='Start at Zero'
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={10}>
          <Group position='center' grow>
            <Text size='xl' fw={700} ta='center'>Preview</Text>
          </Group>
          <Space h='xl' />
          <Space h='xl' />
          <Slider
            defaultValue={sliderValue}
            value={sliderValue}
            onChange={setSliderValue}
            min={selectedRange[0]}
            max={selectedRange[1]}
            step={stepSize}
            marks={marks}
            label={formatLabel}
            labelTransitionProps={{
              transition: 'skew-down',
              duration: 150,
              timingFunction: 'linear'
            }}
            maw='99%'
          />
        </Grid.Col>
      </Grid>
      <Space h='xl' />
      <Stack align='flex-end' justify='flex-end'>
        <Group position='right' grow>
          <Button size='xl' color='green.4' onClick={() => onConfirm({ start: (startAtZero ? 0 : startAt), stepSize: stepSize, stepCount: numberSteps })}>Create Scale</Button>
        </Group>
      </Stack>
    </Box>
  )
}
