/* eslint react/prop-types: 0 */
import { Box, Center, Group, Loader, Skeleton, Space, Stack } from '@mantine/core';
import React from 'react';

export function CardLoadingPlaceholder () {
  return (
    <Box>
      <Skeleton height={40} radius="xl" miw='24rem' w='40%' />
      <Space h='xl' />
      <Group mb={20}>
        <Skeleton height={25} circle />
        <Skeleton height={25} radius="xl" miw='10rem' w='10%' />
      </Group>
      <Stack justify='flex-start'>
        <Skeleton height={12} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} radius="xl" miw='40rem' w='100%' />
        <Skeleton height={12} mt={10} miw='24rem' w="60%" radius="xl" />
      </Stack>
      <Space h='xl' />
      <Group justify='space-between'>
        <Stack justify='flex-start' align='center'>
          <Skeleton height={50} circle />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
        </Stack>
        <Stack justify='flex-start' align='center'>
          <Skeleton height={50} circle />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
        </Stack>
        <Stack justify='flex-start' align='center'>
          <Skeleton height={50} circle />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
          <Skeleton height={8} mt={6} radius="xl" miw='10rem' w='100%' />
        </Stack>
      </Group>
      <Space h='xl' />
      <Center>
        <Loader type="bars" />
      </Center>
    </Box>
  )
}
