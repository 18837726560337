/* eslint react/prop-types: 0 */
import React, { useCallback, useContext, useEffect } from 'react';
import { PublishedAssessmentContext } from '../../../../assessment/PublishedAssessmentContext';
import { Center, Group, Loader, Space, Stack, Title } from '@mantine/core';
import { SubmitAssessmentButton } from '../../../../assessment/AssessmentSubmitHandler';
import { PublishedAssessmentPages } from '../../../../assessment/PublishedAssessmentApp';
import { useDispatch, useSelector } from 'react-redux';
import { QueryState } from '../../../../assessment/util';
import { selectPageIdsByAssessmentId } from '../../../../assessment/pagesSlice';
import { fetchPublishedAssessmentQuestions } from '../../../../assessment/assessmentsSlice';
import { notifications } from '@mantine/notifications';
import { DistributionReporter } from './DistributionReporter';
import { LoadAssessmentError } from '../../../../assessment/AssessmentResponseHandler';
import { useSpringGestures } from '../../../../core/useSpringGestures';

export function DistributionReportApp () {
  const { assessmentId, displayNumbers, next, fetch, recordResponse, timeLimit, currentPageIndex, auth } = useContext(PublishedAssessmentContext)
  console.debug('DistributionReportApp updating - context display numbers | current page index', displayNumbers, currentPageIndex)

  const dispatch = useDispatch()
  const questionsStatus = useSelector(state => state.assessments.status)
  const rejectedByServer = (questionsStatus === QueryState.Rejected)
  const questionsDidError = (questionsStatus === QueryState.Failed) || rejectedByServer
  const questionsLoading = (questionsStatus === QueryState.Idle) || (questionsStatus === QueryState.Loading)

  const pageIds = useSelector(state => selectPageIdsByAssessmentId(state, assessmentId))
  const hasPages = !!(pageIds?.length)
  const questionsLoaded = (questionsStatus === QueryState.Succeeded || ((questionsStatus === QueryState.Submitting) && hasPages))

  const dispatchLoadAssessment = useCallback(() => {
    dispatch(fetchPublishedAssessmentQuestions({
      fetchUrl: fetch,
      startPageIndex: currentPageIndex,
      submitUrl: next,
      recordResponse: recordResponse,
      timeLimit: timeLimit,
      displayNumbers: displayNumbers,
      assessmentId: assessmentId,
      auth: auth,
      isDistributionReport: true
    }))
  }, [dispatch, fetch, currentPageIndex, next, recordResponse, timeLimit, displayNumbers, assessmentId, auth])

  useEffect(() => {
    if (questionsStatus === QueryState.Idle) {
      console.info('Triggering redux fetch assessment data from idle query state.', questionsStatus)
      dispatchLoadAssessment()
    } else {
      console.debug('Questions Status changed.', questionsStatus)
      if (questionsStatus === QueryState.Failed) {
        const errorMessage = 'We\'re having trouble contacting the server for your assessment data. Try reloading and hang tight!'
        notifications.show({
          id: 'refresh-page',
          title: 'Refresh Page',
          message: errorMessage,
          color: 'red',
          withBorder: true
        })
      }
    }
  }, [questionsStatus, dispatchLoadAssessment])

  return (
    <>
      <DistributionReporter assessmentId={assessmentId} />
      {hasPages && (
        <DistributionReportPageController
          pageIds={pageIds}
          assessmentId={assessmentId}
        />
      )}
      {!hasPages && questionsLoading && (
        <Center>
          <Space h='xl' />
          <Loader variant="dots" />
        </Center>
      )}
      {!hasPages && questionsLoaded && !questionsDidError && (
        <Center>
          <Space h='xl' />
          <Title>No page data found when requesting assessment - please try refreshing the page.</Title>
        </Center>
      )}
      {!hasPages && questionsDidError && (
        <LoadAssessmentError />
      )}
    </>
  )
}

function DistributionReportPageController ({ pageIds, assessmentId = '' }) {
  const { next } = useContext(PublishedAssessmentContext)

  useSpringGestures()

  return (
    <Stack gap='xl'>
      <PublishedAssessmentPages pageIds={pageIds} />
      <Group justify='center'>
        <SubmitAssessmentButton submit={() => { window.location.href = next }} showSubmit={true} />
      </Group>
    </Stack>
  )
}
