/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { createFile, getFiles } from '../../../js/api/applicant_repository'
import FileHome from './FileHome'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import FileUpload from './FileUpload'
import { Button } from '@mantine/core'
import { IconFileUpload } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'

export default function ApplicantFiles () {
  const cycleId = document.getElementById('applicant-files-container').getAttribute('data-cycle-id')
  const applicantId = document.getElementById('applicant-files-container').getAttribute('data-applicant-id')
  const isViewer = parseInt(document.getElementById('applicant-files-container').getAttribute('data-is-viewer')) === 1
  const [files, setFiles] = useState([])

  useEffect(() => {
    loadFiles()
  }, [])

  const loadFiles = () => {
    getFiles(applicantId).then(data => setFiles(data.items))
  }

  const upload = <Button disabled={isViewer} color='blue.6' component={Link} to={`/hire/report-results/${cycleId}/applicants/${applicantId}/files/upload`} leftSection={<IconFileUpload size={18}/>}>Upload</Button>

  const submit = data => {
    return createFile(applicantId, data)
      .then(() => {
        showNotification({
          message: 'Successfully created file',
          color: 'success',
          autoClose: 3000
        })

        loadFiles()
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error creating this file',
          color: 'red',
          autoClose: 3000
        })
      })
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/hire/report-results/:id/applicants/:applicant_id/files' element={<FileHome files={files} loadFiles={loadFiles} upload={upload} isViewer={isViewer} home={`/cycles/${cycleId}/results`}/>}/>
        <Route path='/hire/report-results/:id/applicants/:applicant_id/files/upload' element={<FileUpload back={`/hire/report-results/${cycleId}/applicants/${applicantId}/files`} files={files} submit={submit}/>}/>
      </Routes>
    </BrowserRouter>
  )
}
