import axios from 'axios'

export function getUnreadNotifications () {
  return axios.get('/api/v1/notifications/unread').then(response => response.data.data)
}

export function markAsRead (id) {
  return axios.patch(`api/v1/notifications/${id}`, { read: true })
    .then(() => {
      return true
    })
    .catch(err => {
      console.error(err)
      throw err
    })
}
