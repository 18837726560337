import React from 'react'
import PropTypes from 'prop-types'
import {
  ActionIcon,
  Divider,
  Grid,
  Group,
  List,
  Space,
  Switch,
  Title,
  Tooltip,
  UnstyledButton
} from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import { useGetPhasesQuery } from '../../../redux/query/hire/phasesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import _ from 'lodash'

export default function TriggerView ({ trigger, emailTemplates, toggleTrigger, editTrigger, deleteTrigger }) {
  const { data: phases, error, isLoading } = useGetPhasesQuery()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const toggle = trigger => {
    toggleTrigger(trigger)
      .then(() => {
        showNotification({
          message: trigger.enabled ? 'This trigger is now disabled. It will not run for applicants' : 'This trigger is now enabled. It will run for applicants',
          color: 'success',
          autoClose: 3000
        })
      })
      .catch(err => {
        if (err.data?.type === 'validation_error') {
          for (const message of err.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error updating this trigger',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Title order={4}>{trigger.name}</Title>
          <Tooltip label={trigger.strategy.description} position='bottom-start'>
            <Title order={6}>Strategy: {trigger.strategy.name}</Title>
          </Tooltip>
          <Tooltip label='Whether or not the trigger only runs once for each applicant.' position='bottom-start'>
            <Title order={6}>Run once: {trigger.onetime ? 'Yes' : 'No'}</Title>
          </Tooltip>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={6}>Conditions</Title>
          <List withPadding listStyleType='disc'>
            {
              trigger.conditions.map(condition => (
                <ConditionListItem key={trigger.id + condition.code} trigger={trigger} condition={condition} phases={phases} />
              ))
            }
          </List>
          <Space h='lg' />
          <Title order={6}>Consequents</Title>
          <List withPadding listStyleType='disc'>
            {
              trigger.consequents.map(consequent => (
                <ConsequentListItem key={trigger.id + consequent.code} trigger={trigger} consequent={consequent} emailTemplates={emailTemplates} />
              ))
            }
          </List>
        </Grid.Col>
        <Grid.Col span={3}>
          <Group spacing='xs'>
            <Tooltip label={trigger.enabled ? 'Disable' : 'Enable'} position='bottom'>
              <UnstyledButton>
                <Switch color='teal.6' checked={trigger.enabled} size='md' onLabel='ON' offLabel='OFF' onChange={() => toggle(trigger)}/>
              </UnstyledButton>
            </Tooltip>
            <Tooltip label='Edit' position='bottom'>
              <ActionIcon onClick={() => editTrigger(trigger)}><IconEdit size={30}/></ActionIcon>
            </Tooltip>
            <Tooltip label='Delete' position='bottom'>
              <ActionIcon variant='danger-subtle' onClick={() => deleteTrigger(trigger)}><IconTrash size={30}/></ActionIcon>
            </Tooltip>
          </Group>
        </Grid.Col>
      </Grid>
      <Divider my='md'/>
    </>
  )
}

TriggerView.propTypes = {
  trigger: PropTypes.object.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  toggleTrigger: PropTypes.func.isRequired,
  editTrigger: PropTypes.func.isRequired,
  deleteTrigger: PropTypes.func.isRequired
}

function ConditionListItem ({ trigger, condition, phases }) {
  return (
    <List.Item>
      {condition.code === 'BASELINE_HIGHER_THAN_OR_EQUAL_TO_AFTER_PHASE' && <>Minimum baseline of <b>{trigger.metadata.baseline}</b> after <i>{_.find(phases.items, { id: parseInt(trigger.metadata.phase) })?.name}</i></>}
      {condition.code === 'APPLICANT_STATUS_EQUALS' &&
        <>
          <p>Status equals any of:</p>
          <List withPadding listStyleType='circle'>
            {trigger.metadata.applicant_status.map((name) =>
              <List.Item key={trigger.id + condition.code + name}><i>{name}</i></List.Item>
            )}
          </List>
        </>
      }
      {condition.code === 'BELOW_CUT_SCORES_AFTER_PHASE' && <>Below cut scores after <i>{_.find(phases.items, { id: parseInt(trigger.metadata.cut_score_phase) })?.name}</i></>}
      {condition.code === 'ABOVE_CUT_SCORES_AFTER_PHASE' && <>Above cut scores after <i>{_.find(phases.items, { id: parseInt(trigger.metadata.cut_score_phase) })?.name}</i></>}
    </List.Item>
  )
}

ConditionListItem.propTypes = {
  trigger: PropTypes.object.isRequired,
  condition: PropTypes.object.isRequired,
  phases: PropTypes.object.isRequired
}

function ConsequentListItem ({ trigger, consequent, emailTemplates }) {
  return (
    <List.Item>
      {consequent.code === 'CONTACT_APPLICANT' &&
        <>
          Contact applicant:
          <List withPadding listStyleType='circle'>
            {
              trigger.metadata.emails?.map((email) =>
                <List.Item key={trigger.id + consequent.code + email.template}>
                  Send <i>{_.find(emailTemplates, { id: parseInt(email.template) })?.title}</i> {parseInt(email.schedule) === 0 && <>immediately</>} {parseInt(email.schedule) === 1 && <>{email.after_execution} days later</>}
                </List.Item>
              )
            }
          </List>
        </>
      }
      {consequent.code === 'UPDATE_APPLICANT_STATUS' && <>Update status to <i>{trigger.metadata.update_applicant_status}</i></>}
      {consequent.code === 'CHANGE_APPLICANT_ASSESSMENT_STATUS_IN_ADP' && <>Reflect applicant status in ADP WFN</>}
      {consequent.code === 'NOTIFY_SUBSCRIBERS' && <>Notify cycle subscribers.</>}
      {consequent.code === 'SEND_APPLICANT_TO_SUCCESS_FACTOR' && <>Send applicant to SuccessFactor.</>}
    </List.Item>
  )
}

ConsequentListItem.propTypes = {
  trigger: PropTypes.object.isRequired,
  consequent: PropTypes.object.isRequired,
  emailTemplates: PropTypes.array.isRequired
}
