/* eslint react/prop-types: 0 */
import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Group,
  Space,
  Title,
  Badge,
  Grid
} from '@mantine/core';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import { useAssessmentInviteDetail } from './AssessmentInvitesHooks';
import { formatPhaseInviteLink, renderDateFromSource } from '../formatUtil';
import { useIsAdmin } from '../CyclesHooks';
import { ApplicantSummaryCard } from '../summaries/ApplicantSummaryCard';
import { LockableIcon } from '../summaries/LockableIcon';
import { CyclePassSummaryCard } from '../summaries/CyclePassSummaryCard';
import { EmailSummaryCard } from '../summaries/EmailSummaryCard';
import { AccountSummaryCard } from '../summaries/AccountSummaryCard';
import { OpenInviteRecipientSummaryCard } from '../summaries/OpenInviteRecipientSummaryCard';
import { CycleSummaryCard } from '../summaries/CycleSummaryCard';
import { PassActivityTabs } from '../activity/PassActivityTabs';
import { PreviewButton } from '../nav/CycleNavButton';
import { useCycle } from '../../CycleHooks';
import { ToggleLockButton } from '../nav/ToggleLockButton';
import { useAssessmentInviteLockMutation } from './AssessmentInvitesApi';
import { ExpirationDateBadge } from '../summaries/ExpirationDateBadge';
import { CardLoadingPlaceholder } from '../nav/CardLoadingPlaceholder';
import { CardQueryError } from '../nav/CardQueryError';
import { CopyableLink } from '../nav/CopyableLink';

export function PhaseInviteDetail () {
  const { cycleId, inviteId } = useParams()
  const [invite, inviteQuerying] = useAssessmentInviteDetail(inviteId, cycleId, !inviteId)
  const [cycle, cycleQuerying] = useCycle(cycleId)
  const [isAdmin, adminLoading] = useIsAdmin()
  const querying = !!(inviteQuerying || adminLoading || cycleQuerying)
  return (
    <>
      {!!querying && <CardLoadingPlaceholder />}
      {!querying && !invite && <CardQueryError />}
      {!querying && !!invite && <PhaseInviteCard invite={invite} cycle={cycle} isAdmin={isAdmin} />}
    </>
  )
}

const cardColSpan = { base: 12, md: 6, lg: 3 }
const cardGridGutter = { base: 'xs', sm: 'md', md: 'xl', lg: 'xxl' }

function PhaseInviteCard ({ invite, cycle, isAdmin }) {
  console.debug('Cycle pass phase invite card updating', invite)
  const passId = invite.cycle_pass?.id
  const cycleId = cycle?.id
  const inviteId = invite.id
  const missingData = (!cycleId || !passId || !inviteId)
  const previewParams = { title: invite.invite_config.header, message: invite.invite_config.message, style: invite.invite_config.style, locked: invite.locked }
  const editRedirectLink = missingData ? '/' : generatePath(CyclePassRoute.CyclePassInviteEdit, { cycleId, passId, inviteId })
  const phaseInviteLink = formatPhaseInviteLink(invite.url_suffix, invite.invite_config.link_prefix)
  return (
    <Box>
      <Title>Phase Invite #{inviteId ?? 'Id Missing'} Detail</Title>
      <Group mb='md' mt='xxs'>
        <Badge color='teal' variant='light'>{invite.type}</Badge>
        {!!invite.phase?.name && <Badge color='indigo' variant='light'>{invite.phase.name}</Badge>}
        <ExpirationDateBadge inviteConfig={invite.invite_config} locked={invite.locked} />
        <Badge color='green' variant='transparent'>Updated {renderDateFromSource(invite.updated_at)}</Badge>
        <Badge color='gray' variant='transparent'>Created {renderDateFromSource(invite.created_at)}</Badge>
        {!!isAdmin && <Badge color='blue' variant='light'>{phaseInviteLink ?? 'Link Missing'}</Badge>}
      </Group>
      <Group>
        <LockableIcon item={invite} />
        <PreviewButton cycleId={cycleId} { ...previewParams } />
        <Button variant='subtle' size='sm' component={Link} to={editRedirectLink}>Edit</Button>
        {!!isAdmin && <TogglePhaseInviteLockButton cycleId={cycleId} inviteId={inviteId} locked={invite.locked} />}
      </Group>
      <Space h='xl' />
      {!!phaseInviteLink && (
        <>
          <CopyableLink label='Invite Link:' contents={phaseInviteLink} />
          <Space h='xl' />
        </>
      )}
      <Grid justify='flex-start' align='stretch' gutter={cardGridGutter} grow>
        {!!invite.applicant && <Grid.Col span={cardColSpan}><ApplicantSummaryCard applicant={invite.applicant} isAdmin={isAdmin} /></Grid.Col>}
        {!!invite.cycle_pass && <Grid.Col span={cardColSpan}><CyclePassSummaryCard cyclePass={invite.cycle_pass} cycleId={cycle?.id} isAdmin={isAdmin} /></Grid.Col>}
        {!!invite.open_invite_recipient && <Grid.Col span={cardColSpan}><OpenInviteRecipientSummaryCard recipient={invite.open_invite_recipient} cycleId={cycleId} isAdmin={isAdmin} /></Grid.Col>}
        {!!invite.email && <Grid.Col span={cardColSpan}><EmailSummaryCard email={invite.email} isAdmin={isAdmin} /></Grid.Col>}
        {!!invite.account && !!isAdmin && <Grid.Col span={cardColSpan}><AccountSummaryCard account={invite.account} isAdmin={isAdmin} /></Grid.Col>}
        {!!invite.facilitator?.account && <Grid.Col span={cardColSpan}><AccountSummaryCard account={invite.facilitator.account} isAdmin={isAdmin} title='Facilitator' /></Grid.Col>}
        {!!cycle && <Grid.Col span={cardColSpan}><CycleSummaryCard cycle={cycle} isAdmin={isAdmin} /></Grid.Col>}
      </Grid>
      <Space h='xl' />
      <PassActivityTabs activityTarget={invite} activityTargetType='Invite' isAdmin={isAdmin} />
    </Box>
  )
}

function TogglePhaseInviteLockButton ({ cycleId, inviteId, locked = true }) {
  const [toggleLock, { isLoading: toggleLockProcessing }] = useAssessmentInviteLockMutation()
  return (
    <ToggleLockButton
      toggleLock={toggleLock}
      toggleLockProcessing={toggleLockProcessing}
      cycleId={cycleId}
      itemId={inviteId}
      itemIdName='id'
      locked={locked}
    />
  )
}
