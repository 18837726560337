import React from 'react'
import { ActionIcon, Anchor, Button, Flex, Tooltip } from '@mantine/core'
import Dropdown from '../../core/Dropdown'
import { IconChevronDown, IconPhoto } from '@tabler/icons-react'
import PropTypes from 'prop-types'

export default function CreateButtons ({ context = 'assessments' }) {
  const createAssessmentOptions =
  context === 'assessments'
    ? [
        {
          label: 'Create Assessment',
          href: '/build/assessments/create'
        },
        {
          label: 'Import Assessment',
          href: '/build/assessments/import'
        }
      ]
    : [
        {
          label: 'Create AP Battery',
          href: '/build/assessments/create-battery'
        },
        {
          label: 'Create TestPrep Battery',
          href: '/build/assessments/testprep/create'
        }
      ]

  const CreateButton = <Button variant='filled' color='success' rightSection={<IconChevronDown size='1.25rem' />}>Create</Button>

  return (
    <Flex justify='space-between'>
      <Dropdown target={CreateButton} items={createAssessmentOptions} />
      <Flex gap='xs' justify='flex-end' align='center' mih='100%'>
        <Tooltip label='Media'>
          <ActionIcon component={Anchor} color='success' variant='light' href='/build/assessments/media'><IconPhoto size='2rem' /></ActionIcon>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

CreateButtons.propTypes = {
  context: PropTypes.string
}
