/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Combobox, useCombobox, Pill, PillsInput, Group, CheckIcon, Space } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import {
  getGenericCategory,
  QuestionSubMapping,
  supportsCustomFieldName
} from '../../../../js/generated/enums/QuestionSubMapping';
import { hasSubCategories, QuestionMapping } from '../../../../js/generated/enums/QuestionMapping';
import { QuestionStateUpdate } from './QuestionsState';

const allCategoryOptions = [
  ...Object.values(QuestionMapping).filter(elem => !hasSubCategories(elem)).map(elem => ({ id: elem, searchable: elem.toLowerCase().trim(), subType: null, type: elem, customFieldName: null, group: null, value: elem, label: elem })),
  ...Object.values(QuestionSubMapping).filter(elem => !supportsCustomFieldName(elem)).map(elem => ({ id: elem, searchable: (getGenericCategory(elem) + ' ' + elem).toLowerCase().trim(), subType: elem, type: getGenericCategory(elem), customFieldName: null, group: getGenericCategory(elem), value: elem, label: elem }))
  // ...Object.values(QuestionSubMapping).filter(elem => supportsCustomFieldName(elem)).map(elem => ({ id: elem + ' TestABC', searchable: (getGenericCategory(elem) + ' ' + elem + ' ' + 'TestABC').toLowerCase().trim(), subType: elem, type: getGenericCategory(elem), customFieldName: 'TestABC', group: getGenericCategory(elem), value: elem + ' TestABC', label: elem + ' TestABC' })),  // Uncomment to test.
]

export const QuestionCategoriesEditor = memo(function QuestionCategoriesEditor ({ questionId, categories, dispatch }) { // TODO [full custom mapping] Query backend for custom field results as appropriate.
  console.debug('QuestionCategoriesEditor updating.', questionId, categories)
  const [activeCategories, setActiveCategories] = useState(categories ?? [])
  const lastCategoriesRef = useRef(categories)
  const skipUpdateRef = useRef(false)

  useEffect(() => {
    if (categories !== lastCategoriesRef.current) {
      lastCategoriesRef.current = categories
      skipUpdateRef.current = true
      setActiveCategories(categories)
    }
  }, [categories])

  useEffect(() => {
    if (activeCategories !== categories && categories === lastCategoriesRef.current) {
      if (skipUpdateRef.current) {
        skipUpdateRef.current = false
      } else {
        lastCategoriesRef.current = activeCategories
        dispatch({
          type: QuestionStateUpdate.UpdateQuestion,
          questionId: questionId,
          newAttributes: { categories: activeCategories }
        })
      }
    }
  }, [activeCategories, categories, dispatch, questionId])

  return (
    <SelectCreatable
      value={activeCategories}
      setValue={setActiveCategories}
      allOptions={allCategoryOptions}
    />
  )
})

const customFieldSupported = Object.values(QuestionSubMapping).filter(elem => supportsCustomFieldName(elem))

export function SelectCreatable ({ value, setValue, allOptions }) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const [data, setData] = useState(allOptions)
  const [search, setSearch] = useState('')

  const filteredOptions = data.filter((item) => item.searchable.includes(search.toLowerCase().trim()))

  const exactMatchBySupportedCustomSubCategory = useMemo(() => {
    return customFieldSupported.map(elem => [elem, data.some(item => item.subType === elem && item.customFieldName?.toLowerCase().trim() === search.toLowerCase().trim())])
  }, [data, search])

  const handleValueSelect = useCallback((val) => {
    setValue((current) =>
      current.filter((v) => v.value === val.value).length ? current.filter((v) => v.value !== val.value) : [...current, val]
    )
  }, [setValue])

  const handleValueRemove = useCallback((val) => {
    setValue((current) => current.filter((v) => v !== val))
  }, [setValue])

  const values = value.map((item) => (
    <Pill key={item.value} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item.label}
    </Pill>
  ))

  const groupTypes = [...new Set(filteredOptions.filter(elem => !!elem.subType).map(elem => elem.type))]
  const noGroup = filteredOptions.filter(elem => !elem.subType)
  const groups = Object.fromEntries(groupTypes.map(groupType => [groupType, filteredOptions.filter(elem => elem.type === groupType)])) // Note elem.subType should be guaranteed to exist here.

  const exactMatchOptions = search.trim().length <= 0
    ? []
    : exactMatchBySupportedCustomSubCategory
      .filter(([, matched]) => !matched)
      .map(([elem]) =>
        ({ value: '$create' + elem, label: `+ Create ${elem} ${search}` })
      )
  const exactMatchData = exactMatchOptions.length ? { 'Custom Field': exactMatchOptions } : null

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val?.startsWith?.('$create')) { // createCustomReportField, createADPCustomIntegration, createICIMsCustomIntegration, createSuccessFactorCustomIntegration, createGenericCustomIntegration
          const selectedSubType = val.split('$create')[1]
          const newElement = { searchable: (getGenericCategory(selectedSubType) + ' ' + selectedSubType + ' ' + search.toLowerCase().trim()).toLowerCase().trim(), value: selectedSubType + '.' + search, label: selectedSubType + ' ' + search, subType: selectedSubType, type: getGenericCategory(selectedSubType), id: selectedSubType + '.' + search, customFieldName: search, group: getGenericCategory(selectedSubType) }
          setData((current) => [...current, newElement])
          handleValueSelect(newElement)
        } else if (val?.startsWith?.('$toggle')) {
          console.debug('Got toggle select on submit.', val)
        } else {
          const selectedElement = data.filter(elem => elem.value === val)[0] ?? null
          if (selectedElement) {
            handleValueSelect(selectedElement)
          } else {
            console.error('Unable to find matching element with value.', val, data)
          }
        }
      }}
    >
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                onChange={(event) => {
                  combobox.openDropdown();
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => {
                  setSearch('')
                  combobox.closeDropdown()
                }}
                placeholder='Search mappings'
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(value[value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={400} style={{ overflowY: 'auto' }}>
          <CategorySelectGroup group='General' groups={{ General: noGroup }} value={value} search={search} />
          {Object.keys(groups).map(group => <CategorySelectGroup key={group} group={group} groups={groups} value={value} search={search} />)}
          {!!exactMatchData && <CategorySelectGroup key='Custom Field' group='Custom Field' groups={exactMatchData} value={value} search={search} />}
          { search.trim().length > 0 && (groupTypes.length + noGroup.length) === 0 && !exactMatchData && (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

function CategorySelectGroup ({ group, groups, value, search }) { // TODO [full custom field] This will collapse the "Custom Field" group every time a new custom field is created because the check against value mismatches for that group's labels.
  const [expanded, setExpanded] = useState(!!search)
  const expandedSinceSearchRef = useRef(!!search)
  const expandedSinceSelectRef = useRef(false)

  useEffect(() => {
    if (search && !expandedSinceSearchRef.current) {
      expandedSinceSearchRef.current = true
      setExpanded(true)
    } else if (!search && expandedSinceSearchRef.current) {
      expandedSinceSearchRef.current = false
    }
  }, [search])

  const groupOptions = groups[group]

  const groupValues = new Set(groupOptions.map(elem => elem.value))
  const selectedGroupValuesCount = value.filter(selected => groupValues.has(selected.value)).length

  useEffect(() => {
    if (!expandedSinceSelectRef.current && selectedGroupValuesCount) {
      expandedSinceSelectRef.current = true
      setExpanded(true)
    }
  }, [selectedGroupValuesCount])

  const onClickCallback = (event) => {
    console.debug('Called category editor expand on click - ignoring if from child component.', event)
    if (
      !groupValues.has(event.target?.textContent ?? null) &&
      (
        !(event.target?.nodeName === 'svg' || event.target?.nodeName === 'path') ||
        (event.target?.classList?.[0] ?? '').includes('tabler') ||
        (event.target?.ownerSVGElement?.classList?.[0] ?? '').includes('tabler')
      )
    ) {
      setExpanded(!expanded)
    }
  }

  return (
    <Combobox.Group label={<CategorySelectExpandGroup group={group} expanded={expanded} />} key={group} onClick={onClickCallback} style={{ cursor: (expanded ? 'zoom-out' : 'zoom-in') }}>
      {!expanded && <Space h='xs' />}
      {!!expanded && groupOptions.map(item => <CategorySelectOption key={item.value} item={item} value={value} />)}
    </Combobox.Group>
  )
}

function CategorySelectExpandGroup ({ group, expanded }) {
  return (
    <Group gap="sm">
      {expanded ? <IconChevronUp size={12} /> : <IconChevronDown size={12} />}
      <span>{group}</span>
    </Group>
  )
}

function CategorySelectOption ({ item, value }) {
  return (
    <Combobox.Option value={item.value} key={item.value} label={item.label}>
      <Group gap="sm">
        {value.filter(elem => elem.value === item.value).length ? <CheckIcon size={12} /> : null}
        <span>{item.label}</span>
      </Group>
    </Combobox.Option>
  )
}
