import { ActionIcon, Box, Group, Image, NumberInput, ScrollArea, Stack, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAspectRatioFilled, IconAspectRatioOff } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

const minDimension = 1
const step = 1
const stepHoldDelay = 500

function handleStepIntervalUpdate (time) {
  return Math.max(1000 / Math.max(time, 1) ** 2, 10)
}

export default function RatioInputs ({ width, height, onChange: updateSize, file, h }) {
  const [ratio, handlers] = useDisclosure(true)

  const updateWidth = (val) => {
    console.debug('Updating width.', { val, width, height, ratio })
    let _height = height
    if (val < 1) {
      val = 1
      if (ratio) _height = 1
      updateSize(val, _height)
      return
    }

    if (ratio) _height = Math.round((_height / Math.max(width, minDimension)) * val)

    updateSize(val, _height)
  }

  const updateHeight = (val) => {
    console.debug('Updating height.', { val, height, width, ratio })
    let _width = width
    if (val < 1) {
      val = 1
      if (ratio) _width = 1
      updateSize(_width, val)
      return
    }

    if (ratio) _width = Math.round((_width / Math.max(height, minDimension)) * val)

    updateSize(_width, val)
  }

  return (
    <Stack p='md' bg='gray.2' h='100%' w='100%' mah='100%' maw='100%' style={{ borderRadius: 'var(--mantine-radius-md)', border: 'solid 1px var(--mantine-color-gray-3)' }}>
      <Group align='center'>
        <Group>
          <NumberInput
            label='Width'
            placeholder='Custom width'
            value={width}
            min={minDimension}
            onChange={updateWidth}
            step={step}
            stepHoldDelay={stepHoldDelay}
            stepHoldInterval={handleStepIntervalUpdate}
          />
          <NumberInput
            label='Height'
            placeholder='Custom height'
            step={step}
            value={height}
            min={minDimension}
            onChange={updateHeight}
            stepHoldDelay={stepHoldDelay}
            stepHoldInterval={handleStepIntervalUpdate}
          />
        </Group>
        <Tooltip label={ratio ? 'Maintain aspect ratio' : 'Do not maintain aspect ratio'}>
          <ActionIcon
            variant='subtle'
            mt='lg'
            onClick={handlers.toggle}
            color={ratio ? 'success' : 'gray'}
          >
            {ratio ? <IconAspectRatioFilled/> : <IconAspectRatioOff />}
          </ActionIcon>
        </Tooltip>
      </Group>
      <ScrollArea type='auto' h={h}>
        <Box w={width} h={height} m='0 auto'>
          <Image fit='fill' src={file} w='100%' h='100%' />
        </Box>
      </ScrollArea>
    </Stack>
  )
}

RatioInputs.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onChange: PropTypes.func,
  file: PropTypes.string,
  h: PropTypes.string
}
