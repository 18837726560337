import { Font } from '@react-pdf/renderer'
import Style from './base'

export default function InterviewStyles (options) {
  Font.registerHyphenationCallback(words => {
    return [words]
  })
  return {
    cover: {
      coverPage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center'
      },
      coverText: {
        fontSize: 36,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 34
      },
      coverSubtext: {
        fontSize: 18
      }
    },
    summary: {
      ...Style(options),
      infoBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative',
        flexDirection: 'column',
        marginVertical: options.font.size
      },
      interviewInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: options.font.size
      },
      ivInfoTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: options.font.size
      },
      interviewerList: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: options.font.size
      },
      interviewerListItem: {
        lineHeight: 2,
        fontSize: options.font.size,
        display: 'flex',
        flexDirection: 'row'
      }
    },
    directions: {
      ...Style(options),
      list: {
        marginTop: 10
      },
      item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        fontSize: options.font.size,
        marginTop: options.font.size * 3
      },
      number: {
        paddingHorizontal: 5,
        fontSize: options.font.size
      }
    },
    questionTable: {
      ...Style(options),
      qTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: options.font.size
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: options.font.size,
        alignContent: 'center',
        paddingVertical: options.font.size / 2
      },
      header: {
        fontFamily: options.font.styles.bold,
        paddingBottom: '0.05in'
      },
      col: {
        width: '33.33%',
        fontSize: options.font.size,
        display: 'flex',
        flexDirection: 'column'
      },
      questionCol: {
        width: '15%',
        textAlign: 'center'
      },
      competencyCol: {
        width: '35%'
      },
      ratingsCol: {
        width: '50%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignSelf: 'center'
      },
      ratingHeaders: {
        fontSize: options.font.size,
        width: '50%',
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: 'auto',
        marginTop: options.font.size
      },
      interviewerHeader: {
        maxWidth: options.ratingWidth + '%',
        fontSize: options.ratingFontSize
      },
      competencyList: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: options.font.size,
        maxHeight: '0.5in',
        width: 'auto',
        alignContent: 'flex-start'
      }
    },
    summaryRatings: {
      ...Style(options),
      summaryRatingsBox: {
        fontSize: options.font.size,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: options.font.size * 2,
        paddingBottom: options.font.size * 2,
        borderBottom: '1px solid gray'
      },
      summaryRatingsText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingVertical: options.font.size / 2
      },
      consensusRating: {
        borderWidth: 3,
        width: options.font.size * 6,
        height: options.font.size * 4.5,
        backgroundColor: '#fefefe'
      }
    },
    questionPage: {
      ...Style(options),
      questionBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: options.font.size * 2
      },
      questionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'relative',
        alignItems: 'center',
        paddingHorizontal: options.font.size * 1.75
      },
      questionNumber: {
        left: -options.font.size,
        fontSize: options.font.size * 1.5,
        fontFamily: 'Helvetica-Bold'
      },
      questionCompetencyList: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        fontSize: options.font.size,
        maxHeight: '0.5in',
        width: 'auto',
        marginBottom: options.font.size,
        alignContent: 'flex-start',
        paddingHorizontal: options.font.size * 1.75
      },
      questionCompetency: {
        color: '#2DB1FF',
        fontFamily: 'Helvetica-BoldOblique',
        paddingRight: options.font.size * 2
      },
      questionCompetencyDescription: {
        fontFamily: 'Helvetica-Oblique',
        color: '#444444'
      },
      answerBox: {
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: options.font.size * 1.75
      },
      answerResponse: {
        width: '100%',
        height: '2.5in',
        backgroundColor: '#e6e6f0',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'black',
        marginVertical: options.font.size
      },
      answerList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        height: 'auto',
        textAlign: 'left'
      },
      answerItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: options.font.size,
        alignItems: 'center',
        justifyContent: 'flex-start'
      },
      answerRatingCol: {
        width: 'auto',
        padding: options.font.size * 2,
        textAlign: 'center',
        fontSize: options.font.size * 1.5
      }
    }
  }
}
