import React, { useState } from 'react'
import { Button, Group, SimpleGrid, TextInput } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { sendTwoFactorCode } from '../../js/api/account_repository'
import PropTypes from 'prop-types'

export default function EmailTwoFactorAuthentication ({ setType, apiBasePath }) {
  const [sent, setSent] = useState(false)
  const [code, setCode] = useState('')

  const sendCode = () => {
    showNotification({
      id: 'email-2fa-send-code-notification',
      message: 'Generating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'email', action: 'send', resend: false })
      .then(() => {
        updateNotification({
          id: 'email-2fa-send-code-notification',
          message: 'Check your email for the code!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setSent(true)
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'email-2fa-send-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const resendCode = () => {
    showNotification({
      id: 'email-2fa-resend-code-notification',
      message: 'Generating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'email', action: 'send', resend: true })
      .then(() => {
        updateNotification({
          id: 'email-2fa-resend-code-notification',
          message: 'The code has been sent again. Check your email!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'email-2fa-resend-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const validate = () => {
    showNotification({
      id: 'email-2fa-validate-code-notification',
      message: 'Validating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'email', action: 'validate', code: code })
      .then(() => {
        updateNotification({
          id: 'email-2fa-validate-code-notification',
          message: 'The code has been sent successfully validated!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setType('email')
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'email-2fa-validate-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  return (
    <>
      {!sent && <Button onClick={() => sendCode()}>Send code</Button>}
      {
        sent && (
          <SimpleGrid cols={1} verticalSpacing='xl'>
            <TextInput label="Write the code we've sent to your email below" placeholder='Code' value={code} onChange={ev => setCode(ev.target.value)}/>
            <Group>
              <Button color='success' onClick={() => validate()}>Validate</Button>
              <Button onClick={() => resendCode()}>Re-send code</Button>
            </Group>
          </SimpleGrid>
        )
      }
    </>
  )
}

EmailTwoFactorAuthentication.propTypes = {
  setType: PropTypes.func,
  apiBasePath: PropTypes.string
}
