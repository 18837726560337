/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { Center, Container, Loader, Space, Title } from '@mantine/core'
import { invite } from '../../js/api/keysurvey_repository'
import Content from '../layout/Content'

export default function KeySurveyInviteApp () {
  const applicantId = document.getElementById('keysurvey-invite-container').getAttribute('data-applicant-id')
  const cycleId = document.getElementById('keysurvey-invite-container').getAttribute('data-cycle-id')
  const ksFormId = document.getElementById('keysurvey-invite-container').getAttribute('data-ks-form-id')
  const workflow = parseInt(document.getElementById('keysurvey-invite-container').getAttribute('data-cycle-workflow-value'))

  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState()

  useEffect(() => {
    invite(applicantId, cycleId, ksFormId)
      .then(redirect => setRedirect(redirect))
      .catch(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (typeof redirect !== 'undefined' && workflow === 0) {
      window.location.href = redirect
    }
  }, [redirect])

  return (
    <Content>
      <Space h='xl'/>
      {
        workflow === 1 &&
        <>
          <Container>
            <Center>
              <Title order={2} align='center'>
                Thank you for completing the proctored section of this process.
              </Title>
            </Center>
            <Space h='xl'/>
            <Center>
              <Title order={3} align='center'>
                If you still haven&apos;t closed ProctorFree, feel free to click on &quot;Finish Exam&quot; before continuing with the next step. The next section is not proctored.
              </Title>
            </Center>
            <Space h='xl'/>
            {
              typeof redirect === 'undefined' &&
              <Center>
                <Title order={3} align='center'>
                  Preparing Online Prescreen... This might take a minute.
                </Title>
              </Center>
            }
          </Container>
          <Space h='md' />
          <Container>
            <Center>
              {typeof redirect !== 'undefined' && <Title order={3}><a href={redirect}>Click here to start the next step</a></Title>}
              {typeof redirect === 'undefined' && loading && <Loader/>}
              {typeof redirect === 'undefined' && !loading && <Title order={3} color='red'>Error preparing Online Prescreen!</Title> }
            </Center>
          </Container>
        </>
      }
      {
        workflow === 0 &&
        <>
          <Container>
            <Center>
              <Title order={2} align='center'>
                Preparing Online Prescreen... This might take a minute. You will be redirected automatically once it&apos;s ready
              </Title>
            </Center>
          </Container>
          <Space h='md' />
          <Container>
            <Center>
              {typeof redirect !== 'undefined' && <Title order={3}>If you have not been automatically redirect, <a href={redirect}>Click here</a></Title>}
              {typeof redirect === 'undefined' && loading && <Loader/>}
              {typeof redirect === 'undefined' && !loading && <Title order={3} color='red'>Error preparing Online Prescreen!</Title> }
            </Center>
          </Container>
        </>
      }
    </Content>
  )
}
