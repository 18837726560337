import React, { useState } from 'react';
import { Button, Flex, Group, Text, TextInput } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { sendTwoFactorCode } from '../../js/api/account_repository';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types'

export default function TotpTwoFactorAuthentication ({ setType, apiBasePath }) {
  const [sent, setSent] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [code, setCode] = useState('')

  const sendCode = () => {
    showNotification({
      id: 'totp-2fa-send-code-notification',
      message: 'Generating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'totp', action: 'send', resend: false })
      .then(response => {
        setQrCode(response)

        updateNotification({
          id: 'totp-2fa-send-code-notification',
          message: 'Check your authenticator app for the code!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setSent(true)
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'totp-2fa-send-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const resendCode = () => {
    showNotification({
      id: 'totp-2fa-resend-code-notification',
      message: 'Generating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'totp', action: 'send', resend: true })
      .then(response => {
        setQrCode(response)

        updateNotification({
          id: 'totp-2fa-resend-code-notification',
          message: 'The code has been sent again. Check your authenticator app for the code!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'totp-2fa-resend-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }

  const validate = () => {
    showNotification({
      id: 'totp-2fa-validate-code-notification',
      message: 'Validating code...',
      loading: true,
      withCloseButton: false
    })
    sendTwoFactorCode(apiBasePath, { type: 'totp', action: 'validate', code: code })
      .then(() => {
        updateNotification({
          id: 'totp-2fa-validate-code-notification',
          message: 'The code has been sent successfully validated!',
          color: 'success',
          loading: false,
          autoClose: 3000
        })
        setType('totp')
      })
      .catch(err => {
        let message = 'Something went wrong'

        if (err.response.status === 400 && err.response.data?.type === 'general_error') {
          message = err.response.data.errors[0]
        }

        updateNotification({
          id: 'totp-2fa-validate-code-notification',
          message: message,
          color: 'red',
          loading: false,
          autoClose: 3000
        })
      })
  }
  return (
    <>
      {!sent && <Button onClick={() => sendCode()}>Send code</Button>}
      {
        sent && (
          <Flex
            mih={50}
            gap='md'
            justify='center'
            align='center'
            direction='column'
            wrap='wrap'
          >
            <Text size={'sm'}>Use your authenticator app to scan the QR Code below</Text>
            <QRCodeSVG value={qrCode} width={200} height={200}/>
            <TextInput label='Enter the code generated from your authenticator app below' placeholder='Code' value={code} onChange={ev => setCode(ev.target.value)}/>
            <Group>
              <Button color='success' onClick={() => validate()}>Validate</Button>
              <Button onClick={() => resendCode()}>Generate new QR Code</Button>
            </Group>
          </Flex>
        )
      }
    </>
  )
}

TotpTwoFactorAuthentication.propTypes = {
  setType: PropTypes.func,
  apiBasePath: PropTypes.string
}
