/* eslint react/prop-types: 0 */
import {
  IconAdjustments,
  IconAffiliate,
  IconBan,
  IconCalendarDue,
  IconChartAreaLine,
  IconClipboardText,
  IconCopy,
  IconDatabaseExport,
  IconFileSpreadsheet,
  IconFiles,
  IconLink,
  IconMail,
  IconMailOpened,
  IconPencil,
  IconRefresh,
  IconRocket,
  IconSettingsAutomation,
  IconTableExport,
  IconTableImport,
  IconTrash,
  IconUserPlus,
  IconUserUp,
  IconListDetails,
  IconInputSearch,
  IconPercentage,
  IconActivity,
  IconTrendingUp2,
  IconLocation,
  IconSchool,
  IconMailbox,
  IconCurrentLocation,
  IconUsers,
  IconPhoneCheck,
  IconCalendarTime
} from '@tabler/icons-react'

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { generatePath } from 'react-router-dom';
import { CyclePassRoute } from '../../../js/generated/enums/CyclePassRoute';
import { Header, NumberCell, RezviewCell } from '../../core/ReactTable/ReactTable';
import {
  Anchor,
  Box,
  Center,
  ColorSwatch,
  Group,
  HoverCard,
  Highlight,
  Text,
  Tooltip,
  Button,
  Select,
  Modal,
  Space,
  Flex
} from '@mantine/core';
import SelectableColumn from '../../core/ReactTable/SelectableColumn';
import dayjs from 'dayjs';
import { Tag, tagFromId } from '../../../js/generated/enums/Tag';
import { useCycleStatuses } from './CycleHooks';
import { useDisclosure } from '@mantine/hooks';
import { useEditStatusMutation } from '../../../redux/query/hire/applicantsApi.slice';
import NoticeAlert from '../../core/Alert/NoticeAlert';
import { DatePicker } from '@mantine/dates';
import {
  DetailViewNotesScoreCell,
  DetailViewScoreCell,
  formatScoreDisplay,
  NOTES_MODULE_ID
} from './DetailViewScoreCell';
import { CycleContext } from '../../core/ReactTable/TableContexts';

const ICON_SIZE = '1.25rem'
const ROW_HEIGHT = '5rem'

export function useCycleActions (id, hasAdminAccess, hasClientAccess, hasBetaAccess, meta = {}) {
  const actions = useMemo(() => {
    const _actions = {}
    const exports = {
      label: 'Exports',
      metadata: {
        items: [
          {
            label: 'Export Summaries',
            leftSection: <IconDatabaseExport size={ICON_SIZE} />,
            href: `/cycles/${id}/summaries`
          },
          {
            label: 'Applicant Results',
            leftSection: <IconFileSpreadsheet size={ICON_SIZE} />,
            href: `/cycles/${id}/fullExport`
          },
          {
            label: 'Custom Export',
            leftSection: <IconTableExport size={ICON_SIZE} />,
            href: `/cycles/${id}/customExport`
          },
          {
            label: 'Duplicate Report',
            leftSection: <IconCopy size={ICON_SIZE} />,
            href: `/cycles/${id}/duplicateFlagReport`
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (exports.metadata.items.length > 0) {
      _actions.exports = exports
    }

    const cycleManagement = {
      label: 'Cycle Management',
      metadata: {
        defaultOpened: true,
        items: [
          {
            label: 'Edit Cycle',
            leftSection: <IconPencil size={ICON_SIZE} />,
            href: '/hire/report-results/' + id + '/edit',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Cycle Specs',
            leftSection: <IconAdjustments size={ICON_SIZE} />,
            href: '/hire/report-results/' + id + '/specs',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Update Cycle',
            leftSection: <IconCalendarDue size={ICON_SIZE} />,
            href: `/cycles/${id}/update`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Refresh Cycle',
            leftSection: <IconRefresh size={ICON_SIZE} />,
            href: `/cycles/${id}/refresh`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Delete Cycle',
            leftSection: <IconTrash size={ICON_SIZE} />,
            href: `/cycles/${id}/delete`,
            variant: 'danger-subtle',
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Close Cycle',
            leftSection: <IconBan size={ICON_SIZE} />,
            href: `/cycles/${id}/close`,
            metadata: {
              isGranted: hasClientAccess && !hasAdminAccess
            }
          },
          {
            label: 'Triggers',
            leftSection: <IconSettingsAutomation size={ICON_SIZE} />,
            href: `/hire/cycles/${id}/triggers`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            label: 'Batteries',
            leftSection: <IconClipboardText size={ICON_SIZE} />,
            href: `/hire/cycles/${id}/batteries`,
            metadata: {
              isGranted: hasAdminAccess
            }
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }

    if (cycleManagement.metadata.items.length > 0) {
      _actions.cycleManagement = cycleManagement
    }

    const candidateManagement = {
      label: 'Candidate Management',
      metadata: {
        defaultOpened: true,
        items: [
          {

            label: 'Add Applicant',
            leftSection: <IconUserPlus size={ICON_SIZE} />,
            href: `/cycles/${id}/applicants/create`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Import Applicants',
            leftSection: <IconUserUp size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/applicants/import`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {
            label: 'Invites (Beta)',
            leftSection: <IconTableImport size={ICON_SIZE} />,
            href: generatePath(CyclePassRoute.CycleInvitesHome, { cycleId: id }),
            metadata: {
              isGranted: hasBetaAccess && hasAdminAccess
            }
          },
          {

            label: 'Contact Applicants',
            leftSection: <IconMail size={ICON_SIZE} />,
            href: `/cycles/${id}/contact`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {

            label: 'Boost Cycle',
            leftSection: <IconRocket size={ICON_SIZE} />,
            href: `/cycles/${id}/invite`,
            metadata: {
              isGranted: (hasClientAccess || hasAdminAccess)
            }
          },
          {

            label: 'Message History',
            leftSection: <IconMailOpened size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/message-history`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {

            label: 'Files',
            leftSection: <IconFiles size={ICON_SIZE} />,
            href: `/hire/report-results/${id}/files`
          },
          {
            label: 'Detail View (Beta)',
            leftSection: <IconListDetails size={ICON_SIZE} />,
            href: `/cycles/${id}/beta-detail`,
            metadata: {
              isGranted: hasAdminAccess
            }
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (candidateManagement.metadata.items.length > 0) {
      _actions.candidateManagement = candidateManagement
    }

    const overview = {
      label: 'Overview',
      metadata: {
        items: [
          {

            label: 'Job Posting Site',
            leftSection: <IconLink size={ICON_SIZE} />,
            href: `${meta.job_posting_site}`
          },
          {

            label: 'Analytics',
            leftSection: <IconChartAreaLine size={ICON_SIZE} />,
            href: `/cycles/${id}/analytics`
          },
          {

            label: 'Integrations',
            leftSection: <IconAffiliate size={ICON_SIZE} />,
            href: `/hire/cycle/${id}/integrations`,
            metadata: {
              isGranted: hasAdminAccess
            }
          },
          {
            component: Button,
            label: 'Users with Access',
            leftSection: <IconUsers size='1.25rem' />,
            onClick: meta.openUserAccessModal,
            metadata: {
              isGranted: hasAdminAccess
            }
          }
        ].filter(item => item.metadata?.isGranted !== false)
      }
    }
    if (overview.metadata.items.length > 0) {
      _actions.overview = overview
    }

    return _actions
  }, [hasAdminAccess, hasClientAccess, hasBetaAccess, id, meta])

  return actions
}

const DETAIL_TABLE_ABSTRACT_ORDER_VARIANTS = {
  score: 'orderScore'
}

export function useCycleApplicantsDetailListTable (namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, cycleDescription, educationOptions = null) {
  return {
    defaultHiddenColumns: useDetailViewDefaultHiddenColumns(account.access.DEMO, account.access.ADMIN),
    defaultFilters: DEFAULT_CYCLE_DETAIL_LIST_FILTERS,
    columns: useAllDetailViewColumns(namespace, cycleId, account, moduleInfos, cycleDescription),
    filters: useCycleDetailListFilters(account.access.ADMIN, statuses, statusTiers, regions, educationOptions),
    actions: account.access.ADMIN ? CYCLE_DETAIL_TABLE_PM_ACTIONS : CYCLE_DETAIL_TABLE_ACTIONS,
    searchable: true,
    alwaysShowHeaderGroups: false,
    showHeaderGroupsIfExpanded: false,
    abstractOrderVariants: DETAIL_TABLE_ABSTRACT_ORDER_VARIANTS,
    noBorders: true
  }
}

const DEFAULT_CYCLE_DETAIL_LIST_FILTERS = Object.freeze({
  display: 'percentile'
})

const CYCLE_DETAIL_TABLE_ACTIONS = [ // TODO [detail rework full] Portal user actions: Edit, Candidate Report, Contact Applicant, Files

]

const CYCLE_DETAIL_TABLE_PM_ACTIONS = [ // TODO [detail rework full] PM user actions: +KeySurvey Data, +Refresh/Delete Applicant
  ...CYCLE_DETAIL_TABLE_ACTIONS
]

const DISPLAY_TYPE_FILTER_OPTIONS = Object.freeze([
  Object.freeze({ label: 'Percentile', value: 'percentile' }),
  Object.freeze({ label: 'Mixed', value: 'mixed' }),
  Object.freeze({ label: 'Raw Score', value: 'rawscore' })
])

const DISPLAY_TYPE_PM_FILTER_OPTIONS = Object.freeze([
  ...DISPLAY_TYPE_FILTER_OPTIONS,
  Object.freeze({ label: 'Z Score', value: 'zscore' })
])

const SEARCH_FIELD_OPTIONS = Object.freeze([
  { label: 'All', value: 'all' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Name', value: 'name' },
  { label: 'City', value: 'city' },
  { label: 'Email', value: 'email' }
])

const CYCLE_DETAIL_LIST_SHARED_FILTERS = [
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    leftSection: <IconPhoneCheck />,
    input: {
      type: 'phone'
    },
    placeholder: 'Enter phone number',
    errorText: 'Enter a valid phone number',
    useValue: true
  },
  {
    id: 'submitDate',
    label: 'Submit Date',
    leftSection: <IconCalendarTime />,
    input: {
      type: 'minMaxDate'
    },
    useValue: true
  },
  {
    id: 'searchField',
    label: 'Search Field',
    leftSection: <IconInputSearch />,
    options: SEARCH_FIELD_OPTIONS,
    placeholder: 'Current: All',
    clearOptionValue: 'all'
  }
]

const CYCLE_DETAIL_LIST_FILTERS = [
  {
    id: 'display',
    label: 'Display Type',
    leftSection: <IconPercentage />,
    options: DISPLAY_TYPE_FILTER_OPTIONS
  },
  ...CYCLE_DETAIL_LIST_SHARED_FILTERS
]

const CYCLE_DETAIL_LIST_PM_FILTERS = [
  {
    id: 'display',
    label: 'Display Type',
    leftSection: <IconPercentage />,
    options: DISPLAY_TYPE_PM_FILTER_OPTIONS
  },
  ...CYCLE_DETAIL_LIST_SHARED_FILTERS
]

const STATUS_FILTER_NO_OPTIONS = {
  id: 'status',
  label: 'Status',
  leftSection: <IconActivity />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__',
  multiSelect: true
}

const TIER_FILTER_NO_OPTIONS = {
  id: 'tier',
  label: 'Tiering',
  leftSection: <IconTrendingUp2 />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__',
  multiSelect: true
}

const REGION_FILTER_NO_OPTIONS = {
  id: 'region',
  label: 'Location',
  leftSection: <IconLocation />,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__'
}

const EDUCATION_FILTER_NO_OPTIONS = {
  id: 'degree',
  label: 'Highest Education',
  leftSection: <IconSchool />,
  multiSelect: true,
  placeholder: 'Current: All',
  clearOptionValue: '__SHOW_ALL__'
}

const zipCodeFilterId = 'zipCode'

function isAllNumbers (value) {
  return /^\d+$/.test(value)
}

function isZipCode (value) {
  if (value.length === 5) {
    return isAllNumbers(value)
  }
  if (value.length === 10) {
    const replaced = value.replace('-', '')
    return (replaced.length !== 10) && isZipCode(replaced)
  }
  if (value.length === 9) {
    return isAllNumbers(value)
  }
  return false
}

const ZIP_FILTER_INPUT = {
  id: zipCodeFilterId,
  label: 'Zip Code',
  leftSection: <IconMailbox />,
  input: {
    type: 'text'
  },
  placeholder: 'Enter zip code',
  validateUpdate: (value) => !value || isZipCode(value),
  errorText: 'Enter a valid Zip Code',
  useValue: true
}

const RADIUS_FILTER_INPUT = {
  id: 'radius',
  label: 'Radius',
  leftSection: <IconCurrentLocation />,
  input: {
    type: 'number'
  },
  getDisabled: (allSelected) => !(allSelected[zipCodeFilterId] ?? null) || !isZipCode(allSelected[zipCodeFilterId]),
  getRequired: (allSelected) => !!(allSelected[zipCodeFilterId] ?? null) && isZipCode(allSelected[zipCodeFilterId]),
  numberProps: {
    min: 1,
    max: 500,
    step: 5,
    startValue: 50,
    clampBehavior: 'strict'
  },
  placeholder: 'Enter miles',
  useValue: true
}

function toTitleCase (str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function useCycleDetailListFilters (isPM, statuses, statusTiers, regions, educationOptions = null) {
  return useMemo(() => {
    const statusFilter = {
      ...STATUS_FILTER_NO_OPTIONS,
      options: [{ label: 'All', value: STATUS_FILTER_NO_OPTIONS.clearOptionValue }, ...statuses.items.map(status => ({ label: status.name, value: status.name }))]
    }
    const tierFilter = {
      ...TIER_FILTER_NO_OPTIONS, options: [{ label: 'All', value: TIER_FILTER_NO_OPTIONS.clearOptionValue }, ...statusTiers.map(tier => ({ label: toTitleCase(tier.name), value: tier.id.toString() }))]
    }
    const regionsFilters = regions.items?.length
      ? [{
          ...REGION_FILTER_NO_OPTIONS, options: [{ label: 'All', value: REGION_FILTER_NO_OPTIONS.clearOptionValue }, ...regions.items.map(region => ({ label: region, value: region }))]
        }]
      : []
    const simpleViewFilters = educationOptions?.items?.length
      ? [{
          ...EDUCATION_FILTER_NO_OPTIONS, options: [{ label: 'All', value: EDUCATION_FILTER_NO_OPTIONS.clearOptionValue }, ...educationOptions.items.map(education => ({ label: education, value: education }))]
        }, ZIP_FILTER_INPUT, RADIUS_FILTER_INPUT]
      : [ZIP_FILTER_INPUT, RADIUS_FILTER_INPUT]
    const generatedFilters = [statusFilter, tierFilter, ...regionsFilters, ...simpleViewFilters]
    return isPM ? [...generatedFilters, ...CYCLE_DETAIL_LIST_PM_FILTERS] : [...generatedFilters, ...CYCLE_DETAIL_LIST_FILTERS]
  }, [isPM, statuses, statusTiers, regions, educationOptions])
}

const ID_COLUMNS = [
  {
    Header: <Header>ID</Header>,
    headerLabel: 'ID',
    id: 'id',
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      return <><Text ta='center'>{value.id}</Text>{!!value.ks_respondent?.ks_respondent_id && <Text ta='center'>KS: {value.ks_respondent.ks_respondent_id}</Text>}</>
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

function getIdColumns (isPm) {
  return isPm ? ID_COLUMNS : []
}

const PERSONAL_COLUMNS = [
  {
    Header: <Header>Name</Header>,
    headerLabel: 'Name',
    id: 'name',
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      const CELL_SIZE = '20rem'
      const joinedName = (value.first_name ?? '') + (value.first_name && value.last_name ? ' ' : '') + (value.last_name ?? '')
      const joinedAddress = (value.contact_information?.city ?? '') + (value.contact_information?.city && value.contact_information?.state ? ', ' : '') + (value.contact_information?.state ?? '')
      return ( // TODO [detail rework full] show fake resume/summary modal on name click
        <RezviewCell name={joinedName} applicantId={value.id} address={joinedAddress} w={CELL_SIZE} />
      )
    },
    sortable: true,
    hideable: true,
    filterable: false,
    align: 'left'
  },
  {
    Header: <Header centered>Submit Date</Header>,
    headerLabel: 'Submit Date',
    id: 'submitDate',
    accessor: 'submitted_at',
    Cell: ({ cell: { value } }) => {
      return (
        <Flex justify='center' align='center'>
          <Tooltip label='Submitted Date' position='top'>
            <Text ta='center' m='auto'>{dayjs(value).format('MM-DD-YYYY')}</Text>
          </Tooltip>
        </Flex>
      )
    },
    width: '20rem',
    sortable: true,
    hideable: true,
    filterable: false,
    align: 'center'
  },
  {
    Header: <Header>Highest Degree</Header>,
    headerLabel: 'Highest Degree',
    id: 'highestDegree',
    accessor: 'education',
    Cell: ({ cell: { value } }) => {
      const degreeAnswer = value?.highest_degree?.answer
      return (
        <Text ta='left'>{degreeAnswer || '-'}</Text>
      )
    },
    sortable: false,
    hideable: true,
    filterable: false
  }
]

const STATUS_COLUMN = {
  Header: <Header centered>Status</Header>,
  headerLabel: 'Status',
  id: 'status',
  accessor: (row) => row,
  Cell: DetailViewStatusCell,
  sortable: false,
  hideable: true,
  filterable: false,
  align: 'center'
}

const noStatusOptions = []
const hiredTierId = 2

function DetailViewStatusCell ({ cell: { value: row } }) {
  const value = row.status?.name ?? ''
  const cycleId = useContext(CycleContext)
  // const { cycleId } = useParams()  // useParams is causing a rerender on params change even when cycleId does not change - resolved with context provider.
  const [statuses] = useCycleStatuses(cycleId)
  const [opened, { open, close }] = useDisclosure(false)
  const [dateModalOpened, { open: openDateModal, close: closeDateModal }] = useDisclosure(false)
  const [currentValue, setCurrentValue] = useState(value)
  const ignoreClickRef = useRef(false)
  const initializedRef = useRef(false)
  const [edit] = useEditStatusMutation()

  const statusOptions = useMemo(() => {
    if (!statuses?.items?.length) {
      return noStatusOptions
    }
    return statuses.items.map(status => ({ value: status.name, label: status.name, meta: status }))
  }, [statuses])

  const applicantId = row.id

  const onSelect = useCallback((newValue, selectedOption) => {
    console.debug('Called on status select', { newValue, selectedOption, currentValue, applicantId })
    if (newValue) {
      if (newValue !== currentValue) {
        setCurrentValue(newValue)
        if (selectedOption?.meta?.tier?.id === hiredTierId) {
          openDateModal()
        } else {
          edit({ status: newValue, applicants: [applicantId], hiredDate: '', cycleId: cycleId })
        }
      }
    }
    ignoreClickRef.current = true
    close()
  }, [currentValue, applicantId, cycleId, openDateModal, close, edit])

  const onModalClose = useCallback((fromInsideClick = true) => {
    console.debug('Called status modal close', { value, fromInsideClick })
    closeDateModal()
    setCurrentValue(value)
    if (!fromInsideClick) {
      ignoreClickRef.current = true
    }
  }, [value, closeDateModal])

  const onModalSave = useCallback((hiredDate) => {
    console.debug('Called status modal save', { hiredDate, currentValue, applicantId })
    edit({ status: currentValue, applicants: [applicantId], hiredDate: hiredDate, cycleId: cycleId })
    closeDateModal()
  }, [currentValue, applicantId, cycleId, closeDateModal, edit])

  const onBoxClick = (event) => {
    console.debug('Called status box on click', { event, opened, dateModalOpened })
    event.stopPropagation()
    const ignoreClick = ignoreClickRef.current
    if (!dateModalOpened && !ignoreClick) {
      open()
    }
    ignoreClickRef.current = false
  }

  useEffect(() => {
    if (initializedRef.current) {
      setCurrentValue(value)
      close()
      closeDateModal()
      ignoreClickRef.current = false
    } else {
      initializedRef.current = true
    }
  }, [value, close, closeDateModal])

  console.debug('Detail status cell updating', { currentValue, value, applicantId, row, opened, statusOptions })

  return (
    <Flex onBlur={close} align='center' justify='center' pos='relative'>
      {!(statuses && opened) && (<Button color='dark.3' variant='subtle' fw={500} onClick={onBoxClick}>{currentValue}</Button>)}
      {(statuses && opened) && (<Select data={statusOptions} pos='absolute' value={currentValue} onBlur={() => close()} onChange={onSelect} onClick={(e) => e.stopPropagation()} />)}
      <StatusCellHiredDateModal opened={dateModalOpened} onClose={onModalClose} onSave={onModalSave} />
    </Flex>
  )
}

function StatusCellHiredDateModal ({ opened, onClose, onSave }) {
  const [value, setValue] = useState(new Date())

  const onClick = () => {
    onSave(dayjs(value).local().format('YYYY-MM-DD'))
  }
  console.debug('StatusCellHiredDateModal updating', { value, opened })
  return (
    <Modal opened={opened} onClose={() => onClose(false)} title='Info Required!'>
      <Box onClick={(event) => { event.stopPropagation() }}>
        <NoticeAlert title='Please select the hire date' />
        <Center><DatePicker value={value} onChange={setValue} /></Center>
        <Space h='md' />
        <Group justify='center' grow>
          <Button onClick={onClick} color='success'>Save</Button>
          <Button onClick={onClose} color='gray'>Cancel</Button>
        </Group>
      </Box>
    </Modal>
  )
}

const FLAG_COLUMN = {
  Header: <Header>Flags</Header>,
  headerLabel: 'Flags',
  id: 'flags',
  accessor: 'flags',
  Cell: ({ cell: { value } }) => { // TODO [detail rework full] on-click show flag modal
    return (
      <NumberCell centered>
          <span>
            <Tooltip label='Click to View' position='top'>
              <Text>{value ? value.length : 0}</Text>
            </Tooltip>
          </span>
      </NumberCell>
    )
  },
  sortable: false,
  hideable: true,
  filterable: false,
  align: 'center'
}

const FINAL_DETAIL_COLUMNS_NO_DISCUSSION = [
  STATUS_COLUMN,
  {
    Header: <Header>Tag</Header>,
    headerLabel: 'Tag',
    id: 'tag',
    accessor: 'tag',
    Cell: ({ cell: { value } }) => {
      return <>{!!value && <Center><ColorSwatch color={getTagColor(tagFromId(value))} /></Center>}</>
    },
    sortable: false,
    hideable: true,
    filterable: false
  },
  FLAG_COLUMN
]

const FINAL_DETAIL_COLUMNS_DISCUSSION = [
  {
    Header: <Header>Discussion</Header>,
    headerLabel: 'Discussion',
    id: 'discussion',
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      const courtesyLetter = value.scores.filter(elem => elem.module_id === 52)[0]?.score ?? null
      return ( // TODO [detail rework full] formatting/envelope icon for courtesy letter. On click show comments modal.
        <Box mah={ROW_HEIGHT}>
          {!!value.tag && <Center><ColorSwatch color={getTagColor(tagFromId(value.tag))} /></Center>}
          {!!courtesyLetter && <Text>{courtesyLetter}</Text>}
        </Box>
      )
    },
    sortable: false,
    hideable: true,
    filterable: false
  },
  STATUS_COLUMN,
  FLAG_COLUMN
]

function getFinalDetailColumns (cycleDescription) {
  return cycleDescription?.has_discussion ? FINAL_DETAIL_COLUMNS_DISCUSSION : FINAL_DETAIL_COLUMNS_NO_DISCUSSION
}

function getTagColor (tag) {
  switch (tag) {
    case Tag.Black:
      return 'rgba(0, 18, 25, 1)'
    case Tag.Blue:
      return 'rgba(0, 95, 155, 1)'
    case Tag.Green:
      return 'rgba(148, 230, 189, 1)'
    case Tag.Yellow:
      return 'rgba(238, 155, 0, 1)'
    case Tag.Red:
      return 'rgba(174, 32, 18, 1)'
    default:
      return 'rgba(255, 255, 255, 0)'
  }
}

const DEMOGRAPHIC_COLUMNS = [
  {
    Header: <Header>Gender</Header>,
    headerLabel: 'Gender',
    id: 'gender',
    accessor: 'demographic.gender_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value ?? ''}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Race</Header>,
    headerLabel: 'Race',
    id: 'race',
    accessor: 'demographic.race_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value ?? ''}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  },
  {
    Header: <Header>Ethnicity</Header>,
    headerLabel: 'Ethnicity',
    id: 'ethnicity',
    accessor: 'demographic.ethnicity_text',
    Cell: ({ cell: { value } }) => {
      return <Text ta='left'>{value}</Text>
    },
    sortable: false,
    filterable: false,
    hideable: true
  }
]

function buildModuleColumn (namespace, cycleId, account, moduleInfo, child = false, collapsedGroup = null, isExpandedSummary = false) {
  const nestedModuleColumns = []
  if (moduleInfo.sub_modules?.length) {
    nestedModuleColumns.push(...buildModuleColumn(namespace, cycleId, account, { ...moduleInfo, sub_modules: [] }, true, null, true))
    for (const moduleChild of moduleInfo.sub_modules) {
      nestedModuleColumns.push(...buildModuleColumn(namespace, cycleId, account, moduleChild, true))
    }
  }
  console.debug('Nested module columns for info.', { moduleInfo, nestedModuleColumns })
  const moduleName = moduleInfo.module.name ?? 'Name Missing'
  const moduleHasMean = !!moduleInfo.std_dev && (parseFloat(moduleInfo.std_dev) !== 0.0) && !!moduleInfo.mean && (parseFloat(moduleInfo.mean) !== 0.0)
  const [moduleMinScoreString, moduleMinScore] = ((moduleInfo.min_score ?? null) !== null) ? formatScoreDisplay(moduleInfo.min_score) : ['0', 0.0]
  const moduleHasMinScore = moduleHasMean && ((moduleInfo.min_score ?? null) !== null)
  const moduleHasLink = !!(moduleInfo.generated_link && moduleInfo.generated_link_name)
  const moduleHasTooltip = !!(moduleHasMean || moduleHasLink)
  const moduleNameHighlighted = moduleHasTooltip ? moduleName.split(' ') : []
  const moduleTooltipTargetHighlightColor = moduleHasLink ? 'linear-gradient(45deg, var(--mantine-color-blue-7), var(--mantine-color-blue-6))' : 'linear-gradient(180deg, var(--mantine-color-blue-7), var(--mantine-color-blue-7))'
  const moduleColumn = {
    Header: (
      <>
        {moduleHasTooltip
          ? (
          <HoverCard closeDelay={500} openDelay={150} shadow='md' defaultOpened={false}>
            <HoverCard.Target>
              <Box style={{ cursor: 'help' }}>
                <Header centered>
                  <Highlight
                    ta="center"
                    highlight={moduleNameHighlighted}
                    highlightStyles={{
                      backgroundImage: moduleTooltipTargetHighlightColor,
                      fontWeight: 700,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >
                    {moduleName}
                  </Highlight>
                </Header>
              </Box>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <>
                {moduleHasMean && <Group justify='center' gap='xxs'><Text fw={700}>Max Raw Score: </Text><Text fw={300}>{formatScoreDisplay(moduleInfo.max_score)[0]}</Text></Group>}
                {moduleHasMinScore && <Group justify='center' gap='xxs'><Text fw={700}>Cut Score: </Text><Text fw={300}>{moduleMinScoreString}</Text></Group>}
                {moduleHasLink && <Group justify='center'><Anchor href={moduleInfo.generated_link} target='_blank'>{moduleInfo.generated_link_name}</Anchor></Group>}
              </>
            </HoverCard.Dropdown>
          </HoverCard>
            )
          : (
          <Header centered>
            {moduleName}
          </Header>
            )}
      </>
    ),
    headerLabel: moduleInfo.module.name ?? 'Module Name Missing',
    abstractOrder: 'score',
    id: moduleInfo.module.id.toString() + (isExpandedSummary ? '-expandedTotal' : '') + (nestedModuleColumns.length ? '-group' : ''),
    sortId: moduleInfo.module.id.toString(),
    accessor: (row) => row,
    Cell: ({ cell: { value } }) => {
      const scoreValue = value.scores.filter(elem => elem.module_id === moduleInfo.module.id)[0] ?? { score: '-', z_score: '-', percentile_score: '-' }
      const applicantLink = ((moduleInfo.generate_applicant_link_always ?? null) === null) ? null : ((moduleInfo.generate_applicant_link_always || !!value.ks_respondent?.ks_respondent_id) ? `/hire/cycles/${cycleId}/applicants/${value.id}/assessments/${moduleInfo.id}/report` : null)
      const isNotes = moduleInfo.module.id === NOTES_MODULE_ID
      return isNotes
        ? <DetailViewNotesScoreCell
          value={scoreValue}
          applicantId={value.id}
          cycleId={cycleId}
          account={account}
          infoPermission={moduleInfo.permission}
          mah={ROW_HEIGHT}
        />
        : <DetailViewScoreCell
          value={scoreValue}
          moduleInfoType={moduleInfo.type.id}
          moduleId={moduleInfo.module.id}
          precision={moduleInfo.precision}
          namespace={namespace}
          applicantLink={applicantLink}
          moduleMinScore={moduleMinScore}
          mah={ROW_HEIGHT}
        />
    },
    sortable: !nestedModuleColumns.length,
    filterable: false,
    hideable: !nestedModuleColumns.length && !child,
    _hideable: (!!nestedModuleColumns.length || (!nestedModuleColumns.length && !collapsedGroup)) && !child, // TODO [refactor all tables] remove legacy prop once collapsedGroup is removed hideable above can be changed to this
    collapsable: !!nestedModuleColumns.length,
    collapsedGroup: collapsedGroup,
    defaultSortOrder: 'DESC',
    summaryColumnGroup: isExpandedSummary ? moduleInfo.module.id.toString() + '-group' : null,
    phase: moduleInfo.phase?.name ?? null,
    align: 'center',
    ...(nestedModuleColumns.length ? { columns: nestedModuleColumns } : {})
  }
  return nestedModuleColumns.length ? [...buildModuleColumn(namespace, cycleId, account, { ...moduleInfo, sub_modules: [] }, child, moduleColumn.id), moduleColumn] : [moduleColumn]
}

function buildModuleColumns (namespace, cycleId, account, moduleInfos) {
  const allModuleColumns = []
  const generatedColumnGroups = moduleInfos.items.map(moduleInfo => buildModuleColumn(namespace, cycleId, account, moduleInfo))
  generatedColumnGroups.forEach(elem => allModuleColumns.push(...elem))
  return allModuleColumns
}

export function useAllDetailViewColumns (namespace, cycleId, account, moduleInfos, cycleDescription) {
  return useMemo(() => {
    return [
      ...getDetailViewSelectionColumns(),
      ...getIdColumns(account.access.ADMIN),
      ...PERSONAL_COLUMNS,
      ...buildModuleColumns(namespace, cycleId, account, moduleInfos),
      ...getDetailViewDemographicColumns(account.access.DEMO),
      ...getFinalDetailColumns(cycleDescription)
    ]
  }, [namespace, cycleId, account, moduleInfos, cycleDescription])
}

function getDetailViewDemographicColumns (canDiscoverDemo) {
  if (!canDiscoverDemo) {
    return []
  }
  return DEMOGRAPHIC_COLUMNS
}

function getDetailViewSelectionColumns () {
  return [
    SelectableColumn({
      selectAriaLabel: 'Select applicant',
      selectAllAriaLabel: 'Select all applicants',
      selectedKeyAccessor: 'id',
      id: 'checkbox',
      accessor: 'id'
    })
  ]
}

export function useDetailViewDefaultHiddenColumns (canDiscoverDemo, isPm = false) {
  return useMemo(() => {
    if (isPm) {
      return canDiscoverDemo ? DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO_ADMIN : DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS_ADMIN
    }
    return canDiscoverDemo ? DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO : DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS
  }, [canDiscoverDemo, isPm])
}

const DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS = Object.freeze([
  'highestDegree'
])

const DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO = Object.freeze([
  ...DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS,
  'gender',
  'race',
  'ethnicity'
])

const DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS_ADMIN = Object.freeze([
  'id',
  ...DEFAULT_BASE_HIDDEN_CYCLE_DETAIL_COLUMNS
])

const DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO_ADMIN = Object.freeze([
  'id',
  ...DEFAULT_HIDDEN_CYCLE_DETAIL_COLUMNS_WITH_DEMO
])
