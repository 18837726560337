import React from 'react'
import Content from '../../layout/Content'
import CycleManagementDetail from './CycleManagementDetail'

/**
 * This component allows to use the CycleManagementDetail component from a non-React twig template.
 * It receives its props from a div container
 */
export default function CycleManagementDetailWrapper () {
  const selected = document.getElementById('cycle-management-detail-container').getAttribute('data-selected')
  const id = parseInt(document.getElementById('cycle-management-detail-container').getAttribute('data-cycle-id'))

  return <Content><CycleManagementDetail id={id} selected={selected}/></Content>
}
