import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, unwrapResult } from '@reduxjs/toolkit'
import { fetchPublishedAssessmentQuestions } from './assessmentsSlice';
import {
  addPDFTitleFont,
  ExportState,
  makePDFOutline,
  sleep,
  drawExportMissingPlaceholder,
  getMaxPDFPageWidth,
  getPDFPages,
  markPDFConfidential,
  markPDFPages,
  maxScaleFactor
} from './pdfUtil';
import { jsPDF } from 'jspdf';

/**
 * @typedef {object} QuestionExport
 * @property {int} id Question id
 * @property {string|Uint8ClampedArray|canvas|boolean|null} data Rendered question
 * @property {int} width
 * @property {int} height
 * @property {int} offset
 * @property {int} index
 * @property {?boolean} measured
 * @property {?boolean} captured
 * @property {?boolean} rendered
 * @property {?string} error
 */

/**
 * @type {QuestionExport} defaultExport
 */
const defaultExport = Object.freeze({
  id: 0,
  data: null,
  width: 0,
  height: 0,
  offset: 0,
  index: 0,
  measured: false,
  captured: false,
  rendered: false,
  error: null
})

/**
 * @type {EntityAdapter<{QuestionExport}, int>}
 */
const exportsAdapter = createEntityAdapter()

/**
 * @typedef {object} ExportsConfig
 * @property {boolean} displayCompetencies
 * @property {boolean} orderByCompetency
 * @property {int} randomSeed
 * @property {boolean} displayQuestionNumbers
 * @property {boolean} displayPageNumbers
 * @property {boolean} displayCorrectAnswers
 * @property {?boolean} displayAnswerScores
 * @property {boolean} displayLogicIndicators
 * @property {boolean} fullColor
 * @property {boolean} interviewLayout One question per page
 * @property {string[]} features
 * @property {string} title
 * @property {string} subject
 * @property {string} filename
 * @property {string} author
 * @property {string} keywords
 * @property {string} creator
 */

/**
 * @type {ExportsConfig} defaultConfig
 */
const defaultConfig = Object.freeze({
  displayCompetencies: false,
  orderByCompetency: false,
  randomSeed: 0,
  displayQuestionNumbers: false,
  displayPageNumbers: true,
  displayCorrectAnswers: false,
  displayAnswerScores: null,
  displayLogicIndicators: false,
  fullColor: false,
  interviewLayout: false,
  features: [],
  title: 'Assessment',
  subject: 'HireScore Assessment',
  filename: 'Assessment_Export.pdf',
  author: 'HireScore',
  keywords: 'assessment, hirescore, stangds',
  creator: 'StangDS'
})

const initialState = exportsAdapter.getInitialState({
  status: ExportState.Idle,
  error: null,
  loadingProgress: 0, // 0-numberQuestions
  captureProgress: 0, // 0-numberQuestions
  renderProgress: 0, // 0-numberQuestions
  rendersMissing: 0, // 0-numberQuestions
  config: { ...defaultConfig }
})

export const exportToPDF = createAsyncThunk('exports/toPDF', async (pdfParams, { dispatch, rejectWithValue, signal }) => {
  const { config, initializationParams, measurementParams, captureParams, renderParams } = pdfParams
  console.debug('Called exportToPDF', pdfParams)

  /**
   * Example chained signal with axios:
   * const source = axios.CancelToken.source()
   * signal.addEventListener('abort', () => {
   *   source.cancel()
   * })
   *
   * Example chained signal with dispatch in component:
   * const thunkPromise = dispatch(someAsyncThunk(thunkProps))
   * return () => {
   *   // `createAsyncThunk` attaches an `abort()` method to the promise
   *   thunkPromise.abort()
   * }
   */
  console.debug('Clearing any previous export data.', config)
  let currentAction = ExportState.Idle
  dispatch(clearAllExports({ config }))
  if (signal.aborted) {
    console.warn('Export to PDF cancelled via signal before initialization.', signal, pdfParams)
    return rejectWithValue({ error: true, message: currentAction })
  }
  currentAction = ExportState.Initializing
  try {
    console.debug('Awaiting initialization.')
    const initializationPromise = dispatch(initializeAllQuestions(initializationParams))
    signal.addEventListener('abort', () => {
      console.info('Canceling initialization promise from abort signal.', initializationPromise)
      initializationPromise?.abort()
    })
    const initializationResultAction = await initializationPromise
    const initializationResult = unwrapResult(initializationResultAction)
    console.debug('Awaited initialization result.', initializationResult, initializationParams)
  } catch (err) {
    console.error('Initialize all questions error - ending export process.', err)
    return rejectWithValue({ error: true, message: err?.message ?? currentAction })
  }
  if (signal.aborted) {
    console.warn('Export to PDF cancelled via signal after initialization.', signal, pdfParams)
    return rejectWithValue({ error: true, message: currentAction })
  }
  currentAction = ExportState.Loading
  try {
    console.debug('Awaiting measurements.')
    const measurementPromise = dispatch(measureAllQuestions(measurementParams))
    signal.addEventListener('abort', () => {
      console.info('Canceling measurement promise from abort signal.', measurementPromise)
      measurementPromise?.abort()
    })
    const measurementResultAction = await measurementPromise
    const measurementResult = unwrapResult(measurementResultAction)
    console.debug('Awaited measurement result.', measurementResult, measurementParams)
  } catch (err) {
    console.error('Measurement all questions error - ending export process.', err)
    return rejectWithValue({ error: true, message: err?.message ?? currentAction })
  }
  if (signal.aborted) {
    console.warn('Export to PDF cancelled via signal after measuring.', signal, pdfParams)
    return rejectWithValue({ error: true, message: currentAction })
  }
  currentAction = ExportState.Capturing
  try {
    console.debug('Awaiting capture.')
    const capturePromise = dispatch(captureAllQuestions(captureParams))
    signal.addEventListener('abort', () => {
      console.info('Canceling capture promise from abort signal.', capturePromise)
      capturePromise?.abort()
    })
    const captureResultAction = await capturePromise
    const captureResult = unwrapResult(captureResultAction)
    console.debug('Awaited capture result.', captureResult, captureParams)
  } catch (err) {
    console.error('Capture all questions error - ending export process.', err)
    return rejectWithValue({ error: true, message: err?.message ?? currentAction })
  }
  if (signal.aborted) {
    console.warn('Export to PDF cancelled via signal after capturing.', signal, pdfParams)
    return rejectWithValue({ error: true, message: currentAction })
  }
  currentAction = ExportState.Rendering
  try {
    console.debug('Awaiting render.')
    const renderPromise = dispatch(renderAllQuestions(renderParams))
    signal.addEventListener('abort', () => {
      console.info('Canceling render promise from abort signal.', renderPromise)
      renderPromise?.abort()
    })
    const renderResultAction = await renderPromise
    const renderResult = unwrapResult(renderResultAction)
    console.debug('Awaited render result.', renderResult, renderParams)
    return renderResult
  } catch (err) {
    console.error('Render all questions error - ending export process.', err)
    return rejectWithValue({ error: true, message: err?.message ?? currentAction })
  }
})

export const initializeAllQuestions = createAsyncThunk('exports/initializeAllQuestions', async (initializationParams, { rejectWithValue, dispatch, signal }) => {
  console.debug('Called initializeAllQuestions', initializationParams)
  try {
    const initializePromise = dispatch(fetchPublishedAssessmentQuestions(initializationParams))
    signal.addEventListener('abort', () => {
      console.info('Canceling initializeAllQuestions promise from abort signal.', initializePromise, initializationParams)
      initializePromise?.abort()
    })
    const initializeResultAction = await initializePromise
    const result = unwrapResult(initializeResultAction)
    console.debug('Awaited init-fetch result.', result, initializationParams)
    return { error: false, data: true }
  } catch (err) {
    console.error('Init-fetch all questions error.', err)
    return rejectWithValue({ error: true, message: ExportState.Initializing })
  }
})

const maxIterations = 150

export const measureAllQuestions = createAsyncThunk('exports/measureAllQuestions', async (loadingParams, { rejectWithValue, signal, dispatch }) => {
  console.debug('Called measureAllQuestions', loadingParams)
  let iteration = 0
  while (!(iteration > maxIterations)) {
    try {
      const measuredPromise = dispatch(tryWaitForAllQuestionMeasurements(loadingParams))
      signal.addEventListener('abort', () => {
        console.info('Canceling measureAllQuestions promise from abort signal.', measuredPromise, loadingParams)
        measuredPromise?.abort()
      })
      const measureResultAction = await measuredPromise
      const result = unwrapResult(measureResultAction)
      if (result) {
        return result
      }
    } catch (err) {
      console.warn('Measure all questions promise unwrap error.', err)
    }
    iteration += 1
    if (signal.aborted) {
      console.warn('measureAllQuestions cancelled via signal after iterations.', iteration, signal, loadingParams)
      return rejectWithValue({ error: true, message: ExportState.Cancelled })
    }
  }
  return rejectWithValue({
    error: true,
    message: ExportState.Failed,
    details: 'Took too long to load and measure all questions. There is likely an unexpected error elsewhere in the component tree or render process.'
  })
})

export const tryWaitForAllQuestionMeasurements = createAsyncThunk('exports/tryWaitForAllQuestionMeasurements', async (waitParams, { getState, rejectWithValue }) => {
  console.debug('Called tryWaitForAllQuestionMeasurements', waitParams)
  try {
    const result = await sleep(250, checkAllQuestionsMeasured, getState)
    console.debug('Got try wait for all question measurements result.', result)
    return result
  } catch (error) {
    console.error('tryWaitForAllQuestionMeasurements error.', error)
    return rejectWithValue({ error: true, message: ExportState.Loading })
  }
})

function checkAllQuestionsMeasured (getState) {
  const state = getState()
  console.debug('Checking if all questions measured after delay.', state, getState)
  const anyUnfinished = selectAllExports(state).filter(elem => elem.measured === false).length
  console.debug('Unmeasured count:', anyUnfinished)
  return anyUnfinished ? false : { error: false, data: {} }
}

export const captureAllQuestions = createAsyncThunk('exports/captureAllQuestions', async (captureParams, { rejectWithValue, signal, dispatch }) => {
  console.debug('Called captureAllQuestions', captureParams)
  let iteration = 0
  while (!(iteration > maxIterations)) {
    try {
      const capturedPromise = dispatch(tryWaitForAllQuestionCaptures(captureParams))
      signal.addEventListener('abort', () => {
        console.info('Canceling captureAllQuestions promise from abort signal.', capturedPromise, captureParams)
        capturedPromise?.abort()
      })
      const captureResultAction = await capturedPromise
      const result = unwrapResult(captureResultAction)
      if (result) {
        return result
      }
    } catch (err) {
      console.warn('Capture all questions promise unwrap error.', err)
    }
    iteration += 1
    if (signal.aborted) {
      console.warn('captureAllQuestions cancelled via signal after iterations.', iteration, signal, captureParams)
      return rejectWithValue({ error: true, message: ExportState.Cancelled })
    }
  }
  return rejectWithValue({
    error: true,
    message: ExportState.Failed,
    details: 'Took too long to screenshot all questions. This could be due to a particularly long assessment, or a very slow computer.'
  })
})

export const tryWaitForAllQuestionCaptures = createAsyncThunk('exports/tryWaitForAllQuestionCaptures', async (waitParams, { getState, rejectWithValue }) => {
  console.debug('Called tryWaitForAllQuestionCaptures', waitParams)
  try {
    const result = await sleep(500, checkAllQuestionsCaptured, getState)
    console.debug('Got try wait for all question captures result.', result)
    return result
  } catch (error) {
    console.error('tryWaitForAllQuestionCaptures error.', error)
    return rejectWithValue({ error: true, message: ExportState.Capturing })
  }
})

function checkAllQuestionsCaptured (getState) {
  const state = getState()
  console.debug('Checking if all questions captured after delay.', state, getState)
  const anyUnfinished = selectAllExports(state).filter(elem => elem.captured === false).length
  console.debug('Count not captured:', anyUnfinished)
  return anyUnfinished ? false : { error: false, data: {} }
}

export const renderAllQuestions = createAsyncThunk('exports/renderAllQuestions', async (renderParams, { getState, rejectWithValue, signal, dispatch }) => {
  console.debug('Called renderAllQuestions', renderParams)
  const state = getState()
  const pages = getPDFPages(selectAllExports(state).map(elem => ({ ...elem })), renderParams)
  console.debug('Pages parsed.', pages)
  try {
    const renderPromise = dispatch(createPDF({ renderParams, pages }))
    signal.addEventListener('abort', () => {
      console.info('Canceling createPDF promise from abort signal.', renderPromise, renderParams)
      renderPromise?.abort()
    })
    const renderResultAction = await renderPromise
    const result = unwrapResult(renderResultAction)
    return { error: false, data: result }
  } catch (err) {
    console.warn('CreatePDF promise unwrap error.', err)
    return rejectWithValue({ error: true, message: ExportState.Rendering })
  }
})

export const createPDF = createAsyncThunk('exports/createPDF', async (pdfParams, { rejectWithValue, signal, dispatch }) => {
  const { renderParams, pages } = pdfParams
  console.debug('Called createPDF', renderParams, pages, pdfParams)
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4', hotfixes: ['px_scaling'], compress: true, putOnlyUsedFonts: true }) // Maybe TODO { encryption: { userPassword: '', ownerPassword: generateRandomString(), userPermissions: [] } } - userPermissions can include or exclude 'print' || 'modify' || 'copy' || 'annot-forms'
  console.debug('PDF object created', pdf.getCurrentPageInfo(), pdf)
  let pageNumber = 0
  const asyncArgs = []
  for (const pageInfo of pages) {
    if (pageNumber === 0) {
      console.debug('Skipping auto-created first pdf page.', pageInfo)
    } else {
      console.debug('Adding pdf page.', pageInfo)
      pdf.addPage('a4', 'portrait')
    }
    pageNumber += 1

    const [padding, pageHeaderPadding, page] = pageInfo
    let pageOffset = pageHeaderPadding
    for (const slice of page) {
      const [, , sliceHeight] = slice
      asyncArgs.push({ slice, pageNumber, pageOffset })
      pageOffset += sliceHeight
      pageOffset += padding
    }
  }
  console.debug('Pages created, dispatching render functions.', asyncArgs, pdf.getCurrentPageInfo(), pages, pdf)
  if (signal.aborted) {
    console.warn('createPDF cancelled via signal after parsing pages.', signal, pdfParams)
    return rejectWithValue({ error: true, message: ExportState.Cancelled })
  }
  try {
    const allResults = asyncArgs.map(elem => dispatch(renderQuestion({ ...elem, id: elem.slice[0].id, pdf: pdf, renderParams: renderParams }))) //  Promise.all([dispatch(initGame()), dispatch(initOnboarding())])
    console.debug('Adding cancel signal listener to all promises.')
    signal.addEventListener('abort', () => {
      console.info('Canceling createPDF-renderQuestion promises from abort signal.', allResults.length, allResults, renderParams)
      for (const resultDispatch of allResults) {
        resultDispatch.abort()
      }
    })
    if (signal.aborted) {
      console.warn('createPDF cancelled via signal after dispatching results.', signal, allResults, pdfParams)
      return rejectWithValue({ error: true, message: ExportState.Cancelled })
    }
    console.debug('Awaiting all results.')
    const allResultsPromise = await Promise.all(allResults)
    console.debug('Print to pdf results awaited.', allResultsPromise)
    const errors = []
    const results = []
    for (const resultPromise of allResultsPromise) {
      try {
        const result = unwrapResult(resultPromise)
        console.debug('Individual question render result.', result)
        results.push(result)
      } catch (error) {
        console.error('Render individual questions solo result error.', error)
        errors.push(error)
      }
    }
    console.debug('All question render successCount | errorCount | results | errors', results.length, errors.length, results, errors)
    addPDFTitleFont(pdf)
    console.debug('Added title font to pdf.')
    const exportParams = { ...defaultConfig, ...(renderParams ?? {}) }
    if (exportParams.displayCorrectAnswers || exportParams.displayAnswerScores) {
      console.debug('Marking pdf confidential.', exportParams) // TODO encryption options above?
      await markPDFConfidential(pdf, pages.length)
    }
    const { title, subject, filename, author, keywords, creator, features, fullColor, displayPageNumbers } = exportParams
    await markPDFPages(pdf, displayPageNumbers, pages.length, title, features, fullColor)
    console.debug('Adding PDF outline.', pages.length, title)
    makePDFOutline(pdf, pages, title)
    console.debug('All drawing complete.', results)
    pdf.setDisplayMode('fullwidth', 'continuous', null) //  'UseOutlines' || 'UseThumbs' || 'FullScreen' || 'UseNone'
    pdf.setDocumentProperties({ title, subject, author, keywords, creator }) // TODO pass params title subject author: 'UserName' keywords: 'confidential, correct, etc', creator: 'HireScore'
    console.info('PDF properties set - saving.', exportParams)
    await pdf.save(filename)
    console.debug('PDF saved.', filename)
    return { error: !!errors.length, data: results, message: ExportState.Succeeded }
  } catch (error) {
    console.error('Render individual questions batch error.', error)
    return rejectWithValue({ error: true, message: ExportState.Rendering })
  }
})

export const renderQuestion = createAsyncThunk('exports/renderQuestion', async (questionParams, { rejectWithValue }) => {
  const { slice, pageNumber, pageOffset, id, pdf } = questionParams
  console.debug('Called renderQuestion', id, questionParams)
  const [exportData, sliceOffset, sliceHeight, sliceWidth] = slice
  const pixels = exportData.data ?? null
  const maxPageWidth = getMaxPDFPageWidth()

  if (!pixels || (pixels === true) || (pixels.length === 0)) {
    console.error('Did not find pixel data for question id', id, slice, pixels, exportData)
    await drawExportMissingPlaceholder(pdf, pageNumber ?? 1, maxPageWidth, sliceWidth ?? 0, pageOffset ?? 0)
    return rejectWithValue({ error: true, id: id, message: ExportState.Failed, details: ExportState.Capturing })
  }
  if (!sliceWidth || !sliceHeight) {
    console.error('No image slice width or height for question id - skipping render', id, slice)
    await drawExportMissingPlaceholder(pdf, pageNumber ?? 1, maxPageWidth, sliceWidth ?? 0, pageOffset ?? 0)
    return rejectWithValue({ error: true, id: id, message: ExportState.Failed, details: ExportState.Loading })
  }
  const newWidth = sliceWidth / maxScaleFactor
  const newHeight = sliceHeight / maxScaleFactor
  console.debug('Creating canvas')
  const targetCanvas = document.createElement('canvas')
  targetCanvas.width = sliceWidth
  targetCanvas.height = sliceHeight
  const ctx = targetCanvas.getContext('2d')
  ctx.imageSmoothingQuality = 'high'
  console.debug('Putting pixels into canvas', sliceWidth, sliceHeight, newWidth, newHeight)
  try {
    const sliceImageData = new Image()
    await new Promise(resolve => {
      sliceImageData.crossOrigin = 'anonymous'
      sliceImageData.onload = resolve
      sliceImageData.src = pixels
    })
    console.debug('Awaited sliceImageData', sliceImageData)
    ctx.drawImage(sliceImageData, 0, sliceOffset, sliceWidth, sliceHeight, 0, 0, sliceWidth, sliceHeight)
    console.debug('Pulling cropped sliceImageData from canvas context')
    const canvasUrl = targetCanvas.toDataURL('image/png')
    console.debug('Setting PDF page', pageNumber)
    pdf.setPage(pageNumber)
    console.debug('Painting data', canvasUrl)
    await pdf.addImage(
      canvasUrl,
      'PNG',
      Math.max(((maxPageWidth - sliceWidth) / maxScaleFactor) / 2, 0),
      pageOffset / maxScaleFactor,
      newWidth,
      newHeight,
      undefined,
      'FAST'
    )
    return { error: false, id: id }
  } catch (error) {
    console.error('Error when rendering question image.', error, id, pageNumber, questionParams)
    await drawExportMissingPlaceholder(pdf, pageNumber ?? 1, maxPageWidth, sliceWidth ?? 0, pageOffset ?? 0)
    return rejectWithValue({ error: true, id: id, message: ExportState.Failed, details: error.message })
  }
})

const exportsSlice = createSlice({
  name: 'exports',
  initialState: initialState,
  reducers: {
    clearAllExports: (state, action) => {
      const { config } = action.payload
      console.info('Clearing all artifacts for new export. old|new|fallback config', state.config, config, defaultConfig)
      state.loadingProgress = 0
      state.captureProgress = 0
      state.renderProgress = 0
      state.rendersMissing = 0
      state.status = ExportState.Initializing
      state.error = null
      state.config = { ...defaultConfig, ...(config ?? defaultConfig) }
      exportsAdapter.removeAll(state)
    },
    clearRenderData: (state, action) => {
      const { numberExports } = action.payload
      console.info('Clearing render-related data.', action, numberExports)
      state.loadingProgress = Math.min(Math.floor((state.loadingProgress / Math.max(numberExports, 1)) * measureSum), measureSum)
      state.captureProgress = Math.min(Math.floor((state.captureProgress / Math.max(numberExports, 1)) * captureSum), captureSum)
      state.renderProgress = Math.min(Math.floor((state.renderProgress / Math.max(numberExports, 1)) * renderSum), renderSum)
      state.rendersMissing = Math.min(Math.floor((state.rendersMissing / Math.max(numberExports, 1)) * totalSum), totalSum)
      exportsAdapter.removeAll(state)
    },
    questionData: (state, action) => {
      const { id, width, height, offset, index, data, error } = action.payload
      const exportEntity = state.entities[id]
      console.debug('Export segment loaded and captured.', !!exportEntity, id, action.payload)
      if (exportEntity?.id) {
        console.debug('Updating existing data.', id)
        exportEntity.width = width ?? 0
        exportEntity.height = height ?? 0
        exportEntity.offset = offset ?? 0
        exportEntity.index = index ?? -2
        if (exportEntity.measured === false) {
          state.loadingProgress = state.loadingProgress + 1
        }
        exportEntity.measured = (width || height || offset) ? true : null
        exportEntity.data = data
        if (exportEntity.captured === false) {
          state.captureProgress = state.captureProgress + 1
        }
        if (error) {
          exportEntity.captured = null
          exportEntity.error = error
        } else {
          exportEntity.captured = true
        }
      } else {
        console.error('No data already loaded for loaded export id.', id)
      }
    },
    questionLoaded: (state, action) => {
      const { id, width, height, offset, index } = action.payload
      const exportEntity = state.entities[id]
      console.debug('Export segment loaded.', !!exportEntity, id, action.payload)
      if (exportEntity?.id) {
        console.debug('Updating existing data.', id)
        exportEntity.width = width ?? 0
        exportEntity.height = height ?? 0
        exportEntity.offset = offset ?? 0
        exportEntity.index = index ?? -2
        if (exportEntity.measured === false) {
          state.loadingProgress = state.loadingProgress + 1
        }
        exportEntity.measured = (width || height || offset) ? true : null
      } else {
        console.error('No data already loaded for loaded export id.', id)
      }
    },
    questionCaptured: (state, action) => {
      const { id, data, error } = action.payload
      const exportEntity = state.entities[id]
      console.debug('Export segment captured.', !!exportEntity, id, action.payload)
      if (exportEntity?.id) {
        console.debug('Updating existing data.', id)
        exportEntity.data = data
        if (exportEntity.captured === false) {
          state.captureProgress = state.captureProgress + 1
        }
        if (error) {
          exportEntity.captured = null
          exportEntity.error = error
        } else {
          exportEntity.captured = true
        }
      } else {
        console.error('No data already loaded for captured export id.', id)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        if (state.status === ExportState.Initializing) {
          console.debug('exports slice builder fulfilled', state, action)
          const questions = action.payload.questions ?? []
          const exportEntities = questions.map(elem => (
            { ...defaultExport, id: (elem.id ?? 0), index: (elem.location?.indexInAssessment ?? -2) }
          ))
          exportsAdapter.upsertMany(state, exportEntities)
        } else if (state.status !== ExportState.Idle) {
          console.warn('Loaded assessment with unexpected export state - not updating.', state.status, action)
        }
      })
      .addCase(fetchPublishedAssessmentQuestions.rejected, (state, action) => {
        console.info('exports slice builder rejected', state, action)
        if (state.status === ExportState.Initializing) {
          state.status = ExportState.Failed
          state.error = ExportState.Initializing
        } else if (state.status !== ExportState.Idle) {
          console.warn('Loaded assessment rejected with unexpected export state - not updating.', state.status, action)
        }
      })
      .addCase(measureAllQuestions.pending, (state, action) => {
        console.debug('Measure all questions pending.', state.status, action)
        if (state.status !== ExportState.Initializing) {
          console.error('Unexpected status when beginning measure process - overwriting.', state.status)
        }
        state.status = ExportState.Loading
      })
      .addCase(measureAllQuestions.rejected, (state, action) => {
        console.info('Measure all questions rejected.', state.status, action)
        if (state.status !== ExportState.Loading) {
          console.error('Unexpected status when measure process rejected - overwriting.', state.status)
        }
        state.status = ExportState.Failed
        state.error = ExportState.Loading
      })
      .addCase(captureAllQuestions.pending, (state, action) => {
        console.debug('Capture all questions pending.', state.status, action)
        if (state.status !== ExportState.Loading) {
          console.error('Unexpected status when beginning capture process - overwriting.', state.status)
        }
        state.status = ExportState.Capturing
      })
      .addCase(captureAllQuestions.rejected, (state, action) => {
        console.info('Capture all questions rejected.', state.status, action)
        if (state.status !== ExportState.Loading) {
          console.error('Unexpected status when capture process rejected - overwriting.', state.status)
        }
        state.status = ExportState.Failed
        state.error = ExportState.Capturing
      })
      .addCase(renderAllQuestions.pending, (state, action) => {
        console.debug('Render all questions pending.', state.status, action)
        if (state.status !== ExportState.Capturing) {
          console.error('Unexpected status when beginning render process - overwriting.', state.status)
        }
        state.status = ExportState.Rendering
      })
      .addCase(renderAllQuestions.rejected, (state, action) => {
        console.info('Render all questions rejected.', state.status, action)
        if (state.status !== ExportState.Rendering) {
          console.error('Unexpected status when render process rejected - overwriting.', state.status)
        }
        state.status = ExportState.Failed
        state.error = ExportState.Rendering
      })
      .addCase(renderQuestion.fulfilled, (state, action) => {
        console.debug('renderQuestion fulfilled.', state.status, action)
        state.renderProgress = state.renderProgress + 1
      })
      .addCase(renderQuestion.rejected, (state, action) => {
        console.warn('renderQuestion rejected.', state.status, action)
        state.renderProgress = state.renderProgress + 1
        state.rendersMissing = state.rendersMissing + 1
      })
      .addCase(exportToPDF.fulfilled, (state, action) => {
        console.debug('exportToPDF fulfilled.', state.status, action)
        if (state.status !== ExportState.Succeeded) {
          console.debug('Status was not already set to Succeeded upon fulfillment - setting.', state.status)
          state.error = null
          state.status = ExportState.Succeeded
        } else {
          console.debug('Succeeded status setting already handled upon fulfillment.', state.status, action)
        }
      })
      .addCase(exportToPDF.rejected, (state, action) => {
        console.warn('exportToPDF rejected.', state.status, action)
        if (state.status !== ExportState.Failed) {
          console.info('Status was not already set to failed upon rejection - setting.', state.status)
          state.error = state.status
          state.status = ExportState.Failed
        } else {
          console.debug('Failed status setting already handled upon rejection.', state.status, action)
        }
      })
  }
})

export const { clearAllExports, clearRenderData, questionData, questionLoaded, questionCaptured } = exportsSlice.actions

export default exportsSlice.reducer

export const { selectAll: selectAllExports, selectById: selectExportById, selectIds: selectExportIds, selectEntities: selectExportsMap, selectTotal: selectExportsTotal } =
  exportsAdapter.getSelectors(state => state.exports)

export const selectDoUpdateDimensions = createSelector(
  [(state) => state.exports.status],
  (status) => (status === ExportState.Loading) || (status === ExportState.Capturing) // && (exportEntity?.measured === false) - Note: useEffect only triggers once anyway - not included to reduce re-renders.
)

export const selectDoCapture = createSelector(
  [selectExportById, (state, questionId) => (state.exports.status === ExportState.Capturing) || (state.exports.status === ExportState.Loading)],
  (exportEntity, isCapturing) => isCapturing && (exportEntity?.captured === false) && (exportEntity?.measured !== false)
)

export const selectExportInProgress = createSelector(
  [(state) => state.exports.status],
  (status) => (status !== ExportState.Idle) && (status !== ExportState.Failed) && (status !== ExportState.Succeeded) && (status !== ExportState.Cancelled)
)

export const measureSum = 10
export const captureSum = 60
export const renderSum = 30
export const totalSum = 100

export const selectMeasureProgress = createSelector(
  [selectExportsTotal, (state) => state.exports.loadingProgress],
  (total, totalMeasured) => Math.min(Math.floor((totalMeasured / Math.max(total, 1)) * measureSum), measureSum)
)

export const selectCaptureProgress = createSelector(
  [selectExportsTotal, (state) => state.exports.captureProgress],
  (total, totalCaptured) => Math.min(Math.floor((totalCaptured / Math.max(total, 1)) * captureSum), captureSum)
)

export const selectRenderProgress = createSelector(
  [selectExportsTotal, (state) => state.exports.renderProgress],
  (total, totalRendered) => Math.min(Math.floor((totalRendered / Math.max(total, 1)) * renderSum), renderSum)
)

export const selectMissingRenders = createSelector(
  [selectExportsTotal, (state) => state.exports.rendersMissing],
  (total, rendersMissing) => Math.min(Math.floor((rendersMissing / Math.max(total, 1)) * totalSum), totalSum)
)

export const selectPrintFullColor = createSelector(
  [(state) => state.exports.config],
  (config) => config?.fullColor ?? false
)
