import { Button } from '@mantine/core';
import { IconChevronsLeft } from '@tabler/icons-react';
import React from 'react';
import PropTypes from 'prop-types';

export function GoBackButton ({ href, ...props }) {
  const back = () => window.history.back()
  const goTo = (href) => { window.location.href = href }

  return (
    <Button
      leftSection={<IconChevronsLeft />}
      color='gray'
      onClick={() => {
        if (props.to) return
        href ? goTo(href) : back()
      }}
      {...props}
  >
      Go Back
    </Button>
  )
}

GoBackButton.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string
}
