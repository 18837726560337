import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import CookieBox from './core/CookieBox'
import HomeTable from './hire/view/HomeTable'
import DetailTable from './hire/view/DetailTable'
import MarketingApp from './marketing/MarketingApp'
import Interview from './pdf/Interview/Interview'
import KeySurveyInviteApp from './keySurvey/KeySurveyInviteApp'
import CustomExportApp from './hire/cycle/CustomExportApp'
import CycleFiles from './hire/file/CycleFiles'
import ApplicantFiles from './hire/file/ApplicantFiles'
import SkillbuilderCyclesApp from './skillbuilder/SkillbuilderCyclesApp'
import AccountApp from './account/AccountApp'
import { DetailViewApp } from './hire/cycle/DetailViewApp'
import ProfileControls from './layout/topbar/ProfileControls'
import Notification from './core/Notification'
import UserApp from './account/user/UserApp'
import BatteryScoreApp from './hire/bvi/battery/ScoreApp'
import ProfileScoreApp from './hire/bvi/profile/ScoreApp'
import CompetencyEditApp from './skillbuilder/CompetencyEditApp'
import Publish from './build/assessment/Publish'
import Export from './build/assessment/Export'
import SkillBuilderExport from './skillbuilder/Export'
import MediaApp from './build/assessment/MediaApp'
import { SBCustomExport } from './skillbuilder/SBCustomExport';
import { PublishedAssessmentApp } from './assessment/PublishedAssessmentApp'
import { LivePDFView } from './build/assessment/LivePDFView';
import HiringTemplateDetailWrapper from './build/hiring_template/HiringTemplateDetailWrapper'
import { ApplicantCardWrapper } from './hire/cycle/ApplicantCard/ApplicantCard'
import RequireAuthentication from './security/RequireAuthentication'
import CycleManagementDetailWrapper from './hire/cycle/CycleManagementDetailWrapper'
import Content from './layout/Content'
import { CycleInviteApp } from './hire/cycle/invites/CycleInviteApp';

const ReactAppContainer = document.getElementById('react-app-container')
ReactAppContainer && createRoot(ReactAppContainer).render(<App />)

const cookieContainer = document.getElementById('cookie-container')
cookieContainer && createRoot(cookieContainer).render(<CookieBox />)

const hiringViewHomeContainer = document.getElementById('hiring-view-home-container')
hiringViewHomeContainer && createRoot(hiringViewHomeContainer).render(<HomeTable />)

const hiringViewDetailContainer = document.getElementById('hiring-view-detail-container')
hiringViewDetailContainer && createRoot(hiringViewDetailContainer).render(<DetailTable />)

const marketingContainer = document.getElementById('marketing-container')
marketingContainer && createRoot(marketingContainer).render(<MarketingApp />)

const interviewContainer = document.getElementById('interview-container')
interviewContainer && createRoot(interviewContainer).render(<Interview />)

const DetailViewAppContainer = document.getElementById('detail-view-app-container')
DetailViewAppContainer && createRoot(DetailViewAppContainer).render(<DetailViewApp />)

const keysurveyInviteContainer = document.getElementById('keysurvey-invite-container')
keysurveyInviteContainer && createRoot(keysurveyInviteContainer).render(<KeySurveyInviteApp />)

const CustomExportAppContainer = document.getElementById('custom-export-app-container')
CustomExportAppContainer && createRoot(CustomExportAppContainer).render(<CustomExportApp/>)

const ApplicantFilesContainer = document.getElementById('applicant-files-container')
ApplicantFilesContainer && createRoot(ApplicantFilesContainer).render(<Content><ApplicantFiles/></Content>)

const CycleFilesContainer = document.getElementById('cycle-files-container')
CycleFilesContainer && createRoot(CycleFilesContainer).render(<Content><CycleFiles /></Content>)

const SkillbuilderCyclesContainer = document.getElementById('skillbuilder-cycles-container')
SkillbuilderCyclesContainer && createRoot(SkillbuilderCyclesContainer).render(<SkillbuilderCyclesApp />)

const AccountAppContainer = document.getElementById('account-app-container')
AccountAppContainer && createRoot(AccountAppContainer).render(<AccountApp />)

const ProfileControlsContainer = document.getElementById('profile-controls-container')
ProfileControlsContainer && createRoot(ProfileControlsContainer).render(<ProfileControls />)

const NotificationContainer = document.getElementById('react-notification-container')
NotificationContainer && createRoot(NotificationContainer).render(<Notification />)

const ClientUserAppContainer = document.getElementById('client-user-app-container')
ClientUserAppContainer && createRoot(ClientUserAppContainer).render(<UserApp />)

const BatteryScoreAppContainer = document.getElementById('battery-score-app-container')
BatteryScoreAppContainer && createRoot(BatteryScoreAppContainer).render(<BatteryScoreApp />)

const ProfileScoreAppContainer = document.getElementById('profile-score-app-container')
ProfileScoreAppContainer && createRoot(ProfileScoreAppContainer).render(<ProfileScoreApp />)

const CompetencyEditAppContainer = document.getElementById('competency-edit-app-container')
CompetencyEditAppContainer && createRoot(CompetencyEditAppContainer).render(<CompetencyEditApp />)

const PublishAssessmentContainer = document.getElementById('publish-assessment-container')
PublishAssessmentContainer && createRoot(PublishAssessmentContainer).render(<Publish />)

const ExportAssessmentContainer = document.getElementById('export-assessment-container')
ExportAssessmentContainer && createRoot(ExportAssessmentContainer).render(<Export />)

const SBCustomExportAppContainer = document.getElementById('sb-custom-export-app-container')
SBCustomExportAppContainer && createRoot(SBCustomExportAppContainer).render(<SBCustomExport />)

const ExportSkillBuilderAssessmentContainer = document.getElementById('export-skillbuilder-assessment-container')
ExportSkillBuilderAssessmentContainer && createRoot(ExportSkillBuilderAssessmentContainer).render(<SkillBuilderExport />)

const mediaAppContainer = document.getElementById('media-app-container')
mediaAppContainer && createRoot(mediaAppContainer).render(<MediaApp />)

const publishedAssessmentAppContainer = document.getElementById('published-assessment-app-container')
publishedAssessmentAppContainer && createRoot(publishedAssessmentAppContainer).render(<PublishedAssessmentApp />)

const livePDFViewAppContainer = document.getElementById('export-live-pdf-view-assessment-container')
livePDFViewAppContainer && createRoot(livePDFViewAppContainer).render(<LivePDFView />)

const HiringTemplateDetailContainer = document.getElementById('hiring-template-detail-container')
HiringTemplateDetailContainer && createRoot(HiringTemplateDetailContainer).render(<HiringTemplateDetailWrapper />)

const CycleManagementDetailContainer = document.getElementById('cycle-management-detail-container')
CycleManagementDetailContainer && createRoot(CycleManagementDetailContainer).render(<CycleManagementDetailWrapper />)

const cycleInviteAppContainer = document.getElementById('cycle-invite-app-container')
cycleInviteAppContainer && createRoot(cycleInviteAppContainer).render(<CycleInviteApp />)

const RequireAuthenticationContainer = document.getElementById('require-authentication-container')
RequireAuthenticationContainer && createRoot(RequireAuthenticationContainer).render(<RequireAuthentication />)

const ApplicantInfoContainer = document.getElementById('applicant-card')
ApplicantInfoContainer && createRoot(ApplicantInfoContainer).render(<ApplicantCardWrapper />)
