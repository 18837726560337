/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import {
  useCycleApplicantsDetailsList,
  useCycleDegrees,
  useCycle,
  useCycleRegions,
  useCycleStatuses,
  useCycleTableModuleInfos
} from './CycleHooks';
import {
  NamespaceContext,
  TableQueryingContext,
  TableRowIdsContext,
  CycleContext
} from '../../core/ReactTable/TableContexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useCycleApplicantsDetailListTable } from './DetailViewHooks';
import { useAccount } from '../../util/Hooks';
import { Box, Center, Grid, Loader, Space } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { CycleDetailViewActionsContainer } from './DetailViewApp';
import { DetailViewSelectedHandler } from './DetailViewSelectedHandler';
import { ApplicantCardFrame } from './ApplicantCard/ApplicantCard';
import { DetailViewStateUpdate, blankDetailViewState, createInitialDetailViewState, detailViewReducer } from './DetailViewState';
import { CleanTable, CleanTableContextsProvider } from '../../core/ReactTable/CleanTable';
import {
  rowsLoaded,
  selectTableActiveRequestId,
  selectTableRowIdsByActiveRequestId
} from '../../core/ReactTable/tableRowsSlice';
import { useSpringGestures } from '../../core/useSpringGestures';

const applicantCardSpan = { md: 12, lg: 4 }

export function CleanDetailViewTableApp () {
  const { cycleId } = useParams()
  const [state, dispatch] = useReducer(detailViewReducer, blankDetailViewState, createInitialDetailViewState)

  const tableSpan = useMemo(() => state.currentApplicant ? { md: 12, lg: 8 } : 12, [state.currentApplicant])

  const handleCardClose = useCallback(() => {
    dispatch({ type: DetailViewStateUpdate.UpdateCurrentApplicant, payload: null })
  }, [])

  const account = useAccount()

  useSpringGestures()

  return (
    <CycleContext.Provider value={cycleId}>
      <Grid align='stretch'>
        <Grid.Col span={tableSpan}>
          <DetailViewTableActionsBar />
          <DetailViewSelectedHandler account={account} />
          <DetailViewTableLoader cycleId={cycleId} account={account} dispatch={dispatch} />
        </Grid.Col>
        {!!state.currentApplicant &&
          <Grid.Col span={applicantCardSpan}>
            <Box>
              <ApplicantCardFrame applicantId={state.currentApplicant} cid={cycleId} simple={false} onClose={handleCardClose} />
            </Box>
          </Grid.Col>
        }
      </Grid>
    </CycleContext.Provider>
  )
}

export const DetailViewTableActionsBar = memo(function DetailViewTableActionsBar () {
  // TODO [detail rework] verified first script?
  return (
    <div className='detail-view'>
      <div style={{ float: 'right', height: 0, overflow: 'visible' }}>
        <CycleDetailViewActionsContainer containerName='react-app-container'/>
      </div>
    </div>
  )
})

const DetailViewTableLoader = memo(function DetailViewTableLoader ({ cycleId, account, dispatch }) {
  const [moduleInfos, modulesRefreshing] = useCycleTableModuleInfos(cycleId)
  const [statuses, statusesRefreshing] = useCycleStatuses(cycleId)
  const [regions, regionsRefreshing] = useCycleRegions(cycleId)
  const [educationOptions, educationOptionsRefreshing] = useCycleDegrees(cycleId)
  const [cycleDescription, descriptionRefreshing] = useCycle(cycleId)

  const anyLoading = modulesRefreshing || !account.email || statusesRefreshing || regionsRefreshing || educationOptionsRefreshing || descriptionRefreshing

  const statusTiers = useMemo(() => {
    if (!statuses?.items) {
      return []
    }
    const tiersMap = new Map()
    for (const status of statuses.items) {
      if (status.tier) {
        tiersMap.set(status.tier.id, status.tier)
      } else {
        console.warn('Did not find tier where expected in status.', { status, statuses })
      }
    }
    return [...tiersMap.values()]
  }, [statuses])

  return (
    <>
      {!!anyLoading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!anyLoading && (
        <DetailViewTable
          cycleId={cycleId}
          account={account}
          moduleInfos={moduleInfos}
          statuses={statuses}
          statusTiers={statusTiers}
          regions={regions}
          cycleDescription={cycleDescription}
          educationOptions={educationOptions}
          dispatch={dispatch}
        />
      )}
    </>
  )
})

const DetailViewTable = memo(function DetailViewTable (
  {
    cycleId,
    account,
    moduleInfos,
    statuses,
    statusTiers,
    regions,
    cycleDescription,
    educationOptions,
    namespace = 'cycle-detail-view',
    dispatch
  }
) {
  // const [applicants] = useLazyCycleApplicantsDetailsList(namespace, cycleId)
  // const selected = useSelector(state => selectAllSelectedKeys(state, namespace))

  const handleRowClicked = useCallback((rowId) => {
    console.debug('Detail view table row clicked.', rowId)
    if (!rowId) {
      console.warn('Unable to find applicant matching clicked id.', { rowId, cycleId })
      return true
    }

    console.debug('Updating selection for applicant card', { cycleId, rowId })
    dispatch({ type: DetailViewStateUpdate.UpdateCurrentApplicant, payload: rowId })
  }, [cycleId, dispatch])

  const { columns, defaultFilters, defaultHiddenColumns, abstractOrderVariants, ...otherTableProps } = useCycleApplicantsDetailListTable(
    namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, cycleDescription, educationOptions
  )

  return (
    <CleanTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
      abstractOrderVariants={abstractOrderVariants}
    >
      <CycleDetailTableDataProvider cycleId={cycleId}>
        <CleanTable
          {...otherTableProps}
          abstractOrderVariants={abstractOrderVariants} // TODO [long term/order variants] refactor into redux state or provider
          onRowClick={handleRowClicked}
          columns={columns}
        />
      </CycleDetailTableDataProvider>
    </CleanTableContextsProvider>
  )
})

function CycleDetailTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const dispatch = useDispatch()
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const activeRequestId = useSelector(state => selectTableActiveRequestId(state, namespace))
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  const [collection, queryRunning, , requestId] = useCycleApplicantsDetailsList(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded

  useEffect(() => { // Necessary as there's no good way to hook into loading from cache.
    console.debug('Cached table request sync effect running', { requestId, activeRequestId, collection })
    if (requestId && activeRequestId && collection && (requestId !== activeRequestId)) {
      console.info('CycleDetailTableDataProvider request id changed', { requestId, activeRequestId })
      dispatch(rowsLoaded({ namespace: namespace, data: collection, requestId: requestId }))
    }
  }, [requestId, activeRequestId, collection, namespace, dispatch])

  console.debug('Cycle detail table data provider updated.', { rowIds, collection, querying, namespace, cycleId, requestId, activeRequestId })
  return (
    <TableRowIdsContext.Provider value={rowIds}>
      <TableQueryingContext.Provider value={!!(querying || ((requestId !== activeRequestId) && requestId && activeRequestId))}>
        {children}
      </TableQueryingContext.Provider>
    </TableRowIdsContext.Provider>
  )
}
