import axios from 'axios'

export function getSkillbuilderCycles (data = {}) {
  return axios({
    method: 'get',
    url: 'api/v1/skillbuilder/cycles',
    params: data
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      return false
    })
}

export function getLocations (data = {}) {
  return axios({
    method: 'get',
    url: 'api/v1/skillbuilder/locations',
    params: data
  })
    .then(response => response.data)
}

export function getSkillBuilderCycleAssessment (cycleId, assessmentId, params = null, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/skillbuilder/cycles/${cycleId}/assessments/${assessmentId}`
  }
  if (params) {
    config.params = params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios cycle assessment data request via token.', config)
      } else {
        console.error('Cycle assessment data request error.', err, config)
        throw err
      }
    })
}

export function getSkillBuilderLivePDFViewAssessment (cycleId, assessmentId, params = null, cancelToken = null) {
  const config = {
    method: 'POST',
    url: `/skillbuilder/assessments/${assessmentId}/cycles/${cycleId}/pdfview/portal`
  }
  if (params) {
    config.data = params
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios assessment data request via token.', config)
      } else {
        console.error('LivePDFViewAssessment data request error.', err, config)
        throw err
      }
    })
}

export function exportSkillBuilderCycleAssessment (cycleId, assessmentId, json) {
  return axios({
    method: 'POST',
    url: `api/v1/skillbuilder/cycles/${cycleId}/assessments/${assessmentId}/export`,
    data: json,
    responseType: 'arraybuffer'
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function exportSkillBuilderAssessmentLivePDFView (cycleId, assessmentId, json) {
  return axios({
    method: 'POST',
    url: `/skillbuilder/assessments/${assessmentId}/cycles/${cycleId}/pdfview/download`,
    data: json,
    responseType: 'arraybuffer'
  })
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getSkillBuilderJobsForClient (clientId, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/clients/${clientId}/jobs`
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios SkillBuilder jobs request via token.', config)
      } else {
        console.error('SkillBuilder jobs request error.', err)
        throw err
      }
    })
}

export function getSkillBuilderFacilitators (clientId, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/clients/${clientId}/sb-managers`
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios SkillBuilder facilitators request via token.', config)
      } else {
        console.error('SkillBuilder facilitators request error.', err)
        throw err
      }
    })
}

export function getSkillBuilderFacilitatorsForLocation (locationId, cancelToken = null) {
  const config = {
    method: 'GET',
    url: `api/v1/locations/${locationId}/sb-managers`
  }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) {
        console.info('Cancelled axios SkillBuilder location facilitators request via token.', config)
      } else {
        console.error('SkillBuilder location facilitators request error.', err)
        throw err
      }
    })
}

export function requestSBCustomExport (data) {
  return axios({
    method: 'POST',
    url: window.location.pathname,
    data: data,
    responseType: 'arraybuffer'
  })
    .catch(err => {
      console.error('Error posting export form data.', err, data)
      throw err
    })
}

export function getTrainingUser (accountId) {
  return axios({
    method: 'GET',
    url: `/api/v1/skillbuilder/training-users/${accountId}`
  }).then(response => response.data)
}
