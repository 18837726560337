export default function Styles (options) {
  return {
    page: {
      position: 'relative',
      paddingHorizontal: options.padding.horizontal + options.padding.unit,
      paddingVertical: options.padding.vertical + options.padding.unit,
      fontFamily: options.font.styles.normal,
      display: 'flex'
    },
    pageHeader: {
      fontSize: options.font.header.size,
      fontFamily: options.font.styles.bold
    },
    pageNumber: {
      position: 'absolute',
      fontSize: options.font.size,
      right: options.padding.horizontal + options.padding.unit,
      top: options.padding.vertical + options.padding.unit,
      fontFamily: 'Times-Roman'
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 8,
      position: 'absolute',
      bottom: options.padding.vertical / 2 + options.padding.unit,
      width: 'auto',
      textAlign: 'center',
      alignSelf: 'center',
      fontFamily: 'Times-Roman'
    },
    footerText: {
      textAlign: 'center'
    },
    blankLine: {
      height: options.font.size,
      borderBottomStyle: 'solid',
      borderBottomColor: 'black',
      borderBottomWidth: 1,
      float: 'right',
      marginLeft: options.font.size * 0.5
    },
    checkboxWrapper: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: options.font.size,
      alignItems: 'center',
      marginTop: options.font.size
    },
    checkbox: {
      width: options.font.size * 2,
      height: options.font.size * 2,
      backgroundColor: '#fefefe',
      marginRight: options.font.size / 2,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'black'
    },
    htmlStyles: {
      strong: {
        fontFamily: 'Helvetica-Bold'
      },
      em: {
        fontFamily: 'Helvetica-Oblique'
      },
      p: {
        fontFamily: 'Helvetica',
        fontSize: options.font.size
      },
      span: {
        fontFamily: 'Helvetica'
      }
    },
    downloadButton: {
      padding: 10,
      backgroundColor: '#2DB1FF',
      color: 'white',
      borderRadius: 5,
      fontSize: 16,
      fontWeight: 'bold'
    }
  }
}
