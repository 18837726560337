import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from './../../react/layout/marketing/Footer'
import ReferralInvite from './ReferralInvite'
import Signup from './Signup'
import { AppShell, Box, Group } from '@mantine/core'
import Logo from './Logo'
import Content from '../layout/Content'

export default function MarketingApp () {
  return (
    <Content>
      <BrowserRouter>
        <AppShell header={{ height: 60 }}>
          <AppShell.Header>
          <Group justify='space-between' align='center' px='lg'>
            <Logo />
            <Box>
              <span>Need help? <i className='fa fa-phone'></i> <a href='tel:9062262829'>(906) 226-2829</a></span>
            </Box>
          </Group>
          </AppShell.Header>
          <AppShell.Main>
              <Routes>
                <Route path='/invite' element={<ReferralInvite />} />
                <Route path='/signup' element={<Signup />} />
              </Routes>
          </AppShell.Main>
          <AppShell.Footer>
            <Footer />
          </AppShell.Footer>
        </AppShell>
      </BrowserRouter>
    </Content>
  )
}
