/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Highlight as HighlightExtension } from '@tiptap/extension-highlight';
import TextStyle from '@tiptap/extension-text-style';
import { useEditor } from '@tiptap/react';
import { RichTextEditor } from '@mantine/tiptap';

export function useDetailViewTextEditor (applicantId, content, onSave) {
  const timeoutId = useRef(null)
  const applicantIdRef = useRef(applicantId)
  const contentRef = useRef(content)
  const onSaveRef = useRef(onSave)
  const defaultContentRef = useRef(null)

  console.debug('Detail view text editor hook updating', { applicantId, content })

  const onBlur = useCallback(({ editor, event }) => {
    const currentContent = editor?.getHTML()
    console.debug('Edit onBlur updating applicant content.', currentContent)
    if (currentContent !== undefined) {
      if (!defaultContentRef.current || currentContent !== defaultContent) {
        window.clearTimeout(timeoutId.current)
        defaultContentRef.current = null
        onSaveRef.current(currentContent)
      } else {
        editor?.commands.setContent(defaultContentRef.current ?? defaultContent, false)
      }
    }
  }, [])

  const onUpdate = useCallback(({ editor }) => {
    const currentContent = editor?.getHTML()
    window.clearTimeout(timeoutId.current)
    timeoutId.current = window.setTimeout(() => {
      const newCurrentContent = editor?.getHTML()
      console.debug('Edit onUpdate timeout callback checking whether to update applicant content.', currentContent, newCurrentContent)
      if ((currentContent !== undefined) && (currentContent === newCurrentContent)) {
        console.debug('Edit onUpdate timeout callback triggering applicant update.', currentContent)
        onSaveRef.current(currentContent)
      }
    }, 3000);
  }, [])

  const onFocus = useCallback(({ editor }) => {
    console.warn('On detail view text editor focus called for applicant.', applicantId)
    const existingContent = editor?.getHTML()
    if (existingContent === defaultContent) {
      console.warn('Was default content - clearing.', existingContent)
      defaultContentRef.current = existingContent
      editor.commands.clearContent()
    } else {
      defaultContentRef.current = null
    }
  }, [applicantId])

  const extensions = useMemo(() => {
    return [
      StarterKit,
      Underline,
      Superscript,
      SubScript,
      HighlightExtension,
      TextStyle
    ]
  }, [])

  const editorOptions = useMemo(() => {
    return {
      extensions: extensions,
      content: content,
      onBlur: onBlur,
      onUpdate: onUpdate,
      onFocus: onFocus,
      autofocus: 'end'
    }
  }, [extensions, onBlur, onUpdate, onFocus, content])

  const editor = useEditor(editorOptions, [])

  useEffect(() => {
    onSaveRef.current = onSave
  }, [onSave])

  const html = editor?.getHTML() ?? null

  useEffect(() => {
    if (applicantId !== applicantIdRef.current) {
      editor?.commands.setContent(content, false)
    } else if (contentRef.current !== content) {
      window.clearTimeout(timeoutId.current)
      if (content !== html) {
        console.debug('Setting detail view text editor content from parent content change.', content)
        editor?.commands.setContent(content, false)
      }
    }
  }, [applicantId, editor?.commands, html, content])

  useEffect(() => {
    applicantIdRef.current = applicantId
  }, [applicantId])

  useEffect(() => {
    contentRef.current = content
  }, [content])

  return editor
}

const defaultContent = '<p></p>'

export const DetailViewTextEditor = memo(function DetailViewTextEditor ({ editor }) {
  console.debug('DetailViewTextEditor updating', { editor })
  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  )
})
