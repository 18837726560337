import { ApiAbstractIds, apiSlice, ApiTags } from '../api';
import { paramsToQuery } from '../util/queries';

export const analyticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHiringAnalytics: builder.query({
      query: (params) => `analytics/hiring${paramsToQuery(params)}`,
      transformResponse: (response) => ({ ...response, items: Object.values(response.items) }),
      providesTags: [{ type: ApiTags.HiringAnalytics, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetHiringAnalyticsQuery } = analyticsApi
