/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from '@mantine/core'
import { isUserSubscribed, subscribe, unsubscribe } from '../../../js/api/cycle_repository'
import { showNotification } from '@mantine/notifications'

export default function CycleSubscribe ({ id, isSubscribed }) {
  const [subscribed, setSubscribed] = useState(isSubscribed)

  useEffect(() => {
    isUserSubscribed(id).then(subbed => setSubscribed(subbed))
  }, [])

  const sub = () => {
    setSubscribed(true)
    subscribe(id)
      .then(() => {
        showNotification({
          message: 'You are now subscribed to this cycle\'s notifications',
          color: 'blue',
          autoClose: 3000
        })
      })
      .catch(() => {
        showNotification({
          message: 'Something went wrong!',
          color: 'red',
          autoClose: 3000
        })
        setSubscribed(false)
      })
  }

  const unsub = () => {
    setSubscribed(false)
    unsubscribe(id)
      .then(() => {
        showNotification({
          message: 'You are now unsubscribed from this cycle\'s notifications',
          color: 'blue',
          autoClose: 3000
        })
      })
      .catch(() => {
        showNotification({
          message: 'Something went wrong!',
          color: 'red',
          autoClose: 3000
        })
        setSubscribed(true)
      })
  }

  return (
      <Tooltip label={`${subscribed ? 'Disable' : 'Enable'} notifications for this cycle`}>
          <Button
            variant={subscribed ? 'outline' : 'filled'}
            size='compact-sm'
            onClick={() => subscribed ? unsub() : sub()}
            >
              {subscribed ? 'Subscribed' : 'Subscribe'}
          </Button>
      </Tooltip>
  )
}
