import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../../core/ReactTable/paramsSlice';
import { apiSlice } from '../../../../api';
import {
  useGetActiveStagesForCycleQuery,
  useGetStageDetailQuery,
  useGetStageResponseDistributionSliceQuery,
  useGetStagesForCycleQuery
} from './CycleStagesApi';
import {
  BLANK_TABLE_ACTIONS,
  CYCLE_STAGE_COLUMNS,
  CYCLE_STAGE_FILTERS,
  DEFAULT_CYCLE_STAGE_FILTERS,
  DEFAULT_HIDDEN_CYCLE_STAGE_COLUMNS
} from '../CycleInviteHooks';

export function useStageDetail (stageId, cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetStageDetailQuery({ stageId, cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleStages (namespace, cycleId, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetStagesForCycleQuery({ ...queryParams, cycleId }, { skip })
  return [collection ?? null, querying]
}

export function useLazyCycleStages (namespace, cycleId) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getStagesForCycle.useQueryState({ ...queryParams, cycleId })
  return [collection ?? null, querying]
}

export function useCycleActiveStages (cycleId, skip = false) {
  const { data: collection, isFetching: querying } = useGetActiveStagesForCycleQuery(cycleId, { skip })
  return [collection ?? null, querying]
}

export function useStageResponseDistributionSlice (namespace, stageId, cycleId, skip = false, page = 1) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  console.debug('useStageResponseDistributionSlice params', { namespace, stageId, cycleId, skip, page, queryParams })
  const { data, isFetching: querying, isLoading: loading } = useGetStageResponseDistributionSliceQuery({ ...queryParams, stageId, cycleId, page }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleStagesTable () {
  return {
    defaultHiddenColumns: DEFAULT_HIDDEN_CYCLE_STAGE_COLUMNS,
    defaultFilters: DEFAULT_CYCLE_STAGE_FILTERS,
    columns: CYCLE_STAGE_COLUMNS,
    filters: CYCLE_STAGE_FILTERS,
    actions: BLANK_TABLE_ACTIONS,
    searchable: true
  }
}
