/* eslint react/prop-types: 0 */
import { useSelector } from 'react-redux';
import { selectParamsFilterField } from '../../core/ReactTable/paramsSlice';
import {
  Anchor,
  Box,
  Button,
  Center,
  Group,
  LoadingOverlay,
  Modal,
  Space,
  ScrollArea,
  Text,
  TypographyStylesProvider,
  Flex
} from '@mantine/core';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { useHover } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEditModuleScoreMutation } from '../../../redux/query/hire/applicantsApi.slice';
import { DetailViewTextEditor, useDetailViewTextEditor } from './DetailViewTextEditor';
import NoticeAlert from '../../core/Alert/NoticeAlert';

export const NOTES_MODULE_ID = 11

export function DetailViewScoreCell ({ value, moduleInfoType, moduleId, precision, namespace, applicantLink, moduleMinScore, mah }) {
  const display = useSelector(state => selectParamsFilterField(state, namespace, 'display'))
  const score = getScoreForDisplayType(value, moduleInfoType, moduleId, display ?? 'percentile')
  const [formattedScore, parsedScore] = formatScoreDisplay(score, precision ?? 0)
  const color = (parsedScore === null) ? 'dark' : (parsedScore < moduleMinScore ? 'red' : 'blue')

  const { hovered, ref } = useHover()

  const props = useMemo(() => {
    const defaultStyles = {
      borderRadius: '0.75rem',
      transition: 'transform 250ms ease-out'
    }

    const hoveredProps = hovered
      ? {
          bg: `${color}.1`,
          p: 'sm',
          fw: 'bold',
          style: {
            ...defaultStyles,
            filter: 'brightness(98%)',
            transform: 'scale(1.25)'
          }
        }
      : {}

    return {
      p: 'xs',
      c: `${color}.6`,
      style: {
        ...defaultStyles,
        borderRadius: '1rem'
      },
      ...hoveredProps
    }
  }, [hovered, color])

  return (
    <Flex justify='center' align='center' h={mah}>
      {(applicantLink && (formattedScore !== '-'))
        ? (
            <Anchor ref={ref} href={applicantLink} target='_blank' fw='500' {...props}>
              {formattedScore}
            </Anchor>
          )
        : (
          <ScrollArea.Autosize mah={mah}>
            <Text ta='center' fw='500' c={color === 'blue' ? 'dark.3' : color}>
              {formattedScore}
            </Text>
          </ScrollArea.Autosize>
          )}
    </Flex>
  )
}

export function formatScoreDisplay (score, maximumDigits = 0) {
  const parsedScore = (score !== '' && score !== '-' && score !== null && score !== undefined) ? parseFloat(score) : null
  const parsedScoreValid = parsedScore !== null && !isNaN(parsedScore)
  const formattedScore = parsedScoreValid ? parsedScore.toLocaleString('en-US', { maximumFractionDigits: maximumDigits }) : score
  return [formattedScore ?? '-', parsedScoreValid ? parsedScore : null]
}

const MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER = 2
const DEFAULT_PERCENTILE_MAGIC_MODULE_INFO_TYPE_IDS = [3, 4]
const DEFAULT_PERCENTILE_MAGIC_MODULE_IDS = [14, 33, 53, 54, 55, 56]

function getScoreForDisplayType (score, moduleInfoTypeId, moduleId, display) {
  switch (display) {
    case 'zscore': {
      return MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER !== moduleInfoTypeId ? score.z_score : score.score
    }
    case 'percentile': {
      return MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER !== moduleInfoTypeId ? formatPercentileScore(score.percentile_score) : score.score
    }
    default: {
      if (DEFAULT_PERCENTILE_MAGIC_MODULE_INFO_TYPE_IDS.includes(moduleInfoTypeId) || DEFAULT_PERCENTILE_MAGIC_MODULE_IDS.includes(moduleId)) {
        return formatPercentileScore(score.percentile_score)
      }
      return score.score
    }
  }
}

function formatPercentileScore (percentileScore) {
  if (percentileScore > 99) {
    return 99
  } else if (percentileScore < 1) {
    return 1
  }

  return percentileScore
}

const CAN_EDIT_INFO_PERMISSION = 1

export function DetailViewNotesScoreCell ({ value, applicantId, cycleId, account, infoPermission, mah }) {
  const [editing, setEditing] = useState(false)
  const content = (value?.score && (value.score !== '-')) ? value.score : ''
  const sanitizedContent = useMemo(() => {
    if (content) {
      return DOMPurify.sanitize(content)
    }
    return ''
  }, [content])

  const [currentContent, setCurrentContent] = useState(sanitizedContent)
  const lastContentRef = useRef(currentContent)

  useEffect(() => {
    if (sanitizedContent !== lastContentRef.current) {
      lastContentRef.current = sanitizedContent
      setCurrentContent(sanitizedContent)
    }
  }, [sanitizedContent])

  const canEdit = account.access.ADMIN || ((infoPermission === CAN_EDIT_INFO_PERMISSION) && account.access.PORTAL_CLIENT)

  console.debug(
    'DetailViewNotesScoreCell updating',
    { applicantId, currentContent, content, editing, account, infoPermission, canEdit }
  )

  return (
    <>
      {editing
        ? (
          <NotesScoreEditor
            applicantId={applicantId}
            cycleId={cycleId}
            content={currentContent}
            setCurrentContent={setCurrentContent}
            setEditing={setEditing}
            mah={mah}
          />
          )
        : (
          <NotesScoreTriggerEditCell sanitizedContent={currentContent} canEdit={canEdit} setEditing={setEditing} mah={mah} />
          )}
    </>
  )
}

const interactiveNotesStyle = { outline: '2px dotted', outlineOffset: '-2px' }

function NotesScoreTriggerEditCell ({ sanitizedContent, canEdit, setEditing, mah }) {
  const { hovered, ref } = useHover()

  const onBoxClick = (event) => {
    if (canEdit) {
      setEditing(true)
    } else {
      notifications.show({
        title: 'Cannot Edit Notes',
        message: 'Sorry! Editing notes has not been enabled for you in this cycle.',
        color: 'yellow',
        autoClose: 7000,
        id: 'no-edit-notes-permission-id'
      })
    }
    event.stopPropagation()
  }

  return (
    <Box ref={ref} onClick={onBoxClick} w='100%' mih={mah} mah={mah} style={(hovered && canEdit) ? interactiveNotesStyle : null}>
      <Box h='100%' w='100%'>
        <NotesStaticContent sanitizedContent={sanitizedContent} mah={mah} />
      </Box>
    </Box>
  )
}

function NotesStaticContent ({ sanitizedContent, mah }) {
  return (
    <>
      {sanitizedContent
        ? (
          <ScrollArea.Autosize mah={mah} scrollbars='y'>
            <TypographyStylesProvider>
              <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </TypographyStylesProvider>
          </ScrollArea.Autosize>
          )
        : (
          <Space h='xl' />
          )}
    </>
  )
}

const notesLoadingOverlayProps = { radius: 'sm', blur: 2 }

function onModalBoxClick (event) {
  console.debug('Stopping edit notes modal on click propagation.', { event })
  event.stopPropagation()
}

function NotesScoreEditor ({ applicantId, cycleId, content, setCurrentContent, setEditing, mah }) {
  const [edit, { isLoading: processing }] = useEditModuleScoreMutation()
  const [localContent, setLocalContent] = useState(content)

  const onEditorSave = useCallback((score) => {
    console.debug('Called on notes score editor save local', { score, localContent })
    if (score !== localContent) {
      setLocalContent(score)
    }
  }, [localContent])

  const editor = useDetailViewTextEditor(applicantId, content, onEditorSave)

  const onModalClose = () => {
    setEditing(false)
  }

  const onModalSave = () => {
    const score = localContent
    if (score !== content) {
      const moduleId = NOTES_MODULE_ID
      console.debug('Updating applicant notes.', { applicantId, cycleId, score, content })
      edit({ cycleId, applicantId, moduleId, score })
        .unwrap()
        .then((response) => {
          console.debug('Got edit notes score response.', { response, applicantId, cycleId, score, content })
          setCurrentContent(score)
          setEditing(false)
          notifications.show({
            title: 'Edit Notes Success',
            message: 'Your notes changes have been saved!',
            color: 'green',
            autoClose: 5000
          })
        })
        .catch((err) => {
          console.error('Got edit notes score response error.', { err, applicantId, cycleId, score, content })
          if (err?.data?.type === 'validation_error' && err?.data.errors?.length) {
            for (const reason of err.data.errors) {
              notifications.show({
                title: 'Edit Notes Error',
                message: `Failed to edit notes: ${reason}`,
                color: 'red',
                autoClose: 7000
              })
            }
          } else {
            notifications.show({
              title: 'Edit Notes Error',
              message: 'Failed to edit notes due to unexpected error.',
              color: 'red',
              autoClose: 7000
            })
          }
        })
    } else {
      console.debug('Skipping applicant notes update.', { applicantId, cycleId, score, content })
      setEditing(false)
    }
  }
  console.debug('NotesScoreEditor updating.', { applicantId, cycleId, content, processing, editor })
  return (
    <>
      <NotesEditInProgressPlaceholder sanitizedContent={content} mah={mah} />
      <Modal opened={true} onClose={onModalClose} onClick={onModalBoxClick} title='Edit Notes' size='xl'>
        <Box onClick={onModalBoxClick}>
          <NoticeAlert title='Edit candidate notes below' />
          <Box pos='relative'>
            <LoadingOverlay visible={processing} zIndex={1000} overlayProps={notesLoadingOverlayProps} />
            <Center>
              <DetailViewTextEditor editor={editor} />
            </Center>
            <Space h='md' />
            <Group justify='center' grow>
              <Button onClick={onModalSave} color='success' loading={processing}>Save</Button>
              <Button onClick={onModalClose} color='gray' loading={processing}>Cancel</Button>
            </Group>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

const NotesEditInProgressPlaceholder = memo(function NotesEditInProgressPlaceholder ({ sanitizedContent, mah }) {
  return (
    <Box onClick={onModalBoxClick} w='100%' mih={mah} mah={mah}>
      <Box h='100%' w='100%'>
        <NotesStaticContent sanitizedContent={sanitizedContent} mah={mah} />
      </Box>
    </Box>
  )
})
