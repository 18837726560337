/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Center, Checkbox, Group, Select, SimpleGrid, Table } from '@mantine/core'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Program ({ form, getSubmissions, cycles, applicants, cycleId = null }) {
  const [lastSubmissions, setLastSubmissions] = useState([])
  const [apCycleId, setApCycleId] = useState(0)

  useEffect(() => () => {
    form.setValues(prev => ({ ...prev, ap_cycle: null, retakes: [] }))
  }, [])

  useEffect(() => {
    if (apCycleId > 0) {
      getSubmissions(apCycleId).then(response => {
        setLastSubmissions(response.data)
      })
    }
  }, [apCycleId, applicants])

  useEffect(() => {
    form.setValues(prev => ({ ...prev, retakes: [] }))

    lastSubmissions.forEach(submission => {
      if (_.find(applicants, a => parseInt(a) === parseInt(submission.id), 0) !== undefined) {
        form.insertListItem('retakes', { submission: submission, retake: true })
      }
    })
  }, [lastSubmissions, applicants])

  console.debug(
    'Program component updating. We will eventually be replacing form ap_cycle with cycle pass invite config.',
    { form, cycleId }
  )

  return (
    <SimpleGrid cols={1}>
      <Group>
        <Select
          label='Battery'
          placeholder='Select a battery to invite to...'
          data={cycles.map(cycle => ({ value: cycle.id.toString(), label: cycle.name }))}
          {...form.getInputProps('ap_cycle')}
          onChange={value => {
            form.setFieldValue('ap_cycle', value)
            setApCycleId(value)
          }}
        />
      </Group>
      {
        !_.isEmpty(form.values.retakes) &&
        <Table verticalSpacing='xs'>
          <caption>The following applicants have already taken the selected battery of assessments</caption>
          <thead>
          <tr>
            <th>Applicant</th>
            <th>Submitted At</th>
            <th>Assessment Status</th>
            <th>Retake</th>
          </tr>
          </thead>
          <tbody>
          {
            form.values.retakes.map((retake, index) => (
              <tr key={`last-submission-${retake.submission.id}`}>
                <td>{retake.submission.email}</td>
                <td>{typeof retake.submission.submitted_at !== 'undefined' ? dayjs(retake.submission.submitted_at).format('MMMM Do YYYY, h:mm a') : 'Never completed'}</td>
                <td>{retake.submission.status}</td>
                <td><Center><Checkbox{...form.getInputProps(`retakes.${index}.retake`, { type: 'checkbox' })}/></Center></td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      }
    </SimpleGrid>
  )
}
