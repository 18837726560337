/* eslint react/prop-types: 0 */
import React, { useContext, useMemo } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable';
import { useAssessmentsQuery, useAssessmentTable } from './AssessmentHooks';
import { NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';

const namespace = 'assessments'

export default function AssessmentTable () {
  const [tableProps, revisionModal] = useAssessmentTable(namespace)
  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = tableProps
  return (
    <>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <AssessmentTableDataProvider>
          <ReactTable {...otherTableProps} />
        </AssessmentTableDataProvider>
      </ReactTableContextsProvider>
      {revisionModal}
    </>
  )
}

export function AssessmentTableDataProvider ({ children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useAssessmentsQuery(namespace, !loaded)
  const querying = queryRunning || !loaded
  const currentTableDataContext = useMemo(() => {
    console.info('Updating AssessmentTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Assessments table data provider updated.', { collection, querying, namespace })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
