import React from 'react'
import { TextInput } from '@mantine/core'
import PropTypes from 'prop-types'

export default function DescriptionInput ({
  form,
  path = 'description',
  label = 'Description',
  placeholder = 'Description',
  required = false,
  ...props
}) {
  return <TextInput
    {...form.getInputProps(path)}
    {...props}
    label={label}
    required={required}
    placeholder={placeholder}
  />
}

DescriptionInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  path: PropTypes.string,
  form: PropTypes.shape({
    getInputProps: PropTypes.func
  })
}
