import React from 'react'
import { IconInfoCircle } from '@tabler/icons-react'
import PropTypes from 'prop-types'
import CustomAlert from './CustomAlert'

export default function NoticeAlert ({ children, title = 'Notice!', ...props }) {
  return <CustomAlert title={title} icon={<IconInfoCircle />} color='blue' {...props}>{children}</CustomAlert>
}

NoticeAlert.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
