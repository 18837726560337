/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { Box, Center, Loader, Space } from '@mantine/core';
import { LandingPageMessage } from './LandingPageMessage';
import { LandingPageBackground } from './LandingPageBackground';

export function LandingPage ({ title = null, message = null, style = null, locked = null, redirectUrl = null }) {
  const [init, setInit] = useState(false)
  return (
    <div style={{ transition: 'opacity 3s cubic-bezier(0.32, 0, 0.67, 0)', opacity: !!locked || !!init ? 1 : 0 }}>
      {!locked && <LandingPageBackground init={init} setInit={setInit} landingStyle={style} />}
      {(!init && !locked)
        ? <Box mih='80vh'><><Space h='xxl' /><Center><Loader type='bars' /></Center></></Box>
        : (
        <Box mih='80vh'>
          <Space h='xxl' />
          <LandingPageMessage title={title} message={message} locked={locked} style={style} redirectUrl={redirectUrl} />
        </Box>
          )}
    </div>
  )
}
