/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react'
import { createFile, getFiles } from '../../../js/api/cycle_repository'
import FileHome from './FileHome'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import FileUpload from './FileUpload'
import { Button } from '@mantine/core'
import { IconFileUpload } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'

export default function CycleFiles () {
  const id = document.getElementById('cycle-files-container').getAttribute('data-cycle-id')
  const isViewer = parseInt(document.getElementById('cycle-files-container').getAttribute('data-is-viewer')) === 1
  const [files, setFiles] = useState([])

  const loadFiles = () => {
    getFiles(id).then(data => setFiles(data.items))
  }

  useEffect(() => {
    loadFiles()
  }, [])

  const upload = <Button disabled={isViewer} color='blue.6' component={Link} to={`/hire/report-results/${id}/files/upload`} leftSection={<IconFileUpload size={18}/>}>Upload</Button>

  const submit = data => {
    return createFile(id, data)
      .then(() => {
        showNotification({
          message: 'Successfully created file',
          color: 'success',
          autoClose: 3000
        })

        loadFiles()
      })
      .catch(() => {
        showNotification({
          title: 'Something went wrong',
          message: 'There was an error creating this file',
          color: 'red',
          autoClose: 3000
        })
      })
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/hire/report-results/:id/files' element={<FileHome files={files} loadFiles={loadFiles} upload={upload} isViewer={isViewer} home={`/cycles/${id}/results`}/>}/>
        <Route path='/hire/report-results/:id/files/upload' element={<FileUpload back={`/hire/report-results/${id}/files`} files={files} submit={submit}/>}/>
      </Routes>
    </BrowserRouter>
  )
}
