import {
  BLANK_TABLE_ACTIONS,
  DEFAULT_HIDDEN_PASS_COLUMNS,
  DEFAULT_PASS_FILTERS,
  PASS_COLUMNS, PASS_FILTERS
} from '../CycleInviteHooks';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../../core/ReactTable/paramsSlice';
import { apiSlice } from '../../../../api';
import {
  useGetCyclePassAnalyticsQuery,
  useGetCyclePassDetailQuery,
  useGetCyclePassesForCycleQuery
} from './CyclePassesApi';

export function useCyclePassDetail (cyclePassId, cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCyclePassDetailQuery({ cyclePassId, cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCyclesPasses (namespace, cycleId, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetCyclePassesForCycleQuery({ ...queryParams, cycleId }, { skip })
  return [collection ?? null, querying]
}

export function useLazyCyclesPasses (namespace, cycleId) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getCyclePassesForCycle.useQueryState({ ...queryParams, cycleId })
  return [collection ?? null, querying]
}

export function useCyclePassesTable () {
  return {
    defaultHiddenColumns: DEFAULT_HIDDEN_PASS_COLUMNS,
    defaultFilters: DEFAULT_PASS_FILTERS,
    columns: PASS_COLUMNS,
    filters: PASS_FILTERS,
    actions: BLANK_TABLE_ACTIONS,
    searchable: true
  }
}

export function useCyclePassAnalytics (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCyclePassAnalyticsQuery(cycleId, { skip })
  return [data ?? null, querying, loading]
}
