import { useSearchParams } from 'react-router-dom';
import { LandingPage } from './LandingPage';
import React from 'react';

export function InvitePreview () {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams.entries()]);
  console.debug('Edit invite preview updating', params)
  const { title, message, style: styleString, redirectUrl, locked: lockedString } = params;
  const locked = (lockedString === 'true') || (lockedString === '1')
  const style = styleString ? parseInt(styleString) : null
  return (
    <LandingPage title={locked ? 'Expired Link' : title} message={locked ? 'If you think this is a mistake, please email us at support@hirescore.com' : message} style={style} locked={locked} redirectUrl={redirectUrl} />
  )
}
