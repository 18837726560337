import axios from 'axios'

export function getProfile (id) {
  return axios({
    method: 'GET',
    url: `api/v1/profiles/${id}`
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateProfileInfo (profileId, profileInfoId, data) {
  return axios({
    method: 'POST',
    url: `api/v1/profiles/${profileId}/infos/${profileInfoId}`,
    data: data
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateProfileInfos (id, data) {
  return axios({
    method: 'POST',
    url: `api/v1/profiles/${id}/infos`,
    data: data
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
