/* eslint react/prop-types: 0 */
import { ColorSwatch, Group, Skeleton, Text } from '@mantine/core';
import { IconBan, IconHeart, IconZzz } from '@tabler/icons-react';
import React, { memo } from 'react';

export const LockableIcon = memo(function LockableIcon ({ item, querying = false }) {
  return (
    <>
      {!item && !querying && (
        <Text>
          There was an error loading some data - refreshing the page may resolve the issue.
        </Text>
      )}
      {!!querying && (
        <Group mb={20}>
          <Skeleton height={25} circle />
          <Skeleton height={25} radius="xl" miw='10rem' w='10%' />
        </Group>
      )}
      {!querying && !!item && (
        <>
          <ColorSwatch
            color={getLockableItemCssColor(item)}
            withShadow={false}
          >
            {getLockableItemBullet(item)}
          </ColorSwatch>
          <Text>{getLockableItemStatusText(item)}</Text>
        </>
      )}
    </>
  )
})

export function getLockableItemCssColor (item) {
  return item.locked ? LockableCssColor.Locked : (item.active ? LockableCssColor.Active : LockableCssColor.Dormant)
}

export function getLockableItemMantineColor (item) {
  return item.locked ? LockableMantineColor.Locked : (item.active ? LockableMantineColor.Active : LockableMantineColor.Dormant)
}

export function getLockableItemBasicColor (item) {
  return item.locked ? LockableColor.Locked : (item.active ? LockableColor.Active : LockableColor.Dormant)
}

export function getLockableItemBullet (item) {
  return item.locked ? getLockedBullet() : (item.active ? getActiveBullet() : getDormantBullet())
}

export function getLockedBullet () {
  return <IconBan size={20} strokeWidth={2} color='#FFF' />
}

export function getActiveBullet () {
  return <IconHeart size={20} strokeWidth={2} color='#FFF' />
}

export function getDormantBullet () {
  return <IconZzz size={20} strokeWidth={2} color='#FFF' />
}

export function getLockableItemStatusText (item) {
  return item.locked ? LockableStatus.Locked : (item.active ? LockableStatus.Active : LockableStatus.Dormant)
}

export const LockableColor = Object.freeze({
  Active: 'green',
  Dormant: 'gray',
  Locked: 'red'
})

export const LockableCssColor = Object.freeze({
  Active: `var(--mantine-color-${LockableColor.Active}-6)`,
  Dormant: `var(--mantine-color-${LockableColor.Dormant}-6)`,
  Locked: `var(--mantine-color-${LockableColor.Locked}-6)`
})

export const LockableMantineColor = Object.freeze({
  Active: `${LockableColor.Active}.6`,
  Dormant: `${LockableColor.Dormant}.6`,
  Locked: `${LockableColor.Locked}.6`
})

export const LockableStatus = Object.freeze({
  Active: 'Active',
  Dormant: 'Dormant',
  Locked: 'Locked'
})
