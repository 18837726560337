import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { signup } from '../../js/api/account_repository'
import styles from './Signup.module.scss'
import { showNotification } from '@mantine/notifications'
import { Container, Grid, Paper, Title } from '@mantine/core'
import Logo from './Logo'

export default function Signup () {
  const referral = document.getElementById('marketing-container').getAttribute('data-referral')
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: { referral }
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    const success = await signup(data)

    if (success) {
      window.location.replace('/invite')
    } else {
      showNotification({
        title: 'Something went wrong',
        message: 'There was an error submitting the form',
        color: 'red'
      })
    }

    setLoading(false)
  }

  return (
    <Container size='md' mt='md'>
      <Paper withBorder>
        <Title>Sign up for <Logo />!</Title>
          <div className={styles.formContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid>
                <Grid.Col sm={6}>
                  <label>First Name
                    <input type="text" autoFocus={true} {...register('first_name', { required: true, maxLength: 100 })} />
                    {errors.first_name?.type === 'required' && 'First name is required'}
                    {errors.first_name?.type === 'maxLength' && 'First name cannot be longer than 100 characters'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <label>Last Name
                    <input type="text" {...register('last_name', { required: true, maxLength: 100 })} />
                    {errors.last_name?.type === 'required' && 'Last name is required'}
                    {errors.last_name?.type === 'maxLength' && 'Last name cannot be longer than 100 characters'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <label>Email
                    <input type="text" {...register('email', { required: true, pattern: /^\S+@\S+$/i, maxLength: 50 })} />
                    {errors.email?.type === 'required' && 'Email is required'}
                    {errors.email?.type === 'maxLength' && 'Email cannot be longer than 50 characters'}
                    {errors.email?.type === 'pattern' && 'Invalid email'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <label>Company Name
                    <input type="text" {...register('company', { required: true, maxLength: 100 })} />
                    {errors.company?.type === 'required' && 'Company name is required'}
                    {errors.company?.type === 'maxLength' && 'Company name cannot be longer than 100 characters'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <label>No. of employees
                    <select {...register('company_size', { required: true })}>
                      <option value="0">Fewer than 10</option>
                      <option value="1">10 to 49</option>
                      <option value="2">50 to 249</option>
                      <option value="3">250+</option>
                    </select>
                    {errors.company_size?.type === 'required' && 'No. of employees is required'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <label>Phone
                    <input type="tel" {...register('phone', { required: true, maxLength: 20 })} />
                    {errors.phone?.type === 'required' && 'Phone is required'}
                    {errors.phone?.type === 'maxLength' && 'Phone cannot be longer than 20 characters'}
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <label>Do you have an invitation code? (optional)
                    <input type="text" {...register('referral', { required: false })} />
                  </label>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <input type='submit' className='button large expand radius' disabled={!!loading } value={loading ? 'Processing' : 'Request a free trial'} />
                  {errors.submit?.type === 'manual' && errors.submit.message}
                </Grid.Col>
              </Grid>
            </form>
          </div>
      <div className={styles.signIn}>
        <Grid>
          <Grid.Col md={8} offsetMd={2} lg={4} offsetLg={1}>
            <div className='float right'>Already a client? <a href='/login'>Sign in</a> instead</div>
          </Grid.Col>
        </Grid>
      </div>
      </Paper>
    </Container>
  )
}
