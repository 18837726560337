import React from 'react'
import Content from '../../layout/Content'
import { List, Paper, Text, ThemeIcon } from '@mantine/core'
import { IconChevronsRight } from '@tabler/icons-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useAssessmentPurposes } from './AssessmentHooks'
import { isNumerable } from '../../../js/generated/enums/QuestionType';

export default function Information ({ assessment }) {
  const purposes = useAssessmentPurposes()

  return (
    <Content>
      <Paper shadow='xs' p='md' withBorder>
        <List
          spacing='xs'
          size='sm'
          left
          icon={
            <ThemeIcon color='blue.6' size={18} radius='xl'>
              <IconChevronsRight size='1rem'/>
            </ThemeIcon>
          }
        >
          <List.Item><Text fw={700}>Name</Text>{assessment.name}</List.Item>
          <List.Item><Text fw={700}>Description</Text>{assessment.description}</List.Item>
          <List.Item><Text fw={700} span>Type</Text> {purposes?.[assessment.type]}</List.Item>
          {
            assessment.skillbuilder_assessment !== undefined &&
            <List.Item><Text fw={700} span>Subtype</Text> {assessment.skillbuilder_assessment.name}</List.Item>
          }
          {
            assessment.hiring_assessment !== undefined &&
            <List.Item><Text fw={700} span>Subtype</Text> {assessment.hiring_assessment.name}</List.Item>
          }
          <List.Item>
            <Text fw={700} span>Number of items</Text> {_.filter(assessment.questions, question => isNumerable(question.type)).length}
          </List.Item>
          <List.Item>
            <Text fw={700}>Competencies</Text>
            <List withPadding>
              {Object.values(assessment.competency_tree.parents).map(category => (
                <List.Item key={category.id}>
                  {category.name}
                  <List withPadding>
                    {Object.values(assessment.competency_tree.children[category.id]).map(competency => (
                      <List.Item key={competency.id}>{competency.name}</List.Item>
                    ))}
                  </List>
                </List.Item>
              ))}
            </List>
          </List.Item>
        </List>
      </Paper>
    </Content>
  )
}

Information.propTypes = {
  assessment: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.number,
    skillbuilder_assessment: PropTypes.shape({
      name: PropTypes.string
    }),
    hiring_assessment: PropTypes.shape({
      name: PropTypes.string
    }),
    questions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string
    })),
    competency_tree: PropTypes.shape({
      parents: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.objectOf(PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number
        }))
      ]),
      children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number
        })))
      ])
    })
  })
}
