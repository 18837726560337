/* eslint-disable camelcase */
import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../api';

export const sbUsersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrainingUser: builder.query({
      query: (accountId) => `skillbuilder/training-users/${accountId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ training_user }) => ({ type: ApiTags.SBTrainingUsers, id: training_user.id })),
              { type: ApiTags.SBTrainingUsers, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.SBTrainingUsers, id: ApiAbstractIds.PartialList }]
    }),
    createSBCandidates: builder.mutation({
      query: (data) => ({
        url: 'skillbuilder/candidates',
        method: ApiMethods.Post,
        body: data
      }),
      invalidatesTags: (result, error, data) => [
        { type: ApiTags.SBCandidates, id: ApiAbstractIds.PartialList },
        { type: ApiTags.SBCandidates, id: ApiAbstractIds.List }
      ]
    })
  })
})

export const { useGetTrainingUserQuery, useCreateSBCandidatesMutation } = sbUsersApi
