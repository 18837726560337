/* eslint react/prop-types: 0 */
import React, { useCallback } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../../core/ReactTable/ReactTable';
import { useAssessmentTable, useLazyAssessmentsQuery } from '../AssessmentHooks';
import { AssessmentTableDataProvider } from '../AssessmentTable';

const actions = []
const boxConfig = { miw: '75vw', mih: '80vh' }

export function ImportAssessmentTable ({ importAssessment, namespace = 'importAssessments' }) {
  const [collection] = useLazyAssessmentsQuery(namespace)

  const handleRowClicked = useCallback((row) => {
    if (row === undefined) return true
    const numId = parseInt(row.dataset.rowid)
    const assessment = (collection?.items.filter(c => c.id === numId))?.[0]
    if (!assessment) {
      console.debug('Unable to locate assessment matching id on click', collection, numId)
    }
    console.debug('Import assessment row clicked', numId, assessment, row)
    importAssessment(assessment)
  }, [importAssessment, collection])

  const [tableProps, revisionModal] = useAssessmentTable(namespace)
  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = tableProps
  return (
    <>
      <ReactTableContextsProvider
        columns={columns}
        namespace={namespace}
        defaultFilters={defaultFilters}
        defaultHiddenColumns={defaultHiddenColumns}
      >
        <AssessmentTableDataProvider>
          <ReactTable
            {...otherTableProps}
            actions={actions}
            onRowClick={handleRowClicked}
            boxConfig={boxConfig}
          />
        </AssessmentTableDataProvider>
      </ReactTableContextsProvider>
      {revisionModal}
    </>
  )
}
