import { ApiAbstractIds, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries';

export const applicantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpertiseCategories: builder.query({
      query: (params) => `expertise/category${paramsToQuery(params)}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.ExpertiseCategory, id: id })),
              { type: ApiTags.ExpertiseCategory, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.ExpertiseCategory, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetExpertiseCategoriesQuery } = applicantsApiSlice
