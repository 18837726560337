import { ApiAbstractIds, apiSlice, ApiTags } from '../../../react/api'
import { paramsToQuery } from '../../../react/util/queries';

export const emailHistoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmailHistories: builder.query({
      query: (params) => `email-history${paramsToQuery(params)}`,
      providesTags: (result, error, params) => {
        const tags =
          result?.items
            ? [
                ...result.items.map(({ id }) => ({ type: ApiTags.EmailHistory, id: id })),
                { type: ApiTags.EmailHistory, id: ApiAbstractIds.PartialList }
              ]
            : [{ type: ApiTags.EmailHistory, id: ApiAbstractIds.PartialList }]

        if (!error && params?.cycle) {
          tags.push({ type: ApiTags.CyclesBoostInvitableApplicants, id: params.cycle })
        }
        return tags
      }
    }),
    getEmailHistoryStatusCount: builder.query({
      query: (params) => `email-history/status/count${paramsToQuery(params)}`,
      providesTags: (result, error, params) =>
        (result && params?.cycle)
          ? [{ type: ApiTags.EmailHistory, id: ApiAbstractIds.PartialList }, { type: ApiTags.CyclesBoostInvitableApplicants, id: params.cycle }]
          : [{ type: ApiTags.EmailHistory, id: ApiAbstractIds.PartialList }]
    })
  })
})

export const { useGetEmailHistoriesQuery, useGetEmailHistoryStatusCountQuery, usePrefetch } = emailHistoryApiSlice
