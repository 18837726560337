import React, { useCallback, useState } from 'react';
import { useLivePDFViewCycleAssessment } from '../../util/SkillBuilder/CycleAssessmentHooks';
import { showNotification } from '@mantine/notifications';
import { exportSkillBuilderAssessmentLivePDFView } from '../../../js/api/skillbuilder_repository';
import { AssessmentPDFExport } from './AssessmentPDFExport';
import _ from 'lodash';

const configToAssessmentParamsMap = {
  showbycomp: 'orderByCompetency',
  randomization_seed: 'randomSeed'
}

const configToExportParamsMap = {
  showbycomp: 'orderByCompetency',
  showcorrect: 'displayCorrectAnswers',
  showcomp: 'displayCompetencies'
}

export function LivePDFView () {
  const assessmentId = document.getElementById('export-live-pdf-view-assessment-container').getAttribute('data-assessment-id')
  const cycleId = document.getElementById('export-live-pdf-view-assessment-container').getAttribute('data-cycle-id')
  const [assessmentParams, setAssessmentParams] = useState({
    showbycomp: false,
    randomization_seed: 0
  })

  const [assessment, querying] = useLivePDFViewCycleAssessment(cycleId, assessmentId, assessmentParams)

  const updateAssessmentParams = useCallback((newConfig) => {
    let anyChanged = false
    const newParams = { ...assessmentParams }
    for (const [paramKey, configKey] of Object.entries(configToAssessmentParamsMap)) {
      const newConfigValue = newConfig[configKey] ?? null
      if (newParams[paramKey] !== newConfigValue) {
        newParams[paramKey] = newConfigValue
        anyChanged = true
        if (newConfigValue === null) {
          console.error('Unable to find expected config key when updating assessment params.', configKey, newConfig)
        }
      }
    }
    if (anyChanged) {
      console.debug('Updating assessment params. old|new|pdfConfig', assessmentParams, newParams, newConfig)
      setAssessmentParams(newParams)
    }
  }, [assessmentParams])

  const assessmentName = assessment?.name ?? 'Assessment'

  const exportLegacyAssessment = useCallback((pdfParams) => {
    const exportParams = Object.fromEntries(
      Object.entries(configToExportParamsMap)
        .map(([exportKey, pdfKey]) => [exportKey, pdfParams[pdfKey] ?? null])
    )
    console.info('Exporting to legacy pdf.', { exportParams, pdfParams })

    return exportSkillBuilderAssessmentLivePDFView(cycleId, assessmentId, exportParams)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${assessmentName} Export.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch(err => {
        const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

        if (err.response.status === 400 && data?.type === 'validation_error') {
          _.forEach(data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }, [cycleId, assessmentId, assessmentName])

  return (
    <AssessmentPDFExport
      assessment={assessment}
      querying={!!querying}
      updateAssessmentParams={updateAssessmentParams}
      exportLegacyAssessment={exportLegacyAssessment}
    />
  )
}
