export const formatResponseData = (data) => {
  const dataCopy = { ...data }
  dataCopy.items = dataCopy.items.map(item => {
    const newItem = { ...item[0], ...item };
    delete newItem[0];
    return newItem;
  });

  return dataCopy;
}

export const formatToOptions = (items, props) => {
  items = items !== undefined ? items : []
  const defaultProps = { label: 'name', value: 'id', group: null }
  props = { ...defaultProps, ...props }

  if (!isArray(items) && isObject(items)) {
    items = Object.keys(items).map(key => ({ [props.label]: items[key], [props.value]: key }))
  }

  const formatItems = (items) => items.map(item => ({ label: item[props.label].toString(), value: item[props.value].toString() ?? item[props.name].toString() }))
  let groups = items.filter(item => item.group).map(item => item.group)
  groups = [...new Set(groups)]
  if (groups.length > 0) {
    groups = groups.map(group => {
      return {
        group: group,
        items: formatItems(items.filter(i => (i.group === group)))
      }
    })
    const ungroupedItems = formatItems(items.filter(item => !item.group))
    if (ungroupedItems.length > 0) {
      if (props.group) {
        return [
          ...groups,
          { group: props.group, items: ungroupedItems }]
      }
      return [
        ...groups,
        ...ungroupedItems
      ]
    }
    return groups
  }
  return formatItems(items)
}

export const isArray = (a) => {
  return (!!a) && (a.constructor === Array)
}

export const isObject = (o) => {
  return (!!o) && (o.constructor === Object)
}
