import React, { useEffect } from 'react'
import { getFlashMessages } from '../../js/api/flash_message_repository'
import { Notifications, showNotification } from '@mantine/notifications'
import classes from './Notification.module.scss'
import Content from '../layout/Content'

export default function Notification () {
  const add = (type, message) => {
    switch (type) {
      case 'error':
        showNotification({
          message: message,
          color: 'red'
        })
        break
      case 'success':
        showNotification({
          message: message,
          color: 'success'
        })
        break
      default:
        showNotification({ message })
    }
  }

  useEffect(() => {
    getFlashMessages().then((data) => {
      for (const type in data.items) {
        for (const message in data.items[type]) {
          add(type, data.items[type][message])
        }
      }
    })
  }, [])

  return (
    <Content>
      <Notifications
        classNames={classes}
        position='top-center'
        portalProps={{ target: document.getElementById('react-notification-container') }}
        />
    </Content>
  )
}
