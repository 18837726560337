import {
  Button,
  Checkbox,
  Grid,
  Group,
  List,
  MultiSelect,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip
} from '@mantine/core'
import { isEmail, isNotEmpty, useForm } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import {
  IconAt,
  IconPhone,
  IconUserQuestion,
  IconUsersGroup
} from '@tabler/icons-react'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getOrganizations } from '../../../js/api/organization_repository'
import { createUser, editUser } from '../../../js/api/user_repository'
import { formatToOptions } from '../../../js/util/DataUtil'

export default function ClientDetails ({ account }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [lastQueryResponse, setLastQueryResponse] = useState(null)

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      active: true,
      demographics: false,
      role: null,
      access: []
    },
    validate: {
      first_name: isNotEmpty('First name cannot be empty'),
      last_name: isNotEmpty('First Last cannot be empty'),
      email: isEmail('Invalid email'),
      role: isNotEmpty('Please choose a role'),
      access: isNotEmpty('Please choose at least one organization')
    },
    transformValues: function (values) {
      const transformedValues = {
        account: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_number: values.phone_number,
          active: values.active
        },
        organizations: [],
        locations: [],
        cycles: [],
        role: values.role,
        demographics: values.demographics
      }

      values.access.forEach(function (organization) {
        transformedValues.organizations.push(organization.id)

        organization.locations.forEach(function (location) {
          if (location.selected) {
            transformedValues.locations.push(location.id)
          }

          location.cycles.forEach(function (cycle) {
            if (cycle.selected) {
              transformedValues.cycles.push(cycle.id)
            }
          })
        })
      })

      return transformedValues
    }
  })

  const submit = data => {
    setLoading(true)

    if (account === undefined) {
      createUser(data)
        .then(() => {
          window.location.href = '/clients/users'
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }

          setLoading(false)
        })
    } else {
      editUser(account.id, data)
        .then(() => {
          window.location.href = '/clients/users'
        })
        .catch(err => {
          if (err.response.status === 400 && err.response.data?.type === 'validation_error') {
            _.forEach(err.response.data.errors, error => {
              showNotification({
                title: 'Something went wrong',
                message: error,
                color: 'red',
                autoClose: 3000
              })
            })
          } else {
            showNotification({
              message: 'Something went wrong',
              color: 'red',
              autoClose: 3000
            })
          }

          setLoading(false)
        })
    }
  }

  const handleErrors = err => {
    if (err.access) alert(err.access)
  }

  const selectAllLocations = (orgIndex, checked) => {
    form.values.access[orgIndex].locations.forEach(function (location, locIndex) {
      form.setFieldValue(`access.${orgIndex}.locations.${locIndex}.selected`, checked)

      selectAllCycles(orgIndex, locIndex, checked)
    })
  }

  const selectAllCycles = (orgIndex, locIndex, checked) => {
    form.values.access[orgIndex].locations[locIndex].cycles.forEach(function (cycle, cycIndex) {
      form.setFieldValue(`access.${orgIndex}.locations.${locIndex}.cycles.${cycIndex}.selected`, checked)
    })
  }

  const everyFromOrganization = orgIndex => _.every(form.values.access[orgIndex].locations, { selected: true }) && _.reduce(form.values.access[orgIndex].locations.map(location => _.every(location.cycles, { selected: true })), (sum, n) => sum && n)

  const everyFromLocation = (orgIndex, locIndex) => _.every(form.values.access[orgIndex].locations[locIndex].cycles, { selected: true })

  useEffect(() => {
    getOrganizations({ limit: 0 }).then(collection => setOrganizations(collection.items))
  }, [])

  useShallowEffect(() => {
    if (account !== undefined) {
      const values = {
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email,
        phone_number: account.phone_number ?? '',
        active: account.active === 1
      }

      if (!_.isEmpty(_.filter(account.roles, { name: 'ROLE_PORTAL_CLIENT' }))) {
        values.role = 'ROLE_PORTAL_CLIENT'
      } else if (!_.isEmpty(_.filter(account.roles, { name: 'ROLE_PORTAL_USER' }))) {
        values.role = 'ROLE_PORTAL_USER'
      }

      if (!_.isEmpty(_.filter(account.roles, { name: 'ROLE_DISC_DEMO' }))) {
        values.demographics = true
      }

      form.setValues(values)
      setSelectedOrganizations(_.map(account.organization_access, organizationAccess => organizationAccess.organization.id.toString()))
    }
  }, [account])

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()

    const params = { clientIds: selectedOrganizations }

    if (account !== undefined) {
      params.accountId = account.id
    }

    axios({
      method: 'get',
      url: '/api/v1/clients/locationsAndCycles',
      params: params,
      cancelToken: cancelSource.token
    })
      .then(response => {
        if (response?.data) {
          setLastQueryResponse(response.data)
        }
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.info('Cancelled axios locationsAndCycles request via token.', err)
        } else {
          console.error(err)
          throw err
        }
      })
    return () => cancelSource.cancel()
  }, [selectedOrganizations, account])

  useShallowEffect(() => {
    if (lastQueryResponse) {
      const organizationIds = new Set(lastQueryResponse.map(organization => parseInt(organization.id)))
      const formOrganizationIds = new Set(form.values.access.map(access => parseInt(access.id)))
      let accessIndex = 0
      let removedAccess = 0
      for (const formAccessId of formOrganizationIds) {
        if (!(organizationIds.has(formAccessId))) {
          form.removeListItem('access', accessIndex - removedAccess)
          removedAccess += 1
        }
        accessIndex += 1
      }
      for (const organization of lastQueryResponse) {
        if (!(formOrganizationIds.has(parseInt(organization.id)))) {
          form.insertListItem('access', organization)
        }
      }
    }
  }, [lastQueryResponse])

  const organizationOptions = useMemo(() => {
    return formatToOptions(organizations)
  }, [organizations])

  return (
    <form onSubmit={form.onSubmit(submit, handleErrors)}>
      <Grid>
        <Grid.Col span={{ lg: 6, md: 9, sm: 12 }}>
            <Grid>
              <Grid.Col span={{ sm: 12, md: 6 }}>
                <TextInput label='First name' placeholder='John' {...form.getInputProps('first_name')} withAsterisk />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }}>
                <TextInput label='Last name' placeholder='Smith' {...form.getInputProps('last_name')} withAsterisk />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }}>
                <TextInput label='Email' leftSection={<IconAt size='0.8rem' />} placeholder='john@email.com' {...form.getInputProps('email')} withAsterisk />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6 }}>
                <TextInput label='Phone number' leftSection={<IconPhone size='0.8rem' />} placeholder='(XXX) XXX-XXXX' {...form.getInputProps('phone_number')} />
              </Grid.Col>
              <Grid.Col sm={12}>
                <Select
                  label='Role'
                  placeholder='Pick One'
                  data={[
                    { value: 'ROLE_PORTAL_CLIENT', label: 'Portal Client' },
                    { value: 'ROLE_PORTAL_USER', label: 'Portal Viewer' }
                  ]}
                  {...form.getInputProps('role')}
                  leftSection={<IconUserQuestion size='1rem' />}
                  withAsterisk
                />
              </Grid.Col>
              {
                account !== undefined &&
                <Grid.Col sm={12}>
                  <Checkbox label='Active' {...form.getInputProps('active', { type: 'checkbox' })} />
                </Grid.Col>
              }
              <Grid.Col sm={12}>
                <Tooltip label={'Grant this user permission to see demographic information about applicants'}>
                  <Checkbox label='Demographics' {...form.getInputProps('demographics', { type: 'checkbox' })} />
                </Tooltip>
              </Grid.Col>
              <Grid.Col sm={12}>
                <MultiSelect
                  label='Organizations'
                  placeholder='Pick all that apply'
                  data={organizationOptions}
                  value={selectedOrganizations}
                  onChange={setSelectedOrganizations}
                  searchable
                  clearable
                  withAsterisk
                  readOnly={loading}
                />
              </Grid.Col>
              {
                !_.isEmpty(form.values.access) &&
                <Grid.Col sm={12}>
                  <List
                    spacing='md'
                    icon={
                      <ThemeIcon color='blue' size={24} radius='xl'>
                        <IconUsersGroup size='1rem' />
                      </ThemeIcon>
                    }
                  >
                    {
                      form.values.access.map((organization, orgIndex) => (
                        <React.Fragment key={`access-client-${organization.id}`}>
                          <List.Item>
                            <SimpleGrid cols={1} verticalSpacing='xs'>
                              <Title order={4}>{organization.name}</Title>
                              <List spacing='xs'>
                                {
                                  !_.isEmpty(organization.locations) &&
                                  <List.Item icon={
                                    <Checkbox
                                      checked={everyFromOrganization(orgIndex)}
                                      onChange={ev => selectAllLocations(orgIndex, ev.target.checked)}
                                    />
                                  }>
                                    <SimpleGrid cols={1} verticalSpacing='xs'>
                                      <Text>Select All</Text>
                                      <List spacing='xs'>
                                        {organization.locations.map((location, locIndex) => (
                                          <React.Fragment key={`access-location-${location.id}`}>
                                            <List.Item icon={
                                              <Tooltip label={'Grant access to future cycles under this location automatically'}>
                                                <Checkbox {...form.getInputProps(`access.${orgIndex}.locations.${locIndex}.selected`, { type: 'checkbox' })}/>
                                              </Tooltip>
                                            }>
                                              <SimpleGrid cols={1} verticalSpacing='xs'>
                                                <Title order={5}>{location.name}</Title>
                                                <List spacing='xs'>
                                                  {
                                                    !_.isEmpty(location.cycles) &&
                                                    <List.Item
                                                      icon={
                                                        <Checkbox
                                                          checked={everyFromLocation(orgIndex, locIndex)}
                                                          onChange={ev => selectAllCycles(orgIndex, locIndex, ev.target.checked)}
                                                        />
                                                      }
                                                    >
                                                      <SimpleGrid cols={1} verticalSpacing='xs'>
                                                        <Text>Select All</Text>
                                                        <List spacing='xs'>
                                                          {location.cycles.map((cycle, cycIndex) => (
                                                            <React.Fragment key={`access-cycle-${cycle.id}`}>
                                                              <List.Item icon={
                                                                <Tooltip label={'Grant access to this cycle'}>
                                                                  <Checkbox {...form.getInputProps(`access.${orgIndex}.locations.${locIndex}.cycles.${cycIndex}.selected`, { type: 'checkbox' })}/>
                                                                </Tooltip>
                                                              }>
                                                                <Text>{cycle.name}</Text>
                                                              </List.Item>
                                                            </React.Fragment>
                                                          ))}
                                                        </List>
                                                      </SimpleGrid>
                                                    </List.Item>
                                                  }
                                                </List>
                                              </SimpleGrid>
                                            </List.Item>
                                          </React.Fragment>
                                        ))}
                                      </List>
                                    </SimpleGrid>
                                  </List.Item>
                                }
                              </List>
                            </SimpleGrid>
                          </List.Item>
                        </React.Fragment>
                      ))
                    }
                  </List>
                </Grid.Col>
              }
              <Grid.Col sm={12}>
                <Group spacing='xs'>
                  <Button type='submit' color='success' loading={loading}>{account !== undefined ? 'Save' : 'Invite'}</Button>
                  <Button color='gray.6' onClick={() => navigate(-1)}>Cancel</Button>
                </Group>
              </Grid.Col>
            </Grid>
        </Grid.Col>
      </Grid>
    </form>
  )
}

ClientDetails.propTypes = {
  account: PropTypes.object
}
