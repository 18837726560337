/* eslint react/prop-types: 0 */
import { Box, Checkbox, Stack, Text } from '@mantine/core'
import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSelectedKeys,
  selectBulk,
  selectOne,
  unselectBulk,
  unselectOne
} from './selectedColumnsSlice';
import { NamespaceContext } from './TableContexts';
import { selectTableRowIdsByActiveRequestId, selectTableRowsByActiveRequestId } from './tableRowsSlice';

/**
 * Renders a selectable column for the ReactTable. State is managed with redux.
 * @example
 * //  return useMemo(
 * //    () => [
 * //      SelectableColumn({
 * //        selectedValueAccessor: 'checkbox',
 * //        selectAriaLabel: 'Select applicant',
 * //        selectAllAriaLabel: 'Select all applicants',
 * //        id: 'checkbox',
 * //        accessor: 'id',
 * //        metadata: {
 * //          isGranted: account.access.ADMIN
 * //        }
 * //      }),
 * //  ...
 * //  ], [])
 * @param {?string} [selectedKeyAccessor] - The column ID to use as the key for selections. The value of the chosen column must be unique to each row. Defaults to the id passed to this column
 * @param {?string} [selectedValueAccessor] -  The column ID to use as the value. Defaults to the whole row
 * @param {string} [selectAriaLabel] - Accessibility label for each rows' checkbox
 * @param {string} [selectAllAriaLabel] - Accessibility label for the select all checkbox
 * @param props Additional props used by the ReactTable columns (accessor, id, metadata, etc.)
 */
export default function SelectableColumn ({
  selectedKeyAccessor = null,
  selectedValueAccessor = null,
  selectAriaLabel = 'Select this row',
  selectAllAriaLabel = 'Select all rows',
  ...props
}) {
  selectedKeyAccessor = selectedKeyAccessor ?? props.id

  return {
    Header: ({ rows }) => {
      const selected = useSelector((state) => selectAllSelectedKeys(state))
      const keys = rows.map(row => row.original[selectedKeyAccessor].toString())
      const checked = selected.length > 0 && keys.every(key => selected.includes(key))
      const indeterminate = selected.length > 0 && keys.some(key => selected.includes(key))

      return (
        <SelectAllColumn
          count={selected.length}
          rows={rows}
          ariaLabel={selectAllAriaLabel}
          selectedKeyAccessor={selectedKeyAccessor}
          selectedValueAccessor={selectedValueAccessor}
          checked={checked}
          indeterminate={indeterminate && !checked}
        />
      )
    },
    newHeaderProps: { selectedKeyAccessor, selectAllAriaLabel, selectedValueAccessor },
    _Header: NewHeaderComponent,
    Cell: ({ cell: { row } }) => {
      const selected = useSelector((state) => selectAllSelectedKeys(state))

      return (
        <SelectOneColumn
          checked = {selected.includes(row.original[selectedKeyAccessor].toString())}
          row = {row}
          selectedKeyAccessor={selectedKeyAccessor}
          selectedValueAccessor={selectedValueAccessor}
          ariaLabel = {selectAriaLabel}
        />
      )
    },
    ...props
  }
}

const NewHeaderComponent = memo(function NewHeaderComponent ({ selectedKeyAccessor, selectAllAriaLabel, selectedValueAccessor }) {
  const namespace = useContext(NamespaceContext)
  const rowIds = useSelector(state => selectTableRowIdsByActiveRequestId(state, namespace))
  const selected = useSelector((state) => selectAllSelectedKeys(state))
  const keys = rowIds.map(rowId => rowId.toString())
  const checked = selected.length > 0 && keys.every(key => selected.includes(key))
  const indeterminate = selected.length > 0 && keys.some(key => selected.includes(key))

  console.debug('New select all header component update', { namespace, rowIds, selected, checked, indeterminate })

  return (
    <NewSelectAllColumn
      count={selected.length}
      ariaLabel={selectAllAriaLabel}
      selectedKeyAccessor={selectedKeyAccessor}
      selectedValueAccessor={selectedValueAccessor}
      checked={checked}
      indeterminate={indeterminate && !checked}
    />
  )
})

const NewSelectAllColumn = memo(function NewSelectAllColumn ({ count, checked, indeterminate, selectedKeyAccessor, selectedValueAccessor, ariaLabel }) { // TODO [column refactor] remove accessors for row id
  const dispatch = useDispatch()
  const namespace = useContext(NamespaceContext)
  const rows = useSelector(state => selectTableRowsByActiveRequestId(state, namespace))
  console.debug('NewSelectAllColumn updated', { rows, namespace })

  return (
    <Stack>
      {!!count && (
        <Box pos='relative' w='100%' />
      )}
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        aria-label={ariaLabel}
        onClick={(event) => { event.stopPropagation() }}
        onChange={(event) =>
          event.target.checked
            ? dispatch(selectBulk({
              rows: rows,
              keyAccessor: selectedKeyAccessor,
              valueAccessor: selectedValueAccessor
            }))
            : dispatch(unselectBulk({
              keys: rows.map(row => row.id.toString())
            }))
        }
      />
      {!!count && (
        <Box pos='relative' w='100%'>
          {!!count && <Text ta='center' span lh={0} top={0} left={0} right={0} w='auto' my={0} py={0} pos='absolute'>({count})</Text>}
        </Box>
      )}
    </Stack>
  )
})

const SelectOneColumn = memo(function SelectOneColumn ({ checked, row, selectedKeyAccessor, selectedValueAccessor, ariaLabel }) {
  const dispatch = useDispatch()

  return (
    <Checkbox
      checked={checked}
      aria-label={ariaLabel}
      onClick={(event) => { event.stopPropagation() }}
      onChange={(event) => event.target.checked
        ? dispatch(selectOne({
          key: row.original[selectedKeyAccessor].toString(),
          value: selectedValueAccessor ? row.original[selectedValueAccessor] : row.original
        }))
        : dispatch(unselectOne({
          key: row.original[selectedKeyAccessor].toString()
        }))
      }
    />
  )
})

const SelectAllColumn = memo(function SelectAllColumn ({ count, checked, indeterminate, rows, selectedKeyAccessor, selectedValueAccessor, ariaLabel }) {
  const dispatch = useDispatch()

  return (
    <Stack>
      {!!count && (
        <Box pos='relative' w='100%' />
      )}
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        aria-label={ariaLabel}
        onClick={(event) => { event.stopPropagation() }}
        onChange={(event) =>
          event.target.checked
            ? dispatch(selectBulk({
              rows: rows.map(row => row.original),
              keyAccessor: selectedKeyAccessor,
              valueAccessor: selectedValueAccessor
            }))
            : dispatch(unselectBulk({
              keys: rows.map(row => row.original[selectedKeyAccessor].toString())
            }))
      }
      />
      {!!count && (
        <Box pos='relative' w='100%'>
          {!!count && <Text ta='center' span lh={0} top={0} left={0} right={0} w='auto' my={0} py={0} pos='absolute'>({count})</Text>}
        </Box>
      )}
    </Stack>
  )
})

SelectableColumn.propTypes = {
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  selectAriaLabel: PropTypes.string,
  selectAllAriaLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired
}

SelectAllColumn.propTypes = {
  count: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  ariaLabel: PropTypes.string
}

SelectOneColumn.propTypes = {
  checked: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  ariaLabel: PropTypes.string
}
