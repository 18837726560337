/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { ActionIcon, Button, Container, Flex, Radio, Stack, Tooltip } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import ReactSlider from 'react-slider'
import styles from './BulletChartEditor.module.scss'
import BulletChart, { getChartData } from '../../js/charts/BulletChart'
import _ from 'lodash'
import { IconMinus, IconPlus } from '@tabler/icons-react'

export default function BulletChartEditor ({ editing, onSubmit }) {
  const ref = useRef()
  const editChart = useRef()
  const [handles, setHandles] = useState([])
  const [colors, setColors] = useState([])
  const [activeHandle, setActiveHandle] = useState(null)
  const [activeColor, setActiveColor] = useState(null)
  const chartColors = { r: 'rgba(255 0 0 / 50%)', y: 'rgba(255 255 0 / 50%)', g: 'rgba(0 255 0 / 50%)' }
  const handleColors = { r: 'red', y: 'yellow', g: 'green' }

  const updateData = (vals) => {
    let index = 0
    for (const val of vals) {
      let newVal = val
      if (index !== 0) {
        newVal = val - vals[index - 1]
      }
      editChart.current.data.datasets[index].data[1] = newVal
      index++
    }
    editChart.current.update()
  }

  const updateColor = (color, index) => {
    editChart.current.data.datasets[index].backgroundColor = chartColors[color] ?? 'rgba(150 150 150 / 90%)'
    editChart.current.update()
  }

  const removeZone = () => {
    editChart.current.data.datasets.splice(activeHandle, 1)
    setColors(prev => {
      const newColors = [...prev]
      newColors.splice(activeHandle, 1)
      return newColors
    })
    setHandles(prev => {
      const newHandles = [...prev]
      newHandles.splice(activeHandle, 1)
      return newHandles
    })
    setActiveHandle(_.indexOf(handles, _.last(handles)) - 1)
  }

  const addZone = () => {
    const NEW_ZONE_END = 100
    editChart.current.data.datasets.push({
      backgroundColor: 'rgba(150 150 150 / 90%)',
      data: [0, NEW_ZONE_END, 0],
      barPercentage: 3,
      categoryPercentage: 1
    })
    setHandles(prev => {
      const newData = [...prev]
      if (handles.length > 0 && _.last(handles) === 100) {
        newData[newData.length - 1] = (NEW_ZONE_END + (newData[newData.length - 2] ?? 0)) / 2
      }
      newData.push(NEW_ZONE_END)
      return newData
    })
    setActiveHandle(handles.length)
  }

  function removeDuplicates (array) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      const current = array[i]
      const previous = result[result.length - 1]

      if (previous && current.color === previous.color) {
        if (current.end > previous.end) {
          current.start = previous.start
          result[result.length - 1] = current
        }
        continue
      }

      result.push(current)
    }

    return result;
  }

  const validate = () => {
    if (handles[handles.length - 1] !== 100) {
      addZone()
      return { valid: false, msg: 'Zones must end at 100' }
    }
    if (_.compact(handles).length !== _.compact(colors).length) {
      setActiveHandle(_.indexOf(handles, _.find(handles, (handle, index) => !colors[index])))
      return { valid: false, msg: 'All zones must be assigned a color' }
    }

    return { valid: true }
  }

  const submit = () => {
    const validated = validate()
    if (!validated.valid) {
      alert(validated.msg)
      return
    }

    const zones = handles.map((value, index) => {
      return {
        start: index === 0 ? 0 : handles[index - 1],
        end: value,
        color: colors[index]
      }
    })

    onSubmit(removeDuplicates(zones))
  }

  const clear = () => {
    editChart.current?.destroy()
    setHandles([])
    setColors([])
  }

  useEffect(() => {
    editChart.current?.destroy()
    if (!editing) {
      clear()
      return
    }

    const chartData = getChartData(editing.zones, editing.name ?? '')
    editChart.current = new BulletChart(ref.current, chartData)
    setHandles(editing.zones.map((zone) => zone.end))
    setColors(editing.zones.map((zone) => zone.color))
    setActiveHandle(null)
    setActiveColor(null)
    if (editing.zones.length === 0 || (editing.id.toString().includes('new-') && editing.zones.length === 0)) {
      addZone()
    }
  }, [editing])

  useEffect(() => {
    if (!editing) return
    updateData(handles)
  }, [handles])

  useEffect(() => {
    if (!editing || activeHandle === null) return
    updateColor(activeColor, activeHandle)
    setColors(prev => {
      const newData = [...prev]
      newData[activeHandle] = activeColor ?? null
      return newData
    })
  }, [activeColor])

  useEffect(() => {
    if (!editing) return
    setActiveColor(colors[activeHandle] ?? null)
  }, [activeHandle])

  return (
    <Container pos='relative'>
      <Stack>
        <canvas ref={ref} width='8' height='1' style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}></canvas>
        <ReactSlider
          className={styles.slider}
          value={handles}
          onChange={(value, index) => {
            if (typeof value !== 'object') {
              value = [value]
            }
            setHandles(value)
          }}
          renderThumb={(props, state) => (
            <ActionIcon
              variant={activeHandle === state.index ? 'filled' : 'light'}
              onFocusCapture={() => setActiveHandle(state.index)}
              color={handleColors[colors[state.index]]}
              {...props}
            >
              {state.valueNow}
            </ActionIcon>
          )}
          thumbClassName={styles.thumb}
          thumbActiveClassName={styles.thumbActive}
          minDistance={1}
        />
        <Flex justify='space-between'>
          <Radio.Group value={activeColor} onChange={setActiveColor}>
            <Stack>
              <Radio value='r' label='Red' />
              <Radio value='y' label='Yellow' />
              <Radio value='g' label='Green' />
            </Stack>
          </Radio.Group>
          <Button.Group orientation='vertical' >
            <Tooltip label='Add new zone'>
              <Button onClick={() => addZone()}><IconPlus /></Button>
            </Tooltip>
            <Tooltip label='Remove selected zone' disabled={activeHandle === null || handles.length < 2}>
              <Button disabled={activeHandle === null || handles.length < 2} onClick={() => removeZone()}>
                <IconMinus />
              </Button>
            </Tooltip>
          </Button.Group>
        </Flex>
        <Flex justify='flex-end'>
          <Button onClick={() => submit()}>Change</Button>
        </Flex>
      </Stack>
    </Container>
  )
}
